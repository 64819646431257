import moment, {Moment} from 'moment-timezone'
import {storage} from '@/services/storage'
import {setDefaultTimeZone} from '@/utils/moment-utils'
import {Dates, PeriodType} from '@/stores'

setDefaultTimeZone(storage.get('timezone'))

export const getDates = (period: PeriodType) => {
    let startDate: moment.Moment = null
    let endDate: moment.Moment = null

    switch (period) {
        case PeriodType.Day:
            startDate = moment().startOf('day')
            endDate = moment().endOf('day')
            break
        case PeriodType.Yesterday:
            startDate = moment().subtract(1, 'days').startOf('day')
            endDate = moment().subtract(1, 'days').endOf('day')
            break
        case PeriodType.Week:
            startDate = moment().startOf('isoWeek')
            endDate = moment().endOf('day')
            break
        case PeriodType.LastWeek:
            startDate = moment().startOf('isoWeek').subtract(1, 'week').startOf('day')
            endDate = moment().startOf('isoWeek').subtract(1, 'day').endOf('day')
            break
        case PeriodType.Month:
            startDate = moment().startOf('month')
            endDate = moment().endOf('day')
            break
        case PeriodType.LastMonth:
            startDate = moment().startOf('month').subtract(1, 'month').startOf('day')
            endDate = moment().startOf('month').subtract(1, 'day').endOf('day')
            break
        case PeriodType.LastTwoMonth:
            startDate = moment().startOf('day').clone().startOf('month').clone().add(-1, 'month')
            endDate = moment().startOf('day').clone().startOf('month').clone().endOf('month')
            break
        case PeriodType.Year:
            startDate = moment().startOf('year')
            endDate = moment().endOf('day')
            break
        case PeriodType.Last7Days:
            startDate = moment().subtract(6, 'days').startOf('day')
            endDate = moment().endOf('day')
            break
        case PeriodType.Last30Days:
            startDate = moment().subtract(29, 'days').startOf('day')
            endDate = moment().endOf('day')
            break
        case PeriodType.Last60Days:
            startDate = moment().subtract(59, 'days').startOf('day')
            endDate = moment().endOf('day')
            break
        case PeriodType.Last90Days:
            startDate = moment().subtract(89, 'days').startOf('day')
            endDate = moment().endOf('day')
            break
        case PeriodType.Last12Months:
            startDate = moment().subtract(12, 'months').startOf('day')
            endDate = moment().endOf('day')
            break
        case PeriodType.Quarter:
            startDate = moment().startOf('day').clone().startOf('quarter')
            endDate = moment().startOf('day').clone().startOf('quarter').clone().endOf('quarter')
            break
    }

    return {startDate, endDate}
}

export const getPrevDates = (startDate: Moment, endDate: Moment, period: PeriodType): Dates => {
    const prevEndDate = startDate.clone().subtract(1, 'second')
    switch (period) {
        case PeriodType.Week:
            return {
                startDate: startDate.clone().subtract(1, 'week'),
                endDate: prevEndDate
            }
        case PeriodType.Month:
            return {
                startDate: startDate.clone().subtract(1, 'month'),
                endDate: prevEndDate
            }
        case PeriodType.Year:
            return {
                startDate: startDate.clone().subtract(1, 'year'),
                endDate: prevEndDate
            }
        default:
            const diff = endDate.clone().diff(startDate, 'seconds')
            return {
                startDate: startDate.clone().subtract(diff + 1, 'seconds'),
                endDate: prevEndDate
            }
    }
}

export const getPeriod = (startDate: moment.Moment, endDate: moment.Moment): PeriodType => {
    const enumKeys = Object.keys(PeriodType)
    return enumKeys.reduce<PeriodType>((resultVal, current) => {
        const periodEnum = PeriodType[current]
        const dates = getDates(periodEnum)
        if (startDate?.isSame(dates.startDate) && endDate?.isSame(dates.endDate)) {
            resultVal = periodEnum
        }
        return resultVal
    }, PeriodType.Custom)
}

export const convertToMoment = (date: moment.Moment | string): moment.Moment => {
    if (!date) {
        return null
    }

    if (typeof date === 'string') {
        return moment(date)
    }

    return date.clone()
}

export const formatDate = (date: moment.Moment | string): string => {
    return convertToMoment(date)?.format()
}

export const startOfDay = (date: moment.Moment | string): string => {
    return convertToMoment(date)?.startOf('day').format()
}

export const endOfDay = (date: moment.Moment | string) => {
    return convertToMoment(date)?.endOf('day').format()
}

// Function to disable dates more than one year ago
export const disableDatesBeforeOneYear = (date: Moment): boolean => {
    // Calculate the date one year ago from now
    const oneYearAgo = moment().subtract(1, 'year').startOf('day')
    return date.isBefore(oneYearAgo)
}
