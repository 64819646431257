import translations from './translations'

translations.add('sl', {
    title: 'Vývoz',
    documentType: 'Typ dokladu',
    id: 'ID',
    fileName: 'Názov súboru',
    dateRequested: 'Požadovaný dátum',
    lastUpdatedDate: 'Posledná aktualizácia',
    status: 'Stav',
    statuses: {
        STARTED: 'Spustené',
        PROCESSING: 'Spracovávanie',
        QUEUED: 'V rad',
        FINISHED: 'Dokončené',
        FAILED: 'Neúspešné'
    }
})
