import React from 'react'
import {Typography} from 'antd'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {getAmountWithCurrentCurrency} from '@/utils'
import {ColoredAmountProds} from './props'
import styles from './styles.scss'

export const ColoredAmount: React.FC<ColoredAmountProds> = observer(({amount}) => {
    return (
        <Typography.Text
            className={classNames(styles.text,
                {[styles.green]: +amount > 0},
                {[styles.dark]: +amount < 0}
            )}
        >
            {+amount > 0 ? '+' : '-'}&nbsp;{getAmountWithCurrentCurrency(Math.abs(amount))}
        </Typography.Text>
    )
})
