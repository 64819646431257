import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import {
    FilterContainer,
    IFilterContainerStore,
    RangePickerContainer,
    RefreshButton
} from '@/components'
import {getRangeDate, isDateDisabled, isDatesAreInRange} from '@/pages/Settlements/utils'
import {useScreenType} from '@/hooks'
import {SettlementsFiltersStoreSymbol} from '../../Settlements'
import {SettlementsTableFilterProps} from './props'
import styles from './styles.scss'

export const SettlementsFilterRangePickerContainerStoreSymbol = Symbol(
    'SettlementsFilterRangePickerContainerStore'
)

export const SettlementsTableFilter: React.FC<SettlementsTableFilterProps> = observer(
    ({isLoading, onReloadClick}) => {
        const {isMobile} = useScreenType()
        const filterStore = useInjection<IFilterContainerStore>(SettlementsFiltersStoreSymbol)

        const onRefreshButtonClick = async () => await onReloadClick()

        return (
            <div className={classNames(styles.settlementsTableFilter)}>
                <>
                    <RefreshButton onClick={onRefreshButtonClick} disabled={isLoading} />
                    {filterStore.isFilterButtonVisible && (
                        <div className={styles.filterButtonContainer}>
                            <FilterContainer
                                store={filterStore}
                                formId={'settlements-popover-filter-form'}
                            />
                        </div>
                    )}
                    <RangePickerContainer
                        className={classNames({[styles.rangePickerMobile]: isMobile})}
                        disabledDate={isDateDisabled}
                        onCalendarChange={(dates, rangeType) => {
                            if (rangeType === 'start' && !isDatesAreInRange(dates)) {
                                return [dates[0], getRangeDate(dates[0], 'end')]
                            }
                            return dates
                        }}
                        injectableIdentifier={SettlementsFilterRangePickerContainerStoreSymbol}
                    />
                </>
            </div>
        )
    }
)
