import translations from './translations'

translations.add('el', {
    title: 'Απαιτείται Επαλήθευση',
    continue: 'Συνέχεια',
    resend: {
        question: `Δεν λάβατε έναν κωδικό;`
    },
    description: (n, e) =>
        `Για να συνεχίσετε, παρακαλούμε εισάγετε τον ${n}-ψήφιο κωδικό επαλήθευσης που στάλθηκε στον αριθμό ${e}`
})
