import translations from './translations'

translations.add('ro', {
    back: 'Înapoi la autentificare',
    title: 'Setare parolă nouă',
    newPassword: 'Parola nouă',
    confirmPassword: 'Confirmă parola',
    errors: {
        newPasswordRequired: 'Te rugăm să introduci parola',
        newPasswordInvalid: 'Formatul parolei incorect',
        confirmPasswordInvalid: 'Parolele introduse nu se potrivesc',
        confirmPasswordRequired: 'Te rugăm să introduci din nou parola pentru confirmare'
    },
    submit: 'Confirmare',
    codeMustContain: 'Parola trebuie să conțină',
    codeRestrictions: {
        atLeastEightCharacters: 'Cel puțin 8 caractere',
        atLeastOneDigit: 'Cel puțin 1 cifră',
        atLeastOneSpecialCharacter: 'Cel puțin 1 caracter special, de exemplu !@#&',
        atLeastOneUppercaseLetter: 'Cel puțin 1 literă majusculă',
        atLeastOneLowercaseLetter: 'Cel puțin 1 literă minusculă'
    }
})
