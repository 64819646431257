import translations from './translations'
import React from 'react'

translations.add('el', {
    title: 'Klarna',
    description: <>
        Η DNA Payments είναι περήφανη που συνεργάζεται με την Klarna για την παροχή συναλλαγών Buy Now Pay Later (BNPL). Το BNPL επιτρέπει στους καταναλωτές να κατανείμουν το κόστος μιας σχετικής αγοράς χωρίς να πληρώνουν τόκους ή πρόσθετες χρεώσεις.
        <br/><br/>
        Μόλις ενεργοποιηθεί αυτό, οι καταναλωτές θα έχουν την επιλογή να επιλέξουν το Klarna BNPL κατά τη διαδικασία ολοκλήρωσης αγοράς, εκτός εάν έχει απενεργοποιηθεί στη διαδικασία του integration.
        <br/><br/>
    </>
})
