import translations from './translations'

translations.add('sl', {
    buttons: {
        processNewPayment: 'Spracovať novú platbu',
        cancelPayment: 'Zrušiť platbu',
        charge: 'Naúčtuj',
        refund: 'Vrátenie'
    },
    transactions: 'Transakcie',
    transactionDetails: 'Podrobnosti o transakcii',
    noDescription: 'Bez popisu',
    refundDisabledTooltip: "The Customer's bank does not support Open Banking refunds"
})
