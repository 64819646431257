import translations from './translations'
import React from 'react'

translations.add('es', {
    title: 'Google Pay',
    description: <>
        Google Pay permite a los consumidores pagar sus bienes y servicios en línea de forma fácil y rápida. Los consumidores almacenan la información de su tarjeta de pago en su Google Wallet, que puede invocarse desde sus dispositivos Android o dentro del navegador web Google Chrome.
        <br/><br/>
        DNA Payments se complace en ofrecer este método de pago a los comerciantes y se puede activar o desactivar aquí.
        <br/><br/>
    </>
})
