import React from 'react'
import {useInjection} from 'dna-react-ioc'
import {observer} from 'mobx-react'
import {InviteEditTeammate} from './InviteEditTeammate'
import {ModalContainer, ModalFormFooter} from '@/components'
import {LoadingState} from '@/types'
import {TInviteEditTeammateStore} from '@/pages/TeamManagement/components/InviteEditTeammate/TInviteEditTeammateStore'
import translations from './translations'
import styles from './styles.scss'
import {Form} from 'antd'
import {InviteEditTeammateFormModel} from '@/pages/TeamManagement/components/InviteEditTeammate/models'

export const EditTeammateModalContainerStoreSymbol = Symbol('EditTeammateModalContainerStoreSymbol')
export const EditTeammateStoreSymbol = Symbol('EditTeammateStore')

const EDIT_TEAMMATE_FORM = 'editTeammateForm'

export const EditTeammate = observer(() => {
    const { loadingState, cancel } = useInjection<TInviteEditTeammateStore>(EditTeammateStoreSymbol)
    const [form] = Form.useForm<InviteEditTeammateFormModel>()

    const cleanFields = () => {
        form.resetFields()
    }

    const closeModal = () => {
        cleanFields()
        cancel()
    }

    return <ModalContainer
        className={styles.modal}
        title={translations().editTeammate}
        injectableIdentifier={EditTeammateModalContainerStoreSymbol}
        width={'600px'}
        onCancel={closeModal}
        footer={<ModalFormFooter
            formId={EDIT_TEAMMATE_FORM}
            onCancel={closeModal}
            cancelText={translations().cancel}
            confirmLoading={loadingState === LoadingState.LOADING}
            okText={translations().submit}
        />}
    >
        <InviteEditTeammate type={'edit'} form={form} injectableIdentifier={EditTeammateStoreSymbol} formId={EDIT_TEAMMATE_FORM}/>
    </ModalContainer>
})
