export enum CaptureMethod {
    'pos-contactless' = 'pos-contactless',
    'ecom-paybybankapp' = 'ecom-paybybankapp',
    'ecom-openbanking' = 'ecom-openbanking',
    'ecom-klarna' = 'ecom-klarna',
    'pos-openbanking' = 'pos-openbanking',
    'pos-alipay' = 'pos-alipay',
    ecom = 'ecom',
    pos = 'pos',
    stored = 'stored',
    moto = 'moto',
    recurring = 'recurring'
}
