import React from 'react'
import translations from './translations'

translations.add('el', {
    overviewDescription: 'Η σελίδα μας "Επισκόπηση" προσφέρει μια πανοραμική άποψη για τις ροές εργασίας πληρωμών σας, όπου μπορείτε να δείτε αναλυτικά στοιχεία ως γραφήματα σε πραγματικό χρόνο που παρακολουθούν και αναφέρουν τις πληρωμές σας.',
    posPaymentsDescription: 'Η Πύλη για τους εμπόρους μας παρέχει ενημερώσεις σε πραγματικό χρόνο για κάθε Πληρωμή POS που επεξεργάζεστε, όπου μπορείτε να αναζητήσετε και να υπολογίσετε τις πληρωμές σας χρησιμοποιώντας τα κατάλληλα φίλτρα.',
    posAmexPaymentsDescription: 'Παρακολουθήστε τις συναλλαγές POS που επεξεργάζονται μέσω της American Express, όπου μπορείτε να αναζητήσετε και να λεπτομερολογήσετε τις πληρωμές σας χρησιμοποιώντας τα εργαλεία φιλτραρίσματος.',
    onlinePaymentsDescriptions: 'Η πύλη για τους εμπόρους μας παρέχει ενημερώσεις σε πραγματικό χρόνο για κάθε Ηλεκτρονική Πληρωμή που επεξεργάζεστε, όπου μπορείτε να τις παρακολουθήσετε και να διαχειριστείτε, να πραγματοποιήσετε επιστροφές χρημάτων ή να ακυρώσετε μια πληρωμή.',
    paymentLinksDescription: 'Έχετε ενεργοποιήσει τους Συνδέσμους Πληρωμών. Οι Σύνδεσμοι Πληρωμής σάς επιτρέπουν να δέχεστε πληρωμές στο διαδίκτυο χωρίς να διαθέτετε ιστότοπο, δημιουργώντας και στέλνοντας έναν Σύνδεσμο Πληρωμής στον πελάτη σας.',
    paymentLinksCreate: 'Μπορείτε να δημιουργήσετε και να στείλετε ένα Σύνδεσμο Πληρωμής στον πελάτη σας χρησιμοποιώντας αυτό το κουμπί και να πληρωθείτε διαδικτυακά σε πραγματικό χρόνο.',
    virtualTerminalDescription: 'Το Εικονικό Τερματικό, σάς επιτρέπει να δέχεστε πληρωμές μέσω τηλεφώνου ή μέσω email, ώστε να μπορείτε να δέχεστε πληρωμές εξ\' αποστάσεως χωρίς να διαθέτετε ιστότοπο.',
    virtualTerminalDetails: 'Το Εικονικό Τερματικό, σάς επιτρέπει να δέχεστε πληρωμές μέσω τηλεφώνου ή μέσω email, ώστε να μπορείτε να δέχεστε πληρωμές εξ\' αποστάσεως χωρίς να διαθέτετε ιστότοπο.',
    settlementsDescription: 'Μπορείτε να πραγματοποιήσετε λήψη, να παρακολουθήσετε και να κάνετε αναζήτηση μέσω προσαρμοσμένων ημερομηνιών και παραμέτρων των Διακανονισμών σας χρησιμοποιώντας τα φίλτρα και τα εργαλεία αναζήτησης σε αυτήν τη σελίδα.',
    settlementsDownload: 'Για να πραγματοποιήσετε λήψη των Καταστάσεων Διακανονισμών, μεταβείτε στο κουμπί ‘Λήψη’ στην επάνω δεξιά πλευρά της οθόνης και πραγματοποιήστε λήψη είτε ως αρχείο PDF, Excel ή CSV.',
    invoicesDescription: 'Παρακολουθήστε τα τέλη από τις εξοφλημένες σας συμφωνίες στην ενότητα Χρέωσης, η οποία εμφανίζει μια λίστα με τις τιμολογημένες σας από τη DNA Payments για λήψη.',
    reportsDescription: 'Εμφανίστε αναφορές και συγκρίνετε τις καταστάσεις πληρωμών σας λεπτομερώς με τα εργαλεία φιλτραρίσματος αναζήτησης και δείτε διαγράμματα ανάλογα με την κατάσταση της συναλλαγής ή τις μεθόδους πληρωμής.',
    teamManagementDescription: 'Διαχειριστείτε και ορίστε άδειες για τα μέλη της ομάδας σας στο Merchant Portal για να βελτιώσετε τη συνεργασία και να ελέγχετε την πρόσβαση με ευκολία.',
    teamManagementAddUserDescription: 'Μπορείτε εύκολα να προσκαλέσετε νέα μέλη της ομάδας να αποκτήσουν πρόσβαση στην Πύλη Εμπόρων είτε ως ΔΙΑΧΕΙΡΙΣΤΕΣ είτε με περιορισμένη πρόσβαση.',
    paymentMethodsDescription: 'Δείτε και διαχειριστείτε όλες τις εναλλακτικές μεθόδους πληρωμής για Ηλεκτρονικές Πληρωμές και Συνδέσμους Πληρωμής μέσω του Εμπορικού μας Πύλης, όλα σε ένα μέρος.',
    exportDescription: 'Καταγράψτε τα εξαγμένα αρχεία CSV με δεδομένα πληρωμής για Ηλεκτρονικές και Πληρωμές POS, εμφανίζοντας μια λίστα των εξαγμένων αρχείων σας και την κατάστασή τους.',
    thankYou: 'Ευχαριστούμε!',
    thankYouDescription: 'Ευχαριστούμε που επιλέξατε την DNA Payments! Για ακόμη μια φορά, μη διστάσετε να επικοινωνήσετε μαζί μας μέσω του online chat εάν έχετε περαιτέρω απορίες ή ανάγκες.',
    skipTutorial: 'Παράλειψη tutorial'
})
