import translations from './translations'

translations.add('ro', {
    calendarTitle: 'Personalizat',
    submit: 'Trimite',
    period: 'Perioadă',
    presets: {
        today: 'Azi',
        yesterday: 'Ieri',
        thisWeek: 'Săptămâna aceasta',
        lastWeek: 'Săptămâna trecută',
        last7Days: 'Ultimele 7 zile',
        thisMonth: 'Luna aceasta',
        last30Days: 'Ultimele 30 de zile',
        last60Days: 'Ultimele 60 de zile',
        last90Days: 'Ultimele 90 de zile',
        lastMonth: 'Luna trecută',
        thisYear: 'Anul acesta',
        last12Month: 'Ultimele 12 luni'
    }
})
