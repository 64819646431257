import translations from './translations'

translations.add('sl', {
    title: 'Nastavenia karty',
    freeze: {
        freezeCard: 'Zmraziť kartu',
        inCaseYouWantToFreeze: 'V prípade, že chcete na chvíľu zastaviť prácu s kartou'
    },
    block: {
        blockCard: 'Blokovať kartu',
        inCaseYouWantToBlock: 'V prípade straty alebo odcudzenia karty'
    },
    activate: {
        activateCard: 'Activate card',
        inCaseYouWantToActivate: 'In case of blocked card'
    },
    getCVV: 'Get CVV',
    getPIN: 'Get PIN',
    cardholder: 'Cardholder',
    address: 'Adresa'
})
