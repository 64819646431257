import translations from './translations'

translations.add('ro', {
    labels: {
        newPaymentLink: 'Noul link de plată este pregătit',
        date: 'Dată',
        customer: 'Client',
        transactionType: 'Tip tranzacție',
        amount: 'Sumă',
        customerName: 'Nume client',
        name: 'Nume',
        description: 'Descriere',
        nameOfTheServiceOrItemYouProvide: 'Numele serviciului sau produsului furnizat',
        linkExpiration: 'Expirare link',
        stores: 'Magazine',
        theNewPaymentLinkIsCreatedAndCopiedToClipboard: 'Noul link de plată a fost creat și copiat în clipboard',
        recurring: 'Plată inițială (recurentă)'
    },
    buttons: {
        createNewLink: 'Creați un link nou',
        close: 'Închideți'
    },
    errors: {
        storeRequired: 'Magazin necesar',
        orderNumberRequired: 'Numărul comenzii este obligatoriu',
        descriptionRequired: 'Descriere necesară',
        linkExpirationRequired: 'Expirarea link-ului este obligatorie',
        customerNameRequired: 'Nume client necesar'
    },
    ranges: {
        hour24: '24 de ore',
        hour48: '48 de ore',
        week1: '1 săptămână',
        month1: '1 lună'
    },
    tooltips: {
        recurring: 'Aceasta va fi prima dintr-o serie de plăți viitoare inițiate de comerciant sau de client.'
    },
    selectDate: 'Selectați data'
})
