import {register} from 'dna-react-ioc'
import {
    TModalContainerStore,
    PaginationContainerStoreType,
    TSelectContainerStore,
    TTabsContainerStore
} from '@/components'
import {PaginationStore, SelectContainerStore, TabsContainerStore} from '@/stores'
import {
    TeamManagementFiltersStoreSymbol,
    TeamManagementInvitedPaginationContainerStoreSymbol,
    TeamManagementPaginationContainerStoreSymbol,
    TeamManagementRoleSelectContainerStoreSymbol,
    TeamManagementStoreSymbol,
    TeamManagementTabsContainerStoreSymbol
} from '@/pages/TeamManagement/TeamManagement'
import {TTeamManagementStore} from '@/pages/TeamManagement/TTeamManagementStore'
import {TeamManagementStore} from '@/stores/team-management/TeamManagementStore'
import {
    InviteTeammateModalContainerStoreSymbol,
    InviteTeammateStoreSymbol,
    EditTeammateModalContainerStoreSymbol,
    EditTeammateStoreSymbol
} from '@/pages/TeamManagement/components/InviteEditTeammate'
import {ModalContainerStore} from '@/stores/modal/__mocks__/ModalContainerStore'
import {InviteTeammateStore} from '@/stores/team-management/InviteTeammateStore'
import {TInviteEditTeammateStore} from '@/pages/TeamManagement/components/InviteEditTeammate/TInviteEditTeammateStore'
import {EditTeammateStore} from '@/stores/team-management/EditTeammateStore'
import {TeamManagementFiltersStore} from '@/stores/team-management/TeamManagementFiltersStore'
import {TTeamManagementFilterStore} from '@/pages/TeamManagement/components/TeamManagementFilter'

export const teamManagementState = () => {
    register<TTabsContainerStore>(TeamManagementTabsContainerStoreSymbol)
        .to(TabsContainerStore)
        .inSingletonScope()
    register<TSelectContainerStore>(TeamManagementRoleSelectContainerStoreSymbol)
        .to(SelectContainerStore)
        .inSingletonScope()
    register<PaginationContainerStoreType>(TeamManagementPaginationContainerStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()
    register<PaginationContainerStoreType>(TeamManagementInvitedPaginationContainerStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()
    register<TModalContainerStore>(InviteTeammateModalContainerStoreSymbol)
        .to(ModalContainerStore)
        .inSingletonScope()
    register<TInviteEditTeammateStore>(InviteTeammateStoreSymbol)
        .to(InviteTeammateStore)
        .inSingletonScope()
    register<TModalContainerStore>(EditTeammateModalContainerStoreSymbol)
        .to(ModalContainerStore)
        .inSingletonScope()
    register<TInviteEditTeammateStore>(EditTeammateStoreSymbol)
        .to(EditTeammateStore)
        .inSingletonScope()
    register<TTeamManagementStore>(TeamManagementStoreSymbol)
        .to(TeamManagementStore)
        .inSingletonScope()
    register<TTeamManagementFilterStore>(TeamManagementFiltersStoreSymbol)
        .to(TeamManagementFiltersStore)
        .inSingletonScope()
}
