import React from 'react'
import translations from './translations'

translations.add('en', {
    buttons: {
        home: 'Go home'
    },
    errors: {
        ourSupportTeamIsHereToHelp: <>
            Operation failed, our support team is here to help: <a href={'https://dnapayments.com/online-quote#2'} target={'_blank'} rel={'noreferrer'}>Support</a>
        </>
    }
})
