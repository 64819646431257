import translations from '../translations'

export const essentialDataSource = [
    {key: 'name', label: translations().descriptions.name, value: translations().descriptions.cookie},
    {key: 'provider', label: translations().descriptions.provider, value: translations().descriptions.ownerOfSite},
    {
        key: 'saves_preferences',
        label: translations().descriptions.saveVisitorsPreferences,
        value: translations().descriptions.cookieByGoogleAdvanced
    },
    {key: 'cookie_name', label: translations().descriptions.cookieName, value: 'cookie'},
    {key: 'cookie_expiry', label: translations().descriptions.cookieExpiry, value: translations().descriptions.year}
]

export const statisticsDataSource = [
    {key: 'name', label: translations().descriptions.name, value: translations().descriptions.googleTagManager},
    {key: 'provider', label: translations().descriptions.provider, value: translations().descriptions.googleLLC},
    {
        key: 'purpose',
        label: translations().descriptions.purpose,
        value: translations().descriptions.cookieByGoogleAdvanced
    },
    {key: 'privacy_policy', label: translations().descriptions.privacyPolicy, value: 'https://policies.google.com/privacy?hl=en'},
    {key: 'cookie_name', label: translations().descriptions.cookieName, value: '_ga,_gat,_gid'},
    {key: 'cookie_expiry', label: translations().descriptions.cookieExpiry, value: translations().descriptions.twoYears}
]
