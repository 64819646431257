import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import classNames from 'classnames'
import {Tooltip, Typography} from 'antd'

import {Button, TitleWithStatus} from '@/components'
import {OnlinePaymentsStoreSymbol, TOnlinePaymentsStore} from '@/pages/OnlinePayments'
import {
    getOnlinePaymentStatusIconType,
    getOnlinePaymentStatusName,
    getOnlinePaymentStatusTagType,
    isProcessNewPaymentActionVisible
} from '@/pages/OnlinePayments/services'
import {hasPermissions} from '@/stores/auth/services/utils'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {OnlinePaymentStatus, PaymentMethodType} from '@/constants'
import {getAmountWithCurrency} from '@/utils'
import translations from './translations'
import {TransactionDrawerHeaderProps} from './props'
import styles from './styles.scss'
import {
    CANCEL_PAYMENT_BUTTON,
    CHARGE_PAYMENT_BUTTON,
    PROCESS_NEW_PAYMENT,
    REFUND_BUTTON
} from '@/constants/playwright-ids'

const {Text} = Typography

export const TransactionDrawerHeader: React.FC<TransactionDrawerHeaderProps> = observer(
    ({status, amount, currency, description, selectedTransaction, extra}) => {
        const isProcessNewPaymentBtnVisible = isProcessNewPaymentActionVisible(
            selectedTransaction.processingTypeId,
            selectedTransaction.status as OnlinePaymentStatus,
            selectedTransaction.paymentMethod
        )
        const {openPaymentModal, openCancelPaymentModal, openProcessNewPaymentModal} =
            useInjection<TOnlinePaymentsStore>(OnlinePaymentsStoreSymbol)

        const renderChargeBtn = () => {
            const renderBtn = (disabled = false) => (
                <Button
                    className={classNames(styles.btn, disabled && 'disabled')}
                    onClick={() => !disabled && openPaymentModal()}
                    data-test-id={REFUND_BUTTON}
                >
                    {translations().buttons.refund}
                </Button>
            )

            if (selectedTransaction) {
                const {paymentMethod, additionalDetails} = selectedTransaction

                if (
                    paymentMethod === PaymentMethodType.ecospend &&
                    !additionalDetails?.refundSupported
                ) {
                    return (
                        <Tooltip title={translations().refundDisabledTooltip}>
                            {renderBtn(true)}
                        </Tooltip>
                    )
                }
            }

            return renderBtn()
        }

        const renderAuthBtn = () => {
            return [
                <Button
                    key={'charge'}
                    className={styles.btn}
                    onClick={() => openPaymentModal()}
                    data-test-id={CHARGE_PAYMENT_BUTTON}
                >
                    {translations().buttons.charge}
                </Button>,
                <Button
                    key={'cancel'}
                    className={styles.btn}
                    onClick={() => openCancelPaymentModal(selectedTransaction)}
                    data-test-id={CANCEL_PAYMENT_BUTTON}
                >
                    {translations().buttons.cancelPayment}
                </Button>
            ]
        }

        const renderNewPaymentBtn = () => {
            return (
                <Button
                    className={styles.btn}
                    onClick={() => openProcessNewPaymentModal(selectedTransaction)}
                    data-test-id={PROCESS_NEW_PAYMENT}
                >
                    {translations().buttons.processNewPayment}
                </Button>
            )
        }

        return (
            <>
                <TitleWithStatus
                    subtitle={description || translations().noDescription}
                    statusProps={{
                        status: getOnlinePaymentStatusName(status),
                        type: getOnlinePaymentStatusTagType(status),
                        iconType: getOnlinePaymentStatusIconType(status)
                    }}
                >
                    <Text className={styles.title}>{getAmountWithCurrency(amount, currency)}</Text>
                </TitleWithStatus>
                <br />
                <div className={styles.cancelPayment}>
                    {hasPermissions([PermissionsMap.online_payments.full]) && (
                        <>
                            {status === OnlinePaymentStatus.charge &&
                                selectedTransaction.balance > 0 &&
                                renderChargeBtn()}
                            {status === OnlinePaymentStatus.auth && amount > 0 && renderAuthBtn()}
                            {isProcessNewPaymentBtnVisible && renderNewPaymentBtn()}
                        </>
                    )}
                    {extra && <div className={styles.extra}>{extra}</div>}
                </div>
            </>
        )
    }
)
