import translations from './translations'

translations.add('sl', {
    bankingDate: 'Datum Bančne Transakcije',
    amount: 'Znesek',
    currency: 'Valuta',
    captureMethod: 'Capture method',
    status: 'Stanje',
    documentId: 'ID Dokumenta',
    operation: 'Operacija',
    transactionType: 'Vrsta Transakcije',
    chargebackDate: 'Datum Vračila',
    cardMask: 'Maska kartice',
    cardScheme: 'Shema kartice',
    terminalId: 'ID terminala',
    transactionId: 'ID transakcije',
    reasonCode: 'Koda Razloga',
    arn: 'ARN',
    previousDocumentId: 'Prejšnji ID Dokumenta',
    chargebacksDetails: 'Podrobnosti Vračil',
    originalTransactionDetails: 'Podrobnosti Originalne Transakcije',
    chargebackHistory: 'Zgodovina Vračil',
    chargebackStatus: 'Status povračila',
    financialStatus: 'Finančni status'
})
