import {FieldKeyType, FieldStateType} from 'hosted-fields/types'
import {HostedFields} from 'hosted-fields/classes/HostedFields'
import {inject, injectable} from 'inversify'
import {error} from 'dna-common'
import {action, computed, makeObservable, observable, runInAction} from 'mobx'

import {TVirtualTerminalStore} from '@/pages/VirtualTerminal'
import {LoadingState} from '@/types'
import {TransactionType} from '@/types/transactions'
import {
    HostedCardFieldsOptionsType,
    VTEntryModeType,
    VTPaymentDataType,
    VTStepType
} from '@/types/virtual-terminal'
import {fetchPaymentToken, sendReceipt} from '@/api'
import {VTPaymentResultType} from '@/types/virtual-terminal/VTPaymentResultType'
import {createHostedCardFields, getPaymentData, getVirtualTerminals} from '@/utils/virtual-terminal'
import {generateInvoiceId, openErrorNotification} from '@/utils'
import translations from '@/translations'
import {OnlinePaymentsStoreSymbol, TOnlinePaymentsStore} from '@/pages/OnlinePayments'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol
} from '@/stores/store-and-terminals'

@injectable()
export class VirtualTerminalStore implements TVirtualTerminalStore {
    private readonly _merchantStoresStore: AvailableStoresDataStore
    private readonly _onlinePaymentsStore: TOnlinePaymentsStore

    step: VTStepType = 'paymentData'
    isLoading = false
    paymentData: Partial<VTPaymentDataType> = null
    paymentAccessToken = ''
    paymentResult: VTPaymentResultType = null
    cardFieldsState: Partial<Record<FieldKeyType, FieldStateType>> = {}
    hostedFields: HostedFields

    constructor(
        @inject(AvailableStoresDataStoreSymbol) merchantStoresStore: AvailableStoresDataStore,
        @inject(OnlinePaymentsStoreSymbol) onlinePaymentsStore: TOnlinePaymentsStore
    ) {
        this._merchantStoresStore = merchantStoresStore
        this._onlinePaymentsStore = onlinePaymentsStore

        makeObservable(this, {
            step: observable,
            isLoading: observable,
            paymentData: observable,
            cardFieldsState: observable,

            virtualTerminals: computed,
            isVirtualTerminalsLoaded: computed,
            isVirtualTerminalAvailable: computed,
            isRefund: computed,

            setStep: action.bound,
            setLoading: action.bound,
            setPaymentData: action.bound,
            setCardFieldsState: action.bound,
            sendReceipt: action.bound
        })
    }

    get virtualTerminals() {
        const stores = this._merchantStoresStore.ecomStores
        return getVirtualTerminals(stores)
    }

    get isVirtualTerminalsLoaded() {
        return this._merchantStoresStore.loadingState === LoadingState.DONE
    }

    get isVirtualTerminalAvailable() {
        return this.virtualTerminals.length > 0
    }

    get isRefund() {
        return this.paymentData.transactionType === TransactionType.REFUND
    }

    init() {
        const terminalId =
            this.virtualTerminals.length === 1 ? this.virtualTerminals[0].value : null

        this.setPaymentData({
            terminalId,
            invoiceId: generateInvoiceId('VT'),
            entryMode: VTEntryModeType.telephone,
            transactionType: TransactionType.SALE
        })
    }

    destroy() {
        this.init()
        this.setStep('paymentData')
        this.clearHostedFields()
        this.paymentResult = null
    }

    setStep(step: VTStepType) {
        this.step = step
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading
    }

    setPaymentData(paymentData: Partial<VTPaymentDataType>) {
        this.paymentData = paymentData
    }

    setCardFieldsState(fieldsState: Partial<Record<FieldKeyType, FieldStateType>>) {
        this.cardFieldsState = fieldsState
    }

    clearHostedFields() {
        if (this.hostedFields) {
            this.hostedFields.destroy()
        }
        this.hostedFields = null
        this.paymentAccessToken = null
        this.cardFieldsState = {}
    }

    async sendReceipt(email: string) {
        return await sendReceipt(this.paymentResult?.id, email)
    }

    async fetchToken() {
        this.setLoading(true)
        const {amount, currency, invoiceId, terminalId} = this.paymentData || {}

        try {
            const {result, error} = await fetchPaymentToken({
                amount,
                currency,
                invoiceId,
                terminalId
            })
            this.setLoading(false)
            if (error || !result) {
                openErrorNotification(error ? error.message : translations().errors.unknown)
                return false
            }
            runInAction(() => (this.paymentAccessToken = result.token))
            return true
        } catch (err) {
            openErrorNotification(err.message || translations().errors.unknown)
            this.setLoading(false)
            return false
        }
    }

    async createHostedFields(options: HostedCardFieldsOptionsType) {
        this.setLoading(true)
        try {
            this.hostedFields = await createHostedCardFields(this.paymentAccessToken, options)

            this.hostedFields.on('validityChange', (data) => {
                this.setCardFieldsState(data.fieldsState)
            })
        } catch (err) {
            error(err)
            openErrorNotification(err?.message)
        }
        this.setLoading(false)
    }

    async pay() {
        this.setLoading(true)

        try {
            const {isValid} = await this.hostedFields.validate()

            if (!isValid) {
                this.setLoading(false)
                return
            }

            const {data} = await this.hostedFields.submit({
                paymentData: getPaymentData(this.paymentData) as any
            })

            this._onlinePaymentsStore.loadTransactions()
            this.paymentResult = data as any
            this.setStep('success')
        } catch (err) {
            this._onlinePaymentsStore.loadTransactions()
            this.paymentResult = (err.data?.data || null) as any
            this.setStep('fail')
        }

        this.setLoading(false)
    }
}
