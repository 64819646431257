import translations from './translations'

translations.add('el', {
    date: 'Ημερομηνία',
    orderNumber: 'Αριθμός παραγγελίας',
    customer: 'Πελάτης',
    transactionType: 'Τύπος συναλλαγής',
    amount: 'Ποσό',
    customerName: 'Ονομα πελάτη',
    name: 'Ονομα',
    description: 'Περιγραφή',
    nameOfTheServiceOrItemYouProvide: 'Όνομα της υπηρεσίας ή του αντικειμένου που παρέχετε',
    linkExpiration: 'Λήξη συνδέσμου',
    stores: 'Καταστήματα',
    theNewPaymentLinkIsCreatedAndCopiedToClipboard: 'Ο νέος σύνδεσμος πληρωμής δημιουργήθηκε και αντιγράφηκε στο πρόχειρο',
    copied: 'Προσωπικό μήνυμα εάν χρειάζεται',
    errors: {
        storeRequired: 'Απαιτείται κατάστημα',
        orderNumberRequired: 'Απαιτείται αριθμός παραγγελίας',
        descriptionRequired: 'Απαιτείται περιγραφή',
        linkExpirationRequired: 'Απαιτείται λήξη του συνδέσμου',
        customerNameRequired: 'Απαιτείται όνομα πελάτη'
    },
    ranges: {
        hour24: '24 ώρες',
        hour48: '48 ώρες',
        week1: '1 εβδομάδα',
        month1: '1 μήνα'
    }
})
