import rootTranslations from '@/translations'

export enum PaymentMethodType {
    card = 'card',
    paypal = 'paypal',
    applepay = 'applepay',
    googlepay = 'googlepay',
    ecospend = 'ecospend',
    paybybankapp = 'payByBankApp',
    klarna = 'klarna',
    clicktopay = 'clicktopay',
    astropay = 'astropay',
    alipay = 'alipay',
    wechatpay = 'wechatpay'
}

export const getPaymentMethodTypeLabel = (paymentMethod: PaymentMethodType) => {
    return rootTranslations().constants.paymentMethodType[paymentMethod] || paymentMethod
}
