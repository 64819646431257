import React from 'react'
import translations from './translations'

translations.add('es', {
    text: 'Reenviar',
    seconds: count =>
        count ? (
            <>
                {' '}
                en <b>{count} segundos</b>
            </>
        ) : (
            ''
        )
})
