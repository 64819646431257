export function openPopupWindow(url: string, windowName: string, onWindowClose: () => void, options?: { maxWidth?: number, maxHeight?: number }) {
    const isDesktop = window.outerWidth > 768
    let width = isDesktop ? window.outerWidth * 0.8 : window.outerWidth
    let height = isDesktop ? window.outerHeight * 0.9 : window.outerHeight
    if (options?.maxWidth && width > options.maxWidth) {
        width = options.maxWidth
    }
    if (options?.maxHeight && height > options.maxHeight) {
        height = options.maxHeight
    }
    const top = Math.ceil((window.outerHeight - height) / 2)
    const left = Math.ceil((window.outerWidth - width) / 2)
    const openedWindow = window.open(url, windowName, `width=${width},height=${height},left=${left},top=${top}`)
    listenPopupWindowClose(openedWindow, onWindowClose)
    return openedWindow
}

export function listenPopupWindowClose(popupWindow: Window, onClose: () => void) {
    const timer = setInterval(() => {
        if (!popupWindow || popupWindow.closed) {
            clearInterval(timer)
            onClose()
        }
    }, 1000)
}
