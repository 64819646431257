import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    freeze: {
        freezeCard: string
        inCaseYouWantToFreeze: string
    }
    block: {
        blockCard: string
        inCaseYouWantToBlock: string
    }
    activate: {
        activateCard: string
        inCaseYouWantToActivate: string
    }
    getCVV: string
    getPIN: string
    cardholder: string
    address: string
}>()

