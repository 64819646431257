import React from 'react'
import {disableDatesBeforeOneYear} from '@/stores'
import {RangePickerContainer, RefreshButton, SelectWithTitle} from '@/components'
import {TableFilterProps} from './props'
import translations from './translations'
import styles from './styles.scss'

export const TableFilter: React.FC<TableFilterProps> = ({
    extra,
    rangePickerInjectableIdentifier,
    defaultSelectValue,
    selectProps,
    onReloadClick
}) => {
    return (
        <div className={styles.root}>
            <RefreshButton onClick={onReloadClick} />
            <SelectWithTitle
                isMobile={false}
                displayValue={translations().status}
                defaultValue={defaultSelectValue}
                className={styles.selectWrapper}
                value={selectProps.value}
                onChange={selectProps.onChange}
                options={selectProps.options}
                placeholder={selectProps.placeholder}
            />
            <div className={styles.divider}></div>
            <RangePickerContainer
                showRangePickerTime
                disabledDate={disableDatesBeforeOneYear}
                injectableIdentifier={rangePickerInjectableIdentifier}
            />
            {extra}
        </div>
    )
}
