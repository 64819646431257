import {saveAs} from 'file-saver'
import {parseTextResult} from 'back-connector'
import {camelCaseObject} from 'dna-common'

import {getWithAuth, GridResponse, postWithAuth} from '@/api'
import {FileType} from '@/constants'
import {getFilename, getPdfFilename} from '../services/utils'
import {SettlementPerDay, SettlementsResponse, SettlementsRequest} from '../models'

export const fetchAccountNumbers = () => getWithAuth<string[]>('/oppapi/v1/account-numbers')

export const fetchSettlements = (params: SettlementsRequest) =>
    postWithAuth<GridResponse<SettlementsResponse>>('/oppapi/v2/settlements', params).then(
        camelCaseObject
    )

export const fetchSettlementsByDay = (params: SettlementsRequest) =>
    postWithAuth<GridResponse<SettlementPerDay>>('/oppapi/v1/settlements-by-day', params).then(
        camelCaseObject
    )

export const downloadStatementFile = async (
    merchantName: string,
    params: Partial<SettlementsRequest>
) => {
    const {format, from, to, period} = params

    if (format !== FileType.PDF) {
        return await postWithAuth('/oppapi/v1/statement-async', {
            ...params,
            fileName: getFilename(merchantName, from, to, format)
        })
    }

    const response = await getWithAuth('/oppapi/v1/pdf-statement', {period}, [], {
        parseResponse: async (res) => {
            if (res.status !== 200) {
                const error = await parseTextResult(res)
                throw new Error(error.message)
            }
            return await res.blob()
        }
    })

    if (response.error) {
        return {error: response.error}
    }

    saveAs.saveAs(await response.result, getPdfFilename(merchantName, params.period, params.format))

    return response
}

export const downloadStatementFileOld = async (
    merchantName: string,
    params: Partial<SettlementsRequest>
) => {
    const {from, to, format} = params

    const response = await postWithAuth('/oppapi/v2/statement', params, [], {
        parseResponse: async (res) => {
            if (res.status !== 200) {
                const error = await parseTextResult(res)
                throw new Error(error.message)
            }
            return await res.blob()
        }
    })

    if (response.error) {
        return {error: response.error}
    }

    saveAs.saveAs(await response.result, getFilename(merchantName, from, to, format))

    return response
}
