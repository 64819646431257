import translations from './translations'

translations.add('en', {
    title: 'Unregister domain',
    pleaseEnterReasonWhyUnregistering: 'Please enter the reason why you are unregistering the selected domains',
    unregister: 'Unregister',
    close: 'Close',
    errors: {
        reasonRequired: 'Reason required'
    }
})
