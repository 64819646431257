import translations from './translations'

translations.add('ro', {
    title: 'Gestionarea echipei',
    inviteTeammate: 'Adaugă utilizator',
    editTeammate: 'Editează utilizator',
    cancel: 'Anulează',
    submit: 'Finalizează',
    invite: 'Invită',
    formFields: {
        email: 'Email',
        store: 'Magazin',
        firstName: 'Prenume',
        lastName: 'Nume de familie',
        teammatePermissions: 'Acces utilizator',
        inviteAsAdmin: 'Administrator',
        inviteAsRestrictedAccess: 'Acces limitat',
        accountantAccess: 'Acces financiar',
        customAccess: 'Acces personalizat',
        grantedPermissions: 'Setări permisiuni',
        accessLevels: 'Nivele de acces',
        sectionName: 'Nume secțiune',
        noAccess: 'Fără acces',
        readAccess: 'Doar citire',
        fullAccess: 'Acces complet',
        sections: {
            overview: 'Prezentare generală',
            onlinePayments: 'Plăți online',
            posPayments: 'Plăți POS',
            posAmexPayments: 'POS American Express',
            paymentLinks: 'Link-uri de plată',
            virtualTerminal: 'Terminal virtual',
            settlements: 'Reglementări',
            invoices: 'Facturi',
            reports: 'Rapoarte',
            paymentMethods: 'Metode de plată',
            teammates: 'Gestionarea echipei',
            chargebacks: 'Refuzuri la plată'
        }
    },
    formFieldsPlaceholders: {
        role: 'Selectează rol'
    },
    formErrors: {
        emailRequired: 'Adresa de email este obligatorie',
        roleRequired: 'Rol este obligatoriu',
        shopRequired: 'Magazinul este obligatoriu',
        enterValidEmail: 'Vă rugăm să introduceți o adresă de email validă',
        firstNameRequired: 'Prenumele este obligatoriu',
        lastNameRequired: 'Numele de familie este obligatoriu'
    }
})
