import translations from './translations'

translations.add('ro', {
    showAll: 'Afișează tot',
    dataTypes: {
        amount: 'Sumă',
        count: 'Număr'
    },
    week: 'Săptămână'
})
