import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    date: string
    orderNumber: string
    customer: string
    transactionType: string
    amount: string
    customerName: string
    name: string
    description: string
    nameOfTheServiceOrItemYouProvide: string
    linkExpiration: string
    stores: string
    theNewPaymentLinkIsCreatedAndCopiedToClipboard: string
    copied: string
    errors: {
        storeRequired: string
        orderNumberRequired: string
        customerNameRequired: string
        descriptionRequired: string
        linkExpirationRequired: string
    },
    ranges: {
        hour24: string
        hour48: string
        week1: string
        month1: string
    }
}>()

