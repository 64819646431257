import React from 'react'
import translations from './translations'

translations.add('ro', {
    overviewDescription:
        'Pagina noastră de prezentare oferă o vedere de ansamblu asupra fluxurilor dvs. de plată, unde puteți vizualiza analize sub formă de grafice în timp real, monitoriza și raporta plățile dvs.',
    posPaymentsDescription:
        'Portalul nostru pentru comercianți oferă actualizări în timp real pentru fiecare plată POS pe care o procesați, unde puteți căuta și filtra plățile folosind instrumentele de filtrare.',
    posAmexPaymentsDescription: 'Monitorizați tranzacțiile POS procesate prin American Express, unde puteți căuta și detalia plățile folosind instrumentele de filtrare.',
    onlinePaymentsDescriptions:
        'Portalul nostru pentru comercianți oferă actualizări în timp real pentru fiecare plată online pe care o procesați, unde puteți monitoriza și gestiona plățile, efectua rambursări sau anula o plată.',
    paymentLinksDescription:
        'Ați activat Link-urile de plată. Link-urile de plată vă permit să acceptați plăți online fără a utiliza un site web, creând și trimițând un link de plată către clientul dumneavoastră.',
    paymentLinksCreate:
        'Puteți crea și trimite un link de plată către clientul dumneavoastră folosind acest buton și puteți primi plata online în timp real.',
    virtualTerminalDescription:
        'Terminalul nostru virtual vă permite să acceptați plăți prin telefon sau prin e-mail, astfel încât să puteți accepta plăți la distanță fără a utiliza un site web.',
    virtualTerminalDetails:
        'Terminalul nostru virtual vă permite să acceptați plăți prin telefon sau prin e-mail, astfel încât să puteți accepta plăți la distanță fără a utiliza un site web.',
    settlementsDescription:
        'Puteți descărca, monitoriza și căuta date și parametri personalizați pentru soluțiile de plată folosind instrumentele de filtrare și căutare de pe această pagină.',
    settlementsDownload:
        'Pentru a descărca extrasele de cont pentru soluțiile de plată, accesați butonul "Descărcare" situat în partea din dreapta sus a ecranului și descărcați fișierul în format PDF, Excel sau CSV.',
    invoicesDescription: 'Urmați taxele din soluțiile dvs. de plată în secțiunea de facturare, care afișează o listă a facturilor dvs. de la DNA Payments pentru descărcare.',
    reportsDescription: 'Accesați rapoartele și comparați stările plăților dvs. detaliate prin instrumentele de filtrare și vizualizați graficele în funcție de starea tranzacției sau metodele de plată.',
    teamManagementDescription: 'Gestionați și stabiliți permisiunile pentru membrii echipei dvs. în cadrul Portalului Comerciantului pentru a optimiza colaborarea și a controla accesul cu ușurință',
    teamManagementAddUserDescription:
        'Puteți invita cu ușurință noi membri ai echipei să acceseze Portalul pentru comercianți, fie ca ADMIN, fie cu acces restricționat.',
    paymentMethodsDescription: 'Vizualizați și gestionați toate metodele alternative de plată pentru Plățile Online și Linkurile de Plată prin intermediul Portalului nostru pentru Comercianți, toate într-un singur loc.',
    exportDescription: 'Urmăriți fișierele CSV ale datelor de plată exportate pentru Plăți Online și POS, afișând o listă a fișierelor exportate și starea lor.',
    thankYou: 'Mulțumesc!',
    thankYouDescription:
        'Vă mulțumim că ați ales DNA Payments! Nu ezitați să ne contactați prin Chat-ul online dacă aveți întrebări sau cerințe suplimentare.',
    skipTutorial: 'Omite tutorial'
})
