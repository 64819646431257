import {getWithAuth, postWithAuth} from '@/api'
import {UnregisterDomainRequestModel} from '@/stores/payment-methods'

export const fetchDomains = () =>
    getWithAuth<{domainNames: string[]}>('/oppapi/v1/applepay/merchants/info')

export const unregisterDomain = (data: UnregisterDomainRequestModel) =>
    postWithAuth<null>('/oppapi/v1/applepay/merchants/unregistration', data)

export const registerDomain = (data: Pick<UnregisterDomainRequestModel, 'domainNames'>) =>
    postWithAuth<null>('/oppapi/v1/applepay/merchants/registration', data)
