import translations from './translations'

translations.add('ro', {
    title: 'Export plăți online',
    columns: 'Coloane',
    cancel: 'Anulare',
    ok: 'Export',
    all: value => `Toate (${value})`,
    custom: 'Personalizat'
})
