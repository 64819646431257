import translations from './translations'

translations.add('es', {
    pendingTransactions: number => `Transacciones pendientes (${number})`,
    description: 'Descripción',
    sum: 'Suma',
    transactionStatus: {
        PROCESSED: 'Processed',
        PENDING: 'Pending',
        FAILED: 'Failed',
        CANDELLED: 'Cancelled'
    },
    all: 'Todo'
})
