import React from 'react'
import classNames from 'classnames'
import {Col, Row, Typography} from 'antd'
import {observer} from 'mobx-react'
import {Card, Icon, Logo, Tag} from '@/components'
import {Theme} from '@/constants'
import {TagType} from '@/components/dumb/Tag/props'
import {CreditCardProps} from './props'
import styles from './styles.scss'

export const CreditCard: React.FC<CreditCardProps> = observer(
    ({active, cardData, className, isVirtual, onClick}) => {
        return (
            <Card
                className={classNames(styles.card, className, {[styles.cardActive]: active})}
                bordered={false}
                hoverable={!active}
                onClick={onClick}
            >
                <Row justify={'space-between'}>
                    <Col />
                    <Col>
                        <Logo className={styles.logo} type={Theme.LIGHT} />
                    </Col>
                </Row>
                <div className={styles.data}>
                    <Icon
                        className={classNames(styles.chip, {[styles.chipActive]: active})}
                        type={'chip_background'}
                    />
                    <Typography.Text className={styles.text}>{cardData.number}</Typography.Text>
                    <Typography.Text className={styles.text}>{cardData.date}</Typography.Text>
                    <Row justify={'space-between'} className={styles.lastRow}>
                        <Col>
                            <Typography.Text className={styles.text}>
                                {cardData.name}
                            </Typography.Text>
                        </Col>
                        <Col>{isVirtual && <Tag type={TagType.Success}>Virtual</Tag>}</Col>
                    </Row>
                </div>
            </Card>
        )
    }
)
