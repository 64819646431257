import translations from './translations'

translations.add('en', {
    labels: {
        title: 'Process new payment',
        merchantCustomData: 'Merchant custom data'
    },
    buttons: {
        ok: 'Ok'
    }
})
