import translations from './translations'

translations.add('el', {
    all: 'Ολα',
    selectAll: 'Επιλογή όλων',
    deselectAll: 'Αποεπιλογή όλων',
    placeholder: 'Αναζήτηση εδώ...',
    noData: 'Χωρίς δεδομένα',
    selected: (count) => `${count} Επιλέχθηκαν'`
})

