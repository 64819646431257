import translations from './translations'

translations.add('es', {
    labels: {
        title: 'Procesar nuevo pago',
        merchantCustomData: 'Datos personalizados del comerciante'
    },
    buttons: {
        ok: 'Ok'
    }
})
