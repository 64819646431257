import React from 'react'
import numeral from 'numeral'
import {Tag, Tooltip} from 'antd'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons'
import styles from './styles.scss'
import {PERCENTAGE} from '@/constants/playwright-ids'

type Props = {
    percent: number
    tooltipText?: string
}

export const PercentTrend: React.FC<Props> = observer(({percent, tooltipText}) => {
    return (
        <Tooltip title={tooltipText}>
            <Tag
                className={classNames(styles.tag,
                    {[styles.green]: +percent >= 50},
                    {[styles.red]: +percent < 50}
                )}
                data-test-id={PERCENTAGE}
            >
                {+percent >= 50 ? <ArrowUpOutlined/> :
                    <ArrowDownOutlined/>} {`${numeral(Math.abs(percent)).format('0.00')}%`}
            </Tag>
        </Tooltip>
    )
})
