import React from 'react'
import { Menu } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { BaseTransactionType } from '@/types/transactions'
import { ActionButtons } from '@/pages/components'
import { TransactionsPageContainerProps } from '@/components/containers/transaction'
import translations from '@/translations'

type Options<T extends BaseTransactionType> = Pick<TransactionsPageContainerProps<object, T>, 'hideActions' | 'getItemActions'> & {
    openDrawer(item: T): void
}

export const addActionToColumns = <T extends BaseTransactionType>(
    columns: ColumnsType<T>,
    options: Options<T>
) => {
    if (options.hideActions) return columns
    
    return [
        ...columns,
        {
            title: translations().action.title,
            key: 'x',
            width: 200,
            render: (_, item: T) => {
                const menuItems: any[] = [
                    {
                        key: 'action',
                        label: (
                            <a onClick={() => options.openDrawer(item)}>
                                {translations().action.viewDetails}
                            </a>
                        )
                    }
                ]

                if (options.getItemActions) {
                    menuItems.push(...options.getItemActions(item))
                }

                return (
                    <ActionButtons
                        menu={<Menu items={menuItems} />}
                    />
                )
            }
        }
    ]
}
