import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    acceptPolicies: string
    setUpPayPalAccount: string
    warningMessage: string
    permissionNotGranted: string
    errors: {
        terminalNotFound: string
    }
}>()
