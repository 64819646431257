import translations from './translations'

translations.add('ro', {
    labels: {
        title: 'Procesare plată nouă',
        merchantCustomData: 'Date personalizate comerciant'
    },
    buttons: {
        ok: 'Ok'
    }
})
