import React from 'react'
import translations from './translations'

translations.add('ro', {
    name: {
        ON_EACH_LOGIN: (
            <>
                <strong>La fiecare autentificare:</strong> Vom solicita un cod de verificare
            </>
        ),
        TRUSTED_DEVICE: (
            <>
                <strong>O dată la fiecare dispozitiv:</strong> Vom solicita un cod de verificare
                doar o dată la fiecare 30 de zile pe un dispozitiv de încredere
            </>
        )
    },
    disabled: 'Dezactivat'
})
