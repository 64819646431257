import React from 'react'
import classNames from 'classnames'
import {Chart} from '@/components/dumb/chart/Chart/Chart'
import {BarChartProps} from '@/components/dumb/chart/BarChart/props'
import styles from './styles.scss'

/**
 *
 * @param className - a css class name
 * @param data - dataset that is used to display the chart
 * @param options - chart options
 * @param restOfTheProps - additional props that are passed down from parent (e.g. test-dataid etc.)
 * @constructor
 */
export const BarChart: React.FC<BarChartProps> = ({
        className,
        data,
        options ,
        ...restOfTheProps
}) => {
    const _options = {
        scales:{
            x: {
                grid:{
                    display: false
                }
            }
        },
        ...options
    }

    return <Chart
        className={classNames(styles.barChart, className)}
        type='bar'
        data={data}
        options={_options}
        {...restOfTheProps}
    />
}
