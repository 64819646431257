import translations from './translations'

translations.add('el', {
    buttons: {
        cancel: 'Άκυρο',
        continue: 'Συνέχεια'
    },
    description: n =>
        `Παρακαλούμε εισάγετε τον ${n}-ψήφιο κωδικό επαλήθευσης που δημιουργήθηκε από την εφαρμογή επαλήθευσης ταυτότητάς σας`
})
