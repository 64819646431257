import translations from './translations'
import React from 'react'

translations.add('en', {
    title: 'Open Banking',
    description: <>
        Open Banking is a secure way to receive payment for goods and services direct from the consumer&apos;s bank account.
        <br/><br/>
        By enabling Open Banking the consumers will have the option to choose their banking provider (from over 50+ financial institutions, a list which is growing all the time) making the payment in a few easy steps. The funds will be debited from the account instantly helping manage available balances more efficient.
        <br/><br/>
        DNA Payments are proud to have partnered with the leading supplier of Open Banking services, Ecospend, to make this facility available to our customer base.
        <br/><br/>
    </>
})
