import React from 'react'
import {Divider, Radio} from 'antd'
import {TableViewType} from '@/constants'
import {useInjection} from 'dna-react-ioc'
import {PageSizeSelect} from '@/components/dumb/Pagination'
import {PaginationContainerStoreType} from '@/components/containers'
import {Icon} from '@/components'
import {MobileTableControlsProps} from './props'
import styles from './styles.scss'

export const MobileTableControls: React.FC<MobileTableControlsProps> = ({
    setViewType,
    paginationInjectableID,
    extra
}) => {
    const store = useInjection<PaginationContainerStoreType>(paginationInjectableID)

    return (
        <div className={styles.tableParams}>
            <Radio.Group defaultValue={TableViewType.EXPANDED} onChange={setViewType}>
                <Radio.Button value={TableViewType.EXPANDED}>
                    <Icon type={'expanded'} />
                </Radio.Button>
                <Radio.Button value={TableViewType.SHORTED}>
                    <Icon type={'shorted'} />
                </Radio.Button>
            </Radio.Group>
            <div className={styles.controls}>
                <PageSizeSelect
                    onChange={store.setPageSize}
                    pageSize={store.paging.size}
                    total={store.total}
                />
                {extra && (
                    <>
                        <Divider type={'vertical'} />
                        {extra}
                    </>
                )}
            </div>
        </div>
    )
}
