import {createHostedFields} from 'hosted-fields'
import {FieldKeyType, FieldStateType} from 'hosted-fields/types'
import {StoreWithTerminalsType} from '@/stores/store-and-terminals'
import {OnlinePaymentStatus} from '@/constants'
import {
    HostedCardFieldsOptionsType,
    VTPaymentDataType,
    VTPaymentResultType,
    VTStoreType
} from '@/types/virtual-terminal'
import {TransactionType} from '@/types/transactions'
import {Terminal} from '@/pages/VirtualTerminal/models'
import translations from '../translations'

const isVirtualTerminal = (terminal: Terminal) => Boolean(terminal?.settings?.card?.allowMoto)

export const getStoreVirtualTerminal = (store: StoreWithTerminalsType): Terminal => {
    if (!store.isActive || !store.terminals || store.terminals.length === 0) return null

    const terminals = store.terminals.filter((t) => t.isActive && isVirtualTerminal(t))
    if (terminals.length === 0) return null
    if (terminals.length === 1) return terminals[0]

    const defaultTerminals = terminals.filter((t) => t.isDefault)
    if (defaultTerminals.length === 0) return terminals[0]
    return defaultTerminals[0]
}

export const getVirtualTerminals = (stores: StoreWithTerminalsType[]): VTStoreType[] => {
    return !stores
        ? []
        : stores
              .map((s) => ({...s, terminal: getStoreVirtualTerminal(s)}))
              .filter((s) => s.terminal)
              .map((s) => ({value: s.terminal.id, label: s.name, data: s.terminal}))
}

export const isVirtualTerminalAvailable = (stores: StoreWithTerminalsType[]) =>
    getVirtualTerminals(stores).length > 0

interface FormItemProps {
    help?: string
    validateStatus?: 'success' | 'error'
}
export const createGetterFormItemProps =
    (fieldsState: Partial<Record<FieldKeyType, FieldStateType>>) =>
    (fieldKey: FieldKeyType): FormItemProps => {
        const fieldState = fieldsState[fieldKey]
        if (!fieldState || !fieldState.isTouched) return {}
        const {error, isValid} = fieldState
        return {
            help: translations().errors[error?.code] || error?.message || '',
            validateStatus: isValid ? 'success' : 'error'
        }
    }

export const createContainer = (el: HTMLElement, className?: string) => {
    const div = document.createElement('div')
    if (className) {
        div.classList.add(className)
    }
    el.appendChild(div)
    return div
}

export const getVTPaymentStatus = (
    result: VTPaymentResultType,
    transactionType: TransactionType
) => {
    const {settled, success} = result
    if (!success) return OnlinePaymentStatus.reject
    if (transactionType === TransactionType.VERIFICATION) return OnlinePaymentStatus.verified
    if (transactionType === TransactionType.REFUND) return OnlinePaymentStatus.credited
    return settled ? OnlinePaymentStatus.charge : OnlinePaymentStatus.auth
}

export const getPaymentData = (paymentData: VTPaymentDataType) => {
    const {
        entryMode,
        invoiceId,
        currency,
        amount,
        description,
        terminalId,
        address,
        postalCode,
        recurring,
        transactionType
    } = paymentData

    return {
        entryMode,
        amount,
        currency,
        description,
        paymentSettings: {
            terminalId
        },
        customerDetails: {
            billingAddress: {
                country: 'GB',
                addressLine1: address,
                postalCode
            }
        },
        invoiceId,
        transactionType,
        ...(recurring ? {periodic: {periodicType: 'ucof'}} : {})
    }
}

export const createHostedCardFields = (accessToken: string, options: HostedCardFieldsOptionsType) =>
    createHostedFields({
        isTest: !_IS_PROD_,
        env: _IS_PROD_ ? 'prod' : 'test',
        accessToken,
        styles: {
            input: {
                'font-size': '14px',
                'font-family': 'Roboto',
                padding: '8px 11px',
                color: '#1D1737'
            },
            'input::-moz-placeholder': {
                color: '#959799',
                opacity: '1'
            },
            'input:-ms-input-placeholder': {
                color: '#959799'
            },
            'input::-webkit-input-placeholder': {
                color: '#959799'
            }
        },
        fontNames: ['Roboto'],
        fields: {
            cardholderName: {
                container: createContainer(options.cardName, options.className),
                placeholder: 'John'
            },
            cardNumber: {
                container: createContainer(options.cardNumber, options.className),
                placeholder: ''
            },
            expirationDate: {
                container: createContainer(options.cardExpDate, options.className),
                placeholder: ''
            },
            cvv: {
                container: createContainer(options.cardCvv, options.className),
                placeholder: ''
            }
        }
    })
