import translations from './translations'


translations.add('en', {
    amount: 'Amount',
    currency: 'Currency',
    errors: {
        amountRequired: 'Amount is required',
        amountInvalid: 'Amount must be greater than 0'
    }
})
