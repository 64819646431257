import React from 'react'
import {RouteObject} from 'react-router-dom'

import {Theme} from '@/constants'
import {EcospendReturn} from '@/pages/components/Ecospend/components'

import {AuthArea} from './AuthArea'
import {UnAuthArea} from './UnAuthArea'
import {ROUTES} from './routes'

import translations from '@/translations'
import {Maintenance} from '@/pages/Maintenance'

const Login = React.lazy(() => import(/* webpackChunkName: "Login" */ '@/pages/Login'))
const ResetPassword = React.lazy(
    () => import(/* webpackChunkName: "ResetPassword" */ '@/pages/ResetPassword/ResetPassword')
)
const ResetPasswordFailure = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ResetPasswordFailure" */ '@/pages/ResetPasswordFailure/ResetPasswordFailure'
        )
)
const TwoFA = React.lazy(() => import(/* webpackChunkName: "TwoFA" */ '@/pages/TwoFA/TwoFA'))
const RecoveryCode = React.lazy(
    () => import(/* webpackChunkName: "RecoveryCode" */ '@/pages/RecoveryCode/RecoveryCode')
)
const ChooseCompany = React.lazy(
    () => import(/* webpackChunkName: "ChooseMerchant" */ '@/pages/ChooseMerchant/ChooseMerchant')
)
const ConfirmEmail = React.lazy(
    () => import(/* webpackChunkName: "ConfirmEmail" */ '@/pages/ConfirmEmail')
)
const TwoFAConfirm = React.lazy(
    () => import(/* webpackChunkName: "TwoFAConfirm" */ '@/pages/TwoFAConfirm')
)
const TwoFAEnforced = React.lazy(
    () => import(/* webpackChunkName: "TwoFAEnforced" */ '@/pages/TwoFAEnforced')
)
const SetPassword = React.lazy(
    () => import(/* webpackChunkName: "SetPassword" */ '@/pages/SetPassword')
)
const SetPasswordSuccess = React.lazy(
    () => import(/* webpackChunkName: "SetPasswordSuccess" */ '@/pages/SetPasswordSuccess')
)
const Profile = React.lazy(() => import(/* webpackChunkName: "Profile" */ '@/pages/Profile'))
const Overview = React.lazy(() => import(/* webpackChunkName: "Overview" */ '@/pages/Overview'))
const PayByLink = React.lazy(() => import(/* webpackChunkName: "PayByLink" */ '@/pages/PayByLink'))
const Activation = React.lazy(
    () => import(/* webpackChunkName: "Activation" */ '@/pages/Activation')
)
const Reports = React.lazy(
    () => import(/* webpackChunkName: "Reports" */ '@/pages/Reports/Reports')
)
const Developers = React.lazy(
    () => import(/* webpackChunkName: "Developers" */ '@/pages/settings/Developers')
)
const Checkout = React.lazy(
    () => import(/* webpackChunkName: "Checkout" */ '@/pages/settings/Checkout')
)
const Tariffs = React.lazy(
    () => import(/* webpackChunkName: "Tariffs" */ '@/pages/settings/Tariffs')
)
const Chargebacks = React.lazy(
    () => import(/* webpackChunkName: "Chargebacks" */ '@/pages/Chargebacks')
)
const OnlinePayments = React.lazy(
    () => import(/* webpackChunkName: "OnlinePayments" */ '@/pages/OnlinePayments')
)
const POSPayments = React.lazy(
    () => import(/* webpackChunkName: "POSPayments" */ '~/code/pages/POSPayments')
)
const POSAmexPayments = React.lazy(
    () => import(/* webpackChunkName: "POSAmexPayments" */ '@/pages/POSAmexPayments')
)
const PaymentMethods = React.lazy(
    () => import(/* webpackChunkName: "PaymentMethods" */ '@/pages/payment-methods/PaymentMethods')
)
const VirtualTerminal = React.lazy(
    () => import(/* webpackChunkName: "VirtualTerminal" */ '@/pages/VirtualTerminal')
)
const TabbedPaymentMethods = React.lazy(
    () =>
        import(
            /* webpackChunkName: "TabbedPaymentMethods" */ '@/pages/payment-methods/TabbedPaymentMethods'
        )
)
const Invoices = React.lazy(
    () => import(/* webpackChunkName: "Invoices" */ '@/pages/Billing/pages/Invoices')
)
const Settlements = React.lazy(
    () => import(/* webpackChunkName: "Settlements" */ '@/pages/Settlements')
)
const AccountInfo = React.lazy(
    () => import(/* webpackChunkName: "AccountInfo" */ '@/pages/business-account/AccountInfo')
)
const CardsManagement = React.lazy(
    () =>
        import(/* webpackChunkName: "CardsManagement" */ '@/pages/business-account/CardsManagement')
)
const MakeTransfer = React.lazy(
    () => import(/* webpackChunkName: "MakeTransfer" */ '@/pages/business-account/MakeTransfer')
)
const TeamManagement = React.lazy(
    () => import(/* webpackChunkName: "TeamManagement" */ '@/pages/TeamManagement')
)
const Exports = React.lazy(() => import(/* webpackChunkName: "Exports" */ '@/pages/Exports'))
const NoPermissions = React.lazy(
    () => import(/* webpackChunkName: "NoPermissions" */ '@/pages/NoPermissions')
)
const NoPage = React.lazy(() => import(/* webpackChunkName: "NoPage" */ '@/pages/NoPage'))
const OnlineChat = React.lazy(
    () => import(/* webpackChunkName: "OnlineChat" */ '@/pages/OnlineChat')
)

export const routesMap: RouteObject[] = [
    {
        element: <AuthArea />,
        children: [
            {
                path: ROUTES.home,
                element: <Overview title={translations().pages.overview} />
            },
            {
                path: ROUTES.activation,
                element: <Activation title={translations().pages.activation} />
            },
            {
                path: ROUTES.paymentLinks,
                element: <PayByLink title={translations().pages.paymentLinks} />
            },
            {
                path: ROUTES.onlinePayments,
                element: <OnlinePayments title={translations().pages.onlinePayments} />
            },
            {
                path: ROUTES.chargebacks,
                element: <Chargebacks title={translations().pages.chargebacks} />
            },
            {
                path: ROUTES.posPayments,
                element: <POSPayments title={translations().pages.posPayments} />
            },
            {
                path: ROUTES.posAmexPayments,
                element: <POSAmexPayments title={translations().pages.posAmexPayments} />
            },
            {
                path: ROUTES.settlements,
                element: <Settlements title={translations().pages.settlements} />
            },
            {
                path: ROUTES.billing.invoices,
                element: <Invoices title={translations().pages.invoices} />
            },
            {
                path: ROUTES.virtualTerminal,
                element: <VirtualTerminal title={translations().pages.virtualTerminal} />
            },
            {
                path: ROUTES.reports,
                element: <Reports title={translations().pages.reports} />
            },
            {
                path: ROUTES.profile,
                element: <Profile title={translations().pages.profile} />
            },
            {
                path: ROUTES.exports,
                element: <Exports title={translations().pages.exports} />
            },
            {
                path: ROUTES.settings.developers,
                element: <Developers title={translations().pages.developers} />
            },
            {
                path: ROUTES.settings.checkout,
                element: <Checkout title={translations().pages.checkoutCustomization} />
            },
            {
                path: ROUTES.settings.tariffs,
                element: <Tariffs title={translations().pages.tariffs} />
            },
            {
                path: ROUTES.teamManagement,
                element: <TeamManagement title={translations().pages.teamManagement} />
            },
            {
                path: ROUTES.noPermissions,
                element: <NoPermissions title={translations().pages.noPermissions} />
            },
            {
                path: ROUTES.businessAccount.accountInfo,
                element: <AccountInfo title={translations().pages.accountInfo} />
            },
            {
                path: ROUTES.businessAccount.cardsManagement,
                element: <CardsManagement title={translations().pages.cardsManagement} />
            },
            {
                path: ROUTES.businessAccount.makeTransfer,
                element: <MakeTransfer title={translations().pages.makeTransfer} />
            },
            {
                path: ROUTES.ecospendReturn,
                element: <EcospendReturn title={translations().pages.ecospendReturn} />
            }
        ]
    },
    {
        element: <UnAuthArea />,
        children: [
            {
                path: ROUTES.login,
                element: <Login title={translations().pages.login} />
            },
            {
                path: ROUTES.resetPassword,
                element: <ResetPassword title={translations().pages.resetPassword} />
            },
            {
                path: ROUTES.resetPasswordFailure,
                element: <ResetPasswordFailure title={translations().pages.resetPassword} />
            },
            {
                path: ROUTES.twoFA,
                element: <TwoFA title={translations().pages.twoFA} />
            },
            {
                path: ROUTES.recoveryCode,
                element: <RecoveryCode title={translations().pages.recoveryCode} />
            },
            {
                path: ROUTES.chooseCompany,
                element: <ChooseCompany title={translations().pages.chooseCompany} />
            },
            {
                path: ROUTES.confirmEmail,
                element: <ConfirmEmail title={translations().pages.confirmEmail} />
            },
            {
                path: ROUTES.twoFAEnforced,
                element: <TwoFAEnforced title={translations().pages.twoFAEnforced} />
            },
            {
                path: ROUTES.confirmTwoFA,
                element: <TwoFAConfirm title={translations().pages.confirmTwoFA} />
            },
            {
                path: ROUTES.setPassword,
                element: <SetPassword title={translations().pages.setPassword} />
            },
            {
                path: ROUTES.setPasswordSuccess,
                element: <SetPasswordSuccess title={translations().pages.setPassword} />
            },
            {
                path: ROUTES.noPage,
                element: <NoPage title={translations().pages.noPage} />
            }
        ]
    },
    {
        element: <AuthArea theme={Theme.LIGHT} />,
        children: [
            {
                path: ROUTES.paymentMethods,
                element: <PaymentMethods title={translations().pages.paymentMethods} />
            },
            {
                path: ROUTES.paymentMethodDetails,
                element: <TabbedPaymentMethods title={translations().pages.paymentMethods} />
            }
        ]
    },
    {
        path: ROUTES.onlineChat,
        element: <OnlineChat title={translations().pages.onlineChat} />
    },
    {
        path: ROUTES.maintenance,
        element: <Maintenance />
    }
]
