import translations from './translations'

translations.add('en', {
    title: 'Reports',
    transactions: 'Transactions',
    transactionsComparison: 'Transactions comparison',
    calendarTitle: 'Last Month',
    paymentMethods: 'Payment methods',
    cardPayments: 'Card methods',
    issuingBanks: 'Issuing banks (card payments only)',
    amount: 'Amount',
    count: 'Number',
    volumeTotal: 'Volume total',
    previous: 'Previous',
    current: 'Current',
    downloadLink: 'Download',
    cardScheme: 'Card scheme',
    onlinePayments: 'Online payments',
    posPayments: 'POS payments',
    currency: 'Currency',
    noContentTitle: 'No content available',
    empty: 'Empty'
})
