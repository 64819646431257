import React from 'react'
import {Layout, Row, Skeleton} from 'antd'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {useInjection} from 'dna-react-ioc'
import {LoadingState} from '@/types'
import {useScreenType} from '@/hooks'
import {withTitle} from '@/router/utils'
import {CreditCard, PageHeader} from '@/components'
import {CardSettings} from './components'
import {CardsManagementStoreInterface} from './CardsManagementStoreInterface'
import translations from './translations'
import styles from './styles.scss'
import {CardType} from '@/stores'

export const CardsManagementStoreSymbol = Symbol('CardsManagementStore')

const CardsManagement: React.FC = observer(() => {
    const {cardsLoadingState, cards, currentCardToken, card, setCurrentCardToken} =
        useInjection<CardsManagementStoreInterface>(CardsManagementStoreSymbol)
    const {isMobile, isTablet, isLaptop} = useScreenType()

    return (
        <>
            <PageHeader title={translations().cardsManagement} />
            <Layout.Content className={styles.body}>
                <div
                    className={classNames(styles.management, {
                        [styles.managementMobile]: isMobile || isTablet || isLaptop
                    })}
                >
                    {(cardsLoadingState === LoadingState.LOADING ||
                        cardsLoadingState === LoadingState.IDLE) && <Skeleton active={true} />}
                    {cardsLoadingState !== LoadingState.LOADING && cards && card && (
                        <>
                            <Row>
                                {cards.map(c => (
                                    <CreditCard
                                        key={c.number}
                                        className={styles.creditCard}
                                        cardData={c}
                                        active={currentCardToken === c.token}
                                        isVirtual={c.type === CardType.VIRTUAL}
                                        onClick={() => setCurrentCardToken(c.token)}
                                    />
                                ))}
                            </Row>
                            <CardSettings cardData={card} />
                        </>
                    )}
                </div>
            </Layout.Content>
        </>
    )
})

export default withTitle(CardsManagement)
