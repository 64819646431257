import translations from './translations'

translations.add('en', {
    jobNameTypes: {
        'all': 'All',
        'merchant-statement-excel': 'Statement (Excel)',
        'merchant-statement-csv': 'Statement (CSV)',
        'pos-transactionsv2-csv': 'POS payments (CSV)',
        'pos-amex-transactions-csv': 'POS American Express (CSV)',
        'ecom-transactions-csv': 'Online payments (CSV)'
    }
})
