import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {
    TransactionsCardWithBarChart,
    TransactionsCardWithBarChartProps
} from '@/components/composite'
import {TTransactionsComparisonCardWithBarChartContainerStore} from '@/components/containers/TransactionsComparisonCardWithBarChartContainer/TTransactionsComparisonCardWithBarChartContainerStore'

export const TransactionsComparisonCardWithBarChartContainerStoreSymbol = Symbol(
    'TransactionsComparisonCardWithBarChartContainerStore'
)

export const TransactionsComparisonCardWithBarChartContainer = observer(
    (props: TransactionsCardWithBarChartProps) => {
        const {injectableIdentifier, ...rest} = props
        const store = useInjection<TTransactionsComparisonCardWithBarChartContainerStore>(
            injectableIdentifier || TransactionsComparisonCardWithBarChartContainerStoreSymbol
        )

        return (
            <TransactionsCardWithBarChart
                data={store.data.data}
                dataLoadingState={store.dataLoadingState}
                dataTypes={store.dataTypes}
                onDataTypeChange={store.setSelectedDataType}
                selectedStatus={store.selectedStatus}
                statuses={store.statuses}
                onStatusChange={store.setSelectedStatus}
                chartOptions={store.data.options}
                isCompare={true}
                {...rest}
            />
        )
    }
)
