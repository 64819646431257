export enum VerificationStatus {
    matched = 'Matched',
    notMatched = 'Not Matched',
    partialMatch = 'Partial Match',
    yes = 'Yes',
    no = 'No',
    authenticated = 'Authenticated',
    notAuthenticated = 'Not authenticated',
    unableToAuth = 'Unable to authenticate',
    attempted = 'Attempted',
    notAttempted = 'Not attempted',
    enrolled = 'Enrolled',
    notEnrolled = 'Not enrolled',
    challengeRequired = 'Challenge required',
    notProvided = 'Not Provided',
    notChecked = 'Not Checked',
    unableToCheck = 'Unable to check',
    notSet = 'Not Set',
    authRejected = 'Authentication rejected',
}
