import translations from './translations'

translations.add('ro', {
    linkDetails: 'Detalii link de plată',
    copyToClipboard: 'Copiază în clipboard',
    copied: 'Copiat',
    orderNumber: 'Număr de comandă:',
    amount: 'Sumă',
    customer: 'Client',
    dateCreated: 'Data creării',
    expirationDate: 'Dată expirare',
    viewedDate: 'Dată vizualizare',
    paidDate: 'Dată plată',
    cancelledDate: 'Dată anulare',
    attemptedDate: 'Dată încercare',
    description: 'Descriere',
    createdBy: 'Creat de'
})
