import translations from './translations'
import React from 'react'

translations.add('sl', {
    title: 'Klarna',
    description: (
        <>
            Spoločnosť DNA Payments je hrdým partnerom spoločnosti Klarna pri poskytovaní transakcií
            typu Buy Now Pay Later (BNPL). BNPL umožňuje spotrebiteľom rozložiť splátky na príslušný
            nákup bez platenia úrokov alebo dodatočných poplatkov.
            <br />
            <br />
            Keď to bude umožnené, spotrebitelia budú mať možnosť vybrať si Klarna BNPL počas procesu
            platby, pokiaľ to nie je výslovne zakázané v integračnej konfigurácii.
            <br />
            <br />
        </>
    )
})
