import translations from './translations'

translations.add('el', {
    title: 'Διαχείριση ομάδας',
    inviteTeammate: 'Προσθήκη χρήστη',
    role: 'Ρόλος',
    all: 'Ολα',
    active: 'Προσκλήθηκαν',
    inactive: 'Αποδέχθηκε πρόσκληση',
    statuses: {
        ok: 'Ενεργοί',
        invited: 'Προσκλήθηκαν',
        validateEmail: 'Επικύρωση email',
        changePassword: 'Αλλάξτε κωδικό',
        block: 'Μπλοκαρίστηκαν'
    },
    authMethods: {
        password: 'Κωδικός πρόσβασης',
        twoFAApp: '2FA (Εφαρμογή Επαλήθευσης)',
        twoFAEnforced: '2FA (Επιβεβλημένο)'
    },
    notifications: {
        userSuccessfullyInvited: 'Ο χρήστης προσκλήθηκε με επιτυχία'
    }
})
