import translations from './translations'

translations.add('en', {
    overviewDescription: 'Our Overview page offers a bird\'s eye view of your payment workflows, where you can view analytics as real-time charts, monitor and report on your payments.',
    posPaymentsDescription: 'Our Merchant Portal provides real-time updates for each POS Payment you process, where you can search and drill down your payments using the filter tools.',
    posAmexPaymentsDescription: 'Monitor your POS transaction processed via American Express, where you can search and drill down your payments using the filter tools.',
    onlinePaymentsDescriptions: 'Our Merchant Portal provides real-time updates for each Online Payment you process, where you can monitor and manage payments, perform refunds, or cancel a payment.',
    paymentLinksDescription: 'You’ve enabled Payments Links. Payment Links let you accept payments online without using a website, by creating and sending a Payment Link to your customer.',
    paymentLinksCreate: 'You can create and send a Payment Link to your customer using this button, and get paid online in real-time.',
    virtualTerminalDescription: 'Our Virtual Terminal lets you accept payments over the phone or by email so that you can accept payments remotely without using a website.',
    virtualTerminalDetails: 'Our Virtual Terminal lets you accept payments over the phone or by email so that you can accept payments remotely without using a website.',
    settlementsDescription: 'You can download, monitor, and search custom dates and parameters for your Settlements using the filters and search tools on this page.',
    settlementsDownload: 'To download your Settlements Statements, go to the “Download” button on the top right-hand side of the screen, and downloaded either as a PDF, Excel, or CSV file.',
    invoicesDescription: 'Keep track of fees from your Settlements on the Billing section, which shows a list of your Invoices from DNA Payments to download.',
    reportsDescription: 'Pull up reports and compare your payment statuses drilled down by search filter tools and view charts by transaction status or payment methods.',
    teamManagementDescription: 'Manage and set permissions for your team members within the Merchant Portal to streamline collaboration and control access with ease.',
    teamManagementAddUserDescription: 'You can easily invite new team members to access the Merchant Portal, either as an ADMIN or with restricted access.',
    paymentMethodsDescription: 'View and manage all of your alternative payment methods for Online Payments and Payments Links via our Merchant Portal all in the one place. ',
    exportDescription: 'Keep track of exported payment data CSV files of Online and POS Payments showing a list of your exported files and their status. ',
    thankYou: 'Thank you!',
    thankYouDescription: 'Thanks for choosing DNA Payments! Once again, please don\'t hesitate to contact us via our Online Chat if you\'ve any further queries or requirements.',
    skipTutorial: 'Skip tutorial'
})
