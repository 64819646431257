import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Domains, PaymentMethodDetails, TApplePayPaymentMethodStore} from '@/pages/payment-methods'
import {PaymentMethodsApplePayStoreSymbol} from '@/pages/payment-methods/PaymentMethods/PaymentMethods'
import {PaymentMethodType} from '@/constants'
import translations from './translations'

export const ApplePayPaymentMethod = observer(() => {
    const store = useInjection<TApplePayPaymentMethodStore>(PaymentMethodsApplePayStoreSymbol)

    return <>
        <PaymentMethodDetails
            title={translations().title}
            description={translations().description}
            paymentMethodType={PaymentMethodType.applepay}
            stores={store.stores}
        />
        <Domains/>
    </>
})
