import React from 'react'
import {Layout, Typography} from 'antd'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {useInjection} from 'dna-react-ioc'
import {Links, Cookies} from '@/components'
import {useScreenType} from '@/hooks'
import {TFooterStore} from './TFooterStore'
import translations from './translations'
import styles from './styles.scss'

export const FooterStoreSymbol = Symbol('FooterStore')

export const Footer = observer(() => {
    const {isMobile} = useScreenType()
    const store = useInjection<TFooterStore>(FooterStoreSymbol)
    const versionStr = _LAST_COMMIT_HASH_ || ''

    return (
        <Layout.Footer className={classNames(styles.footer, {[styles.footerMobile]: isMobile})}>
            <Cookies
                open={store.open}
                statisticsChecked={store.statisticsChecked}
                setStatisticsChecked={store.setStatisticsChecked}
                onSaveClick={store.onSaveClick}
                onEssentialClick={store.onEssentialClick}
                onAllAcceptClick={store.onAllAcceptClick}
            />
            <Links onCookiesClick={() => store.setOpen(true)}/>
            <div>{translations().copyright2}</div>
            {versionStr ? <Typography.Text className={styles.version} type={'secondary'}>{translations().version}{versionStr}</Typography.Text> : null}
        </Layout.Footer>
    )
})
