import {CaptureMethod} from '@/types/transactions'
import rootTranslations from '@/translations'

export const getPOSCaptureMethods = () =>
    [
        CaptureMethod.pos,
        CaptureMethod['pos-contactless'],
        CaptureMethod.stored,
        CaptureMethod.moto,
        CaptureMethod.recurring,
        CaptureMethod['pos-openbanking'],
        CaptureMethod['pos-alipay']
    ].map((key) => ({
        value: key,
        label: rootTranslations().constants.captureMethods[key]
    }))
