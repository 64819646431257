import translations from './translations'

translations.add('sl', {
    title: 'Pridať novú doménu',
    provideDomainWhereTheFile: 'Uveďte doménu, na ktorej bude súbor',
    inputTheTopLevelDomain:
        'Zadajte doménu najvyššej úrovne (napr. dnapayments.com) alebo subdoménu (napr. pay.dnapayments.com), pre ktorú chcete povoliť Apple Pay.',
    downloadDomainAssociationFile: 'Stiahnuť súbor priradenia domény',
    hostDownloadedDomainAssociationFile: 'Hosťovanie stiahnutého súboru priradenia domény',
    youNeedToHostVerificationFileYouDownloaded:
        'Overovací súbor, ktorý ste stiahli vyššie, budete musieť umiestniť vo svojej doméne na nasledujúcom mieste:',
    cancel: 'Zruš',
    add: 'Pridať',
    errors: {
        domainRequired: 'Vyžaduje sa doména'
    }
})
