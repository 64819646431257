import React from 'react'
import {Tooltip} from 'antd'
import {Button, Icon, Input} from '@/components'
import translations from './translations'
import styles from './styles.scss'
import {COPY_PAYMENT_LINK, PAYMENT_LINK_VALUE_IN_FIELD} from '@/constants/playwright-ids'

type Props = {
    url: string
}

export const CopyLink: React.FC<Props> = ({url}) => {
    return (
        <div className={styles.copyLink}>
            <Input className={styles.input} value={url} placeholder={'link'} data-test-id={PAYMENT_LINK_VALUE_IN_FIELD}/>
            <Tooltip title={translations().copied} placement={'top'} trigger={'click'}>
                <Button
                    className={styles.btn}
                    type={'primary'}
                    icon={<Icon className={styles.copy} type={'copy'}/>}
                    onClick={() => {
                        navigator.clipboard.writeText(url)
                    }}
                    data-test-id={COPY_PAYMENT_LINK}
                />
            </Tooltip>
        </div>
    )
}
