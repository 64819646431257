import translations from './translations'

translations.add('en', {
    buttons: {
        send: 'Send',
        cancel: 'Cancel',
        sendReceipt: 'Send receipt'
    },
    labels: {
        email: 'Email address'
    },
    errors: {
        emailInvalid: 'email is invalid',
        emailRequired: 'email is required'
    }
})
