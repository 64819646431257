import translations from './translations'

translations.add('el', {
    recipientName: 'Ονομα αποστολέα',
    recipientNumber: 'Τηλέφωνο',
    recipientsNumber: 'αριθμός τηλεφώνου',
    recipientEmail: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
    recipientsEmail: 'διεύθυνση email',
    errors: {
        recipientNumberRequired: 'Απαιτείται αριθμός παραλήπτη',
        recipientNumberInvalid: 'Το τηλέφωνο παραλήπτη δεν είναι έγκυρο',
        recipientEmailRequired: 'Απαιτείται email',
        recipientEmailInvalid: 'Το email δεν είναι έγκυρο',
        recipientNameRequired: 'Απαιτείται όνομα αποστολέα'
    }
})
