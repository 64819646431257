import _ from 'lodash'
import {MerchantTerminalType} from '@/stores/pay-by-link/models/MerchantTerminalType'
import {Currencies, TerminalType} from '@/constants'
import {MerchantTerminalSelectType} from '@/stores/pay-by-link/models/MerchantTerminalSelectType'
import {CreatePayLinkRequest} from '@/api'
import {StoreWithTerminalsType} from '@/stores/store-and-terminals'

export const getStoreTerminal = (store: StoreWithTerminalsType) => {
    if (!store.isActive || !store.terminals || store.terminals.length === 0) return null

    const activeTerminals = store.terminals.filter((t) => t.isActive)
    if (activeTerminals.length === 0) return null
    if (activeTerminals.length === 1) return activeTerminals[0]

    const defaultTerminals = activeTerminals.filter((t) => t.isDefault)
    if (defaultTerminals.length === 0) return activeTerminals[0]
    return defaultTerminals[0]
}

export const getEcomTerminalsSelectType = (stores: StoreWithTerminalsType[]) => {
    return stores
        ?.map((s) => ({...s, terminal: getStoreTerminal(s)}))
        .filter((s) => s.terminal && s.type === TerminalType.ECOM)
        .map((s) => ({value: s.terminal.id, label: s.name, data: getTerminalData(s.terminal)}))
}

export const getTerminalData = (terminal: MerchantTerminalType) => {
    const {settings, defaultCurrency, supportedCurrencies} = terminal
    return {
        ...settings,
        defaultCurrency: defaultCurrency || Currencies.GBP,
        supportedCurrencies: supportedCurrencies || [Currencies.GBP]
    }
}

export const findTerminalData = (
    terminalId: string,
    terminals: MerchantTerminalSelectType[] = []
) => {
    const terminal = terminals.find((t) => t.value === terminalId)
    return (
        (terminal && terminal.data) || {
            defaultCurrency: Currencies.GBP,
            supportedCurrencies: [Currencies.GBP]
        }
    )
}

export const formatCreatePayLinkRequest = (values: CreatePayLinkRequest, terminalId: string) => {
    const formattedValues = _.cloneDeep(values)

    formattedValues.currency = formattedValues.currency || Currencies.GBP
    formattedValues.amount = +formattedValues.amount

    if (!values.terminalId) {
        formattedValues.terminalId = terminalId
    }

    if (formattedValues.recurring) {
        delete formattedValues.recurring
        return {
            ...formattedValues,
            periodic: {
                periodicType: 'ucof'
            }
        }
    }

    delete formattedValues.recurring

    return formattedValues
}
