import translations from './translations'
import React from 'react'

translations.add('el', {
    title: 'Pay by Bank',
    description: <>
        Η DNA Payments είναι υπερήφανη που προσφέρει στους εμπόρους τη δυνατότητα οι καταναλωτές να πληρώνουν για αγαθά και υπηρεσίες απευθείας από τον τραπεζικό τους λογαριασμό μέσω της νέας υπηρεσίας Pay by Bank. Η υπηρεσία Pay by Bank είναι μια νέα επιλογή πληρωμών που επιτρέπει στους καταναλωτές να πληρώνουν χρησιμοποιώντας την τραπεζική εφαρμογή τους στο τηλέφωνό τους.
        <br/><br/>
        Έχει σχεδιαστεί για να διευκολύνει την ολοκλήρωση ηλεκτρονικών αγορών, ενώ χρησιμοποιεί όλη την ασφάλεια των υποστηριζόμενων τραπεζών. Το πιο σημαντικό είναι ότι η εφαρμογή Pay by Bank δεν είναι μια ξεχωριστή εφαρμογή για λήψη ή υπηρεσία για εγγραφή – λειτουργεί μέσω της τραπεζικής εφαρμογής που χρησιμοποιούν ήδη οι καταναλωτές'.
        <br/><br/>
    </>
})
