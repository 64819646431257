import React from 'react'
import moment from 'moment'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Col, Divider, Grid, Row, Tooltip, Typography} from 'antd'
import {PayByLinkCreateNewLinkStoreSymbol} from '@/pages/PayByLink'
import {Description, MobileBackButton} from '@/components'
import {
    LinkDetailsModalHeader,
    NewPaymentLinkWidgetPreview,
    ShareButtons,
    TNewPaymentLinkModalStore
} from '@/pages/components'
import {DATE_FORMAT, DATE_TIME_FORMAT, PayByLinkStatus} from '@/constants'
import {getAmountWithCurrentCurrency} from '@/utils'
import translations from './translations'
import {LinkDetailsModalViewProps} from './props'
import styles from './styles.scss'
import {COPIED_PAYMENT_LINK} from '@/constants/playwright-ids'

const {Title, Text} = Typography

export const LinkDetailsModalView: React.FC<LinkDetailsModalViewProps> = observer((
    {createdPaymentLink, onEmailBtnClick, onSmsBtnClick}
) => {
    const screens = Grid.useBreakpoint()
    const isMobile = screens.xs
    const store = useInjection<TNewPaymentLinkModalStore>(PayByLinkCreateNewLinkStoreSymbol)

    const mobileDataSource = [
        {
            key: translations().amount,
            label: translations().amount,
            value: createdPaymentLink.amount
        },
        {
            key: translations().customer,
            label: translations().customer,
            value: createdPaymentLink.customerName
        },
        {
            key: translations().dateCreated,
            label: translations().dateCreated,
            value: moment(createdPaymentLink.createdDate).format(DATE_FORMAT)
        },
        {
            key: translations().expirationDate,
            label: translations().expirationDate,
            value: moment(createdPaymentLink.expirationDate).format(DATE_TIME_FORMAT)
        },
        {
            key: translations().description,
            label: translations().description,
            value: createdPaymentLink.description
        }
    ]

    return (
        <>
            {isMobile && (
                <>
                    <MobileBackButton onCancel={store.closeModals}/>
                    <Title level={5}>{translations().linkDetails}</Title>
                    <div className={styles.mobileHeader}>
                        <Tooltip title={translations().copied} trigger={'click'}>
                            <Text
                                data-test-id={COPIED_PAYMENT_LINK}
                                className={styles.mobileTitle}
                                onClick={() => {
                                    navigator.clipboard.writeText(store.paymentLink.url)
                                }}
                                strong
                            >
                                {translations().copyToClipboard}
                            </Text>
                        </Tooltip>
                        <Divider className={styles.verticalDivider} type={'vertical'}/>
                        <ShareButtons
                            onEmailClick={onEmailBtnClick}
                            onSmsClick={onSmsBtnClick}
                        />
                    </div>
                    <Divider type={'horizontal'}/>
                </>
            )}
            <LinkDetailsModalHeader createdPaymentLink={createdPaymentLink}/>
            <Divider type={'horizontal'}/>
            {
                isMobile && (
                    <Description<string | number> dataSource={createdPaymentLink.initiatorEmail && createdPaymentLink.initiatorEmail.length ?
                        [
                            ...mobileDataSource,
                            {
                                key: translations().createdBy,
                                label: translations().createdBy,
                                value: createdPaymentLink.initiatorEmail
                            }
                        ] : mobileDataSource
                    }/>
                )
            }
            {
                !isMobile && (
                    <Row gutter={[32, 16]}>
                        <Col xs={24} md={12}>
                            <Row gutter={[16, 16]}>
                                <Col xs={12}>{translations().amount}</Col>
                                <Col xs={12}>{getAmountWithCurrentCurrency(createdPaymentLink.amount, createdPaymentLink.currency)}</Col>
                                <Col xs={12}>{translations().customer}</Col>
                                <Col xs={12}>{createdPaymentLink.customerName}</Col>
                                <Col xs={12}>{translations().dateCreated}</Col>
                                <Col xs={12}>{moment(createdPaymentLink.createdDate).format(DATE_TIME_FORMAT)}</Col>
                                {createdPaymentLink.expirationDate ? (
                                    <>
                                        <Col xs={12}>{translations().expirationDate}</Col>
                                        <Col xs={12}>{moment(createdPaymentLink.expirationDate).format(DATE_TIME_FORMAT)}</Col>
                                    </>
                                ) : null}
                                {createdPaymentLink.viewedDate && createdPaymentLink.status === PayByLinkStatus.viewed ? (
                                    <>
                                        <Col xs={12}>{translations().viewedDate}</Col>
                                        <Col xs={12}>{moment(createdPaymentLink.viewedDate).format(DATE_TIME_FORMAT)}</Col>
                                    </>
                                ) : null}
                                {createdPaymentLink.paidDate && createdPaymentLink.status === PayByLinkStatus.paid ? (
                                    <>
                                        <Col xs={12}>{translations().paidDate}</Col>
                                        <Col xs={12}>{moment(createdPaymentLink.paidDate).format(DATE_TIME_FORMAT)}</Col>
                                    </>
                                ) : null}
                                {createdPaymentLink.cancelledDate && createdPaymentLink.status === PayByLinkStatus.cancelled ? (
                                    <>
                                        <Col xs={12}>{translations().cancelledDate}</Col>
                                        <Col xs={12}>{moment(createdPaymentLink.cancelledDate).format(DATE_TIME_FORMAT)}</Col>
                                    </>
                                ) : null}
                                {createdPaymentLink.attemptedDate && createdPaymentLink.status === PayByLinkStatus.attempted ? (
                                    <>
                                        <Col xs={12}>{translations().attemptedDate}</Col>
                                        <Col xs={12}>{moment(createdPaymentLink.attemptedDate).format(DATE_TIME_FORMAT)}</Col>
                                    </>
                                ) : null}
                                <Col xs={12}>{translations().description}</Col>
                                <Col xs={12}>{createdPaymentLink.description}</Col>
                                {createdPaymentLink.initiatorEmail && createdPaymentLink.initiatorEmail.length ? (
                                    <>
                                        <Col xs={12}>{translations().createdBy}</Col>
                                        <Col xs={12}>{createdPaymentLink.initiatorEmail}</Col>
                                    </>
                                ): null}
                            </Row>
                        </Col>
                        <Col xs={24} md={12}>
                            <NewPaymentLinkWidgetPreview/>
                        </Col>
                    </Row>
                )
            }
        </>
    )
})
