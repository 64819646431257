import {post} from '@/api'
import {SetPasswordRequest} from '@/stores'
import {AuthType} from '@/stores/set-password/models'

export const fetchSetPassword = (data: SetPasswordRequest, code?: number, authType?: AuthType) =>
    post<void>('/oppapi/client/v2/password', data, getFetchSetPasswordHeaders(code, authType))

const getFetchSetPasswordHeaders = (
    code?: number,
    authType?: AuthType
): [string, string][] | undefined => {
    const headers: [string, string][] = []

    if (code) {
        headers.push(['code', `${code}`])
    }

    if (authType) {
        headers.push(['authType', authType])
    }

    return headers.length > 0 ? headers : undefined
}
