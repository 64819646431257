import React from 'react'
import {useInjection} from 'dna-react-ioc'
import {PayByLinkSendMessageStoreSymbol} from '@/pages/PayByLink'
import {ModalFormFooter} from '@/components'
import {SendMessageStore} from '@/stores'
import translations from './translations'
import {LinkShareFooterProps} from './props'

export const LinkShareFooter: React.FC<LinkShareFooterProps> = ({type, formId, form, closeModal}) => {
    const store = useInjection<SendMessageStore>(PayByLinkSendMessageStoreSymbol)
    const {isLoading, closeLinkDetailsModal} = store
    const okText = type === 'email' ? translations().okText('email') : translations().okText('SMS')

    const onCancel = () => {
        form.resetFields()
        closeLinkDetailsModal()
        closeModal()
    }

    return (
        <ModalFormFooter
            formId={formId}
            okText={okText}
            confirmLoading={isLoading}
            onCancel={onCancel}
        />
    )
}
