import React, {useState, useEffect} from 'react'
import classNames from 'classnames'
import {error} from 'dna-common'
import {Form, Space, Typography, Button} from 'antd'
import {FormItemVerificationCode} from '@/components'
import {SendVerificationCodeProps} from './props'

import translations from './translations'
import styles from './styles.scss'
import {CANCEL_TWO_FA_MODAL, SEND_VERIFICATION_CODE_BUTTON} from '@/constants/playwright-ids'

const {useForm} = Form

export const SendVerificationCode: React.FC<SendVerificationCodeProps> = props => {
    const {
        className,
        title,
        danger,
        length = 6,
        description = translations().description(length),
        submitButtonText = translations().buttons.continue,
        cancelButtonText = translations().buttons.cancel,
        fullWidthButtons = false,
        onSubmit,
        onCancel
    } = props

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [form] = useForm()

    useEffect(() => form.resetFields(), [onCancel])

    const submit = async values => {
        setIsLoading(true)
        try {
            await form.validateFields()
            await onSubmit(values)
        } catch (err) {
            error(err)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={classNames(className, styles.root)}>
            {title && <Typography.Title level={3}>{title}</Typography.Title>}

            <Typography.Text>{description}</Typography.Text>

            <Form form={form} onFinish={submit} className={styles.form}>
                <FormItemVerificationCode length={length} />

                <div
                    className={classNames(
                        {[styles.buttonsFull]: fullWidthButtons},
                        styles.buttonsHolder
                    )}
                >
                    <Space>
                        {onCancel && <Button onClick={onCancel} data-test-id={CANCEL_TWO_FA_MODAL}>{cancelButtonText}</Button>}
                        <Button
                            type='primary'
                            htmlType={'submit'}
                            disabled={isLoading}
                            danger={danger}
                            data-test-id={SEND_VERIFICATION_CODE_BUTTON}
                        >
                            {submitButtonText}
                        </Button>
                    </Space>
                </div>
            </Form>
        </div>
    )
}
