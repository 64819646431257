import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import { THasIoCIdentifier } from '@/types'
import {Modal} from '@/components'
import {Ecospend} from '../../Ecospend'
import {TEcospendStore} from '@/pages/components'
import translations from './translations'
import styles from './EcospendModal.scss'

export const EcospendModal = observer((props: THasIoCIdentifier) => {
    const store = useInjection<TEcospendStore>(props.injectableIdentifier)

    return <Modal
        title={translations().ecospendModalTitle}
        className={styles.ecospendModal}
        open={store.isEcospendModalVisible}
        onCancel={store.closeEcospendModal}
        footer={null}
    >
        <Ecospend injectableIdentifier={props.injectableIdentifier} />
    </Modal>
})
