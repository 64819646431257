import {FileType} from '@/constants'

export const GoogleManagerTagClassNames = {
    header: {
        createPaymentLink: 'create-payment-link-button',
        newPaymentLink: 'new-payment-link-button',
        mobileAppStoreAdSliderCloseIcon: 'mobile-app-store-ad-slider-close-icon',
        redirectToMobileAppStoreGetButton: 'redirect-to-mobile-app-store-get-button',
        redirectToMobileAppStoreGetTheAppButton: 'redirect-to-mobile-app-store-get-the-app-button'
    },
    settlements: {
        downloadButtons: (type: FileType) => {
            switch (type) {
                case FileType.PDF:
                    return 'settlements-download-pdf'
                case FileType.EXCEL:
                    return 'settlements-download-excel'
                case FileType.CSV:
                    return 'settlements-download-csv'
            }
        }
    },
    posPayments: {
        exportButton: 'pos-payments-export-button'
    },
    onlinePayments: {
        exportButton: 'online-payments-export-button'
    }
}
