import 'reflect-metadata'
import {injectable} from 'inversify'
import {action, makeObservable, observable} from 'mobx'
import {Tab} from '@/components/dumb/Tabs/props'
import {TRangePickerContainerStore, TTabsContainerStore} from '@/components'
import translations from '@/pages/Overview/translations'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'

@injectable()
export class OverviewTabsContainerStore implements TTabsContainerStore {
    tabs: Tab[]
    value: 'onlinePayments' | 'posPayments'
    _rangePickerStore: TRangePickerContainerStore

    constructor() {
        this.tabs = [
            hasPermissions([
                PermissionsMap.pos_payments.read,
                PermissionsMap.pos_payments.full
            ]) && {title: translations().posPayments, disabled: false, key: 'posPayments'},
            hasPermissions([
                PermissionsMap.online_payments.read,
                PermissionsMap.online_payments.full
            ]) && {title: translations().onlinePayments, disabled: false, key: 'onlinePayments'}
        ].filter((tab) => !!tab)

        this.value = hasPermissions([
            PermissionsMap.pos_payments.read,
            PermissionsMap.pos_payments.full
        ])
            ? 'posPayments'
            : 'onlinePayments'

        makeObservable(this, {
            value: observable,
            tabs: observable,
            setValue: action.bound
        })
    }

    public clear() {
        this.value = null
    }

    setValue(value) {
        this.value = value
    }

    public setTabs(tabs: Tab[]): void {
        this.tabs = tabs
    }
}
