import translations from './translations'

translations.add('el', {
    labels: {
        email: 'Ηλεκτρονική διεύθυνση',
        firstName: 'Ονομα',
        lastName: 'Επίθετο',
        store: 'Κατάστημα',
        status: 'Κατάσταση',
        authMethod: 'Μέθοδος Επαλήθευσης',
        active: 'Ενεργοί'
    }
})
