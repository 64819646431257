import translations from './translations'

translations.add('el', {
    successTitle: 'Συγχαρητήρια',
    successMessage: 'Η πληρωμή σας υποβλήθηκε με επιτυχία.',
    cancelMessage: 'Η πληρωμή σας ακυρώθηκε.',
    failMessage: 'Η πληρωμή σας απέτυχε.',
    rejectMessage: 'Η πληρωμή σας απορρίφθηκε.',
    transactionId: 'Αναγνωριστικό συναλλαγής',
    autoRedirectAfter: 'Θα ανακατευθυνθείτε αυτόματα στον ιστότοπο μετά από',
    seconds: 'δευτερόλεπτα',
    tryAgain: 'Προσπαθήστε ξανά',
    returnToBack: 'Επιστροφή στον ιστότοπο'
})
