import {getWithAuth, postWithAuth} from '@/api'
import {Currencies} from '@/constants'
import {ECOSPEND_RETURN_URL, EcospendBankResponseModel, EcospendRefundResponseModel, POSEcospendRefundResponseModel} from '@/stores/ecospend'

export const refundEcospendPayment = (paymentId: string, bankId: string, amount?: number) =>
    postWithAuth<EcospendRefundResponseModel>(`/oppapi/operation/${paymentId}/refund${(amount ? '?amount=' + amount : '')}`, {
        bankId,
        returnUrl: ECOSPEND_RETURN_URL
    })

export const refundEcospendPOSPayment = async (paymentId: string, bankId: string, amount: number, currency: Currencies, reference: string) => {
    const { result: posResult, ...rest } = await postWithAuth<POSEcospendRefundResponseModel>(`/oppapi/v1/ecospend/pos/payments/${paymentId}/refund`, {
        bankId,
        amount,
        currency,
        reference,
        returnUrl: ECOSPEND_RETURN_URL
    })

    const result: EcospendRefundResponseModel = posResult && {
        amount: posResult.amount,
        balance: undefined,
        bankUrl: posResult.payment_url,
        currency: posResult.currency,
        errorCode: 0,
        id: posResult.id,
        invoiceId: posResult.reference,
        rrn: posResult.id,
        status: posResult.status,
        success: true        
    }

    return { ...rest, result }
}

export const fetchEcospendBanks = () =>
    getWithAuth<EcospendBankResponseModel>('/oppapi/v1/ecospend/banks')
