import translations from './translations'

translations.add('sl', {
    title: 'Unregister domain',
    pleaseEnterReasonWhyUnregistering: 'Uveďte dôvod, prečo rušíte registráciu vybraných domén',
    unregister: 'Zrušiť registráciu',
    close: 'Zavri',
    errors: {
        reasonRequired: 'Požadovaný dôvod'
    }
})
