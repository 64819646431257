import {makeAutoObservable, runInAction} from 'mobx'
import {inject, injectable} from 'inversify'
import {
    OnlinePaymentsPaymentModalStoreSymbol,
    OnlinePaymentsStoreSymbol,
    TOnlinePaymentsStore,
    TPaymentOperationStore
} from '@/pages/OnlinePayments'
import {chargePayment, refundPayment, refundAstropay} from '@/api'
import {TModalContainerStore} from '@/components/containers'
import {openErrorNotification, openSuccessNotification} from '@/utils'
import {PaymentMethodType} from '@/constants'
import translations from '@/translations'
import {filterOperators} from '@/stores/transactions/OnlinePaymentsStore/OnlinePaymentsFilterButtonStore'
import {OnlineEcospendStoreSymbol, TEcospendStore} from '@/pages/components'

@injectable()
export class PaymentOperationStore implements TPaymentOperationStore {
    private readonly _paymentModal: TModalContainerStore
    private readonly _onlinePaymentsStore: TOnlinePaymentsStore
    private readonly _ecospendStore: TEcospendStore

    constructor(
        @inject(OnlinePaymentsPaymentModalStoreSymbol) paymentModal: TModalContainerStore,
        @inject(OnlinePaymentsStoreSymbol) onlinePaymentsStore: TOnlinePaymentsStore,
        @inject(OnlineEcospendStoreSymbol) ecospendStore: TEcospendStore
    ) {
        this._paymentModal = paymentModal
        this._onlinePaymentsStore = onlinePaymentsStore
        this._ecospendStore = ecospendStore

        makeAutoObservable(this)
    }

    public isLoading = false

    public loadCharge = async (paymentId: string, amount): Promise<void> => {
        if (this.isLoading) {
            return
        }

        runInAction(() => {
            this.isLoading = true
        })

        try {
            const {error} = await chargePayment(paymentId, amount)

            runInAction(() => {
                this.isLoading = false

                if (error) {
                    return openErrorNotification(error?.message)
                }

                this.onPaymentModalCancel()
                this.loadTransactionById(
                    this._onlinePaymentsStore.selectedTransaction.id,
                    filterOperators.in,
                    false
                )

                return openSuccessNotification(translations().success.paymentChargedSuccessfully)
            })
        } catch (error) {
            return openErrorNotification(translations().errors.general)
        }
    }

    public loadRefund = async (paymentId: string, amount: number): Promise<void> => {
        if (this.isLoading) {
            return
        }
        runInAction(() => {
            this.isLoading = true
        })

        try {
            const {result, error} = await (() => {
                switch (this._onlinePaymentsStore.selectedTransaction.paymentMethod) {
                    case PaymentMethodType.astropay:
                        return refundAstropay(paymentId, amount)
                    default:
                        return refundPayment(paymentId, amount)
                }
            })()

            await runInAction(async () => {
                if (error) {
                    return openErrorNotification(error?.message)
                }

                this.onPaymentModalCancel()
                this._onlinePaymentsStore.loadTransactions().then(() => {
                    this.loadTransactionById(result.id, filterOperators.in, true)
                })
                if (this._onlinePaymentsStore.selectedTransaction.balance === amount) {
                    return openSuccessNotification(
                        translations().success.paymentRefundedFullySuccessfully
                    )
                }
                return openSuccessNotification(translations().success.paymentRefundedSuccessfully)
            })
        } catch (error) {
            return openErrorNotification(translations().errors.general)
        } finally {
            runInAction(() => {
                this.isLoading = false
            })
        }
    }

    public openEcospendRefundModal = (id: string, amount: number) => {
        this._ecospendStore.refund(id, amount)
        this._ecospendStore.loadBanks()
        this._paymentModal.onCancel()
    }

    loadTransactionById = async (
        transactionId: string,
        filterBy: string,
        loadByCurrentDate: boolean
    ) => {
        await this._onlinePaymentsStore.loadTransactionsById(
            transactionId,
            filterBy,
            loadByCurrentDate
        )
    }

    public onPaymentModalCancel = () => {
        this._paymentModal.onCancel()
    }
}
