export const USER_GUIDE_SELECTORS = {
    overview: 'overview',
    posPayments: 'pos-payments',
    posAmexPayments: 'pos-amex-payments',
    onlinePayments: 'online-payments',
    chargebacks: 'chargebacks',
    paymentLinks: 'payment-links',
    createNewPaymentLink: 'new-payment-link-button',
    virtualTerminal: 'virtual-terminal',
    virtualTerminalDetails: 'virtual-terminal-details',
    settlements: 'settlements',
    invoices: 'invoices',
    settlementsDownload: 'settlements-download',
    teamManagement: 'team-management',
    teamManagementButton: 'team-management-button',
    reports: 'reports',
    paymentMethods: 'payment-methods',
    exports: 'exports'
}
