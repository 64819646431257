import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    back: string
    title: string
    newPassword: string
    confirmPassword: string
    errors: {
        newPasswordRequired: string
        newPasswordInvalid: string
        confirmPasswordInvalid: string
        confirmPasswordRequired: string
    }
    submit: string
    codeMustContain: string
    codeRestrictions: {
        atLeastEightCharacters: string
        atLeastOneDigit: string
        atLeastOneSpecialCharacter: string
        atLeastOneUppercaseLetter: string
        atLeastOneLowercaseLetter: string
    }
}>()
