import translations from './translations'

translations.add('es', {
    showAll: 'Mostrar todo',
    dataTypes: {
        amount: 'Importe',
        count: 'Número'
    },
    week: 'Semana',
    previousRangeData: 'Anterior',
    currentRangeData: 'Actual'
})
