import translations from './translations'

translations.add('sl', {
    type: 'Type',
    code: 'Code',
    channel: 'Kanál',
    merchant: 'Merchant',
    user: 'User',
    reference: 'Referencia',
    showHidden: 'Show hidden',
    account: 'Account',
    balance: 'Zostatok',
    debitCredit: {
        CREDIT: 'Credit',
        DEBIT: 'Debit'
    },
    transactionTypes: {
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        payment: 'Payment',
        transfer: 'Transfer'
    },
    transactionDirection: 'Transaction direction',
    loadAfter: 'Load after',
    yes: 'Áno',
    no: 'Nie',
    all: 'Všetko'
})
