import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {Alert, Button, Card, Checkbox} from 'antd'

import {PaypalSvg} from '~/assets/icons'

import translations from './translations'
import {PayPalOnboardingProps} from './props'
import styles from './PaypalOnboarding.scss'

export const PayPalOnboarding: React.FC<PayPalOnboardingProps> = observer(
    ({actionUrl, isPoliciesAccepted, setPoliciesAccepted}) => {
        return actionUrl ? (
            <>
                <br />
                <Card>
                    <Checkbox
                        value={isPoliciesAccepted}
                        className={styles.checkbox}
                        onChange={e => setPoliciesAccepted(e.target.checked)}
                    >
                        {translations().acceptPolicies}
                    </Checkbox>
                    <br />
                    <Button
                        className={classNames(styles.payPalButton, {
                            [styles.payPalButtonDisabled]: !isPoliciesAccepted
                        })}
                        data-paypal-button='true'
                        disabled={!isPoliciesAccepted}
                        target='PPFrame'
                        href={`${actionUrl}&displayMode=minibrowser`}
                        type='primary'
                        icon={<PaypalSvg />}
                    >
                        {translations().setUpPayPalAccount}
                    </Button>
                </Card>
                <br />
                <Alert message={translations().warningMessage} type='warning' showIcon />
                <br />
            </>
        ) : null
    }
)
