export enum TransactionType {
  REFUND = 'REFUND',
  ADJUSTMENT = 'ADJUSTMENT',
  OTHER = 'OTHER',
  PAYBYLINK = 'PAYBYLINK',
  RETAIL = 'RETAIL',
  SALE = 'SALE',
  INIT = 'INIT',
  VERIFICATION = 'VERIFICATION',
  PREAUTH = 'PRE-AUTH',
  TOKENIZATION = 'TOKENIZATION',
  P2P = 'P2P',
  RECURRING = 'RECURRING',
  AUTH = 'AUTH'
}
