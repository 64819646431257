import {inject, injectable} from 'inversify'
import {makeAutoObservable, toJS} from 'mobx'
import {SelectItem} from '@/components/dumb/Select'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol
} from '@/stores/store-and-terminals'
import {TOnlinePaymentsFilterButtonStore} from '@/pages/OnlinePayments/components/OnlinePaymentsFilterButton/TOnlinePaymentsFilterButtonStore'
import {OnlinePaymentsPaginationStoreSymbol} from '@/pages/OnlinePayments'
import rootTranslations from '@/translations'
import {getPortalCurrencies, PaymentMethodType, portalOnlinePaymentsCardSchemes} from '@/constants'
import {removeFirstOrLastPercent} from '@/utils'
import translations from '../translations'
import {FormInstance} from 'antd'
import {PaginationContainerStoreType} from '@/components'
import {OnlinePaymentsFilterModel} from '@/stores/transactions/OnlinePaymentsStore/OnlinePaymentsFilterButtonStore/models'

const doubleKeysFilter = ['amount', 'card_mask', 'donation_amount']

const adjustFilterValueToOperator = (operator, value) => {
    if (operator === filterOperators.startsWith || operator === filterOperators.endsWith) {
        return `%${value}%`
    }
    return value
}

export const filterOperators = {
    startsWith: 'like',
    endsWith: 'like',
    between: 'between',
    in: 'in',
    less: '<',
    greater: '>',
    lessOrEqual: '<=',
    greaterOrEqual: '>=',
    equals: '='
}

export type FilterParam = {
    operator
    value
}

export type OnlinePaymentsFilterFormParams = {
    shop: FilterParam
    payer_name: FilterParam
    invoice_id: FilterParam
    description: FilterParam
    post_link_status: FilterParam
    account_id: FilterParam
    payer_email: FilterParam
    reference: FilterParam
    amount: {
        from: FilterParam
        to: FilterParam
    }
    card_mask: {
        from: FilterParam
        to: FilterParam
    }
    card_type_id: FilterParam
    donation_amount: {
        from: FilterParam
        to: FilterParam
    }
    payment_method: FilterParam
    currency_id: FilterParam
    payout_date: FilterParam
    payout_amount: {
        from: FilterParam
        to: FilterParam
    }
}

export const onlinePaymentsFilterParamsWithValues: OnlinePaymentsFilterFormParams = {
    shop: {
        operator: filterOperators.equals,
        value: undefined
    },
    payer_name: {
        operator: filterOperators.startsWith,
        value: undefined
    },
    invoice_id: {
        operator: filterOperators.startsWith,
        value: undefined
    },
    description: {
        operator: filterOperators.startsWith,
        value: undefined
    },
    post_link_status: {
        operator: filterOperators.equals,
        value: undefined
    },
    account_id: {
        operator: filterOperators.equals,
        value: undefined
    },
    payer_email: {
        operator: filterOperators.startsWith,
        value: undefined
    },
    reference: {
        operator: filterOperators.equals,
        value: undefined
    },
    amount: {
        from: {
            operator: filterOperators.greaterOrEqual,
            value: undefined
        },
        to: {
            operator: filterOperators.lessOrEqual,
            value: undefined
        }
    },
    card_mask: {
        from: {
            operator: filterOperators.startsWith,
            value: undefined
        },
        to: {
            operator: filterOperators.endsWith,
            value: undefined
        }
    },
    card_type_id: {
        operator: filterOperators.in,
        value: undefined
    },
    donation_amount: {
        from: {
            operator: filterOperators.greaterOrEqual,
            value: undefined
        },
        to: {
            operator: filterOperators.lessOrEqual,
            value: undefined
        }
    },
    payment_method: {
        operator: filterOperators.equals,
        value: undefined
    },
    currency_id: {
        operator: filterOperators.equals,
        value: undefined
    },
    payout_date: {
        operator: filterOperators.between,
        value: undefined
    },
    payout_amount: {
        from: {
            operator: filterOperators.greaterOrEqual,
            value: undefined
        },
        to: {
            operator: filterOperators.lessOrEqual,
            value: undefined
        }
    }
}

@injectable()
export class OnlinePaymentsFilterButtonStore implements TOnlinePaymentsFilterButtonStore {
    private readonly _merchantStoresStore: AvailableStoresDataStore
    readonly _paginationStore: PaginationContainerStoreType

    constructor(
        @inject(AvailableStoresDataStoreSymbol) merchantStoresStore: AvailableStoresDataStore,
        @inject(OnlinePaymentsPaginationStoreSymbol) paginationStore: PaginationContainerStoreType
    ) {
        this._merchantStoresStore = merchantStoresStore
        this._paginationStore = paginationStore

        makeAutoObservable(this)
    }

    count = 0
    open = false
    initialValues: Partial<OnlinePaymentsFilterModel> = {
        payment_method: '',
        shop: '',
        currency_id: ''
    }
    searchParameters = []
    _formValues = this.initialValues
    form: FormInstance = null

    get postLinkStatuses() {
        return [
            {value: '', label: translations().postLinkStatus.all},
            {value: true, label: translations().postLinkStatus.ok},
            {value: false, label: translations().postLinkStatus.failed}
        ]
    }

    get paymentMethods(): SelectItem[] {
        return [
            {value: '', label: translations().postLinkStatus.all},
            ...Object.keys(PaymentMethodType).map((key) => ({
                value: key,
                label: rootTranslations().constants.paymentMethodType[key]
            }))
        ]
    }

    get cardSchemes(): SelectItem[] {
        return portalOnlinePaymentsCardSchemes
    }

    get currencies(): SelectItem[] {
        return [{label: translations().all, value: ''}, ...getPortalCurrencies]
    }

    get stores(): SelectItem[] {
        const selectStores = this._merchantStoresStore.ecomStores?.map((store) => ({
            label: store.name,
            value: store.name
        }))
        if (selectStores) {
            return [{label: translations().all, value: ''}, ...selectStores]
        } else {
            return [{label: translations().all, value: ''}]
        }
    }

    get storeIDs(): string[] {
        return this.formValues.shop
            ? this._merchantStoresStore.ecomStores
                  ?.filter((store) => store.name === this.formValues.shop)
                  .map((store) => store.id)
            : undefined
    }

    get formValues() {
        return this._formValues
    }

    prepareSearchParameters = () => {
        this.searchParameters = []

        for (const key in this.formValues) {
            this.extractSearchParameter(key, this.formValues[key])
        }

        this.count = this.searchParameters.length
        this._paginationStore.setPageNumber(1)
        this.setOpen(false)
    }

    extractSearchParameter(key, value) {
        const paramInfo = onlinePaymentsFilterParamsWithValues[key]

        if (paramInfo.operator === filterOperators.between) {
            if (value && value.length > 1 && value[0] && value[1]) {
                this.searchParameters.push({
                    name: key,
                    method: filterOperators.between,
                    searchParameter: [...value]
                })
            }
            return
        }

        if (typeof value === 'object' && !Array.isArray(value)) {
            if (value.from) {
                this.extractSearchParameterForFrom(key, paramInfo.from, value.from)
            }
            if (value.to) {
                this.extractSearchParameterForTo(key, paramInfo.to, value.to)
            }
            return
        }

        if (value !== undefined && value !== null && value !== '') {
            value = adjustFilterValueToOperator(paramInfo.operator, value)
            this.searchParameters.push({
                name: key,
                method: paramInfo.operator,
                searchParameter: Array.isArray(value) ? [...value] : [value]
            })
        }
    }

    extractSearchParameterForFrom(key, fromInfo, fromValue) {
        if (fromInfo.operator === filterOperators.startsWith) {
            this.searchParameters.push({
                name: key,
                method: fromInfo.operator,
                searchParameter: [`${fromValue}%`]
            })
        } else {
            this.searchParameters.push({
                name: key,
                method: fromInfo.operator,
                searchParameter: [+fromValue]
            })
        }
    }

    extractSearchParameterForTo(key, toInfo, toValue) {
        if (toInfo.operator === filterOperators.endsWith) {
            this.searchParameters.push({
                name: key,
                method: toInfo.operator,
                searchParameter: [`%${toValue}`]
            })
        } else {
            this.searchParameters.push({
                name: key,
                method: toInfo.operator,
                searchParameter: [+toValue]
            })
        }
    }

    onFinish = () => {
        this.prepareSearchParameters()

        this.count = this.searchParameters.length
        this._paginationStore.setPageNumber(1)
        this.setOpen(false)
    }

    clearFilters = () => {
        this.searchParameters = []
        this.count = 0
        this._formValues = this.initialValues
        this.setOpen(false)
    }

    resetPagination = () => {
        this._paginationStore.setPageNumber(1)
    }

    onFilterClose = () => {
        this.setOpen(false)
    }

    openFilter = () => {
        this.setOpen(true)
    }

    setOpen = (open: boolean) => {
        this.open = open
    }

    setSearchParameters(searchParameters): void {
        this.searchParameters = searchParameters
        this.setSearchParametersToFormValues(searchParameters)
        this.count = searchParameters.length
    }

    setSearchParametersToFormValues = (searchParameters) => {
        const values = {}
        searchParameters.forEach((item) => {
            values[item.name] = this.getInitialValueFromSearchParameters(item.name)
        })

        toJS(values)

        this.onFormValuesChange(values)
    }

    getInitialValueFromSearchParameters(key: string) {
        const [first, second] = this.searchParameters.filter((item) => item.name === key)
        const [firstValue] = first && first.searchParameter

        if (doubleKeysFilter.includes(key)) {
            return {
                from:
                    typeof firstValue === 'string'
                        ? removeFirstOrLastPercent(firstValue)
                        : firstValue,
                to:
                    typeof second?.searchParameter[0] === 'string'
                        ? removeFirstOrLastPercent(second?.searchParameter[0])
                        : second?.searchParameter[1]
            }
        } else {
            return typeof firstValue === 'string'
                ? removeFirstOrLastPercent(firstValue)
                : firstValue
        }
    }

    onFormValuesChange = (values, callback?): void => {
        this._formValues = values

        if (callback) {
            callback()
        }
    }

    setForm = (form: FormInstance): void => {
        this.form = form
    }

    clearStore = () => {
        this.form?.resetFields()
        this.onFormValuesChange({})
        this.setSearchParameters([])
        this.count = 0
    }
}
