import translations from './translations'

translations.add('ro', {
    recipientName: 'Nume expeditor',
    recipientNumber: 'Număr de telefon',
    recipientsNumber: 'Număr de telefon \'s',
    recipientEmail: 'Adresă de email',
    recipientsEmail: 'Adresă de email \'s',
    errors: {
        recipientNumberRequired: 'Numărul destinatarului este obligatoriu',
        recipientNumberInvalid: 'Numărul de telefon al destinatarului nu este valid',
        recipientEmailRequired: 'Adresa de email este obligatorie',
        recipientEmailInvalid: 'Adresa de email nu este validă',
        recipientNameRequired: 'Numele expeditorului este obligatoriu'
    }
})
