import React from 'react'
import classNames from 'classnames'
import {Pagination as AntPagination, Typography} from 'antd'
import {PageSizeSelect} from '@/components/dumb/Pagination/index'
import {Icon} from '@/components'
import translations from './translations'
import {PaginationProps} from './props'
import styles from './styles.scss'
import {useScreenType} from '@/hooks'
import {NEXT_PAGE, PREVIOUS_PAGE} from '@/constants/playwright-ids'

const {Text} = Typography

export const Pagination: React.FC<PaginationProps> = (
    {className, current,
        pageSize, total, hideSizeChanger,
        onPageSizeChange, ...rest}
) => {

    const {isMobile, isTablet} = useScreenType()
    const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
        if (type === 'prev') {
            return <Text className={styles.actions} data-test-id={PREVIOUS_PAGE}><Icon type={'back_arrow_with_tail'}/>{translations().previous}</Text>
        }
        if (type === 'next') {
            return <Text className={styles.actions} data-test-id={NEXT_PAGE}>{translations().next}<Icon type={'next'}/></Text>
        }

        return originalElement
    }

    return <div className={styles.paginationContainer}>
        {
            !hideSizeChanger &&
            <PageSizeSelect onChange={onPageSizeChange} pageSize={pageSize} total={total}/>
        }
        <AntPagination
            {...rest}
            total={total}
            current={current}
            pageSize={pageSize}
            className={classNames(styles.pagination, className)}
            itemRender={pageSize < total ? itemRender : () => <div>1</div>}
            showSizeChanger={false}
            showLessItems={isMobile || isTablet}
            hideOnSinglePage
        />
    </div>
}
