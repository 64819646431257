import translations from './translations'

translations.add('ro', {
    charge: 'Încărcare',
    refund: 'Rambursare',
    cancel: 'Anulare',
    paymentCharge: 'Încasare plată',
    paymentRefund: 'Restituire plată',
    paymentAmount: 'Sumă plată',
    balance: 'Sold',
    chargeAmount: 'Sumă încasare',
    refundAmount: 'Sumă restituire',
    pending: 'În așteptare',
    errors: {
        positiveAmount: 'Suma de restituire trebuie să fie un număr pozitiv',
        amountRefundExceed: 'Suma de restituire trebuie să fie un număr pozitiv, nu mai mare decât soldul',
        amountChargeExceed: 'Suma de încasare trebuie să fie un număr pozitiv, nu mai mare decât suma plății'
    }
})
