import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    labels: {
        title: string
        merchantCustomData: string
    }
    buttons: {
        ok: string
    }
}>()

