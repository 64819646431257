import React, {useEffect} from 'react'
import {Form} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {useSearchParams} from 'react-router-dom'
import {Buffer} from 'buffer'

import {withTitle} from '@/router/utils'
import {LoadingState} from '@/types'
import {BackToLoginLink, Button, Card, Input} from '@/components'
import {TResetPasswordStore} from '@/pages/ResetPassword'
import translations from './translations'
import {EMAIL_TO_RESET, SUBMIT_RESET} from '@/constants/playwright-ids'
import styles from './styles.scss'

export const ResetPasswordStoreSymbol = Symbol('ResetPasswordStore')

const ResetPassword = observer(() => {
    const [form] = Form.useForm()
    const store = useInjection<TResetPasswordStore>(ResetPasswordStoreSymbol)

    const [queryParams] = useSearchParams()
    const t = queryParams.get('t') || ''

    useEffect(() => {
        let email = null

        if (!t) {
            return
        }

        try {
            email = t ? Buffer.from(t, 'base64').toString() : null
            store.sendInstructions({email: email?.toLowerCase()})
        } catch (error) {
            const errorMessage = translations().errorOnEmail(error.message)
            store.navigateToPasswordFailure(errorMessage)
            return
        }
    }, [t])

    const onFinish = values => {
        values.email = values.email?.toLowerCase()
        store.sendInstructions(values)
    }

    const formId = 'reset-password'

    return t ? null : (
        <>
            <div className={styles.resetCard}>
                <BackToLoginLink/>
                <Card title={translations().title}>
                    <div>{translations().text}</div>
                    <br />
                    <Form
                        form={form}
                        id={formId}
                        layout='vertical'
                        requiredMark={false}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label={translations().email}
                            name='email'
                            rules={[
                                {required: true, message: translations().emailRequired},
                                {
                                    type: 'email',
                                    message: translations().emailInvalid
                                }
                            ]}
                        >
                            <Input placeholder={translations().email} data-test-id={EMAIL_TO_RESET} />
                        </Form.Item>
                        <Form.Item key={'submit'}>
                            <Button
                                form={formId}
                                type={'primary'}
                                htmlType={'submit'}
                                className={styles.submit}
                                loading={store.resetPasswordLoadingState === LoadingState.LOADING}
                                data-test-id={SUBMIT_RESET}
                            >
                                {translations().submit}
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </>
    )
})

export default withTitle(ResetPassword)
