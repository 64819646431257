import React from 'react'
import { Input as AntdInput } from 'antd'
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons'
import {InputProps} from '@/components/dumb/Input/props'

// eslint-disable-next-line react/display-name
export const InputPassword = React.forwardRef<never, InputProps>((props, ref) => {
    const { className, ...rest } = props
    return <AntdInput.Password
        ref={ref} {...rest}
        className={className}
        iconRender={open => (open ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
    />
})
