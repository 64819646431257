import React from 'react'
import {observer} from 'mobx-react'
import {subscribe} from 'dna-react-ioc'

import {TerminalType} from '@/constants'

import {TSelectContainerStore} from '@/components/containers'
import {SelectItemWithTwoLabels} from '@/components/dumb/SelectWithTwoLabels/models'
import {SelectWithTitle, SelectWithTitleAndTwoLabel} from '@/components/dumb/SelectWithTitle'
import {SelectWithTitleProps} from '@/components/dumb/SelectWithTitle/props'
import {StoreSelectContainerProps} from './props'
import styles from './styles.scss'
import rootTranslations from '@/translations'

export const StoreSelectContainer: React.FC<StoreSelectContainerProps> = observer((props) => {
    const {className, type, ecomInjectableIdentifier, posInjectableIdentifier} = props
    const sEcom = subscribe<TSelectContainerStore>(ecomInjectableIdentifier)
    const sPos = subscribe<TSelectContainerStore<SelectItemWithTwoLabels>>(posInjectableIdentifier)
    const s = type === TerminalType.ECOM ? sEcom : sPos

    /* if stores are empty or only one store we not display dropdown */
    if (!s.options.length || s.options.length === 1) {
        return null
    }

    const selectProps: Omit<SelectWithTitleProps, 'options'> = {
        displayValue: rootTranslations().fields.store,
        placeholder: rootTranslations().fields.store,
        onChange: (v) => s.setValue(v),
        className,
        value: s.value,
        isMobile: false,
        popupClassName: styles.dropdown,
        displayValueWidth: 150
    }

    return type === TerminalType.ECOM ? (
        <SelectWithTitle {...selectProps} options={sEcom.options} />
    ) : (
        <SelectWithTitleAndTwoLabel
            {...selectProps}
            optionsWithTwoLabels={sPos.options}
            getDisplayValue={(value) => sPos.options.find((el) => el.value === value)?.labelPrimary}
        />
    )
})
