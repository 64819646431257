import React, {useEffect, useRef} from 'react'
import classNames from 'classnames'
import { Chart as ChartJSChart, registerables } from 'chart.js'
import { ChartProps } from './props'
import styles from './styles.scss'
import {CHART_BLOCK, CHART_INFO} from '@/constants/playwright-ids'

/**
 * A base chart component that does the basic stuff like registering and drawing on a canvas
 * It does not have any predefined type, the type is passed as a property
 * @param className - a css class name
 * @param type - type of the chart (bar, pie, doughnut etc.)
 * @param data - dataset that is used to display the chart
 * @param options - chart options
 * @param plugins
 * @param width - width of the chart
 * @constructor
 */
export const Chart: React.FC<ChartProps> = (
    { id, className, type, data,
        options, plugins, ...restOfTheProps}
) => {
    const chartRef = useRef(null)

    useEffect(() => {
        ChartJSChart.register(...registerables)
    }, [])

    useEffect(() => {
        let chart
        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d')

            chart = new ChartJSChart(ctx, {
                type,
                data,
                options,
                plugins
            })
        }

        return () => {
            chart.destroy()
        }
    }, [chartRef.current, type, data, options])

    return (
        <div
            className={classNames(styles.Chart, className)}
            id={id || `chart-wrapper-${styles.Chart}`} {...restOfTheProps}
            data-test-id={CHART_BLOCK}
        >
            <canvas
                id={`chart-${styles.Chart}`}
                ref={chartRef}
                data-test-id={CHART_INFO}
            />
        </div>
    )
}
