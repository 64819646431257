import translations from './translations'
import React from 'react'

translations.add('ro', {
    title: 'PayPal',
    description: (
        <>
            DNA Payments s-a asociat cu PayPal pentru a oferi comercianților o modalitate ușoară de
            a adăuga această metodă populară de plată pentru clienți. Comercianții cu conturi PayPal
            existente le pot conecta pe această pagină, iar în cazul în care este necesar să se
            creeze un cont PayPal, acest lucru poate fi făcut tot aici.
            <br />
            <br />
            Odată ce un cont este conectat, opțiunea de plată PayPal poate fi ușor activată sau
            dezactivată folosind opțiunea de comutare, oferind comerciantului control complet.
            <br />
            <br />
        </>
    ),
    paypalStatus: 'Statut PayPal',
    settings: 'Personalizare setări',
    yourAccountDisabled:
        'Configurarea contului dvs. PayPal este în prezent dezactivată. Pentru a începe din nou să acceptați plăți PayPal, vă rugăm să o activați.',
    consentRevokedText:
        'Consimțirile pentru configurarea contului PayPal au fost retrase sau contul de comerciant PayPal este închis.',
    readyPaymentText: 'Ești pregătit să accepți plăți PayPal.'
})
