import {deleteWithAuth, getWithAuth, postWithAuth, putWithAuth} from '@/api'
import {
    EditTeammateRequest,
    InviteTeammateRequest,
    TeammatesRequest,
    TeammatesResponse
} from '@/stores/team-management/models'

export const getTeammates = (request: TeammatesRequest) =>
    getWithAuth<TeammatesResponse>('/oppapi/v1/teammates', request)

export const inviteTeammate = (request: InviteTeammateRequest) =>
    postWithAuth<TeammatesResponse>('/oppapi/v1/teammates', request)

export const resendInvitation = (teammateId: string) =>
    postWithAuth<TeammatesResponse>(`/oppapi/v1/teammates/${teammateId}/resend`)

export const editTeammate = (request: EditTeammateRequest) => {
    const {id, ...rest} = request
    return putWithAuth<TeammatesResponse>(`/oppapi/v1/teammates/${id}`, rest)
}

export const deleteTeammate = (teammateId: string) =>
    deleteWithAuth<TeammatesResponse>(`/oppapi/v1/teammates/${teammateId}`)
