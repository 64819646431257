import React from 'react'
import {Radio} from 'antd'

import {TableViewType} from '@/constants'
import {Icon} from '@/components'

import {MobileTableControlsProps} from './props'
import styles from './styles.scss'
import {RADIO_GROUP, RADIO_GROUP_EXPANDED, RADIO_GROUP_SHORTED} from '@/constants/playwright-ids'

export const MobileTableControls: React.FC<MobileTableControlsProps> = (
    {viewType, setViewType}
) => {
    return (
        <Radio.Group
            className={styles.root}
            defaultValue={TableViewType.EXPANDED}
            value={viewType}
            onChange={(e) => setViewType(e.target.value)}
            buttonStyle="solid"
            data-test-id={RADIO_GROUP}
        >
            <Radio.Button
                data-test-id={RADIO_GROUP_EXPANDED}
                key={`key-${TableViewType.EXPANDED}`}
                value={TableViewType.EXPANDED}
            >
                <Icon type={'expanded'}/>
            </Radio.Button>
            <Radio.Button
                data-test-id={RADIO_GROUP_SHORTED}
                key={`key-${TableViewType.SHORTED}`}
                value={TableViewType.SHORTED}
            >
                <Icon type={'shorted'}/>
            </Radio.Button>
        </Radio.Group>
    )
}
