import {injectable} from 'inversify'
import {debounce} from 'lodash'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import {subscribe} from 'dna-react-ioc'

import {getPOSSummary, POSSummaryResponseModel} from '@/stores'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol,
    PosStoreSelectStore
} from '@/stores/store-and-terminals'
import {EpaySummary} from '@/stores/reports'
import {posStateSummaryList} from '@/pages/Reports'
import {
    TRangePickerContainerStore,
    TSelectContainerStore,
    TTransactionSummaryContainerStore
} from '@/components/containers'
import {Currencies, DEBOUNCE_MS, POSPaymentStateStatus} from '@/constants'
import {LoadingState} from '@/types'
import {openErrorNotification} from '@/utils'
import translations from '@/translations'

@injectable()
export class POSTransactionSummaryStore implements TTransactionSummaryContainerStore {
    private readonly _rangePickerStore: TRangePickerContainerStore
    private readonly _currencySelectStore: TSelectContainerStore
    private readonly _posStoreSelectStore: PosStoreSelectStore
    private readonly _merchantStoresStore: AvailableStoresDataStore

    constructor(
        protected _name: string,
        rangePickerStore: TRangePickerContainerStore,
        currencySelectStore: TSelectContainerStore,
        posStoreSelectStore: PosStoreSelectStore
    ) {
        this._rangePickerStore = rangePickerStore
        this._currencySelectStore = currencySelectStore
        this._posStoreSelectStore = posStoreSelectStore
        this._merchantStoresStore = subscribe<AvailableStoresDataStore>(
            AvailableStoresDataStoreSymbol
        )

        makeObservable(this, {
            loadingState: observable,
            prevSummary: observable,
            currSummary: observable,
            summaryByStatus: computed,
            currSummaryRequest: computed,
            prevDates: computed,
            currency: computed,
            loadSummary: action.bound
        })

        const loadSummary = debounce(this.loadSummary, DEBOUNCE_MS)

        reaction(
            () => ({
                request: this.currSummaryRequest,
                store: this._posStoreSelectStore.value,
                loadingState: this._posStoreSelectStore.loadingState
            }),
            ({request, loadingState}) => {
                if (loadingState === LoadingState.DONE && request.currency) {
                    loadSummary()
                }
            }
        )

        reaction(
            () => this._merchantStoresStore.posMostUsedDefaultCurrency,
            (defaultCurrency) => {
                if (defaultCurrency || !currencySelectStore.value) {
                    currencySelectStore.setValue(defaultCurrency)
                }
            }
        )
    }

    loadingState = LoadingState.IDLE

    prevSummary: EpaySummary = {
        currency: null,
        data: []
    }

    currSummary: EpaySummary = {
        currency: null,
        data: []
    }

    get summaryByStatus() {
        return posStateSummaryList.map((item) => {
            const currItem = this.currSummary.data.find((el) => el.status === item.status)
            const prevItem = this.prevSummary.data.find((el) => el.status === item.status)

            if (!currItem) return item

            const prevAmount = prevItem?.amount || 0
            const percent =
                prevAmount > 0 && currItem.amount > 0
                    ? ((currItem.amount - prevAmount) / prevAmount) * 100
                    : 0

            return {
                ...currItem,
                percent
            }
        })
    }

    get currSummaryRequest() {
        return {
            from: this._rangePickerStore.startDate?.format(),
            to: this._rangePickerStore.endDate?.format(),
            currency: this._currencySelectStore.value
        }
    }

    get prevDates() {
        return {
            from: this._rangePickerStore.prevDates.startDate,
            to: this._rangePickerStore.prevDates.endDate
        }
    }

    get currency() {
        return this._currencySelectStore.value as Currencies
    }

    getSummaryByStatus(transactions?: POSSummaryResponseModel): EpaySummary {
        if (!transactions) {
            transactions = {
                all: [],
                failed: [],
                successful: []
            }
        }

        const allSum = transactions?.all?.reduce((acc, obj) => acc + obj.amount, 0)
        const successfulSum = transactions?.successful?.reduce((acc, obj) => acc + obj.amount, 0)
        const failedSum = transactions?.failed?.reduce((acc, obj) => acc + obj.amount, 0)

        const allCount = transactions?.all?.reduce((acc, obj) => acc + obj.count, 0)
        const successfulCount = transactions?.successful?.reduce((acc, obj) => acc + obj.count, 0)
        const failedCount = transactions?.failed?.reduce((acc, obj) => acc + obj.count, 0)

        return {
            currency: transactions.all[0] && transactions.all[0].currency,
            data: [
                {
                    count: allCount,
                    amount: allSum,
                    status: POSPaymentStateStatus.all
                },
                {
                    count: successfulCount,
                    amount: successfulSum,
                    status: POSPaymentStateStatus.successful
                },
                {
                    count: failedCount,
                    amount: failedSum,
                    status: POSPaymentStateStatus.failed
                }
            ]
        }
    }

    public async loadSummary() {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => (this.loadingState = LoadingState.LOADING))

        const request = this._posStoreSelectStore.requestForServer

        const prevSummaryRequest = {
            ...this.currSummaryRequest,
            from: this._rangePickerStore.prevDates.startDate?.format(),
            to: this._rangePickerStore.prevDates.endDate?.format(),
            ...request
        }

        await Promise.all([
            getPOSSummary(prevSummaryRequest),
            getPOSSummary({...this.currSummaryRequest, ...request})
        ])
            .then((res) => {
                const [prevData, currData] = res

                if (prevData.error || currData.error) {
                    throw new Error(translations().errors.general)
                }

                runInAction(() => {
                    this.loadingState = LoadingState.DONE

                    this.prevSummary = this.getSummaryByStatus(prevData.result)
                    this.currSummary = this.getSummaryByStatus(currData.result)
                })
            })
            .catch((err) => {
                runInAction(() => {
                    this.loadingState = LoadingState.FAILED

                    this.prevSummary = this.getSummaryByStatus()
                    this.currSummary = this.getSummaryByStatus()
                })
                return openErrorNotification(err.message)
            })
    }
}
