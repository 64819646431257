import React from 'react'
import {Form} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {ModalContainer, ModalFormFooter} from '@/components'
import translations from '@/pages/TeamManagement/components/InviteEditTeammate/translations/translations'
import {LoadingState} from '@/types'
import {TInviteEditTeammateStore} from '@/pages/TeamManagement/components/InviteEditTeammate/TInviteEditTeammateStore'
import {InviteEditTeammateFormModel} from '@/pages/TeamManagement/components/InviteEditTeammate/models'
import {InviteEditTeammate} from './InviteEditTeammate'
import styles from '@/pages/TeamManagement/components/InviteEditTeammate/styles.scss'
import {INVITE_TEAMMATE_BUTTON} from '@/constants/playwright-ids'

export const InviteTeammateModalContainerStoreSymbol = Symbol('InviteTeammateModalContainerStoreSymbol')
export const InviteTeammateStoreSymbol = Symbol('InviteTeammateStore')

const INVITE_TEAMMATE_FORM = 'inviteTeammateForm'

export const InviteTeammate = observer(() => {
    const { loadingState, cancel } = useInjection<TInviteEditTeammateStore>(InviteTeammateStoreSymbol)
    const [form] = Form.useForm<InviteEditTeammateFormModel>()

    const cleanFields = () => {
        form.resetFields()
    }

    const closeModal = () => {
        cleanFields()
        cancel()
    }

    return <ModalContainer
        className={styles.modal}
        title={translations().inviteTeammate}
        injectableIdentifier={InviteTeammateModalContainerStoreSymbol}
        width={'600px'}
        onCancel={closeModal}
        footer={<ModalFormFooter
            formId={INVITE_TEAMMATE_FORM}
            onCancel={closeModal}
            cancelText={translations().cancel}
            confirmLoading={loadingState === LoadingState.LOADING}
            okText={translations().inviteTeammate}
        />}
    >
        <InviteEditTeammate
            type={'create'}
            form={form}
            injectableIdentifier={InviteTeammateStoreSymbol}
            formId={INVITE_TEAMMATE_FORM}
            data-test-id={INVITE_TEAMMATE_BUTTON}
        />
    </ModalContainer>
})
