import {injectable} from 'inversify'
import {action, makeObservable, observable} from 'mobx'
import 'reflect-metadata'
import {TDrawerContainerStore} from '@/components/containers/DrawerContainer/TDrawerContainerStore'

@injectable()
export class DrawerStore implements TDrawerContainerStore {
    constructor() {
        makeObservable(this, {
            open: observable,
            setOpen: action.bound,
            onClose: action.bound
        })
    }

    public open = false

    public onClose(): void {
        this.open = false
    }

    public setOpen(open: boolean): void {
        this.open = open
    }
}
