import {action, reaction, makeObservable, observable, runInAction} from 'mobx'
import uuid from 'uuid/v4'
import {inject, injectable} from 'inversify'
import moment from 'moment'

import {SERVER_DATE_FORMAT} from '@/constants'
import {
    TInvoicesStore,
    invoiceTabs,
    InvoicesPaginationStoreSymbol,
    InvoicesRangePickerContainerStoreSymbol
} from '@/pages/Billing/pages/Invoices'
import {PaginationContainerStoreType, TRangePickerContainerStore} from '@/components'
import {Tab} from '@/components/dumb/Tabs/props'
import {getInvoices} from '@/stores/invoices/services/fetchers'
import {TInvoicesDataSource} from '@/stores/invoices/models/TInvoicesDataSource'
import {InvoiceStatus} from '@/stores/invoices/models/InvoiceStatus'
import {openErrorNotification} from '@/utils'

@injectable()
export class InvoicesStore implements TInvoicesStore {
    rangePickerContainerStore: TRangePickerContainerStore
    paginationContainerStore: PaginationContainerStoreType
    invoices: TInvoicesDataSource[] = null
    status: InvoiceStatus = InvoiceStatus.all
    statuses: Tab[] = invoiceTabs
    isInvoicesLoading = false

    constructor(
        @inject(InvoicesRangePickerContainerStoreSymbol)
        rangePickerContainerStore: TRangePickerContainerStore,
        @inject(InvoicesPaginationStoreSymbol)
        paginationContainerStore: PaginationContainerStoreType
    ) {
        this.rangePickerContainerStore = rangePickerContainerStore
        this.paginationContainerStore = paginationContainerStore

        makeObservable(this, {
            status: observable,
            isInvoicesLoading: observable,
            invoices: observable,
            setStatus: action.bound
        })

        this.rangePickerContainerStore.setDates([
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month')
        ])

        reaction(
            () => {
                return {
                    ...this.paginationContainerStore.paging,
                    from: this.rangePickerContainerStore.startDate,
                    to: this.rangePickerContainerStore.endDate,
                    status: this.status
                }
            },
            () => this.loadInvoices()
        )
    }

    public setStatus(value) {
        this.status = value
    }

    loadInvoices = async () => {
        runInAction(() => (this.isInvoicesLoading = true))

        try {
            const {result, error} = await getInvoices({
                from: this.rangePickerContainerStore.startDate.format(SERVER_DATE_FORMAT),
                to: this.rangePickerContainerStore.endDate.format(SERVER_DATE_FORMAT),
                status: this.status === InvoiceStatus.all ? undefined : this.status,
                ...this.paginationContainerStore.paging
            })

            if (error) throw new Error(error.message)

            runInAction(() => {
                this.invoices = result.data.map(invoice => ({...invoice, rowKey: uuid()}))
                this.paginationContainerStore.setTotal(result.totalCount)
            })
        } catch (error) {
            openErrorNotification(error.message)
        } finally {
            runInAction(() => {
                this.isInvoicesLoading = false
            })
        }
    }
}
