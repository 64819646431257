import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {TransactionSummaryList, TTransactionSummaryContainerStore} from '@/components'
import {TransactionSummaryListContainerProps} from './props'

export const TransactionSummaryListContainer: React.FC<TransactionSummaryListContainerProps> = observer((
    {
        injectableIdentifier, type
    }
) => {
    const {summaryByStatus, loadingState, prevDates, currency} = useInjection<TTransactionSummaryContainerStore>(injectableIdentifier)

    return <TransactionSummaryList
        type={type}
        summaryByStatus={summaryByStatus}
        loadingState={loadingState}
        prevDates={prevDates}
        currency={currency}
    />
})
