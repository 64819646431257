import {EcospendStatusType} from '@/stores/ecospend'

export function addListener(event: string, callback: EventListener, elem: any) {
    if (elem.addEventListener) {
        elem.addEventListener(event, callback)
    } else {
        elem.attachEvent('on' + event, callback)
    }
}

export function listenPopapWindowClose(popapWindow: Window, onClose: () => void) {
    const timer = setInterval(() => {
        if (!popapWindow || popapWindow.closed) {
            clearInterval(timer)
            onClose()
        }
    }, 1000)
}

export function isStatusOk(status: EcospendStatusType) {
    return status === 'Verified' || status === 'Completed'
}
