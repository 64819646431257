import translations from './translations'

translations.add('en', {
    id: 'ID',
    date: 'Issue date',
    period: 'Period',
    merchantName: 'Merchant',
    amount: 'Amount',
    currency: 'Currency',
    status: 'Status',
    invoices: 'Invoices',
    all: 'All',
    paid: 'Paid',
    issued: 'Issued',
    action: 'Action',
    download: 'Download'
})
