import React from 'react'
import translations from './translations'

translations.add('sl', {
    hereWillBeTheList: 'Tu bude zoznam vašich domén',
    textWithExplanationOfDomain: (
        <>
            Nemáte žiadne overené domény.
            <br />
            Ak chcete pridať svoje domény, použite tlačidlo &quot;Pridať domény&quot;
        </>
    ),
    addNewDomain: 'Pridať novú doménu'
})
