import React from 'react'
import {Avatar, Col, List as AntList, Row, Skeleton, Typography} from 'antd'
import {List} from '@/components'
import {TransactionDetailModel} from '@/stores'
import {TransactionsListProps} from './props'
import styles from './styles.scss'

export const PendingTransactionsList: React.FC<TransactionsListProps<TransactionDetailModel>> = ({
    dataSource
}) => {
    return (
        <List
            loading={false}
            itemLayout={'horizontal'}
            dataSource={dataSource}
            renderItem={item => (
                <AntList.Item className={styles.listItem}>
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <Row align={'middle'}>
                            <Col>
                                <Avatar className={styles.avatar} src={item.picture.large} />
                            </Col>
                            <Col>
                                <Typography.Text>{item.name}</Typography.Text>
                            </Col>
                        </Row>
                        <Typography.Text className={styles.amount} type={'secondary'}>
                            {item.amount}
                        </Typography.Text>
                    </Skeleton>
                </AntList.Item>
            )}
        />
    )
}
