import {TSelectContainerStore} from '@/components'
import {injectable} from 'inversify'
import {action, computed, makeObservable, observable} from 'mobx'
import {OnlinePaymentsStore} from './OnlinePaymentsStore'
import {SelectItem} from '@/components/dumb/Select'

@injectable()
export class OnlinePaymentsStatusSelectStore implements TSelectContainerStore {
    constructor() {
        makeObservable(this, {
            value: computed,
            options: observable,
            setOptions: action.bound,
            setValue: action.bound
        })
    }

    parentStore: OnlinePaymentsStore
    options: SelectItem[] = []

    get value() {
        return this.parentStore?.status
    }

    clear() {
        return this.parentStore?.setStatus(null)
    }

    setValue(value: string): void {
        return this.parentStore?.setStatus(value)
    }

    setOptions(options: SelectItem[]): void {
        this.options = options
    }
}
