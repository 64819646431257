import translations from './translations'

translations.add('en', {
    paymentView: 'Payment view',
    paymentLink: 'Payment link',
    date: 'Date',
    orderNumber: 'Order number',
    customer: 'Customer',
    transactionType: 'Transaction type',
    cancel: 'Cancel',
    ok: 'Full payment details'
})
