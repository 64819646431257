import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {UserGuide} from '@/components/composite/UserGuide/UserGuide'
import {TUserGuideStore} from './TUserGuideStore'

export const UserGuideStoreSymbol = Symbol('UserGuideStore')

export const UserGuideContainer = observer(() => {
    const {
        steps,
        run,
        handleCallback,
        stepIndex,
        isModalVisible,
        onGotItClick,
        onSkipPress
    } = useInjection<TUserGuideStore>(UserGuideStoreSymbol)

    return <UserGuide
        handleCallback={handleCallback}
        steps={steps}
        run={run}
        stepIndex={stepIndex}
        isModalVisible={isModalVisible}
        onGotItClick={onGotItClick}
        onSkipPress={onSkipPress}
    />
})
