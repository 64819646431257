import {SelectItem} from '@/components/dumb/Select'
import {SearchParams} from '@/stores/transactions/OnlinePaymentsStore/models'
import translations from '@/pages/OnlinePayments/translations'
import {TRANSACTION_ID_PATTERN} from '@/constants/patterns'

export const searchOptions: SelectItem[] = [
    {
        label: translations().searchFilter.order,
        value: SearchParams.ORDER
    },
    {
        label: translations().searchFilter.description,
        value: SearchParams.DESCRIPTION
    },
    {
        label: translations().searchFilter.customerName,
        value: SearchParams.CUSTOMER_NAME
    },
    {
        label: translations().searchFilter.email.startsWith,
        value: SearchParams.EMAIL_STARTS_WITH
    },
    {
        label: translations().searchFilter.card.startsWith,
        value: SearchParams.CARD_STARTS_WITH
    },
    {
        label: translations().searchFilter.card.endsWith,
        value: SearchParams.CARD_ENDS_WITH
    },
    {
        label: translations().searchFilter.customerAccountId,
        value: SearchParams.CUSTOMER_ACCOUNT_ID
    },
    {
        label: translations().searchFilter.transactionId,
        value: SearchParams.TRANSACTION_ID
    }
]

export const searchValidation = {
    [SearchParams.TRANSACTION_ID]: {
        pattern: TRANSACTION_ID_PATTERN,
        error: translations().searchFilterErrors.transactionId
    }
}
