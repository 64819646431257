import translations from './translations'

translations.add('en', {
    title: 'Payment receipt options',
    buttons: {
        send: 'Send',
        receipt: 'Receipt',
        downloadReceipt: 'Download receipt'
    },
    labels: {
        email: 'Customer\'s email',
        emailPlaceholder: 'Email address',
        date: 'Date',
        description: 'Order description',
        paymentMethod: 'Payment method',
        orderNumber: 'Order number'
    },
    errors: {
        emailInvalid: 'email is invalid',
        emailRequired: 'email is required'
    }
})
