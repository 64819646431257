import {Chart} from 'chart.js'
import {Colors} from '@/constants/theme/colors'
import {getFontFamilyName} from '@/utils/css-styles-utils'

Chart.defaults.backgroundColor = Colors.LIGHT_GREEN
Chart.defaults.plugins.title = {
    ...Chart.defaults.plugins.title,
    color: Colors.PURPLE,
    font: {
        size: 14,
        weight: '500',
        family: getFontFamilyName(),
        lineHeight: 3
    }
}
