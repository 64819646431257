import React from 'react'
import {Collapse as AntCollapse, Form, Radio} from 'antd'
import {observer} from 'mobx-react'
import {Collapse, Input, Select} from '@/components'
import translations from './translations'
import {AccountTransactionsFilterFormProps} from './props'
import styles from './styles.scss'
import {
    ACCOUNT,
    BALANCE, CHANNEL,
    DEBIT_CREDIT,
    LOAD_AFTER,
    MERCHANT,
    MERCHANT_ITEM,
    OTP_CODE, REFERENCE, TYPE,
    USER
} from '@/constants/playwright-ids'

const {Panel} = AntCollapse

export const AccountTransactionsFilterForm: React.FC<AccountTransactionsFilterFormProps> = observer(
    ({formId, form, selectTransactionTypes, selectDebitCredit, onFinish}) => {
        return (
            <Form id={formId} form={form} onFinish={onFinish}>
                <Collapse>
                    <Panel className={styles.panel} header={translations().type} key='0'>
                        <Form.Item className={styles.formItem} name='type'>
                            <Select
                                className={styles.select}
                                bordered={false}
                                data-test-id={TYPE}
                                options={selectTransactionTypes}
                                placeholder={translations().type}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel className={styles.panel} header={translations().code} key='1'>
                        <Form.Item className={styles.formItem} name={'code'}>
                            <Input
                                className={styles.input}
                                data-test-id={OTP_CODE}
                                placeholder={translations().code}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel className={styles.panel} header={translations().channel} key='2'>
                        <Form.Item className={styles.formItem} name='channel'>
                            <Input
                                className={styles.input}
                                data-test-id={CHANNEL}
                                placeholder={translations().channel}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel className={styles.panel} header={translations().merchant} key='3'>
                        <Form.Item className={styles.formItem} name='merchant'>
                            <Input
                                className={styles.input}
                                data-test-id={MERCHANT}
                                placeholder={translations().merchant}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel className={styles.panel} header={translations().user} key='4'>
                        <Form.Item className={styles.formItem} name='user'>
                            <Input
                                className={styles.input}
                                data-test-id={USER}
                                placeholder={translations().user}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel className={styles.panel} header={translations().reference} key='5'>
                        <Form.Item className={styles.formItem} name='reference'>
                            <Input
                                className={styles.input}
                                data-test-id={REFERENCE}
                                placeholder={translations().reference}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel className={styles.panel} header={translations().showHidden} key='6'>
                        <Form.Item className={styles.formItem} name='showHidden'>
                            <Radio.Group value={true} buttonStyle='solid'>
                                <Radio key={'yes'} value={true}>
                                    {translations().yes}
                                </Radio>
                                <Radio key={'no'} value={false}>
                                    {translations().no}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Panel>
                    <Panel className={styles.panel} header={translations().account} key='7'>
                        <Form.Item className={styles.formItem} name='account'>
                            <Input
                                className={styles.input}
                                data-test-id={ACCOUNT}
                                placeholder={translations().account}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel className={styles.panel} header={translations().balance} key='8'>
                        <Form.Item className={styles.formItem} name='balance'>
                            <Input
                                className={styles.input}
                                data-test-id={BALANCE}
                                placeholder={translations().balance}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel
                        className={styles.panel}
                        header={translations().transactionDirection}
                        key='9'
                    >
                        <Form.Item className={styles.formItem} name='debitCredit'>
                            <Select
                                className={styles.select}
                                bordered={false}
                                data-test-id={DEBIT_CREDIT}
                                options={selectDebitCredit}
                                placeholder={translations().transactionDirection}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel className={styles.panel} header={translations().loadAfter} key='10'>
                        <Form.Item className={styles.formItem} name='loadAfter'>
                            <Input
                                className={styles.input}
                                data-test-id={LOAD_AFTER}
                                placeholder={translations().loadAfter}
                            />
                        </Form.Item>
                    </Panel>
                </Collapse>
            </Form>
        )
    }
)
