import React from 'react'
import {Typography} from 'antd'
import {PageHeader, PaymentMethodLabelWithIcon} from '@/components'
import {PaymentMethodStoreSettings} from '@/pages/payment-methods/components'
import {PaymentMethodDetailsProps} from './props'

export const PaymentMethodDetails: React.FC<PaymentMethodDetailsProps> = (
    {stores, paymentMethodType, title, description}
) => {
    return <>
        <PageHeader
            title={(
                <PaymentMethodLabelWithIcon
                    paymentMethod={paymentMethodType}
                    bordered={true}
                    title={title}
                />
            )}
            border={'none'}
        >
            <Typography.Text>
                {description}
            </Typography.Text>
            <br/><br/>
            <PaymentMethodStoreSettings
                paymentMethodType={paymentMethodType}
                stores={stores}
            />
        </PageHeader>
    </>
}
