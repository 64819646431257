import React from 'react'
import {PaymentMethodType} from '@/constants'
import {Icon} from '@/components'
import translations from './translations'
import {PaymentMethodIconProps} from './props'
import {
    ALI_PAY,
    APPLE_PAY,
    ASTRO_PAY,
    CLICK_TO_PAY,
    GOOGLE_PAY,
    KLARNA,
    OPEN_BANKING,
    PAY_BY_BANK,
    PAYPAL_PAY,
    WECHAT_PAY
} from '@/constants/playwright-ids'

export const PaymentMethodIcon: React.FC<PaymentMethodIconProps> = (props) => {
    const {type, className} = props

    switch (type?.toLowerCase()) {
        case PaymentMethodType.card:
            return <Icon type={'card'} className={className} />
        case PaymentMethodType.applepay:
            return <Icon type={'apple_pay'} className={className} data-test-id={APPLE_PAY} />
        case PaymentMethodType.googlepay:
            return <Icon type={'google_pay'} className={className} data-test-id={GOOGLE_PAY} />
        case PaymentMethodType.paypal:
            return <Icon type={'paypal'} className={className} data-test-id={PAYPAL_PAY} />
        case PaymentMethodType.paybybankapp.toLowerCase():
            return (
                <Icon type={'pay_by_bank_logo'} className={className} data-test-id={PAY_BY_BANK} />
            )
        case PaymentMethodType.ecospend:
            return <Icon type={'open_banking'} className={className} data-test-id={OPEN_BANKING} />
        case PaymentMethodType.klarna:
            return <Icon type={'klarna'} className={className} data-test-id={KLARNA} />
        case PaymentMethodType.clicktopay:
            return <Icon type={'clicktopay'} className={className} data-test-id={CLICK_TO_PAY} />
        case PaymentMethodType.astropay:
            return <Icon type={'astropay'} className={className} data-test-id={ASTRO_PAY} />
        case PaymentMethodType.alipay:
            return <Icon type={'alipay'} className={className} data-test-id={ALI_PAY} />
        case PaymentMethodType.wechatpay:
            return <Icon type={'wechat_pay'} className={className} data-test-id={WECHAT_PAY} />
        default:
            return <>{translations().other}</>
    }
}
