import {makeObservable} from 'mobx'
import {injectable} from 'inversify'
import {ApiResponse} from 'back-connector'

import {BaseDataStore} from '@/stores/shared/BaseDataStore'

import {StoreTerminalType} from '../types'
import {fetchMerchantTerminals} from '../api'

@injectable()
export class Way4StoresDataStore extends BaseDataStore<StoreTerminalType[]> {
    protected _name = 'Way4StoresDataStore'

    constructor() {
        super()

        makeObservable(this, {
            ...this.getAnnotationsMap()
        })
    }

    protected async fetchData(): Promise<ApiResponse<StoreTerminalType[]>> {
        return fetchMerchantTerminals()
    }

    protected parseResponse(result: StoreTerminalType[]): StoreTerminalType[] {
        if (!result) return []

        return result.map((el) => ({
            ...el,
            name: String(el.name || '').trim(),
            location: String(el.location || '').trim()
        }))
    }

    protected getDefaultData(): StoreTerminalType[] {
        return []
    }
}
