import translations from './translations'

translations.add('sl', {
    linkDetails: 'Podrobnosti o odkaze na platbu',
    copyToClipboard: 'Kopírovať do schránky',
    copied: 'Skopírované',
    orderNumber: 'Poradové číslo:',
    amount: 'Množstvo',
    customer: 'Zákazník',
    dateCreated: 'Dátum vytvorenia',
    expirationDate: 'Dátum vypršania platnosti',
    viewedDate: 'Zobrazený dátum',
    paidDate: 'Dátum platby',
    cancelledDate: 'Dátum zrušenia',
    attemptedDate: 'Dátum pokusu',
    description: 'Popis',
    createdBy: 'Created by'
})
