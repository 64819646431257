import translations from './translations'

translations.add('es', {
    title: 'Descripción general',
    detailedReport: 'Reporte detallado',
    onlinePayments: 'Pagos en línea',
    posPayments: 'Pagos TPV',
    chargedTransactions: 'Transacciones cargadas',
    chargedTransactionsComparison: 'Comparación de transacciones cobradas',
    howItWorks: '¿Cómo funciona?',
    merchantPortal: 'Portal de Comerciantes',
    productGuides: 'Guías de productos',
    paymentLinks: 'Enlaces de pago',
    readMore: 'Leer más',
    merchantPortalGuide: 'Guía del portal de comerciantes',
    onlinePaymentSystem:
        'Un sistema de pago en línea integrado directamente en su sitio web: haga clic en los consejos sobre cómo comenzar a usar el portal',
    toHelpYouGetStarted:
        'Para ayudarlo a comenzar, hemos preparado algunas guías rápidas y fáciles de usar',
    whereToStart: 'Donde empezar',
    startEarning: 'Empezar a ganar',
    allPosTransactions: 'Todas las transacciones de POS',
    currency: 'Divisa',
    noContentTitle: 'No hay contenido disponible'
})
