import React, {useState, useCallback, useEffect} from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {error} from 'dna-common'
import {Button, Steps, Space, Form, Modal} from 'antd'
import {TwoFAModalStoreInterface} from '@/stores/profile/TwoFAModalStore/TwoFAModalStoreInterface'
import {
    TwoFAConfirm,
    TwoFAFrequency,
    TwoFARecoveryCode,
    TwoFAConfigureAuthApp
} from '@/pages/Profile/components/TwoFA/components'
import {TwoFAModalProps} from './props'

import translations from './translations'
import styles from './styles.scss'
import {
    TWO_FA_BACK,
    TWO_FA_CANCEL,
    TWO_FA_NEXT,
    TWO_FA_SUBMIT,
    TWO_FA_UPDATE
} from '@/constants/playwright-ids'

const {Step} = Steps
const {useForm} = Form

export const TwoFAModalStoreSymbol = Symbol('TwoFAModalStoreSymbol')

export const TwoFAModal = observer(({onSubmit, onCancel, ...rest}: TwoFAModalProps) => {
    const [current, setCurrent] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [form] = useForm()

    const {
        twoFAStore,
        selectedFrequency,
        savedFrequency,
        isModalCloseble,
        setIsModalCloseble,
        updateTwoFAUserSettings,
        setSelectedFrequency,
        getConfigureAppInfo,
        reset
    } = useInjection<TwoFAModalStoreInterface>(TwoFAModalStoreSymbol)

    const {isEnabled, isModalOpen, setIsModalOpen} = twoFAStore

    useEffect(() => {
        if (isModalOpen && !isEnabled) {
            getConfigureAppInfo()
        }
    }, [isModalOpen])

    useEffect(() => {
        if (current === 2 && isEnabled) {
            setIsModalCloseble(false)
        }
    }, [current])

    const getSteps = useCallback(
        () => [
            {
                title: translations().selectFrequency,
                modalTitle: !isEnabled ? translations().title : translations().updateTitle,
                content: <TwoFAFrequency onChange={(v) => setSelectedFrequency(v)} />
            },

            ...(!isEnabled
                ? [
                      {
                          title: translations().configureApp,
                          modalTitle: translations().title,
                          content: <TwoFAConfigureAuthApp form={form} />
                      },
                      {
                          title: null,
                          modalTitle: translations().recoveryCodes,
                          content: <TwoFARecoveryCode />
                      }
                  ]
                : [
                      {
                          title: null,
                          modalTitle: !isEnabled
                              ? translations().title
                              : translations().updateTitle,
                          content: (
                              <TwoFAConfirm
                                  onCancel={() => setCurrent(0)}
                                  onSubmit={async (v) => {
                                      const result = await updateTwoFAUserSettings(
                                          v.verificationCode
                                      )

                                      if (result) handleCancel()
                                  }}
                              />
                          )
                      }
                  ])
        ],
        [twoFAStore.isModalOpen]
    )

    const steps = getSteps()

    const isUpdateButtonDisable = selectedFrequency === savedFrequency

    const next = () => {
        setCurrent((prevCurrent) => prevCurrent + 1)
    }

    const prev = () => {
        setCurrent((prevCurrent) => prevCurrent - 1)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        setCurrent(0)
        reset()

        onCancel && onCancel()
    }

    const handleSubmit = async () => {
        try {
            await form.validateFields()

            const {verificationCode: code} = form.getFieldsValue()

            setIsLoading(true)

            const result = await (onSubmit ? onSubmit(code) : updateTwoFAUserSettings(code))

            if (result) next()
        } catch (err) {
            error(err)
        } finally {
            setIsLoading(false)
        }
    }

    const isButtonsVisible = useCallback((): boolean => {
        if ((!isEnabled && current > 1) || (isEnabled && current !== 0)) {
            return false
        }

        return true
    }, [current])

    return (
        <>
            <Modal
                width={'600px'}
                title={steps[current]?.modalTitle}
                open={isModalOpen}
                footer={null}
                onCancel={handleCancel}
                closable={isModalCloseble}
                maskClosable={isModalCloseble}
                keyboard={isModalCloseble}
                {...rest}
            >
                {current < 2 && !isEnabled && (
                    <Steps size={'small'} current={current} className={styles.steps}>
                        {steps.map(({title}) => title && <Step key={title} title={title} />)}
                    </Steps>
                )}

                <div className={styles.content}>{steps[current]?.content}</div>

                {isButtonsVisible() && (
                    <div className={styles.buttonsHolder}>
                        <Space className={styles.buttons}>
                            {current === 0 && (
                                <Button data-test-id={TWO_FA_CANCEL} onClick={() => handleCancel()}>
                                    {translations().cancel}
                                </Button>
                            )}

                            {current > 0 && (
                                <Button data-test-id={TWO_FA_BACK} onClick={() => prev()}>
                                    {translations().back}
                                </Button>
                            )}

                            {current < 1 && !isEnabled && (
                                <Button
                                    data-test-id={TWO_FA_NEXT}
                                    type='primary'
                                    onClick={() => next()}
                                >
                                    {translations().next}
                                </Button>
                            )}

                            {current === 0 && isEnabled && (
                                <Button
                                    type='primary'
                                    onClick={() => next()}
                                    disabled={isUpdateButtonDisable}
                                    data-test-id={TWO_FA_UPDATE}
                                >
                                    {translations().update}
                                </Button>
                            )}

                            {current === 1 && (
                                <Button
                                    type='primary'
                                    disabled={isLoading}
                                    onClick={handleSubmit}
                                    data-test-id={TWO_FA_SUBMIT}
                                >
                                    {translations().submit}
                                </Button>
                            )}
                        </Space>
                    </div>
                )}
            </Modal>
        </>
    )
})
