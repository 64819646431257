import translations from './translations'

translations.add('es', {
    buttons: {
        processNewPayment: 'Procesar nuevo pago',
        cancelPayment: 'Cancelar el pago',
        charge: 'Cargar',
        refund: 'Reembolso'
    },
    transactions: 'Actas',
    transactionDetails: 'Detalles de la transacción',
    noDescription: 'Sin descripción',
    refundDisabledTooltip: 'El banco del Cliente no admite reembolsos de Open Banking'
})
