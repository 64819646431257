import translations from './translations'

translations.add('el', {
    title: 'Σύνδεσμοι για πληρωμή',
    paymentDetails: 'Λεπτομέρειες πληρωμής',
    chargedAmount: 'Χρεωμένο ποσό',
    expirationDate: 'Ημερομηνία λήξης',
    dateCreated: 'Ημερομηνία Δημιουργίας',
    summary: 'Περίληψη',
    channel: 'Κανάλι',
    description: 'Περιγραφή',
    amount: 'Ποσό',
    status: 'Κατάσταση',
    customer: 'Πελάτης',
    action: 'Ενέργεια',
    statement: 'Δήλωση',
    statuses: {
        active: 'Ενεργοί',
        expired: 'Εχει λήξει',
        paid: 'Πληρώθηκε',
        cancelled: 'Ακυρώθηκε',
        viewed: 'Προβολή',
        attempted: 'Προσπάθεια',
        all: 'Ολα'
    }
})
