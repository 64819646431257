import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import _ from 'lodash'
import {useInjection} from 'dna-react-ioc'
import {Input, MultiSelect, Select, Text} from '@/components'
import {useScreenType} from '@/hooks'
import {Col, Divider, Form, Radio, Row, Typography} from 'antd'
import {TInviteEditTeammateStore} from '@/pages/TeamManagement/components/InviteEditTeammate/TInviteEditTeammateStore'
import {InviteEditTeammateModalProps} from '@/pages/TeamManagement/components/InviteEditTeammate/props'
import {Role} from '@/stores/team-management/constants'
import {hasProducts} from '@/stores/auth/services'
import {ProductsMap} from '@/stores/auth/constants/products-map'
import {renderMobileSectionPermissionsFormItem, renderSectionPermissionsFormItem} from './services'
import {InviteTeammatePermissions} from './models'
import {AccessType} from './constants'
import translations from './translations'
import styles from './styles.scss'
import {
    ADMIN_ROLE,
    FULL_ACCESS_RADIO_BUTTON,
    INVITE_AS_RADIO,
    NO_ACCESS_RADIO_BUTTON,
    READ_ACCESS_RADIO_BUTTON,
    RESTRICTED_ROLE,
    TEAMMATE_EMAIL,
    TEAMMATE_FIRST_NAME,
    TEAMMATE_LAST_NAME,
    TEAMMATE_ROLE_SELECT,
    TEAMMATE_ROLE_TYPE
} from '@/constants/playwright-ids'

const financePermissions: InviteTeammatePermissions = {
    overview: 'read',
    online_payments: 'full',
    pos_payments: 'full',
    pos_amex_payments: 'full',
    payment_links: 'no',
    virtual_terminal: 'no',
    settlements: 'full',
    invoices: 'full',
    reports: 'read',
    payment_methods: 'no',
    teammates: 'no',
    chargebacks: 'read'
}

const customPermissions: InviteTeammatePermissions = {
    overview: 'no',
    online_payments: 'no',
    pos_payments: 'no',
    pos_amex_payments: 'no',
    payment_links: 'no',
    virtual_terminal: 'no',
    settlements: 'no',
    invoices: 'no',
    reports: 'no',
    payment_methods: 'no',
    teammates: 'no',
    chargebacks: 'no'
}

export const InviteEditTeammate = observer(
    ({injectableIdentifier, formId, form, type}: InviteEditTeammateModalProps) => {
        const {isMobile} = useScreenType()
        const {teammate, inviteEditTeammate, selectStores, isAdmin, setStores, stores} =
            useInjection<TInviteEditTeammateStore>(injectableIdentifier)

        const isTeammateAdmin = teammate?.role === Role.admin

        /**
         *  UI states
         */
        const [inviteAs, setInviteAs] = useState<AccessType>(
            teammate?.inviteAs || isAdmin ? AccessType.admin : AccessType.restricted
        )
        const [selectedRole, setSelectedRole] = useState<Role>(teammate?.role || Role.finance)
        const [selectedPermissions, setSelectedPermissions] = useState<InviteTeammatePermissions>(
            teammate?.permissions || financePermissions
        )
        const shopIds =
            teammate?.shopIds?.length > 0
                ? Array.from(new Set(teammate?.shopIds))
                : selectStores?.map((store) => store.value)

        useEffect(() => {
            form.resetFields([
                ...Object.keys(selectedPermissions).map((key) => ['permissions', key])
            ])
        }, [selectedPermissions])

        useEffect(() => {
            form.resetFields(['role'])
        }, [selectedRole])

        useEffect(() => {
            form.resetFields(['inviteAs'])
        }, [inviteAs])

        useEffect(() => {
            teammate && setInviteAs(teammate.inviteAs)
            teammate && setSelectedRole(teammate.role)
            teammate && setSelectedPermissions(teammate.permissions)
            form.resetFields(['email', 'firstName', 'lastName', 'shopIds'])
        }, [teammate])

        useEffect(() => {
            return () => {
                setInviteAs(
                    teammate?.inviteAs || isAdmin ? AccessType.admin : AccessType.restricted
                )
                setSelectedRole(teammate?.role || Role.finance)
                setSelectedPermissions(teammate && teammate.permissions)
            }
        }, [])

        const onInvitedAsEditChange = (inviteAs: AccessType) => {
            if (inviteAs === AccessType.restricted) {
                setSelectedRole(null)
            }

            setInviteAs(inviteAs)
        }

        const onFinish = (values) => {
            values.email = values.email?.toLowerCase()
            inviteEditTeammate(values).then(() => {
                setSelectedRole(Role.finance)
                setInviteAs(isAdmin ? AccessType.admin : AccessType.restricted)
                setSelectedPermissions(financePermissions)
                form.resetFields()
            })
        }

        const onPermissionChange = (sectionCode, permission) => {
            const _selectedPermissions = {...selectedPermissions, [sectionCode]: permission}
            setSelectedPermissions(_selectedPermissions)
            if (_.isEqual(_selectedPermissions, financePermissions)) {
                setSelectedRole(Role.finance)
            } else {
                setSelectedRole(Role.custom)
            }
        }

        const onRoleChange = (role) => {
            if (role === Role.finance) {
                setSelectedPermissions(financePermissions)
            } else {
                setSelectedPermissions(customPermissions)
            }
            setSelectedRole(role)
        }

        const renderDesktopPermissions = () => {
            return (
                <>
                    <Row>
                        <Col sm={10}>
                            <Text>{translations().formFields.sectionName}</Text>
                        </Col>
                        <Col sm={14}>
                            <Row>
                                <Col sm={8}>
                                    <Text type={'secondary'} data-test-id={NO_ACCESS_RADIO_BUTTON}>
                                        {translations().formFields.noAccess}
                                    </Text>
                                </Col>
                                <Col sm={8}>
                                    <Text
                                        type={'secondary'}
                                        data-test-id={READ_ACCESS_RADIO_BUTTON}
                                    >
                                        {translations().formFields.readAccess}
                                    </Text>
                                </Col>
                                <Col sm={8}>
                                    <Text
                                        type={'secondary'}
                                        data-test-id={FULL_ACCESS_RADIO_BUTTON}
                                    >
                                        {translations().formFields.fullAccess}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider className={styles.sectionPermissionsDivider} />
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.overview,
                        sectionCode: 'overview',
                        accessLevels: [{value: 'no'}, {value: 'read'}],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.overview
                    })}
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.posPayments,
                        sectionCode: 'pos_payments',
                        accessLevels: [{value: 'no'}, {value: 'read'}, {value: 'full'}],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.pos_payments
                    })}
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.onlinePayments,
                        sectionCode: 'online_payments',
                        accessLevels: [{value: 'no'}, {value: 'read'}, {value: 'full'}],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.online_payments
                    })}
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.paymentLinks,
                        sectionCode: 'payment_links',
                        accessLevels: [{value: 'no'}, {value: 'read'}, {value: 'full'}],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.payment_links
                    })}
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.virtualTerminal,
                        sectionCode: 'virtual_terminal',
                        accessLevels: [
                            {value: 'no'},
                            {value: 'read', disabled: true},
                            {value: 'full'}
                        ],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.virtual_terminal
                    })}
                    {hasProducts([ProductsMap.amex]) &&
                        renderSectionPermissionsFormItem({
                            sectionName: translations().formFields.sections.posAmexPayments,
                            sectionCode: 'pos_amex_payments',
                            accessLevels: [{value: 'no'}, {value: 'read'}, {value: 'full'}],
                            onChange: onPermissionChange,
                            initialValue: selectedPermissions.pos_amex_payments
                        })}
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.chargebacks,
                        sectionCode: 'chargebacks',
                        accessLevels: [{value: 'no'}, {value: 'read'}],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.chargebacks,
                        disabled: !isAdmin
                    })}
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.settlements,
                        sectionCode: 'settlements',
                        accessLevels: [{value: 'no'}, {value: 'read'}, {value: 'full'}],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.settlements
                    })}
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.invoices,
                        sectionCode: 'invoices',
                        accessLevels: [{value: 'no'}, {value: 'read'}, {value: 'full'}],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.invoices
                    })}
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.reports,
                        sectionCode: 'reports',
                        accessLevels: [{value: 'no'}, {value: 'read'}],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.reports
                    })}
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.paymentMethods,
                        sectionCode: 'payment_methods',
                        accessLevels: [{value: 'no'}, {value: 'read'}, {value: 'full'}],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.payment_methods
                    })}
                    {renderSectionPermissionsFormItem({
                        sectionName: translations().formFields.sections.teammates,
                        sectionCode: 'teammates',
                        accessLevels: [{value: 'no'}, {value: 'read'}, {value: 'full'}],
                        onChange: onPermissionChange,
                        initialValue: selectedPermissions.teammates,
                        disabled: !isAdmin
                    })}
                </>
            )
        }

        const renderMobilePermissions = () => {
            return (
                <>
                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.overview,
                        'overview',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.readAccess, value: 'read'}
                        ],
                        onPermissionChange,
                        selectedPermissions.overview
                    )}
                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.posPayments,
                        'pos_payments',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.readAccess, value: 'read'},
                            {label: translations().formFields.fullAccess, value: 'full'}
                        ],
                        onPermissionChange,
                        selectedPermissions.pos_payments
                    )}
                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.onlinePayments,
                        'online_payments',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.readAccess, value: 'read'},
                            {label: translations().formFields.fullAccess, value: 'full'}
                        ],
                        onPermissionChange,
                        selectedPermissions.online_payments
                    )}
                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.paymentLinks,
                        'payment_links',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.readAccess, value: 'read'},
                            {label: translations().formFields.fullAccess, value: 'full'}
                        ],
                        onPermissionChange,
                        selectedPermissions.payment_links
                    )}
                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.virtualTerminal,
                        'virtual_terminal',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.fullAccess, value: 'full'}
                        ],
                        onPermissionChange,
                        selectedPermissions.virtual_terminal
                    )}
                    {hasProducts([ProductsMap.amex]) &&
                        renderMobileSectionPermissionsFormItem(
                            translations().formFields.sections.posAmexPayments,
                            'pos_amex_payments',
                            [
                                {label: translations().formFields.noAccess, value: 'no'},
                                {label: translations().formFields.readAccess, value: 'read'},
                                {label: translations().formFields.fullAccess, value: 'full'}
                            ],
                            onPermissionChange,
                            selectedPermissions.pos_amex_payments
                        )}
                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.chargebacks,
                        'chargebacks',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.readAccess, value: 'read'}
                        ],
                        onPermissionChange,
                        selectedPermissions.chargebacks
                    )}

                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.settlements,
                        'settlements',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.readAccess, value: 'read'},
                            {label: translations().formFields.fullAccess, value: 'full'}
                        ],
                        onPermissionChange,
                        selectedPermissions.settlements
                    )}
                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.invoices,
                        'invoices',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.readAccess, value: 'read'},
                            {label: translations().formFields.fullAccess, value: 'full'}
                        ],
                        onPermissionChange,
                        selectedPermissions.invoices
                    )}
                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.reports,
                        'reports',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.readAccess, value: 'read'}
                        ],
                        onPermissionChange,
                        selectedPermissions.reports
                    )}
                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.paymentMethods,
                        'payment_methods',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.readAccess, value: 'read'},
                            {label: translations().formFields.fullAccess, value: 'full'}
                        ],
                        onPermissionChange,
                        selectedPermissions.payment_methods
                    )}
                    {renderMobileSectionPermissionsFormItem(
                        translations().formFields.sections.teammates,
                        'teammates',
                        [
                            {label: translations().formFields.noAccess, value: 'no'},
                            {label: translations().formFields.readAccess, value: 'read'},
                            {label: translations().formFields.fullAccess, value: 'full'}
                        ],
                        onPermissionChange,
                        selectedPermissions.teammates
                    )}
                </>
            )
        }

        const renderCreateUserAccessTypes = () => (
            <Radio.Group onChange={(event) => setInviteAs(event?.target?.value)}>
                {isAdmin && (
                    <Radio value={AccessType.admin}>
                        {translations().formFields.inviteAsAdmin}
                    </Radio>
                )}
                <Radio value={AccessType.restricted}>
                    {translations().formFields.inviteAsRestrictedAccess}
                </Radio>
            </Radio.Group>
        )

        const renderEditUserAccessTypes = () => {
            return (
                <Radio.Group onChange={(event) => onInvitedAsEditChange(event?.target?.value)}>
                    {(isAdmin || (!isAdmin && isTeammateAdmin)) && (
                        <Radio value={AccessType.admin} data-test-id={ADMIN_ROLE}>
                            {translations().formFields.inviteAsAdmin}
                        </Radio>
                    )}
                    {(isAdmin || (!isAdmin && !isTeammateAdmin)) && (
                        <Radio value={AccessType.restricted} data-test-id={RESTRICTED_ROLE}>
                            {translations().formFields.inviteAsRestrictedAccess}
                        </Radio>
                    )}
                </Radio.Group>
            )
        }

        return (
            <Form
                id={formId}
                form={form}
                layout={'vertical'}
                onFinish={onFinish}
                autoComplete='off'
            >
                <Form.Item
                    label={translations().formFields.email}
                    name={'email'}
                    initialValue={teammate?.email}
                    rules={[
                        {required: true, message: translations().formErrors.emailRequired},
                        {type: 'email', message: translations().formErrors.enterValidEmail}
                    ]}
                >
                    <Input
                        disabled={!!teammate}
                        placeholder={translations().formFields.email}
                        data-test-id={TEAMMATE_EMAIL}
                    />
                </Form.Item>
                <Form.Item
                    label={translations().formFields.firstName}
                    name={'firstName'}
                    initialValue={teammate?.firstName}
                    rules={[{required: true, message: translations().formErrors.firstNameRequired}]}
                >
                    <Input
                        placeholder={translations().formFields.firstName}
                        data-test-id={TEAMMATE_FIRST_NAME}
                    />
                </Form.Item>
                <Form.Item
                    label={translations().formFields.lastName}
                    name={'lastName'}
                    initialValue={teammate?.lastName}
                    rules={[{required: true, message: translations().formErrors.lastNameRequired}]}
                >
                    <Input
                        placeholder={translations().formFields.lastName}
                        data-test-id={TEAMMATE_LAST_NAME}
                    />
                </Form.Item>
                <Form.Item
                    name={'shopIds'}
                    label={translations().formFields.store}
                    initialValue={shopIds}
                    rules={[{required: true, message: translations().formErrors.shopRequired}]}
                    data-test-id={'shopsOfTeammate'}
                >
                    <MultiSelect
                        isSearchable={true}
                        value={stores}
                        filterOption={(input, option) =>
                            String(option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        onChange={(v) => setStores(v)}
                        placeholder={translations().formFields.store}
                        options={selectStores}
                        data-test-id={'selectTeammateShop'}
                    />
                </Form.Item>
                <Typography.Title level={5}>
                    {translations().formFields.teammatePermissions}
                </Typography.Title>
                <Form.Item
                    name={'inviteAs'}
                    initialValue={inviteAs}
                    rules={[{required: true, message: translations().formErrors.lastNameRequired}]}
                    data-test-id={INVITE_AS_RADIO}
                >
                    {type === 'create'
                        ? renderCreateUserAccessTypes()
                        : renderEditUserAccessTypes()}
                </Form.Item>
                {inviteAs === AccessType.restricted && (
                    <>
                        <Form.Item
                            name={'role'}
                            initialValue={selectedRole}
                            rules={[
                                {required: true, message: translations().formErrors.roleRequired}
                            ]}
                            data-test-id={TEAMMATE_ROLE_SELECT}
                        >
                            <Select
                                placeholder={translations().formFieldsPlaceholders.role}
                                options={[
                                    {
                                        label: translations().formFields.accountantAccess,
                                        value: 'finance'
                                    },
                                    {
                                        label: translations().formFields.customAccess,
                                        value: 'custom'
                                    }
                                ]}
                                onChange={onRoleChange}
                                data-test-id={TEAMMATE_ROLE_TYPE}
                            />
                        </Form.Item>

                        <Typography.Title level={5}>
                            {translations().formFields.grantedPermissions}
                        </Typography.Title>
                        {!isMobile && renderDesktopPermissions()}
                        {isMobile && renderMobilePermissions()}
                    </>
                )}
            </Form>
        )
    }
)
