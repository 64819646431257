import translations from './translations'

translations.add('es', {
    amount: 'Cantidad',
    chargebackDate: 'Fecha de Devolución',
    transactionDate: 'Fecha de Transacción',
    currency: 'Moneda',
    reasonCode: 'Código de Motivo',
    merchantName: 'Nombre del Comerciante',
    reason: 'Razón',
    status: 'Estado',
    paymentMethod: 'Método de pago'
})
