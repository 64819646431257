import translations from './translations'

translations.add('ro', {
    amount: 'Suma',
    currency: 'Monedă',
    errors: {
        amountRequired: 'Suma este obligatorie',
        amountInvalid: 'Suma trebuie să fie mai mare decât 0'
    }
})
