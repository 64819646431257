import React from 'react'
import classNames from 'classnames'
import {Collapse, Timeline} from 'antd'
import {CaretRightOutlined} from '@ant-design/icons'
import {Description} from '@/components/dumb/Description'
import {buildTransactionFields, buildChargebackFields, buildChargebackHistoryFields} from './utils'
import {ChargebacksDetailsProps} from './props'

import translations from './translations'
import styles from './styles.scss'

const {Panel} = Collapse

export const ChargebacksDetails: React.FC<ChargebacksDetailsProps> = ({
    data,
    chargeback,
    isMobile
}) => {
    const originalTransaction = data.find((item) => item.category === 'original-transaction')
    const chargebackHistory = data.filter((item) => item.category !== 'original-transaction')

    const originalTransactionFields = buildTransactionFields(originalTransaction, chargeback)
    const chargebackFields = buildChargebackFields(chargeback)
    const chargebackHistoryFields = buildChargebackHistoryFields(chargebackHistory, chargeback)

    return (
        <Collapse
            bordered={false}
            ghost
            defaultActiveKey={[0, 1, 2]}
            expandIcon={({isActive}) => (
                <CaretRightOutlined className={styles.expandIcon} rotate={isActive ? 270 : 90} />
            )}
            expandIconPosition={'end'}
            className={styles.accordion}
        >
            <Panel className={styles.panel} header={translations().chargebacksDetails} key={0}>
                <Description
                    dataSource={chargebackFields}
                    className={classNames(styles.description, {
                        [styles.descriptionMobile]: isMobile
                    })}
                />
            </Panel>
            <Panel
                className={styles.panel}
                header={translations().originalTransactionDetails}
                key={1}
            >
                <Description
                    dataSource={originalTransactionFields}
                    className={classNames(styles.description, {
                        [styles.descriptionMobile]: isMobile
                    })}
                />
            </Panel>
            <Panel className={styles.panel} header={translations().chargebackHistory} key={2}>
                <Timeline mode='left' className={styles.timeline}>
                    {chargebackHistoryFields.map((field) => (
                        <React.Fragment key={field.key}>{field.render()}</React.Fragment>
                    ))}
                </Timeline>
            </Panel>
        </Collapse>
    )
}
