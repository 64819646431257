import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    orderNumber: string
    orderNumberTooltip: string
    errors: {
        orderNumberRequired: string
    }
}>()

