import React, {useEffect} from 'react'
import {Form} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {FilterButtonPopover} from '@/components'
import {AccountTransactionsFilterButtonStoreInterface} from './AccountTransactionsFilterButtonStoreInterface'
import {AccountTransactionsFilterButtonProps} from './props'
import styles from './styles.scss'
import {AccountTransactionsFilterForm} from '@/pages/business-account/AccountInfo/components/AccountTransactionsFilter/components/AccountTransactionsFilterForm'

export const AccountTransactionsFilterButtonStoreSymbol = Symbol(
    'AccountTransactionsFilterButtonStore'
)

export const AccountTransactionsFilterButton = observer(
    ({
        injectableIdentifier = AccountTransactionsFilterButtonStoreSymbol
    }: AccountTransactionsFilterButtonProps) => {
        const [form] = Form.useForm()
        const {
            count,
            openFilter,
            onFilterClose,
            clearFilters,
            open,
            setOpen,
            onFinish,
            selectTransactionTypes,
            selectDebitCredit
        } = useInjection<AccountTransactionsFilterButtonStoreInterface>(injectableIdentifier)

        const formName = 'account-transactions-popover-filter-form'

        const onClearFilters = () => {
            clearFilters()
            form.setFieldsValue(null)
        }

        return (
            <FilterButtonPopover
                buttonClassName={styles.button}
                count={count}
                formId={formName}
                onClearFiltersClick={onClearFilters}
                onClick={openFilter}
                open={open}
                setOpen={setOpen}
                onCloseClick={onFilterClose}
                popoverContent={
                    <AccountTransactionsFilterForm
                        formId={formName}
                        form={form}
                        onFinish={onFinish}
                        selectDebitCredit={selectDebitCredit}
                        selectTransactionTypes={selectTransactionTypes}
                    />
                }
            />
        )
    }
)
