import translations from './translations'

translations.add('es', {
    successTitle: 'Enhorabuena',
    successMessage: 'Su pago se ha enviado con éxito.',
    cancelMessage: 'Your payment has been cancelled.',
    failMessage: 'Su pago ha sido cancelado.',
    rejectMessage: 'Su pago ha sido rechazado.',
    transactionId: 'ID de transacción',
    autoRedirectAfter: 'Será redirigido automáticamente al sitio web después',
    seconds: 'segundo',
    tryAgain: 'Intentar otra vez',
    returnToBack: 'Volver al sitio'
})
