import translations from './translations'

translations.add('sl', {
    back: 'Späť na prihlásenie',
    title: 'Obnoviť heslo',
    text: 'Zadajte e-mailovú adresu, ktorú ste použili pri registrácii na portáli, a my vám pošleme pokyny na obnovenie hesla',
    email: 'Emailová adresa',
    emailRequired: 'E-mailová adresa je povinná',
    emailInvalid: 'E-mailová adresa nie je platná',
    submit: 'Odoslať',
    errorOnEmail: message =>
        `Nepodarilo sa získať alebo analyzovať e-maily z parametrov dopytu: ${message}`
})
