import translations from './translations'

translations.add('es', {
    paymentView: 'Vista de pago',
    paymentLink: 'Enlace de pago',
    date: 'Fecha',
    orderNumber: 'Número de pedido',
    customer: 'Cliente',
    transactionType: 'Tipo de transacción',
    cancel: 'Cancelar',
    ok: 'Detalles completos de pago'
})
