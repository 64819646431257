import translations from './translations'

translations.add('el', {
    paymentView: 'Προβολή πληρωμής',
    paymentLink: 'Σύνδεσμος πληρωμής',
    date: 'Ημερομηνία',
    orderNumber: 'Αριθμός παραγγελίας',
    customer: 'Πελάτης',
    transactionType: 'Τύπος συναλλαγής',
    cancel: 'Ακύρωση',
    ok: 'Πλήρη στοιχεία πληρωμής'
})
