import {Moment} from 'moment'
import moment from 'moment'
import {Colors} from '@/constants'
import {Interval} from '@/stores/reports/models/Interval'

export enum DifferenceDescriptor {
    hour = 'hours',
    day ='days',
    week = 'weeks',
    month = 'months',
    year ='years'
}

const formatToHour = (hour: number): string => {
    if( hour < 10 ) {
        return `0${hour}`
    } else return `${hour}`
}

export const emptyChartDataGenerator = (dateRange: {from: Moment, to: Moment}, interval: Interval, isComparison = false) => {

    const {from, to} = dateRange

    const labels = []
    const data = []
    const prevData = []
    const diff = moment(to).diff(from, DifferenceDescriptor[interval]) + 1

    switch (interval) {
        case Interval.DAY:
            for(let i = 1; i <= diff; i++) {
                labels.push((moment(from).add(i - 1, DifferenceDescriptor[interval])).format('DD'))
                data.push(Math.floor(Math.random() * 30))
                prevData.push(Math.floor(Math.random() * 30))
            }
            break
        case Interval.YEAR:
            for(let i = 1; i <= diff; i++) {
                labels.push((moment(from).add(i - 1, DifferenceDescriptor[interval])).format('YYYY'))
                data.push(Math.floor(Math.random() * 30))
                prevData.push(Math.floor(Math.random() * 30))
            }
            break
        case Interval.MONTH:
            for(let i = 1; i <= diff; i++) {
                labels.push((moment(from).add(i - 1, DifferenceDescriptor[interval])).format('MMMM'))
                data.push(Math.floor(Math.random() * 30))
                prevData.push(Math.floor(Math.random() * 30))
            }
            break
        case Interval.WEEK:
            for(let i = 1; i <= diff; i++) {
                labels.push(`Week ${moment(from).add(i - 1, DifferenceDescriptor[interval]).week()}`)
                data.push(Math.floor(Math.random() * 30))
                prevData.push(Math.floor(Math.random() * 30))
            }
            break
        case Interval.HOUR:
            for(let i = 0; i < diff; i++) {
                labels.push(formatToHour(i))
                data.push(Math.floor(Math.random() * 30))
                prevData.push(Math.floor(Math.random() * 30))
            }
            break
    }

    if (isComparison) {
        return {
            labels,
            datasets: [
                {data: prevData, backgroundColor: [Colors.LIGHT_GRAY]},
                {data, backgroundColor: [Colors.SUPER_LIGHT_GRAY]}
            ]
        }
    }

    return {
        labels,
        datasets: [{data, backgroundColor: [Colors.LIGHT_GRAY]}]
    }
}

