import React from 'react'
import {Tabs as AntTabs} from 'antd'
import classNames from 'classnames'
import {TabsProps} from './props'
import styles from './styles.scss'

const { TabPane } = AntTabs

export const Tabs: React.FC<TabsProps> = (props) => {
    const { onChange, tabs = [], className, activeKey, ...rest } = props

    if (!tabs.length) {
        return null
    }

    const [firstTab] = tabs

    return <AntTabs
        className={classNames(styles.tabs, className)}
        defaultActiveKey={String(firstTab.key)}
        activeKey={activeKey}
        onChange={onChange}
        type='line'
        {...rest}
    >
        {
            tabs.map(tab => (
                <TabPane tab={tab.title} key={tab.key} disabled={tab.disabled}>
                    {tab.children}
                </TabPane>
            ))
        }
    </AntTabs>
}
