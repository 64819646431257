import React from 'react'
import {observer} from 'mobx-react-lite'
import {Navigate, Outlet} from 'react-router-dom'
import {AuthLayout} from '@/layouts'
import {Footer} from '@/components'
import {useAuthStore} from '@/stores'

export const UnAuthArea = observer(() => {
    return (
        <>
            <AuthLayout>
                <Outlet />
            </AuthLayout>
            <Footer />
        </>
    )
})
