import React from 'react'
import {Button, Checkbox, Col, Form, Modal, Row, Select} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {OnlinePaymentsExportsModalInterface} from '@/stores/transactions/OnlinePaymentsExportsStore'
import translations from './translations'
import {
    ADD_COLUMN_TO_EXPORT,
    CANCEL_EXPORT,
    COLUMNS_TYPE_TO_EXPORT,
    CUSTOM_FIELDS_TO_EXPORT,
    TABLE_EXPORT_BUTTON
} from '@/constants/playwright-ids'

const {useForm} = Form
export const OnlinePaymentsExportsModalStoreSymbol = Symbol('OnlinePaymentsExportsModalStore')

export const OnlinePaymentsExportsModal = observer(() => {
    const {
        options,
        exportTypeOptions,
        selectExportType,
        selectOption,
        fields,
        isButtonDisabled,
        isUploading,
        onlinePaymentsStore,
        handleCancel,
        selectedExportType,
        onFormSubmit
    } = useInjection<OnlinePaymentsExportsModalInterface>(OnlinePaymentsExportsModalStoreSymbol)

    const [form] = useForm()

    const renderCheckboxGroup = () => {
        return (
            <Checkbox.Group>
                <Row>
                    {Object.values(options).map((option: {value: string; label: string}) => {
                        return (
                            <Col span={8} key={option.value}>
                                <Checkbox
                                    data-test-id={ADD_COLUMN_TO_EXPORT + option.value}
                                    value={option}
                                    onChange={event => selectOption(event.target.value)}
                                >
                                    {option.label}
                                </Checkbox>
                            </Col>
                        )
                    })}
                </Row>
            </Checkbox.Group>
        )
    }

    const onSelectExportType = value => {
        if (value === 'custom') form.setFieldsValue({fields: []})
        selectExportType(value)
    }

    const onCancel = () => {
        form.setFieldsValue({columnsOption: 'all', fields})
        onSelectExportType('all')
        handleCancel()
    }

    const formSubmitHandler = () => {
        onFormSubmit()
        onCancel()
    }

    return (
        <>
            <Modal
                open={onlinePaymentsStore.isExportModalOpen}
                onCancel={onCancel}
                title={translations().title}
                footer={
                    <Row justify={'space-between'} style={{width: '100%'}}>
                        <Col>
                            <Button onClick={onCancel} data-test-id={CANCEL_EXPORT}>{translations().cancel}</Button>
                        </Col>
                        <Col>
                            <Button
                                loading={isUploading}
                                disabled={isButtonDisabled}
                                type='primary'
                                onClick={form.submit}
                                data-test-id={TABLE_EXPORT_BUTTON}
                            >
                                {translations().ok}
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Form form={form} onFinish={formSubmitHandler}>
                    <Form.Item data-test-id={COLUMNS_TYPE_TO_EXPORT} label={translations().columns} name={'columnsOption'} shouldUpdate>
                        <Select
                            defaultValue={selectedExportType}
                            options={exportTypeOptions}
                            onChange={onSelectExportType}
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                        {() => {
                            return form.getFieldValue('columnsOption') === 'custom' ? (
                                <Form.Item
                                    name={'fields'}
                                    data-test-id={CUSTOM_FIELDS_TO_EXPORT}
                                >{renderCheckboxGroup()}</Form.Item>
                            ) : null
                        }}
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                        {() => {
                            const labels = fields.map(field => options[field].label)
                            return <div>{labels.length ? labels.join(', ') : ''}</div>
                        }}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
})
