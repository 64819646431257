import translations from './translations'
import React from 'react'

translations.add('sl', {
    title: 'Apple Pay',
    description: (
        <>
            Apple Pay umožňuje spotrebiteľom jednoducho a rýchlo platiť za tovar a služby online.
            Spotrebitelia ukladajú informácie o svojich platobných kartách vo svojej peňaženke
            Apple, ktorú je možné vyvolať na ich zariadeniach Apple alebo vo webovom prehliadači
            Safari.
            <br />
            <br />
            DNA Payments s potešením ponúka obchodníkom tento spôsob platby a tu ho možno povoliť
            alebo zakázať.
            <br />
            <br />
        </>
    )
})
