import translations from './translations'

translations.add('sl', {
    showAll: 'Ukáž všetko',
    dataTypes: {
        amount: 'Množstvo',
        count: 'Číslo'
    },
    week: 'Týždeň'
})
