import React from 'react'
import {Divider, Grid} from 'antd'
import classNames from 'classnames'
import translations from '@/pages/Overview/components/AverageMetrics/translations'
import {getAmountWithCurrency} from '@/utils'
import {IconTitle} from '@/components'
import {Card} from '@/components/dumb/Card'
import {AverageMetricsProps} from './props'
import styles from './styles.scss'
import {
    AVERAGE_SUCCESS_AMOUNT,
    DAYLY_SUCCESS_AMOUNT,
    DAYLY_SUCCESS_TRANSACTIONS_NUMBER
} from '@/constants/playwright-ids'

export const AverageMetrics: React.FC<AverageMetricsProps> = ({
    isLoading,
    transactionsPerDay,
    transactionValue,
    dailyValue,
    currency
}) => {
    const screens = Grid.useBreakpoint()
    const isMobile = screens.xs
    const transactionsPerDayLabel = isNaN(transactionsPerDay) ? '-' : transactionsPerDay

    return (
        <Card
            title={
                <IconTitle
                    className={styles.title}
                    title={translations().averageMetrics}
                    tooltipText={translations().averageMetricsTooltip}
                />
            }
            loading={isLoading}
            className={classNames(styles.root, isMobile && styles.mobile)}
        >
            <table className='reset-table'>
                <tbody>
                    <tr>
                        <td className='amount' data-test-id={AVERAGE_SUCCESS_AMOUNT}>
                            {getAmountWithCurrency(transactionValue, currency)}
                        </td>
                        <td rowSpan={3}>
                            <Divider className={styles.divider} type={'vertical'} />
                        </td>
                        <td className={styles.text}>{translations().transactionsValue}</td>
                    </tr>
                    <tr>
                        <td
                            className='amount'
                            data-test-id={DAYLY_SUCCESS_AMOUNT}
                        >{getAmountWithCurrency(dailyValue, currency)}</td>
                        <td className={styles.text}>{translations().dailyValue}</td>
                    </tr>
                    <tr>
                        <td className='amount' data-test-id={DAYLY_SUCCESS_TRANSACTIONS_NUMBER}>{transactionsPerDayLabel}</td>
                        <td className={styles.text}>{translations().transactionsPerDay}</td>
                    </tr>
                </tbody>
            </table>
        </Card>
    )
}
