import React from 'react'
import {observer} from 'mobx-react'
import { BlockNotification } from '@/components/composite/notifications/BlockNotification'
import {useNotificationStore} from '@/stores/notification'
import {InitialSectionsConfigType} from '@/stores/notification/types'

export const Maintenance = observer(() => {
    const {sections} = useNotificationStore()
    const {app: appNotification} = sections as InitialSectionsConfigType

    return (
        <div>
            <BlockNotification notification={appNotification}/>
        </div>
    )
})
