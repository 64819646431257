import React, {useEffect} from 'react'
import {Form, Typography} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {SendMessageRequest} from '@/api'
import {Input} from '@/components'
import {UK_PHONE_NUMBER_PREFIX} from '@/constants'
import {NOT_EMPTY_PATTERN, PHONE_UK_NUMBER_PATTERN} from '@/constants/patterns'
import {PayByLinkSendMessageStoreSymbol} from '@/pages/PayByLink'
import {SendMessageStore} from '@/stores/pay-by-link/SendMessageStore'
import translations from './translations'
import {LinkShareFormProps} from './props'
import styles from './styles.scss'
import {RECIPIENT_EMAIL, RECIPIENT_PHONE} from '@/constants/playwright-ids'

const {Text} = Typography

export const LinkShareForm: React.FC<LinkShareFormProps> = observer((
    {type, customer, formId, form, id, senderNameHandler}
) => {
    const {sendMessage, merchantName} = useInjection<SendMessageStore>(PayByLinkSendMessageStoreSymbol)

    useEffect(() => {
        form.resetFields()
        senderNameHandler(merchantName)
    }, [form, senderNameHandler, merchantName])

    const onFinish = (values: SendMessageRequest) => {
        switch (type) {
            case 'email':
                values.channel = 'email'
                values.receiverContact = values.receiverContact.toLowerCase()
                sendMessage(id, values, customer)
                form.resetFields()
                break
            case 'sms':
                values.channel = 'sms'
                values.receiverContact = `${UK_PHONE_NUMBER_PREFIX}${values.receiverContact}`
                sendMessage(id, values, customer)
                form.resetFields()
                break
        }
    }

    const renderReceiverContact = () => {
        switch (type) {
            case 'email':
                return (
                    <Form.Item
                        label={`${customer}${translations().recipientsEmail}`}
                        name="receiverContact"
                        rules={[
                            {required: true, message: translations().errors.recipientEmailRequired},
                            {type: 'email', message: translations().errors.recipientEmailInvalid}
                        ]}
                    >
                        <Input data-test-id={RECIPIENT_EMAIL} placeholder={translations().recipientEmail}/>
                    </Form.Item>
                )
            case 'sms':
                return (
                    <Form.Item
                        label={`${customer}${translations().recipientsNumber}`}
                        name="receiverContact"
                        rules={[
                            {required: true, message: translations().errors.recipientNumberRequired},
                            {pattern: PHONE_UK_NUMBER_PATTERN, message: translations().errors.recipientNumberInvalid}
                        ]}
                    >
                        <Input
                            placeholder={translations().recipientNumber}
                            maxLength={10}
                            prefix={
                                <Text className={styles.prefix}>
                                    {UK_PHONE_NUMBER_PREFIX}
                                </Text>
                            }
                            data-test-id={RECIPIENT_PHONE}
                        />
                    </Form.Item>
                )
        }
    }

    return (
        <Form
            form={form}
            id={formId}
            layout={'vertical'}
            initialValues={{remember: true, senderName: merchantName}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                label={translations().recipientName}
                name="senderName"
                rules={[
                    {required: true, message: translations().errors.recipientNameRequired},
                    {pattern: NOT_EMPTY_PATTERN, message: translations().errors.recipientNameRequired}
                ]}
            >
                <Input onChange={(event) => senderNameHandler(event.target.value)} placeholder={translations().recipientName}/>
            </Form.Item>
            {renderReceiverContact()}
        </Form>
    )
})
