import CryptoJS from 'crypto-js'
import {storage} from '@/services/storage'
import {CRYPTO_KEY, PRODUCTS_KEY} from '@/stores/auth/constants/configurations'
import {ACCESS_TOKEN_KEY} from '@/constants/auth-constants'
import {ROUTES} from '@/router/routes'
import {routesPermissionMap} from '@/router/routes-permission-map'
import {getRouterStore} from '@/router/utils'
import {PermissionOrder} from '@/stores/auth/constants/permission-order'

export const savePermissions = (permissions: string[]) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(permissions), CRYPTO_KEY).toString()
    storage.set('permissions', encrypted)
}

export const getPermissions = () => {
    const encrypted = storage.get('permissions')
    if (!encrypted) return null

    const bytes = CryptoJS.AES.decrypt(encrypted, CRYPTO_KEY)
    const decrypted = bytes.toString(CryptoJS.enc.Utf8)
    return JSON.parse(decrypted || '[]')
}

export const hasPermissions = (permissions: string[]) => {
    if (!permissions || permissions.length === 0) return true
    return permissions.some((p) => getPermissions()?.indexOf(p) >= 0)
}

export const getProducts = () => {
    const products = storage.get(PRODUCTS_KEY)
    if (!products) return null

    return JSON.parse(products || '{}')
}

export const hasProducts = (products: string[]) => {
    if (!products || products.length === 0) return true
    return products.some((p) => getProducts() && getProducts()[p])
}

export const getRouteByPermission = (permission: string) => {
    for (const key in routesPermissionMap) {
        const value = routesPermissionMap[key]

        if (typeof value === 'string' && value === permission) {
            return key
        } else if (typeof value === 'object' && permission in value) {
            return key
        } else if (typeof value === 'object') {
            for (const nestedKey in value) {
                const nestedValue = value[nestedKey]

                if (typeof nestedValue === 'string' && nestedValue === permission) {
                    return key
                }
            }
        }
    }

    return ROUTES.home
}

export const getHomeUrl = (permissions: string[]) => {
    for (const permissionName in PermissionOrder) {
        const permission = PermissionOrder[permissionName]

        if (permissions?.includes(permission)) {
            const route = getRouteByPermission(permission)
            if (route) {
                return route
            }
        }
    }

    return ROUTES.noPermissions
}

export const navigateUserAfterLogin = (permissions: string[]) => {
    getRouterStore().push(getHomeUrl(permissions))
}

export const isAuthenticated = () => Boolean(storage.get('email') && storage.get(ACCESS_TOKEN_KEY))

export const isLoginPage = () => window.location.pathname === ROUTES.login
