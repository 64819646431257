import translations from './translations'
import React from 'react'

translations.add('ro', {
    messageTextPreview: 'Preview-ul textului mesajului:',
    message: (name) => `Ați primit acest mesaj cu o cerere de plată de la ${name ? name : 'Numele expeditorului'}. Vă rugăm să urmați link-ul pentru a începe plata:`,
    emailMessage: (senderName, recipientName) => (
        <div>
            <div>Dear {recipientName}</div>
            <div>{senderName ? senderName : 'Numele expeditorului'} vă solicită să efectuați o plată.
                Vă rugăm să urmați link-ul de mai jos și să selectați metoda dvs. preferată de plată.
            </div>
        </div>
    )
})
