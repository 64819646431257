import { injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'

import { FilterFormFieldType } from '@/types'
import { BaseFilterContainerStore } from './BaseFilterContainerStore'

@injectable()
export class FilterContainerStore<T> extends BaseFilterContainerStore<T> {

    get filter() {
        return this._filter
    }

    constructor(
        public fields: FilterFormFieldType[]
    ) {
        super()
        makeAutoObservable(this)
    }

    setFields(fields: FilterFormFieldType[]) {
        this.fields = fields
    }

    getFilterForBackend(): Partial<T> {
        return this.filter
    }
}
