import {injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import {DoughnutChartData} from '@/components'
import {openErrorNotification} from '@/utils'
import {TRangePickerContainerStore, TSelectContainerStore} from '@/components/containers'
import {getDoughnutChartAmountData, getDoughnutChartCountData} from '@/pages/Reports'
import {LoadingState} from '@/types'
import {emptyDoughnutChartData} from '@/services/mocks'
import {
    DoughnutChartType,
    DoughnutTabType,
    PosTransactionSummaryDoughnutChartCardStoreType
} from '@/pages/Reports/components'
import {getPOSTopSummaryByType} from '@/stores/reports/services/fetchers'
import {POSTopSummaryRequest} from '@/stores'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol,
    PosStoreSelectStore
} from '@/stores/store-and-terminals'
import {Currencies} from '@/constants'
import {subscribe} from 'dna-react-ioc'
import {getPosMostUsedDefaultCurrency} from '@/stores/handbooks/services'
import translations from '@/translations'

@injectable()
export class POSTransactionSummaryDoughnutChartStore
    implements PosTransactionSummaryDoughnutChartCardStoreType
{
    private _rangePickerStore: TRangePickerContainerStore
    private _statusSelectStore: TSelectContainerStore
    private _currencySelectStore: TSelectContainerStore
    private readonly _storeSelectStore: PosStoreSelectStore
    private _merchantStoresStore: AvailableStoresDataStore

    constructor(
        rangePickerStore: TRangePickerContainerStore,
        statusSelectStore: TSelectContainerStore,
        currencySelectStore: TSelectContainerStore,
        type: DoughnutChartType,
        storeSelectStore: PosStoreSelectStore,
        private _parseLabel?: (label: any) => string
    ) {
        this._rangePickerStore = rangePickerStore
        this._statusSelectStore = statusSelectStore
        this._currencySelectStore = currencySelectStore
        this._merchantStoresStore = subscribe<AvailableStoresDataStore>(
            AvailableStoresDataStoreSymbol
        )
        this._storeSelectStore = storeSelectStore
        this._type = type

        makeObservable(this, {
            chartType: observable,
            _amountData: observable,
            _countData: observable,
            amountLoadingState: observable,
            countLoadingState: observable,
            amountData: computed,
            countData: computed,
            filter: computed,
            setChartTabType: action.bound
        })

        reaction(
            () => ({
                from: this._rangePickerStore.startDate,
                to: this._rangePickerStore.endDate,
                currency: this._currencySelectStore.value,
                isTerminalsLoaded: this._storeSelectStore.loadingState === LoadingState.DONE,
                store: this._storeSelectStore.value
            }),
            ({from, to, currency, isTerminalsLoaded}) => {
                if (from && to && currency && isTerminalsLoaded) {
                    this.loadAmountTransactionChartData()
                    this.loadCountTransactionChartData()
                }
            }
        )
    }

    _type: DoughnutChartType
    _amountData: {
        all: DoughnutChartData
        successful: DoughnutChartData
        failed: DoughnutChartData
    } = {
        all: null,
        successful: null,
        failed: null
    }
    _countData: {
        all: DoughnutChartData
        successful: DoughnutChartData
        failed: DoughnutChartData
    } = {
        all: null,
        successful: null,
        failed: null
    }
    chartType = DoughnutTabType.amount
    amountLoadingState = LoadingState.IDLE
    countLoadingState = LoadingState.IDLE

    get filter(): Partial<POSTopSummaryRequest> {
        return {
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate,
            currency: this._currencySelectStore.value
        }
    }

    async loadAmountTransactionChartData() {
        if (this.amountLoadingState === LoadingState.LOADING || !this.type) {
            return
        }

        runInAction(() => {
            this.amountLoadingState = LoadingState.LOADING
        })

        const request: POSTopSummaryRequest = {
            from: this._rangePickerStore.startDate.format(),
            to: this._rangePickerStore.endDate.format(),
            currency: this._currencySelectStore.value,
            top: 5,
            orderBy: 'amount',
            ...this._storeSelectStore.requestForServer
        }

        try {
            const {result, error} = await getPOSTopSummaryByType(request, this.type)

            runInAction(() => {
                this.amountLoadingState = LoadingState.DONE

                if (error) {
                    this.amountLoadingState = LoadingState.FAILED
                    return openErrorNotification(error.message)
                }

                this._amountData = {
                    all:
                        result?.all?.length > 0
                            ? getDoughnutChartAmountData(result?.all, this._parseLabel)
                            : emptyDoughnutChartData,
                    successful:
                        result?.successful?.length > 0
                            ? getDoughnutChartAmountData(result?.successful, this._parseLabel)
                            : emptyDoughnutChartData,
                    failed:
                        result?.failed?.length > 0
                            ? getDoughnutChartAmountData(result?.failed, this._parseLabel)
                            : emptyDoughnutChartData
                }
            })
        } catch (error) {
            runInAction(() => {
                this.amountLoadingState = LoadingState.FAILED
                return openErrorNotification(translations().errors.general)
            })
        }
    }

    async loadCountTransactionChartData() {
        if (this.countLoadingState === LoadingState.LOADING || !this.type) {
            return
        }

        runInAction(() => {
            this.countLoadingState = LoadingState.LOADING
        })

        const request: POSTopSummaryRequest = {
            from: this._rangePickerStore.startDate.format(),
            to: this._rangePickerStore.endDate.format(),
            currency: this._currencySelectStore.value,
            top: 5,
            orderBy: 'count',
            ...this._storeSelectStore.requestForServer
        }

        try {
            const {result, error} = await getPOSTopSummaryByType(request, this.type)

            runInAction(() => {
                this.countLoadingState = LoadingState.DONE

                if (error) {
                    this.countLoadingState = LoadingState.FAILED
                    return openErrorNotification(error.message)
                }

                this._countData = {
                    all:
                        result?.all?.length > 0
                            ? getDoughnutChartCountData(result?.all, this._parseLabel)
                            : emptyDoughnutChartData,
                    successful:
                        result?.successful?.length > 0
                            ? getDoughnutChartCountData(result?.successful, this._parseLabel)
                            : emptyDoughnutChartData,
                    failed:
                        result?.failed?.length > 0
                            ? getDoughnutChartCountData(result?.failed, this._parseLabel)
                            : emptyDoughnutChartData
                }
            })
        } catch (error) {
            runInAction(() => {
                this.countLoadingState = LoadingState.FAILED
                return openErrorNotification(translations().errors.general)
            })
        }
    }

    setChartTabType(chartType: DoughnutTabType) {
        this.chartType = chartType
    }

    get currency(): Currencies {
        return this._currencySelectStore.value as Currencies
    }

    get type(): DoughnutChartType {
        return this._type
    }

    set type(value: DoughnutChartType) {
        this._type = value
    }

    get amountData() {
        if (!this._currencySelectStore.value && this._merchantStoresStore.ecomStores) {
            this._currencySelectStore.setValue(
                getPosMostUsedDefaultCurrency(this._merchantStoresStore.ecomStores)
            )
        }

        if (this._storeSelectStore.loadingState !== LoadingState.DONE) {
            return emptyDoughnutChartData
        }

        const amountByStatus = this._amountData[this._statusSelectStore.value]

        if (!amountByStatus && this._currencySelectStore.value) {
            this.loadAmountTransactionChartData()
            return emptyDoughnutChartData
        }

        return amountByStatus
    }

    get countData() {
        if (!this._currencySelectStore.value && this._merchantStoresStore.ecomStores) {
            this._currencySelectStore.setValue(
                getPosMostUsedDefaultCurrency(this._merchantStoresStore.ecomStores)
            )
        }

        if (this._storeSelectStore.loadingState !== LoadingState.DONE) {
            return emptyDoughnutChartData
        }

        const countByStatus = this._countData[this._statusSelectStore.value]

        if (!countByStatus && this._currencySelectStore.value) {
            this.loadCountTransactionChartData()
            return emptyDoughnutChartData
        }

        return countByStatus
    }
}
