import translations from './translations'

translations.add('es', {
    title: 'Exportación',
    documentType: 'Tipo de documento',
    id: 'ID',
    fileName: 'Nombre de archivo',
    dateRequested: 'Fecha Solicitada',
    lastUpdatedDate: 'Última actualización',
    status: 'Estado',
    statuses: {
        STARTED: 'Iniciado',
        PROCESSING: 'En proceso',
        QUEUED: 'En cola',
        FINISHED: 'Terminado',
        FAILED: 'Fallido'
    }
})
