import translations from './translations'

translations.add('el', {
    charge: 'Χρέωση',
    refund: 'Επιστροφή χρημάτων',
    cancel: 'Ακύρωση',
    paymentCharge: 'Χρέωση πληρωμής',
    paymentRefund: 'Επιστροφή πληρωμών',
    paymentAmount: 'Ποσό πληρωμής',
    balance: 'Υπόλοιπο',
    chargeAmount: 'Ποσό χρέωσης',
    refundAmount: 'Ποσό επιστροφής χρημάτων',
    pending: 'Εκκρεμή',
    errors: {
        positiveAmount: 'Το ποσό επιστροφής πρέπει να είναι ένα θετικό αριθμό',
        amountRefundExceed: 'Το ποσό επιστροφής χρημάτων πρέπει να είναι θετικός αριθμός όχι μεγαλύτερος από το υπόλοιπο',
        amountChargeExceed: 'Το ποσό χρέωσης πρέπει να είναι θετικός αριθμός όχι μεγαλύτερος από το ποσό πληρωμής'
    }
})
