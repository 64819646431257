import translations from './translations'

translations.add('ro', {
    title: 'Opțiuni de chitanță de plată',
    buttons: {
        send: 'Trimite',
        receipt: 'chitanță',
        downloadReceipt: 'Descarcă chitanța'
    },
    labels: {
        email: 'E-mailul clientului',
        emailPlaceholder: 'Adresă de email',
        date: 'Dată',
        description: 'Descrierea comenzii',
        paymentMethod: 'Metodă de plată',
        orderNumber: 'Număr comandă'
    },
    errors: {
        emailInvalid: 'Adresa de email este invalidă',
        emailRequired: 'Adresa de email este obligatorie'
    }
})
