import translations from './translations'

translations.add('sl', {
    pendingTransactions: number => `Čakajúce transakcie (${number})`,
    description: 'Popis',
    sum: 'Súčet',
    transactionStatus: {
        PROCESSED: 'Processed',
        PENDING: 'Prebiehajúci',
        FAILED: 'Zlyhalo',
        CANDELLED: 'Zrušené'
    },
    all: 'Všetko'
})
