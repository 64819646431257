import {inject, injectable} from 'inversify'
import {action, makeObservable} from 'mobx'
import {PaymentLink} from '@/api'
import {
    PayByLinkCancelPaymentLinkStoreSymbol,
    PayByLinkCreateNewLinkStoreSymbol,
    PayByLinkEditPaymentLinkStoreSymbol,
    PayByLinkPaymentLinkDetailsStoreSymbol
} from '@/pages/PayByLink'
import {CancelPaymentLinkStore} from '@/stores'
import {
    TEditPaymentLinkStore,
    TLinkActionsMenuStore,
    TNewPaymentLinkModalStore,
    TPaymentLinkDetailsModalStore
} from '@/pages/components'

@injectable()
export class LinkActionsMenuStore implements TLinkActionsMenuStore {
    private readonly _newPaymentLinkModalStore: TNewPaymentLinkModalStore
    private readonly _paymentLinkDetailsModalStore: TPaymentLinkDetailsModalStore
    private readonly _editPaymentLinkStore: TEditPaymentLinkStore
    private readonly _cancelPaymentLinkStore: CancelPaymentLinkStore

    constructor(
        @inject(PayByLinkCreateNewLinkStoreSymbol)
        newPaymentLinkModalStoreStore: TNewPaymentLinkModalStore,
        @inject(PayByLinkPaymentLinkDetailsStoreSymbol)
        paymentLinkDetailsModalStore: TPaymentLinkDetailsModalStore,
        @inject(PayByLinkEditPaymentLinkStoreSymbol) editPaymentLinkStore: TEditPaymentLinkStore,
        @inject(PayByLinkCancelPaymentLinkStoreSymbol)
        cancelPaymentLinkStore: CancelPaymentLinkStore
    ) {
        this._newPaymentLinkModalStore = newPaymentLinkModalStoreStore
        this._paymentLinkDetailsModalStore = paymentLinkDetailsModalStore
        this._editPaymentLinkStore = editPaymentLinkStore
        this._cancelPaymentLinkStore = cancelPaymentLinkStore

        makeObservable(this, {
            createSimilarPaymentLink: action.bound,
            setSharePaymentLink: action.bound,
            cancelPaymentLink: action.bound,
            openEditModal: action.bound,
            closeModals: action.bound
        })
    }

    closeModals() {
        this._newPaymentLinkModalStore.closeModals()
    }

    openEditModal(paymentLink: PaymentLink) {
        this._editPaymentLinkStore.openEditModal(paymentLink)
    }

    setSharePaymentLink(paymentLink: PaymentLink) {
        this._paymentLinkDetailsModalStore.widgetType = 'shareViaEmail'
        this._paymentLinkDetailsModalStore.isNewLinkDetails = false
        this._paymentLinkDetailsModalStore.setPaymentLink(paymentLink)
    }

    createSimilarPaymentLink(paymentLink: PaymentLink) {
        this._newPaymentLinkModalStore.createSimilarPaymentLink(paymentLink)
    }

    cancelPaymentLink(id: string) {
        this._cancelPaymentLinkStore.cancelPaymentLink(id)
    }
}
