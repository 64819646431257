import translations from './translations'

translations.add('en', {
    title: 'Settlements',
    download: 'Download statement',
    perDay: 'Per day',
    perTransaction: 'Per transaction',
    description1:
        'Please be aware that occasionally, your Settlements may run until 5pm on working days, but they will be automatically deposited into your bank account.',
    description2:
        'Remember, American Express POS payments made via DNA Payments are settled directly by American Express and won’t appear on your Settlement Statements. You have the ability to monitor these transactions at any time using the Merchant Portal.'
})
