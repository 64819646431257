import translations from './translations'

translations.add('en', {
    buttons: {
        processNewPayment: 'Process new payment',
        cancelPayment: 'Cancel payment',
        charge: 'Charge',
        refund: 'Refund'
    },
    transactions: 'Transactions',
    transactionDetails: 'Transaction details',
    noDescription: 'No description',
    refundDisabledTooltip: 'The Customer\'s bank does not support Open Banking refunds'
})
