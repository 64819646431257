import React from 'react'
import classNames from 'classnames'
import translations from './translations'
import {CookiesLinksProps} from './props'
import styles from './styles.scss'

export const CookiesLinks: React.FC<CookiesLinksProps> = ({className}) => {
    return <div className={classNames(styles.links, className)}>
        <a href="https://www.dnapayments.com/page/cookie-policy" target={'_blank'} rel="noreferrer">{translations().cookiesPolicy}</a>
        <a href="https://www.dnapayments.com/page/privacy-policy" target={'_blank'} rel="noreferrer">{translations().privacyPolicy}</a>
        <a href="https://www.dnapayments.com/page/terms-use" target={'_blank'} rel="noreferrer">{translations().termsAndConditions}</a>
    </div>
}
