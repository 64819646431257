import translations from './translations'

translations.add('es', {
    title: 'Enlaces de pago',
    paymentDetails: 'Detalles del pago',
    chargedAmount: 'Cantidad cobrada',
    expirationDate: 'Fecha de caducidad',
    dateCreated: 'Fecha de creacion',
    summary: 'Resumen',
    channel: 'Canal',
    description: 'Descripción',
    amount: 'Cantidad',
    status: 'Estado',
    customer: 'Cliente',
    action: 'Acción',
    statement: 'Declaración',
    statuses: {
        active: 'Activo',
        expired: 'Venció',
        paid: 'Pagado',
        cancelled: 'Cancelado',
        viewed: 'Visto',
        attempted: 'Intentó',
        all: 'Todo'
    }
})
