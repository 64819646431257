import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Col, Divider, Row, Typography} from 'antd'
import {Link} from 'react-router-dom'

import {CardStatus} from '@/stores'
import {Button, Card, Description, Icon} from '@/components'
import {
    CardBlockModal,
    CardFreezeModal,
    GetPINModal
} from '@/pages/business-account/CardsManagement'
import {GetCVVModal} from '@/pages/business-account/CardsManagement/components/GetCVVModal/GetCVVModal'
import {CardSettingsStoreInterface} from './CardSettingsStoreInterface'
import translations from './translations'
import {CardSettingsProps} from './props'
import styles from './styles.scss'
import {COPY_ICON} from '@/constants/playwright-ids'

export const CardSettingsStoreSymbol = Symbol('CardSettingsStore')

export const CardSettings: React.FC<CardSettingsProps> = observer(({cardData}) => {
    const store = useInjection<CardSettingsStoreInterface>(CardSettingsStoreSymbol)

    const dataSource = [
        {
            label: translations().cardholder,
            value: cardData.name
        }
    ]

    return (
        <Card
            title={translations().title}
            extra={<Icon type={'copy'} className={styles.copy} data-test-id={COPY_ICON} />}
            className={styles.card}
        >
            <Row gutter={[16, 16]}>
                <Col xs={11} sm={9}>
                    <div className={styles.cardNumber}>{cardData.number}</div>
                    <div>{cardData.date}</div>
                </Col>
                <Col xs={3} sm={2}>
                    <Divider type={'vertical'} className={styles.divider} />
                </Col>
                <Col xs={10} sm={13}>
                    <Description dataSource={dataSource} />
                </Col>
            </Row>
            <br />
            {/*<Button className={styles.getCVV} onClick={store.openGetCVVModal}>{translations().getCVV}</Button>*/}
            <Button onClick={store.openGetPINModal}>{translations().getPIN}</Button>
            <Divider type={'horizontal'} />
            <Row justify={'space-between'}>
                <Col>
                    <Typography.Title level={4}>
                        {translations().freeze.freezeCard}
                    </Typography.Title>
                    <Typography.Text type={'secondary'}>
                        {translations().freeze.inCaseYouWantToFreeze}
                    </Typography.Text>
                </Col>
                <Col className={styles.freezeCardColumn}>
                    <Link
                        className={styles.freezeCardLink}
                        to={'#'}
                        onClick={store.openFreezeCardModal}
                    >
                        {translations().freeze.freezeCard}
                    </Link>
                </Col>
            </Row>
            <Divider type={'horizontal'} />
            {cardData.status === CardStatus.ACTIVE && (
                <Row justify={'space-between'}>
                    <Col>
                        <Typography.Title type={'danger'} level={4}>
                            {translations().block.blockCard}
                        </Typography.Title>
                        <Typography.Text type={'danger'}>
                            {translations().block.inCaseYouWantToBlock}
                        </Typography.Text>
                    </Col>
                    <Col className={styles.blockCardColumn}>
                        <Button danger onClick={store.openBlockCardModal}>
                            {translations().block.blockCard}
                        </Button>
                    </Col>
                </Row>
            )}
            {cardData.status === CardStatus.NOT_ACTIVATED && (
                <Row justify={'space-between'}>
                    <Col>
                        <Typography.Title type={'success'} level={4}>
                            {translations().activate.activateCard}
                        </Typography.Title>
                        <Typography.Text type={'success'}>
                            {translations().activate.inCaseYouWantToActivate}
                        </Typography.Text>
                    </Col>
                    <Col className={styles.blockCardColumn}>
                        <Button onClick={store.openBlockCardModal}>
                            {translations().activate.activateCard}
                        </Button>
                    </Col>
                </Row>
            )}
            <CardBlockModal
                open={store.isBlockModalVisible}
                loadingState={store.changeCardStatusLoadingState}
                onChangeCardStatus={store.onChangeCardStatus}
                onCancel={store.closeBlockCardModal}
            />
            <CardFreezeModal
                open={store.isFreezeModalVisible}
                onCancel={store.closeFreezeCardModal}
            />
            <GetCVVModal
                loadingState={store.getCVVLoadingState}
                open={store.isGetCVVModalVisible}
                onCancel={store.closeGetCVVModal}
                cvvData={store.cvvData}
                onGetCVVClick={store.onGetCVVClick}
            />
            <GetPINModal
                loadingState={store.getPINLoadingState}
                open={store.isGetPINModalVisible}
                onCancel={store.closeGetPINModal}
                pinData={store.pinData}
                onGetPINClick={store.onGetPINClick}
            />
        </Card>
    )
})
