import React from 'react'
import classNames from 'classnames'
import {ManagerTipProps} from './props'
import styles from './styles.scss'

export const ManagerTip: React.FC<ManagerTipProps> = (props: ManagerTipProps) => {
    const { text, className } = props
    return <div className={classNames(styles.body, className)}>
        <div className={styles.avatar}/>
        <div className={styles.text}>{text}</div>
    </div>
}
