import React from 'react'
import { Popover as AntPopover } from 'antd'
import classNames from 'classnames'
import { PopoverProps } from './props'
import styles from './styles.scss'

export const Popover: React.FC<PopoverProps> = ({ overlayClassName, className, children, ...rest}) => {
    return <AntPopover overlayClassName={classNames(styles.popover, overlayClassName)} className={className} {...rest}>
        {children}
    </AntPopover>
}
