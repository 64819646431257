import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import translations from '@/pages/Reports/translations/translations'
import {TransactionSummaryDoughnutChartCard} from '@/components/composite'
import {EcomTransactionSummaryDoughnutChartCardStoreType} from '@/pages/Reports/components'
import {TSelectContainerStore} from '@/components/containers'

export const CMStoreSymbol = Symbol('CMStoreSymbol')
export const CMStatusStoreSymbol = Symbol('CMStatusStore')

export const CMDoughnutChartContainer = observer(() => {
    const store = useInjection<EcomTransactionSummaryDoughnutChartCardStoreType>(CMStoreSymbol)
    const selectStore = useInjection<TSelectContainerStore>(CMStatusStoreSymbol)

    return (
        <TransactionSummaryDoughnutChartCard
            tooltipText={translations().cardPayments}
            amountData={store.amountData}
            countData={store.countData}
            tabType={store.chartType}
            setChartType={store.setChartTabType}
            title={translations().cardPayments}
            centerTitle={translations().volumeTotal}
            selectStatusProps={{
                value: selectStore.value,
                options: selectStore.options,
                onChange: selectStore.setValue
            }}
            loadingState={store.amountLoadingState}
            currency={store.currency}
        />
    )
})
