import translations from './translations'

translations.add('el', {
    buttons: {
        processNewPayment: 'Επεξεργασία νέας πληρωμής',
        cancelPayment: 'Ακύρωση πληρωμής',
        charge: 'Χρέωση',
        refund: 'Επιστροφή χρημάτων'
    },
    transactions: 'Συναλλαγές',
    transactionDetails: 'Λεπτομέρειες Συναλλαγής',
    noDescription: 'Χωρίς περιγραφή',
    refundDisabledTooltip: 'The Customer\'s bank does not support Open Banking refunds'
})
