import translations from './translations'

translations.add('ro', {
    title: 'Rapoarte',
    transactions: 'Tranzacții',
    transactionsComparison: 'Comparație tranzacții',
    calendarTitle: 'Luna trecută',
    paymentMethods: 'Metode de plată',
    cardPayments: 'Metode de plată cu cardul',
    issuingBanks: 'Bănci emitente (doar plăți cu cardul)',
    amount: 'Sumă',
    count: 'Număr',
    volumeTotal: 'Volum total',
    previous: 'Anterior',
    current: 'Curent',
    downloadLink: 'Descarcă',
    cardScheme: 'Schema cardului',
    onlinePayments: 'Plăți online',
    posPayments: 'Plăți POS',
    currency: 'Monedă',
    noContentTitle: 'Nu există conținut disponibil',
    empty: 'Gol'
})
