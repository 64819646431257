import {useEffect, useState} from 'react'

export const useWaitForTimeoutOrChange = (isConditionTrue: boolean) => {
    const [isConditionMet, setIsConditionMet] = useState(false)

    useEffect(() => {
        let isCancelled = false

        const waitForCondition = async () => {
            try {
                await Promise.race([
                    new Promise<void>((resolve) => setTimeout(resolve, 1000)), // Wait for 1 second
                    new Promise<void>((resolve) => {
                        const interval = setInterval(() => {
                            if (!isCancelled && isConditionTrue) {
                                resolve()
                                clearInterval(interval)
                            }
                        }, 100) // Check every 100ms if isAppBlocked has changed
                    })
                ])

                if (!isCancelled) {
                    setIsConditionMet(true)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }
        waitForCondition()
        // Cleanup
        return () => {
            isCancelled = true
        }
    }, [isConditionTrue])

    return isConditionMet
}
