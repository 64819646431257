import translations from './translations'


translations.add('el', {
    amount: 'Ποσό',
    currency: 'Νόμισμα',
    errors: {
        amountRequired: 'Απαιτείται το ποσό',
        amountInvalid: 'Το ποσό πρέπει να είναι μεγαλύτερο από 0'
    }
})
