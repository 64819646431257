import {injectable} from 'inversify'
import {action, makeObservable, observable} from 'mobx'
import {SelectItem} from '@/components/dumb/Select'
import 'reflect-metadata'
import {Tab} from '@/components/dumb/Tabs/props'
import {TSelectContainerStore, TTabsContainerStore} from '@/components'

@injectable()
export class StatusStore implements TTabsContainerStore, TSelectContainerStore{
    constructor() {
        makeObservable(this, {
            value: observable,
            options: observable,
            tabs: observable,
            setOptions: action.bound,
            setValue: action.bound
        })
    }

    public options: SelectItem[] = []
    public tabs: Tab[] = []
    public value: string = null

    public clear() {
        this.value = null
    }

    public setTabs(tabs: Tab[]): void {
        this.tabs = tabs
    }

    public setOptions(options: SelectItem[]): void {
        this.options = options
    }

    setValue(value): void {
        this.value = value
    }
}
