import translations from './translations'

translations.add('ro', {
    showAll: 'Afișează tot',
    previousRangeData: 'Anterior',
    currentRangeData: 'Curent',
    statuses: {
        AUTH: 'În așteptare',
        CHARGE: 'Încărcat',
        REFUND: 'Rambursat',
        REJECT: 'Respins'
    },
    dataTypes: {
        amount: 'Sumă',
        count: 'Număr'
    },
    week: 'Săptămână'
})
