import React from 'react'
import {observer} from 'mobx-react'
import {Divider, Typography} from 'antd'
import {ActionButtons, LinkActionsMenu} from '@/pages/components'
import {Icon, TableStatus} from '@/components'
import {
    getPayByLinkPaymentStatusExtraIconType,
    getPayByLinkPaymentStatusIconType,
    getPayByLinkPaymentStatusName,
    getPayByLinkPaymentStatusTagType
} from '@/pages/PayByLink/utils'
import translations from './translations'
import {LinkDetailsModalHeaderProps} from './props'
import styles from './styles.scss'
import {ORDER_NUMBER_WITH_STATUS} from '@/constants/playwright-ids'

const {Text} = Typography

export const LinkDetailsModalHeader: React.FC<LinkDetailsModalHeaderProps> = observer(({createdPaymentLink}) => {
    return (
        <div className={styles.container}>
            <div className={styles.orderNumber} data-test-id={ORDER_NUMBER_WITH_STATUS}>
                <Text strong>{translations().orderNumber} {createdPaymentLink.invoiceId}</Text>
                <Divider type={'vertical'}/>
                <TableStatus
                    statusProps={{
                        type: getPayByLinkPaymentStatusTagType(createdPaymentLink.status),
                        iconType: getPayByLinkPaymentStatusIconType(createdPaymentLink.status),
                        status: getPayByLinkPaymentStatusName(createdPaymentLink.status)
                    }}
                    extra={<Icon type={getPayByLinkPaymentStatusExtraIconType(createdPaymentLink.status)}/>}
                />
            </div>
            <ActionButtons menu={<LinkActionsMenu paymentLink={createdPaymentLink}/>}/>
        </div>
    )
})
