import {TranslationBuilder} from '@/components/dumb/Translation'
import {PaymentMethodType} from '@/constants'

export default TranslationBuilder.create<{
    description: string
    customer: string
    date: string
    orderNumber: string
    paymentMethod: string
}>()
