import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    paymentDetails: string
    chargedAmount: string
    refundAmount: string
    balance: string
    donationAmount: string
    date: string
    status: string
    summary: string
    verifications: string
    channel: string
    shop: string
    charge: string
    refund: string
    search: string
    statement: string
    select: string
    items: string
    description: string
    customer: string
    orderNumber: string
    amount: string
    rnn: string
    transactionType: string
    authorizedOn: string
    chargedOn: string
    refundedOn: string
    issuer: string
    card: string
    is3DSecure: string
    cardExpiryDate: string
    acquirerResponseCode: string
    authCode: string
    AVSHouseNumberResult: string
    AVSPostcodeResult: string
    yes: string
    no: string
    payerDetails: string
    payer: string
    accountId: string
    email: string
    phone: string
    resultMessage: string
    geolocation: {
        payerIp: string
        location: string
    }
    paymentPage: {
        title: string
        language: string
        postLinkAddress: string
        postLink: string
        ok: string
        failed: string
    }
    signifydDetails: string
    signifydId: string
    decision: string
    reason: string
    score: string
    id: string
    processingTypeName: string
    reference: string
    cancelOn: string
    avsResult: string
    payPal: {
        details: string
        paypalOrderStatus: string
        paypalPayerName: string
        paypalEmail: string
        paypalPayerID: string
        paypalPayerCountryCode: string
        paypalCaptureStatus: string
        paypalCaptureStatusReason: string
        paypalSellerProtectionStatus: string
        paypalSellerProtectionDisputeCategories: string
    }
    searchFilter: {
        transactionId: string
        customerAccountId: string
        order: string
        email: {
            startsWith: string
        }
        customerName: string
        description: string
        card: {
            endsWith: string
            startsWith: string
        }
    }
    searchFilterErrors: {
        transactionId: string
    }
    createdDate: string
    payoutAmount: string
    currency: string
    payoutDate: string
    processingType: string
    invoiceId: string
    paymentMethod: string
    cardType: string
    cardMask: string
    secure3D: string
    avsHouseNumberResult: string
    avsPostcodeResult: string
    cscResult: string
    paEnrollment: string
    paAuthentication: string
    payerName: string
    payerEmail: string
    payerPhone: string
    payerIp: string
    ipCountry: string
    ipCity: string
    ipLatitude: string
    ipLongitude: string
    message: string
    transactionTypeLower: string
    initiatorEmail: string
    statuses: {
        Matched: string
        'Not Matched': string
        'Partial Match': string
        Yes: string
        No: string
        Authenticated: string
        'Not authenticated': string
        'Unable to authenticate': string
        Attempted: string
        'Not attempted': string
        Enrolled: string
        'Not enrolled': string
        'Challenge required': string
        'Not Provided': string
        'Not Checked': string
        'Unable to check': string
        'Not Set': string
        'Authentication rejected': string
    }
}>()
