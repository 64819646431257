import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {PaymentMethodDetails, TGooglePayPaymentMethodStore} from '@/pages/payment-methods'
import {PaymentMethodsGooglePayStoreSymbol} from '@/pages/payment-methods/PaymentMethods/PaymentMethods'
import {PaymentMethodType} from '@/constants'
import translations from './translations'

export const GooglePayPaymentMethod = observer(() => {
    const store = useInjection<TGooglePayPaymentMethodStore>(PaymentMethodsGooglePayStoreSymbol)

    return <PaymentMethodDetails
        title={translations().title}
        description={translations().description}
        paymentMethodType={PaymentMethodType.googlepay}
        stores={store.stores}
    />
})
