import React from 'react'
import {observer} from 'mobx-react'
import {List} from 'antd'
import classNames from 'classnames'
import {LoadingState} from '@/types'
import {useScreenType} from '@/hooks'
import {Card} from '@/components/dumb/Card'
import {DATE_TIME_FORMAT, TerminalType} from '@/constants'
import rootTranslations from '@/translations'
import {TransactionSummaryListItem} from './components'
import {TransactionListProps} from './props'
import styles from './styles.scss'

export const TransactionSummaryList: React.FC<TransactionListProps> = observer((
    {
        summaryByStatus, loadingState, prevDates,
        type, currency
    }
) => {
    const {isMobile, isTablet, isLaptop} = useScreenType()

    const getListPropertiesByType = () => {
        switch (type) {
            case TerminalType.ECOM:
                return {
                    gutter: 0,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 4,
                    xl: 4,
                    xxl: 4
                }
            case TerminalType.POS:
                return {
                    gutter: 0,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    xxl: 2
                }
            default:
                return {
                    gutter: 0,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    xxl: 2
                }
        }
    }

    return (
        <Card
            loading={loadingState === LoadingState.LOADING}
            className={classNames(
                {[styles.posCard]: type === TerminalType.POS},
                {[styles.posCardLaptop]: type === TerminalType.POS && (isLaptop || isTablet)},
                {[styles.posCardMobile]: type === TerminalType.POS && isMobile}
            )}
        >
            <List
                grid={getListPropertiesByType()}
                itemLayout={'horizontal'}
                dataSource={summaryByStatus}
                renderItem={(item, index) => (
                    <TransactionSummaryListItem
                        period={`${prevDates.from.format(DATE_TIME_FORMAT)} - ${prevDates.to.format(DATE_TIME_FORMAT)}`}
                        item={item}
                        statusLabel={(
                            type === TerminalType.ECOM
                                ? rootTranslations().constants.onlinePaymentStatus[item.status]
                                : rootTranslations().constants.posPaymentStateStatus[item.status]
                        )}
                        hasDivider={summaryByStatus.length - 1 !== index}
                        currency={currency}
                    />
                )}
            />
        </Card>
    )
})
