import React from 'react'
import translations from './translations'

translations.add('en', {
    title: 'Chargebacks',
    description: (
        <>
            What is a Chargeback? <br /> A chargeback is the potential outcome of a disputed credit
            or debit card transaction. If the cardholder’s bank accepts the dispute, they will
            reverse the transaction, taking the funds back from the merchant and returning them to
            the cardholder. <br /> When a cardholder disputes a charge, their issuing bank will
            review the transaction and decide if the dispute is valid. If they believe that it is,
            they will provide the cardholder with a provisional credit and work with the card
            network and the acquiring bank to finalize a chargeback.
        </>
    )
})
