import React from 'react'
import translations from './translations'

translations.add('sl', {
    text: 'Ponovno pošlji',
    seconds: count =>
        count ? (
            <>
                {' '}
                v <b>{count} sekundah</b>
            </>
        ) : (
            ''
        )
})
