import React, {useEffect} from 'react'
import moment from 'moment'
import {observer} from 'mobx-react'
import {DatePicker, Form, Radio} from 'antd'
import {useInjection} from 'dna-react-ioc'

import {disabledDateBeforeNow, disabledTimeBeforeNow} from '@/utils'
import {getExpirationDateByPreset} from '@/services/payment-link'
import {PaymentLink} from '@/api'
import {DATE_TIME_FORMAT} from '@/constants'
import {THasForm, THasFormId} from '@/types'
import {Input} from '@/components'
import {PayByLinkEditPaymentLinkStoreSymbol} from '@/pages/PayByLink'
import {TEditPaymentLinkStore} from '@/pages/components'
import translations from './translations'
import styles from '@/pages/components/NewPaymentLinkModal/styles.scss'
import {ExpirationDateType} from '@/stores/pay-by-link/models/ExpirationDateType'
import {
    FOURTY_EIGHT_HOURS_EXPIRE_DATE, MONTH_EXPIRE_DATE,
    TWENTY_FOUR_HOURS_EXPIRE_DATE,
    WEEK_EXPIRE_DATE
} from '@/constants/playwright-ids'

const getFormInitialValues = (data: PaymentLink) => {
    return {
        description: data.description,
        expirationDate: moment(data.expirationDate)
    }
}

export const EditPaymentLinkForm: React.FC<THasForm & THasFormId> = observer(({form, formId}) => {
    const editPaymentLinkStore = useInjection<TEditPaymentLinkStore>(
        PayByLinkEditPaymentLinkStoreSymbol
    )

    const onFinish = (values: any) => {
        const {expirationDatePreset, ...rest} = values
        editPaymentLinkStore.editPaymentLink(editPaymentLinkStore?.paymentLink?.id, rest)
    }

    useEffect(() => {
        form.resetFields()
    }, [form, editPaymentLinkStore?.paymentLink])

    return (
        <Form
            id={formId}
            form={form}
            layout={'vertical'}
            labelCol={{span: 24}}
            wrapperCol={{span: 24}}
            initialValues={getFormInitialValues(editPaymentLinkStore?.paymentLink)}
            onFinish={onFinish}
            autoComplete='off'
            onValuesChange={(changedValues) => {
                if (changedValues.expirationDatePreset) {
                    form.setFieldValue(
                        'expirationDate',
                        getExpirationDateByPreset(changedValues.expirationDatePreset)
                    )
                }
            }}
        >
            <Form.Item
                label={translations().description}
                name='description'
                rules={[{required: true, message: translations().errors.descriptionRequired}]}
            >
                <Input placeholder={translations().nameOfTheServiceOrItemYouProvide} />
            </Form.Item>

            <Form.Item
                label={translations().linkExpiration}
                name='expirationDate'
                rules={[{required: true, message: translations().errors.linkExpirationRequired}]}
            >
                <DatePicker
                    format={DATE_TIME_FORMAT}
                    showTime
                    showNow={false}
                    showSecond={false}
                    disabledDate={disabledDateBeforeNow}
                    disabledTime={disabledTimeBeforeNow}
                    onSelect={() => form.resetFields(['expirationDatePreset'])}
                    renderExtraFooter={() => (
                        <Form.Item noStyle name='expirationDatePreset'>
                            <Radio.Group className={styles.radio}>
                                <Radio value={ExpirationDateType.Hour24} data-test-id={TWENTY_FOUR_HOURS_EXPIRE_DATE}>
                                    {translations().ranges.hour24}
                                </Radio>
                                <Radio value={ExpirationDateType.Hour48} data-test-id={FOURTY_EIGHT_HOURS_EXPIRE_DATE}>
                                    {translations().ranges.hour48}
                                </Radio>
                                <Radio value={ExpirationDateType.Week1} data-test-id={WEEK_EXPIRE_DATE}>
                                    {translations().ranges.week1}
                                </Radio>
                                <Radio value={ExpirationDateType.Month1} data-test-id={MONTH_EXPIRE_DATE}>
                                    {translations().ranges.month1}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}
                />
            </Form.Item>
        </Form>
    )
})
