import {makeAutoObservable} from 'mobx'
import {injectable} from 'inversify'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import {TResetPasswordFailureStore} from '@/pages/ResetPasswordFailure'

@injectable()
export class ResetPasswordFailureStore implements TResetPasswordFailureStore {
    constructor() {
        makeAutoObservable(this)
    }

    onTryAgainClick = () => {
        getRouterStore().push(ROUTES.resetPassword)
    }
}
