import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    linkDetails: string
    copyToClipboard: string
    copied: string
    orderNumber: string
    amount: string
    customer: string
    dateCreated: string
    expirationDate: string
    viewedDate: string
    paidDate: string
    cancelledDate: string
    attemptedDate: string
    description: string
    createdBy: string
}>()

