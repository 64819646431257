import React from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import {Form, FormItemProps, Input} from 'antd'
import {IconTitle} from '@/components'
import {generateInvoiceId} from '@/utils'
import translations from './translations'
import {NEW_PAYMENT_DESCRIPTION, PAYMENT_ORDER_NUMBER, RELOAD_ORDER_NUMBER} from '@/constants/playwright-ids'

type Props = {
    prefix?: string
    onReloadClick(newInvoiceId: string): void
} & FormItemProps

export const OrderNumberFormField: React.FC<Props> = (props) => {
    const { prefix = 'PL', onReloadClick, ...rest } = props
    return (
        <Form.Item
            label={
                <IconTitle
                    title={translations().orderNumber}
                    tooltipText={translations().orderNumberTooltip}
                />
            }
            name="invoiceId"
            rules={[
                { required: true, message: translations().errors.orderNumberRequired }
            ]}
            {...rest}
        >
            <Input
                placeholder={translations().orderNumber}
                data-test-id={PAYMENT_ORDER_NUMBER}
                suffix={<ReloadOutlined onClick={() => onReloadClick(generateInvoiceId(prefix))} data-test-id={RELOAD_ORDER_NUMBER}/>}
            />
        </Form.Item>
    )
}