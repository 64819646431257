import React from 'react'
import { Input as AntdInput } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { InputProps } from './props'
import styles from './styles.scss'

// eslint-disable-next-line react/display-name
export const Input = React.forwardRef<never, InputProps>((props, ref) => {
    const { loading, suffix, className, ...rest } = props
    const _suffix = loading ? <LoadingOutlined className={styles.loadingIcon} /> : suffix
    const _className = classNames(className, { [styles.loading]: loading })
    return <AntdInput ref={ref} {...rest} className={_className} suffix={_suffix} />
})
