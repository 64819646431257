export enum Currencies {
    GBP = 'GBP',
    EUR = 'EUR',
    USD = 'USD',
    CNY = 'CNY',
    SEK = 'SEK',
    ISK = 'ISK'
}

export const currencies = ['GBP', 'EUR', 'USD', 'CNY', 'SEK', 'ISK']

export const getPortalCurrencies = [
    Currencies.GBP,
    Currencies.EUR,
    Currencies.USD,
    Currencies.ISK
].map((item) => ({
    label: item,
    value: item
}))

export const DEFAULT_CURRENCY = Currencies.GBP
