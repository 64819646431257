import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Pagination} from '@/components'
import {PageSizeSelect} from '@/components/dumb/Pagination'
import {PaginationContainerStoreType} from '@/components/containers'
import {PaginationContainerProps} from './props'

export const PaginationContainerStoreSymbol = Symbol('PaginationContainerStore')

export const PaginationContainer: React.FC<PaginationContainerProps> = observer(
    ({
        store: _store,
        injectableIdentifier = PaginationContainerStoreSymbol,
        hideSizeChanger,
        showOnlySizeChanger,
        ...rest
    }) => {
        const store = _store || useInjection<PaginationContainerStoreType>(injectableIdentifier)

        if (showOnlySizeChanger) {
            return (
                <PageSizeSelect
                    onChange={store.setPageSize}
                    pageSize={store.paging.size}
                    total={store.total}
                />
            )
        }

        return (
            <Pagination
                hideSizeChanger={hideSizeChanger}
                current={store.paging.page}
                pageSize={store.paging.size}
                total={store.total}
                onChange={page => store.setPageNumber(page)}
                onPageSizeChange={pageSize => store.setPageSize(pageSize)}
                {...rest}
            />
        )
    }
)
