import translations from './translations'
import React from 'react'

translations.add('es', {
    title: 'Klarna',
    description: <>
        DNA Payments se enorgullece de asociarse con Klarna para la provisión de transacciones Buy Now Pay Later (BNPL). BNPL permite a los consumidores distribuir el coste de una compra aplicable sin pagar intereses ni comisiones adicionales.
        <br/><br/>
        Una vez activado, los consumidores tendrán la opción de elegir el BNPL de Klarna durante el proceso de pago, a menos que se desactive específicamente en la configuración de la integración.
        <br/><br/>
    </>
})
