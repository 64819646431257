import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import translations from './translations'

translations.add('el', {
    columns: {
        paymentMethod: 'Μέθοδος πληρωμής',
        transactionDate: 'Ημερομηνία Συναλλαγής',
        settlementDate: 'Ημερομηνία διακανονισμού',
        address: 'Διεύθυνση',
        postcode: 'Ταχυδρομικός κώδικας',
        mid: 'MID',
        terminalNumber: 'Τερματικό',
        amount: 'Ποσό',
        acquirerFee: 'Προμήθεια acquirer',
        totalFee: 'Συνολική προμήθεια',
        interchangeFee: 'Προμήθεια Interchange',
        schemeFee: 'Προμήθεια Scheme',
        processingFee: 'Προμήθεια Επεξεργασίας',
        gatewayFee: 'Προμήθεια Πύλη',
        authorisationFee: 'Προμήθεια Εξουσιοδότηση',
        blendedFee: 'Μικτή Προμήθεια',
        refundFee: 'Προμήθεια Επιστροφής Χρημάτων',
        amountToMerchant: 'Πληρωτέο',
        sortCode: 'Κωδικός ταξινόμησης',
        accountNumber: 'Αριθμός λογαριασμού',
        operation: 'Λειτουργία',
        transactionType: 'Τύπος συναλλαγής',
        transactionId: 'Αναγνωριστικό συναλλαγής',
        merchantReference: 'Αναφορά εμπόρου',
        cardScheme: 'Τύπος κάρτας',
        cardMask: 'Μάσκα κάρτας',
        cardholderName: 'Ονομα κατόχου κάρτας',
        cardType: 'Τύπος κάρτας',
        isEuropeanCard: 'Ευρωπαϊκή Κάρτα',
        issuerRegion: 'Περιοχή έκδοσης',
        issuerCountry: 'Χώρα έκδοσης',
        isCorporateCard: 'Εταιρική κάρτα',
        captureMethod: 'Μέθοδος λήψης',
        settlementType: 'Τύπος οικισμού'
    },
    columnsPerDay: {
        transactionDate: 'Ημερομηνία Συναλλαγής',
        count: 'Αριθμός',
        amount: 'Ποσό',
        acquirerFee: 'Προμήθεια Acquirer',
        amountToMerchant: 'Πληρωτέο',
        settlementDate: 'Ημερομηνία διακανονισμού'
    },
    captureMethod: {
        posContactless: 'Aνέπαφο POS'
    },
    columnValues: {
        european: 'Ευρωπαϊκό',
        nonEuropean: 'μη Ευρωπαϊκό',
        corporate: 'Εταιρικό',
        nonCorporate: 'μη Εταιρικό',
        today: 'Σήμερα'
    },
    messages: {
        successExportMessage: (
            <span>
                Το αίτημα λήψης σας έχει μπει σε ουρά για επεξεργασία. Μόλις το έγγραφο είναι έτοιμο, μπορείτε να το κατεβάσετε από την ενότητα {' '}
                <Link to={ROUTES.exports}>Εξαγωγές</Link>.
            </span>
        )
    }
})
