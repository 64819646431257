import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    back: string
    title: string
    text: string
    email: string
    emailInvalid: string
    emailRequired: string
    submit: string
    errorOnEmail: (message: string) => string
}>()

