import translations from './translations'

translations.add('el', {
    title: 'Ρυθμίσεις κάρτας',
    freeze: {
        freezeCard: 'Κλείδωμα κάρτας',
        inCaseYouWantToFreeze:
            'Σε περίπτωση που θέλετε να διακόψετε την λειτουργία της κάρτας για λίγο'
    },
    block: {
        blockCard: 'Μπλοκάρισμα κάρτας',
        inCaseYouWantToBlock: 'Σε περίπτωση απώλειας ή κλοπής της κάρτας'
    },
    activate: {
        activateCard: 'Activate card',
        inCaseYouWantToActivate: 'In case of blocked card'
    },
    getCVV: 'Get CVV',
    getPIN: 'Get PIN',
    cardholder: 'Cardholder',
    address: 'Διεύθυνση'
})
