import React, {useCallback} from 'react'
import {runInAction} from 'mobx'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Form, Grid} from 'antd'
import {ModalContainer} from '@/components/containers'
import {
    LinkDetailsModalFooter,
    LinkDetailsModalView,
    LinkShare,
    TPaymentLinkDetailsModalStore
} from '@/pages/components'
import {PayByLinkPaymentLinkDetailsStoreSymbol} from '@/pages/PayByLink'
import translations from './translations'
import {LinkDetailsModalProps} from './props'
import styles from './styles.scss'
import {LinkShareFooter} from '@/pages/components/PaymentLinkDetailsModal/components/LinkShare/components'

export const PaymentLinkDetailsModal: React.FC<LinkDetailsModalProps> = observer((
    {injectableIdentifier}: LinkDetailsModalProps
) => {
    const store = useInjection<TPaymentLinkDetailsModalStore>(PayByLinkPaymentLinkDetailsStoreSymbol)
    const screens = Grid.useBreakpoint()
    const isMobile = screens.xs
    const [formSms] = Form.useForm()
    const [formEmail] = Form.useForm()

    const getTitle = () => {
        switch (store.widgetType) {
            case 'linkDetails':
                return store.isNewLinkDetails ? translations().newPaymentLinkIsReady : translations().linkDetails
            case 'shareViaEmail':
                return translations().shareViaEmail
            case 'shareViaSMS':
                return translations().shareViaSMS
        }
    }

    const handleCloseModal = () => {
        runInAction(() => store.widgetType = 'linkDetails')
    }

    const onSenderNameChange = useCallback((name) => {
        const {paymentLink} = store

        store.setPaymentLink({...paymentLink, senderName: name})
    }, [store])

    const renderBody = () => {
        switch (store.widgetType) {
            case 'linkDetails':
                return <LinkDetailsModalView
                    createdPaymentLink={store.paymentLink}
                    onSmsBtnClick={() => {
                        store.widgetType = 'shareViaSMS'
                    }}
                    onEmailBtnClick={() => {
                        store.widgetType = 'shareViaEmail'
                    }}
                />
            case 'shareViaSMS':
                return <LinkShare
                    senderNameHandler={onSenderNameChange}
                    paymentLink={store.paymentLink}
                    form={formSms}
                    formId={'linkShareSms'}
                    type={'sms'}
                    onBackToLinkClick={() => {
                        runInAction(() => {
                            store.widgetType = 'linkDetails'
                            store.setPaymentLink({...store.paymentLink, senderName: null})
                        })
                    }}
                />
            case 'shareViaEmail':
                return <LinkShare
                    senderNameHandler={onSenderNameChange}
                    paymentLink={store.paymentLink}
                    form={formEmail}
                    formId={'linkShareEmail'}
                    type={'email'}
                    onBackToLinkClick={() => {
                        runInAction(() => {
                            store.widgetType = 'linkDetails'
                            store.setPaymentLink({...store.paymentLink, senderName: null})
                        })
                    }}
                />
        }
    }

    const renderFooter = () => {
        switch (store.widgetType) {
            case 'linkDetails':
                return isMobile
                    ? null
                    : <LinkDetailsModalFooter
                        status={store.paymentLink.status}
                        onEmailBtnClick={() => {
                            store.widgetType = 'shareViaEmail'
                        }}
                        onSmsBtnClick={() => {
                            store.widgetType = 'shareViaSMS'
                        }}
                        url={store.paymentLink.url}
                    />
            case 'shareViaSMS':
                return <LinkShareFooter form={formSms} formId={'linkShareSms'} type={'sms'} closeModal={handleCloseModal}/>
            case 'shareViaEmail':
                return <LinkShareFooter form={formEmail} formId={'linkShareEmail'} type={'email'} closeModal={handleCloseModal}/>
        }
    }

    return (
        <ModalContainer
            className={styles.modal}
            title={!isMobile && getTitle()}
            injectableIdentifier={injectableIdentifier}
            width={'900px'}
            footer={renderFooter()}
        >
            {renderBody()}
        </ModalContainer>
    )
})
