import translations from './translations'
import React from 'react'

translations.add('es', {
    messageTextPreview: 'Vista previa del texto del mensaje:',
    message: (name) => `Usted recibió este mensaje con una solicitud de pago de ${name ? name : 'Nombre del remitente'}. Siga el enlace para iniciar el pago:`,
    emailMessage: (senderName, recipientName) => (
        <div>
            <div>Dear {recipientName}</div>
            <div>{senderName ? senderName : 'El Nombre del Remitente'} te está solicitando realizar un pago.
                Por favor, sigue el enlace de abajo y selecciona tu método de pago preferido.
            </div>
        </div>
    )
})
