import React from 'react'
import translations from './translations'

translations.add('el', {
    hereWillBeTheList: '\'Εδώ θα είναι η λίστα των domain σας\'',
    textWithExplanationOfDomain: <>
        Δεν έχετε επαληθευμένα domain.
        <br/>
        Για να προσθέσετε τα domain σας, χρησιμοποιήστε το κουμπί Προσθήκη Domains
    </>,
    addNewDomain: 'Προσθήκη νέου domain'
})
