import translations from './translations'

translations.add('en', {
    recipientName: 'Sender name',
    recipientNumber: 'Phone number',
    recipientsNumber: '\'s Phone number',
    recipientEmail: 'Email Address',
    recipientsEmail: '\'s Email Address',
    errors: {
        recipientNumberRequired: 'Recipient number required',
        recipientNumberInvalid: 'Recipient phone not valid',
        recipientEmailRequired: 'Email required',
        recipientEmailInvalid: 'Email not valid',
        recipientNameRequired: 'Sender name required'
    }
})
