import {TagProps as AntTagProps} from 'antd'

export enum TagType {
    Default,
    Success,
    Active,
    Processing,
    Warn,
    Error,
    Disabled
}

type Tag = {
    type: TagType
}

export type TagProps = Pick<AntTagProps, 'className' | 'onClick' | 'children'> & Tag
