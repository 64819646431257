import translations from './translations'

translations.add('el', {
    pendingTransactions: number => `Συναλλαγές σε εκκρεμότητα (${number})`,
    description: 'Περιγραφή',
    sum: 'Αθροισμα',
    transactionStatus: {
        PROCESSED: 'Processed',
        PENDING: 'Pending',
        FAILED: 'Failed',
        CANDELLED: 'Cancelled'
    },
    all: 'Ολα'
})
