import {inject, injectable} from 'inversify'
import {action, makeObservable, observable, runInAction, reaction} from 'mobx'
import {openErrorNotification, openSuccessNotification} from '@/utils'
import {PaginationStore} from '@/stores'
import {getUserTrustedDevices, deleteUserTrustedDevices} from '@/stores/profile/services/fetchers'
import {TwoFATrustedDeviceModel} from '@/stores/profile/models'
import {TwoFADevicesStoreInterface} from './TwoFADevicesStoreInterface'
import {EXTRA_SMALL_PAGE_SIZE} from '@/constants'
import {TwoFADevicesPaginationContainerStoreSymbol} from '@/pages/Profile/components/TwoFA/components/'
import {TwoFAModalStoreInterface} from '@/stores/profile/TwoFAModalStore/TwoFAModalStoreInterface'
import {TwoFAModalStoreSymbol} from '@/pages/Profile/components/TwoFA/components/TwoFAModal'

import translations from './translations'

@injectable()
export class TwoFADevicesStore implements TwoFADevicesStoreInterface {
    public paginationStore: PaginationStore

    public twoFAModalStore: TwoFAModalStoreInterface
    public devices: TwoFATrustedDeviceModel[] = []
    public isLoading: boolean = false
    public isOpen: boolean = false

    constructor(
        @inject(TwoFADevicesPaginationContainerStoreSymbol) paginationStore: PaginationStore,
        @inject(TwoFAModalStoreSymbol) twoFAModalStore: TwoFAModalStoreInterface
    ) {
        this.twoFAModalStore = twoFAModalStore
        this.paginationStore = paginationStore

        makeObservable(this, {
            isOpen: observable,
            isLoading: observable,
            devices: observable,

            setIsLoading: action.bound,
            setIsOpen: action.bound
        })

        this.paginationStore.setPageSize(EXTRA_SMALL_PAGE_SIZE)

        this.setIsOpen()

        reaction(
            () => this.paginationStore.paging,
            async () => await this.getDevices()
        )

        reaction(
            () => ({
                isTrustedDeviceSelected: this.twoFAModalStore.isTrustedDeviceSelected,
                isEnabled: this.twoFAModalStore.twoFAStore.isEnabled
            }),
            async ({isTrustedDeviceSelected, isEnabled}) =>
                runInAction(() => (this.isOpen = isTrustedDeviceSelected && isEnabled))
        )
    }

    getDevices = async () => {
        try {
            this.setIsLoading(true)

            const {page, size} = this.paginationStore.paging

            const {
                error,
                result: {data, totalCount}
            } = await getUserTrustedDevices(page, size)

            if (error) {
                return openErrorNotification(error.message)
            }

            this.paginationStore.setTotal(totalCount)

            runInAction(() => (this.devices = data))
        } catch (error) {
            openErrorNotification(error)
        } finally {
            this.setIsLoading(false)
        }
    }

    deleteDevice = async (id: string) => {
        try {
            const {
                paging: {page},
                setPageNumber
            } = this.paginationStore

            await deleteUserTrustedDevices(id)

            if (this.devices.length === 1 && page > 1) {
                setPageNumber(page - 1)
            } else {
                await this.getDevices()
            }

            openSuccessNotification(translations().message.delete)
        } catch (error) {
            openErrorNotification(error)
        }
    }

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading
    }

    setIsOpen = () => {
        this.isOpen =
            this.twoFAModalStore.isTrustedDeviceSelected &&
            this.twoFAModalStore.twoFAStore.isEnabled
    }
}
