import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    transactions: string
    transactionsComparison: string
    paymentMethods: string
    cardPayments: string
    issuingBanks: string
    calendarTitle: string
    amount: string
    count: string
    volumeTotal: string
    previous: string
    current: string
    downloadLink: string
    cardScheme: string
    onlinePayments: string
    posPayments: string
    currency: string
    noContentTitle: string
    empty: string
}>()

