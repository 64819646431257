import React from 'react'
import translations from './translations'

translations.add('el', {
    back: 'Αλλαγή τράπεζας',
    cancel: 'Ακύρωση',
    continue: 'Συνεχίστει',
    infoText: 'Θα ζητήσουμε από την τράπεζά σας να κοινοποιήσει τον κωδικό ταξινόμησης του ονόματος και τον αριθμό λογαριασμού σας. Θα χρησιμοποιήσουμε αυτά τα στοιχεία μόνο εάν ζητήσετε επιστροφή χρημάτων για αυτήν τη συναλλαγή.',
    permissionText: 'Η Ecospend Technologies Limited χρειάζεται την άδειά σας για να ξεκινήσει με ασφάλεια μια εντολή πληρωμής από την τράπεζά σας.',
    privacyPolicyText: (termsUrl: string, privacyUrl: string) => <>
        Κάνοντας κλικ στη συνέχεια αποδέχεστε <a href={termsUrl} target="_blank" rel="noreferrer">τους όρους και τις</a> προϋποθέσεις και την <a href={privacyUrl} target="_blank" rel="noreferrer">πολιτική απορρήτου</a> μας
    </>
})
