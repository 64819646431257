import translations from './translations'

translations.add('ro', {
    cookies: 'Preferințe cookie-uri',
    cookiesContent:
        'Utilizăm cookie-uri pe site-ul nostru. Unele dintre ele sunt esențiale, în timp ce altele ne ajută să îmbunătățim acest site și experiența dvs.',
    descriptions: {
        name: 'Nume',
        provider: 'Furnizor',
        saveVisitorsPreferences: 'Salvează preferințele vizitatorilor selectate în Cookie',
        cookieName: 'Numele cookie-ului',
        cookieExpiry: 'Expirarea cookie-ului',
        cookie: 'Cookie',
        ownerOfSite: 'Proprietarul acestui site',
        cookieByGoogleAdvanced:
            'Cookie de la Google utilizat pentru controlul scripturilor avansate și manipularea evenimentelor',
        purpose: 'Scop',
        year: '1 an',
        googleTagManager: 'Google Tag Manager',
        googleLLC: 'Google LLC',
        twoYears: '2 ani',
        privacyPolicy: 'Politica de confidențialitate'
    }
})
