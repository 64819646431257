import React from 'react'
import translations from './translations'

translations.add('ro', {
    download: 'Descarcă',
    cancel: 'Anulare',
    title: 'Descărcare declarație PDF',
    text: (
        <>
            Vă rugăm să selectați Perioada de decontare dorită și să faceți clic pe Descarcă. <br />{' '}
            <br />
            <strong>Disclaimer:</strong> Extrasele pot fi descărcate pentru o lună completă din
            Prima zi a noii luni. Din august 2023 încolo, vor fi în cel mai recent stil și format și
            înainte de august 2023, acestea vor rămâne în vechiul design.
        </>
    )
})
