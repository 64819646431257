import {makeAutoObservable, toJS} from 'mobx'
import {injectable} from 'inversify'
import {AccountTransactionsFilterButtonStoreInterface} from '@/pages/business-account/AccountInfo/components/AccountTransactionsFilterButton'
import {SelectItem} from '@/components/dumb/Select'
import {AccountTransactionFilterValues} from '@/stores'
import {
    getSelectDebitCreditOptions,
    getSelectTransactionTypesOptions
} from '@/stores/business-account/account-transactions/services/utils'
import _ from 'lodash'

@injectable()
export class AccountTransactionsFilterButtonStore
    implements AccountTransactionsFilterButtonStoreInterface
{
    count: number
    open = false
    filterValues: Partial<AccountTransactionFilterValues> = null
    filterForRequest: Partial<AccountTransactionFilterValues> = null

    constructor() {
        makeAutoObservable(this)
    }

    get selectTransactionTypes(): SelectItem[] {
        return getSelectTransactionTypesOptions()
    }

    get selectDebitCredit(): SelectItem[] {
        return getSelectDebitCreditOptions()
    }

    clearFilters = (): void => {
        this.setOpen(false)
    }

    onFilterClose = (): void => {
        this.setOpen(false)
    }

    onFinish = (values: Partial<AccountTransactionFilterValues>): void => {
        this.filterForRequest = _(values)
            .omitBy(_.isNil)
            .omitBy(v => v === '')
            .value()
        this.setOpen(false)
    }

    openFilter = (): void => {
        this.setOpen(true)
    }

    setOpen = (open: boolean): void => {
        this.open = open
    }

    onFormValuesChange = (values): void => {
        this.filterValues = values
    }
}
