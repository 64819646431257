import React from 'react'
import classNames from 'classnames'
import icons from '~/assets/icons'
import {IconProps} from './props'
import styles from './styles.scss'

export const Icon: React.FC<IconProps> = ({type, className, hoverable, ...rest}) => (
    <span className={classNames(styles.icon, className, {[styles.hoverable]: hoverable})} {...rest}>
        {icons[type]}
    </span>
)
