import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import classNames from 'classnames'

import {THasClassName} from '@/types/common/THasClassName'
import {ROUTES} from '@/router/routes'
import {Popover} from '@/components'
import {Button} from '@/components/dumb/Button'
import ProfileIcon from '~/assets/img/profile/Profile.svg'

import translations from './translations'
import styles from './styles.scss'
import {LOGOUT, OPEN_PROFILE, OPEN_PROFILE_ICON} from '@/constants/playwright-ids'

type ProfilePopoverProps = {onLogOut: () => void} & THasClassName

export const ProfilePopover = ({className, onLogOut}: ProfilePopoverProps) => {
    const [open, setOpen] = useState<boolean>(false)

    const content = () => {
        return (
            <ul className={styles.content}>
                <li className={styles.row}>
                    <Link
                        className={styles.link}
                        to={ROUTES.profile}
                        onClick={() => setOpen(false)}
                        data-test-id={OPEN_PROFILE}
                    >
                        {translations().profile}
                    </Link>
                </li>
                <li className={styles.row}>
                    <Button
                        className={styles.button}
                        type={'link'}
                        onClick={onLogOut}
                        data-test-id={LOGOUT}
                    >
                        {translations().logOut}
                    </Button>
                </li>
            </ul>
        )
    }

    return (
        <Popover
            open={open}
            overlayClassName={styles.overlay}
            placement={'bottomRight'}
            content={content()}
            trigger={'click'}
            onOpenChange={setOpen}
        >
            <div className={classNames(styles.iconHolder, className)} data-test-id={OPEN_PROFILE_ICON}>
                <img src={ProfileIcon} className={styles.icon} />
            </div>
        </Popover>
    )
}
