import React from 'react'
import {Tooltip} from 'antd'
import {Icon} from '@/components'
import translations from './translations'
import styles from './styles.scss'
import {DOWNLOAD_BUTTON} from '@/constants/playwright-ids'

export const DownloadReceipt = () => {
    return (
        <Tooltip placement="top" title={translations().downloadReceipt} data-test-id={DOWNLOAD_BUTTON}>
            <Icon className={styles.pdfIcon} type={'pdf'}/>
        </Tooltip>
    )
}
