import translations from './translations'

translations.add('en', {
    areYouSureYouWantTo: (c: boolean) =>
        `Are you sure you want to ${c ? 'enable' : 'disable'} the payment method?`,
    yes: 'Yes',
    no: 'No',
    active: 'Active',
    disabled: 'Disabled'
})
