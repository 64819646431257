import translations from './translations'

translations.add('es', {
    title: 'Proceso de integración',
    initialCheck: 'Proceso de integración',
    initialCheckDescription: 'Las preguntas del gerente para aclarar los detalles de su negocio',
    actionRequired: 'Acción requerida',
    processTakesDays: 'El proceso tarda 1 día',
    documentsUpload: 'El proceso tarda 1 día',
    finalCheck: 'Revision final',
    agreement: 'Acuerdo',
    activation: 'Activación',
    submit: 'Entregar',
    cancel: 'Cancelar',
    initialCheckSuccessMessage: '¡Gracias! Ahora estamos revisando la información que nos ha proporcionado.',
    thankYouMessage: '¡Gracias! Ahora estamos revisando la información que nos ha proporcionado.',
    documentsUploadDescription: 'Cargue sus documentos de respaldo: comprobante de identificación, comprobante de domicilio y un extracto bancario',
    finalCheckDescription: 'Proporcione información adicional',
    back: 'Atrás'
})
