import {TranslationBuilder} from '@/components/dumb/Translation'
import {TransactionDirection, TransactionType} from '@/stores'

export default TranslationBuilder.create<{
    type: string
    code: string
    channel: string
    merchant: string
    user: string
    reference: string
    showHidden: string
    account: string
    balance: string
    debitCredit: {
        [key in keyof typeof TransactionDirection]: string
    }
    transactionTypes: {
        [key in keyof typeof TransactionType]: string
    }
    transactionDirection: string
    loadAfter: string
    yes: string
    no: string
    all: string
}>()
