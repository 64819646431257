import {CaptureMethod, BasePOSTransactionType} from '@/types/transactions'
import {PaymentMethodType} from '@/constants'

export const getPOSPaymentMethod = (payment: BasePOSTransactionType) => {
    if (payment.cardMask) return PaymentMethodType.card

    switch (payment.captureMethod) {
        case CaptureMethod['ecom-paybybankapp']:
            return PaymentMethodType.paybybankapp
        case CaptureMethod['ecom-openbanking']:
        case CaptureMethod['pos-openbanking']:
            return PaymentMethodType.ecospend
        case CaptureMethod['ecom-klarna']:
            return PaymentMethodType.klarna
        case CaptureMethod['pos-alipay']:
            return PaymentMethodType.alipay
    }

    return null
}
