import translations from './translations'

translations.add('es', {
    welcome: 'Bienvenido a DNA Payments',
    merchantPortal: 'Portal de Comerciantes',
    startTutorial: 'Iniciar tutorial',
    description1: 'Nuestro Portal del Comerciante es una potente plataforma que le ayuda a supervisar y gestionar su flujo de trabajo Transacciones Reembolsos Impugnaciones de pagos y liquidaciones, todo ello en tiempo real.',
    description2: 'Le recomendamos que consulte nuestro tutorial para descubrir cómo aprovechar al máximo las características y funciones de nuestro portal para comerciantes.',
    skipTutorial: 'Saltar tutorial',
    back: 'Atrás',
    close: 'Cerrar',
    next: 'Siguiente',
    open: 'Abrir el diálogo',
    skip: 'Saltar'
})
