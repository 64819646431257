import React from 'react'
import translations from './translations'

translations.add('es', {
    download: 'Descargar',
    cancel: 'Cancelar',
    title: 'Descargar declaración en PDF',
    text: (
        <>
            Seleccione el Período de liquidación deseado y haga clic en Descargar. <br /> <br />
            <strong>Descargo de responsabilidad:</strong> Los estados de cuenta se pueden descargar
            para un mes completado a partir del día 1 del nuevo mes. A partir de agosto de 2023,
            serán en el último estilo y formato, y antes de agosto de 2023, permanecerán en el
            antiguo diseño.
        </>
    )
})
