import React from 'react'
import { Upload as AntUpload } from 'antd'
import classNames from 'classnames'
import { Button } from '@/components/dumb/Button'
import { Icon } from '@/components/dumb/Icon'
import { UploadProps } from './props'
import styles from './styles.scss'
import translations from './translations'
import {mockDefaultUploadFiles} from "@/services/mocks";

const uploadProps = {
    accept: '.pdf, .png, .jpeg',
    multiple: true,
    // TODO change action props default
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    showUploadList: {
        showRemoveIcon: true,
        removeIcon: <Icon type={'close'} />,
    }
}

export const Upload: React.FC<UploadProps> = (props: UploadProps) => {
    const { fileList, className, defaultFileList = mockDefaultUploadFiles } = props
    return <AntUpload
        {...uploadProps}
        defaultFileList={defaultFileList}
        fileList={fileList}
        className={classNames(className)}
    >
        <Button className={styles.button} type={'link'}>{translations().attachFile}</Button>
    </AntUpload>
}
