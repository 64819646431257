import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import translations from './translations'

translations.add('sl', {
    title: 'Exportovať online platby',
    columns: 'Stĺpce',
    cancel: 'Zruš',
    ok: 'Export',
    all: value => `Všetko (${value})`,
    custom: 'Vlastné',
    successExportMessage: (
        <span>
            Vaša žiadosť o stiahnutie bola zaradená do frontu na spracovanie. Keď bude dokument
            pripravený. Môžete si ho stiahnuť z časti{' '}
            <Link to={ROUTES.exports}>Exports</Link>.
        </span>
    )
})
