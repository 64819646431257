import _ from 'lodash'
import {PaymentMethodMerchantStoreModel} from '@/pages/payment-methods'
import {StoreWithTerminalsType} from '@/stores/store-and-terminals'
import {PaymentMethodType} from '@/constants'
import {Terminal} from '@/pages/VirtualTerminal/models'
import translations from './translations'

export const filterUnavailableTerminals = (
    stores: StoreWithTerminalsType[],
    paymentMethodType: Exclude<PaymentMethodType, PaymentMethodType.card>
): PaymentMethodMerchantStoreModel[] => {
    const deepCopyStores = JSON.parse(JSON.stringify(stores)) as PaymentMethodMerchantStoreModel[]
    return deepCopyStores.map((store) => {
        const deepCopyStore = _.cloneDeep(store)

        deepCopyStore.terminals = store.terminals.filter(
            (terminal) => terminal.settings[paymentMethodType]?.status !== 'unavailable'
        )

        return deepCopyStore
    })
}

export const setTerminalsSwitchChangeOnStores = (
    stores: PaymentMethodMerchantStoreModel[],
    onTerminalSwitchChange
): PaymentMethodMerchantStoreModel[] => {
    return (JSON.parse(JSON.stringify(stores)) as PaymentMethodMerchantStoreModel[]).map(
        (store) => {
            const deepCopyStore = _.cloneDeep(store)
            deepCopyStore.onTerminalSwitchChange = onTerminalSwitchChange

            return deepCopyStore
        }
    )
}

export const getTerminalsLabel = (terminals: Terminal[]): string => {
    if (terminals?.length === 0) {
        return translations().noTerminalsToConfigure
    }

    return translations().terminals(terminals?.length.toString())
}

export const isPaymentMethodActive = (status: string) => status === 'active'

export const getActiveApplePayTerminalCount = (
    terminals: Terminal[],
    paymentMethodType: Exclude<PaymentMethodType, PaymentMethodType.card>
): string => {
    if (terminals.length === 0) {
        return ''
    }

    const sum = terminals?.reduce((count, terminal) => {
        const increment = isPaymentMethodActive(terminal?.settings[paymentMethodType]?.status)
            ? 1
            : 0
        return count + increment
    }, 0)

    if (terminals.length === sum) {
        return translations().allActive
    }

    if (sum === 0) {
        return translations().allDisabled
    }

    return translations().active(sum.toString())
}
