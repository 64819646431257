import translations from './translations'

translations.add('es', {
    title: 'Bloqueo de tarjetas',
    ohSeemsLikeSomethingBadHappened: '-  Oh, ¿parece que algo malo le pasó a tu tarjeta?',
    pleaseClarifyTheProblem: 'Por favor aclare el problema',
    myCardWasStolen: 'Me robaron la tarjeta',
    iveLostMyCard: 'I\'ve lost my card',
    cardIsDamaged: 'He perdido mi tarjeta',
    cancel: 'Cancelar',
    blockCard: 'Bloquear tarjeta'
})
