import { ReactNode } from 'react'
import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    overviewDescription: ReactNode
    posPaymentsDescription: ReactNode
    posAmexPaymentsDescription: ReactNode
    onlinePaymentsDescriptions: ReactNode
    paymentLinksDescription: ReactNode
    paymentLinksCreate: ReactNode
    virtualTerminalDescription: ReactNode
    virtualTerminalDetails: ReactNode
    settlementsDescription: ReactNode
    settlementsDownload: ReactNode
    teamManagementDescription: ReactNode
    teamManagementAddUserDescription: ReactNode
    thankYou: ReactNode
    thankYouDescription: ReactNode
    skipTutorial: ReactNode
    invoicesDescription: ReactNode
    reportsDescription: ReactNode
    paymentMethodsDescription: ReactNode
    exportDescription: ReactNode
}>()

