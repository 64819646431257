import {PayPalReturnUrlQuery} from '../models'

export const getReturnUrlFields = (query: PayPalReturnUrlQuery) => {
    const fields = [
        'merchantId',
        'merchantIdInPayPal',
        'permissionsGranted',
        'consentStatus',
        'productIntentId',
        'productIntentID',
        'isEmailConfirmed',
        'accountStatus',
        'riskStatus'
    ]

    return fields.map(f => ({
        title: f,
        value: query[f]
    }))
}

export const loadPaypalScript = () => {
    const d = document
    const s = 'script'
    const id = 'paypal-js'

    let js: HTMLScriptElement = null
    const ref = d.getElementsByTagName(s)[0]

    if (!d.getElementById(id)) {
        js = d.createElement(s)
        js.id = id
        js.async = true
        js.src = 'https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js'
        ref.parentNode.insertBefore(js, ref)
    }
}
