export const PermissionOrder = [
    'overview.read',
    'online_payments.full',
    'online_payments.read',
    'pos_payments.full',
    'pos_payments.read',
    'pos_amex_payments.read',
    'pos_amex_payments.full',
    'payment_links.full',
    'payment_links.read',
    'virtual_terminal.full',
    'settlements.full',
    'settlements.read',
    'reports.read',
    'payment_methods.full',
    'payment_methods.read',
    'teammates.full',
    'teammates.read',
    'invoices.full',
    'invoices.read'
]
