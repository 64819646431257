import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    amount: string
    chargebackDate: string
    currency: string
    merchantName: string
    transactionDate: string
    reasonCode: string
    reason: string
    status: string
    paymentMethod: string
}>()
