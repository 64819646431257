import translations from './translations'

translations.add('ro', {
    labels: {
        transactionType: 'Tipul tranzacției'
    },
    warnings: {
        verification: 'Verificarea contului va iniția o tranzacție de valoare 0 (zero) pentru a verifica detaliile cardului.'
    },
    errors: {
        transactionType: 'Tipul tranzacției este obligatoriu'
    },
    placeholders: {
        transactionType: 'Tipul tranzacției'
    }
})
