import translations from './translations'

translations.add('el', {
    title: 'Προσθήκη Διπλής Πιστοποίησης Ταυτότητας',
    updateTitle: 'Ενημέρωση Διπλής Πιστοποίησης Ταυτότητας',
    recoveryCodes: 'Κωδικοί Ανάκτησης',
    selectFrequency: 'Επιλογή συχνότητας',
    configureApp: 'Διαμόρφωση εφαρμογής επαλήθευσης',
    cancel: 'Ακύρωση',
    back: 'Πίσω',
    next: 'Επόμενο',
    update: 'Ενημέρωση',
    submit: 'Υποβολή'
})
