import translations from './translations'

translations.add('sl', {
    acceptPolicies:
        'S klikom na ta gumb dovoljujem deljenje svojih podatkov s PayPal za namene vkrcanja',
    setUpPayPalAccount: 'Nastavitev računa PayPal',
    warningMessage:
        'Ko kliknete zgoraj navedeni gumb, boste preusmerjeni na spletno mesto PayPal v novem zavihku brskalnika. Sledite navodilom, da ustvarite nov račun ali dodate obstoječi račun PayPal. Ko bo postopek zaključen, se boste vrnili na to zaslon in status se bo posodobil.',
    permissionNotGranted:
        'Nie je možné zistiť, či boli udelené oprávnenia PayPal. Skontrolujte a skúste znova proces integrácie.',
    errors: {
        terminalNotFound: 'Nebol nájdený žiadny vhodný e-commerce terminál'
    }
})
