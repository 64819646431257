import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    ohSeemsLikeSomethingBadHappened: string
    pleaseClarifyTheProblem: string
    myCardWasStolen: string
    iveLostMyCard: string
    cardIsDamaged: string
    cancel: string
    blockCard: string
}>()
