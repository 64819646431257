import React from 'react'
import translations from './translations'

translations.add('es', {
    back: 'Cambiar de banco',
    cancel: 'Cancelar',
    continue: 'Continuar',
    infoText: `Le pediremos a su banco que nos facilite su nombre, código de clasificación y número de cuenta. Sólo utilizaremos estos datos si solicita el reembolso de esta transacción.
    'Ecospend Technologies Limited needs your permission to securely initiate a payment order from your bank.`,
    permissionText: 'Ecospend Technologies Limited necesita su permiso para iniciar de forma segura una orden de pago de su banco.',
    privacyPolicyText: (termsUrl: string, privacyUrl: string) => <>
        Al hacer clic en Continuar, acepta <a href={termsUrl} target="_blank" rel="noreferrer">nuestras condiciones generales</a> y <a href={privacyUrl} target="_blank" rel="noreferrer">nuestra política de privacidad</a>
    </>
})

