import {computed, makeObservable} from 'mobx'
import {injectable} from 'inversify'
import {ApiResponse} from 'back-connector'

import {BaseDataStore} from '@/stores/shared/BaseDataStore'

import {StoreType} from '../types'
import {fetchAllStoresOfMerchant} from '../api'
import {convertStoresToSelectItems} from '../utils'

@injectable()
export class AllStoresDataStore extends BaseDataStore<StoreType[]> {
    get storesAsSelectItems() {
        return convertStoresToSelectItems(this.data)
    }

    constructor() {
        super()

        makeObservable(this, {
            ...this.getAnnotationsMap(),
            storesAsSelectItems: computed
        })
    }

    protected async fetchData(): Promise<ApiResponse<StoreType[]>> {
        return fetchAllStoresOfMerchant()
    }

    protected getDefaultData(): StoreType[] {
        return []
    }
}
