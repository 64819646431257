import translations from './translations'

translations.add('el', {
    showAll: 'Προβολή όλων',
    previousRangeData: 'Προηγούμενο',
    currentRangeData: 'Τρέχον',
    statuses: {
        AUTH: 'Εκκρεμή',
        CHARGE: 'Χρεώθηκε',
        REFUND: 'Επιστράφηκε',
        REJECT: 'Απορρίφθηκε'
    },
    dataTypes: {
        amount: 'Ποσό',
        count: 'Αριθμός'
    },
    week: 'Εβδομάδα'
})
