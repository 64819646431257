import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Modal} from '@/components'
import {TModalContainerStore} from '@/components/containers/ModalContainer/TModalContainerStore'
import {ModalContainerProps} from './props'


export const ModalContainerStoreSymbol = Symbol('ModalContainerStore')

export const ModalContainer: React.FC<ModalContainerProps> = observer((
    {
        store: _store,
        injectableIdentifier = ModalContainerStoreSymbol,
        className, title,
        footer, children, open, onCancel,
        width
    }
) => {
    const store = _store || useInjection<TModalContainerStore>(injectableIdentifier)

    return (
        <Modal
            title={title}
            className={className}
            open={open || store.open}
            onOk={store.onOk}
            onCancel={(e) => {
                store.onCancel()
                onCancel && onCancel(e)
            }}
            footer={footer}
            width={width}
        >
            {children}
        </Modal>
    )
})
