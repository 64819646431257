import React from 'react'
import translations from './translations'
import {Typography} from 'antd'

translations.add('el', {
    back: 'Επιστροφή στην επαναφορά κωδικού πρόσβασης',
    title: 'Επιβεβαίωση Email',
    text: (email) => <>
        If we find an account associated with <Typography.Text strong>{email}</Typography.Text>, we will send a 6-digit verification code to it.
    </>,
    otp: 'Κωδικός επαλήθευσης',
    otpInvalid: 'Απαιτείται κωδικός επαλήθευσης',
    otpRequired: 'Ο κωδικός επαλήθευσης δεν είναι έγκυρος',
    submit: 'Επιβεβαίωση',
    confirmEmailDescription(onClick) {
        return <>Εάν δεν λάβατε τον κωδικό επαλήθευσης, ελέγξτε τα "Ανεπιθύμητα" email ή προσπαθήστε να τον στείλετε <a onClick={onClick}>ξανά</a></>
    },
    waitingToResendVerificationCode(count) {
        return <>Εάν δεν λάβατε email επαλήθευσης, ελέγξτε τα "Ανεπιθύμητα" email ή προσπαθήστε να το στείλετε <a className="disabled">ξανά</a> σε <span className="count">{count} δευτερόλεπτα</span></>
    }
})
