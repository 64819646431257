import translations from './translations'

translations.add('es', {
    title: 'Congelación de tarjeta',
    freezeYourCardTemporarily: '- Congele su tarjeta temporalmente',
    indefinitely: 'Indefinidamente',
    oneHour: '1 hora',
    twentyFourHour: '24 horas',
    customPeriod: 'Congelar tarjeta',
    cancel: 'Cancelar',
    freezeCard: 'Congelar tarjeta'
})
