import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction, when} from 'mobx'
import moment from 'moment-timezone'
import 'reflect-metadata'

import {PayByLinkStatus, TABLE_STORAGE_KEYS, TableViewType} from '@/constants'
import {getPaymentLinks, PaymentLink, PaymentLinkRequest} from '@/api'
import {openErrorNotification} from '@/utils'

import {IPayByLinkStore} from '@/pages/PayByLink/IPayByLinkStore'
import {TRangePickerContainerStore} from '@/components/containers/RangePickerContainer/TRangePickerContainerStore'
import {getColumns} from '@/pages/PayByLink/utils'
import {
    PayByLinkCreateNewLinkStoreSymbol,
    PayByLinkNewPaymentLinkModalContainerStoreSymbol,
    PayByLinkOverviewModalContainerStoreSymbol,
    PayByLinkPaginationStoreSymbol,
    PayByLinkPaymentLinkDetailsStoreSymbol,
    PayByLinkRangePickerContainerStoreSymbol
} from '@/pages/PayByLink'
import {TModalContainerStore} from '@/components/containers/ModalContainer/TModalContainerStore'
import {payByLinkTabs} from '@/services/mocks'
import {TNewPaymentLinkModalStore, TPaymentLinkDetailsModalStore} from '@/pages/components'
import {PaginationContainerStoreType} from '@/components/containers'
import {statuses} from '@/pages/PayByLink/constants/statuses'
import {TOverviewModalStore} from '@/pages/PayByLink/components/OverviewModal/TOverviewModalStore'
import {LoadingState} from '@/types'
import {PeriodType} from '@/stores/date-picker'
import {storage} from '@/services/storage'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import rootTranslations from '@/translations/translations'

@injectable()
export class PayByLinkStore implements IPayByLinkStore {
    private readonly _rangePickerStore: TRangePickerContainerStore
    private readonly _overviewModalStore: TOverviewModalStore
    private readonly _newPaymentLinkModalStore: TModalContainerStore
    private readonly _paginationStore: PaginationContainerStoreType
    private readonly _createNewPaymentLinkStore: TNewPaymentLinkModalStore
    private readonly _paymentLinkDetailsModalStore: TPaymentLinkDetailsModalStore
    private readonly _profileStore: ProfileStoreInterface

    private _isInitialized = false

    mobileTableViewType = TableViewType.EXPANDED
    isLoading = false
    selectedPaymentLink: PaymentLink = {} as PaymentLink
    createdPaymentLink: PaymentLink = {} as PaymentLink
    _paymentLinks: PaymentLink[] = null
    _columns = getColumns(this)
    status: PayByLinkStatus = payByLinkTabs[0].key
    statusSelectOptions = statuses
    statusTabsOptions = payByLinkTabs

    get merchantId() {
        return this._profileStore?.merchant?.merchantId
    }

    get columns() {
        return this._columns
    }

    get request(): PaymentLinkRequest {
        return {
            ...this.preferences,
            status:
                this.status === PayByLinkStatus.active
                    ? `${PayByLinkStatus.active},${PayByLinkStatus.viewed}`
                    : this.status,
            page: this._paginationStore.pageNumber
        }
    }

    get preferences(): Omit<PaymentLinkRequest, 'page'> {
        return {
            sort: 'created_date',
            order: 'desc',
            from: this._rangePickerStore?.startDate?.format(),
            to: this._rangePickerStore?.endDate?.format(),
            ...(this.status ? {status: this.status} : {}),
            size: this._paginationStore.pageSize
        }
    }

    get paymentLinks(): PaymentLink[] {
        if (!this._paymentLinks && this.merchantId) {
            this.loadPaymentLinks()

            return []
        }

        return this._paymentLinks
    }

    constructor(
        @inject(PayByLinkRangePickerContainerStoreSymbol)
        rangePickerStore: TRangePickerContainerStore,
        @inject(PayByLinkOverviewModalContainerStoreSymbol)
        overviewModalStore: TModalContainerStore,
        @inject(PayByLinkNewPaymentLinkModalContainerStoreSymbol)
        newPaymentLinkModalStore: TModalContainerStore,
        @inject(PayByLinkCreateNewLinkStoreSymbol)
        createNewPaymentLinkStore: TNewPaymentLinkModalStore,
        @inject(PayByLinkPaginationStoreSymbol) paginationStore: PaginationContainerStoreType,
        @inject(PayByLinkPaymentLinkDetailsStoreSymbol)
        paymentLinkDetailsModalStore: TPaymentLinkDetailsModalStore,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface
    ) {
        this._rangePickerStore = rangePickerStore
        this._overviewModalStore = overviewModalStore
        this._newPaymentLinkModalStore = newPaymentLinkModalStore
        this._createNewPaymentLinkStore = createNewPaymentLinkStore
        this._paginationStore = paginationStore
        this._paymentLinkDetailsModalStore = paymentLinkDetailsModalStore
        this._profileStore = profileStore

        makeObservable(this, {
            isLoading: observable,
            _paymentLinks: observable,
            selectedPaymentLink: observable,
            createdPaymentLink: observable,
            mobileTableViewType: observable,
            status: observable,

            columns: computed,
            paymentLinks: computed,
            request: computed,
            merchantId: computed,
            preferences: computed,

            loadPaymentLinks: action.bound,
            openNewPaymentLinkModal: action.bound,
            setStatus: action.bound,
            openOverviewModal: action.bound,
            openPaymentDetailsModal: action.bound
        })

        reaction(
            () => ({
                isNewLinkCreated: this._createNewPaymentLinkStore.loadingState === LoadingState.DONE
            }),
            ({isNewLinkCreated}) => {
                if (isNewLinkCreated) {
                    this.loadPaymentLinks()
                }
            }
        )

        when(
            () => Boolean(this.merchantId),
            () => this.initializePreferences()
        )

        /** reaction:preferences */
        reaction(
            () => this.preferences,
            () => {
                if (!this._isInitialized) {
                    return
                }
                if (this._paginationStore.paging.page === 1) {
                    this.loadPaymentLinks()
                } else {
                    this._paginationStore.setPageNumber(1)
                }
            }
        )

        reaction(
            () => this._paginationStore.pageNumber,
            () => this.loadPaymentLinks()
        )
    }

    setStatus(status: PayByLinkStatus) {
        this.status = status
    }

    setMobileTableViewType = (tableViewType: TableViewType) =>
        (this.mobileTableViewType = tableViewType)

    private initializePreferences = () => {
        const profileEmail = storage.get('email')
        const storageKey = `${TABLE_STORAGE_KEYS.paymentLinks}-${profileEmail}-${this.merchantId}`
        const value = storage.get(storageKey) && JSON.parse(storage.get(storageKey))

        if (value) {
            const {size, status, period: p, from, to} = value
            const period = p as PeriodType

            if (size) {
                this._paginationStore.setPageSize(size)
            }

            if (status) {
                this.setStatus(status)
            }

            if (period && period !== PeriodType.Custom) {
                this._rangePickerStore.setPeriod(period)
            } else if (from && to) {
                this._rangePickerStore.setDates([moment(from), moment(to)])
            }
        }

        /**
         * The 'reaction:preferences' in the constructor should not be executed.
         * Therefore the 'isInitialized' flag is set to true within a setTimeout.
         */
        setTimeout(() => (this._isInitialized = true))

        reaction(
            () => this.preferences,
            () => {
                if (profileEmail) {
                    const period = this._rangePickerStore.period
                    storage.set(storageKey, JSON.stringify({...this.preferences, period}))
                }
            }
        )
    }

    public openOverviewModal(selectedPaymentLink: PaymentLink) {
        this.selectedPaymentLink = selectedPaymentLink
        this._overviewModalStore.setOpen(true)
    }

    public openNewPaymentLinkModal() {
        this._newPaymentLinkModalStore.setOpen(true)
    }

    public openPaymentDetailsModal(link) {
        this._paymentLinkDetailsModalStore.widgetType = 'linkDetails'
        this._paymentLinkDetailsModalStore.isNewLinkDetails = false
        this._paymentLinkDetailsModalStore.setPaymentLink(link)
    }

    async loadPaymentLinks() {
        if (this.isLoading) {
            return
        }

        this.isLoading = true
        try {
            const {result, error} = await getPaymentLinks(this.request)

            runInAction(() => {
                if (error) {
                    return openErrorNotification(error.message)
                }

                if (result) {
                    this._paymentLinks = result.records
                    this._paginationStore.setTotal(result.totalCount)
                }

                if (!result) {
                    this._paymentLinks = []
                    this._paginationStore.setTotal(0)
                }
            })
        } catch (error) {
            runInAction(() => {
                this._paymentLinks = []
                this._paginationStore.setTotal(0)
            })

            return openErrorNotification(rootTranslations().errors.general)
        } finally {
            runInAction(() => {
                this.isLoading = false
            })
        }
    }
}
