import 'reflect-metadata'
import moment from 'moment'
import {action, makeObservable, observable} from 'mobx'
import {inject, injectable} from 'inversify'
import {getChargebackDetails, ChargebackType, ChargebackInfoType} from '@/api'
import {openErrorNotification} from '@/utils'
import {TDrawerContainerStore} from '@/components/containers/DrawerContainer/TDrawerContainerStore'
import {DATE_FORMAT} from '@/stores/chargebacks/ChargebacksTableStore/constants'
import {
    TChargebacksDetailsStore,
    ChargebacksDetailsDrawerContainerStoreSymbol
} from '@/pages/Chargebacks/components'

@injectable()
export class ChargebacksDetailsStore implements TChargebacksDetailsStore {
    data: ChargebackInfoType[] = []
    isLoading: boolean = false
    drawerStore: TDrawerContainerStore
    selectedChargeback: ChargebackType = null

    constructor(
        @inject(ChargebacksDetailsDrawerContainerStoreSymbol) drawerStore: TDrawerContainerStore
    ) {
        this.drawerStore = drawerStore

        makeObservable(this, {
            data: observable,
            isLoading: observable,
            selectedChargeback: observable,

            onOpen: action.bound,
            onClose: action.bound,
            setData: action.bound,
            setIsLoading: action.bound,
            setSelectedChargeback: action.bound
        })
    }

    onOpen = (chargeback: ChargebackType) => {
        this.loadChargebackDetails(chargeback.id)
        this.setSelectedChargeback(chargeback)
        this.drawerStore.setOpen(true)
    }

    onClose = () => {
        this.drawerStore.setOpen(false)
        this.setSelectedChargeback(null)
    }

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading
    }

    setData = (data: ChargebackInfoType[]) => {
        this.data = data
    }

    loadChargebackDetails = async (id: string): Promise<void> => {
        this.setIsLoading(true)

        try {
            const result = await getChargebackDetails(id)

            this.setData(this.transformData(result?.result || []))
        } catch (error) {
            openErrorNotification(error)
        } finally {
            this.setIsLoading(false)
        }
    }

    setSelectedChargeback = (selectedChargeback: ChargebackType) => {
        const transformedChargeback: ChargebackType = selectedChargeback
            ? {
                  ...selectedChargeback,
                  chargebackDate: moment(selectedChargeback.chargebackDate).format(DATE_FORMAT)
              }
            : null

        this.selectedChargeback = transformedChargeback
    }

    transformData = (data: ChargebackInfoType[]): ChargebackInfoType[] => {
        return data?.map((item) => ({
            ...item,
            date: moment(item.date).format(DATE_FORMAT)
        }))
    }
}
