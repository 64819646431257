import React from 'react'
import moment from 'moment'
import {Button, Modal} from 'antd'
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    TabletOutlined,
    DesktopOutlined,
    MobileOutlined,
    RobotOutlined,
    QuestionOutlined
} from '@ant-design/icons'
import {TwoFATrustedDeviceType} from '@/stores/profile/models'
import {DATE_FORMAT, TIME_FORMAT} from '@/constants'

import translations from '../translations'
import styles from '../styles.scss'
import {DELETE_DEVICE} from '@/constants/playwright-ids'

const icons: Record<TwoFATrustedDeviceType, React.ReactElement> = {
    Tablet: <TabletOutlined title={translations().tablet} />,
    Desktop: <DesktopOutlined title={translations().desktop} />,
    Mobile: <MobileOutlined title={translations().mobile} />,
    Bot: <RobotOutlined title={translations().bot} />,
    Unknown: <QuestionOutlined />
}

const columns = [
    {
        title: translations().columns.device,
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        render: (value, {deviceType}) => {
            return (
                <div className={styles.device}>
                    {icons[deviceType] && <div className={styles.icon}>{icons[deviceType]}</div>}{' '}
                    {value}
                </div>
            )
        }
    },
    {
        title: translations().columns.addedOn,
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '25%',
        render: date => date && moment(date).format(`${DATE_FORMAT} | ${TIME_FORMAT}`)
    },
    {
        title: translations().columns.lastLogin,
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: '25%',
        render: date => date && moment(date).format(`${DATE_FORMAT} | ${TIME_FORMAT}`)
    }
]

export const getColumns = (deleteDevice: (id: string) => Promise<void>) => {
    return [
        ...columns,
        {
            key: 'actions',
            render: ({id}) => (
                <Button
                    title={translations().delete}
                    icon={<DeleteOutlined />}
                    size={'small'}
                    shape='circle'
                    onClick={() => {
                        Modal.confirm({
                            width: '500px',
                            icon: <ExclamationCircleOutlined />,
                            content: translations().messages.confirm,
                            okText: translations().yes,
                            cancelText: translations().no,
                            onOk: async () => {
                                await deleteDevice(id)
                            }
                        })
                    }}
                    data-test-id={DELETE_DEVICE}
                ></Button>
            )
        }
    ]
}
