import translations from './translations'

translations.add('el', {
    back: 'Επιστροφή στην σελίδα εισόδου',
    title: 'Ορισμός νέου κωδικού πρόσβασης',
    newPassword: 'Ο νέος σας κωδικός πρόσβασης',
    confirmPassword: 'Επιβεβαίωση Κωδικού',
    errors: {
        newPasswordRequired: 'Παρακαλώ εισάγετε τον κωδικό σας',
        newPasswordInvalid: 'Λανθασμένη μορφή κωδικού πρόσβασης',
        confirmPasswordInvalid: 'Οι κωδικοί πρόσβασης που εισάγατε δεν ταιριάζουν',
        confirmPasswordRequired: 'Παρακαλώ εισάγετε ξανά τον κωδικό πρόσβασής σας για επιβεβαίωση'
    },
    submit: 'Επιβεβαίωση',
    codeMustContain: 'Ο κωδικός πρέπει να περιέχει',
    codeRestrictions: {
        atLeastEightCharacters: 'Τουλάχιστον 8 χαρακτήρες',
        atLeastOneDigit: 'Τουλάχιστον 1 ψηφίο',
        atLeastOneSpecialCharacter: 'Τουλάχιστον 1 ειδικός χαρακτήρας π.χ. !@#&',
        atLeastOneUppercaseLetter: 'Τουλάχιστον 1 κεφαλαίο γράμμα',
        atLeastOneLowercaseLetter: 'Τουλάχιστον 1 πεζό γράμμα'
    }
})
