import translations from './translations'

translations.add('en', {
    date: 'Date',
    orderNumber: 'Order number',
    customer: 'Customer',
    transactionType: 'Transaction type',
    amount: 'Amount',
    customerName: 'Customer name',
    name: 'Name',
    description: 'Description',
    nameOfTheServiceOrItemYouProvide: 'Name of the service or item you provide',
    linkExpiration: 'Link expiry',
    stores: 'Stores',
    theNewPaymentLinkIsCreatedAndCopiedToClipboard: 'The new payment link is created and copied to clipboard',
    copied: 'Text personal if needed',
    errors: {
        storeRequired: 'Store required',
        orderNumberRequired: 'Order number required',
        descriptionRequired: 'Description required',
        linkExpirationRequired: 'Link expiration required',
        customerNameRequired: 'Customer name required'
    },
    ranges: {
        hour24: '24 hours',
        hour48: '48 hours',
        week1: '1 week',
        month1: '1 month'
    }
})
