import {makeAutoObservable, reaction} from 'mobx'
import {inject, injectable} from 'inversify'
import {
    AccountInfoStoreInterface,
    AccountInfoTableFilterRangePickerContainerStoreSymbol,
    AccountInfoTableFilterStatusSelectContainerStoreSymbol
} from '@/pages/business-account/AccountInfo'
import {
    AccountInfo,
    AccountRequest,
    AuthStoreInterface,
    AuthStoreSymbol,
    CardIssuingConfigStoreInterface,
    fetchAccounts,
    fetchAllTransactions,
    getStatusSelectOptions,
    PeriodType,
    TransactionDetailModel,
    TransactionDetailsRequest,
    TransactionStatus
} from '@/stores'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import {openErrorNotification} from '@/utils'
import {LoadingState} from '@/types'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import {
    PaginationContainerStoreType,
    TRangePickerContainerStore,
    TSelectContainerStore
} from '@/components'
import {
    AccountInfoPaginationStoreSymbol,
    CardIssuingConfigsStoreSymbol
} from '@/pages/business-account/AccountInfo/AccountInfo'
import {
    AccountTransactionsFilterButtonStoreInterface,
    AccountTransactionsFilterButtonStoreSymbol
} from '@/pages/business-account/AccountInfo/components/AccountTransactionsFilterButton'
import translations from '@/translations'

@injectable()
export class AccountInfoStore implements AccountInfoStoreInterface {
    private readonly _authStore: AuthStoreInterface
    private readonly _profileStore: ProfileStoreInterface
    private readonly _paginationStore: PaginationContainerStoreType
    private readonly _rangePickerStore: TRangePickerContainerStore
    private readonly _statusSelectStore: TSelectContainerStore
    private readonly _cardIssuingConfigStore: CardIssuingConfigStoreInterface
    private readonly _accountTransactionsFilterButtonStore: AccountTransactionsFilterButtonStoreInterface

    constructor(
        @inject(AuthStoreSymbol) authStore: AuthStoreInterface,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface,
        @inject(AccountInfoPaginationStoreSymbol) paginationStore: PaginationContainerStoreType,
        @inject(CardIssuingConfigsStoreSymbol)
        cardIssuingConfigStore: CardIssuingConfigStoreInterface,
        @inject(AccountInfoTableFilterRangePickerContainerStoreSymbol)
        rangePickerStore: TRangePickerContainerStore,
        @inject(AccountInfoTableFilterStatusSelectContainerStoreSymbol)
        statusSelectStore: TSelectContainerStore,
        @inject(AccountTransactionsFilterButtonStoreSymbol)
        accountTransactionsFilterButtonStore: AccountTransactionsFilterButtonStoreInterface
    ) {
        rangePickerStore.setPeriod(PeriodType.Last7Days)
        statusSelectStore.setOptions(getStatusSelectOptions())
        this._authStore = authStore
        this._profileStore = profileStore
        this._paginationStore = paginationStore
        this._rangePickerStore = rangePickerStore
        this._statusSelectStore = statusSelectStore
        this._cardIssuingConfigStore = cardIssuingConfigStore
        this._accountTransactionsFilterButtonStore = accountTransactionsFilterButtonStore

        makeAutoObservable(this)

        reaction(
            () => this._cardIssuingConfigStore.tokens,
            async () => {
                this._accountInfo = {
                    ...this.accountInfo,
                    iban: this._cardIssuingConfigStore.iban,
                    accountNumber: this._cardIssuingConfigStore.accountNumber,
                    sortCode: this._cardIssuingConfigStore.sortCode
                }

                await this.fetchAccounts()
                await this.fetchTransactions()
            }
        )

        reaction(
            () => ({
                paging: this._paginationStore.paging,
                filter: this._accountTransactionsFilterButtonStore.filterForRequest
            }),
            async () => {
                await this.fetchTransactions()
            }
        )

        reaction(
            () => ({
                startDate: this._rangePickerStore.startDate,
                endDate: this._rangePickerStore.endDate,
                status: this._statusSelectStore.value
            }),
            async () => {
                this._paginationStore.setPageNumber(1)
                await this.fetchTransactions()
            }
        )
    }

    _transactions: TransactionDetailModel[] = []

    income = 4100
    outgoing = 2250
    _accountInfo: AccountInfo = null
    accountsLoadingState: LoadingState = LoadingState.IDLE
    transactionsLoadingState: LoadingState = LoadingState.IDLE

    get accountRequest(): Partial<AccountRequest> {
        return {
            limit: 10,
            page: 1,
            merchant: this._cardIssuingConfigStore.tokens.merchant
        }
    }

    get transactionsRequest(): Partial<TransactionDetailsRequest> {
        return {
            startDate: this._rangePickerStore.startDate?.format(),
            endDate: this._rangePickerStore.endDate?.format(),
            page: this._paginationStore.paging.page,
            limit: this._paginationStore.paging.size,
            ...(this._statusSelectStore.value
                ? {status: this._statusSelectStore.value as TransactionStatus}
                : {}),
            ...this._accountTransactionsFilterButtonStore.filterForRequest
        }
    }

    get accountName() {
        return this._profileStore?.merchant?.name
    }

    get accountInfo(): AccountInfo {
        if (
            this._cardIssuingConfigStore.tokens.merchant &&
            this.accountsLoadingState === LoadingState.IDLE
        ) {
            this.fetchAccounts()
        }

        return this._accountInfo
    }

    get transactions() {
        if (
            this._cardIssuingConfigStore.tokens.merchant &&
            this.transactionsLoadingState === LoadingState.IDLE
        ) {
            this.fetchTransactions()
        }

        return this._transactions
    }

    fetchAccounts = async () => {
        if (this.accountsLoadingState === LoadingState.LOADING) {
            return
        }

        this.accountsLoadingState = LoadingState.LOADING

        try {
            const {result, error} = await fetchAccounts(this.accountRequest)

            if (error) {
                this.accountsLoadingState = LoadingState.FAILED
                return openErrorNotification(error.message)
            } else {
                const firstCompanyAccount =
                    result?.data && result.data.items && result.data.items[0]

                this._accountInfo = {
                    ...this.accountInfo,
                    ...(firstCompanyAccount?.balances && firstCompanyAccount?.balances[0])
                }

                this.accountsLoadingState = LoadingState.DONE
            }
        } catch (e) {
            this.accountsLoadingState = LoadingState.FAILED
            return openErrorNotification(translations().errors.general)
        }
    }

    onPayAndTransferClick = () => {
        getRouterStore().push(ROUTES.businessAccount.makeTransfer)
    }

    fetchTransactions = async () => {
        if (this.transactionsLoadingState === LoadingState.LOADING) {
            return
        }

        this.transactionsLoadingState = LoadingState.LOADING

        try {
            const {result, error} = await fetchAllTransactions(this.transactionsRequest)

            if (error) {
                this.transactionsLoadingState = LoadingState.FAILED
                return openErrorNotification(error.message)
            } else {
                this._transactions = result.data?.items
                this._paginationStore.setTotal(result.data?.totalItems)

                this.transactionsLoadingState = LoadingState.DONE
            }
        } catch (e) {
            this.transactionsLoadingState = LoadingState.FAILED
            return openErrorNotification(translations().errors.general)
        }
    }
}
