import {OnlinePaymentStatus} from '@/constants'
import appTranslations from '@/translations'
import {SelectItem} from '@/components/dumb/Select'

export const statusTabs = [
    {
        title: appTranslations().constants.onlinePaymentStatus[OnlinePaymentStatus.all],
        key: OnlinePaymentStatus.all
    },
    {
        title: appTranslations().constants.onlinePaymentStatus[OnlinePaymentStatus.auth],
        key: OnlinePaymentStatus.auth
    },
    {
        title: appTranslations().constants.onlinePaymentStatus[OnlinePaymentStatus.charge],
        key: OnlinePaymentStatus.charge
    }
]

export const statusSelectOptions: SelectItem[] = Object.entries(
    appTranslations().constants.onlinePaymentStatus
)
    .map(([value, label]) => ({
        value,
        label
    }))
    .filter((item) => item.value !== OnlinePaymentStatus.other)
