import translations from './translations'
import React from 'react'

translations.add('en', {
    title: 'PayPal',
    description: (
        <>
            DNA Payments have partnered with PayPal to provide merchants with an easy route to add
            this popular payment method for customers. Merchants with existing PayPal accounts can
            link them on this page or if a PayPal account needs to be created it can also be done
            here.
            <br />
            <br />
            Once an account is linked the PayPal payment option can easily be enabled or disabled
            using the toggle option, leaving the merchant in complete control.
            <br />
            <br />
        </>
    ),
    paypalStatus: 'Paypal status',
    settings: 'Customize settings',
    yourAccountDisabled:
        'Your PayPal setup is currently disabled. In order to start accepting PayPal payments again, please activate it.',
    consentRevokedText:
        'The PayPal account setup consents has been revoked or the PayPal merchant account is closed.',
    readyPaymentText: 'You are ready to accept PayPal payments.'
})
