import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {LoadingState} from '@/types'
import {IconTitle, PageHeader} from '@/components'
import {hasPermissions} from '@/stores/auth/services/utils'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {
    AddNewDomainModal,
    DomainList,
    NoDomains,
    TApplePayDomainsStore,
    UnregisterReasonConfirmModal
} from '@/pages/payment-methods'
import translations from './translations'
import styles from './styles.scss'
import {ADD_DOMAIN} from '@/constants/playwright-ids'

export const ApplePayDomainsStoreSymbol = Symbol('ApplePayDomainsStore')

export const Domains = observer(() => {
    const store = useInjection<TApplePayDomainsStore>(ApplePayDomainsStoreSymbol)

    const renderAddNewDomainLink = () =>
        store.domains.length !== 0 && (
            <a href='#' onClick={store.onOpenRegisterModal} data-test-id={ADD_DOMAIN}>
                + {translations().addNewDomain}
            </a>
        )

    return (
        <PageHeader
            className={styles.header}
            title={
                <IconTitle
                    title={translations().title}
                    tooltipText={translations().domainsTooltipText}
                />
            }
            extra={
                hasPermissions([PermissionsMap.payment_methods.full])
                    ? renderAddNewDomainLink()
                    : null
            }
            border={'none'}
        >
            {store.domains.length > 0 ? (
                <DomainList
                    loading={store.loadingState === LoadingState.LOADING}
                    dataSource={store.domains}
                />
            ) : null}

            {store.loadingState === LoadingState.DONE && store.domains.length === 0 && (
                <NoDomains onClick={store.onOpenRegisterModal} />
            )}

            <UnregisterReasonConfirmModal
                open={store.isDomainUnregisterModalVisible}
                confirmLoading={store.isUnregisterLoading}
                onOk={store.onUnregisterDomain}
                onCancel={store.onCloseUnregisterModal}
            />

            <AddNewDomainModal
                shouldShowDownloadStep={process.env.APP_ENV === 'prod' || false}
                open={store.isRegisterModalVisible}
                confirmLoading={store.isRegisterLoading}
                onCancel={store.onCloseRegisterModal}
                onOk={store.onRegisterDomain}
            />
        </PageHeader>
    )
})
