import translations from './translations'

translations.add('ro', {
    buttons: {
        send: 'Trimite',
        cancel: 'Anulare',
        sendReceipt: 'Trimite chitanță'
    },
    labels: {
        email: 'Adresă de email'
    },
    errors: {
        emailInvalid: 'Adresa de email este invalidă',
        emailRequired: 'Adresa de email este obligatorie'
    }
})
