import translations from './translations'

translations.add('en', {
    title: 'Card settings',
    freeze: {
        freezeCard: 'Freeze card',
        inCaseYouWantToFreeze: 'In case you want to stop card work for a while'
    },
    block: {
        blockCard: 'Block card',
        inCaseYouWantToBlock: 'In case of lost or stolen card'
    },
    activate: {
        activateCard: 'Activate card',
        inCaseYouWantToActivate: 'In case of blocked card'
    },
    getCVV: 'Get CVV',
    getPIN: 'Get PIN',
    cardholder: 'Cardholder',
    address: 'Address'
})
