import {getWithAuth} from '@/api'
import {StoreType, StoreTerminalType, StoreWithTerminalsType, DossierStoreType} from '../types'

/**
 * Retrieves all terminals from the Way4 system. This is necessary because ECOM terminals are stored in our system.
 * The accurate list of POS terminals is saved in Way4 under Dossier.
 * @param type ECOM / POS
 */
export const fetchMerchantTerminals = () => getWithAuth<StoreTerminalType[]>('/oppapi/v1/terminals')

/**
 * Returns all stores owned by the merchant of the current user, regardless of whether the user has access to these stores or not.
 */
export const fetchAllStoresOfMerchant = () =>
    getWithAuth<StoreType[]>('/oppapi/v1/merchants/stores')

/**
 * Returns all stores owned by the current user's merchant, ensuring that the user has access to these stores.
 */
export const fetchAvailableStoresOfMerchant = () =>
    getWithAuth<StoreWithTerminalsType[]>('/oppapi/v1/stores')

export const fetchAvailableStoresOfMerchantV2 = () =>
    getWithAuth<DossierStoreType[]>('/oppapi/v2/stores')
