import 'reflect-metadata'
import {inject, injectable} from 'inversify'
import {action, makeObservable, observable, runInAction, when} from 'mobx'
import timezones from 'timezones-list'
import {updateProfileTimezone} from '@/stores/profile/services/fetchers'
import {setDefaultTimeZone} from '@/utils/moment-utils'
import {openErrorNotification, openSuccessNotification} from '@/utils'
import {storage} from '@/services/storage'
import {LoadingState} from '@/types'
import {DEFAULT_TIMEZONE} from '@/constants'
import {TimeZoneStoreInterface} from '@/pages/Profile/components'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import translations from '@/pages/Profile/components/TimeZone/translations'

@injectable()
export class TimeZoneStore implements TimeZoneStoreInterface {
    private _profileStore: ProfileStoreInterface
    public savedTimeZone: string = ''
    public isLoading: boolean = false

    constructor(@inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface) {
        this._profileStore = profileStore

        makeObservable(this, {
            isLoading: observable,
            savedTimeZone: observable,

            setIsLoading: action.bound
        })

        when(
            () => this._profileStore?.loadingState === LoadingState.DONE,
            () => {
                runInAction(() => {
                    this.savedTimeZone =
                        this._profileStore?.profileData?.timezone || DEFAULT_TIMEZONE
                })
            }
        )
    }

    get timeZoneList() {
        const list = timezones.map(({tzCode}) => ({
            label: tzCode,
            value: tzCode
        }))

        return list
    }

    saveTimeZone = async (timezone: string) => {
        this.setIsLoading(true)

        try {
            await updateProfileTimezone(timezone)

            storage.set('timezone', timezone)

            openSuccessNotification(translations().successMessage)

            setTimeout(() => location.reload(), 700)
        } catch (error) {
            openErrorNotification(error.message)
            throw new Error(error.message)
        } finally {
            this.setIsLoading(false)
        }
    }

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading
    }
}
