import React from 'react'
import {observer} from 'mobx-react'
import {List as AntList} from 'antd'
import {Icon, List} from '@/components'
import {hasPermissions} from '@/stores/auth/services/utils'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {DomainListProps} from './props'
import styles from './styles.scss'
import {DELETE_DOMAIN, DOMAIN_NAME} from '@/constants/playwright-ids'

export const DomainList: React.FC<DomainListProps> = observer((
    {dataSource, loading}
) => {
    return <List
        loading={loading}
        dataSource={dataSource}
        renderItem={(item) => (
            <AntList.Item
                className={styles.listItem}
                extra={(
                    hasPermissions([PermissionsMap.payment_methods.full]) ? <Icon
                        data-test-id={DELETE_DOMAIN + '_' + item.name}
                        onClick={() => item.onTrashIconClick(item.name)}
                        type={'trash'}
                    /> : null
                )}
                key={item.name}
                data-test-id={DOMAIN_NAME + '_' + item.name}
            >
                {item.name}
            </AntList.Item>
        )}
    />
})
