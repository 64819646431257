import {register} from 'dna-react-ioc'
import {RangePickerStore, SelectContainerStore, PaginationStore, DrawerStore} from '@/stores'
import {
    TRangePickerContainerStore,
    TSelectContainerStore,
    PaginationContainerStoreType
} from '@/components'
import {TDrawerContainerStore} from '@/components/containers/DrawerContainer/TDrawerContainerStore'
import {ChargebacksStoreSymbol, TChargebacksStore} from '@/pages/Chargebacks'
import {
    ChargebacksStore,
    ChargebacksDetailsStore,
    ChargebacksFilterStore,
    ChargebacksTableStore
} from '@/stores/chargebacks'
import {
    ChargebacksFilterStoreSymbol,
    TChargebacksFilterStore,
    ChargebacksTableStoreSymbol,
    TChargebacksTableStore,
    ChargebacksDetailsStoreSymbol,
    TChargebacksDetailsStore,
    ChargebacksRangePickerContainerStoreSymbol,
    ChargebacksStatusSelectContainerStoreSymbol,
    ChargebacksDetailsDrawerContainerStoreSymbol,
    ChargebacksPaginationStoreSymbol
} from '@/pages/Chargebacks/components'

export const chargebacksState = () => {
    register<TChargebacksStore>(ChargebacksStoreSymbol).to(ChargebacksStore).inSingletonScope()
    register<TChargebacksFilterStore>(ChargebacksFilterStoreSymbol)
        .to(ChargebacksFilterStore)
        .inSingletonScope()
    register<TChargebacksTableStore>(ChargebacksTableStoreSymbol)
        .to(ChargebacksTableStore)
        .inSingletonScope()
    register<TChargebacksDetailsStore>(ChargebacksDetailsStoreSymbol)
        .to(ChargebacksDetailsStore)
        .inSingletonScope()
    register<TDrawerContainerStore>(ChargebacksDetailsDrawerContainerStoreSymbol)
        .to(DrawerStore)
        .inSingletonScope()
    // filter stores
    register<TSelectContainerStore>(ChargebacksStatusSelectContainerStoreSymbol)
        .to(SelectContainerStore)
        .inSingletonScope()
    register<TRangePickerContainerStore>(ChargebacksRangePickerContainerStoreSymbol)
        .to(RangePickerStore)
        .inSingletonScope()

    register<PaginationContainerStoreType>(ChargebacksPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()
}
