import translations from './translations'

translations.add('ro', {
    title: 'Plăți online',
    charge: 'Încasare',
    refund: 'Restituire',
    paymentDetails: 'Detalii plată',
    chargedAmount: 'Sumă încasată',
    refundAmount: 'Sumă restituită',
    balance: 'Sold',
    donationAmount: 'Sumă donație',
    date: 'Dată',
    status: 'Statut',
    summary: 'Rezumat',
    verifications: 'Verificări',
    channel: 'Canal',
    shop: 'Magazin',
    search: 'Căutare',
    statement: 'Extras de cont',
    select: 'Selectați',
    items: 'Articole',
    description: 'Descriere',
    customer: 'Client',
    orderNumber: 'Număr comandă',
    amount: 'Sumă',
    rnn: 'RNN',
    transactionType: 'Tip tranzacție',
    authorizedOn: 'Autorizat la',
    chargedOn: 'Încasat la',
    refundedOn: 'Restituit la',
    issuer: 'Emitent',
    card: 'Card',
    is3DSecure: '3DS',
    cardExpiryDate: 'Dată expirare card',
    acquirerResponseCode: 'Cod de răspuns al procesatorului',
    authCode: 'Cod autorizare',
    AVSHouseNumberResult: 'Număr de casă AVS',
    AVSPostcodeResult: 'Cod poștal AVS',
    yes: 'Da',
    no: 'No',
    payerDetails: 'Detalii client',
    payer: 'Nume',
    accountId: 'ID cont',
    email: 'Email',
    phone: 'Telefon',
    resultMessage: 'Motiv respingere',
    geolocation: {
        payerIp: 'IP plătitorului',
        location: 'Locație'
    },
    paymentPage: {
        title: 'Pagina de plată',
        language: 'Limbă',
        postLinkAddress: 'Adresă PostLink',
        postLink: 'PostLink',
        ok: 'Ok',
        failed: 'Eșuat'
    },
    signifydDetails: 'Detalii Signifyd',
    signifydId: 'ID Signifyd',
    decision: 'Decizie',
    reason: 'Motiv',
    score: 'Scor',
    id: 'ID',
    processingTypeName: 'Tip de procesare',
    reference: 'RRN',
    cancelOn: 'Anulat la',
    avsResult: 'Rezultat AVS',
    payPal: {
        details: 'Detalii PayPal',
        paypalOrderStatus: 'Statut comandă',
        paypalPayerName: 'Nume plătitor',
        paypalEmail: 'Email plătitor',
        paypalPayerID: 'ID plătitor',
        paypalPayerCountryCode: 'Cod țară plătitor',
        paypalCaptureStatus: 'Statut captură',
        paypalCaptureStatusReason: 'Motiv statut captură',
        paypalSellerProtectionStatus: 'Statut protecție vânzător',
        paypalSellerProtectionDisputeCategories: 'Categorii dispută protecție vânzător'
    },
    searchFilter: {
        transactionId: 'ID tranzacție',
        customerAccountId: 'ID cont client',
        description: 'Descriere',
        order: 'Număr comandă #',
        email: {
            startsWith: 'Email începe cu'
        },
        customerName: 'Nume client',
        card: {
            startsWith: 'Card începe cu',
            endsWith: 'Card se termină cu'
        }
    },
    searchFilterErrors: {
        transactionId: 'Vă rugăm introduceți un ID valid'
    },
    createdDate: 'Data creării (GMT)',
    payoutAmount: 'Suma confirmată',
    currency: 'Moneda',
    payoutDate: 'Confirmed date (GMT)',
    processingType: 'Tip de procesare',
    invoiceId: 'Număr comandă',
    paymentMethod: 'Metode de plată',
    cardType: 'Scheme de carduri',
    cardMask: 'Masca cardului',
    secure3D: '3DS',
    avsHouseNumberResult: 'Rezultat AVS pentru numărul de casă',
    avsPostcodeResult: 'Rezultatul AVS pentru codul poștal',
    cscResult: 'Rezultat CSC',
    paEnrollment: 'Înscriere PA',
    paAuthentication: 'Autentificare PA',
    payerName: 'Nume client',
    payerEmail: 'Email client',
    payerPhone: 'Telefon client',
    payerIp: 'IP plătitorului',
    ipCountry: 'IP Țara',
    ipCity: 'IP Oraș',
    ipLatitude: 'Latitudine IP',
    ipLongitude: 'Longitudine IP',
    message: 'Mesaj',
    transactionTypeLower: 'Tip tranzacție',
    initiatorEmail: 'Inițiat de',
    statuses: {
        'Matched': 'Potrivit',
        'Not Matched': 'Nepotrivit',
        'Partial Match': 'Potrivire parțială',
        'Yes': 'Da',
        'No': 'Nu',
        'Authenticated': 'Autentificat',
        'Not authenticated': 'Neautentificat',
        'Unable to authenticate': 'Imposibilitate de autentificare',
        'Attempted': 'Încercat',
        'Not attempted': 'Nefăcut',
        'Enrolled': 'Înscris',
        'Not enrolled': 'Neînscris',
        'Challenge required': 'Cerință de provocare',
        'Not Provided': 'Nu furnizat',
        'Not Checked': 'Nefăcut',
        'Unable to check': 'Imposibilitate de verificare',
        'Not Set': 'Nesetat',
        'Authentication rejected': 'Autentificare respinsă'
    }
})
