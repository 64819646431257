import React from 'react'
import {Col, Divider, Form, Row, Typography} from 'antd'
import {AmountFormField, Button, Card, Input} from '@/components'
import {MakeTransferFormProps} from './props'
import styles from './styles.scss'
import {getAmountWithCurrency} from '@/utils'

export const MakeTransferForm = ({form, onFinish, senderName, balance}: MakeTransferFormProps) => {
    return (
        <Form form={form} layout={'vertical'} onFinish={onFinish} autoComplete='off'>
            <Card>
                <Typography.Title type={'secondary'} level={5}>
                    From account
                </Typography.Title>
                <Typography.Title level={5}>{senderName}</Typography.Title>
                <Typography.Title type={'secondary'} level={5}>
                    Available
                </Typography.Title>
                <Typography.Title level={3}>{getAmountWithCurrency(balance)}</Typography.Title>
                <Divider className={styles.divider} type={'horizontal'} />
                <Typography.Text className={styles.formSubtitle}>To recipient</Typography.Text>
                <br />
                <br />
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={{span: 15, order: 1}}>
                        <Form.Item
                            label={'Account holder name'}
                            name={'receiverName'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required'
                                }
                            ]}
                        >
                            <Input placeholder={'Account holder name'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={{span: 15, order: 2}}>
                        <Form.Item
                            label={'Iban'}
                            name={'iban'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required'
                                }
                            ]}
                        >
                            <Input placeholder={'Iban'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={{span: 15, order: 3}}>
                        <Form.Item
                            label={'Sort code'}
                            name={'sortCode'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required'
                                }
                            ]}
                        >
                            <Input placeholder={'Sort code'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider className={styles.divider} type={'horizontal'} />
                <Typography.Text className={styles.formSubtitle}>Payment details</Typography.Text>
                <br />
                <br />
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={{span: 15, order: 5}}>
                        <AmountFormField form={form} />
                    </Col>
                    <Col xs={24} sm={{span: 15, order: 6}}>
                        <Form.Item
                            label={'Reference'}
                            name={'reference'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required'
                                }
                            ]}
                        >
                            <Input placeholder={'Reference'} />
                        </Form.Item>
                    </Col>
                </Row>

                <br />
                <Button htmlType={'submit'}>Continue</Button>
            </Card>
        </Form>
    )
}
