import {ChartOptions} from 'chart.js'
import {injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import moment from 'moment'
import {Colors, Currencies, OnlinePaymentStatus} from '@/constants'
import {TRangePickerContainerStore, TSelectContainerStore} from '@/components/containers'
import {barChartConfig} from '@/services/chart-config'
import {TTransactionsComparisonCardWithBarChartContainerStore} from '@/components/containers/TransactionsComparisonCardWithBarChartContainer'
import {AuthStoreInterface} from '@/stores'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol,
    EcomStoreSelectStore
} from '@/stores/store-and-terminals'
import {LoadingState} from '@/types'
import {openErrorNotification} from '@/utils'
import {generateInterval, getIntervalValue} from '@/stores/reports/services/date-utils'
import {getECOMTransactionsSummary} from '@/stores/reports/services/fetchers'
import {emptyChartDataGenerator} from '@/stores/reports/services/mocks'
import {generateChartTitleForTwoRanges} from '@/stores/reports/TransactionsComparisonCardWithBarChartContainerStore/services/date-utils'
import translations from './translations'
import {Interval} from '@/stores/reports/models/Interval'
import {subscribe} from 'dna-react-ioc'
import {getEcomMostUsedDefaultCurrency} from '@/stores/handbooks/services'
import rootTranslations from '@/translations'

@injectable()
export class ECOMTransactionsComparisonCardWithBarChartContainerStore
    implements TTransactionsComparisonCardWithBarChartContainerStore
{
    private _authStore: AuthStoreInterface
    private _rangePickerStore: TRangePickerContainerStore
    private _currencySelectStore: TSelectContainerStore
    private _merchantStoresStore: AvailableStoresDataStore
    private _storeSelectStore: EcomStoreSelectStore
    private _statusSelectStore: TSelectContainerStore

    constructor(
        authStore: AuthStoreInterface,
        rangePickerStore: TRangePickerContainerStore,
        currencySelectStore: TSelectContainerStore,
        storeSelectStore: EcomStoreSelectStore,
        statusSelectStore: TSelectContainerStore
    ) {
        this._authStore = authStore
        this._rangePickerStore = rangePickerStore
        this._currencySelectStore = currencySelectStore
        this._merchantStoresStore = subscribe<AvailableStoresDataStore>(
            AvailableStoresDataStoreSymbol
        )
        this._storeSelectStore = storeSelectStore
        this._statusSelectStore = statusSelectStore

        makeObservable(this, {
            _data: observable,
            allTransactions: observable,
            dataLoadingState: observable,
            selectedDataType: observable,
            selectedStatus: computed,
            data: computed,
            currency: computed,
            dateRange: computed,
            setSelectedDataType: action
        })

        reaction(
            () => ({
                previousRangeFrom: this._rangePickerStore.prevDates.startDate,
                previousRangeTo: this._rangePickerStore.prevDates.endDate,
                currentRangeFrom: this._rangePickerStore.startDate,
                currentRangeTo: this._rangePickerStore.endDate,
                selectedStatus: this.selectedStatus,
                currency: this._currencySelectStore.value,
                storeId: this._storeSelectStore?.value
            }),
            ({
                previousRangeFrom,
                previousRangeTo,
                currentRangeFrom,
                currentRangeTo,
                selectedStatus,
                currency
            }) => {
                if (
                    previousRangeFrom &&
                    previousRangeTo &&
                    currentRangeFrom &&
                    currentRangeTo &&
                    selectedStatus &&
                    currency
                ) {
                    this.loadData()
                }
            }
        )

        reaction(
            () => this._authStore.isAuthenticated,
            () => {
                if (this._authStore.isAuthenticated) {
                    this.loadData()
                }
            }
        )
    }

    _data = {amount: undefined, count: undefined}
    selectedDataType = this.dataTypes[0]
    dataLoadingState = LoadingState.IDLE
    previousRangeColour = Colors.YELLOW
    currentRangeColour = Colors.GREEN
    allTransactions = []

    get selectedStatus() {
        return this._statusSelectStore.value as OnlinePaymentStatus
    }

    get statuses() {
        return this._statusSelectStore.options
    }

    get dataTypes(): {key: string; title: string}[] {
        return [
            {key: 'amount', title: translations().dataTypes.amount},
            {key: 'count', title: translations().dataTypes.count}
        ]
    }

    get currency() {
        return this._currencySelectStore.value as Currencies
    }

    get dateRange() {
        return {
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate
        }
    }

    get currentInterval() {
        return generateInterval({
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate
        })
    }

    async loadData() {
        if (this.dataLoadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.dataLoadingState = LoadingState.LOADING
        })

        const previousInterval = generateInterval({
            from: this._rangePickerStore.prevDates.startDate,
            to: this._rangePickerStore.prevDates.endDate
        })
        const storeId = this._storeSelectStore?.value
        const status =
            this.selectedStatus === OnlinePaymentStatus.refund
                ? `${OnlinePaymentStatus.refund},${OnlinePaymentStatus.credited}`
                : this.selectedStatus
        const requestForCurrentRange = {
            from: this._rangePickerStore.startDate.format(),
            to: this._rangePickerStore.endDate.format(),
            interval: this.currentInterval,
            status: status,
            currency: this._currencySelectStore.value,
            ...(storeId ? {storeId} : {})
        }

        const requestForPreviousRange = {
            from: this._rangePickerStore.prevDates.startDate.format(),
            to: this._rangePickerStore.prevDates.endDate.format(),
            interval: previousInterval,
            status: status,
            currency: this._currencySelectStore.value,
            ...(storeId ? {storeId} : {})
        }

        await runInAction(async () => {
            try {
                const {result, error} = await getECOMTransactionsSummary(requestForCurrentRange)
                const {result: resultForPreviousRange, error: errorForPreviousRange} =
                    await getECOMTransactionsSummary(requestForPreviousRange)

                if (error || errorForPreviousRange) {
                    this.dataLoadingState = LoadingState.FAILED
                    this.allTransactions = []
                    return openErrorNotification(error?.message || errorForPreviousRange?.message)
                }

                this.allTransactions = result

                const labels =
                    this.currentInterval === Interval.DAY
                        ? result?.map(
                              (item, index) =>
                                  `${getIntervalValue({
                                      interval: this.currentInterval,
                                      date: moment(resultForPreviousRange[index]?.date)
                                  })}/${getIntervalValue({
                                      interval: this.currentInterval,
                                      date: moment(item.date)
                                  })}`
                          )
                        : result?.map((item, index) =>
                              getIntervalValue({
                                  interval: this.currentInterval,
                                  date: moment(item.date)
                              })
                          )

                this._data = {
                    amount: {
                        labels,
                        datasets: [
                            {
                                label: translations().previousRangeData,
                                currency: this._currencySelectStore.value,
                                data: resultForPreviousRange?.map((item) => item.amount),
                                dates: resultForPreviousRange?.map((item) => item.date),
                                backgroundColor: [Colors.YELLOW]
                            },
                            {
                                label: translations().currentRangeData,
                                currency: this._currencySelectStore.value,
                                data: result?.map((item) => item.amount),
                                dates: result?.map((item) => item.date),
                                backgroundColor: [Colors.GREEN]
                            }
                        ]
                    },
                    count: {
                        labels,
                        datasets: [
                            {
                                label: translations().previousRangeData,
                                data: resultForPreviousRange?.map((item) => item.count),
                                backgroundColor: [Colors.YELLOW],
                                dates: resultForPreviousRange?.map((item) => item.date)
                            },
                            {
                                label: translations().currentRangeData,
                                data: result?.map((item) => item.count),
                                backgroundColor: [Colors.GREEN],
                                dates: result?.map((item) => item.date)
                            }
                        ]
                    }
                }

                this.dataLoadingState = LoadingState.DONE
            } catch (error) {
                this.dataLoadingState = LoadingState.FAILED
                openErrorNotification(rootTranslations().errors.general)
            }
        })
    }

    get data() {
        if (!this._currencySelectStore.value && this._merchantStoresStore.ecomStores) {
            this._currencySelectStore.setValue(
                getEcomMostUsedDefaultCurrency(this._merchantStoresStore.ecomStores)
            )
        }

        if (
            this.dataLoadingState !== LoadingState.LOADING &&
            this.dataLoadingState !== LoadingState.FAILED &&
            this.dataLoadingState !== LoadingState.DONE &&
            this._currencySelectStore.value
        ) {
            this.loadData()
        }

        if (!this._data || !this._data.amount || !this._data.count) {
            const options = barChartConfig.barOptions({
                title: generateChartTitleForTwoRanges({
                    previousRangeFrom: this._rangePickerStore.prevDates.startDate,
                    previousRangeTo: this._rangePickerStore.prevDates.endDate,
                    currentRangeFrom: this._rangePickerStore.startDate,
                    currentRangeTo: this._rangePickerStore.endDate
                }),
                showTooltip: false
            }) as ChartOptions

            return {
                data: emptyChartDataGenerator(
                    this.dateRange,
                    generateInterval({
                        from: this._rangePickerStore.startDate,
                        to: this._rangePickerStore.endDate
                    }),
                    true
                ),
                options
            }
        }

        return {
            data: this._data[this.selectedDataType.key],
            options: barChartConfig.barCompareOptions({
                title: generateChartTitleForTwoRanges({
                    previousRangeFrom: this._rangePickerStore.prevDates.startDate,
                    previousRangeTo: this._rangePickerStore.prevDates.endDate,
                    currentRangeFrom: this._rangePickerStore.startDate,
                    currentRangeTo: this._rangePickerStore.endDate
                }),
                renderTooltipTitle: (tooltipItem) => {
                    const [tooltip] = tooltipItem
                    return getIntervalValue({
                        interval: this.currentInterval,
                        date: moment(tooltip.dataset.dates[tooltip.dataIndex])
                    })
                }
            }) as ChartOptions
        }
    }

    setSelectedStatus = (status: OnlinePaymentStatus) => {
        this._statusSelectStore.setValue(status)
    }

    setSelectedDataType = (dataTypeKey: string) => {
        this.selectedDataType = this.dataTypes.find((item) => (item.key = dataTypeKey))
    }
}
