import React from 'react'
import {Menu, Modal} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {TLinkActionsMenuStore} from '@/pages/components'
import {PayByLinkLinkActionsMenuStoreSymbol} from '@/pages/PayByLink'
import {PayByLinkStatus} from '@/constants'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import translations from './translations'
import {LinkActionsMenuProps} from './props'
import styles from './styles.scss'
import {
    CREATE_PAYMENT_LINK,
    DEACTIVATE_PAYMENT_LINK,
    EDIT_PAYMENT_LINK,
    SHARE_PAYMENT_LINK
} from '@/constants/playwright-ids'

export const LinkActionsMenu: React.FC<LinkActionsMenuProps> = observer(({className, paymentLink}) => {
    const store = useInjection<TLinkActionsMenuStore>(PayByLinkLinkActionsMenuStoreSymbol)

    const items = [
        paymentLink.status !== PayByLinkStatus.cancelled &&
        paymentLink.status !== PayByLinkStatus.paid &&
        paymentLink.status !== PayByLinkStatus.expired &&
        paymentLink.status !== PayByLinkStatus.attempted &&
        hasPermissions([PermissionsMap.payment_links.full]) && {
            key: '1',
            label: (
                <a
                    data-test-id={EDIT_PAYMENT_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                        e.stopPropagation()
                        store.openEditModal(paymentLink)
                        store.closeModals()
                    }}
                >
                    {translations().edit}
                </a>
            )
        },
        paymentLink.status !== PayByLinkStatus.cancelled &&
        paymentLink.status !== PayByLinkStatus.paid &&
        paymentLink.status !== PayByLinkStatus.expired &&
        hasPermissions([PermissionsMap.payment_links.full, PermissionsMap.payment_links.read]) && {
            key: '2',
            label: (
                <a
                    data-test-id={SHARE_PAYMENT_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                        e.stopPropagation()
                        store.setSharePaymentLink(paymentLink)
                    }}
                >
                    {translations().share}
                </a>
            )
        },
        hasPermissions([PermissionsMap.payment_links.full]) && {
            key: '3',
            label: (
                <a
                    data-test-id={CREATE_PAYMENT_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                        e.stopPropagation()
                        store.createSimilarPaymentLink(paymentLink)
                    }}
                >
                    {translations().createSimilar}
                </a>
            )
        },
        paymentLink.status !== PayByLinkStatus.cancelled &&
        paymentLink.status !== PayByLinkStatus.paid &&
        paymentLink.status !== PayByLinkStatus.expired &&
        hasPermissions([PermissionsMap.payment_links.full]) && {
            key: '4',
            label: (
                <a
                    data-test-id={DEACTIVATE_PAYMENT_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                        e.stopPropagation()
                        store.closeModals()
                        Modal.confirm({
                            icon: <ExclamationCircleOutlined />,
                            content: translations().areYouSureYouWantToDeactivate,
                            okText: translations().yes,
                            cancelText: translations().no,
                            onOk: () => store.cancelPaymentLink(paymentLink.id)
                        })
                    }}
                >
                    {translations().deactivate}
                </a>
            )
        }
    ]

    const isMenuVisible = items.filter(item => item).length > 0

    return (
        isMenuVisible ? <div className={styles.menu}>
            <Menu
                className={className}
                items={items}
            />
        </div> : null
    )
})
