import React from 'react'
import translations from './translations'

translations.add('es', {
    overviewDescription: 'Nuestra página de Visión general ofrece una perspectiva a de sus flujos de pagos, donde puede ver análisis en forma de gráficos en tiempo real, supervisar sus pagos e informar sobre ellos.',
    posPaymentsDescription: 'Nuestro Portal del Comerciante proporciona actualizaciones en tiempo real para cada pago de TPV que procesa, donde puede buscar y desglosar sus pagos utilizando las herramientas de filtrado.',
    posAmexPaymentsDescription: 'Supervise sus transacciones de POS procesadas a través de American Express, donde puede buscar y detallar sus pagos utilizando las herramientas de filtro.',
    onlinePaymentsDescriptions: 'Nuestro portal para comerciantes proporciona actualizaciones en tiempo real para cada pago online que procesa, donde puede supervisar y gestionar los pagos, realizar reembolsos o cancelar un pago.',
    paymentLinksDescription: 'Ha activado los enlaces de pago. Los enlaces de pago le permiten aceptar pagos online sin utilizar una página web creando y enviando un enlace de pago a su cliente.',
    paymentLinksCreate: 'Puede crear y enviar un Enlace de pago a su cliente utilizando este botón y cobrar de modo online en tiempo real.',
    virtualTerminalDescription: 'Nuestro terminal virtual le permite aceptar pagos por teléfono o por correo electrónico, de modo que puede aceptar pagos a distancia sin necesidad de utilizar una página web.',
    virtualTerminalDetails: 'Nuestro terminal virtual le permite aceptar pagos por teléfono o por correo electrónico, de modo que puede aceptar pagos a distancia sin necesidad de utilizar una página web.',
    settlementsDescription: 'Puede descargar supervisar y buscar fechas y parámetros personalizados para sus Liquidaciones utilizando los filtros y herramientas de búsqueda de esta página.',
    settlementsDownload: 'Para descargar sus extractos de liquidación, pulse el botón "Descargar" situado en la parte superior derecha de la pantalla y descárguelos en formato PDF, Excel o CSV.',
    invoicesDescription: 'Realice un seguimiento de las tarifas de sus liquidaciones en la sección de Facturación, que muestra una lista de sus facturas de DNA Payments para descargar.',
    reportsDescription: 'Consulte informes y compare los estados de sus pagos detallados mediante herramientas de filtro de búsqueda y visualice gráficos según el estado de la transacción o los métodos de pago.',
    teamManagementDescription: 'Administre y configure permisos para los miembros de su equipo dentro del Portal del Comerciante para agilizar la colaboración y controlar el acceso con facilidad.',
    teamManagementAddUserDescription: 'Puede invitar fácilmente a nuevos miembros de su equipo a acceder al Portal del Comerciante, ya sea como ADMINISTRADOR o con acceso restringido.',
    paymentMethodsDescription: 'Vea y gestione todos sus métodos de pago alternativos para Pagos en línea y Enlaces de pago a través de nuestro Portal para Comerciantes, todo en un solo lugar.',
    exportDescription: 'Haga un seguimiento de los archivos CSV de datos de pago exportados de Pagos en línea y Pagos en POS, mostrando una lista de sus archivos exportados y su estado.',
    thankYou: '¡Gracias!',
    thankYouDescription: 'Gracias por elegir DNA Payments. Una vez más, no dude en ponerse en contacto con nosotros a través de nuestro chat en línea si tiene alguna duda o necesidad.',
    skipTutorial: 'Saltar tutorial'
})
