import {subscribe} from 'dna-react-ioc'
import {ContentType, get, patch, post, put, del, ApiResponse} from 'back-connector'
import {storage} from '@/services/storage'
import {ACCESS_TOKEN_KEY, VISITOR_ID_KEY, X_CLIENT_ID_KEY} from '@/constants/auth-constants'
import {AuthStoreInterface, AuthStoreSymbol} from '@/stores'
import 'reflect-metadata'

export const getWithAuth = <T>(
    url: string,
    data?: ContentType,
    headers?,
    options?
): Promise<ApiResponse<T>> => {
    const accessToken = storage.get(ACCESS_TOKEN_KEY)
    const visitorId = storage.get(VISITOR_ID_KEY)
    const _headers = headers || []

    _headers.push([X_CLIENT_ID_KEY, visitorId])
    if (accessToken) {
        _headers.push(['Authorization', `Bearer ${accessToken}`])
    }

    const {interceptor} = subscribe<AuthStoreInterface>(AuthStoreSymbol)

    return interceptor
        ? interceptor<T>(get<T>(url, data, _headers, options))
        : get<T>(url, data, _headers, options)
}

export const putWithAuth = <T>(
    url: string,
    data?: ContentType,
    headers?: any[][]
): Promise<ApiResponse<T>> => {
    const accessToken = storage.get(ACCESS_TOKEN_KEY)
    const visitorId = storage.get(VISITOR_ID_KEY)
    const totalHeaders = [[X_CLIENT_ID_KEY, visitorId]]

    if (accessToken) {
        totalHeaders.push(['Authorization', `Bearer ${accessToken}`])
    }

    if (headers?.length) {
        totalHeaders.push(...headers)
    }

    const {interceptor} = subscribe<AuthStoreInterface>(AuthStoreSymbol)

    return interceptor
        ? interceptor<T>(put<T>(url, data, totalHeaders))
        : put<T>(url, data, totalHeaders)
}

export const postWithAuth = <T>(
    url: string,
    data?: ContentType,
    headers?,
    options?
): Promise<ApiResponse<T>> => {
    const accessToken = storage.get(ACCESS_TOKEN_KEY)
    const visitorId = storage.get(VISITOR_ID_KEY)
    const _headers = headers || []

    _headers.push([X_CLIENT_ID_KEY, visitorId])
    if (accessToken) {
        _headers.push(['Authorization', `Bearer ${accessToken}`])
    }

    const {interceptor} = subscribe<AuthStoreInterface>(AuthStoreSymbol)

    return interceptor
        ? interceptor<T>(post<T>(url, data, _headers, options))
        : post<T>(url, data, _headers, options)
}

export const patchWithAuth = <T>(url: string, data?: ContentType): Promise<ApiResponse<T>> => {
    const accessToken = storage.get(ACCESS_TOKEN_KEY)
    const visitorId = storage.get(VISITOR_ID_KEY)
    const headers = [[X_CLIENT_ID_KEY, visitorId]]

    if (accessToken) {
        headers.push(['Authorization', `Bearer ${accessToken}`])
    }

    const {interceptor} = subscribe<AuthStoreInterface>(AuthStoreSymbol)

    return interceptor ? interceptor<T>(patch<T>(url, data, headers)) : patch<T>(url, data, headers)
}

export const deleteWithAuth = <T>(
    url: string,
    noInterceptor?: boolean
): Promise<ApiResponse<T>> => {
    const accessToken = storage.get(ACCESS_TOKEN_KEY)
    const visitorId = storage.get(VISITOR_ID_KEY)
    const headers = [[X_CLIENT_ID_KEY, visitorId]]

    if (accessToken) {
        headers.push(['Authorization', `Bearer ${accessToken}`])
    }

    const {interceptor} = subscribe<AuthStoreInterface>(AuthStoreSymbol)

    return !noInterceptor && interceptor
        ? interceptor<T>(del<T>(url, null, headers))
        : del<T>(url, null, headers)
}
