import {useEffect, useState} from 'react'
import {storage} from '@/services/storage'

const NOTIFICATIONS_STORAGE_KEY = 'notifications'

// Helper function to safely get notifications from storage
const getStorageNotifications = () => {
    const storedNotifications = storage.get(NOTIFICATIONS_STORAGE_KEY)
    try {
        // parse stored notifications
        const parsedData = JSON.parse(storedNotifications)
        // Check if parsedData is an object and not null, return it; otherwise, return an empty object
        return (parsedData && typeof parsedData === 'object' && !Array.isArray(parsedData))
            ? parsedData : {}
    } catch (e) {
        console.error('Error parsing notifications from storage:', e)
        return {}
    }
}

const updateStorageNotifications = (allNotifications) => {
    storage.set(NOTIFICATIONS_STORAGE_KEY, JSON.stringify(allNotifications))
}

export const useStorageNotification = (notification, userId?) => {
    const { id, notificationContentAreaId } = notification || {}
    const storageKey = userId || 'unauthorized'

    const [notifications, setNotifications] = useState(() => getStorageNotifications()[storageKey] || [])
    const [isClosed, setIsClosed] = useState(false)

    useEffect(() => {
        if (!id) {
            return
        }
        const allNotifications = getStorageNotifications()
        const currentNotifications = allNotifications[storageKey] || []

        // Check if the notification already exists
        const existingNotification = currentNotifications.find((item) => item.id === id)
        if (existingNotification) {
            setIsClosed(existingNotification.isClosed)
        } else {
            // Add the new notification if it doesn't exist
            const newNotification = { id, notificationContentAreaId, isClosed: false }
            const updatedNotifications = [...currentNotifications, newNotification]
            allNotifications[storageKey] = updatedNotifications

            setNotifications(updatedNotifications)
            updateStorageNotifications(allNotifications)
        }
    }, [id, notificationContentAreaId, storageKey])

    const onCloseNotification = (notificationId) => {
        if (!notificationId) {
            return
        }
        const updatedNotifications = notifications.map((item) =>
            item.id === notificationId ? { ...item, isClosed: true } : item
        )

        const allNotifications = getStorageNotifications()
        allNotifications[storageKey] = updatedNotifications

        setNotifications(updatedNotifications)
        updateStorageNotifications(allNotifications)
        setIsClosed(true)
    }

    return { isClosed, onCloseNotification }
}
