import translations from './translations'

translations.add('sl', {
    successTitle: 'Gratulujem',
    successMessage: 'Vaša platba bola úspešne odoslaná.',
    cancelMessage: 'Vaša platba bola zrušená.',
    failMessage: 'Vaša platba zlyhala.',
    rejectMessage: 'Vaša platba bola odmietnutá.',
    transactionId: 'ID transakcie',
    autoRedirectAfter: 'Potom budete automaticky presmerovaní na stránku',
    seconds: 'sec',
    tryAgain: 'Skúste to znova',
    returnToBack: 'Návrat na stránku'
})
