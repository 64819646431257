import translations from './translations'

translations.add('el', {
    title: 'Προσθήκη νέου domain',
    provideDomainWhereTheFile: 'Εισάγετε το domain όπου θα φιλοξενείται το αρχείο',
    inputTheTopLevelDomain: 'Εισαγάγετε το top-level domain (π.χ. dnapayments.com) ή το sub-domain\n' +
        '(π.χ. pay.dnapayments.com) για τον οποίο θέλετε να ενεργοποιήσετε το Apple Pay.',
    downloadDomainAssociationFile: 'Λήψη αρχείου συσχέτισης domain',
    hostDownloadedDomainAssociationFile: 'Φιλοξενία του ληφθέντος αρχείου συσχέτισης domain',
    youNeedToHostVerificationFileYouDownloaded: 'Θα χρειαστεί να φιλοξενήσετε το αρχείο επαλήθευσης που κατεβάσατε παραπάνω στο domain σας στην ακόλουθη τοποθεσία:',
    cancel: 'Ακύρωση',
    add: 'Προσθήκη',
    errors: {
        domainRequired: 'Απαιτείται domain'
    }
})
