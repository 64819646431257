import 'reflect-metadata'
import {inject, injectable} from 'inversify'
import {action, computed, makeObservable} from 'mobx'
import {PaymentMethodType} from '@/constants'
import {PaymentMethodMerchantStoreModel, TApplePayPaymentMethodStore} from '@/pages/payment-methods'
import {PaymentMethodsActivateTerminalSymbol} from '@/pages/payment-methods/PaymentMethods/PaymentMethods'
import {
    ActivateTerminalStore,
    filterUnavailableTerminals,
    setTerminalsSwitchChangeOnStores
} from '@/stores/payment-methods'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol
} from '@/stores/store-and-terminals'

@injectable()
export class ApplePayPaymentMethodStore implements TApplePayPaymentMethodStore {
    private _merchantStoresStore: AvailableStoresDataStore
    private _activateTerminalStore: ActivateTerminalStore

    constructor(
        @inject(AvailableStoresDataStoreSymbol) merchantStoresStore: AvailableStoresDataStore,
        @inject(PaymentMethodsActivateTerminalSymbol) activateTerminalStore: ActivateTerminalStore
    ) {
        this._merchantStoresStore = merchantStoresStore
        this._activateTerminalStore = activateTerminalStore

        makeObservable(this, {
            stores: computed,
            onTerminalSwitchChange: action.bound
        })
    }

    get stores(): PaymentMethodMerchantStoreModel[] {
        if (this._merchantStoresStore?.ecomStores === null) {
            return []
        }

        const filteredStores = filterUnavailableTerminals(
            this._merchantStoresStore.ecomStores,
            PaymentMethodType.applepay
        )

        return setTerminalsSwitchChangeOnStores(filteredStores, this.onTerminalSwitchChange)
    }

    onTerminalSwitchChange = async (terminalId: string, checked: boolean) => {
        await this._activateTerminalStore.onTerminalSwitchChange(
            terminalId,
            checked,
            PaymentMethodType.applepay
        )
    }
}
