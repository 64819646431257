import translations from './translations'

translations.add('sl', {
    buttons: {
        cancel: 'Prekliči',
        continue: 'Naprej'
    },
    description: n =>
        `Prosimo, vnesite ${n}-mestno kodo preverjanja, ki jo je ustvarila vaša aplikacija za preverjanje pristnosti`
})
