import React from 'react'
import {Divider, Grid} from 'antd'
import classNames from 'classnames'
import {Status} from '@/components/dumb/Status'
import { Title } from '@/components/dumb/Title'
import {TextWithTitleProps} from './props'
import styles from './styles.scss'
import {TRANSACTION_TITLE_AND_STATUS} from '@/constants/playwright-ids'

const {useBreakpoint} = Grid

export const TitleWithStatus = ({statusProps, subtitle, className, children}: TextWithTitleProps) => {
    const screens = useBreakpoint()
    const isMobile = screens.xs

    return (
        <>
            <div data-test-id={TRANSACTION_TITLE_AND_STATUS} className={classNames(styles.title, className, {
                [styles.mobile]: isMobile
            })}>
                <Title>{children}</Title>
                <Divider type={'vertical'}/>
                <Status {...statusProps}/>
            </div>
            <span className={styles.subtitle}>{subtitle}</span>
        </>
    )
}
