import React, {useState} from 'react'
import {observer} from 'mobx-react'
import {Col, Row, Typography} from 'antd'
import {Modal} from '@/components'
import {CookiesLinks, CookiesAccept, IndividualCookie} from './components'
import {CookiesModalProps} from './props'
import translations from './translations'
import styles from './styles.scss'

export const Cookies: React.FC<CookiesModalProps> = observer((
    {open, statisticsChecked, setStatisticsChecked, onSaveClick, onEssentialClick, onAllAcceptClick}
) => {
    const [type, setType] = useState<'all' | 'individual' | 'essential'>('all')

    return (
        <Modal
            className={styles.modal}
            open={open}
            closable={false}
            title={null}
            footer={null}
        >
            {
                type === 'all' && (
                    <Row gutter={[40, 40]} justify={'space-between'}>
                        <Col span={16} xs={24} md={16}>
                            <Typography.Title level={3}>
                                {translations().cookies}
                            </Typography.Title>
                            <Typography.Text>
                                {translations().cookiesContent}
                            </Typography.Text>
                            <br/>
                            <CookiesLinks className={styles.links}/>
                        </Col>
                        <Col span={8} xs={24} md={8}>
                            <CookiesAccept
                                onAllClick={onAllAcceptClick}
                                onEssentialClick={onEssentialClick}
                                onIndividualClick={() => {
                                    setType('individual')
                                }}
                            />
                        </Col>
                    </Row>
                )
            }
            {
                type === 'individual' && (
                    <IndividualCookie
                        statisticsChecked={statisticsChecked}
                        onSaveClick={onSaveClick}
                        setStatisticsChecked={setStatisticsChecked}
                        onBackClick={() => setType('all')}
                        onAcceptAllClick={() => {
                            setType('all')
                            onAllAcceptClick()
                        }}
                    />
                )
            }
        </Modal>
    )
})
