import React from 'react'
import translations from './translations'

translations.add('el', {
    title: 'Αντιστροφές χρεώσεων',
    description: (
        <>
            Τι είναι η αντιστροφή χρέωσης? <br /> Η αντιστροφή χρέωσης είναι το πιθανό αποτέλεσμα
            μιας αμφισβητούμενης συναλλαγής πιστωτικής ή χρεωστικής κάρτας. Εάν η τράπεζα του
            κατόχου της κάρτας αποδεχθεί την αμφισβήτηση, θα αντιστρέψει τη συναλλαγή, παίρνοντας τα
            χρήματα από τον έμπορο και επιστρέφοντάς τα στον κάτοχο της κάρτας. <br /> Όταν ένας
            κάτοχος κάρτας αμφισβητεί μια χρέωση, η εκδότρια τράπεζα θα εξετάσει τη συναλλαγή και θα
            αποφασίσει εάν η αμφισβήτηση είναι έγκυρη. Εάν πιστεύουν ότι είναι, θα παρέχουν στον
            κάτοχο της κάρτας μια προσωρινή πίστωση και θα συνεργαστούν με το δίκτυο καρτών και την
            τράπεζα απόκτησης για να ολοκληρώσουν την αντιστροφή χρέωσης.
        </>
    )
})
