import React from 'react'
import {Col, Grid, Row, Skeleton} from 'antd'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {Card} from '@/components/dumb/Card'
import {Tabs} from '@/components/dumb/Tabs'
import {getAmountWithCurrentCurrency} from '@/utils'
import {amountCountTabs} from '@/services/mocks'
import {DoughnutChart, IconTitle, SelectWithTitle} from '@/components'
import {DoughnutTabType} from '@/pages/Reports/components'
import {LoadingState} from '@/types'
import translations from './translations'
import {TransactionBarChartProps} from './props'
import styles from './styles.scss'

export const TransactionSummaryDoughnutChartCard: React.FC<TransactionBarChartProps> = observer(
    ({
        amountData,
        countData,
        tabType,
        title,
        centerTitle,
        setChartType,
        loadingState,
        selectStatusProps,
        tooltipText,
        currency
    }) => {
        const screens = Grid.useBreakpoint()
        const isMobile = screens.xs

        const data = tabType === DoughnutTabType.amount ? amountData : countData
        const subtitle =
            tabType === DoughnutTabType.amount
                ? getAmountWithCurrentCurrency(amountData?.total || 0, currency)
                : countData.total

        return (
            <Card
                title={<IconTitle title={title} tooltipText={tooltipText} />}
                extra={
                    !isMobile && (
                        <SelectWithTitle
                            displayValue={translations().status}
                            {...selectStatusProps}
                        />
                    )
                }
                className={classNames({[styles.headerMobile]: isMobile})}
            >
                {loadingState === LoadingState.LOADING && <Skeleton active={true} />}
                {loadingState !== LoadingState.LOADING && (
                    <>
                        <Row
                            className={classNames({[styles.mobileTabs]: isMobile})}
                            justify={'space-between'}
                            gutter={[16, 16]}
                        >
                            <Col md={9} xs={12}>
                                <Tabs
                                    className={classNames({[styles.tabs]: !isMobile})}
                                    tabs={amountCountTabs}
                                    onChange={type => setChartType(+type)}
                                />
                            </Col>
                            {isMobile && (
                                <Col xs={12}>
                                    <SelectWithTitle
                                        displayValue={translations().status}
                                        {...selectStatusProps}
                                    />
                                </Col>
                            )}
                        </Row>
                        <br />
                        <Row className={styles.row} gutter={[16, 16]}>
                            <Col span={24}>
                                <DoughnutChart
                                    data={data}
                                    centerTitle={centerTitle}
                                    centerSubtitle={subtitle.toString()}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Card>
        )
    }
)
