import moment from 'moment'
import uuid from 'uuid/v4'
import {makeAutoObservable} from 'mobx'
import {injectable} from 'inversify'
import {TFooterStore} from '@/components/composite/Footer/TFooterStore'
import {getCookies, setCookie} from '@/stores/footer/services/utils'

@injectable()
export class FooterStore implements TFooterStore {
    constructor() {
        makeAutoObservable(this)

        if (!!!getCookies('cookiebar')) {
            this.setOpen(true)
        } else {
            this.initiateCookieModal()
        }
    }

    allCookies = {
        consents: {
            essential: ['essential-cookie'],
            statistics: ['googletagmanager']
        },
        domainPath: 'dnapayments.com',
        expires: moment().add(180, 'days').format(),
        uid: uuid(),
        version: 1
    }

    essentialCookies = {
        consents: {
            essential: ['essential-cookie']
        },
        domainPath: 'dnapayments.com',
        expires: moment().add(180, 'days').format(),
        uid: uuid(),
        version: 1
    }

    open = false
    statisticsChecked = false

    setOpen = (open: boolean) => {
        this.open = open
    }

    setStatisticsChecked = (statisticsChecked: boolean) => {
        this.statisticsChecked = statisticsChecked
    }

    onEssentialClick = () => {
        setCookie('cookiebar', JSON.stringify(this.essentialCookies), 180)
        this.setOpen(false)
    }

    onSaveClick = () => {
        if (this.statisticsChecked) {
            setCookie('cookiebar', JSON.stringify(this.allCookies), 180)
        } else {
            setCookie('cookiebar', JSON.stringify(this.essentialCookies), 180)
        }

        this.setOpen(false)
    }

    onAllAcceptClick = () => {
        setCookie('cookiebar', JSON.stringify(this.allCookies), 180)
        this.setOpen(false)
    }

    initiateCookieModal = () => {
        const cookies = JSON.parse(getCookies('cookiebar'))
        if (cookies?.consents?.statistics) {
            this.setStatisticsChecked(true)
        }
    }
}
