import {
    AccountRequest,
    CompanyAccountsResponse,
    TransactionDetailsRequest,
    TransactionDetailsResponse
} from '@/stores'
import {getWithAuth} from '@/api'
import {DossierTokenInfoResponse} from '@/stores/business-account/account-info/models/DossierTokenInfoResponse'

export const fetchAllTransactions = (request: Partial<TransactionDetailsRequest>) =>
    getWithAuth<TransactionDetailsResponse>('/oppapi/v2/card-issuing/transactions', request)

export const fetchAccounts = (request: Partial<AccountRequest>) =>
    getWithAuth<CompanyAccountsResponse>('/oppapi/v2/card-issuing/accounts', request)

export const fetchAccountTokens = () =>
    getWithAuth<DossierTokenInfoResponse>('/bpm/v1/merchant/dossier/mapping-value', undefined)
