import React from 'react'
import {USER_GUIDE_SELECTORS} from '@/constants'
import {ROUTES} from '@/router/routes'
import {UserGuideStep} from '@/stores/user-guide/models/UserGuideStep'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {hasPermissions} from '@/stores/auth/services'
import {isMobile} from '@/utils'

import rootTranslations from '@/translations'
import translations from '../translations'

export const getSteps = (isVirtualTerminalAvailable: boolean, onSkipClick?): UserGuideStep[] => {


    const steps = [
        {
            title: rootTranslations().pages.overview,
            content: translations().overviewDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            target: !!isMobile.any() ? 'body' : `.${USER_GUIDE_SELECTORS.overview}`,
            placement: !!isMobile.any() ? 'center' : 'right-start',
            link: ROUTES.home,
            permission: [PermissionsMap.overview.read]
        },
        {
            title: rootTranslations().pages.posPayments,
            content: translations().posPaymentsDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            placement: !!isMobile.any() ? 'center' : 'right-start',
            target: !!isMobile.any() ? 'body' : `.${USER_GUIDE_SELECTORS.posPayments}`,
            link: ROUTES.posPayments,
            permission: [PermissionsMap.pos_payments.full, PermissionsMap.pos_payments.read]
        },
        {
            title: rootTranslations().pages.onlinePayments,
            content: translations().onlinePaymentsDescriptions,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            placement: !!isMobile.any() ? 'center' : 'right-start',
            target: !!isMobile.any() ? 'body' : `.${USER_GUIDE_SELECTORS.onlinePayments}`,
            link: ROUTES.onlinePayments,
            permission: [PermissionsMap.online_payments.full, PermissionsMap.online_payments.read]
        },
        {
            title: rootTranslations().pages.paymentLinks,
            content: translations().paymentLinksDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            placement: !!isMobile.any() ? 'center' : 'right-start',
            target: `.${USER_GUIDE_SELECTORS.paymentLinks}`,
            link: ROUTES.paymentLinks,
            permission: [PermissionsMap.payment_links.full, PermissionsMap.payment_links.read]
        },
        {
            title: rootTranslations().pages.paymentLinks,
            content: translations().paymentLinksCreate,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            target: `.${USER_GUIDE_SELECTORS.createNewPaymentLink}`,
            permission: [PermissionsMap.payment_links.full]
        },
        {
            title: rootTranslations().pages.virtualTerminal,
            content: translations().virtualTerminalDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            placement: !!isMobile.any() ? 'center' : 'right-start',
            target: !!isMobile.any() ? 'body' : `.${USER_GUIDE_SELECTORS.virtualTerminal}`,
            link: ROUTES.virtualTerminal,
            permission: [PermissionsMap.virtual_terminal.full]
        },
        {
            title: rootTranslations().pages.virtualTerminal,
            content: translations().virtualTerminalDetails,
            locale: {skip: <strong aria-label="skip">{translations().skipTutorial}</strong>},
            disableBeacon: true,
            target: `.${USER_GUIDE_SELECTORS.virtualTerminalDetails}`,
            permission: [PermissionsMap.virtual_terminal.full]
        },
        {
            title: rootTranslations().pages.posAmexPayments,
            content: translations().posAmexPaymentsDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            placement: !!isMobile.any() ? 'center' : 'right-start',
            target: !!isMobile.any() ? 'body' : `.${USER_GUIDE_SELECTORS.posAmexPayments}`,
            link: ROUTES.posAmexPayments,
            permission: [PermissionsMap.pos_amex_payments.full, PermissionsMap.pos_amex_payments.read]
        },
        {
            title: rootTranslations().pages.settlements,
            content: translations().settlementsDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            placement: !!isMobile.any() ? 'center' : 'right-start',
            target: !!isMobile.any() ? 'body' : `.${USER_GUIDE_SELECTORS.settlements}`,
            link: ROUTES.settlements,
            permission: [PermissionsMap.settlements.full, PermissionsMap.settlements.read]
        },
        {
            title: rootTranslations().pages.settlements,
            content: translations().settlementsDownload,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            target: `.${USER_GUIDE_SELECTORS.settlementsDownload}`,
            permission: [PermissionsMap.settlements.full]
        },
        {
            title: rootTranslations().pages.invoices,
            content: translations().invoicesDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            target: `.${USER_GUIDE_SELECTORS.invoices}`,
            link: ROUTES.billing.invoices,
            permission: [PermissionsMap.invoices.full, PermissionsMap.invoices.read]
        },
        {
            title: rootTranslations().pages.reports,
            content: translations().reportsDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            target: `.${USER_GUIDE_SELECTORS.reports}`,
            link: ROUTES.reports,
            permission: [PermissionsMap.reports.read]
        },
        {
            title: rootTranslations().pages.paymentMethods,
            content: translations().paymentMethodsDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            target: `.${USER_GUIDE_SELECTORS.paymentMethods}`,
            link: ROUTES.paymentMethods,
            permission: [PermissionsMap.payment_methods.full, PermissionsMap.payment_methods.read]
        },
        {
            title: rootTranslations().pages.teamManagement,
            content: translations().teamManagementDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            target: `.${USER_GUIDE_SELECTORS.teamManagement}`,
            link: ROUTES.teamManagement,
            permission: [PermissionsMap.teammates.full, PermissionsMap.teammates.read]
        },
        {
            title: rootTranslations().pages.teamManagement,
            content: translations().teamManagementAddUserDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            target: `.${USER_GUIDE_SELECTORS.teamManagementButton}`,
            permission: [PermissionsMap.teammates.full]
        },
        {
            title: rootTranslations().pages.exports,
            content: translations().exportDescription,
            locale: {skip: <strong aria-label="skip" onClick={onSkipClick}>{translations().skipTutorial}</strong>},
            disableBeacon: true,
            target: `.${USER_GUIDE_SELECTORS.exports}`,
            link: ROUTES.exports,
            permission: [
                PermissionsMap.pos_payments.read,
                PermissionsMap.pos_payments.full,
                PermissionsMap.pos_amex_payments.read,
                PermissionsMap.pos_amex_payments.full,
                PermissionsMap.online_payments.read,
                PermissionsMap.online_payments.full,
                PermissionsMap.settlements.full
            ]
        },
        {
            title: translations().thankYou,
            content: translations().thankYouDescription,
            disableBeacon: true,
            placement: 'center',
            target: 'body',
            permission: [PermissionsMap.overview.read]
        }
    ]

    const filteredSteps = steps.filter(step => {
        if (step.target === `.${USER_GUIDE_SELECTORS.virtualTerminalDetails}` || step.link === ROUTES.virtualTerminal) {
            return isVirtualTerminalAvailable && hasPermissions(step.permission)
        }
        return hasPermissions(step.permission)
    })
        .map((step, index) => ({...step, step: index}))

    return filteredSteps as UserGuideStep[]
}
