import translations from './translations'

translations.add('es', {
    edit: 'Editar',
    share: 'Compartir',
    areYouSureYouWantToDeactivate: '¿Está seguro de que desea desactivar el enlace de pago?',
    yes: 'Sí',
    no: 'No',
    createSimilar: 'Crear nuevo',
    deactivate: 'Desactivar'
})
