import React from 'react'
import {observer} from 'mobx-react'
import {Card} from 'antd'
import {useInjection} from 'dna-react-ioc'
import {withTitle} from '@/router/utils'
import {useScreenType} from '@/hooks/useScreenType'
import {PageHeader} from '@/components'
import {MobileTableControls} from '@/components/composite/table'
import {TChargebacksStore} from '.'
import {ChargebacksDetailsDrawer, ChargebacksFilter, ChargebacksTable} from './components'

import translations from './translations'
import styles from './styles.scss'

export const ChargebacksStoreSymbol = Symbol('ChargebacksStoreSymbol')
export const ChargebacksDetailsDrawerContainerStoreSymbol = Symbol(
    'ChargebacksDetailsDrawerContainerStore'
)

const Chargebacks = observer(() => {
    const {isMobile} = useScreenType()
    const {setMobileTableViewType, mobileTableViewType} =
        useInjection<TChargebacksStore>(ChargebacksStoreSymbol)

    return (
        <>
            <PageHeader onBack={null} title={translations().title} border={'none'}>
                <Card className={styles.description}>{translations().description}</Card>
                <ChargebacksFilter />

                {isMobile && (
                    <MobileTableControls
                        viewType={mobileTableViewType}
                        setViewType={setMobileTableViewType}
                    />
                )}
            </PageHeader>

            <ChargebacksDetailsDrawer />
            <ChargebacksTable />
        </>
    )
})

export default withTitle(Chargebacks)
