import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import {AccessType, EditTeammateModalContainerStoreSymbol} from '@/pages/TeamManagement/components'
import {TInviteEditTeammateStore} from '@/pages/TeamManagement/components/InviteEditTeammate/TInviteEditTeammateStore'
import {LoadingState} from '@/types'
import {ModalContainerStore} from '@/stores/modal/__mocks__/ModalContainerStore'
import {error} from 'dna-common'
import {InviteEditTeammateFormModel} from '@/pages/TeamManagement/components/InviteEditTeammate/models'
import {editTeammate} from '@/stores/team-management/services'
import {openErrorNotification} from '@/utils'
import {generatePermissionsArray} from '@/stores/team-management/services/generators'
import {AuthStoreInterface, AuthStoreSymbol} from '@/stores'
import {AllStoresDataStore, AllStoresDataStoreSymbol} from '@/stores/store-and-terminals'
import {EditTeammateRequest} from '@/stores/team-management/models'
import {TeamManagementStore} from '@/stores/team-management/TeamManagementStore'
import {SelectItem} from '@/components/dumb/Select'

@injectable()
export class EditTeammateStore implements TInviteEditTeammateStore {
    constructor(
        @inject(AuthStoreSymbol) authStore: AuthStoreInterface,
        @inject(EditTeammateModalContainerStoreSymbol) modalStore: ModalContainerStore,
        @inject(AllStoresDataStoreSymbol) storesDataStore: AllStoresDataStore
    ) {
        this._authStore = authStore
        this._modalStore = modalStore
        this._storesDataStore = storesDataStore
        this._teamManagementStore

        makeObservable(this, {
            loadingState: observable,
            teammate: observable,

            selectStores: computed,

            cancel: action,
            openEditForm: action,
            inviteEditTeammate: action,
            setStores: action
        })
    }

    /**
     * stores
     */
    _authStore: AuthStoreInterface
    _modalStore: ModalContainerStore
    _teamManagementStore: TeamManagementStore
    _storesDataStore: AllStoresDataStore

    /**
     * fields
     */
    loadingState: LoadingState = LoadingState.IDLE
    teammate: InviteEditTeammateFormModel = null
    stores: string[] = []

    get selectStores(): SelectItem[] {
        return this._storesDataStore.storesAsSelectItems
    }

    get isAdmin(): boolean {
        return this._authStore.isAdmin
    }

    cancel = (): void => {
        this._modalStore.setOpen(false)
    }

    inviteEditTeammate = (values: InviteEditTeammateFormModel): Promise<any> => {
        if (this.loadingState === LoadingState.LOADING) {
            return Promise.resolve()
        }

        runInAction(() => {
            this.loadingState = LoadingState.LOADING
        })

        const shouldSendAllStores = values.shopIds.length === this.selectStores.length

        const request: EditTeammateRequest = {
            id: this.teammate?.id,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            ...(shouldSendAllStores ? {} : {shopIds: values.shopIds})
        }
        if (values.inviteAs === AccessType.admin) {
            request.roles = [AccessType.admin]
        } else {
            if (values.role === 'finance') {
                request.roles = ['finance']
            } else {
                request.roles = ['custom']
                request.permissions = generatePermissionsArray(values.permissions)
            }
        }

        return runInAction(async () => {
            let response
            try {
                response = await editTeammate(request)
            } catch (e) {
                this.loadingState = LoadingState.FAILED
                error(e.message)
                return Promise.reject()
            }

            const {result, error: err, status} = response

            if (err) {
                this.loadingState = LoadingState.FAILED
                openErrorNotification(err.message)
                return Promise.reject()
            } else if (!result) {
                this.loadingState = LoadingState.FAILED
                return Promise.reject()
            } else if (result && status > 200 && status < 300) {
                this.loadingState = LoadingState.FAILED
                openErrorNotification(result.message)
                return Promise.reject()
            }

            this.loadingState = LoadingState.DONE
            this._modalStore.setOpen(false)
            this._teamManagementStore?.loadTeammates()
            this.teammate = null
            return Promise.resolve()
        })
    }

    openEditForm = (teammate) => {
        this.teammate = teammate
        this._modalStore.setOpen(true)
    }

    setStores = (stores: string[]) => {
        this.stores = stores
    }
}
