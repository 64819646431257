import {injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import {subscribe} from 'dna-react-ioc'

import {EpaySummary} from '@/stores'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol,
    EcomStoreSelectStore
} from '@/stores/store-and-terminals'
import {ECOMTransactionStatuses, getEpaySummary} from '@/stores/reports/services'
import {getDefaultSummaryByStatusItem} from '@/pages/Reports'
import {
    TRangePickerContainerStore,
    TSelectContainerStore,
    TTransactionSummaryContainerStore
} from '@/components/containers'
import {Currencies, OnlinePaymentStatus} from '@/constants'
import {LoadingState} from '@/types'
import {openErrorNotification, deepCopy} from '@/utils'

@injectable()
export class ECOMTransactionSummaryStore implements TTransactionSummaryContainerStore {
    private readonly _rangePickerStore: TRangePickerContainerStore
    private readonly _currencySelectStore: TSelectContainerStore
    private readonly _merchantStoresStore: AvailableStoresDataStore
    private _storeSelectStore: EcomStoreSelectStore

    constructor(
        protected _name: string,
        rangePickerStore: TRangePickerContainerStore,
        currencySelectStore: TSelectContainerStore,
        storeSelectStore?: EcomStoreSelectStore
    ) {
        this._rangePickerStore = rangePickerStore
        this._currencySelectStore = currencySelectStore
        this._storeSelectStore = storeSelectStore
        this._merchantStoresStore = subscribe<AvailableStoresDataStore>(
            AvailableStoresDataStoreSymbol
        )

        makeObservable(this, {
            loadingState: observable,
            prevSummary: observable,
            currSummary: observable,

            summaryByStatus: computed,
            currSummaryRequest: computed,
            prevDates: computed,
            currency: computed,
            loadSummary: action.bound
        })

        reaction(
            () => this.currSummaryRequest,
            (request) => {
                if (request.currency) {
                    this.loadSummary()
                }
            }
        )

        reaction(
            () => this._merchantStoresStore.ecomMostUsedDefaultCurrency,
            (defaultCurrency) => {
                if (defaultCurrency || !currencySelectStore.value) {
                    currencySelectStore.setValue(defaultCurrency)
                }
            }
        )
    }

    loadingState = LoadingState.IDLE
    prevSummary: EpaySummary = {currency: null, data: []}
    currSummary: EpaySummary = {currency: null, data: []}

    get summaryByStatus() {
        const findItemByStatus = (data, status) => data?.find((el) => el.status === status)

        const calculatePercent = (currAmount, prevAmount) => {
            if (prevAmount > 0 && currAmount > 0) {
                return ((currAmount - prevAmount) / prevAmount) * 100
            }
            return 0
        }

        return ECOMTransactionStatuses.map((status) => {
            const currItem = deepCopy(findItemByStatus(this.currSummary.data, status))
            const prevItem = deepCopy(findItemByStatus(this.prevSummary.data, status))

            const currentCredited = findItemByStatus(
                this.currSummary.data,
                OnlinePaymentStatus.credited
            )
            const prevCredited = findItemByStatus(
                this.prevSummary.data,
                OnlinePaymentStatus.credited
            )

            if (!currItem) return getDefaultSummaryByStatusItem(status)

            if (status === OnlinePaymentStatus.refund) {
                if (currItem) {
                    currItem.amount += currentCredited?.amount || 0
                    currItem.count += currentCredited?.count || 0
                }

                if (prevItem) {
                    prevItem.amount += prevCredited?.amount || 0
                    prevItem.count += prevCredited?.count || 0
                }
            }

            const percent = calculatePercent(currItem.amount || 0, prevItem?.amount || 0)

            return {
                ...currItem,
                percent
            }
        })
    }

    get currSummaryRequest() {
        const storeId = this._storeSelectStore.value
        return {
            from: this._rangePickerStore.startDate.format(),
            to: this._rangePickerStore.endDate.format(),
            currency: this._currencySelectStore.value,
            ...(storeId ? {storeId} : {})
        }
    }

    get prevDates() {
        return {
            from: this._rangePickerStore.prevDates.startDate,
            to: this._rangePickerStore.prevDates.endDate
        }
    }

    get currency() {
        return this._currencySelectStore.value as Currencies
    }

    public async loadSummary() {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.loadingState = LoadingState.LOADING
        })

        const prevSummaryRequest = {
            ...this.currSummaryRequest,
            from: this.prevDates.from.format(),
            to: this.prevDates.to.format()
        }

        await Promise.all([
            getEpaySummary(prevSummaryRequest),
            getEpaySummary(this.currSummaryRequest)
        ])
            .then((res) => {
                const [prevData, currData] = res

                runInAction(() => {
                    this.loadingState = LoadingState.DONE

                    this.prevSummary = prevData.result
                    this.currSummary = currData.result
                })
            })
            .catch((err) => {
                this.loadingState = LoadingState.FAILED
                return openErrorNotification(err.message)
            })
    }
}
