import {register} from 'dna-react-ioc'
import {
    // stores
    Way4StoresDataStore,
    AllStoresDataStore,
    AvailableStoresDataStore,
    // symbols
    Way4StoresDataStoreSymbol,
    AllStoresDataStoreSymbol,
    AvailableStoresDataStoreSymbol
} from '@/stores/store-and-terminals'

export const storeAndTerminalsState = () => {
    register<AllStoresDataStore>(AllStoresDataStoreSymbol).to(AllStoresDataStore).inSingletonScope()

    register<AvailableStoresDataStore>(AvailableStoresDataStoreSymbol)
        .to(AvailableStoresDataStore)
        .inSingletonScope()

    register<Way4StoresDataStore>(Way4StoresDataStoreSymbol)
        .to(Way4StoresDataStore)
        .inSingletonScope()
}
