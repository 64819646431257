import translations from './translations'

translations.add('sl', {
    title: 'Zaupanja vredne naprave',
    delete: 'Izbriši',
    columns: {
        device: 'Naprava',
        addedOn: 'Dodano',
        lastLogin: 'Zadnja prijava'
    },
    messages: {
        confirm: 'Ste prepričani, da želite izbrisati svojo zaupanja vredno napravo?'
    },
    yes: 'Da',
    no: 'Ne',
    tablet: 'Tablica',
    mobile: 'Mobilna naprava',
    desktop: 'Namizje',
    bot: 'Bot'
})
