import React from 'react'
import { observer } from 'mobx-react-lite'
import {Outlet} from 'react-router-dom'

import {MainLayout} from '@/layouts'
import {Theme} from '@/constants'
import {Footer} from '@/components'

type AuthAreaProps = { theme?: Theme }

export const AuthArea = observer((props: AuthAreaProps) => {
    return (
        <>
            <MainLayout theme={props.theme}><Outlet /></MainLayout>
            <Footer/>
        </>
    )
})
