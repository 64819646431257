import translations from './translations'

translations.add('sl', {
    title: 'Nastavenia terminálu',
    configurationOptions: 'Možnosti konfigurácie',
    shippingPreference: 'Preferencia prepravy',
    useCustomerProvidedShippingAddress:
        'Použite dodaciu adresu poskytnutú zákazníkom na PayPal stránke.',
    redactShippingAddressFromPaypalSite:
        'Upravte dodaciu adresu z PayPal stránky. Odporúča sa pre digitálny tovar.',
    useMerchantProvidedAddress:
        'Použite adresu poskytnutú obchodníkom. Zákazník nemôže zmeniť túto adresu na PayPal stránke.',
    defaultTransactionType: 'Predvolený typ transakcie',
    authorization: 'Povolenie',
    capture: 'Zachytiť',
    cancel: 'Zruš',
    saveChanges: 'Uložiť zmeny'
})
