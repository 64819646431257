import 'reflect-metadata'
import {inject, injectable} from 'inversify'
import {makeAutoObservable, runInAction} from 'mobx'

import {TConfirmEmailStore} from '@/pages/ConfirmEmail'
import {openErrorNotification} from '@/utils'
import {ResetPasswordStoreSymbol} from '@/pages/ResetPassword/ResetPassword'
import {TResetPasswordStore} from '@/pages/ResetPassword'
import {confirmEmail} from '@/stores/confirm-email/fetchers'
import {ConfirmEmailRequest} from '@/stores'
import {LoadingState} from '@/types'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import rootTranslations from '@/translations/translations'

@injectable()
export class ConfirmEmailStore implements TConfirmEmailStore {
    private _resetPasswordStore: TResetPasswordStore

    constructor(@inject(ResetPasswordStoreSymbol) resetPasswordStore: TResetPasswordStore) {
        makeAutoObservable(this)

        this._resetPasswordStore = resetPasswordStore
    }

    public verificationId = ''
    public confirmEmailLoadingState = LoadingState.IDLE

    get email() {
        return this._resetPasswordStore.email
    }

    confirmEmail = async (data: {key: string}) => {
        const request: ConfirmEmailRequest = {
            email: this.email,
            key: data.key
        }

        runInAction(() => {
            this.confirmEmailLoadingState = LoadingState.LOADING
        })

        try {
            const {error, result} = await confirmEmail(request)

            if (error) {
                runInAction(() => {
                    this.confirmEmailLoadingState = LoadingState.FAILED
                })
                openErrorNotification(error.message)
            } else {
                runInAction(() => {
                    this.confirmEmailLoadingState = LoadingState.DONE
                    this.verificationId = result?.Id
                })
                getRouterStore().push(ROUTES.setPassword)
            }
        } catch (error) {
            runInAction(() => {
                this.confirmEmailLoadingState = LoadingState.FAILED
            })
            openErrorNotification(rootTranslations().errors.general)
        }
    }

    onResendClick = async () => {
        this._resetPasswordStore.sendInstructions({email: this.email})
    }
}
