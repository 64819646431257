import {TranslationBuilder} from '@/components/dumb/Translation'
import {CaptureMethod, TransactionType} from '@/types/transactions'
import {
    FileType,
    OnlinePaymentStatus,
    ChargebackStatus,
    ResonCodeCategory,
    PayByLinkStatus,
    PaymentMethodType,
    POSPaymentStateStatus,
    POSPaymentStatus,
    PaymentCardScheme,
    TerminalType,
    PaymentCardType
} from '../constants'

export default TranslationBuilder.create<{
    siteTitle: string
    pages: {
        home: string
        activation: string
        overview: string
        paymentLinks: string
        posPayments: string
        posAmexPayments: string
        onlinePayments: string
        chargebacks: string
        settlements: string
        billing: string
        invoices: string
        virtualTerminal: string
        reports: string
        paymentMethods: string
        payPal: string
        payByBank: string
        openBanking: string
        settings: string
        developers: string
        checkoutCustomization: string
        tariffs: string
        profile: string
        login: string
        resetPassword: string
        twoFA: string
        twoFAEnforced: string
        recoveryCode: string
        chooseCompany: string
        confirmEmail: string
        confirmTwoFA: string
        setPassword: string
        cardsManagement: string
        makeTransfer: string
        teamManagement: string
        noPermissions: string
        accountInfo: string
        help: string
        ecospendReturn: string
        noPage: string
        exports: string
        onlineChat: string
    }
    constants: {
        onlinePaymentStatus: Record<OnlinePaymentStatus, string>
        chargebackStatus: Record<ChargebackStatus, string>
        reasonCodeCategory: Record<ResonCodeCategory, string>
        posPaymentStatus: {
            [key in keyof typeof POSPaymentStatus]: string
        }
        posPaymentStateStatus: Record<POSPaymentStateStatus, string>
        payByLinkStatus: {
            [key in keyof typeof PayByLinkStatus]: string
        }
        paymentMethodType: {
            [key in keyof typeof PaymentMethodType]: string
        }
        fileType: {
            [key in keyof typeof FileType]: string
        }
        issuerRegion: {
            Domestic: string
            Intra: string
            International: string
        }
        cardType: Record<PaymentCardType, string>
        cardScheme: {
            [key in keyof typeof PaymentCardScheme]: string
        }
        captureMethods: Record<keyof typeof CaptureMethod, string>
        terminalTypes: Record<TerminalType, string>
    }
    filter: {
        all: string
        allAll: string
    }
    success: {
        paymentChargedSuccessfully: string
        paymentRefundedSuccessfully: string
        paymentRefundedFullySuccessfully: string
        newPaymentProcessedSuccessfully: string
        sendReceiptSuccess: string
        downloadReceiptSuccess: string
    }
    errors: {
        refundProcessing: string
        unknown: string
        errorOccured: string
        general: string
        maxAttempt: (count: number) => string
        INVALID_CARD_NUMBER: string
        INVALID_CARDHOLDER_NAME: string
        INVALID_SECURE_CODE: string
        INVALID_EXPIRY_DATE: string
    }
    transactionTypes: Partial<Record<keyof typeof TransactionType, string>>
    transactionTypesTooltip: Partial<Record<keyof typeof TransactionType, string>>
    languages: {
        english: string
        spanish: string
        greek: string
        romanian: string
        slovak: string
    }
    action: {
        title: string
        viewDetails: string
    }
    fields: {
        accountNumber: string
        cardMask: string
        cardScheme: string
        captureMethod: string
        currency: string
        mid: string
        status: string
        store: string
        terminalId: string
        terminalType: string
        transactionId: string
    }
    messages: {
        successExportMessage: React.ReactNode
        validation: {
            minLength(n: number): string
        }
    }
}>()
