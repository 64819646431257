import translations from './translations'

translations.add('sl', {
    amount: 'Množstvo',
    currency: 'Mena',
    errors: {
        amountRequired: 'Vyžaduje sa množstvo',
        amountInvalid: 'Množstvo musí byť vyššie ako 0'
    }
})
