import {injectable} from 'inversify'
import {action, computed, makeObservable, observable} from 'mobx'

import { TTabsContainerStore } from '@/components'
import {SelectItem} from '@/components/dumb/Select'
import {TSelectContainerStore} from '@/components/containers/SelectContainer/TSelectContainerStore'

@injectable()
export class StatusSelectContainerStore implements TSelectContainerStore {
    constructor(
        private _statusStore: TTabsContainerStore,
        public options: SelectItem[]
    ) {
        makeObservable(this, {
            value: computed,
            options: observable,
            setOptions: action.bound,
            setValue: action.bound
        })
    }

    get value() {
        return this._statusStore?.value
    }

    clear() {
        this._statusStore?.clear()
    }

    setValue(value: string) {
        this._statusStore.setValue(value)
    }

    setOptions(options: SelectItem[]): void {
        this.options = options
    }
}
