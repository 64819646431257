import translations from './translations'

translations.add('el', {
    shop: 'Κατάστημα',
    shopName: 'Όνομα καταστήματος',
    orderNumber: 'Αριθμός παραγγελίας',
    description: 'Περιγραφή',
    customerName: 'Ονομα πελάτη',
    postLink: 'Δημοσίευση συνδέσμου',
    customerId: 'Αναγνωριστικό λογαριασμού πελάτη',
    email: 'Ηλεκτρονική διεύθυνση',
    rrn: 'RRN',
    receivalReferenceNumber: 'Αριθμός αναφοράς παραλαβής',
    amount: {
        label: 'Ποσό',
        from: 'από',
        to: 'προς'
    },
    cardScheme: 'Σύστημα κάρτας',
    cardMask: {
        label: 'Κάρτα',
        first: 'Πρώτα 6 ψηφία',
        last: 'Τελευταία 4'
    },
    donationAmount: {
        label: 'Ποσό δωρεάς',
        from: 'από',
        to: 'προς'
    },
    paymentMethod: 'Μέθοδος πληρωμής',
    currency: 'Νόμισμα'
})
