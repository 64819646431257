import {PaginationStore} from '@/stores'
import {register} from 'dna-react-ioc'
import {PaginationContainerStoreType} from '@/components'
import {ExportsPaginationContainerSymbol, ExportsStoreSymbol} from '@/pages/Exports/Exports'
import {ExportsStore} from '@/stores/exports/ExportsStore'

export const exportsState = () => {
    register<PaginationContainerStoreType>(ExportsPaginationContainerSymbol)
        .to(PaginationStore)
        .inSingletonScope()
    register<ExportsStore>(ExportsStoreSymbol).to(ExportsStore).inSingletonScope()
}
