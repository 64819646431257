import React from 'react'
import translations from './translations'

translations.add('el', {
    title: (
        <>
            Ο κωδικός ανάκτησης δημιουργείται για τον λογαριασμό σας. <br /> Αυτός ο κωδικός με 12
            χαρακτήρες μπορεί να χρησιμοποιηθεί μία φορά για να ανακτήσετε τον λογαριασμό σας, εάν
            δεν μπορείτε να συνδεθείτε με διπλή πιστοποίηση ταυτότητας (2FA).
        </>
    ),
    copy: 'Αντιγραφή',
    copyText: 'Αντιγράψτε τον κωδικό για να προχωρήσετε.',
    copied: 'Αντιγράφηκε',
    warning: {
        line1: 'Ο κωδικός μπορεί να χρησιμοποιηθεί μόνο μία φορά',
        line2: 'Για λόγους ασφαλείας, αυτός ο κωδικός δεν θα εμφανιστεί ξανά',
        line3: 'Μην μοιράζεστε αυτόν τον κωδικό με κανέναν'
    }
})
