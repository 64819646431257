import {injectable} from 'inversify'
import {action, makeObservable, observable} from 'mobx'
import {TTabsContainerStore} from '@/components/containers/TabsContainer'
import {Tab} from '@/components/dumb/Tabs/props'
import 'reflect-metadata'

@injectable()
export class TabsContainerStore implements TTabsContainerStore {
    constructor(
        public tabs: Tab[] = []
    ) {
        makeObservable(this, {
            value: observable,
            tabs: observable,
            setValue: action.bound,
            setTabs: action.bound
        })

        if (this.tabs?.length > 0) {
            this.value = String(this.tabs[0].key)
        }
    }

    value: string = null

    clear() {
        this.value = null
    }

    setValue(value: string): void {
        this.value = value
    }

    setTabs(tabs: Tab[]): void {
        this.tabs = tabs
    }
}
