import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    buttons: {
        home: string
    }
    errors: {
        ourSupportTeamIsHereToHelp: JSX.Element
    }
}>()

