import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    amount: string
    currency: string
    errors: {
        amountRequired: string
        amountInvalid: string
    }
}>()
