import translations from './translations'

translations.add('ro', {
    title: 'Adăugare Autentificare cu Două Factori',
    updateTitle: 'Actualizare Autentificare cu Două Factori',
    recoveryCodes: 'Coduri de Recuperare',
    selectFrequency: 'Selectați frecvența',
    configureApp: 'Configurați aplicația de autentificare',
    cancel: 'Anulare',
    back: 'Înapoi',
    next: 'Următorul',
    update: 'Actualizare',
    submit: 'Trimite'
})
