import React from 'react'
import {Button, ButtonProps} from 'antd'
import {Icon} from '@/components'
import translations from './translations'
import {EXPORT_BUTTON} from '@/constants/playwright-ids'

export const ExportButton: React.FC<ButtonProps> = ({ children, ...rest }) => (
    <Button
        data-test-id={EXPORT_BUTTON}
        icon={
            <Icon
                onClick={(e) =>
                    e.preventDefault()
                }
                type={
                    'download_document'
                }
            />
        }
        type={'link'}
        {...rest}
    >
        {children || translations().export}
    </Button>
)
