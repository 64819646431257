import {register} from 'dna-react-ioc'
import {
    OnlinePaymentsDrawerContainerStoreSymbol,
    OnlinePaymentsModalConfirmContainerStoreSymbol,
    OnlinePaymentsModalFilterContainerStoreSymbol,
    OnlinePaymentsPaginationStoreSymbol,
    OnlinePaymentsPaymentModalStoreSymbol,
    OnlinePaymentsPaymentOperationStoreSymbol,
    OnlinePaymentsSearchSelectStoreSymbol,
    OnlinePaymentsStoreSymbol,
    OnlinePaymentsTableFilterRangePickerContainerStoreSymbol,
    OnlinePaymentsTableFilterStatusSelectContainerStoreSymbol,
    OnlinePaymentsTabsStatusContainerStoreSymbol,
    TOnlinePaymentsStore,
    TPaymentOperationStore
} from '@/pages/OnlinePayments'
import {OnlinePaymentsStore} from '@/stores/transactions/OnlinePaymentsStore'
import {
    DrawerStore,
    ModalStore,
    PaginationStore,
    PaymentOperationStore,
    RangePickerStore,
    SearchSelectStore
} from '@/stores'
import {
    TModalContainerStore,
    PaginationContainerStoreType,
    TRangePickerContainerStore,
    TSearchSelectContainerStore,
    TSelectContainerStore,
    TTabsContainerStore
} from '@/components'
import {TDrawerContainerStore} from '@/components/containers/DrawerContainer/TDrawerContainerStore'
import {
    searchOptions,
    searchValidation
} from '@/stores/transactions/OnlinePaymentsStore/constants/searchOptions'
import {OnlinePaymentsFilterButtonStoreSymbol} from '@/pages/OnlinePayments/components/OnlinePaymentsFilterButton/OnlinePaymentsFilterButton'
import {TOnlinePaymentsFilterButtonStore} from '@/pages/OnlinePayments/components/OnlinePaymentsFilterButton/TOnlinePaymentsFilterButtonStore'
import {OnlinePaymentsFilterButtonStore} from '@/stores/transactions/OnlinePaymentsStore/OnlinePaymentsFilterButtonStore'
import {OnlinePaymentsTabsStore} from '@/stores/transactions/OnlinePaymentsStore/OnlinePaymentsTabsStore'
import {OnlinePaymentsStatusSelectStore} from '@/stores/transactions/OnlinePaymentsStore/OnlinePaymentsStatusSelectStore'
import {
    ProcessNewPaymentStoreSymbol,
    TProcessNewPaymentModalStore
} from '@/pages/OnlinePayments/components/ProcessNewPaymentModal'
import {ProcessNewPaymentStore} from '@/stores/transactions/ProcessNewPaymentStore'
import {OnlinePaymentsExportsModalInterface} from '@/stores/transactions/OnlinePaymentsExportsStore/OnlinePaymentsExportsModalInterface'
import {OnlinePaymentsExportsModalStore} from '@/stores/transactions/OnlinePaymentsExportsStore'
import {OnlinePaymentsExportsModalStoreSymbol} from '@/pages/OnlinePayments/components/OnlinePaymentsExportsModal'

export const onlinePaymentsState = () => {
    register<TOnlinePaymentsStore>(OnlinePaymentsStoreSymbol)
        .to(OnlinePaymentsStore)
        .inSingletonScope()
    register<TTabsContainerStore>(OnlinePaymentsTabsStatusContainerStoreSymbol)
        .to(OnlinePaymentsTabsStore)
        .inSingletonScope()

    register<TModalContainerStore>(OnlinePaymentsModalFilterContainerStoreSymbol)
        .to(ModalStore)
        .inSingletonScope()
    register<TModalContainerStore>(OnlinePaymentsModalConfirmContainerStoreSymbol)
        .to(ModalStore)
        .inSingletonScope()
    register<TModalContainerStore>(OnlinePaymentsPaymentModalStoreSymbol)
        .to(ModalStore)
        .inSingletonScope()

    register<TDrawerContainerStore>(OnlinePaymentsDrawerContainerStoreSymbol)
        .to(DrawerStore)
        .inSingletonScope()
    register<TPaymentOperationStore>(OnlinePaymentsPaymentOperationStoreSymbol)
        .to(PaymentOperationStore)
        .inSingletonScope()

    // filter stores
    register<TRangePickerContainerStore>(OnlinePaymentsTableFilterRangePickerContainerStoreSymbol)
        .to(RangePickerStore)
        .inSingletonScope()
    register<TSelectContainerStore>(OnlinePaymentsTableFilterStatusSelectContainerStoreSymbol)
        .to(OnlinePaymentsStatusSelectStore)
        .inSingletonScope()
    register<TOnlinePaymentsFilterButtonStore>(OnlinePaymentsFilterButtonStoreSymbol)
        .to(OnlinePaymentsFilterButtonStore)
        .inSingletonScope()
    register<PaginationContainerStoreType>(OnlinePaymentsPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()
    register<TSearchSelectContainerStore>(OnlinePaymentsSearchSelectStoreSymbol)
        .toDynamicValue(() => new SearchSelectStore(searchOptions, searchValidation))
        .inSingletonScope()
    register<TProcessNewPaymentModalStore>(ProcessNewPaymentStoreSymbol)
        .to(ProcessNewPaymentStore)
        .inSingletonScope()
    register<OnlinePaymentsExportsModalInterface>(OnlinePaymentsExportsModalStoreSymbol)
        .to(OnlinePaymentsExportsModalStore)
        .inSingletonScope()
}
