import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    shop: string
    shopName: string
    orderNumber: string
    description: string
    customerName: string
    postLink: string
    customerId: string
    email: string
    rrn: string
    receivalReferenceNumber: string
    amount: {
        label: string
        from: string
        to: string
    }
    cardMask: {
        label: string
        first: string
        last: string
    }
    cardScheme: string
    donationAmount: {
        label: string
        from: string
        to: string
    }
    paymentMethod: string
    currency: string
}>()

