import React from 'react'
import translations from './translations'
import {LinksProps} from './props'
import styles from './styles.scss'
import {observer} from 'mobx-react'

export const Links: React.FC<LinksProps> = observer((
    {onCookiesClick}
) => {
    return <div className={styles.links}>
        <a href={'#'} onClick={onCookiesClick}>{translations().cookies}</a>
        <a href={'https://www.dnapayments.com/page/privacy-policy'} target={'_blank'} rel="noreferrer">{translations().privacyPolicy}</a>
        <a href={'https://www.dnapayments.com/page/terms-use'} target={'_blank'} rel="noreferrer">{translations().termsAndConditions}</a>
        <a href={'https://www.dnapayments.com/storage/app/media/dna-payments-limited-pci-dss-charter-v-20-05112019-1.pdf'} target={'_blank'} rel="noreferrer">{translations().pciCharter}</a>
    </div>
})
