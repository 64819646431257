import translations from './translations'

translations.add('es', {
    paymentDetails: 'Detalles del pago',
    date: 'Fecha',
    transactionDate: 'Fecha de transacción (GMT)',
    amount: 'Importe',
    status: 'Estado',
    terminalId: 'Terminal ID',
    card: 'Tarjeta',
    details: 'Detalles',
    resultCode: 'Código de resultado',
    message: 'Mensaje',
    cardType: 'Tipo de tarjeta',
    cardCategory: 'Categoría de tarjeta',
    corporate: 'Corporativo',
    personal: 'Personal',
    europeanCard: 'Tarjeta Europea',
    captureMethod: 'Método de captura',
    paymentMethod: 'Método de pago',
    transaction: {
        id: 'ID de transacción',
        type: 'Tipo de transacción',
        details: 'Detalles de la transacción',
        country: 'País de transacción',
        city: 'Ciudad de transacción'
    },
    currency: 'Divisa',
    isCorporateCard: 'Categoría de tarjeta',
    returnCode: 'Código de resultado',
    returnCodeDescription: 'Mensaje',
    mid: 'MID',
    issuerRegion: 'Región de emisión',
    cardScheme: 'Esquema de tarjeta',
    cardMask: 'Card Mask'
})
