import rootTranslations from '@/translations'

export enum OnlinePaymentCardScheme {
    Visa = 'VISA',
    Mastercard = 'MasterCard',
    AmericanExpress = 'AmericanExpress',
    UnionPay = 'UnionPay',
    DinersClub = 'DinersClub'
}

export const getOnlineCardSchemeLabel = (cardScheme: OnlinePaymentCardScheme) => {
    return rootTranslations().constants.cardScheme[cardScheme] || cardScheme
}

export const portalOnlinePaymentsCardSchemes = Object.keys(OnlinePaymentCardScheme).map((key) => ({
    label: rootTranslations().constants.cardScheme[key],
    value: OnlinePaymentCardScheme[key]
}))
