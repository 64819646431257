import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    cookies: string
    cookiesContent: string
    acceptAll: string
    save: string
    back: string
    essential: string
    essentialContent: string
    statistics: string
    statisticsContent: string
    cookieInformation: string
}>()
