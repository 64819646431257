import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    buttons: {
        send: string
        receipt: string
        downloadReceipt: string
    }
    labels: {
        email: string
        emailPlaceholder: string
        date: string
        orderNumber: string
        description: string
        paymentMethod: string
    }
    errors: {
        emailInvalid: string
        emailRequired: string
    }
}>()
