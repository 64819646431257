import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {RangePicker} from '@/components'
import {TRangePickerContainerStore} from '@/components/containers'
import {RangePickerContainerProps} from './props'

export const RangePickerStoreSymbol = Symbol('RangePickerStore')

export const RangePickerContainer: React.FC<RangePickerContainerProps> = observer(
    ({
        isLoading,
        store: _store,
        injectableIdentifier = RangePickerStoreSymbol,
        className,
        disabledDate,
        onCalendarChange,
        showRangePickerTime
    }) => {
        const store = _store || useInjection<TRangePickerContainerStore>(injectableIdentifier)

        return (
            <RangePicker
                className={className}
                isLoading={isLoading || store.isLoading}
                ranges={store.rangePresets}
                withLeftMenu={store.withLeftMenu}
                startDate={store.startDate}
                endDate={store.endDate}
                disabled={store.disabled}
                onChange={store.setDates}
                onCalendarChange={onCalendarChange}
                disabledDate={disabledDate}
                setPeriod={store.setPeriod}
                periodTitle={store.periodTitle}
                showRangePickerTime={showRangePickerTime}
            />
        )
    }
)
