import translations from './translations'
import React from 'react'

translations.add('el', {
    title: 'Apple Pay',
    description: <>
        Το Apple Pay επιτρέπει στους καταναλωτές να πληρώνουν για τα αγαθά και τις υπηρεσίες τους εύκολα και γρήγορα στο διαδίκτυο. Οι καταναλωτές αποθηκεύουν τα στοιχεία της κάρτας πληρωμής τους στο Apple Wallet τους, τα οποία μπορούν να κληθούν από τις Apple συσκευές τους ή μέσα από το πρόγραμμα περιήγησης ιστού Safari.
        <br/><br/>
        Η DNA Payments με χαρά προσφέρει αυτόν τον τρόπο πληρωμής στους εμπόρους και μπορεί να ενεργοποιηθεί ή να απενεργοποιηθεί εδώ.
        <br/><br/>
    </>
})
