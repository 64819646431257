import translations from './translations'

translations.add('sl', {
    title: 'Blokácia karty',
    ohSeemsLikeSomethingBadHappened: '-  Ach, zdá sa vám, že sa ti stalo niečo zlé s vašou kartou?',
    pleaseClarifyTheProblem: 'Prosím, objasnite problém',
    myCardWasStolen: 'Moja karta bola ukradnutá',
    iveLostMyCard: 'Stratil som kartu',
    cardIsDamaged: 'Karta je poškodená',
    cancel: 'Zruš',
    blockCard: 'Blokovať kartu'
})
