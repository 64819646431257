import {GridResponse, ExportRequestType, postWithAuth} from '@/api'
import {
    POSAlipayRefundRequestType,
    POSAlipayRefundResponseType,
    POSRefundRequestType,
    POSTransactionRequestType,
    POSTransactionResponseType
} from '@/types/transactions'

export const getPOSTransactions = (request: POSTransactionRequestType) =>
    postWithAuth<GridResponse<POSTransactionResponseType>>('/oppapi/v3/pos/transactions', request)

export const exportPOSTransactions = (params: ExportRequestType<POSTransactionRequestType>) =>
    postWithAuth('oppapi/v2/pos/transactions/export', params)

export const refundPOSAlipay = (paymentId: string, request: POSAlipayRefundRequestType) =>
    postWithAuth<POSAlipayRefundResponseType>(
        `oppapi/v1/pos/apm/payments/${paymentId}/refund?paymentMethod=alipayplus`,
        request
    )

export const refundPOSPayment = (paymentId: string, request: POSRefundRequestType) =>
    postWithAuth<any>(`oppapi/v1/pos/payments/${paymentId}/refund`, request)
