import React from 'react'
import {Avatar, List as AntList, Skeleton} from 'antd'
import {ColoredAmount, DateTimeLabel, Icon, List} from '@/components'
import {getDebitCreditAmount, TransactionDetailModel} from '@/stores'
import {TransactionsListProps} from './props'
import styles from './styles.scss'

export const TransactionsList: React.FC<TransactionsListProps<TransactionDetailModel>> = ({
    dataSource,
    loading
}) => {
    return (
        <List
            className={styles.list}
            loading={loading}
            itemLayout={'horizontal'}
            dataSource={dataSource}
            renderItem={(item: TransactionDetailModel) => (
                <AntList.Item
                    className={styles.listItem}
                    actions={[<Icon hoverable key={'repeat'} type={'repeat_payment'} />]}
                >
                    <Skeleton avatar title={false} loading={false} active>
                        <AntList.Item.Meta
                            avatar={<Avatar src={item.merchant} />}
                            title={item.name}
                            description={<DateTimeLabel date={item.createdAt} />}
                        />
                        <ColoredAmount
                            amount={getDebitCreditAmount(item.amount, item.debitCredit)}
                        />
                    </Skeleton>
                </AntList.Item>
            )}
        />
    )
}
