import React from 'react'
import {observer} from 'mobx-react'
import {Table} from 'antd'
import classNames from 'classnames'
import {useInjection} from 'dna-react-ioc'

import {downloadReceipt} from '@/api'
import {withTitle} from '@/router/utils'
import {PaginationContainer, SearchSelectContainer, TabsContainer} from '@/components/containers'
import {PageHeader, SendOrDownloadReceipt} from '@/components'
import {
    ConfirmationModal,
    MobileOnlinePaymentList,
    MobileTableControls,
    PaymentModal,
    OnlinePaymentsTableFilter,
    TransactionDetails,
    TransactionDrawerHeader,
    OnlinePaymentsExportsModal
} from './components'
import {ActionButtons, OnlineEcospendStoreSymbol, EcospendModal} from '@/pages/components'
import {TOnlinePaymentsStore} from '@/pages/OnlinePayments'
import {ProcessNewPaymentModal} from '@/pages/OnlinePayments/components/ProcessNewPaymentModal'
import {LoadingState} from '@/types'
import {getTransactionDetails} from '@/pages/OnlinePayments/services'
import {OnlinePaymentStatus, TableViewType} from '@/constants'
import {useScreenType} from '@/hooks/useScreenType'
import translations from './translations'
import styles from './styles.scss'

export const OnlinePaymentsStoreSymbol = Symbol('OnlinePaymentsStore')
export const OnlinePaymentsTabsStatusContainerStoreSymbol = Symbol(
    'OnlinePaymentsTabsStatusContainerStore'
)

export const OnlinePaymentsModalFilterContainerStoreSymbol = Symbol(
    'OnlinePaymentsModalFilterContainerStore'
)
export const OnlinePaymentsModalConfirmContainerStoreSymbol = Symbol(
    'OnlinePaymentsModalConfirmContainerStore'
)
export const OnlinePaymentsDrawerContainerStoreSymbol = Symbol('OnlinePaymentsDrawerContainerStore')
export const OnlinePaymentsPaymentModalStoreSymbol = Symbol('OnlinePaymentsPaymentModalStore')
export const OnlinePaymentsPaymentOperationStoreSymbol = Symbol(
    'OnlinePaymentsPaymentOperationStore'
)
export const OnlinePaymentsPaginationStoreSymbol = Symbol('OnlinePaymentsPaginationStore')

export const OnlinePaymentsSearchSelectStoreSymbol = Symbol('OnlinePaymentsSearchSelectStore')

const OnlinePayments = observer(() => {
    const {
        columns,
        transactions,
        loadingState,
        selectedTransaction,
        openDrawer,
        mobileTableViewType,
        setMobileTableViewType,
        sendReceipt,
        loadTransactions,
        loadProcessNewPayment,
        openExportsModal
    } = useInjection<TOnlinePaymentsStore>(OnlinePaymentsStoreSymbol)
    const {isMobile} = useScreenType()
    const isSendReceiptAvailable = [
        OnlinePaymentStatus.charge,
        OnlinePaymentStatus.auth,
        OnlinePaymentStatus.refund,
        OnlinePaymentStatus.credited
    ].includes(selectedTransaction?.status as OnlinePaymentStatus)

    const renderContent = () => {
        return (
            <>
                <OnlinePaymentsExportsModal />
                <PageHeader
                    onBack={null}
                    title={translations().title}
                    extra={
                        !isMobile && (
                            <SearchSelectContainer
                                injectableIdentifier={OnlinePaymentsSearchSelectStoreSymbol}
                            />
                        )
                    }
                    border={'none'}
                >
                    {isMobile && (
                        <>
                            <SearchSelectContainer
                                injectableIdentifier={OnlinePaymentsSearchSelectStoreSymbol}
                            />
                            <OnlinePaymentsTableFilter
                                openExportsModal={openExportsModal}
                                onReloadClick={loadTransactions}
                                loading={loadingState === LoadingState.LOADING}
                            />
                        </>
                    )}

                    <TabsContainer
                        injectableIdentifier={OnlinePaymentsTabsStatusContainerStoreSymbol}
                        className={classNames(styles.headerTabs, {
                            [styles.loading]: loadingState === LoadingState.LOADING
                        })}
                        tabBarExtraContent={
                            isMobile
                                ? null
                                : {
                                      right: (
                                          <OnlinePaymentsTableFilter
                                              openExportsModal={openExportsModal}
                                              onReloadClick={loadTransactions}
                                              loading={loadingState === LoadingState.LOADING}
                                          />
                                      )
                                  }
                        }
                    />

                    {isMobile && (
                        <MobileTableControls
                            paginationInjectableID={OnlinePaymentsPaginationStoreSymbol}
                            setViewType={(e) => setMobileTableViewType(e.target.value)}
                        />
                    )}
                </PageHeader>

                {isMobile && mobileTableViewType === TableViewType.SHORTED && (
                    <>
                        <Table
                            scroll={{x: 500}}
                            loading={loadingState === LoadingState.LOADING}
                            columns={columns}
                            dataSource={transactions}
                            pagination={false}
                            onRow={(record) => ({onClick: () => openDrawer(record)})}
                            rowKey={(transaction) => transaction.id}
                        />
                        <PaginationContainer
                            hideSizeChanger
                            injectableIdentifier={OnlinePaymentsPaginationStoreSymbol}
                        />
                    </>
                )}

                {isMobile && mobileTableViewType === TableViewType.EXPANDED && (
                    <>
                        <MobileOnlinePaymentList
                            dataSource={transactions as any}
                            listExtra={(item) => (
                                <ActionButtons
                                    linkTitle={translations().charge}
                                    onClick={() => openDrawer(item)}
                                />
                            )}
                        />

                        <PaginationContainer
                            hideSizeChanger
                            injectableIdentifier={OnlinePaymentsPaginationStoreSymbol}
                        />
                    </>
                )}

                {!isMobile && (
                    <>
                        <Table
                            className={styles.tableRow}
                            columns={columns}
                            dataSource={transactions}
                            pagination={false}
                            loading={loadingState === LoadingState.LOADING}
                            scroll={{x: 1000}}
                            onRow={(record) => ({onClick: () => openDrawer(record)})}
                            rowKey={(transaction) => transaction.id}
                        />
                        <PaginationContainer
                            injectableIdentifier={OnlinePaymentsPaginationStoreSymbol}
                        />
                    </>
                )}

                <TransactionDetails
                    drawerInjectableId={OnlinePaymentsDrawerContainerStoreSymbol}
                    details={getTransactionDetails(selectedTransaction)}
                    title={
                        <TransactionDrawerHeader
                            selectedTransaction={selectedTransaction}
                            status={selectedTransaction?.status as OnlinePaymentStatus}
                            amount={selectedTransaction?.amount}
                            currency={selectedTransaction?.currency}
                            description={selectedTransaction?.description}
                            extra={
                                isSendReceiptAvailable && (
                                    <SendOrDownloadReceipt
                                        sendReceipt={sendReceipt}
                                        order={{
                                            ...selectedTransaction,
                                            orderNumber: selectedTransaction.invoiceId,
                                            date: selectedTransaction.createdDate
                                        }}
                                        email={selectedTransaction?.payerEmail}
                                        downloadReceipt={() =>
                                            downloadReceipt(selectedTransaction?.id)
                                        }
                                    />
                                )
                            }
                        />
                    }
                />

                <ProcessNewPaymentModal
                    selectedTransaction={selectedTransaction}
                    loadProcessNewPayment={loadProcessNewPayment}
                />

                <PaymentModal selectedTransaction={selectedTransaction} />

                <EcospendModal injectableIdentifier={OnlineEcospendStoreSymbol} />

                <ConfirmationModal
                    modalStoreSymbol={OnlinePaymentsModalConfirmContainerStoreSymbol}
                >
                    {selectedTransaction?.amount}
                </ConfirmationModal>
            </>
        )
    }

    return renderContent()
})

export default withTitle(OnlinePayments)
