import rootTranslations from '@/translations'

export enum PaymentCardScheme {
    Visa = 'visa',
    Amex = 'amex',
    Mastercard = 'mastercard',
    AmericanExpress = 'americanexpress',
    UnionPay = 'unionpay',
    UPI = 'upi',
    DinersClub = 'dinersclub'
}

export const getCardSchemeLabel = (cardScheme: PaymentCardScheme) => {
    const [key] = Object.entries(PaymentCardScheme).find(([_, value]) => value === cardScheme) || []
    return rootTranslations().constants.cardScheme[key] || cardScheme
}

export const portalCardSchemes = Object.keys(PaymentCardScheme)
    .filter(
        (key) =>
            PaymentCardScheme[key] !== PaymentCardScheme.Amex &&
            PaymentCardScheme[key] !== PaymentCardScheme.UnionPay
    )
    .map((key) => ({
        label: rootTranslations().constants.cardScheme[key],
        value: PaymentCardScheme[key]
    }))
