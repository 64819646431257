import React from 'react'
import classNames from 'classnames'
import {PaymentMethodIcon} from '@/components'
import {PaymentMethodLabelWithIconProps} from './props'
import styles from './styles.scss'

export const PaymentMethodLabelWithIcon: React.FC<PaymentMethodLabelWithIconProps> = (
    {
        paymentMethod, title,
        bordered, className
    }
) => {
    return (
        <span className={classNames(styles.paymentMethod, className)}>
            <PaymentMethodIcon
                className={classNames(styles.icon, {[styles.bordered]: bordered})}
                type={paymentMethod}
            />&nbsp;{title}
        </span>
    )
}
