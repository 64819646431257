import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    documentType: string
    id: string
    fileName: string
    dateRequested: string
    lastUpdatedDate: string
    status: string
    statuses: {
        STARTED: string
        PROCESSING: string
        QUEUED: string
        FINISHED: string
        FAILED: string
    }
}>()
