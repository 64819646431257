import React from 'react'
import translations from './translations'

translations.add('el', {
    text: 'Επανάληψη',
    seconds: count =>
        count ? (
            <>
                {' '}
                σε <b>{count} δευτερόλεπτα</b>
            </>
        ) : (
            ''
        )
})
