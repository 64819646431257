import React, {useState} from 'react'
import {Layout, Result, Tabs} from 'antd'
import {observer} from 'mobx-react'
import classNames from 'classnames'

import {withTitle} from '@/router/utils'
import {PageHeader, SelectWithTitleContainer} from '@/components'
import {ECOMPaymentsAnalytics, POSPaymentsAnalytics} from '@/pages/Reports/components'
import {RangePickerContainer} from '@/components/containers'
import {StoreSelectContainer} from '@/components/containers/transaction'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {TerminalType} from '@/constants'
import {useScreenType, useWindowSize} from '@/hooks'
import translations from './translations'
import styles from './styles.scss'

export const ReportsStoreSymbol = Symbol('ReportsStore')
export const ReportsECOMRangePickerStoreSymbol = Symbol('ReportsECOMRangePickerStore')
export const ReportsPOSRangePickerStoreSymbol = Symbol('ReportsPOSRangePickerStore')
export const ReportsECOMTopSummaryStoreSymbol = Symbol('ReportsECOMTopSummaryStore')
export const ReportsPOSTopSummaryStoreSymbol = Symbol('ReportsPOSTopSummaryStore')
export const ReportsPOSTransactionsCardWithBarChartContainerStoreSymbol = Symbol(
    'ReportsPOSTransactionsCardWithBarChartContainerStore'
)
export const ReportsPOSTransactionsComparisonCardWithBarChartContainerStoreSymbol = Symbol(
    'ReportsPOSTransactionsComparisonCardWithBarChartContainerStore'
)
export const ReportsPOSCurrencySelectStoreSymbol = Symbol('ReportsPOSCurrencySelectStore')
export const ReportsECOMCurrencySelectStoreSymbol = Symbol('ReportsECOMCurrencySelectStore')
export const ReportsEcomStoreSelectStoreSymbol = Symbol('ReportsEcomStoreSelectStore')
export const ReportsPosStoreSelectStoreSymbol = Symbol('ReportsPosStoreSelectStore')
export const ReportsEcomTransactionsStatusStoreSymbol = Symbol('ReportsEcomTransactionsStatusStore')

const Reports = observer(() => {
    const defaultTab = hasPermissions([
        PermissionsMap.pos_payments.read,
        PermissionsMap.pos_payments.full
    ])
        ? TerminalType.POS
        : TerminalType.ECOM

    const [activeTab, setActiveTab] = useState<string>(defaultTab)
    const [windowWidth] = useWindowSize()
    const {isMobile} = useScreenType()

    return (
        <>
            <PageHeader title={translations().title} border={'none'}>
                {hasPermissions([
                    PermissionsMap.online_payments.read,
                    PermissionsMap.online_payments.full,
                    PermissionsMap.pos_payments.read,
                    PermissionsMap.pos_payments.full
                ]) ? (
                    <Tabs
                        className={classNames({
                            [styles.tabs]: windowWidth < 1480
                        })}
                        activeKey={activeTab}
                        onChange={setActiveTab}
                        tabBarExtraContent={
                            <div
                                className={classNames(styles.tabBarExtra, {
                                    [styles.tabBarExtraMobile]: isMobile
                                })}
                            >
                                <StoreSelectContainer
                                    className={classNames(styles.store, {
                                        [styles.storeMobile]: isMobile
                                    })}
                                    type={activeTab as any}
                                    ecomInjectableIdentifier={ReportsEcomStoreSelectStoreSymbol}
                                    posInjectableIdentifier={ReportsPosStoreSelectStoreSymbol}
                                />

                                <SelectWithTitleContainer
                                    className={classNames(styles.currency, {
                                        [styles.currencyMobile]: isMobile
                                    })}
                                    injectableIdentifier={
                                        activeTab === TerminalType.ECOM
                                            ? ReportsECOMCurrencySelectStoreSymbol
                                            : ReportsPOSCurrencySelectStoreSymbol
                                    }
                                    displayValue={translations().currency}
                                />
                                <RangePickerContainer
                                    showRangePickerTime
                                    injectableIdentifier={
                                        activeTab === TerminalType.ECOM
                                            ? ReportsECOMRangePickerStoreSymbol
                                            : ReportsPOSRangePickerStoreSymbol
                                    }
                                />
                            </div>
                        }
                    >
                        {hasPermissions([
                            PermissionsMap.pos_payments.read,
                            PermissionsMap.pos_payments.full
                        ]) && (
                            <Tabs.TabPane tab={translations().posPayments} key={TerminalType.POS} />
                        )}
                        {hasPermissions([
                            PermissionsMap.online_payments.read,
                            PermissionsMap.online_payments.full
                        ]) && (
                            <Tabs.TabPane
                                tab={translations().onlinePayments}
                                key={TerminalType.ECOM}
                            />
                        )}
                    </Tabs>
                ) : (
                    <Result status='404' title={translations().noContentTitle} />
                )}
            </PageHeader>
            <Layout.Content className={styles.body}>
                {activeTab === TerminalType.ECOM &&
                hasPermissions([
                    PermissionsMap.online_payments.read,
                    PermissionsMap.online_payments.full
                ]) ? (
                    <ECOMPaymentsAnalytics />
                ) : null}
                {activeTab === TerminalType.POS &&
                hasPermissions([
                    PermissionsMap.pos_payments.read,
                    PermissionsMap.pos_payments.full
                ]) ? (
                    <POSPaymentsAnalytics />
                ) : null}
            </Layout.Content>
        </>
    )
})

export default withTitle(Reports)
