import translations from './translations'

translations.add('sl', {
    shop: 'Obchod',
    shopName: 'Názov predajne',
    orderNumber: 'Číslo objednávky',
    description: 'Popis',
    customerName: 'Meno zákazníka',
    postLink: 'Post link',
    customerId: 'ID zákazníckeho účtu',
    email: 'Email',
    rrn: 'RRN',
    receivalReferenceNumber: 'referenčné číslo príjmu',
    amount: {
        label: 'Množstvo',
        from: 'od',
        to: 'do'
    },
    cardMask: {
        label: 'Karta',
        first: 'Prvých 6 číslic',
        last: 'Posledné 4'
    },
    cardScheme: 'Kartová schéma',
    donationAmount: {
        label: 'Výška daru',
        from: 'od',
        to: 'do'
    },
    paymentMethod: 'Spôsob platby',
    currency: 'Mena'
})
