import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {SelectWithTitle, TSelectContainerStore} from '@/components'
import {SelectContainerProps} from './props'

export const SelectWithTitleContainerStoreSymbol = Symbol('SelectWithTitleContainerStore')

export const SelectWithTitleContainer: React.FC<SelectContainerProps> = observer(
    ({
        injectableIdentifier = SelectWithTitleContainerStoreSymbol,
        store: _store,
        ...rest
    }) => {
        const store = _store || useInjection<TSelectContainerStore>(injectableIdentifier)

        return (
            <SelectWithTitle
                {...rest}
                value={store.value || ''}
                onChange={store.setValue}
                options={store.options}
            />
        )
    }
)
