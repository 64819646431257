import translations from './translations'

translations.add('es', {
    labels: {
        transactionType: 'Tipo de transacción'
    },
    warnings: {
        verification: 'La verificación de la cuenta iniciará una transacción de importe 0 (cero) para verificar los datos de la tarjeta.'
    },
    errors: {
        transactionType: 'se requiere tipo de transacción'
    },
    placeholders: {
        transactionType: 'Tipo de transacción'
    }
})
