import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {Col, Form, Grid, Input, Row} from 'antd'
import {AmountFormField, Modal, ModalFormFooter, OrderNumberFormField, TransactionTypeFormField} from '@/components'
import {generateInvoiceId} from '@/utils'
import {LoadingState} from '@/types'
import {useProcessNewPaymentStore} from '@/stores/transactions/ProcessNewPaymentStore'
import {getProcessNewPaymentTransactionTypes} from '@/utils/transactions'
import {TransactionType} from '@/types/transactions'
import translations from './translations'
import {ProcessNewPaymentModalProps} from './props'
import styles from './styles.scss'

const {useBreakpoint} = Grid

export const ProcessNewPaymentStoreSymbol = Symbol('ProcessNewPaymentStore')

export const ProcessNewPaymentModal: React.FC<ProcessNewPaymentModalProps> = observer((
    {selectedTransaction, loadProcessNewPayment}
) => {
    const screens = useBreakpoint()
    const isMobile = screens.xs
    const [form] = Form.useForm()
    const {id} = selectedTransaction
    const {loadingState, open, setOpen} = useProcessNewPaymentStore()

    const formId = 'processNewPaymentForm'
    const invoiceIdPrefix = 'MIT'

    const onModalCancel = () => {
        form.resetFields()
        setOpen(false)
    }

    const onFinish = (values) => {
        const data = {
            ...values,
            amount: +values.amount,
            sequenceType: 'recurring',
            periodicType: 'ucof',
            parentTransactionId: id
        }

        loadProcessNewPayment(data, form)
    }

    const handleReloadClick = (invoiceId: string) => form.setFieldsValue({invoiceId})

    return (
        <Modal
            title={translations().labels.title}
            open={open}
            width={'700px'}
            onCancel={onModalCancel}
            footer={<ModalFormFooter
                okText={translations().buttons.ok}
                confirmLoading={loadingState === LoadingState.LOADING}
                formId={formId}
                onCancel={onModalCancel}
            />}
        >
            <Row>
                <Col className={classNames({[styles.chargeInput]: isMobile})} xs={24} md={12}>
                    <Form
                        id={formId}
                        form={form}
                        layout="vertical"
                        requiredMark={false}
                        onFinish={onFinish}
                        initialValues={{
                            transactionType: TransactionType.SALE,
                            invoiceId: generateInvoiceId(invoiceIdPrefix)
                        }}
                    >
                        <AmountFormField
                            form={form}
                            inputProps={{placeholder: '0'}}
                            currency={selectedTransaction.currency}
                        />
                        <TransactionTypeFormField form={form} transactionTypes={getProcessNewPaymentTransactionTypes()}/>
                        <OrderNumberFormField onReloadClick={handleReloadClick} prefix={invoiceIdPrefix}/>
                        <Form.Item
                            name={'merchantCustomData'}
                            label={ translations().labels.merchantCustomData }
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
})
