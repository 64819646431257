import {CardSettingsStoreInterface} from '@/pages/business-account/CardsManagement'
import {injectable} from 'inversify'
import {makeAutoObservable} from 'mobx'
import {LoadingState} from '@/types'
import {fetchCardCVV, fetchCardPIN, fetchChangeCardStatus} from '@/stores'
import {openErrorNotification} from '@/utils'

@injectable()
export class CardSettingsStore implements CardSettingsStoreInterface {
    constructor() {
        makeAutoObservable(this)
    }

    isBlockModalVisible = false
    isFreezeModalVisible = false
    isGetCVVModalVisible = false
    isGetPINModalVisible = false
    pinData = null
    cvvData = null
    getCVVLoadingState = LoadingState.IDLE
    getPINLoadingState = LoadingState.IDLE
    changeCardStatusLoadingState = LoadingState.IDLE
    token = ''

    openBlockCardModal = () => {
        this.isBlockModalVisible = true
    }

    openFreezeCardModal = () => {
        this.isFreezeModalVisible = true
    }

    closeBlockCardModal = () => {
        this.isBlockModalVisible = false
    }

    closeFreezeCardModal = () => {
        this.isFreezeModalVisible = false
    }

    openGetCVVModal = () => {
        this.isGetCVVModalVisible = true
    }

    closeGetCVVModal = () => {
        this.isGetCVVModalVisible = false
        this.cvvData = null
    }

    openGetPINModal = () => {
        this.isGetPINModalVisible = true
    }

    closeGetPINModal = () => {
        this.isGetPINModalVisible = false
        this.pinData = null
    }

    setCardToken = (token: string) => {
        this.token = token
    }

    onGetCVVClick = async () => {
        if (this.getCVVLoadingState === LoadingState.LOADING) {
            return
        }

        try {
            this.getCVVLoadingState = LoadingState.LOADING

            const {result, error} = await fetchCardCVV(this.token)

            if (error) {
                this.getCVVLoadingState = LoadingState.FAILED
                throw new Error(error.message)
            }

            if (result) {
                this.getCVVLoadingState = LoadingState.DONE
                this.cvvData = result
            }
        } catch (e) {
            openErrorNotification(e)
            this.getCVVLoadingState = LoadingState.FAILED
        }
    }

    onGetPINClick = async () => {
        if (this.getPINLoadingState === LoadingState.LOADING) {
            return
        }

        try {
            this.getPINLoadingState = LoadingState.LOADING

            const {result, error} = await fetchCardPIN(this.token)

            if (error) {
                this.getPINLoadingState = LoadingState.FAILED
                throw new Error(error.message)
            }

            if (result) {
                this.getPINLoadingState = LoadingState.DONE
                this.pinData = result
            }
        } catch (e) {
            this.getPINLoadingState = LoadingState.FAILED
            openErrorNotification(e)
        }
    }

    onChangeCardStatus = async values => {
        if (this.changeCardStatusLoadingState === LoadingState.LOADING) {
            return
        }

        try {
            this.changeCardStatusLoadingState = LoadingState.LOADING

            const {result, error} = await fetchChangeCardStatus(this.token, values.status)

            if (error) {
                this.changeCardStatusLoadingState = LoadingState.FAILED
                throw new Error(error.message)
            }

            if (result) {
                this.changeCardStatusLoadingState = LoadingState.DONE
            }
        } catch (e) {
            this.changeCardStatusLoadingState = LoadingState.FAILED
            openErrorNotification(e)
        }
    }
}
