import translations from './translations'

translations.add('es', {
    labels: {
        email: 'Email',
        firstName: 'Nombre',
        lastName: 'Apellidos',
        store: 'Tienda',
        status: 'Estado',
        authMethod: 'Método de Autenticación',
        active: 'Activo'
    }
})
