import {register} from 'dna-react-ioc'
import {interfaces} from 'inversify'

import {getPortalPosStatuses} from '@/constants'

import {
    DrawerStore,
    PaginationStore,
    PeriodType,
    RangePickerStore,
    TabsContainerStore
} from '@/stores'

import {
    PaginationContainerStoreType,
    TRangePickerContainerStore,
    TSelectContainerStore,
    TTabsContainerStore
} from '@/components'

import {
    exportPOSAmexTransactions,
    POSAmexPaymentsStore,
    POSAmexPaymentsStoreSymbol,
    POSAmexPaymentsTabsStoreSymbol,
    POSAmexPaymentsFilterStoreSymbol,
    POSAmexPaymentsDrawerStoreSymbol,
    POSAmexPaymentsExportsStoreSymbol,
    POSAmexPaymentsStatusSelectStoreSymbol,
    POSAmexPaymentsRangePickerStoreSymbol,
    POSAmexPaymentsPaginationStoreSymbol
} from '@/stores/transactions/pos-amex'

import {ProfileStoreInterface, ProfileStoreSymbol} from '@/pages/Profile'
import {IPOSAmexPaymentsStore} from '@/pages/POSAmexPayments'

import {TDrawerContainerStore} from '@/components/containers/DrawerContainer/TDrawerContainerStore'
import {StatusSelectContainerStore} from '@/stores/select/StatusSelectContainerStore'

import {IFilterContainerStore} from '@/components/containers'
import {IExportsModalContainerStore} from '@/components/containers/transaction'

import {ExportsModalContainerStore, POSFiltersStore} from '@/stores/shared'

import {getExportFileName} from '@/utils/transactions'
import {getPOSPaymentTabs, getPOSAmexExportColumns} from '@/utils/pos-transaction'

export const posAmexPaymentsState = () => {
    register<IPOSAmexPaymentsStore>(POSAmexPaymentsStoreSymbol)
        .to(POSAmexPaymentsStore)
        .inSingletonScope()

    register<TRangePickerContainerStore>(POSAmexPaymentsRangePickerStoreSymbol)
        .toDynamicValue(() => new RangePickerStore({period: PeriodType.Day}))
        .inSingletonScope()

    register<TTabsContainerStore>(POSAmexPaymentsTabsStoreSymbol)
        .toDynamicValue(() => new TabsContainerStore(getPOSPaymentTabs()))
        .inSingletonScope()

    register<TSelectContainerStore>(POSAmexPaymentsStatusSelectStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new StatusSelectContainerStore(
                    context.container.get(POSAmexPaymentsTabsStoreSymbol),
                    getPortalPosStatuses()
                )
        )
        .inSingletonScope()

    register<PaginationContainerStoreType>(POSAmexPaymentsPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()

    register<TDrawerContainerStore>(POSAmexPaymentsDrawerStoreSymbol)
        .to(DrawerStore)
        .inSingletonScope()

    register<IFilterContainerStore>(POSAmexPaymentsFilterStoreSymbol)
        .toDynamicValue(
            () =>
                new POSFiltersStore({
                    hiddenFields: ['cardScheme']
                })
        )
        .inSingletonScope()

    register<IExportsModalContainerStore>(POSAmexPaymentsExportsStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new ExportsModalContainerStore(getPOSAmexExportColumns(), fields => {
                    const profileStore: ProfileStoreInterface =
                        context.container.get(ProfileStoreSymbol)
                    const posAmexPaymentsStore: IPOSAmexPaymentsStore = context.container.get(
                        POSAmexPaymentsStoreSymbol
                    )

                    const format = 'csv'
                    const {startDate, endDate} = posAmexPaymentsStore.rangePickerStore
                    const fileName = getExportFileName(
                        startDate,
                        endDate,
                        profileStore.merchant?.name,
                        format
                    )

                    return exportPOSAmexTransactions({
                        ...posAmexPaymentsStore.request,
                        format,
                        fields,
                        fileName
                    })
                })
        )
        .inSingletonScope()
}
