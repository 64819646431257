import React from 'react'
import {ColumnsType} from 'antd/es/table'

import {DateTimeLabel, PaymentMethodOrMaskedCard, TableStatus} from '@/components'
import {BasePOSTransactionType} from '@/types/transactions/pos'
import {getAmountWithCurrency} from '@/utils'

import {
    getPOSPaymentMethod,
    getPOSPaymentStatusIconType,
    getPOSPaymentStatusName,
    getPOSPaymentStatusTagType,
    getPOSTransactionType
} from '.'

import translations from './translations'

export const getColumns = (): ColumnsType<BasePOSTransactionType> => {
    return [
        {
            title: translations().amount,
            dataIndex: 'amount',
            key: 'amount',
            align: 'right' as any,
            width: 150,
            render: (_, item) => <b>{getAmountWithCurrency(item.amount, item.currency)}</b>
        },
        {
            title: translations().status,
            dataIndex: 'status',
            key: 'status',
            align: 'left' as any,
            width: 180,
            render: (item) => {
                return (
                    <TableStatus
                        statusProps={{
                            status: getPOSPaymentStatusName(item),
                            type: getPOSPaymentStatusTagType(item),
                            iconType: getPOSPaymentStatusIconType(item)
                        }}
                    />
                )
            }
        },
        {
            title: translations().terminalId,
            dataIndex: 'terminalId',
            key: 'terminalId',
            render: (item) => (item ? item : '-')
        },
        {
            title: translations().transaction.type,
            dataIndex: 'transactionType',
            key: 'transactionType',
            render: (item, object) => getPOSTransactionType(object)
        },
        {
            title: translations().paymentMethod,
            dataIndex: 'cardMask',
            key: 'cardMask',
            render: (_, item) => {
                const paymentMethod = getPOSPaymentMethod(item)
                return (
                    <PaymentMethodOrMaskedCard
                        paymentMethod={paymentMethod}
                        cardScheme={item.cardScheme}
                        cardMask={item.cardMask}
                    />
                )
            }
        },
        {
            title: translations().date,
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            width: 170,
            render: (item) => (item ? <DateTimeLabel date={item} /> : '-')
        }
    ]
}
