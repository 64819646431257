import translations from './translations'

translations.add('sl', {
    title: 'Export Online payments',
    columns: 'Stĺpce',
    cancel: 'Zruš',
    ok: 'Export',
    all: value => `Všetko (${value})`,
    custom: 'Vlastné'
})
