import translations from './translations'

translations.add('sl', {
    amount: 'Znesek',
    chargebackDate: 'Datum Vračila',
    transactionDate: 'Datum Transakcije',
    currency: 'Valuta',
    reasonCode: 'Koda Razloga',
    merchantName: 'Ime Trgovca',
    reason: 'Razlog',
    status: 'Status',
    paymentMethod: 'Način plačila'
})
