import React from 'react'
import translations from './translations'

translations.add('sl', {
    download: 'Stiahnuť',
    cancel: 'Zruš',
    title: 'Prenos izjave PDF',
    text: (
        <>
            Izberite želeno obdobje poravnave in kliknite Stiahnuť. <br /> <br />
            <strong>Zavrnitev odgovornosti:</strong> Izjave lahko prenesete za izpolnjeno mesecu od
            1. dne novega meseca. Od avgusta 2023 naprej bodo v najnovejši slog in format, do
            avgusta 2023 pa bodo ostali v starem dizajnu.
        </>
    )
})
