import translations from './translations'

translations.add('ro', {
    back: 'Înapoi la Autentificare',
    title: 'Resetare Parolă',
    text: 'Introduceți adresa de email pe care ați utilizat-o la înregistrarea în portal și vă vom trimite instrucțiuni pentru resetarea parolei',
    email: 'Adresa de email',
    emailRequired: 'Adresa de email este obligatorie',
    emailInvalid: 'Adresa de email nu este validă',
    submit: 'Trimite',
    errorOnEmail: message =>
        `Nu s-a reușit obținerea sau parsarea adresei de email din parametrii interogării: ${message}`
})
