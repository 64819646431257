import React from 'react'
import translations from './translations'

translations.add('sl', {
    overviewDescription:
        'Naša stránka Prehľad ponúka pohľad na vaše platobné postupy z vtáčej perspektívy, kde si môžete prezerať analýzy ako grafy v reálnom čase, ktoré monitorujú a vykazujú vaše platby.',
    posPaymentsDescription:
        'Náš portál pre obchodníkov poskytuje aktualizácie v reálnom čase pre každú platbu na predajnom mieste, ktorú spracovávate, kde môžete vyhľadávať a detailne analyzovať svoje platby pomocou nástrojov filtra.',
    posAmexPaymentsDescription: 'Monitorujte svoje transakcie POS spracované prostredníctvom American Express, kde môžete vyhľadávať a podrobnejšie analyzovať platby pomocou nástrojov na filtrovanie.',
    onlinePaymentsDescriptions:
        'Náš portál pre obchodníkov poskytuje aktualizácie v reálnom čase pre každú spracovanú online platbu, kde môžete monitorovať a spravovať platby, vykonávať vrátenie peňazí alebo zrušiť platbu.',
    paymentLinksDescription:
        'Povolili ste platobné prepojenia. Platobné odkazy vám umožňujú prijímať platby online bez použitia webovej stránky vytvorením a odoslaním platobného prepojenia zákazníkovi.',
    paymentLinksCreate:
        'Pomocou tohto tlačidla môžete vytvoriť a odoslať zákazníkovi platobné prepojenia a dostávať platby online v reálnom čase.',
    virtualTerminalDescription:
        'Náš virtuálny terminál vám umožňuje prijímať platby telefonicky alebo e-mailom, aby ste mohli prijímať platby na diaľku bez použitia webovej stránky.',
    virtualTerminalDetails:
        'Náš virtuálny terminál vám umožňuje prijímať platby telefonicky alebo e-mailom, aby ste mohli prijímať platby na diaľku bez použitia webovej stránky.',
    settlementsDescription:
        'Môžete si stiahnuť, sledovať a vyhľadávať vlastné dátumy a parametre pre vaše Zúčtovania pomocou filtrov a vyhľadávacích nástrojov na tejto stránke.',
    settlementsDownload:
        "Ak si chcete stiahnuť svoje stavy zúčtovania, prejdite na tlačidlo 'Stiahnuť' v pravom hornom rohu obrazovky a stiahnite si ich buď ako súbor PDF, Excel alebo CSV.",
    invoicesDescription: 'Sledujte poplatky zo svojich uskutočnených transakcií v časti Fakturácia, ktorá zobrazuje zoznam vašich faktúr od spoločnosti DNA Payments na stiahnutie.',
    reportsDescription: 'Načítajte správy a porovnajte stavy vašich platieb podľa filtrov vyhľadávania a zobrazujte grafy podľa stavu transakcie alebo metód platieb.',
    teamManagementDescription: 'Spravujte a nastavujte oprávnenia pre členov tímu vo vašom Obchodníckom portáli, aby ste zjednodušili spoluprácu a s ľahkosťou kontrolovali prístup.',
    teamManagementAddUserDescription:
        'Nových členov tímu môžete jednoducho pozvať na prístup k obchodnému portálu, a to buď ako správca, alebo s obmedzeným prístupom.',
    paymentMethodsDescription: 'Prezrite si a spravujte všetky vaše alternatívne platobné metódy pre Online platby a platobné odkazy prostredníctvom nášho Obchodného portálu v jednom mieste.',
    exportDescription: 'Sledujte exportované CSV súbory údajov o platbách online a na pokladni, ktoré zobrazujú zoznam vašich exportovaných súborov a ich stav.',
    thankYou: 'Ďakujem!',
    thankYouDescription:
        'Ďakujeme, že ste si vybrali DNA platby! Ešte raz, neváhajte nás kontaktovať prostredníctvom nášho online chatu, ak máte akékoľvek ďalšie otázky alebo požiadavky.',
    skipTutorial: 'Preskoč tutorial'
})
