import translations from './translations'

translations.add('es', {
    title: 'Anular registro de dominio',
    pleaseEnterReasonWhyUnregistering: 'Ingrese la razón por la cual está cancelando el registro de los dominios seleccionados',
    unregister: 'Anular registro',
    close: 'Cerrar',
    errors: {
        reasonRequired: 'Razón requerida'
    }
})
