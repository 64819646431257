import 'reflect-metadata'
import {action, computed, makeObservable, observable} from 'mobx'
import {inject, injectable} from 'inversify'
import {MenuDataItem} from '@/types'
import {menu as menuItems} from '@/router/menu'
import {IMainLayoutStore} from '@/layouts/MainLayout/IMainLayoutStore'
import {VirtualTerminalStore, VirtualTerminalStoreSymbol} from '../virtual-terminal'
import {
    filterMenuItemsByPermissions,
    filterMenuItemsByProducts
} from '@/stores/main-layout/services/utils'
import {ROUTES} from '@/router/routes'
import {ProfileStoreInterface} from '@/pages/Profile'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {SelectItem} from '@/components/dumb/Select'
import {storage} from '@/services/storage'
import {IS_SIDER_COLLAPSED_KEY} from '@/constants'
import {ACCESS_TOKEN_KEY} from '@/constants/auth-constants'
import {
    DossierMerchantInfoStoreInterface,
    DossierMerchantInfoStoreSymbol
} from '@/stores/profile/DossierMerchantInfo'

@injectable()
export class MainLayoutStore implements IMainLayoutStore {
    private readonly _virtualTerminalStore: VirtualTerminalStore
    private readonly _profileStore: ProfileStoreInterface
    private readonly _dossierMerchantInfoStore: DossierMerchantInfoStoreInterface

    isCollapsed = Boolean(storage.get(IS_SIDER_COLLAPSED_KEY))

    constructor(
        @inject(VirtualTerminalStoreSymbol) virtualTerminalStore: VirtualTerminalStore,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface,
        @inject(DossierMerchantInfoStoreSymbol)
        dossierMerchantInfoStore: DossierMerchantInfoStoreInterface
    ) {
        this._virtualTerminalStore = virtualTerminalStore
        this._profileStore = profileStore
        this._dossierMerchantInfoStore = dossierMerchantInfoStore

        makeObservable(this, {
            isCollapsed: observable,
            menuItems: computed,
            setCollapsed: action.bound
        })
    }

    public get menuItems(): MenuDataItem[] {
        const filteredMenuItemsByVTAvailability = menuItems.filter(item => {
            switch (item.path) {
                case ROUTES.virtualTerminal:
                    return this._virtualTerminalStore.isVirtualTerminalAvailable
                default:
                    return true
            }
        })

        const filteredMenuItemsByPermissions = filterMenuItemsByPermissions(
            filteredMenuItemsByVTAvailability
        )

        //trigger rerun when products are changed
        this._dossierMerchantInfoStore.products

        return filterMenuItemsByProducts(filteredMenuItemsByPermissions)
    }

    get merchants(): SelectItem[] {
        if (storage.get(ACCESS_TOKEN_KEY)) {
            return (
                this._profileStore?.profileData?.merchants?.map(merchant => ({
                    label: merchant.name,
                    value: merchant.merchantId
                })) || []
            )
        }

        return []
    }

    public setCollapsed = (isCollapsed: boolean) => {
        storage.set(IS_SIDER_COLLAPSED_KEY, isCollapsed ? 'true' : null)
        window.dispatchEvent(new Event('storage'))

        this.isCollapsed = isCollapsed
    }
}
