import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    buttons: {
        processNewPayment: string
        charge: string
        refund: string
        cancelPayment: string
    }
    transactions: string
    transactionDetails: string
    noDescription: string
    refundDisabledTooltip: string
}>()

