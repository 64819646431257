export const PermissionsMap = {
    overview: {
        read: 'overview.read'
    },
    online_payments: {
        read: 'online_payments.read',
        full: 'online_payments.full'
    },
    chargebacks: {
        read: 'chargebacks.read'
    },
    pos_payments: {
        read: 'pos_payments.read',
        full: 'pos_payments.full',
        refunds: 'pos_payments.refunds'
    },
    pos_amex_payments: {
        read: 'pos_amex_payments.read',
        full: 'pos_amex_payments.full'
    },
    payment_links: {
        read: 'payment_links.read',
        full: 'payment_links.full'
    },
    virtual_terminal: {
        full: 'virtual_terminal.full',
        refunds: 'virtual_terminal.refunds'
    },
    settlements: {
        read: 'settlements.read',
        full: 'settlements.full'
    },
    reports: {
        read: 'reports.read'
    },
    payment_methods: {
        read: 'payment_methods.read',
        full: 'payment_methods.full'
    },
    teammates: {
        read: 'teammates.read',
        full: 'teammates.full'
    },
    accountInfo: {
        read: 'teammates.read',
        full: 'teammates.full'
    },
    cardsManagement: {
        read: 'teammates.read',
        full: 'teammates.full'
    },
    invoices: {
        read: 'invoices.read',
        full: 'invoices.full'
    }
}
