import {inject, injectable} from 'inversify'
import {action, makeObservable, observable, runInAction, reaction, computed} from 'mobx'
import {openErrorNotification, openSuccessNotification} from '@/utils'
import {
    getAuthSecretKey,
    getTwoFASettings,
    getAuthAppInfo,
    getAuthQR,
    updateTwoFAUserSettings
} from '@/stores/profile/services/fetchers'
import {TwoFAStoreInterface, TwoFAStoreSymbol} from '@/pages/Profile/components/TwoFA'
import {TwoFAModalStoreInterface} from './TwoFAModalStoreInterface'
import {
    AuthSecretKeyModel,
    TwoFASettingsModel,
    AuthAppInfoModel,
    RecoveryCodeInterface,
    TwoFAUserUpdateSettingsRequestModel
} from '@/stores/profile/models'

import translations from './translations'

@injectable()
export class TwoFAModalStore implements TwoFAModalStoreInterface {
    public twoFAStore: TwoFAStoreInterface
    public settings: TwoFASettingsModel = null
    public selectedFrequency: string = null
    public savedFrequency: string = null
    public secretKey: AuthSecretKeyModel = null
    public authAppInfo: AuthAppInfoModel = null
    public recoveryCodes: RecoveryCodeInterface[] = []
    public qrCode: string = null
    public isModalCloseble: boolean = true

    constructor(@inject(TwoFAStoreSymbol) twoFAStore: TwoFAStoreInterface) {
        this.twoFAStore = twoFAStore

        makeObservable(this, {
            settings: observable,
            authAppInfo: observable,
            secretKey: observable,
            selectedFrequency: observable,
            savedFrequency: observable,
            recoveryCodes: observable,
            qrCode: observable,
            isModalCloseble: observable,

            isTrustedDeviceSelected: computed,

            setSelectedFrequency: action.bound,
            setSettings: action.bound,
            setRecoveryCodes: action.bound,
            setIsModalCloseble: action.bound
        })

        this.getTwoFASettings()

        reaction(
            () => ({
                userSettings: twoFAStore.userSettings
            }),
            ({userSettings}) => {
                if (userSettings?.frequency) {
                    const frequency = userSettings?.frequency.id

                    this.setSelectedFrequency(frequency)
                    runInAction(() => (this.savedFrequency = frequency))
                }
            }
        )
    }

    get isTrustedDeviceSelected(): boolean {
        return (
            this.settings?.frequencies?.find(({id}) => id === this.savedFrequency)?.value ===
            'TRUSTED_DEVICE'
        )
    }

    setSelectedFrequency = (frequency: string) => {
        this.selectedFrequency = frequency
    }

    getAuthSecretKey = async () => {
        try {
            const {result, error} = await getAuthSecretKey()

            if (error) {
                openErrorNotification(error.message)

                return
            }

            runInAction(() => {
                this.secretKey = result
            })
        } catch (error) {
            console.error(error)
        }
    }

    getTwoFASettings = async () => {
        try {
            const {result, error} = await getTwoFASettings()

            if (error) {
                openErrorNotification(error.message)

                return
            }

            this.setSettings(result)

            if (!this.selectedFrequency) {
                this.setSelectedFrequency(result.frequencies[0]?.id)
            }
        } catch (error) {
            console.error(error)
        }
    }

    getAuthAppInfo = async () => {
        try {
            const {result, error} = await getAuthAppInfo()

            if (error) {
                openErrorNotification(error.message)

                return
            }

            runInAction(() => {
                this.authAppInfo = result
            })
        } catch (error) {
            console.error(error)
        }
    }

    public getAuthQR = async () => {
        try {
            const {result, error} = await getAuthQR()

            if (error) {
                openErrorNotification(error.message)

                return
            }

            runInAction(() => {
                this.qrCode = result
            })
        } catch (error) {
            console.error(error)
        }
    }

    public getConfigureAppInfo = async () => {
        this.getAuthSecretKey()
        this.getAuthAppInfo()
        this.getAuthQR()
    }

    public updateTwoFAUserSettings = async (code: number, disable?: boolean): Promise<boolean> => {
        let request = {
            twoFAStatusId: this.settings.statuses.find(({value}) => value === 'ENABLED')?.id,
            twoFATypeId: this.settings.types.find(({value}) => value === 'AUTHENTICATOR')?.id,
            twoFAFrequencyId: this.selectedFrequency
        }

        if (disable) {
            request = {
                twoFAStatusId: this.settings.statuses.find(({value}) => value === 'DISABLED')?.id
            } as TwoFAUserUpdateSettingsRequestModel
        }

        try {
            const {result, error} = await updateTwoFAUserSettings(request, code)

            if (error) {
                openErrorNotification(error.message)

                return false
            }

            if (!this.twoFAStore.isEnabled && !result.code) {
                window.location.reload()

                return false
            }

            if (disable) {
                this.twoFAStore.setUserSettings(null)
                this.savedFrequency = null
                this.reset()
                openSuccessNotification(translations().message.disableSuccess)
            } else if (!Boolean(this.twoFAStore.isEnabled)) {
                openSuccessNotification(translations().message.enableSuccess)
            } else {
                openSuccessNotification(translations().message.updateSuccess)
            }

            if (!disable && result.code) {
                this.setRecoveryCodes([{code: result.code}])
            }

            this.twoFAStore.setUserSettings({
                frequency: this.settings.frequencies.find(
                    ({id}) => id === request.twoFAFrequencyId
                ),
                type: this.settings.types.find(({id}) => id === request.twoFATypeId),
                status: this.settings.statuses.find(({id}) => id === request.twoFAStatusId)
            })

            return true
        } catch (error) {
            console.error(error)
            return false
        }
    }

    public setSettings = (settings: TwoFASettingsModel) => {
        this.settings = settings
    }

    public setRecoveryCodes = (codes: RecoveryCodeInterface[]) => {
        this.recoveryCodes = codes
    }

    public setIsModalCloseble = (closeble: boolean) => {
        this.isModalCloseble = closeble
    }

    public reset = () => {
        this.setSelectedFrequency(this.savedFrequency || this.settings?.frequencies[0]?.id)
    }
}
