import translations from './translations'

translations.add('el', {
    title: 'Ρυθμίσεις τερματικού',
    configurationOptions: 'Επιλογές διαμόρφωσης',
    shippingPreference: 'Προτίμηση αποστολής',
    useCustomerProvidedShippingAddress: 'Χρησιμοποιήστε τη διεύθυνση αποστολής που παρέχεται από τον πελάτη στον ιστότοπο του PayPal.',
    redactShippingAddressFromPaypalSite: 'Παράλειψη της διεύθυνσης αποστολής από τον ιστότοπο του PayPal. Συνιστάται για ψηφιακά αγαθά.',
    useMerchantProvidedAddress: 'Χρησιμοποιήστε τη διεύθυνση που παρέχεται από τον έμπορο. Ο πελάτης δεν μπορεί να αλλάξει αυτήν τη διεύθυνση στον ιστότοπο του PayPal.',
    defaultTransactionType: 'Προεπιλεγμένος τύπος συναλλαγής',
    authorization: 'Εξουσιοδότηση',
    capture: 'Λήψη',
    cancel: 'Ακύρωση',
    saveChanges: 'Αποθήκευσε τις αλλαγές'
})
