import React from 'react'
import {CaretRightOutlined} from '@ant-design/icons'
import {Collapse as AntCollapse} from 'antd'
import {CollapseProps} from './props'
import styles from './styles.scss'

export const Collapse = ({children, defaultActiveKey, className}: CollapseProps) => {
    return (
        <AntCollapse
            className={className}
            bordered={false}
            ghost
            defaultActiveKey={defaultActiveKey}
            expandIcon={({isActive}) => (
                <CaretRightOutlined className={styles.expandIcon} rotate={isActive ? 270 : 90}/>
            )}
            expandIconPosition={'end'}
        >
            {children}
        </AntCollapse>
    )
}
