import {TTabsContainerStore} from '@/components'
import {injectable} from 'inversify'
import {action, computed, makeObservable, observable} from 'mobx'
import {OnlinePaymentsStore} from './OnlinePaymentsStore'
import {Tab} from '@/components/dumb/Tabs/props'

@injectable()
export class OnlinePaymentsTabsStore implements TTabsContainerStore {
    constructor() {
        makeObservable(this, {
            value: computed,
            tabs: observable,
            setValue: action.bound,
            setTabs: action.bound
        })
    }

    parentStore: OnlinePaymentsStore
    tabs: Tab[] = []

    get value(){
        return this.parentStore?.status
    }

    clear() {
        return this.parentStore?.setStatus(null)
    }

    setValue(value: string): void {
        return this.parentStore?.setStatus(value)
    }

    setTabs(tabs: Tab[]): void {
        this.tabs = tabs
    }


}
