import translations from './translations'

translations.add('es', {
    cookies: 'Preferencias de cookies',
    cookiesContent: 'En nuestro sitio web utilizamos cookies. Algunas de ellas son esenciales, mientras que otras nos ayudan a mejorar este sitio web y su experiencia.',
    descriptions: {
        name: 'Nombre',
        provider: 'Provider',
        saveVisitorsPreferences: 'Guarda las preferencias de los visitantes seleccionadas en la Cookie',
        cookieName: 'Nombre de la Cookie',
        cookieExpiry: 'Caducidad de la Cookie',
        cookie: 'Cookie',
        ownerOfSite: 'Propietario de este sitio web',
        cookieByGoogleAdvanced: 'Cookie de Google utilizada para el control avanzado de secuencias de comandos y eventos',
        purpose: 'Purpose',
        year: '1 año',
        googleTagManager: 'Google Tag Manager',
        googleLLC: 'Google LLC',
        twoYears: '2 años',
        privacyPolicy: 'Política de privacidad'
    }
})
