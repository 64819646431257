import React, {useRef, useEffect} from 'react'
import {isRefCallback} from 'dna-common'

export function useRefCurrent<T>(forwardedRef: React.Ref<T>) {
    const currentRef = useRef<T>()
    const ref = (() => {
        if (isRefCallback(forwardedRef)) {
            return (instance: T) => {
                forwardedRef(instance)
                currentRef.current = instance
            }
        }

        return forwardedRef || currentRef
    })()

    if (!isRefCallback(ref)) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            currentRef.current = ref.current
        }, [ref.current])
    }

    return {current: currentRef.current, ref}
}
