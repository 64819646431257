import translations from './translations'

translations.add('es', {
    title: 'Gestión de equipos',
    inviteTeammate: 'Añadir usuario',
    editTeammate: 'Editar usuario',
    cancel: 'Cancelar',
    submit: 'Completar',
    invite: 'Invitar',
    formFields: {
        email: 'Correo electrónico',
        store: 'Tienda',
        firstName: 'Nombre',
        lastName: 'Apellido',
        teammatePermissions: 'Acceso de usuarios',
        inviteAsAdmin: 'Admin',
        inviteAsRestrictedAccess: 'Acceso limitado',
        accountantAccess: 'Acceso financiero',
        customAccess: 'Acceso personalizado',
        grantedPermissions: 'Configuración de permisos',
        accessLevels: 'Niveles de acceso',
        sectionName: 'Nombre de la sección',
        noAccess: 'Sin acceso',
        readAccess: 'Sólo lectura',
        fullAccess: 'Acceso total',
        sections: {
            overview: 'Visión general',
            onlinePayments: 'Pagos en línea',
            posPayments: 'Pagos TPV',
            posAmexPayments: 'POS American Express',
            paymentLinks: 'Enlaces de pago',
            virtualTerminal: 'Terminal virtual',
            settlements: 'Liquidaciones',
            invoices: 'Facturas',
            reports: 'Informes',
            paymentMethods: 'Métodos de pago',
            teammates: 'Gestión de equipos',
            chargebacks: 'Contracargos'
        }
    },
    formFieldsPlaceholders: {
        role: 'Seleccione función'
    },
    formErrors: {
        emailRequired: 'Correo electrónico obligatorio',
        roleRequired: 'Rol obligatorio',
        shopRequired: 'Se requiere una tienda',
        enterValidEmail: 'Introduzca una dirección de correo electrónico válida',
        firstNameRequired: 'Nombre obligatorio',
        lastNameRequired: 'Apellido obligatorio'
    }
})
