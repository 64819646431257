import 'reflect-metadata'
import {inject, injectable} from 'inversify'
import {makeAutoObservable} from 'mobx'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol
} from '@/stores/store-and-terminals'
import {setPaymentMethodSetting} from '@/stores/payment-methods'
import {PaymentMethodType} from '@/constants'
import {openErrorNotification} from '@/utils'
import rootTranslations from '@/translations/translations'

@injectable()
export class ActivateTerminalStore {
    private _merchantStoresStore: AvailableStoresDataStore

    constructor(
        @inject(AvailableStoresDataStoreSymbol) merchantStoresStore: AvailableStoresDataStore
    ) {
        this._merchantStoresStore = merchantStoresStore

        makeAutoObservable(this)
    }

    onTerminalSwitchChange = async (
        terminalId: string,
        checked: boolean,
        paymentMethod: Exclude<PaymentMethodType, PaymentMethodType.card>
    ) => {
        const data = {
            terminalId,
            status: checked ? 'active' : 'disabled'
        }

        try {
            const {error} = await setPaymentMethodSetting(paymentMethod, data)

            if (error) {
                openErrorNotification(error?.message)
                return
            }
        } catch (error) {
            openErrorNotification(rootTranslations().errors.general)
            return
        }

        await this._merchantStoresStore.reloadData()
    }
}
