import {inject, injectable} from 'inversify'
import {action, makeObservable, observable, runInAction} from 'mobx'
import {CreatePayLinkRequest, editLink, PaymentLink} from '@/api'
import {openErrorNotification, openSuccessNotification} from '@/utils'
import {PayByLinkEditPaymentLinkModalContainerStoreSymbol} from '@/pages/PayByLink'
import {TEditPaymentLinkStore} from '@/pages/components/EditPaymentLinkModal'
import {TModalContainerStore} from '@/components/containers/ModalContainer/TModalContainerStore'
import translations from './translations'
import rootTranslations from '@/translations'

@injectable()
export class EditPaymentLinkStore implements TEditPaymentLinkStore {
    private readonly _editPaymentLinkModalStore: TModalContainerStore

    constructor(
        @inject(PayByLinkEditPaymentLinkModalContainerStoreSymbol)
        editPaymentLinkModalStore: TModalContainerStore
    ) {
        this._editPaymentLinkModalStore = editPaymentLinkModalStore

        makeObservable(this, {
            isLoading: observable,
            paymentLink: observable,
            editPaymentLink: action.bound,
            closeEditLinkModal: action.bound,
            openEditModal: action.bound
        })
    }

    public isLoading = false
    public paymentLink: PaymentLink = {} as PaymentLink

    public closeEditLinkModal() {
        this._editPaymentLinkModalStore.setOpen(false)
    }

    public openEditModal(paymentLink: PaymentLink) {
        this.paymentLink = paymentLink
        this._editPaymentLinkModalStore.setOpen(true)
    }

    public async editPaymentLink(id, values: CreatePayLinkRequest) {
        this.isLoading = true
        try {
            const {error} = await editLink(id, values)

            runInAction(() => {
                if (error) {
                    return openErrorNotification(error.message)
                }

                this.closeEditLinkModal()

                return openSuccessNotification(translations().linkSaved)
            })
        } catch (error) {
            return openErrorNotification(rootTranslations().errors.general)
        } finally {
            this.isLoading = false
        }
    }
}
