import translations from './translations'
import React from 'react'

translations.add('en', {
    title: 'Klarna',
    description: <>
        DNA Payments are proud to partner with Klarna for the provision of Buy Now, Pay Later (BNPL) transactions. BNPL allows consumers to spread the cost of an applicable purchase without paying any interest or additional fees.
        <br/><br/>
        Once this is enabled consumers will be given the option to choose the Klarna BNPL during the checkout process unless specifically disabled in the integration configuration.
        <br/><br/>
    </>
})
