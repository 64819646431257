import {register} from 'dna-react-ioc'
import {TResetPasswordFailureStore} from '@/pages/ResetPasswordFailure'
import {ResetPasswordFailureStoreSymbol} from '@/pages/ResetPasswordFailure/ResetPasswordFailure'
import {ResetPasswordFailureStore} from '@/stores/reset-password-failure/ResetPasswordFailureStore'
import {TResetPasswordStore} from '@/pages/ResetPassword'
import {ResetPasswordStoreSymbol} from '@/pages/ResetPassword/ResetPassword'
import {ConfirmEmailStore, PasswordRecoveryStore, SetPasswordStore} from '@/stores'
import {TConfirmEmailStore} from '@/pages/ConfirmEmail'
import {ConfirmEmailStoreSymbol} from '@/pages/ConfirmEmail/ConfirmEmail'
import {TSetPasswordStore} from '@/pages/SetPassword'
import {SetPasswordStoreSymbol} from '@/pages/SetPassword/SetPassword'

export const resetPasswordState = () => {
    register<TResetPasswordStore>(ResetPasswordStoreSymbol).to(PasswordRecoveryStore).inSingletonScope()
    register<TConfirmEmailStore>(ConfirmEmailStoreSymbol).to(ConfirmEmailStore).inSingletonScope()
    register<TSetPasswordStore>(SetPasswordStoreSymbol).to(SetPasswordStore).inSingletonScope()
    register<TResetPasswordFailureStore>(ResetPasswordFailureStoreSymbol).to(ResetPasswordFailureStore).inSingletonScope()
}
