import {Language} from '@/components/dumb/Translation/'
import appTranslations from '@/translations'

type LanguageItemType = {
    label: string
    value: Language
}

export const languages: LanguageItemType[] = [
    {
        label: appTranslations().languages.english,
        value: 'en'
    },
    {
        label: appTranslations().languages.spanish,
        value: 'es'
    },
    {
        label: appTranslations().languages.greek,
        value: 'el'
    },
    {
        label: appTranslations().languages.romanian,
        value: 'ro'
    },
    {
        label: appTranslations().languages.slovak,
        value: 'sl'
    }
]
