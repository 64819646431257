import translations from './translations'

translations.add('sl', {
    labels: {
        title: 'Spracovať novú platbu',
        merchantCustomData: 'Vlastné údaje obchodníka'
    },
    buttons: {
        ok: 'Ok'
    }
})
