import {ReactNode} from 'react'
import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    columns: string
    cancel: string
    ok: string
    all: (number) => string,
    custom: string
    successExportMessage: ReactNode
}>()

