import {injectable} from 'inversify'
import {action, makeObservable, observable, runInAction} from 'mobx'
import { ApiResponse } from 'back-connector'

import {openErrorNotification, openSuccessNotification} from '@/utils'
import { IExportsModalContainerStore } from '@/components/containers/transaction'
import translations from '@/translations'

@injectable()
export class ExportsModalContainerStore implements IExportsModalContainerStore {

    open = false
    isLoading = false

    constructor(
        public fieldOptions: Record<string, string>,
        public exportTransactions: (fields: string[]) => Promise<ApiResponse<any>>
    ) {
        makeObservable(this, {
            open: observable,
            isLoading: observable,
            fieldOptions: observable,

            setOpen: action.bound,
            submit: action.bound
        })
    }


    setOpen(open: boolean) {
        this.open = open
    }

    async submit(fields: string[]) {
        try {
            runInAction(() => this.isLoading = true)
            const {error} = await this.exportTransactions(fields)
            if (error) throw new Error(error.message)
            openSuccessNotification(translations().messages.successExportMessage)
            runInAction(() => this.isLoading = false)
            return true
        } catch (error) {
            openErrorNotification(error?.message)
            runInAction(() => this.isLoading = false)
            return false
        }
    }
}
