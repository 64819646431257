import {SelectItem} from '@/components/dumb/Select'
import {Role} from './Role'
import translations from '../translations'
import {TeammateStatus} from '@/stores/team-management/constants/TeammateStatus'

export const teamManagementAllTab = [
    {
        title: translations().tabs.all,
        key: TeammateStatus.ok
    }
]

export const roleSelectOptions: SelectItem[] = [
    ...Object.keys(Role).map(key => ({
        value: Role[key],
        label: translations().roles[key]
    }))
]
