import {InvoiceStatus} from '@/stores/invoices/models/InvoiceStatus'
import translations from '../translations'

export const invoiceTabs = [
    {
        title: translations().all,
        key: InvoiceStatus.all
    },

    {
        title: translations().issued,
        key: InvoiceStatus.issued
    },
    {
        title: translations().paid,
        key: InvoiceStatus.paid
    }
]
