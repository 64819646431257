import {injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction} from 'mobx'
import {subscribe} from 'dna-react-ioc'

import {LoadingState} from '@/types'
import {TerminalType} from '@/constants'
import {
    convertStringToSelectItem,
    getUniqueValues,
    getWithAll,
    getWithAllForTwoLabels,
    isAllSelected
} from '@/utils'

import {AvailableStoresDataStore} from './AvailableStoresDataStore'
import {Way4StoresDataStore} from './Way4StoresDataStore'
import {Way4StoresDataStoreSymbol, AvailableStoresDataStoreSymbol} from '../symbols'
import {
    convertStoresToSelectItemWithTwoLabels,
    convertTerminalTypesToSelectItems,
    getStoreNameLocation
} from '../utils'

type Options = {
    terminalType?: TerminalType
    // in settlements ignored the user has access or not to the stores
    useAllData?: boolean
}

@injectable()
export class StoreMidTerminalFilterStore {
    protected _storesDataStore: AvailableStoresDataStore
    protected _way4StoresDataStore: Way4StoresDataStore

    selectedTerminalType: TerminalType = null
    /** store.name + '/' + store.location */
    selectedStore: string = null
    selectedMid: string = null
    selectedTerminal: string = null

    get _allData() {
        /** only in Settlements page used both terminal types. In other pages used only POS terminals */
        if (this.options.terminalType) {
            return this._way4StoresDataStore.data.filter(
                (el) => el.type === this.options.terminalType
            )
        }
        return this._way4StoresDataStore.data
    }

    /** store and terminals available for current user */
    get _availableData() {
        return this._allData.filter((el) => this._storesDataStore.mids.includes(el.mid))
    }

    get _data() {
        return this.options.useAllData ? this._allData : this._availableData
    }

    get _hasAccessToAllStores() {
        return this._allData.length === this._data.length
    }

    get _dataForStores() {
        return this._data.filter(
            (el) => !this.selectedTerminalType || el.type === this.selectedTerminalType
        )
    }

    get _dataForMids() {
        return this._dataForStores.filter(
            (el) => !this.selectedStore || this.selectedStore === getStoreNameLocation(el)
        )
    }

    get _dataForTerminals() {
        return this._dataForMids.filter((el) => !this.selectedMid || this.selectedMid === el.mid)
    }

    get terminalTypes() {
        return getUniqueValues(
            this._data.map((item) => item.type?.toLocaleLowerCase() as TerminalType)
        )
    }

    get mids() {
        return getUniqueValues(this._dataForMids.map((el) => el.mid))
    }

    get terminals() {
        return getUniqueValues(this._dataForTerminals.map((el) => el.number))
    }

    get terminalTypesAsSelectItemsWithAll() {
        return getWithAll(convertTerminalTypesToSelectItems(this.terminalTypes))
    }

    get storesAsSelectItemsWithAll() {
        return getWithAllForTwoLabels(convertStoresToSelectItemWithTwoLabels(this._dataForStores))
    }

    get midsAsSelectItemWithAll() {
        return getWithAll(convertStringToSelectItem(this.mids), {addIfOneItem: true})
    }

    get terminalsAsSelectItemWithAll() {
        return getWithAll(convertStringToSelectItem(this.terminals), {addIfOneItem: true})
    }

    /** wait until all needed data will be fetched */
    get loadingState() {
        const state1 = this._storesDataStore.initialLoadingState
        const state2 = this._way4StoresDataStore.loadingState

        if (state1 === state2) {
            return state1
        }

        return LoadingState.LOADING
    }

    get isLoading() {
        return this.loadingState === LoadingState.LOADING
    }

    get defaultTerminalType() {
        if (this.options.terminalType) {
            return this.options.terminalType
        }
        if (this.terminalTypesAsSelectItemsWithAll.length === 1) {
            return this.terminalTypesAsSelectItemsWithAll[0].value as TerminalType
        }
        return null
    }

    /** store.name + '/' + store.location */
    get defaultStore() {
        if (this.storesAsSelectItemsWithAll.length === 1) {
            return this.storesAsSelectItemsWithAll[0].value
        }
        return null
    }

    /**
     * Utilized exclusively on the POS Payments page.
     * If we can send the MID, we do not send terminal IDs for optimization purposes.
     * This is particularly relevant for Instavolt, where a single MID may encompass a large number of terminals.
     * Filtering with multiple terminal IDs on the server side may not be effective compared to filtering with just one MID.
     */
    get midsForServer() {
        if (this.selectedMid) {
            return [this.selectedMid]
        }

        const isTerminalTypeSelected =
            this.selectedTerminalType && this.selectedTerminalType !== this.defaultTerminalType

        const isStoreSelected = this.selectedStore && this.selectedStore !== this.defaultStore

        if (this._hasAccessToAllStores && !isTerminalTypeSelected && !isStoreSelected) {
            return null
        }

        return this.mids
    }

    get requestForServer() {
        if (this.selectedTerminal) {
            return {terminalId: [this.selectedTerminal]}
        }

        if (this.midsForServer?.length > 0) {
            return {mids: this.midsForServer}
        }

        return {}
    }

    constructor(protected options: Options) {
        this.selectedTerminalType = options.terminalType || null

        this._storesDataStore = subscribe<AvailableStoresDataStore>(AvailableStoresDataStoreSymbol)
        this._way4StoresDataStore = subscribe<Way4StoresDataStore>(Way4StoresDataStoreSymbol)

        makeObservable(this, {
            selectedTerminalType: observable,
            selectedStore: observable,
            selectedMid: observable,
            selectedTerminal: observable,

            _allData: computed,
            _availableData: computed,
            _data: computed,
            _hasAccessToAllStores: computed,
            _dataForStores: computed,
            _dataForMids: computed,
            _dataForTerminals: computed,

            terminalTypes: computed,
            mids: computed,
            terminals: computed,
            terminalTypesAsSelectItemsWithAll: computed,
            storesAsSelectItemsWithAll: computed,
            midsAsSelectItemWithAll: computed,
            terminalsAsSelectItemWithAll: computed,

            isLoading: computed,
            loadingState: computed,
            defaultStore: computed,
            requestForServer: computed,

            setTerminalType: action.bound,
            setStore: action.bound,
            setMid: action.bound,
            setTerminal: action.bound
        })

        if (this.loadingState === LoadingState.DONE) {
            this.init()
        } else {
            reaction(
                () => ({
                    defaultStore: this.defaultStore,
                    defaultTerminalType: this.defaultTerminalType
                }),
                () => this.init()
            )
        }
    }

    init() {
        if (!this.selectedTerminalType && this.defaultTerminalType) {
            this.setTerminalType(this.defaultTerminalType)
        } else if (!this.selectedStore && this.defaultStore) {
            this.setStore(this.defaultStore)
        }
    }

    setTerminalType(terminalType: TerminalType) {
        // TODO: using isAllSelected due to in old code used 'all'
        this.selectedTerminalType = isAllSelected(terminalType) ? null : terminalType
        this.setStore(this.defaultStore)
    }

    setStore(store: string) {
        // TODO: using isAllSelected due to in old code used 'All/All'
        this.selectedStore = isAllSelected(store) ? null : store
        this.setMid(null)
    }

    setMid(mid: string) {
        // TODO: using isAllSelected due to in old code used ''
        this.selectedMid = isAllSelected(mid) ? null : mid
        this.setTerminal(null)
    }

    setTerminal(terminal: string) {
        // TODO: using isAllSelected due to in old code used 'all'
        this.selectedTerminal = isAllSelected(terminal) ? null : terminal
    }

    clear() {
        this.setTerminalType(this.defaultTerminalType)
        this.setStore(this.defaultStore)
        this.setMid(null)
        this.setTerminal(null)
    }
}
