import translations from './translations'

translations.add('es', {
    recipientName: 'Nombre del remitente',
    recipientNumber: 'Número de teléfono',
    recipientsNumber: '\'s Número de teléfono',
    recipientEmail: 'Dirección de correo electrónico',
    recipientsEmail: '\'s Dirección de correo electrónico',
    errors: {
        recipientNumberRequired: 'Número de destinatario requerido',
        recipientNumberInvalid: 'Teléfono del destinatario no válido',
        recipientEmailRequired: 'Correo electronico requerido',
        recipientEmailInvalid: 'Correo electrónico no válido',
        recipientNameRequired: 'Nombre del remitente requerido'
    }
})
