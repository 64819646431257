import React, {useEffect, useState} from 'react'
import classNames from 'classnames'
import {Divider, Form, Input, Popover, Space} from 'antd'
import {error} from 'dna-common'

import {Button} from '@/components/dumb/Button'
import translations from './translations'
import {SendReceiptProps} from './props'
import styles from './styles.scss'
import {CANCEL_RECEIPT, CONFIRM_SEND_RECEIPT, EMAIL_SEND_VT_RECEIPT} from '@/constants/playwright-ids'

const {useForm} = Form

export const SendReceipt: React.FC<SendReceiptProps> = (props) => {
    const {email, className, placement, sendReceipt} = props
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [form] = useForm()

    const submit = async (values) => {
        setIsLoading(true)
        try {
            await form.validateFields()
            close()
            await sendReceipt(values.email)
        } catch (err) {
            error(err)
        }
        setIsLoading(false)
    }

    const close = () => {
        setIsOpen(false)
        form.resetFields()
    }

    useEffect(() => {
        form.setFieldsValue({
            email
        })
    }, [form, email])

    return <Popover
        title={translations().buttons.sendReceipt}
        overlayClassName={styles.root}
        open={isOpen}
        trigger={'click'}
        placement={placement || 'bottomRight'}
        onOpenChange={(open) => open ? setIsOpen(open) : close()}
        content={(
            <Form
                form={form}
                layout="inline"
                onFinish={submit}
                hideRequiredMark={true}
                initialValues={{email}}
            >
                <Form.Item
                    name="email"
                    data-test-id = {EMAIL_SEND_VT_RECEIPT}
                    label={translations().labels.email}
                    rules={[
                        {
                            type: 'email',
                            message: translations().errors.emailInvalid
                        },
                        {
                            required: true,
                            message: translations().errors.emailRequired
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Divider/>

                <Space>
                    <Button onClick={close} data-test-id={CANCEL_RECEIPT}>{translations().buttons.cancel}</Button>
                    <Button type="primary" htmlType="submit" data-test-id={CONFIRM_SEND_RECEIPT}>{translations().buttons.send}</Button>
                </Space>
            </Form>
        )}
    >
        <Button className={classNames(className, 'nowrap')} loading={isLoading}>
            {translations().buttons.sendReceipt}
        </Button>
    </Popover>
}
