import React from 'react'
import classNames from 'classnames'
import {EcospendBlock, EcospendField} from '..'
import ecospendTranslations from '../../translations'
import translations from './translations'
import {EcospendPayeeBlockProps} from './props'
import styles from './EcospendPayeeBlock.scss'

export const EcospendPayeeBlock: React.FC<EcospendPayeeBlockProps> = ({payee, className}) => (
    <div className={classNames(styles.ecospendPayeeBlock, className)}>
        <EcospendBlock className={styles.block}>
            <EcospendField label={translations().payee}>
                {payee || ecospendTranslations().unknown}
            </EcospendField>
        </EcospendBlock>
        <EcospendBlock className={styles.block}>
            <EcospendField label={translations().processor}>
                {translations().dnaPayments}
            </EcospendField>
        </EcospendBlock>
    </div>
)
