export const POSPaymentsStoreSymbol = Symbol('POSPaymentsStore')
export const POSPaymentsTabsStoreSymbol = Symbol('POSPaymentsTabsStore')
export const POSPaymentsFilterStoreSymbol = Symbol('POSPaymentsFilterStore')
export const POSPaymentsStatusSelectStoreSymbol = Symbol('POSPaymentsStatusSelectStore')
export const POSPaymentsRangePickerStoreSymbol = Symbol('POSPaymentsRangePickerStore')
export const POSPaymentsPaginationStoreSymbol = Symbol('POSPaymentsPaginationStore')
export const POSPaymentsDrawerStoreSymbol = Symbol('POSPaymentsDrawerStore')
export const POSPaymentsExportsStoreSymbol = Symbol('POSPaymentsExportsStore')
export const POSPaymentsOperationModalContainerStoreSymbol = Symbol(
    'POSPaymentsOperationModalContainerStore'
)
export const POSPaymentsOperationStoreSymbol = Symbol('POSPaymentsOperationStore')
