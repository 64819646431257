import {interfaces} from 'inversify'
import {register} from 'dna-react-ioc'

import {
    getCardSchemeLabel,
    getOnlineCardSchemeLabel,
    getPaymentMethodTypeLabel,
    getPortalCurrencies
} from '@/constants'
import {
    ClientsDoughnutChartStore,
    RangePickerStore,
    ReportsStore,
    SelectContainerStore,
    ECOMTransactionSummaryDoughnutChartStore,
    ECOMTransactionSummaryStore,
    POSTransactionSummaryStore,
    POSTransactionSummaryDoughnutChartStore,
    PeriodType,
    ECOMTransactionStatuses,
    POSTransactionStatuses,
    AuthStoreSymbol
} from '@/stores'
import {
    ClientsStoreSymbol,
    CMStatusStoreSymbol,
    CMStoreSymbol,
    DoughnutChartType,
    EcomTransactionSummaryDoughnutChartCardStoreType,
    IssuerStatusStoreSymbol,
    IssuerStoreSymbol,
    PMStatusStoreSymbol,
    PMStoreSymbol,
    POSCardSchemeStatusStoreSymbol,
    POSCardSchemeStoreSymbol,
    POSIssuerStatusStoreSymbol,
    POSIssuerStoreSymbol,
    PosTransactionSummaryDoughnutChartCardStoreType,
    TClientsDoughnutChartStore
} from '@/pages/Reports/components'
import {
    TRangePickerContainerStore,
    TransactionsCardWithBarChartContainerStoreSymbol,
    TransactionsComparisonCardWithBarChartContainerStoreSymbol,
    TSelectContainerStore,
    TTransactionsCardWithBarChartContainerStore,
    TTransactionsComparisonCardWithBarChartContainerStore,
    TTransactionSummaryContainerStore
} from '@/components'
import {
    ReportsECOMTopSummaryStoreSymbol,
    ReportsPOSRangePickerStoreSymbol,
    ReportsPOSTopSummaryStoreSymbol,
    ReportsECOMRangePickerStoreSymbol,
    ReportsStoreSymbol,
    TReportsStore,
    ReportsPOSTransactionsCardWithBarChartContainerStoreSymbol,
    ReportsPOSTransactionsComparisonCardWithBarChartContainerStoreSymbol,
    ReportsPOSCurrencySelectStoreSymbol,
    ReportsECOMCurrencySelectStoreSymbol,
    ReportsEcomStoreSelectStoreSymbol,
    ReportsPosStoreSelectStoreSymbol,
    ReportsEcomTransactionsStatusStoreSymbol
} from '@/pages/Reports'
import {ECOMTransactionsCardWithBarChartContainerStore} from '@/stores/reports/TransactionsCardWithBarChartContainerStore/ECOMTransactionsCardWithBarChartContainerStore'
import {ECOMTransactionsComparisonCardWithBarChartContainerStore} from '@/stores/reports/TransactionsComparisonCardWithBarChartContainerStore/ECOMTransactionsComparisonCardWithBarChartContainerStore'
import {POSTransactionsCardWithBarChartContainerStore} from '@/stores/reports/POSTransactionsCardWithBarChartContainerStore/POSTransactionsCardWithBarChartContainerStore'
import {POSTransactionsComparisonCardWithBarChartContainerStore} from '@/stores/reports/POSTransactionsComparisonCardWithBarChartContainerStore/POSTransactionsComparisonCardWithBarChartContainerStore'
import {EcomStoreSelectStore, PosStoreSelectStore} from '@/stores/store-and-terminals'
import {convertEcomStatusesToSelectItems, convertPosStatesToSelectItems} from '@/utils/transaction'

export const reportsState = () => {
    /*  filter stores */

    register<TRangePickerContainerStore>(ReportsECOMRangePickerStoreSymbol)
        .toDynamicValue(() => new RangePickerStore({period: PeriodType.Day}))
        .inSingletonScope()

    register<TRangePickerContainerStore>(ReportsPOSRangePickerStoreSymbol)
        .toDynamicValue(() => new RangePickerStore({period: PeriodType.Day}))
        .inSingletonScope()

    register<TSelectContainerStore>(ReportsECOMCurrencySelectStoreSymbol)
        .toDynamicValue(() => new SelectContainerStore({options: getPortalCurrencies}))
        .inSingletonScope()

    register<TSelectContainerStore>(ReportsPOSCurrencySelectStoreSymbol)
        .toDynamicValue(() => new SelectContainerStore({options: getPortalCurrencies}))
        .inSingletonScope()

    register<EcomStoreSelectStore>(ReportsEcomStoreSelectStoreSymbol)
        .to(EcomStoreSelectStore)
        .inSingletonScope()

    register<PosStoreSelectStore>(ReportsPosStoreSelectStoreSymbol)
        .to(PosStoreSelectStore)
        .inSingletonScope()

    register<TSelectContainerStore>(ReportsEcomTransactionsStatusStoreSymbol)
        .toDynamicValue(
            () =>
                new SelectContainerStore({
                    value: ECOMTransactionStatuses[0],
                    options: convertEcomStatusesToSelectItems(ECOMTransactionStatuses)
                })
        )
        .inSingletonScope()

    /* data stores */

    register<TTransactionSummaryContainerStore>(ReportsECOMTopSummaryStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new ECOMTransactionSummaryStore(
                    'reports.ECOMTransactionSummaryStore',
                    context.container.get(ReportsECOMRangePickerStoreSymbol),
                    context.container.get(ReportsECOMCurrencySelectStoreSymbol),
                    context.container.get(ReportsEcomStoreSelectStoreSymbol)
                )
        )
        .inSingletonScope()

    register<TTransactionSummaryContainerStore>(ReportsPOSTopSummaryStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new POSTransactionSummaryStore(
                    'reports.POSTransactionSummaryStore',
                    context.container.get(ReportsPOSRangePickerStoreSymbol),
                    context.container.get(ReportsPOSCurrencySelectStoreSymbol),
                    context.container.get(ReportsPosStoreSelectStoreSymbol)
                )
        )
        .inSingletonScope()

    register<TSelectContainerStore>(PMStatusStoreSymbol)
        .toDynamicValue(
            () =>
                new SelectContainerStore({
                    value: ECOMTransactionStatuses[0],
                    options: convertEcomStatusesToSelectItems(ECOMTransactionStatuses)
                })
        )
        .inSingletonScope()

    register<TSelectContainerStore>(CMStatusStoreSymbol)
        .toDynamicValue(
            () =>
                new SelectContainerStore({
                    value: ECOMTransactionStatuses[0],
                    options: convertEcomStatusesToSelectItems(ECOMTransactionStatuses)
                })
        )
        .inSingletonScope()

    register<TSelectContainerStore>(IssuerStatusStoreSymbol)
        .toDynamicValue(
            () =>
                new SelectContainerStore({
                    value: ECOMTransactionStatuses[0],
                    options: convertEcomStatusesToSelectItems(ECOMTransactionStatuses)
                })
        )
        .inSingletonScope()

    register<TSelectContainerStore>(POSCardSchemeStatusStoreSymbol)
        .toDynamicValue(
            () =>
                new SelectContainerStore({
                    value: POSTransactionStatuses[0],
                    options: convertPosStatesToSelectItems(POSTransactionStatuses)
                })
        )
        .inSingletonScope()

    register<TSelectContainerStore>(POSIssuerStatusStoreSymbol)
        .toDynamicValue(
            () =>
                new SelectContainerStore({
                    value: POSTransactionStatuses[0],
                    options: convertPosStatesToSelectItems(POSTransactionStatuses)
                })
        )
        .inSingletonScope()

    register<EcomTransactionSummaryDoughnutChartCardStoreType>(PMStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new ECOMTransactionSummaryDoughnutChartStore(
                    context.container.get(ReportsECOMRangePickerStoreSymbol),
                    context.container.get(PMStatusStoreSymbol),
                    context.container.get(ReportsECOMCurrencySelectStoreSymbol),
                    DoughnutChartType.PaymentMethod,
                    context.container.get(ReportsEcomStoreSelectStoreSymbol),
                    getPaymentMethodTypeLabel
                )
        )
        .inSingletonScope()

    register<EcomTransactionSummaryDoughnutChartCardStoreType>(CMStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new ECOMTransactionSummaryDoughnutChartStore(
                    context.container.get(ReportsECOMRangePickerStoreSymbol),
                    context.container.get(CMStatusStoreSymbol),
                    context.container.get(ReportsECOMCurrencySelectStoreSymbol),
                    DoughnutChartType.CardScheme,
                    context.container.get(ReportsEcomStoreSelectStoreSymbol),
                    getOnlineCardSchemeLabel
                )
        )
        .inSingletonScope()

    register<EcomTransactionSummaryDoughnutChartCardStoreType>(IssuerStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new ECOMTransactionSummaryDoughnutChartStore(
                    context.container.get(ReportsECOMRangePickerStoreSymbol),
                    context.container.get(IssuerStatusStoreSymbol),
                    context.container.get(ReportsECOMCurrencySelectStoreSymbol),
                    DoughnutChartType.Issuer,
                    context.container.get(ReportsEcomStoreSelectStoreSymbol)
                )
        )
        .inSingletonScope()

    register<PosTransactionSummaryDoughnutChartCardStoreType>(POSCardSchemeStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new POSTransactionSummaryDoughnutChartStore(
                    context.container.get(ReportsPOSRangePickerStoreSymbol),
                    context.container.get(POSCardSchemeStatusStoreSymbol),
                    context.container.get(ReportsPOSCurrencySelectStoreSymbol),
                    DoughnutChartType.CardScheme,
                    context.container.get(ReportsPosStoreSelectStoreSymbol),
                    getCardSchemeLabel
                )
        )
        .inSingletonScope()

    register<PosTransactionSummaryDoughnutChartCardStoreType>(POSIssuerStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new POSTransactionSummaryDoughnutChartStore(
                    context.container.get(ReportsPOSRangePickerStoreSymbol),
                    context.container.get(POSIssuerStatusStoreSymbol),
                    context.container.get(ReportsPOSCurrencySelectStoreSymbol),
                    DoughnutChartType.Issuer,
                    context.container.get(ReportsPosStoreSelectStoreSymbol)
                )
        )
        .inSingletonScope()

    register<TTransactionsCardWithBarChartContainerStore>(
        ReportsPOSTransactionsCardWithBarChartContainerStoreSymbol
    )
        .toDynamicValue(
            (context: interfaces.Context) =>
                new POSTransactionsCardWithBarChartContainerStore(
                    'reports.POSTransactionsCardWithBarChartContainerStore',
                    context.container.get(AuthStoreSymbol),
                    context.container.get(ReportsPOSRangePickerStoreSymbol),
                    context.container.get(ReportsPOSCurrencySelectStoreSymbol),
                    false,
                    context.container.get(
                        ReportsPOSTransactionsComparisonCardWithBarChartContainerStoreSymbol
                    ),
                    context.container.get(ReportsPosStoreSelectStoreSymbol)
                )
        )
        .inSingletonScope()

    register<TTransactionsCardWithBarChartContainerStore>(
        TransactionsCardWithBarChartContainerStoreSymbol
    )
        .toDynamicValue(
            (context: interfaces.Context) =>
                new ECOMTransactionsCardWithBarChartContainerStore(
                    context.container.get(AuthStoreSymbol),
                    context.container.get(ReportsECOMRangePickerStoreSymbol),
                    context.container.get(ReportsECOMCurrencySelectStoreSymbol),
                    false,
                    context.container.get(
                        TransactionsComparisonCardWithBarChartContainerStoreSymbol
                    ),
                    context.container.get(ReportsEcomStoreSelectStoreSymbol),
                    context.container.get(ReportsEcomTransactionsStatusStoreSymbol)
                )
        )
        .inSingletonScope()

    register<TTransactionsComparisonCardWithBarChartContainerStore>(
        ReportsPOSTransactionsComparisonCardWithBarChartContainerStoreSymbol
    )
        .toDynamicValue((context: interfaces.Context) => {
            const posReportsTransactionsComparisonCardWithBarChartStore =
                new POSTransactionsComparisonCardWithBarChartContainerStore(
                    context.container.get(AuthStoreSymbol),
                    context.container.get(ReportsPOSRangePickerStoreSymbol),
                    context.container.get(ReportsPOSCurrencySelectStoreSymbol),
                    context.container.get(ReportsPosStoreSelectStoreSymbol)
                )

            return posReportsTransactionsComparisonCardWithBarChartStore
        })
        .inSingletonScope()

    register<TTransactionsComparisonCardWithBarChartContainerStore>(
        TransactionsComparisonCardWithBarChartContainerStoreSymbol
    )
        .toDynamicValue((context: interfaces.Context) => {
            const ecomReportsTransactionsComparisonCardWithBarChartStore =
                new ECOMTransactionsComparisonCardWithBarChartContainerStore(
                    context.container.get(AuthStoreSymbol),
                    context.container.get(ReportsECOMRangePickerStoreSymbol),
                    context.container.get(ReportsECOMCurrencySelectStoreSymbol),
                    context.container.get(ReportsEcomStoreSelectStoreSymbol),
                    context.container.get(ReportsEcomTransactionsStatusStoreSymbol)
                )

            return ecomReportsTransactionsComparisonCardWithBarChartStore
        })
        .inSingletonScope()

    register<TClientsDoughnutChartStore>(ClientsStoreSymbol)
        .to(ClientsDoughnutChartStore)
        .inSingletonScope()

    register<TReportsStore>(ReportsStoreSymbol).to(ReportsStore).inSingletonScope()
}
