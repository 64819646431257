import translations from './translations'

translations.add('el', {
    labels: {
        transactionType: 'Τύπος συναλλαγής'
    },
    warnings: {
        verification: 'Η επαλήθευση του λογαριασμού θα πραγματοποιήσει μια συναλλαγή 0 (μηδενικού) ποσού για την επαλήθευση των στοιχείων της κάρτας.'
    },
    errors: {
        transactionType: 'transaction type is required'
    },
    placeholders: {
        transactionType: 'Τύπος συναλλαγής'
    }
})
