import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    noTerminalsToConfigure: string
    terminals: (s: string) => string
    allActive: string
    allDisabled: string
    active: (s: string) => string
}>()

