import React, {useCallback, useEffect} from 'react'
import moment from 'moment'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Checkbox, Col, DatePicker, Form, Grid, Radio, Row} from 'antd'
import {isEmpty} from 'dna-common'

import {
    AmountCurrencyFormField,
    IconTitle,
    Input,
    MobileBackButton,
    ModalFormFooter,
    OrderNumberFormField,
    Select,
    TransactionTypeFormField
} from '@/components'
import {ModalContainer, TModalContainerStore} from '@/components/containers'
import {disabledDateBeforeNow, disabledTimeBeforeNow, generateInvoiceId} from '@/utils'
import {getExpirationDateByPreset} from '@/services/payment-link'
import {CreatePaymentLinkViewType} from '@/api'
import {LoadingState} from '@/types'
import {DATE_TIME_FORMAT, DEFAULT_CURRENCY, GoogleManagerTagClassNames} from '@/constants'
import {NewPaymentLinkWidgetPreview, TNewPaymentLinkModalStore} from '@/pages/components'
import {ExpirationDateType} from '@/stores/pay-by-link/models/ExpirationDateType'
import {MerchantTerminalDataType} from '@/stores/pay-by-link/models/MerchantTerminalDataType'
import {getNewPaymentLinkTransactionTypes} from '@/utils/transactions'
import {PayByLinkCreateNewLinkStoreSymbol} from '@/pages/PayByLink'
import {TransactionType} from '@/types/transactions'

import translations from './translations'
import {NewPaymentLinkModalProps} from './props'
import styles from './styles.scss'
import {
    FOURTY_EIGHT_HOURS_EXPIRE_DATE,
    MONTH_EXPIRE_DATE,
    NEW_PAYMENT_CUSTOMER_NAME,
    NEW_PAYMENT_DESCRIPTION,
    NEW_PAYMENT_EXPIRATION_DATE,
    NEW_PAYMENT_EXPIRATION_DATE_TYPES, NEW_PAYMENT_RECURRING,
    NEW_PAYMENT_STORES,
    SELECT_TRANSACTION_TYPE,
    TWENTY_FOUR_HOURS_EXPIRE_DATE,
    WEEK_EXPIRE_DATE
} from '@/constants/playwright-ids'

export const NewPaymentLinkModal: React.FC<NewPaymentLinkModalProps> = observer(
    ({injectableIdentifier}: NewPaymentLinkModalProps) => {
        const screens = Grid.useBreakpoint()
        const isMobile = screens.xs
        const modalStore = useInjection<TModalContainerStore>(injectableIdentifier)
        const store = useInjection<TNewPaymentLinkModalStore>(PayByLinkCreateNewLinkStoreSymbol)
        const [form] = Form.useForm()
        const formId = 'newPaymentLinkForm'

        const isVerification = () =>
            form.getFieldValue('transactionType') === TransactionType.VERIFICATION

        const getTerminalData = useCallback(
            (terminalId = form.getFieldValue('terminalId')): MerchantTerminalDataType => {
                const terminal = store.terminals.find((t) => t.value === terminalId)?.data

                return (
                    terminal || {
                        defaultCurrency: DEFAULT_CURRENCY,
                        supportedCurrencies: [DEFAULT_CURRENCY],
                        card: {}
                    }
                )
            },
            [form, store.terminals]
        )

        const resetForm = () => {
            form.resetFields()
            store.reset()
            store.closeModals()
        }

        const onFinish = async (values: CreatePaymentLinkViewType) => {
            const {expirationDatePreset, ...rest} = values
            await store.createNewPaymentLink(rest)

            form.resetFields()
            store.reset()
        }

        const handleReloadClick = (invoiceId: string) => form.setFieldsValue({invoiceId})

        useEffect(() => {
            if (modalStore.open && store.terminals.length === 1) {
                const terminalId = store.terminals[0].value
                form.setFieldsValue({
                    terminalId
                })
            }
        }, [form, store.terminals, modalStore.open])

        useEffect(() => {
            if (modalStore.open && !isEmpty(store.paymentLink)) {
                const {amount, currency, customerName, description} = store.paymentLink

                form.setFieldsValue({
                    amount,
                    currency,
                    customerName,
                    description
                })
            }
        }, [form, store.paymentLink, modalStore.open])

        return (
            <ModalContainer
                className={styles.modal}
                title={translations().labels.newPaymentLink}
                injectableIdentifier={injectableIdentifier}
                width={'900px'}
                closable={!isMobile}
                onCancel={resetForm}
                footer={
                    <ModalFormFooter
                        okDataClassName={GoogleManagerTagClassNames.header.createPaymentLink}
                        formId={formId}
                        onCancel={resetForm}
                        cancelText={translations().buttons.close}
                        confirmLoading={store.loadingState === LoadingState.LOADING}
                        okText={translations().buttons.createNewLink}
                    />
                }
            >
                {isMobile && <MobileBackButton onCancel={resetForm} />}

                <Row gutter={[32, 16]}>
                    <Col xs={24} md={12}>
                        <Form
                            id={formId}
                            form={form}
                            initialValues={{
                                invoiceId: generateInvoiceId(),
                                currency: DEFAULT_CURRENCY
                            }}
                            layout={'vertical'}
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            onFinish={onFinish}
                            autoComplete='off'
                            onValuesChange={(changedValues) => {
                                if (changedValues.expirationDatePreset) {
                                    form.setFieldValue(
                                        'expirationDate',
                                        getExpirationDateByPreset(
                                            changedValues.expirationDatePreset
                                        )
                                    )
                                }

                                if (changedValues.terminalId) {
                                    const terminalData = getTerminalData()
                                    const currency = form.getFieldValue('currency')

                                    form.setFieldsValue({
                                        recurring: terminalData.card?.allowPblRecurring
                                            ? terminalData.card?.pblRecurringByDefault
                                            : false,
                                        currency: terminalData.supportedCurrencies.includes(
                                            currency
                                        )
                                            ? currency
                                            : terminalData.defaultCurrency
                                    })
                                }
                            }}
                        >
                            <Form.Item
                                hidden={store.terminals?.length < 2}
                                label={translations().labels.stores}
                                name='terminalId'
                                rules={[
                                    {
                                        required: true,
                                        message: translations().errors.storeRequired
                                    }
                                ]}
                            >
                                <Select
                                    placeholder={translations().labels.stores}
                                    options={store.terminals}
                                    data-test-id={NEW_PAYMENT_STORES}
                                />
                            </Form.Item>

                            <OrderNumberFormField onReloadClick={handleReloadClick} />

                            <Form.Item shouldUpdate noStyle>
                                {() =>
                                    getTerminalData().card?.pblTransactionTypeSelection && (
                                        <TransactionTypeFormField
                                            data-test-id={SELECT_TRANSACTION_TYPE}
                                            form={form}
                                            transactionTypes={getNewPaymentLinkTransactionTypes()}
                                        />
                                    )
                                }
                            </Form.Item>

                            <Form.Item shouldUpdate noStyle>
                                {() => (
                                    <Form.Item
                                        hidden={!getTerminalData().card?.allowPblRecurring}
                                        name='recurring'
                                        valuePropName='checked'
                                        data-test-id={NEW_PAYMENT_RECURRING}
                                    >
                                        <Checkbox>
                                            <IconTitle
                                                title={translations().labels.recurring}
                                                tooltipText={translations().tooltips.recurring}
                                            />
                                        </Checkbox>
                                    </Form.Item>
                                )}
                            </Form.Item>

                            <Form.Item shouldUpdate noStyle>
                                {() => (
                                    <AmountCurrencyFormField
                                        currencies={getTerminalData().supportedCurrencies}
                                        form={form}
                                        inputProps={{
                                            disabled: isVerification()
                                        }}
                                        shouldAcceptZero={isVerification()}
                                    />
                                )}
                            </Form.Item>

                            <Form.Item
                                label={translations().labels.customerName}
                                name='customerName'
                                rules={[
                                    {
                                        required: true,
                                        message: translations().errors.customerNameRequired
                                    }
                                ]}
                                data-test-id={NEW_PAYMENT_CUSTOMER_NAME}
                            >
                                <Input maxLength={45} placeholder={translations().labels.name} />
                            </Form.Item>

                            <Form.Item
                                label={translations().labels.description}
                                name='description'
                                rules={[
                                    {
                                        required: true,
                                        message: translations().errors.descriptionRequired
                                    }
                                ]}
                                data-test-id={NEW_PAYMENT_DESCRIPTION}
                            >
                                <Input
                                    maxLength={512}
                                    placeholder={
                                        translations().labels.nameOfTheServiceOrItemYouProvide
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label={translations().labels.linkExpiration}
                                name='expirationDate'
                                rules={[
                                    {
                                        required: true,
                                        message: translations().errors.linkExpirationRequired
                                    }
                                ]}
                                data-test-id={NEW_PAYMENT_EXPIRATION_DATE}
                            >
                                <DatePicker
                                    format={DATE_TIME_FORMAT}
                                    showTime={{defaultValue: moment().add(15, 'minutes')}}
                                    placeholder={translations().selectDate}
                                    showNow={false}
                                    showSecond={false}
                                    disabledDate={disabledDateBeforeNow}
                                    disabledTime={disabledTimeBeforeNow}
                                    onSelect={() => form.resetFields(['expirationDatePreset'])}
                                    renderExtraFooter={() => (
                                        <Form.Item noStyle name='expirationDatePreset'>
                                            <Radio.Group
                                                className={styles.radio}
                                                data-test-id={NEW_PAYMENT_EXPIRATION_DATE_TYPES}
                                            >
                                                <Radio
                                                    value={ExpirationDateType.Hour24}
                                                    data-test-id={TWENTY_FOUR_HOURS_EXPIRE_DATE}
                                                >
                                                    {translations().ranges.hour24}
                                                </Radio>
                                                <Radio
                                                    value={ExpirationDateType.Hour48}
                                                    data-test-id={FOURTY_EIGHT_HOURS_EXPIRE_DATE}
                                                >
                                                    {translations().ranges.hour48}
                                                </Radio>
                                                <Radio
                                                    value={ExpirationDateType.Week1}
                                                    data-test-id={WEEK_EXPIRE_DATE}
                                                >
                                                    {translations().ranges.week1}
                                                </Radio>
                                                <Radio
                                                    value={ExpirationDateType.Month1}
                                                    data-test-id={MONTH_EXPIRE_DATE}
                                                >
                                                    {translations().ranges.month1}
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    )}
                                />
                            </Form.Item>
                        </Form>
                    </Col>

                    {!isMobile && (
                        <Col xs={24} md={12}>
                            <NewPaymentLinkWidgetPreview />
                        </Col>
                    )}
                </Row>
            </ModalContainer>
        )
    }
)
