import React from 'react'
import {Col, Form, Radio, Row} from 'antd'
import {Select, Text} from '@/components'
import styles from '@/pages/TeamManagement/components/InviteEditTeammate/styles.scss'

type PermissionFormItemType = {
    sectionName: string
    sectionCode: string
    accessLevels: {value: string, disabled?: boolean}[]
    onChange?: (sectionCode: string, permission: string) => void
    initialValue?: string
    disabled?: boolean
}
export const renderSectionPermissionsFormItem = (
    {
        sectionName, sectionCode, accessLevels, onChange, initialValue, disabled
    }: PermissionFormItemType
) => {
    return <Row>
        <Col sm={10}><Text>{sectionName}</Text></Col>
        <Col sm={14}>
            <Form.Item name={['permissions', sectionCode]} initialValue={initialValue || accessLevels[0].value}>
                <Radio.Group disabled={disabled} className={styles.radioGroup} onChange={(e) => onChange(sectionCode, e?.target?.value)}>
                    <Row>
                        {
                            accessLevels.map(item => {
                                return <Col key={item.value} sm={8}><Radio value={item.value} disabled={item.disabled}/></Col>
                            })
                        }
                    </Row>
                </Radio.Group>
            </Form.Item>
        </Col>
    </Row>
}

export const renderMobileSectionPermissionsFormItem = (sectionName: string, sectionCode: string, accessLevels: {label, value}[], onChange?, initialValue?) => {
    return <div>
        <Form.Item name={['permissions', sectionCode]} initialValue={initialValue || accessLevels[0].value} label={<Text>{sectionName}</Text>}>
            <Select options={accessLevels} onChange={(value) => onChange(sectionCode, value)}/>
        </Form.Item>
    </div>
}
