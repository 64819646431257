import translations from './translations'

translations.add('en', {
    calendarTitle: 'Custom',
    submit: 'Submit',
    period: 'Period',
    presets: {
        today: 'Today',
        yesterday: 'Yesterday',
        thisWeek: 'This week',
        lastWeek: 'Last week',
        last7Days: 'Last 7 days',
        thisMonth: 'This month',
        last30Days: 'Last 30 days',
        last60Days: 'Last 60 days',
        last90Days: 'Last 90 days',
        lastMonth: 'Last month',
        thisYear: 'This year',
        last12Month: 'Last 12 months'
    }
})
