import moment from 'moment'
import {DATE_FORMAT, OnlinePaymentStatus} from '@/constants'
import {TransactionType} from '@/types/transactions'
import {SelectItem} from '@/components/dumb/Select'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import translations from '@/translations'


export const getExportFileName = (
    startDate: moment.Moment,
    endDate: moment.Moment,
    merchantName: string,
    formatType = 'csv'
) => (
    `${merchantName} ${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}.${formatType}`
)


export const getFilterTransactionTypes = () => {
    const transactionTypes = hasPermissions([PermissionsMap.virtual_terminal.refunds])
        ? [TransactionType.SALE, TransactionType.AUTH, TransactionType.VERIFICATION, TransactionType.REFUND]
        : [TransactionType.SALE, TransactionType.AUTH, TransactionType.VERIFICATION]

    return transactionTypes.map((type) => ({value: type, label: translations().transactionTypes[type]})) as SelectItem[]
}


export const getProcessNewPaymentTransactionTypes = () => [TransactionType.SALE, TransactionType.AUTH]
    .map((type) => ({value: type, label: translations().transactionTypes[type]})) as SelectItem[]

export const getNewPaymentLinkTransactionTypes = () =>
    [TransactionType.SALE, TransactionType.AUTH, TransactionType.PREAUTH, TransactionType.VERIFICATION]
    .map((type) => ({value: type, label: translations().transactionTypes[type]})) as SelectItem[]

export const isFailureTransaction = (state: OnlinePaymentStatus) => {
    return [OnlinePaymentStatus.failed, OnlinePaymentStatus.reject, OnlinePaymentStatus.cancel].includes(state)
}

export const isSuccessTransaction = (state: OnlinePaymentStatus) => {
    return [OnlinePaymentStatus.charge, OnlinePaymentStatus.auth, OnlinePaymentStatus.refund].includes(state)
}
