import React from 'react'
import {Row, Typography} from 'antd'
import Joyride from 'react-joyride'
import {observer} from 'mobx-react'
import {Colors} from '@/constants'
import {Button, Modal} from '@/components'
import translations from './translations'
import styles from './styles.scss'
import {SKIP_USER_GUIDE, START_TUTORIAL} from '@/constants/playwright-ids'

export const UserGuide = observer((
    {
        steps, run, handleCallback, stepIndex, isModalVisible, onGotItClick, onSkipPress
    }
) => {
    return (
        <>
            <Joyride
                disableOverlay={true}
                scrollToFirstStep
                hideCloseButton
                callback={handleCallback}
                steps={steps}
                stepIndex={stepIndex}
                continuous
                run={run}
                showSkipButton
                disableScrolling
                locale={{
                    back: translations().back,
                    close: translations().close,
                    last: translations().close,
                    next: translations().next,
                    open: translations().open,
                    skip: translations().skip
                }}
                styles={{
                    options: {
                        arrowColor: Colors.LIGHT_BLUE,
                        backgroundColor: Colors.LIGHT_GREEN,
                        overlayColor: 'rgba(79, 26, 0, 0)',
                        primaryColor: Colors.WHITE,
                        textColor: Colors.WHITE,
                        width: 500,
                        zIndex: 1000
                    },
                    buttonNext: {
                        background: 'none'
                    },
                    buttonSkip: {
                        border: `1px solid ${Colors.WHITE}`,
                        borderRadius: 8,
                        fontSize: 14
                    },
                    tooltipTitle: {
                        fontSize: 18,
                        margin: '0 0 10px 0',
                        color: Colors.WHITE
                    },
                    buttonBack: {
                        display: 'none'
                    }
                }}
            />
            <Modal
                open={isModalVisible}
                className={styles.modal}
                onCancel={onSkipPress}
                footer={[
                    <Button
                        onClick={onSkipPress}
                        key={'skip'}
                        data-test-id={SKIP_USER_GUIDE}
                    >
                        {translations().skipTutorial}</Button>,
                    <Button
                        key={'go-next'}
                        type={'primary'}
                        onClick={onGotItClick}
                        data-test-id={START_TUTORIAL}
                    >{translations().startTutorial}</Button>
                ]}
            >
                <Row justify={'center'}>
                    <img className={styles.image} src={'/img/guide/welcome.svg'} alt={'Welcome'}/>
                </Row>
                <br/>
                <Row justify={'center'}>
                    <Typography.Title className={styles.title} level={3}>{translations().welcome}</Typography.Title>
                </Row>
                <Row justify={'center'}>
                    <Typography.Title level={3}>{translations().merchantPortal}</Typography.Title>
                </Row>
                <Row justify={'center'}>
                    <Typography.Text className={styles.description}>{translations().description1}</Typography.Text>
                </Row>
                <br/>
                <Row justify={'center'}>
                    <Typography.Text className={styles.description}>{translations().description2}</Typography.Text>
                </Row>
            </Modal>
        </>
    )
})
