import translations from './translations'

translations.add('en', {
    edit: 'Edit',
    share: 'Share',
    areYouSureYouWantToDeactivate: 'Are you sure you want to deactivate the payment link?',
    yes: 'Yes',
    no: 'No',
    createSimilar: 'Create new',
    deactivate: 'Deactivate'
})
