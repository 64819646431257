import React from 'react'
import classNames from 'classnames'
import {Icon, Tag} from '@/components'

import {StatusProps} from './props'
import styles from './style.scss'

export const Status: React.FC<StatusProps> = (props) => {
    const {status, type, className, iconClassName, iconType} = props

    return (
        <Tag type={type} className={classNames(styles.root, className)} data-test-id={`${status}`}>
            <Icon type={iconType} className={iconClassName} />
            <span className={styles.text}>{status}</span>
        </Tag>
    )
}
