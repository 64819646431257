import translations from './translations'

translations.add('sl', {
    all: 'Všetko',
    selectAll: 'Vyber všetko',
    deselectAll: 'Zrušiť výber všetkých',
    placeholder: 'Hľadaj tu...',
    noData: 'Žiadne údaje',
    selected: count => `Vybraný ${count}`
})
