import React from 'react'
import translations from './translations'

translations.add('el', {
    title: 'Συνδεθείτε στο λογαριασμό σας',
    selectEntity: 'Select your entity',
    ourSupportTeamIsHereToHelp: (message: string) => <>{message ? <>{message}<br/></> : <>Δεν αναγνωρίσαμε τα στοιχεία σύνδεσης που χρησιμοποιείτε. Παρακαλώ, ελέγξτε εάν χρησιμοποιείτε το email με το οποίο έχετε πραγματοποιήσει το onboarding. </>}

        Η ομάδα υποστήριξής μας είναι εδώ για να βοηθήσει: <a href={'https://dnapayments.com/online-quote#2'} target={'_blank'} rel={'noreferrer'}>Υποστήριξη</a></>,
    successfullyUpdatedDefaultMerchant: 'Ο προεπιλεγμένος έμπορος ενημερώθηκε με επιτυχία',
    sessionExpired: 'Η συνεδρία σας έχει λήξει. Παρακαλώ συνδεθείτε ξανά.',
    permissionErrorText: 'Δεν έχετε άδεια να συνδεθείτε',
    back: 'Επιστροφή στην σελίδα εισόδου'
})
