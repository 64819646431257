import translations from './translations'

translations.add('el', {
    acceptPolicies:
        'Κάνοντας κλικ σε αυτό το κουμπί συναινώ στο να μοιραστεί η πληροφορία μου με την PayPal για σκοπούς επιβίβασης',
    setUpPayPalAccount: 'Ρύθμιση λογαριασμού PayPal',
    warningMessage:
        'Μόλις κάνετε κλικ στο παραπάνω κουμπί, θα μεταφερθείτε στην ιστοσελίδα της PayPal σε νέα καρτέλα περιήγησης. Ακολουθήστε τις οδηγίες για να δημιουργήσετε έναν νέο λογαριασμό ή να προσθέσετε τον υπάρχοντα σας λογαριασμό PayPal. Αφού ολοκληρωθεί η διαδικασία, θα επιστρέψετε σε αυτήν την οθόνη και η κατάσταση θα ενημερωθεί.',
    permissionNotGranted:
        'Δεν είναι δυνατή η ανίχνευση των δικαιωμάτων PayPal που έχουν χορηγηθεί. Παρακαλώ ελέγξτε και δοκιμάστε ξανά τη διαδικασία εγγραφής.',
    errors: {
        terminalNotFound: 'Δεν βρέθηκε κατάλληλο τερματικό ηλεκτρονικού εμπορίου'
    }
})
