import React from 'react'
import {Divider, Grid} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {TitleWithStatus} from '@/components/composite'
import {ModalContainer} from '@/components/containers'
import {PayByLinkOverviewModalContainerStoreSymbol} from '@/pages/PayByLink'
import {DateTimeLabel, Description, MobileBackButton, ModalFormFooter, Text} from '@/components'
import {getPayByLinkPaymentStatusIconType, getPayByLinkPaymentStatusTagType} from '@/pages/PayByLink/utils'
import {getAmountWithCurrentCurrency} from '@/utils'
import {TOverviewModalStore} from './TOverviewModalStore'
import translations from './translations'
import {OverviewModalProps} from './props'

export const OverviewModal: React.FC<OverviewModalProps> = observer((
    {injectableIdentifier, paymentLink}: OverviewModalProps
) => {
    const screens = Grid.useBreakpoint()
    const isMobile = screens.xs
    const overViewModalStore = useInjection<TOverviewModalStore>(injectableIdentifier || PayByLinkOverviewModalContainerStoreSymbol)

    return (
        <ModalContainer
            title={translations().paymentView}
            injectableIdentifier={injectableIdentifier}
            footer={<ModalFormFooter
                onCancel={overViewModalStore.onCancel}
                onOk={overViewModalStore.onOk}
                cancelText={translations().cancel}
                okText={translations().ok}
            />}
        >
            {isMobile && <MobileBackButton onCancel={overViewModalStore.onCancel}/>}

            <TitleWithStatus subtitle={paymentLink.description} statusProps={{
                status: paymentLink.status,
                type: getPayByLinkPaymentStatusTagType(paymentLink.status),
                iconType: getPayByLinkPaymentStatusIconType(paymentLink.status)
            }}>
                <Text>{getAmountWithCurrentCurrency(paymentLink.amount)}</Text>
            </TitleWithStatus>

            <Divider type={'horizontal'}/>

            <Description dataSource={[
                {
                    label: translations().date,
                    value: paymentLink.description,
                    key: translations().date,
                    render: () => <DateTimeLabel date={paymentLink.createdDate}/>
                },
                {
                    label: translations().orderNumber,
                    value: paymentLink.invoiceId,
                    key: translations().orderNumber
                },
                {
                    label: translations().customer,
                    value: paymentLink.customerName,
                    key: translations().customer
                },
                {
                    label: translations().transactionType,
                    value: translations().paymentLink,
                    key: translations().transactionType
                }
            ]}/>
        </ModalContainer>
    )
})
