import React from 'react'
import {Button, Tooltip} from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import {fetchInvoice} from '@/stores/invoices/services/fetchers'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import translations from '../translations'

const columns = [
    {
        key: 'period',
        dataIndex: 'period',
        title: translations().period
    },
    {
        key: 'date',
        dataIndex: 'date',
        title: translations().date
    },
    {
        key: 'id',
        dataIndex: 'id',
        title: translations().id
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: translations().amount
    },
    {
        key: 'currency',
        dataIndex: 'currency',
        title: translations().currency
    }
]

export const getColumns = () => {
    return hasPermissions([PermissionsMap.invoices.full]) ? (
        [
            ...columns,
            {
                key: 'action',
                title: translations().action,
                render: (item) => (
                    <Tooltip title={translations().download}>
                        <Button
                            type={'link'}
                            onClick={() => fetchInvoice(item.id, `${item.id}.pdf`)}
                            size={'small'}
                            icon={<DownloadOutlined />}
                        />
                    </Tooltip>
                )
            }
        ]
    ) : columns
}
