import translations from './translations'
import React from 'react'

translations.add('es', {
    title: 'PayPal',
    description: (
        <>
            DNA Payments se ha asociado con PayPal para ofrecer a los comerciantes una forma
            sencilla de añadir esta popular forma de pago a sus clientes. Los vendedores con cuentas
            PayPal existentes pueden vincularlas en esta página o, si es necesario crear una cuenta
            PayPal, también puede hacerse aquí.
            <br />
            <br />
            Una vez vinculada la cuenta, la opción de pago de PayPal puede activarse o desactivarse
            fácilmente mediante la opción de alternancia, lo que permite al vendedor tener el
            control total.
            <br />
            <br />
        </>
    ),
    paypalStatus: 'Estado Paypal',
    settings: 'Personalizar configuración',
    yourAccountDisabled:
        'La configuración de su cuenta de PayPal está actualmente desactivada. Para volver a comenzar a aceptar pagos de PayPal, por favor actívela.',
    consentRevokedText:
        'Se han revocado los consentimientos de configuración de la cuenta de PayPal o la cuenta de comerciante de PayPal está cerrada.',
    readyPaymentText: 'Estás listo para aceptar pagos de PayPal.'
})
