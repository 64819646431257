import translations from './translations'

translations.add('en', {
    title: 'Terminal settings',
    configurationOptions: 'Configuration options',
    shippingPreference: 'Shipping preference',
    useCustomerProvidedShippingAddress: 'Use the customer-provided shipping address on the PayPal site.',
    redactShippingAddressFromPaypalSite: 'Redact the shipping address from the PayPal site. Recommended for digital goods.',
    useMerchantProvidedAddress: 'Use the merchant-provided address. The customer cannot change this address on the PayPal site.',
    defaultTransactionType: 'Default transaction type',
    authorization: 'Authorization',
    capture: 'Capture',
    cancel: 'Cancel',
    saveChanges: 'Save changes'
})
