import translations from './translations'
import React from 'react'

translations.add('en', {
    title: 'Apple Pay',
    description: <>
        Apple Pay allows consumers to pay for their goods and services easily and quickly online. The consumers store their payment card information in their Apple Wallet which can be invoked from their Apple devices or within the Safari web browser.
        <br/><br/>
        DNA Payments are pleased to offer this payment method to merchants and it can be enabled or disabled here.
        <br/><br/>
    </>
})
