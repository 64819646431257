import React from 'react'
import {List} from 'antd'
import {Card, DateTimeLabel, Description, Text} from '@/components'
import {TitleWithStatus} from '@/components/composite/TitleWithStatus'
import {PayByLinkStatus} from '@/constants'
import {
    getPayByLinkPaymentStatusIconType,
    getPayByLinkPaymentStatusName,
    getPayByLinkPaymentStatusTagType
} from '@/pages/PayByLink/utils'
import {getAmountWithCurrentCurrency} from '@/utils'
import translations from './translations'
import {MobileListProps} from './props'
import styles from './styles.scss'

export type ListItem = {
    key: string
    amount: string
    status: PayByLinkStatus
    customer: string
    customerName: string
    paymentMethod: string
    paymentMethodType: string
    date: string
    description: string
}

export const MobilePayByLinkPaymentList: React.FC<MobileListProps<ListItem>> = ({dataSource, listExtra, isLoading}) => {
    return (
        <List
            loading={isLoading}
            itemLayout={ 'horizontal' }
            dataSource={dataSource || []}
            renderItem={(item) => {
                return (
                    <Card
                        key={item.key}
                        title={
                            <TitleWithStatus statusProps={{
                                status: getPayByLinkPaymentStatusName(item.status),
                                type: getPayByLinkPaymentStatusTagType(item.status),
                                iconType: getPayByLinkPaymentStatusIconType(item.status)
                            }}>
                                <Text className={styles.listItemTitle}>{getAmountWithCurrentCurrency(item.amount)}</Text>
                            </TitleWithStatus>
                        }
                        extra={listExtra(item)}
                        className={styles.listItem}
                    >
                        <Description dataSource={[
                            {
                                label: translations().description,
                                value: item.description,
                                key: translations().description
                            },
                            {
                                label: translations().customer,
                                value: item.customer || item.customerName,
                                key: translations().customer
                            },
                            {
                                label: translations().date,
                                value: item.date,
                                key: translations().date,
                                render: () => <DateTimeLabel date={item.date}/>
                            }
                        ]}/>
                    </Card>
                )
            }}
        />
    )
}
