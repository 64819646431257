import translations from './translations'

translations.add('en', {
    bankingDate: 'Banking Date',
    amount: 'Amount',
    currency: 'Currency',
    captureMethod: 'Capture Method',
    status: 'Status',
    documentId: 'Document ID',
    operation: 'Operation',
    transactionType: 'Transaction Type',
    cardMask: 'Card Mask',
    cardScheme: 'Card Scheme',
    terminalId: 'Terminal ID',
    transactionId: 'Transaction ID',
    chargebackDate: 'Chargeback Date',
    reasonCode: 'Reason Code',
    arn: 'ARN',
    previousDocumentId: 'Previous Document ID',
    chargebacksDetails: 'Chargebacks details',
    originalTransactionDetails: 'Original Transaction Details',
    chargebackHistory: 'Chargeback History',
    chargebackStatus: 'Chargeback Status',
    financialStatus: 'Financial status'
})
