import React from 'react'
import {useInjection} from 'dna-react-ioc'
import {RangePickerContainer, SelectWithTitleContainer, TTabsContainerStore} from '@/components'
import translations from '@/pages/Overview/translations/translations'
import {
    OverviewECOMCurrencySelectStoreSymbol,
    OverviewPOSCurrencySelectStoreSymbol,
    EcomOverviewRangePickerStoreSymbol,
    OverviewTabsContainerStoreSymbol,
    PosOverviewRangePickerStoreSymbol
} from '@/pages/Overview/Overview'

import styles from './styles.scss'


export const OverviewTabsExtraContent = () => {

    const tabsStore = useInjection<TTabsContainerStore>(OverviewTabsContainerStoreSymbol)

    return (
        <div className={styles.tabBarExtra}>
            <SelectWithTitleContainer
                className={styles.currency}
                injectableIdentifier={(
                    tabsStore.value === 'onlinePayments'
                        ? OverviewECOMCurrencySelectStoreSymbol
                        : OverviewPOSCurrencySelectStoreSymbol
                )}
                displayValue={translations().currency}
            />
            <RangePickerContainer
                key={tabsStore.value === 'onlinePayments' ? 'onlinePayments' : 'PosPayments'}
                injectableIdentifier={
                    tabsStore.value === 'onlinePayments'
                        ? EcomOverviewRangePickerStoreSymbol
                        : PosOverviewRangePickerStoreSymbol
                }
            />
        </div>
    )
}
