import React from 'react'
import {Form, Layout, Row, Spin, Typography} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {
    MakeTransferForm,
    SuccessResult,
    TransferSummary
} from '@/pages/business-account/MakeTransfer/components'
import {LoadingState} from '@/types'
import {BackLink} from '@/components'
import {withTitle} from '@/router/utils'
import {TMakeTransferStore} from './TMakeTransferStore'
import translations from './translations'
import styles from './styles.scss'

export const MakeTransferStoreSymbol = Symbol('MakeTransferStore')

const MakeTransfer: React.FC = observer(() => {
    const store = useInjection<TMakeTransferStore>(MakeTransferStoreSymbol)
    const [form] = Form.useForm()

    const title =
        store.step === 'fill'
            ? translations().titles.makeTransfer
            : translations().titles.transferSummary

    const onFinish = values => {
        store.setValuesForTransfer(values)
        form.validateFields()
            .then(() => {
                store.setStep('confirm')
            })
            .catch(() => {
                store.setStep('fill')
            })
    }

    const getStepNumber = () => {
        switch (store.step) {
            case 'fill':
                return 1
            case 'confirm':
                return 2
            case 'success':
            case 'error':
                return 3
            default:
                return 1
        }
    }

    return (
        <>
            <Layout.Content className={styles.body}>
                <Spin spinning={store.makeTransferLoadingState === LoadingState.LOADING}>
                    {store.step === 'fill' || store.step === 'confirm' ? (
                        <Row align={'bottom'} className={styles.titleBlock}>
                            <Typography.Title className={styles.title} level={3}>
                                {title}&nbsp;&nbsp;
                            </Typography.Title>
                            <Typography.Text className={styles.steps}>
                                {getStepNumber()}/2
                            </Typography.Text>
                        </Row>
                    ) : null}

                    {store.step === 'confirm' ? (
                        <BackLink className={styles.editLink} onClick={() => store.setStep('fill')}>
                            {translations().buttons.edit}
                        </BackLink>
                    ) : null}

                    {store.step === 'fill' ? (
                        <MakeTransferForm
                            form={form}
                            onFinish={onFinish}
                            senderName={store.senderName}
                            balance={store.accountInfo?.available}
                        />
                    ) : null}
                    {store.step === 'confirm' ? (
                        <TransferSummary
                            onConfirmTransfer={store.onConfirmTransfer}
                            formData={store.preparedFormData}
                            senderName={store.senderName}
                        />
                    ) : null}
                    {store.step === 'success' ? (
                        <SuccessResult
                            createNewTransferClick={store.reset}
                            text={`Your transfer of ${store.preparedFormData?.amount} to ${store.preparedFormData?.receiverName} has been completed.`}
                            seeInTransactionsClick={store.seeInTransactionsClick}
                        />
                    ) : null}
                </Spin>
            </Layout.Content>
        </>
    )
})

export default withTitle(MakeTransfer)
