import translations from './translations'

translations.add('es', {
    title: 'Informes',
    transactions: 'Transacciones',
    transactionsComparison: 'Comparación de transacciones',
    calendarTitle: 'Último mes',
    paymentMethods: 'Métodos de pago',
    cardPayments: 'Métodos con tarjeta',
    issuingBanks: 'Bancos emisores (sólo pagos con tarjeta)',
    amount: 'Importe',
    count: 'Número',
    volumeTotal: 'Volumen total',
    previous: 'Anterior',
    current: 'Actual',
    downloadLink: 'Descargar',
    cardScheme: 'Sistema de pagos',
    onlinePayments: 'Pagos Online',
    posPayments: 'Pagos TPV',
    currency: 'Divisa',
    noContentTitle: 'No hay contenido disponible',
    empty: 'Vacío'

})
