import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    configurationOptions: string
    shippingPreference: string
    useCustomerProvidedShippingAddress: string
    redactShippingAddressFromPaypalSite: string
    useMerchantProvidedAddress: string
    defaultTransactionType: string
    authorization: string
    capture: string
    cancel: string
    saveChanges: string
}>()

