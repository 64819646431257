import translations from './translations'

translations.add('el', {
    title: 'Επιλογές απόδειξης πληρωμής',
    buttons: {
        send: 'Στείλτε',
        receipt: 'Απόδειξη',
        downloadReceipt: 'Λήψη απόδειξης'
    },
    labels: {
        email: 'Email του πελάτη',
        emailPlaceholder: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
        date: 'Ημερομηνία',
        description: 'Περιγραφή παραγγελίας',
        paymentMethod: 'Μέθοδος πληρωμής',
        orderNumber: 'Αριθμός παραγγελίας'
    },
    errors: {
        emailInvalid: 'Το email δεν είναι έγκυρο',
        emailRequired: 'απαιτείται email'
    }
})
