import React from 'react'
import translations from './translations'

translations.add('sl', {
    title: 'Za uporabo aplikacije za preverjanje pristnosti sledite tem korakom:',
    step1: (iOSUrl: string, androidUrl: string) => (
        <>
            Prenesite aplikacijo za preverjanje pristnosti s dvema faktorjema, kot je na primer
            Google Authenticator za{' '}
            <a href={androidUrl} target='_blank'>
                Android
            </a>{' '}
            in{' '}
            <a href={iOSUrl} target='_blank'>
                iOS
            </a>{' '}
            ali podobno aplikacijo za preverjanje pristnosti
        </>
    ),
    step1Note:
        'Opomba: Microsoft Authenticator ne podpira računa na več sistemih. Upoštevajte alternativne rešitve za širšo združljivost sistema pri omogočanju dvofaktorskega preverjanja pristnosti (2FA).',
    step2: (key: string, login: string) => (
        <>
            Skenirajte QR kodo ali vnesite prijavni {login} in to ključno besedo <code>{key}</code>{' '}
            v svojo aplikacijo za preverjanje pristnosti z dvema faktorjema. Presledki in velike
            črke niso pomembni
        </>
    ),
    step3: 'Ko ste skenirali QR kodo ali vnesli zgornjo ključno besedo, vam bo aplikacija za preverjanje pristnosti z dvema faktorjema zagotovila edinstveno kodo. Vnesite kodo v spodnje potrditveno polje in kliknite »Pošlji«'
})
