import React from 'react'
import {Form} from 'antd'
import {observer} from 'mobx-react'
import {Description, ManagerTip, Modal, ModalFormFooter} from '@/components'
import {LoadingState} from '@/types'
import translations from './translations'
import {GetCVVModalProps} from './props'

export const GetCVVModal: React.FC<GetCVVModalProps> = observer((
    {open, onCancel, onGetCVVClick, cvvData, loadingState}
) => {
    const [form] = Form.useForm()
    const formId = 'pin-input'

    const onFinish = async (values) => {
        await form.validateFields()
        // return onGetCVVClick(Object.entries(values).map(([_, value]) => value).join(''))
    }

    const CVVDescriptions = cvvData && [
        {
            label: translations().cvvCode,
            value: cvvData.data.cvv
        }
    ]

    return (
        <Modal
            open={open}
            title={translations().getCVV}
            onCancel={onCancel}
            footer={<ModalFormFooter
                formId={formId}
                onCancel={onCancel}
                cancelText={translations().cancel}
                okText={cvvData ? translations().gotIt : translations().getCVV}
                onOk={cvvData ? onCancel : onGetCVVClick}
                confirmLoading={loadingState === LoadingState.LOADING}
            />}
        >
            <ManagerTip text={translations().seeYourCVVAndRemember}/>
            {/*<Form*/}
            {/*    form={form}*/}
            {/*    id={formId}*/}
            {/*    onFinish={onFinish}*/}
            {/*>*/}
            {/*    <PinInput form={form} length={6} formId={formId}/>*/}
            {/*</Form>*/}
            {cvvData ? <Description dataSource={CVVDescriptions}/> : null}
        </Modal>
    )
})
