import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    calendarTitle: string
    submit: string
    period: string
    presets: {
        today: string
        yesterday: string
        thisWeek: string
        lastWeek: string
        last7Days: string
        thisMonth: string
        last30Days: string
        last60Days: string
        last90Days: string
        lastMonth: string
        thisYear: string
        last12Month: string
    }
}>()
