import {AppError} from '../types'
import {openPopupWindow} from './window-utils'
import rootTranslations from '@/translations'

export const openPopupWindowPromise = (
    url: string,
    windowName: string,
    options?: {maxWidth?: number; maxHeight?: number}
) => {
    let popup: Window = null
    const waitPopupClose = new Promise<void>((resolve) => {
        popup = openPopupWindow(url, windowName, () => resolve(), options)
    })

    return {popup, waitPopupClose}
}

export const cachePromise = (func: () => Promise<any>) => {
    let promise: Promise<any>

    return () => {
        if (!promise) promise = func()
        return promise
    }
}

export const longPoll = <T,>(
    promiseFn: () => Promise<T>,
    shouldStop: (result: T) => boolean,
    options: {delayInMs?: number; maxAttempt?: number} = {}
) => {
    const {delayInMs = 2000, maxAttempt = 5} = options
    let attemptCount = 0

    return new Promise<T>((resolve, reject) => {
        const check = async () => {
            try {
                const result = await promiseFn()

                if (shouldStop(result)) return resolve(result)
                if (++attemptCount >= maxAttempt)
                    return reject(
                        new AppError(
                            'MAX_ATTEMPT_REACHED',
                            rootTranslations().errors.maxAttempt(maxAttempt)
                        )
                    )

                setTimeout(check, delayInMs)
            } catch (error) {
                reject(
                    new AppError(
                        'UNKNOWN_ERROR',
                        error.message || rootTranslations().errors.unknown
                    )
                )
            }
        }

        check()
    })
}
