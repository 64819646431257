import translations from './translations'

translations.add('es', {
    acceptPolicies:
        'Al hacer clic en este botón, consiento compartir mis datos con PayPal con fines de embarque',
    setUpPayPalAccount: 'Configurar cuenta de PayPal',
    warningMessage:
        'Una vez que se haga clic en el botón anterior, será llevado al sitio de PayPal en una nueva pestaña del navegador. Siga las instrucciones para crear una nueva cuenta o agregar su cuenta de PayPal existente. Una vez completado el proceso, volverá a esta pantalla y se actualizará el estado.',
    permissionNotGranted:
        'No se pueden detectar los permisos de PayPal que se hayan concedido. Por favor, verifique y vuelva a intentar el proceso de incorporación.',
    errors: {
        terminalNotFound: 'No se encontró ningún terminal de comercio electrónico elegible'
    }
})
