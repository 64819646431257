import translations from './translations'

translations.add('en', {
    title: 'Exports',
    documentType: 'Document type',
    id: 'ID',
    fileName: 'File name',
    dateRequested: 'Date requested',
    lastUpdatedDate: 'Last updated',
    status: 'Status',
    statuses: {
        STARTED: 'Started',
        PROCESSING: 'Processing',
        QUEUED: 'Queued',
        FINISHED: 'Finished',
        FAILED: 'Failed'
    }
})
