import React, {Component, ErrorInfo, ReactNode} from 'react'
import {Result} from 'antd'
import {Link} from 'react-router-dom'
import {Button} from '@/components'
import {ROUTES} from '@/router/routes'
import translations from './translations'
import {getHomeUrl, getPermissions} from '@/stores/auth/services'

interface ErrorBoundaryProps {
    children: ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return {hasError: true}
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error, errorInfo, location.pathname)
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            return <Result
                status="error"
                title={translations().errors.ourSupportTeamIsHereToHelp}
                extra={
                    <Button type="primary" key="console">
                        <Link to={ROUTES.home}>{translations().buttons.home}</Link>
                    </Button>
                }
            />
        }
        return this.props.children
    }
}
