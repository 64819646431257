import translations from './translations'

translations.add('sl', {
    orderNumber: 'Číslo objednávky',
    orderNumberTooltip:
        'Použite predvyplnené číslo objednávky, alebo zvoľte číslo podľa vlastného výberu',
    errors: {
        orderNumberRequired: 'Vyžaduje sa číslo objednávky'
    }
})
