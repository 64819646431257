export enum SearchParams {
    TRANSACTION_ID = 'Transaction ID',
    ORDER = 'Order',
    DESCRIPTION = 'Description',
    CUSTOMER_NAME = 'Customer name',
    EMAIL_STARTS_WITH = 'Email starts with',
    CARD_STARTS_WITH = 'Card starts with',
    CARD_ENDS_WITH = 'Card ends with',
    CUSTOMER_ACCOUNT_ID = 'Customer Account ID'
}
