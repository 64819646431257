import React from 'react'
import classNames from 'classnames'
import {Select as AntSelect} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Icon, Select} from '@/components'
import {MerchantSelectStoreType} from '@/layouts/MainLayout/components'
import translations from './translations'
import {MerchantSelectProps} from './props'
import styles from './styles.scss'
import {MERCHANT_ITEM, SELECT_MERCHANT} from '@/constants/playwright-ids'

const {Option} = AntSelect

export const MerchantSelectStoreSymbol = Symbol('MerchantSelectStore')

export const MerchantSelect: React.FC<MerchantSelectProps> = observer(({isCollapsed = false}) => {
    const store = useInjection<MerchantSelectStoreType>(MerchantSelectStoreSymbol)

    if (!store.options || !Boolean(store.options?.length)) {
        return null
    }

    const isOnlyOneOption = store.options?.length === 1

    return (
        <div className={classNames(styles.selectContainer, {[styles.disabled]: isOnlyOneOption, [styles.collapsed]: isCollapsed})}>
            <Icon type={'shop_outlined'} />
            <Select
                data-test-id={SELECT_MERCHANT}
                loading={!store.value}
                className={styles.select}
                value={store.value}
                onChange={store.setValue}
                options={store.options}
                placeholder={translations().placeholder}
                popupClassName={styles.dropdown}
                disabled={isOnlyOneOption}
            >
                {store.options.map(option => (
                    <Option
                        key={option?.value}
                        value={option?.value}
                        label={option?.label}
                    >
                        <div className={styles.option}>
                            <div
                                title={option?.label}
                                className={styles.optionLabel}
                                data-test-id={MERCHANT_ITEM}
                            >{option?.label}</div>
                            <div title={option?.subLabel} className={styles.optionSubLabel}>
                                {option?.subLabel}
                            </div>
                        </div>
                    </Option>
                ))}
            </Select>
        </div>
    )
})
