import translations from './translations'

translations.add('sl', {
    title: 'Card freeze',
    freezeYourCardTemporarily: '- Dočasne zmrazte svoju kartu',
    indefinitely: 'Indefinitely',
    oneHour: '1 hodina',
    twentyFourHour: '24 hodín',
    customPeriod: 'Voliteľné obdobie',
    cancel: 'Zruš',
    freezeCard: 'Zmraziť kartu'
})
