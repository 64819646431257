import React from 'react'
import translations from './translations'
import {Typography} from 'antd'

translations.add('es', {
    back: 'Volver a Restablecer contraseña',
    title: 'Confirmar correo electrónico',
    text: (email) => <>
        Si encontramos una cuenta asociada al <Typography.Text strong>{email}</Typography.Text>, le enviaremos un código de verificación de 6 dígitos
    </>,
    otp: 'Código de verificación',
    otpInvalid: 'Código de verificación obligatorio',
    otpRequired: 'El código de verificación no es válido',
    submit: 'Confirmar',
    confirmEmailDescription(onClick) {
        return <>Si no didn&apos;t ha recibido el código de verificación, compruebe la bandeja de correo no deseado o intente <a onClick={onClick}>reenviarlo</a></>
    },
    waitingToResendVerificationCode(count) {
        return <>Si no ha didn&apos;t  recibido el correo electrónico de verificación, compruebe la bandeja de correo no deseado o intente <a className="disabled">reenviarlo</a> en <span className="count">{count} segundos</span></>
    }
})
