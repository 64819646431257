import {register} from 'dna-react-ioc'
import {IPayByLinkStore} from '@/pages/PayByLink/IPayByLinkStore'
import {
    PayByLinkCancelPaymentLinkStoreSymbol,
    PayByLinkCreateNewLinkStoreSymbol,
    PayByLinkEditPaymentLinkModalContainerStoreSymbol,
    PayByLinkEditPaymentLinkStoreSymbol,
    PayByLinkLinkActionsMenuStoreSymbol,
    PayByLinkLinkDetailsModalContainerStoreSymbol,
    PayByLinkNewPaymentLinkModalContainerStoreSymbol,
    PayByLinkOverviewModalContainerStoreSymbol,
    PayByLinkPaginationStoreSymbol,
    PayByLinkPaymentLinkDetailsStoreSymbol,
    PayByLinkRangePickerContainerStoreSymbol,
    PayByLinkSendMessageStoreSymbol,
    PayByLinkStoreSymbol,
    PayByLinkWidgetPreviewStoreSymbol
} from '@/pages/PayByLink'
import {
    CancelPaymentLinkStore,
    EditPaymentLinkStore,
    LinkActionsMenuStore,
    ModalStore,
    NewPaymentLinkModalStore,
    PaginationStore,
    PayByLinkStore,
    PaymentLinkDetailsModalStore,
    PaymentLinkWidgetPreviewStore,
    PeriodType,
    RangePickerStore,
    SendMessageStore
} from '@/stores'
import {
    TModalContainerStore,
    PaginationContainerStoreType,
    TRangePickerContainerStore
} from '@/components'
import {
    TLinkActionsMenuStore,
    TNewPaymentLinkModalStore,
    TPaymentLinkDetailsModalStore
} from '@/pages/components'
import {OverviewModalStore} from '@/stores/pay-by-link/OverviewModalStore'
import {TOverviewModalStore} from '@/pages/PayByLink/components/OverviewModal/TOverviewModalStore'

export const payByLinkState = () => {
    register<IPayByLinkStore>(PayByLinkStoreSymbol).to(PayByLinkStore).inSingletonScope()
    register<TRangePickerContainerStore>(PayByLinkRangePickerContainerStoreSymbol)
        .toDynamicValue(() => new RangePickerStore({period: PeriodType.Last7Days}))
        .inSingletonScope()
    register<TOverviewModalStore>(PayByLinkOverviewModalContainerStoreSymbol)
        .to(OverviewModalStore)
        .inSingletonScope()
    register<TModalContainerStore>(PayByLinkNewPaymentLinkModalContainerStoreSymbol)
        .to(ModalStore)
        .inSingletonScope()
    register<TModalContainerStore>(PayByLinkLinkDetailsModalContainerStoreSymbol)
        .to(ModalStore)
        .inSingletonScope()
    register<TModalContainerStore>(PayByLinkEditPaymentLinkModalContainerStoreSymbol)
        .to(ModalStore)
        .inSingletonScope()
    register<TNewPaymentLinkModalStore>(PayByLinkCreateNewLinkStoreSymbol)
        .to(NewPaymentLinkModalStore)
        .inSingletonScope()
    register<TPaymentLinkDetailsModalStore>(PayByLinkPaymentLinkDetailsStoreSymbol)
        .to(PaymentLinkDetailsModalStore)
        .inSingletonScope()
    register<PaymentLinkWidgetPreviewStore>(PayByLinkWidgetPreviewStoreSymbol)
        .to(PaymentLinkWidgetPreviewStore)
        .inSingletonScope()
    register<SendMessageStore>(PayByLinkSendMessageStoreSymbol)
        .to(SendMessageStore)
        .inSingletonScope()
    register<EditPaymentLinkStore>(PayByLinkEditPaymentLinkStoreSymbol)
        .to(EditPaymentLinkStore)
        .inSingletonScope()
    register<CancelPaymentLinkStore>(PayByLinkCancelPaymentLinkStoreSymbol)
        .to(CancelPaymentLinkStore)
        .inSingletonScope()
    register<PaginationContainerStoreType>(PayByLinkPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()
    register<TLinkActionsMenuStore>(PayByLinkLinkActionsMenuStoreSymbol)
        .to(LinkActionsMenuStore)
        .inSingletonScope()
}
