import React from 'react'
import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    confirmationText: (amount: React.ReactNode) => string
    chargeConfirmation: string
    cancel: string
    confirm: string
}>()

