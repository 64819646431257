import React from 'react'
import translations from './translations'

translations.add('sl', {
    title: (
        <>
            Obnovitvena koda je ustvarjena za vaš račun. <br /> Ta koda s 12 znaki se lahko uporabi
            enkrat za obnovitev vašega računa, če se ne morete prijaviti z dvostopenjsko preverjanje
            pristnosti (2FA).
        </>
    ),
    copy: 'Kopiraj',
    copyText: 'Kopirajte kodo, da nadaljujete.',
    copied: 'Kopirano',
    warning: {
        line1: 'Koda se lahko uporabi samo enkrat',
        line2: 'Iz varnostnih razlogov ta koda ne bo več prikazana',
        line3: 'Ne delite te kode z nikomer'
    }
})
