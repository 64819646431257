import translations from './translations'

translations.add('es', {
    title: 'Agregar nuevo dominio',
    provideDomainWhereTheFile: 'Proporcione el dominio donde se alojará el archivo',
    inputTheTopLevelDomain: 'Ingrese el dominio de nivel superior (p. ej., dnapayments.com) o subdominio \n' + 
    '(p. ej., pay.dnapayments.com) para el que desea habilitar Apple Pay.',
    downloadDomainAssociationFile: 'Descargar archivo de asociación de dominio',
    hostDownloadedDomainAssociationFile: 'Alojar el archivo de asociación de dominio descargado',
    youNeedToHostVerificationFileYouDownloaded: 'Deberá alojar el archivo de verificación que descargó anteriormente en su dominio en la siguiente ubicación:',
    cancel: 'Cancelar',
    add: 'Añadir',
    errors: {
        domainRequired: 'Dominio requerido'
    }
})
