import React, {useEffect} from 'react'
import {Alert, Skeleton, Typography} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import {
    IPaypalPaymentMethodStore,
    PaymentMethodStoreSettings,
    PayPalOnboarding,
    PaypalSettingsModal
} from '@/pages/payment-methods'
import {PaymentMethodsPaypalStoreSymbol} from '@/pages/payment-methods/PaymentMethods/PaymentMethods'
import {PageHeader, PaymentMethodLabelWithIcon} from '@/components'
import {PaymentMethodType} from '@/constants'
import {LoadingState} from '@/types'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {hasPermissions} from '@/stores/auth/services'
import {useQuery} from '@/hooks'
import {PayPalReturnUrlQuery} from '@/stores/payment-methods'

import translations from './translations'
import styles from './PayPalPaymentMethod.scss'
import {PAYPAL_CUSTOMIZE_SETTINGS} from '@/constants/playwright-ids'

const {Link, Text} = Typography

export const PaypalPaymentMethod = observer(() => {
    const {
        reload,
        isFullyOnboarded,
        isPoliciesAccepted,
        setPoliciesAccepted,
        errorMessage,
        loadingState,
        settings,
        closeSettingsModal,
        actionUrl,
        isSettingsModalVisible,
        stores,
        saveSettingsChanges,
        openSettingsModal
    } = useInjection<IPaypalPaymentMethodStore>(PaymentMethodsPaypalStoreSymbol)
    const {status} = settings || {}

    const query = useQuery()

    const parsedData: PayPalReturnUrlQuery = {
        merchantId: query.get('merchantId'),
        merchantIdInPayPal: query.get('merchantIdInPayPal'),
        permissionsGranted: query.get('permissionsGranted') as 'true' | 'false',
        consentStatus: query.get('consentStatus') as 'true' | 'false',
        productIntentId: query.get('productIntentId'),
        productIntentID: query.get('productIntentID'),
        isEmailConfirmed: query.get('isEmailConfirmed') as 'true' | 'false',
        accountStatus: query.get('accountStatus'),
        riskStatus: query.get('riskStatus')
    }

    useEffect(() => {
        reload(parsedData)
    }, [])

    return (
        <PageHeader
            title={
                <PaymentMethodLabelWithIcon
                    paymentMethod={PaymentMethodType.paypal}
                    bordered={true}
                    title={translations().title}
                />
            }
            border={'none'}
        >
            <Text>{translations().description}</Text>
            <br />
            <br />

            {loadingState === LoadingState.LOADING && <Skeleton active={true} />}

            <PaypalSettingsModal
                isOpen={isSettingsModalVisible}
                loading={loadingState === LoadingState.LOADING}
                onOk={saveSettingsChanges}
                onCancel={closeSettingsModal}
                settings={settings}
            />

            {errorMessage ? <Alert type={'error'} message={errorMessage} showIcon /> : null}

            {isFullyOnboarded && (
                <Alert type={'success'} message={translations().readyPaymentText} />
            )}

            {status === 'consent-revoked' ? (
                <Alert showIcon message={translations().consentRevokedText} type={'warning'} />
            ) : null}

            {status === 'disabled' ? (
                <Alert showIcon message={translations().yourAccountDisabled} type={'warning'} />
            ) : null}

            {hasPermissions([PermissionsMap.payment_methods.full]) && (
                <PayPalOnboarding
                    isPoliciesAccepted={isPoliciesAccepted}
                    setPoliciesAccepted={setPoliciesAccepted}
                    actionUrl={actionUrl}
                />
            )}

            {loadingState === LoadingState.LOADING ? null : (
                <PaymentMethodStoreSettings
                    paymentMethodType={PaymentMethodType.paypal}
                    stores={stores}
                    extra={
                        !settings ? null : (
                            <Link
                                data-test-id={PAYPAL_CUSTOMIZE_SETTINGS}
                                className={styles.customizeLink}
                                href='#'
                                onClick={openSettingsModal}
                            >
                                {translations().settings}
                            </Link>
                        )
                    }
                />
            )}
        </PageHeader>
    )
})
