import React from 'react'
import translations from './translations'

translations.add('sl', {
    title: 'Vračila',
    description: (
        <>
            Kaj je povračilo sredstev? <br /> Povračilo sredstev je možen izid sporne transakcije s
            kreditno ali debetno kartico. Če banka imetnika kartice sprejme spor, bo transakcijo
            obrnila, vzela sredstva trgovcu in jih vrnila imetniku kartice. <br /> Ko imetnik
            kartice spodbija obremenitev, bo izdajateljska banka pregledala transakcijo in se
            odločila, ali je spor veljaven. Če menijo, da je, bodo imetniku kartice zagotovili
            začasno dobroimetje in sodelovali z omrežjem kartic in pridobiteljsko banko za
            dokončanje povračila sredstev.
        </>
    )
})
