import React from 'react'
import {Col, Divider, Row, Typography} from 'antd'
import {Button, Card, Description} from '@/components'
import {getAmountWithCurrency} from '@/utils'
import {TransferSummaryProps} from './props'
import styles from './styles.scss'

export const TransferSummary = ({
    formData,
    onConfirmTransfer,
    senderName
}: TransferSummaryProps) => {
    const dataSource = [
        {
            label: 'IBAN',
            value: formData?.iban
        },
        {
            label: 'Personal/company name',
            value: formData?.receiverName
        }
    ]

    return (
        <Card>
            <Typography.Title type={'secondary'} level={5}>
                From Account
            </Typography.Title>
            <Typography.Title level={5}>
                {senderName}, {formData?.iban}
            </Typography.Title>
            <Divider className={styles.divider} type={'horizontal'} />
            <Typography.Text className={styles.formSubtitle}>Recipient information</Typography.Text>
            <br />
            <br />
            <Description dataSource={dataSource} />
            <Divider className={styles.divider} type={'horizontal'} />

            <Row gutter={[32, 32]}>
                <Col xs={12} sm={{span: 4, order: 1}} md={{span: 4, order: 1}}>
                    <Typography.Text type={'secondary'}>Amount</Typography.Text>
                    <br />
                    <br />
                    <Typography.Text>{getAmountWithCurrency(formData?.amount)}</Typography.Text>
                </Col>
                <Col sm={{order: 2}}>
                    <Divider type={'vertical'} className={styles.dividerVertical} />
                </Col>
                <Col
                    xs={12}
                    sm={{span: 4, order: 3, offset: 1}}
                    md={{span: 4, order: 3, offset: 1}}
                >
                    <Typography.Text type={'secondary'}>Fee</Typography.Text>
                    <br />
                    <br />
                    <Typography.Text>{getAmountWithCurrency(0.2)}</Typography.Text>
                </Col>
                <Col xs={24} sm={{span: 6, order: 4, offset: 12}}>
                    <Typography.Text type={'secondary'}>Recipient will get</Typography.Text>
                    <Typography.Title level={3}>
                        {getAmountWithCurrency(formData?.amount)}
                    </Typography.Title>
                </Col>
            </Row>

            <Divider className={styles.divider} type={'horizontal'} />
            <br />
            <Button onClick={onConfirmTransfer}>Confirm transfer</Button>
        </Card>
    )
}
