import React from 'react'
import {observer} from 'mobx-react'
import {Form, Radio} from 'antd'
import {Modal, ModalFormFooter, ManagerTip} from '@/components'
import translations from './translations'
import {CardBlockModalProps} from './props'
import styles from './styles.scss'
import {LoadingState} from '@/types'
import {CardStatus} from '@/stores'

export const CardBlockModal: React.FC<CardBlockModalProps> = observer(
    ({open, onCancel, onChangeCardStatus, loadingState}) => {
        const [form] = Form.useForm()
        const formId = 'card-block-modal'

        const onFinish = values => {
            onChangeCardStatus(values)
        }

        return (
            <Modal
                open={open}
                title={translations().title}
                onCancel={onCancel}
                footer={
                    <ModalFormFooter
                        confirmLoading={loadingState === LoadingState.LOADING}
                        formId={formId}
                        onCancel={onCancel}
                        cancelText={translations().cancel}
                        okText={translations().blockCard}
                    />
                }
            >
                <ManagerTip text={translations().ohSeemsLikeSomethingBadHappened} />
                <p className={styles.problemsTitle}>{translations().pleaseClarifyTheProblem}</p>
                <Form
                    form={form}
                    id={formId}
                    layout={'vertical'}
                    requiredMark={false}
                    onFinish={onFinish}
                    initialValues={{status: CardStatus.STOLEN_CARD}}
                >
                    <Form.Item name={'status'}>
                        <Radio.Group
                            value={'CARD_STOLEN'}
                            className={styles.problems}
                            buttonStyle='solid'
                        >
                            <Radio key={CardStatus.STOLEN_CARD} value={CardStatus.STOLEN_CARD}>
                                {translations().myCardWasStolen}
                            </Radio>
                            <Radio key={CardStatus.LOST_CARD} value={CardStatus.LOST_CARD}>
                                {translations().iveLostMyCard}
                            </Radio>
                            <Radio
                                key={CardStatus.CARD_DESTROYED}
                                value={CardStatus.CARD_DESTROYED}
                            >
                                {translations().cardIsDamaged}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
)
