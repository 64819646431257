import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import translations from './translations'


translations.add('ro', {
    title: 'Exportați plăți online',
    columns: 'Coloane',
    cancel: 'Anulare',
    ok: 'Exportare',
    all: (value) => `Toate (${value})`,
    custom: 'Personalizat',
    successExportMessage: <span>
        Solicitarea de descărcare a fost adăugată în coadă pentru procesare. Odată ce documentul este pregătit, poate fi descărcat din secțiunea {' '}
        <Link to={ROUTES.exports}>Exporturi</Link>{' '}.
    </span>
})
