import translations from './translations'

translations.add('es', {
    title: 'Configuración de la tarjeta',
    freeze: {
        freezeCard: 'Congelar tarjeta',
        inCaseYouWantToFreeze: 'En caso de que quieras dejar de trabajar con cartas por un tiempo'
    },
    block: {
        blockCard: 'Bloquear tarjeta',
        inCaseYouWantToBlock: 'En caso de pérdida o robo de la tarjeta'
    },
    activate: {
        activateCard: 'Activate card',
        inCaseYouWantToActivate: 'In case of blocked card'
    },
    getCVV: 'Get CVV',
    getPIN: 'Get PIN',
    cardholder: 'Titular de la tarjeta',
    address: 'Dirección'
})
