import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Modal} from '@/components/dumb/Modal'
import {TwoFAModalStoreInterface} from '@/stores/profile/TwoFAModalStore/TwoFAModalStoreInterface'
import {TwoFAModalStoreSymbol} from '@/pages/Profile/components/TwoFA/components/TwoFAModal'
import {SendVerificationCode} from '@/components'

import translations from './translations'
import {DISABLE_TWO_FA_CODE} from '@/constants/playwright-ids'

export const TwoFADisableModal = observer(() => {
    const {twoFAStore, updateTwoFAUserSettings} =
        useInjection<TwoFAModalStoreInterface>(TwoFAModalStoreSymbol)

    const {isDisableModalOpen, setIsDisableModalOpen} = twoFAStore

    const onSubmit = async v => {
        const result = await updateTwoFAUserSettings(v.verificationCode, true)

        if (result) onCancel()
    }

    const onCancel = () => setIsDisableModalOpen(false)

    return (
        <Modal
            width={'600px'}
            title={translations().title}
            open={isDisableModalOpen}
            footer={null}
            onCancel={onCancel}
        >
            <SendVerificationCode
                data-test-id={DISABLE_TWO_FA_CODE}
                submitButtonText={translations().disable}
                onSubmit={onSubmit}
                onCancel={onCancel}
                danger
            />
        </Modal>
    )
})
