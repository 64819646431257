import React from 'react'
import {Form, Grid} from 'antd'
import {observer} from 'mobx-react'
import {Button} from '@/components'
import translations from './translations'
import {ModalFooterProps} from './props'
import styles from './styles.scss'
import {CANCEL_BUTTON, SUBMIT_FILLED_FORM} from '@/constants/playwright-ids'

export const ModalFormFooter: React.FC<ModalFooterProps> = observer(
    ({
         onOk,
         onCancel,
         okText,
         cancelText,
         confirmLoading,
         formId,
         okButtonProps,
         okDataClassName
     }) => {
        const screens = Grid.useBreakpoint()
        const isMobile = screens.xs

        return (
            <div className={styles.footer}>
                {!isMobile && (
                    <Form.Item className={styles.field} key={'cancel'}>
                        <Button
                            className={styles.cancel}
                            type={'ghost'}
                            onClick={onCancel}
                            data-test-id={CANCEL_BUTTON}
                        >
                            {cancelText || translations().cancel}
                        </Button>
                    </Form.Item>
                )}
                <Form.Item className={styles.field} key={'submit'}>
                    <Button
                        form={formId}
                        type={'primary'}
                        htmlType={'submit'}
                        onClick={onOk}
                        loading={confirmLoading}
                        className={okDataClassName}
                        {...okButtonProps}
                        data-test-id={SUBMIT_FILLED_FORM}
                    >
                        <span className={okDataClassName}>{okText || translations().ok}</span>
                    </Button>
                </Form.Item>
            </div>
        )
    }
)