import React from 'react'
import {observer} from 'mobx-react'
import {Spin} from 'antd'
import {addZeroes, getCurrencySymbol} from '@/utils'
import {Button} from '@/components'
import {EcospendBackLink, EcospendBankBlock, EcospendBlock, EcospendText} from '..'
import ecospendTranslations from '../../translations'
import {EcospendPayeeBlock} from '../EcospendPayeeBlock'
import translations from './translations'
import {EcospendConsentPageProps} from './props'
import styles from './EcospendConsentPage.scss'

export const EcospendConsentPage = observer((props: EcospendConsentPageProps) => {
    const {store} = props
    const amount = store.amount
    const {currency, invoiceId} = store.currentPayment

    return <Spin spinning={store.isPaymentProcessing}>
        <>
            <EcospendBackLink
                onClick={() => store.back()}
            >
                {translations().back}
            </EcospendBackLink>

            <div className={styles.amountWrapper}>
                <span className={styles.currency}>{currency ? getCurrencySymbol(currency) : ' - '}</span>
                <span className={styles.amount}>{amount ? addZeroes(amount) : ' - '}</span>
            </div>

            <EcospendBankBlock
                friendlyName={store.selectedBank.friendly_name}
                invoiceId={invoiceId}
                amount={amount}
                className={styles.block}
            />

            <EcospendPayeeBlock payee={store.payee} className={styles.block}/>

            <EcospendBlock className={styles.largeBlock}>
                <EcospendText className={styles.infoText}>{translations().infoText}</EcospendText>

                <EcospendText
                    className={styles.continueText}>{translations().privacyPolicyText('https://www.dnapayments.com/page/terms-use', 'https://www.dnapayments.com/page/privacy-policy')}</EcospendText>

                <div className={styles.buttons}>
                    <Button type={'link'} onClick={() => store.cancel()}
                            className={styles.cancel}>{translations().cancel}</Button>
                    <Button type={'primary'} className={styles.continueButton}
                            onClick={() => store.openPopapWindow()}>{translations().continue}</Button>
                </div>

                <EcospendText className={styles.smalltext}>{translations().permissionText}</EcospendText>
            </EcospendBlock>

            <EcospendText
                className={styles.footertext}
            >
                {ecospendTranslations().footerText('https://register.fca.org.uk/s/firm?id=0010X00004KSo9HQAT')}
            </EcospendText>
        </>
    </Spin>
})
