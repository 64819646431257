import translations from './translations'

translations.add('ro', {
    title: 'Link-uri de plată',
    paymentDetails: 'Detalii plată',
    chargedAmount: 'Suma debitată',
    expirationDate: 'Data expirării',
    dateCreated: 'Data creării',
    summary: 'Rezumat',
    channel: 'Canal',
    description: 'Descriere',
    amount: 'Sumă',
    status: 'Stare',
    customer: 'Client',
    action: 'Acțiune',
    statement: 'Extras de cont',
    statuses: {
        active: 'Activ',
        expired: 'Expirat',
        paid: 'Plătit',
        cancelled: 'Anulat',
        viewed: 'Vizualizat',
        attempted: 'Încercat',
        all: 'Toate'
    }
})
