import translations from './translations'

translations.add('en', {
    successTitle: 'Congratulations',
    successMessage: 'Your payment has successfully been submitted.',
    cancelMessage: 'Your payment has been cancelled.',
    failMessage: 'Your payment has failed.',
    rejectMessage: 'Your payment has been rejected.',
    transactionId: 'Transaction Id',
    autoRedirectAfter: 'You will be automatically redirected to the site after',
    seconds: 'sec',
    tryAgain: 'Try again',
    returnToBack: 'Return to Site'
})
