import translations from './translations'

translations.add('el', {
    title: 'Κατάργηση εγγραφής domain',
    pleaseEnterReasonWhyUnregistering: 'Πληκτρολογήστε τον λόγο για τον οποίο καταργείτε την εγγραφή των επιλεγμένων domain',
    unregister: 'Κατάργηση εγγραφής',
    close: 'Κλείσιμο',
    errors: {
        reasonRequired: 'Απαιτείται λόγος'
    }
})
