import {register} from 'dna-react-ioc'
import {ProfileStoreInterface} from '@/pages/Profile'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {TimeZoneStoreInterface, TimeZoneStoreSymbol} from '@/pages/Profile/components/TimeZone'
import {TwoFAModalStoreSymbol} from '@/pages/Profile/components/TwoFA/components/TwoFAModal'
import {TwoFAEmailConfirmationModalStoreSymbol} from '@/pages/Profile/components/TwoFA/components/TwoFAEmailConfirmationModal'
import {TwoFAEmailConfirmationModalStoreInterface} from '@/stores/profile/TwoFAEmailConfirmationModalStore/TwoFAEmailConfirmationModalStoreInterface'
import {TwoFAEmailConfirmationModalStore} from '@/stores/profile/TwoFAEmailConfirmationModalStore'

import {TwoFADevicesStoreSymbol} from '@/pages/Profile/components/TwoFA/components/TwoFADevices'
import {TwoFADevicesStoreInterface} from '@/stores/profile/TwoFADevicesStore/TwoFADevicesStoreInterface'
import {TwoFAModalStoreInterface} from '@/stores/profile/TwoFAModalStore/TwoFAModalStoreInterface'
import {TwoFAStoreInterface, TwoFAStoreSymbol} from '@/pages/Profile/components/TwoFA'
import {ProfileStore} from '@/stores/profile/ProfileStore'
import {TimeZoneStore} from '@/stores/profile/TimeZoneStore'
import {TwoFAStore} from '@/stores/profile/TwoFAStore'
import {TwoFAModalStore} from '@/stores/profile/TwoFAModalStore'
import {TwoFADevicesStore} from '@/stores/profile/TwoFADevicesStore'
import {TwoFADevicesPaginationContainerStoreSymbol} from '@/pages/Profile/components/TwoFA/components/'
import {PaginationStore} from '@/stores'
import {PaginationContainerStoreType} from '@/components'
import {
    DossierMerchantInfoStore,
    DossierMerchantInfoStoreInterface,
    DossierMerchantInfoStoreSymbol
} from '@/stores/profile/DossierMerchantInfo'

export const profileState = () => {
    register<ProfileStoreInterface>(ProfileStoreSymbol).to(ProfileStore).inSingletonScope()
    register<DossierMerchantInfoStoreInterface>(DossierMerchantInfoStoreSymbol)
        .to(DossierMerchantInfoStore)
        .inSingletonScope()
    register<TimeZoneStoreInterface>(TimeZoneStoreSymbol).to(TimeZoneStore).inSingletonScope()
    register<TwoFAStoreInterface>(TwoFAStoreSymbol).to(TwoFAStore).inSingletonScope()
    register<TwoFAModalStoreInterface>(TwoFAModalStoreSymbol).to(TwoFAModalStore).inSingletonScope()
    register<TwoFAEmailConfirmationModalStoreInterface>(TwoFAEmailConfirmationModalStoreSymbol)
        .to(TwoFAEmailConfirmationModalStore)
        .inSingletonScope()
    register<TwoFADevicesStoreInterface>(TwoFADevicesStoreSymbol)
        .to(TwoFADevicesStore)
        .inSingletonScope()
    register<PaginationContainerStoreType>(TwoFADevicesPaginationContainerStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()
}
