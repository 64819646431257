import React from 'react'
import {Link} from 'react-router-dom'
import {RightOutlined} from '@ant-design/icons'

import {Card, PaymentMethodLabelWithIcon} from '@/components'
import {PaymentMethodCardProps} from './props'
import styles from './styles.scss'
import {PAYMENT_METHOD_EXTRA_ICON} from '@/constants/playwright-ids'

export const PaymentMethodCard: React.FC<PaymentMethodCardProps> = (
    {
        title, type,
        onClick, link, linkText, ...rest
    }
) => {
    return (
        <Card
            hoverable
            title={(
                <PaymentMethodLabelWithIcon
                    bordered={true}
                    title={title}
                    paymentMethod={type}
                    className={styles.title}
                />
            )}
            onClick={onClick}
            extra={<RightOutlined data-test-id={PAYMENT_METHOD_EXTRA_ICON} onClick={onClick} className={styles.extraAction}/>}
            {...rest}
        >
            <Link to={link} >{linkText}</Link>
        </Card>
    )
}
