import translations from './translations'
import React from 'react'

translations.add('sl', {
    title: 'Open Banking',
    description: (
        <>
            Otvorené bankovníctvo je bezpečný spôsob prijímania platieb za tovar a služby priamo z
            bankového účtu spotrebiteľa.
            <br />
            <br />
            Povolením otvoreného bankovníctva budú mať spotrebitelia možnosť vybrať si svojho
            poskytovateľa bankovníctva (z viac ako 50+ finančných inštitúcií, zoznam, ktorý sa
            neustále rozrastá), a uskutočniť platbu v niekoľkých jednoduchých krokoch. Prostriedky
            budú okamžite odpísané z účtu, čo pomôže efektívnejšie spravovať dostupné zostatky.
            <br />
            <br />
            DNA Payments je hrdá na to, že sa spojila s popredným poskytovateľom služieb otvoreného
            bankovníctva Ecospend s cieľom sprístupniť toto zariadenie našej zákazníckej základni.
            <br />
            <br />
        </>
    )
})
