import translations from './translations'

translations.add('el', {
    showAll: 'Προβολή όλων',
    dataTypes: {
        amount: 'Ποσό',
        count: 'Αριθμός'
    },
    week: 'Εβδομάδα',
    previousRangeData: 'Προηγούμενο',
    currentRangeData: 'Τρέχον'
})
