import translations from './translations'

translations.add('el', {
    labels: {
        title: 'Διαδικασία νέας πληρωμής',
        merchantCustomData: 'Προσαρμοσμένα δεδομένα εμπόρου'
    },
    buttons: {
        ok: 'Εντάξει'
    }
})
