import React from 'react'
import {observer} from 'mobx-react'
import {IconTitle} from '@/components'
import {Form, message, Select} from 'antd'
import {getFilterTransactionTypes} from '@/utils/transactions'
import {TransactionType} from '@/types/transactions'
import rootTranslations from '@/translations/translations'
import translations from './translations'
import {TransactionTypeFormFieldProps} from './props'
import styles from './styles.scss'
import {SELECT_TRANSACTION_TYPE, TRANSACTION_ITEM} from '@/constants/playwright-ids'

export const TransactionTypeFormField: React.FC<TransactionTypeFormFieldProps> = observer((
    {form, formItemProps, name = 'transactionType', transactionTypes = getFilterTransactionTypes()}
) => {
    return <Form.Item
        name={name}
        label={
            <IconTitle
                title={translations().labels.transactionType}
                tooltipText={(
                    <ul className={styles.tooltipList}>
                        {transactionTypes
                            .map(({value}) => <li key={value}>{rootTranslations().transactionTypesTooltip[value]}</li>)}
                    </ul>
                )}
            />
        }
        rules={[{ required: true, message: translations().errors.transactionType }]}
        {...formItemProps}
    >
        <Select
            data-test-id={SELECT_TRANSACTION_TYPE}
            placeholder={translations().placeholders.transactionType}
            onChange={(v) => {
                if (v === TransactionType.VERIFICATION) {
                    if (form.getFieldValue('amount')) {
                        message.warn(translations().warnings.verification)
                    }
                    form.setFieldsValue({ amount: 0 })
                }
            }}
        >
            {transactionTypes.map((item) => (
                <Select.Option
                    key={item.value}
                    value={item.value}
                    data-test-id={TRANSACTION_ITEM + item.value}
                >
                    {item.label}
                </Select.Option>
            ))}
        </Select>
    </Form.Item>
})
