export enum SectionsEnum {
    APP = 'app',
    OVERVIEW = 'overview',
    POS_PAYMENTS = 'pos_payments',
    ONLINE_PAYMENTS = 'online_payments',
    PAYMENT_LINKS = 'payment_links',
    VIRTUAL_TERMINAL = 'virtual_terminal',
    POS_AMEX = 'pos_amex',
    SETTLEMENTS = 'settlements',
    INVOICES = 'invoices',
    REPORTS = 'reports',
    PAYMENT_METHODS = 'payment_methods',
    TEAM_MANAGEMENT = 'team_management',
    EXPORTS = 'exports',
    LOGIN = 'login'
}
