import translations from './translations'

translations.add('es', {
    areYouSureYouWantTo: (c: boolean) =>
        `¿Estás seguro de que deseas ${c ? 'habilitar' : 'desactivar'} el método de pago?`,
    yes: 'Sí',
    no: 'No',
    active: 'Activo',
    disabled: 'Deshabilitado'
})
