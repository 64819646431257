import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import translations from '@/pages/Reports/translations/translations'
import {TransactionSummaryDoughnutChartCard} from '@/components/composite'
import {PosTransactionSummaryDoughnutChartCardStoreType} from '@/pages/Reports/components'
import {TSelectContainerStore} from '@/components/containers'

export const POSIssuerStoreSymbol = Symbol('POSIssuerStore')
export const POSIssuerStatusStoreSymbol = Symbol('POSIssuerStatusStore')

export const POSIssuerBanksDoughnutChartContainer = observer(() => {
    const store =
        useInjection<PosTransactionSummaryDoughnutChartCardStoreType>(POSIssuerStoreSymbol)
    const selectStore = useInjection<TSelectContainerStore>(POSIssuerStatusStoreSymbol)

    return (
        <TransactionSummaryDoughnutChartCard
            tooltipText={translations().issuingBanks}
            amountData={store.amountData}
            countData={store.countData}
            tabType={store.chartType}
            setChartType={store.setChartTabType}
            title={translations().issuingBanks}
            centerTitle={translations().volumeTotal}
            selectStatusProps={{
                value: selectStore.value,
                options: selectStore.options,
                onChange: selectStore.setValue
            }}
            loadingState={store.amountLoadingState}
            currency={store.currency}
        />
    )
})
