import React from 'react'
import {Typography} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Icon, Tabs} from '@/components'
import {PaymentLinkWidgetPreviewStore} from '@/stores/pay-by-link/PaymentLinkWidgetPreviewStore'
import {PayByLinkWidgetPreviewStoreSymbol} from '@/pages/PayByLink'
import translations from './translations'
import styles from './styles.scss'

import linkWebPreview from '~/assets/img/pay-by-link/linkPreview.svg'
import linkMobilePreview from '~/assets/img/pay-by-link/linkMobilePreview.svg'
import {PLATFORM_LAPTOP, PLATFORM_MOBILE} from '@/constants/playwright-ids'

const {Title, Text} = Typography

export const NewPaymentLinkWidgetPreview = observer(() => {
    const store = useInjection<PaymentLinkWidgetPreviewStore>(PayByLinkWidgetPreviewStoreSymbol)

    return (
        <>
            <Title level={4}>{translations().widgetPreview}</Title>
            <Text>{translations().thisIsHowYourClient}</Text>
            {
                store.type === 'laptop' && (
                    <img className={styles.previewImageLaptop} src={linkWebPreview} alt="link preview"/>
                )
            }
            {
                store.type === 'mobile' && (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img className={styles.previewImageMobile} src={linkMobilePreview} alt="link preview"/>
                    </div>
                )
            }
            <Tabs
                className={styles.tabs}
                tabs={[
                    {
                        title: <Icon type={'laptop'} data-test-id={PLATFORM_LAPTOP}/>,
                        key: 'laptop'
                    },
                    {
                        title: <Icon type={'mobile'} data-test-id={PLATFORM_MOBILE}/>,
                        key: 'mobile'
                    }
                ]}
                onChange={store.setType}
            />
        </>
    )
})
