import React from 'react'
import moment from 'moment'
import {Divider} from 'antd'
import {DATE_FORMAT, TIME_FORMAT} from '@/constants'
import {DateTimeLabelProps} from './props'

import styles from './styles.scss'

export const DateTimeLabel = ({date, dateFormat = DATE_FORMAT}: DateTimeLabelProps) => {
    if (!date) {
        return <div>-</div>
    }

    return (
        <span className='nowrap'>
            <span className={styles.date}>{moment(date).format(dateFormat)}</span>
            <Divider type={'vertical'} />
            {moment(date).format(TIME_FORMAT)}
        </span>
    )
}
