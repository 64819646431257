import {OnlinePaymentStatus} from '@/constants/online-payment-status'
import {POSPaymentStateStatus} from '@/constants/pos-payment-state-status'
import rootTranslations from '@/translations'

export const convertEcomStatusesToSelectItems = (statuses: OnlinePaymentStatus[]) =>
    statuses.map((item) => ({
        label: rootTranslations().constants.onlinePaymentStatus[item],
        value: item
    }))

export const convertPosStatesToSelectItems = (states: POSPaymentStateStatus[]) =>
    states.map((item) => ({
        label: rootTranslations().constants.posPaymentStateStatus[item],
        value: item
    }))
