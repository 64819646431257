import {
    CreatePayLinkRequest,
    getWithAuth,
    patchWithAuth,
    PaymentLink,
    PaymentLinkRequest,
    PaymentLinkResponse,
    postWithAuth,
    SendMessageRequest
} from '@/api'

export * from './models'

export const getPaymentLinks = (request: PaymentLinkRequest) =>
    getWithAuth<PaymentLinkResponse>('/oppapi/v1/payment-links', request)

export const createPaymentLink = (fields: CreatePayLinkRequest) =>
    postWithAuth<PaymentLink>('/oppapi/v1/payment-links', fields)

export const editLink = (id, data) =>
    patchWithAuth<PaymentLink>(`/oppapi/v1/payment-links/${id}`, data)

export const cancelLink = id =>
    postWithAuth<PaymentLink>(`/oppapi/v1/payment-links/${id}/cancellation`, null)

export const sendMessage = (id, data: SendMessageRequest) =>
    postWithAuth<null>(`/oppapi/v1/payment-links/${id}/message`, data)
