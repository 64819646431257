import React from 'react'
import translations from './translations'

translations.add('sl', {
    name: {
        ON_EACH_LOGIN: (
            <>
                <strong>Ob vsaki prijavi:</strong> Zahtevali bomo kodo za preverjanje
            </>
        ),
        TRUSTED_DEVICE: (
            <>
                <strong>Enkrat na napravo:</strong> Zahtevali bomo kodo za preverjanje samo enkrat
                vsakih 30 dni na zaupanja vredni napravi
            </>
        )
    },
    disabled: 'Onemogočeno'
})
