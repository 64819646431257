import translations from './translations'

translations.add('en', {
    pendingTransactions: number => `Pending transactions (${number})`,
    description: 'Description',
    sum: 'Sum',
    transactionStatus: {
        PROCESSED: 'Processed',
        PENDING: 'Pending',
        FAILED: 'Failed',
        CANDELLED: 'Cancelled'
    },
    all: 'All'
})
