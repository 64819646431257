import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    cookies: string
    cookiesContent: string
    descriptions: {
        name: string
        provider: string
        saveVisitorsPreferences: string
        cookieName: string
        cookieExpiry: string
        cookie: string
        ownerOfSite: string
        cookieByGoogleAdvanced: string
        purpose: string
        year: string
        googleTagManager: string
        googleLLC: string
        twoYears: string
        privacyPolicy: string
    }
}>()
