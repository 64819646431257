import translations from './translations'

translations.add('es', {
    showAll: 'Mostrar todo',
    previousRangeData: 'Anterior',
    currentRangeData: 'Actual',
    statuses: {
        AUTH: 'Pendiente',
        CHARGE: 'Cargado',
        REFUND: 'Reembolsado',
        REJECT: 'Rechazado'
    },
    dataTypes: {
        amount: 'Importe',
        count: 'Número'
    },
    week: 'Semana'
})
