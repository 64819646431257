import {inject, injectable} from 'inversify'
import {debounce} from 'lodash'
import moment from 'moment-timezone'
import {action, computed, makeObservable, observable, reaction, runInAction, when} from 'mobx'

import {LoadingState} from '@/types'
import {TSettlementsStore} from '@/pages/Settlements/TSettlementsStore'
import {PaginationContainerStoreType, TRangePickerContainerStore} from '@/components'
import {SettlementsFilterRangePickerContainerStoreSymbol} from '@/pages/Settlements/components/SettlementsTableFilter'
import {
    downloadStatementFile,
    downloadStatementFileOld,
    fetchSettlements,
    fetchSettlementsByDay
} from './api'
import {
    SettlementsFiltersStoreSymbol,
    SettlementsPaginationStoreSymbol,
    SettlementsPerDayPaginationStoreSymbol
} from '@/pages/Settlements'

import {
    checkIfContainsDate,
    dateToDayAndMonth,
    generateColumns,
    generatePerDayColumns
} from '@/stores/settlements/services/ui-utils'
import {GroupableItem} from '@/types/grid'
import {
    DATE_FORMAT,
    DEBOUNCE_MS,
    FileType,
    SERVER_DATE_FORMAT,
    TABLE_STORAGE_KEYS
} from '@/constants'
import {openErrorNotification, openSuccessNotification} from '@/utils'
import {SettlementPerDay} from '@/stores/settlements/models/SettlementPerDay'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import {AuthStoreInterface, AuthStoreSymbol, PeriodType} from '@/stores'
import {SettlementsRequest} from '~/code/stores/settlements/models/SettlementsRequest'
import {storage} from '@/services/storage'
import rootTranslations from '@/translations'

import {isDatesAreInRange} from '~/code/pages/Settlements/utils'
import {SettlementsFiltersStore} from './stores'
import {Settlement} from './models/Settlement'
import translations from './translations'

@injectable()
export class SettlementsStore implements TSettlementsStore<Settlement> {
    private _rangePickerStore: TRangePickerContainerStore
    private _paginationStore: PaginationContainerStoreType
    private _paginationPerDayStore: PaginationContainerStoreType
    private _filterStore: SettlementsFiltersStore
    private _profileStore: ProfileStoreInterface
    private _authStore: AuthStoreInterface

    loadingState: LoadingState = LoadingState.IDLE
    loadingPerDayState: LoadingState = LoadingState.IDLE
    downloadingState: LoadingState = LoadingState.IDLE

    isPdfSatementModalOpen = false
    isPdfSatementLoading = false
    totalColumns: any[] = []
    settlements: GroupableItem<Settlement>[] = []
    settlementsPerDay: GroupableItem<SettlementPerDay>[] = []
    downloadableTypes = [
        {key: FileType.PDF, label: rootTranslations().constants.fileType.PDF},
        {key: FileType.EXCEL, label: rootTranslations().constants.fileType.EXCEL},
        {key: FileType.CSV, label: rootTranslations().constants.fileType.CSV}
    ]

    get isLoading() {
        return (
            this._rangePickerStore.isLoading ||
            this._filterStore.isLoading ||
            this._filterStore.isDisabled ||
            this.loadingState === LoadingState.LOADING ||
            this.loadingPerDayState === LoadingState.LOADING
        )
    }

    get merchantId() {
        return this._profileStore?.merchant?.merchantId
    }

    get request() {
        return {
            from: this._rangePickerStore.startDate?.format(SERVER_DATE_FORMAT),
            to: this._rangePickerStore.endDate?.format(SERVER_DATE_FORMAT),
            ...this._filterStore.getFilterForBackend()
        }
    }

    get preferences() {
        return {
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate,
            pageSize: this._paginationStore.pageSize,
            pagePerDaySize: this._paginationPerDayStore.pageSize,
            filter: this._filterStore.submittedFilter
        }
    }

    constructor(
        @inject(SettlementsFilterRangePickerContainerStoreSymbol)
        rangePickerStore: TRangePickerContainerStore,
        @inject(SettlementsPaginationStoreSymbol) paginationStore: PaginationContainerStoreType,
        @inject(SettlementsPerDayPaginationStoreSymbol)
        paginationPerDayStore: PaginationContainerStoreType,
        @inject(SettlementsFiltersStoreSymbol) filterStore: SettlementsFiltersStore,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface,
        @inject(AuthStoreSymbol) authStore: AuthStoreInterface
    ) {
        this._rangePickerStore = rangePickerStore
        this._paginationStore = paginationStore
        this._paginationPerDayStore = paginationPerDayStore
        this._filterStore = filterStore
        this._profileStore = profileStore
        this._authStore = authStore

        makeObservable(this, {
            downloadingState: observable,
            loadingState: observable,
            loadingPerDayState: observable,
            isPdfSatementLoading: observable,
            isPdfSatementModalOpen: observable,
            settlements: observable,
            settlementsPerDay: observable,

            isLoading: computed,
            preferences: computed,

            setIsPdfSatementModalOpen: action.bound,
            setIsLoading: action.bound
        })

        reaction(
            () => ({loadingState: this.loadingState, loadingPerDayState: this.loadingPerDayState}),
            () => {
                const finalStates = [LoadingState.DONE, LoadingState.FAILED]
                if (
                    finalStates.includes(this.loadingState) &&
                    finalStates.includes(this.loadingPerDayState)
                ) {
                    this.setIsLoading(false)
                }
            }
        )

        /** reaction:preferences */
        reaction(
            () => this.preferences,
            debounce(() => {
                if (this._paginationStore.pageNumber === 1) {
                    this.loadSettlements()
                } else {
                    this._paginationStore.setPageNumber(1)
                }

                if (this._paginationPerDayStore.pageNumber === 1) {
                    this.loadSettlementsPerDay()
                } else {
                    this._paginationPerDayStore.setPageNumber(1)
                }
            }, DEBOUNCE_MS)
        )

        reaction(
            () => this._paginationStore.pageNumber,
            () => this.loadSettlements()
        )

        reaction(
            () => this._paginationPerDayStore.pageNumber,
            () => this.loadSettlementsPerDay()
        )

        when(
            () => Boolean(this.merchantId) && this._filterStore.loadingState === LoadingState.DONE,
            () => this.initializePreferences()
        )
    }

    private initializePreferences = () => {
        const profileEmail = storage.get('email')
        const storageKey = `${TABLE_STORAGE_KEYS.settlements}-${profileEmail}-${this.merchantId}`
        const value = storage.get(storageKey) && JSON.parse(storage.get(storageKey))

        if (value) {
            const {pageSize, pagePerDaySize, period: p, from, to, filter} = value
            const period = p as PeriodType

            if (pageSize) {
                this._paginationStore.setPageSize(pageSize)
            }

            if (pagePerDaySize) {
                this._paginationPerDayStore.setPageSize(pagePerDaySize)
            }

            if (period && period !== PeriodType.Custom) {
                this._rangePickerStore.setPeriod(period)
            } else if (from && to) {
                const momentTo = moment(to)
                const momentFrom = moment(from)

                if (isDatesAreInRange([momentFrom, momentTo])) {
                    this._rangePickerStore.setDates([momentFrom, momentTo])
                } else {
                    this._rangePickerStore.setPeriod(PeriodType.Yesterday)
                }
            }

            if (filter) {
                this._filterStore.setFilter(filter)
                this._filterStore.submittedFilter = filter
            }
        } else {
            /**
             * We need to trigger the data loading process if there is no filter value in the local storage.
             * Because the 'reaction:preferences' in the constructor was not activated.
             */
            this.loadSettlements()
            this.loadSettlementsPerDay()
        }

        reaction(
            () => this.preferences,
            debounce(() => {
                if (profileEmail) {
                    const period = this._rangePickerStore.period
                    storage.set(storageKey, JSON.stringify({...this.preferences, period}))
                }
            }, DEBOUNCE_MS)
        )
    }

    loadSettlements = async () => {
        if (!this._authStore.isAuthenticated || this.loadingState === LoadingState.LOADING) {
            return
        }

        this.setIsLoading(true)
        runInAction(() => (this.loadingState = LoadingState.LOADING))

        try {
            const {result, error} = await fetchSettlements({
                ...this.request,
                ...this._paginationStore.paging
            })

            if (error) {
                throw new Error(error.message)
            }

            runInAction(() => {
                if (result) {
                    const dateColumn = {}
                    const data: GroupableItem<Settlement>[] = []
                    result.data.forEach((item) => {
                        const date = moment(item.transactionDate, SERVER_DATE_FORMAT).format(
                            DATE_FORMAT
                        )
                        if (checkIfContainsDate(dateColumn, date)) {
                            data.push({groupTitle: dateToDayAndMonth(item.transactionDate)})
                        }
                        data.push(item)
                    })
                    this.settlements = data.map((dataItem, index) => ({
                        ...dataItem,
                        rowKey: `${dataItem?.transactionId}${index}`
                    }))
                    this._paginationStore.setTotal(result.totalCount)
                } else {
                    this.settlements = []
                    this._paginationStore.setTotal(0)
                }

                this.loadingState = LoadingState.DONE
            })
        } catch (e) {
            this.loadingState = LoadingState.FAILED
            openErrorNotification(rootTranslations().errors.general)
        }
    }

    loadSettlementsPerDay = async () => {
        if (!this._authStore.isAuthenticated || this.loadingPerDayState === LoadingState.LOADING) {
            return
        }

        this.setIsLoading(true)
        runInAction(() => (this.loadingPerDayState = LoadingState.LOADING))

        try {
            const {result, error} = await fetchSettlementsByDay({
                ...this.request,
                ...this._paginationPerDayStore.paging
            })

            if (error) {
                throw new Error(error.message)
            }

            runInAction(() => {
                if (result) {
                    this.settlementsPerDay = result.data.map((dataItem, index) => ({
                        ...dataItem,
                        rowKey: `${dataItem?.id}${index}`
                    }))
                    this._paginationPerDayStore.setTotal(result.totalCount)
                } else {
                    this.settlementsPerDay = []
                    this._paginationPerDayStore.setTotal(0)
                }

                this.loadingPerDayState = LoadingState.DONE
            })
        } catch (err) {
            runInAction(() => (this.loadingPerDayState = LoadingState.FAILED))
            openErrorNotification(rootTranslations().errors.general)
        }
    }

    loadAllSettlements = async () => {
        this.loadSettlements()
        this.loadSettlementsPerDay()
    }

    setIsLoading(isLoading: boolean) {
        this._rangePickerStore.setIsLoading(isLoading)
        this._filterStore.setIsDisabled(isLoading)
    }

    getColumns = (isMobile: boolean) => {
        return generateColumns(isMobile)
    }

    getPerDayColumns = () => {
        return generatePerDayColumns()
    }

    onDownloadItemSelect = async (format: FileType) => {
        if (format === FileType.PDF) {
            this.setIsPdfSatementModalOpen(true)
            return
        }

        if (this.downloadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => (this.downloadingState = LoadingState.LOADING))

        try {
            const currentMerchant = this._profileStore?.merchant
            const {error} = await downloadStatementFile(currentMerchant?.name || 'settlement', {
                ...this.request,
                format
            })

            runInAction(() => {
                if (error) {
                    this.downloadingState = LoadingState.FAILED
                    return openErrorNotification(error?.message)
                } else {
                    openSuccessNotification(translations().messages.successExportMessage)
                }
                this.downloadingState = LoadingState.DONE
            })
        } catch (err) {
            runInAction(() => {
                this.downloadingState = LoadingState.FAILED
                return openErrorNotification(err?.message)
            })
        }
    }

    downloadPdfStatement = async (data: Partial<SettlementsRequest>) => {
        this.isPdfSatementLoading = true

        const {period, from, to} = data
        const isOld = Boolean(from)

        const params = {
            mid: this.merchantId,
            format: FileType.PDF,
            ...(!isOld ? {period} : {}),
            ...(isOld
                ? {
                      from,
                      to
                  }
                : {})
        }

        try {
            const callback = isOld ? downloadStatementFileOld : downloadStatementFile
            const currentMerchantName = this._profileStore?.merchant?.name || 'settlement'

            await callback(currentMerchantName, params)
        } catch (e) {
            return openErrorNotification(e.message)
        } finally {
            this.isPdfSatementLoading = false
        }
    }

    setIsPdfSatementModalOpen(isOpen: boolean) {
        this.isPdfSatementModalOpen = isOpen
    }
}
