import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    recipientName: string
    recipientNumber: string
    recipientsNumber: string
    recipientEmail: string
    recipientsEmail: string
    errors: {
        recipientNumberRequired: string
        recipientNumberInvalid: string
        recipientEmailRequired: string
        recipientEmailInvalid: string
        recipientNameRequired: string
    }
}>()

