import translations from './translations'
import React from 'react'

translations.add('sl', {
    title: 'Google Pay',
    description: (
        <>
            Google Pay umožňuje spotrebiteľom jednoducho a rýchlo platiť za tovar a služby online.
            Spotrebitelia ukladajú informácie o svojich platobných kartách do svojej Peňaženky
            Google, ktorú je možné vyvolať zo svojich zariadení so systémom Android alebo vo webovom
            prehliadači Google Chrome.
            <br />
            <br />
            DNA Payments s potešením ponúka obchodníkom tento spôsob platby a tu ho možno povoliť
            alebo zakázať.
            <br />
            <br />
        </>
    )
})
