import translations from './translations'

translations.add('es', {
    title: 'Gestión de equipos',
    inviteTeammate: 'Añadir usuario',
    role: 'Rol',
    all: 'Todos',
    active: 'Invitado',
    inactive: 'Invitación aceptada',
    statuses: {
        ok: 'Activo',
        invited: 'Invitado',
        validateEmail: 'Validar correo electrónico',
        changePassword: 'Cambiar contraseña',
        block: 'Bloqueado'
    },
    authMethods: {
        password: 'Contraseña',
        twoFAApp: '2FA (Aplicación Autenticador)',
        twoFAEnforced: '2FA (Obligatorio)'
    },
    notifications: {
        userSuccessfullyInvited: 'Usuario invitado correctamente'
    }
})
