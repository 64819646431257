import {getWithAuth, postWithAuth} from '@/api'
import {TCountable} from '@/types'
import {
    ECOMClients,
    ECOMSummaryResponse,
    EpaySummary,
    EpaySummaryRequest,
    POSSummaryResponse
} from '@/stores/reports/models'
import {DoughnutChartType} from '@/pages/Reports/components'
import {POSTopSummaryRequest} from '@/stores'

export type ECOMTransactionSummaryRequest = {
    from: string
    to: string
    currency?: string
    interval?: string
    status?: string
}

export type ECOMTransactionSummaryItem = {
    amount: number
    currency: string
    date: string
} & TCountable

export type PosTransactionSummaryItem = {
    amount: number
    currency: string
    value: string
} & TCountable

export type ECOMTransactionSummaryResponse = ECOMTransactionSummaryItem[]

export type POSSummary = ECOMSummaryResponse

export type POSDoughnutTransactionSummaryResponse = {
    all: POSSummary
    successful: POSSummary
    failed: POSSummary
}

export const getECOMTransactionsSummary = (request: ECOMTransactionSummaryRequest) =>
    getWithAuth<ECOMTransactionSummaryResponse>('/oppapi/v1/ecom/transactions/summary', request)

export const getPOSTransactionsSummary = (request: ECOMTransactionSummaryRequest) =>
    postWithAuth<POSSummaryResponse>('/oppapi/v1/pos/transactions/summary', request)

export const getPOSTopSummaryByType = (request: POSTopSummaryRequest, type: DoughnutChartType) => {
    switch (type) {
        case DoughnutChartType.CardScheme:
            return postWithAuth<POSDoughnutTransactionSummaryResponse>(
                `/oppapi/v1/pos/transactions/top-summary/${type}`,
                request
            )
        case DoughnutChartType.Issuer:
            return postWithAuth<POSDoughnutTransactionSummaryResponse>(
                `/oppapi/v1/pos/transactions/top-summary/${type}`,
                request
            )
        case DoughnutChartType.PaymentMethod:
            return getWithAuth<POSDoughnutTransactionSummaryResponse>(
                `/oppapi/v1/pos/transactions/top-summary/${type}`,
                request
            )
    }
}

export const getEpaySummary = (request: EpaySummaryRequest) =>
    getWithAuth<EpaySummary>('/oppapi/v1/ecom/transactions/status-summary', request)

export const getEpaySummaryPaymentMethods = (request, type) =>
    getWithAuth<ECOMSummaryResponse>(`/oppapi/v1/ecom/transactions/top-summary/${type}`, request)

export const getEpayClients = request =>
    getWithAuth<ECOMClients>('/oppapi/v1/ecom/transactions/customer-summary', request)
