import translations from './translations'

translations.add('sl', {
    areYouSureYouWantTo: (c: boolean) =>
        `Si si istý, že chceš ${c ? 'povoliť' : 'deaktivovať'} spôsob platby?`,
    yes: 'Áno',
    no: 'Nie',
    active: 'Aktívny',
    disabled: 'Vypnutý'
})
