import translations from './translations'

translations.add('es', {
    cookies: 'Preferencias de cookies',
    cookiesContent:
        'En nuestro sitio web utilizamos cookies. Algunas de ellas son esenciales, mientras que otras nos ayudan a mejorar este sitio web y su experiencia.',
    acceptAll: 'Aceptar todo',
    save: 'Guardar',
    back: 'Atrás',
    essential: 'Esenciales',
    essentialContent:
        'Las cookies esenciales permiten funciones básicas y son necesarias para que el sitio web funcione correctamente.',
    statistics: 'Estadísticas',
    statisticsContent:
        'Las cookies estadísticas recopilan información de forma anónima. Esta información nos ayuda a comprender cómo utilizan nuestro sitio web nuestros visitantes.',
    cookieInformation: 'Información sobre cookies'
})
