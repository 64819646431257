import {hasPermissions, hasProducts} from '@/stores/auth/services/utils'
import {MenuDataItemWithProduct} from '@/stores/auth/types/MenuDataItemWithProduct'

export const filterMenuItemsByPermissions = (
    menuItems: MenuDataItemWithProduct[]
): MenuDataItemWithProduct[] =>
    menuItems.reduce((visibleItems: MenuDataItemWithProduct[], m) => {
        if (m.children) {
            const children = filterMenuItemsByPermissions(m.children)
            if (children.length === 0) return visibleItems
            return [...visibleItems, {...m, children}]
        }

        if (hasPermissions(m.permissions)) {
            return [...visibleItems, m]
        }
        return visibleItems
    }, [])

export const filterMenuItemsByProducts = (
    menuItems: MenuDataItemWithProduct[]
): MenuDataItemWithProduct[] =>
    menuItems.reduce((visibleItems: MenuDataItemWithProduct[], m) => {
        if (m.children) {
            const children = filterMenuItemsByProducts(m.children)
            if (children.length === 0) return visibleItems
            return [...visibleItems, {...m, children}]
        }

        if (hasProducts(m.products)) {
            return [...visibleItems, m]
        }
        return visibleItems
    }, [])
