import {ChartOptions} from 'chart.js'
import {injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import moment from 'moment'
import {TRangePickerContainerStore, TSelectContainerStore} from '@/components/containers'
import {SelectItem} from '@/components/dumb/Select'
import {Colors, Currencies, POSPaymentStateStatus} from '@/constants'
import {barChartConfig} from '@/services/chart-config'
import {TTransactionsComparisonCardWithBarChartContainerStore} from '@/components/containers/TransactionsComparisonCardWithBarChartContainer'
import {
    AuthStoreInterface,
    convertToMoment,
    getPosMostUsedDefaultCurrency,
    POSSummaryResponse
} from '@/stores'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol,
    PosStoreSelectStore
} from '@/stores/store-and-terminals'
import {LoadingState} from '@/types'
import {openErrorNotification} from '@/utils'
import {POSTransactionStatuses} from '@/stores/reports/services/generators'
import {emptyChartDataGenerator} from '@/stores/reports/services/mocks'
import {generateInterval, getIntervalValue} from '@/stores/reports/services/date-utils'
import {getPOSTransactionsSummary} from '@/stores/reports/services/fetchers'
import {generateChartTitleForTwoRanges} from '@/stores/reports/TransactionsComparisonCardWithBarChartContainerStore/services/date-utils'
import rootTranslations from '@/translations'
import translations from './translations'
import {Interval} from '@/stores/reports/models/Interval'
import {subscribe} from 'dna-react-ioc'

@injectable()
export class POSTransactionsComparisonCardWithBarChartContainerStore
    implements TTransactionsComparisonCardWithBarChartContainerStore
{
    private _authStore: AuthStoreInterface
    private _rangePickerStore: TRangePickerContainerStore
    private _currencySelectStore: TSelectContainerStore
    private readonly _merchantStoresStore: AvailableStoresDataStore
    private readonly _storeSelectStore: PosStoreSelectStore

    constructor(
        authStore: AuthStoreInterface,
        rangePickerStore: TRangePickerContainerStore,
        currencySelectStore: TSelectContainerStore,
        storeSelectStore: PosStoreSelectStore
    ) {
        this._authStore = authStore
        this._rangePickerStore = rangePickerStore
        this._currencySelectStore = currencySelectStore
        this._merchantStoresStore = subscribe<AvailableStoresDataStore>(
            AvailableStoresDataStoreSymbol
        )
        this._storeSelectStore = storeSelectStore

        makeObservable(this, {
            _data: observable,
            dataLoadingState: observable,
            selectedDataType: observable,
            selectedStatus: observable,
            allTransactions: observable,
            data: computed,
            currentInterval: computed,
            previousInterval: computed,
            currency: computed,
            currentDateRange: computed,
            previousDateRange: computed,
            setSelectedStatus: action,
            setSelectedDataType: action
        })

        reaction(
            () => ({
                previousRangeFrom: this._rangePickerStore.prevDates.startDate,
                previousRangeTo: this._rangePickerStore.prevDates.startDate,
                currentRangeFrom: this._rangePickerStore.startDate,
                currentRangeTo: this._rangePickerStore.endDate,
                currency: this._currencySelectStore.value,
                isTerminalsLoaded: this._storeSelectStore.loadingState === LoadingState.DONE,
                store: this._storeSelectStore.value
            }),
            ({
                previousRangeFrom,
                previousRangeTo,
                currentRangeFrom,
                currentRangeTo,
                currency,
                isTerminalsLoaded
            }) => {
                if (
                    previousRangeFrom &&
                    previousRangeTo &&
                    currentRangeFrom &&
                    currentRangeTo &&
                    currency &&
                    this._authStore.isAuthenticated &&
                    isTerminalsLoaded
                ) {
                    this.loadData()
                }
            }
        )
    }

    _data = {
        all: {amount: undefined, count: undefined},
        successful: {amount: undefined, count: undefined},
        failed: {amount: undefined, count: undefined}
    }
    selectedDataType = this.dataTypes[0]
    dataLoadingState = LoadingState.IDLE
    selectedStatus: POSPaymentStateStatus = POSTransactionStatuses[0]
    statuses: SelectItem[] = POSTransactionStatuses.map((item) => ({
        label: rootTranslations().constants.posPaymentStateStatus[item],
        value: item
    }))
    previousRangeColour = Colors.YELLOW
    currentRangeColour = Colors.GREEN
    allTransactions = {}

    get dataTypes(): {key: string; title: string}[] {
        return [
            {key: 'amount', title: translations().dataTypes.amount},
            {key: 'count', title: translations().dataTypes.count}
        ]
    }

    get currency() {
        return this._currencySelectStore.value as Currencies
    }

    get currentDateRange() {
        return {
            from: convertToMoment(this._rangePickerStore.startDate),
            to: convertToMoment(this._rangePickerStore.endDate)
        }
    }

    get previousDateRange() {
        return {
            from: convertToMoment(this._rangePickerStore.prevDates.startDate),
            to: convertToMoment(this._rangePickerStore.prevDates.endDate)
        }
    }

    getResponseDataByStatus(
        response: POSSummaryResponse,
        responseForPrevPeriod: POSSummaryResponse,
        status: POSPaymentStateStatus
    ) {
        const labels =
            this.currentInterval === Interval.DAY
                ? response[status]?.map(
                      (item, index) =>
                          `${getIntervalValue({
                              interval: this.currentInterval,
                              date: moment(responseForPrevPeriod[status][index]?.value)
                          })}/${getIntervalValue({
                              interval: this.currentInterval,
                              date: moment(item.value)
                          })}`
                  )
                : response[status]?.map((item, index) =>
                      getIntervalValue({
                          interval: this.currentInterval,
                          date: moment(item.value)
                      })
                  )

        return {
            amount: {
                labels,
                datasets: [
                    {
                        label: translations().previousRangeData,
                        data: responseForPrevPeriod[status]?.map((item) => item.amount),
                        currency: this._currencySelectStore.value,
                        dates: responseForPrevPeriod[status]?.map((item) => item.value),
                        backgroundColor: [Colors.YELLOW]
                    },
                    {
                        label: translations().currentRangeData,
                        data: response[status]?.map((item) => item.amount),
                        currency: this._currencySelectStore.value,
                        dates: response[status]?.map((item) => item.value),
                        backgroundColor: [Colors.GREEN]
                    }
                ]
            },
            count: {
                labels,
                datasets: [
                    {
                        label: translations().previousRangeData,
                        data: responseForPrevPeriod[status]?.map((item) => item.count),
                        backgroundColor: [Colors.YELLOW],
                        dates: responseForPrevPeriod[status]?.map((item) => item.value)
                    },
                    {
                        label: translations().currentRangeData,
                        data: response[status]?.map((item) => item.count),
                        backgroundColor: [Colors.GREEN],
                        dates: response[status]?.map((item) => item.value)
                    }
                ]
            }
        }
    }

    get currentInterval() {
        return generateInterval({
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate
        })
    }

    get previousInterval() {
        return generateInterval({
            from: this._rangePickerStore.prevDates.startDate,
            to: this._rangePickerStore.prevDates.endDate
        })
    }

    async loadData() {
        if (this.dataLoadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.dataLoadingState = LoadingState.LOADING
        })

        const requestForCurrentRange = {
            from: this._rangePickerStore.startDate?.format(),
            to: this._rangePickerStore.endDate?.format(),
            interval: this.currentInterval,
            status: this.selectedStatus,
            currency: this._currencySelectStore.value,
            ...this._storeSelectStore.requestForServer
        }

        const requestForPreviousRange = {
            from: this._rangePickerStore.prevDates.startDate?.format(),
            to: this._rangePickerStore.prevDates.endDate?.format(),
            interval: this.previousInterval,
            status: this.selectedStatus,
            currency: this._currencySelectStore.value,
            ...this._storeSelectStore.requestForServer
        }

        try {
            await runInAction(async () => {
                const {result, error} = await getPOSTransactionsSummary(requestForCurrentRange)
                const {result: resultForPreviousRange, error: errorForPreviousRange} =
                    await getPOSTransactionsSummary(requestForPreviousRange)

                if (error || errorForPreviousRange || !result) {
                    this._data = {
                        all: {amount: undefined, count: undefined},
                        successful: {amount: undefined, count: undefined},
                        failed: {amount: undefined, count: undefined}
                    }

                    this.allTransactions = {
                        all: [],
                        successful: [],
                        failed: []
                    }
                }

                if (error || errorForPreviousRange) {
                    this.dataLoadingState = LoadingState.FAILED
                    return openErrorNotification(error?.message || errorForPreviousRange?.message)
                } else if (!result) {
                    this.dataLoadingState = LoadingState.FAILED
                    return
                }

                this.allTransactions = result

                this._data = {
                    all: this.getResponseDataByStatus(
                        result,
                        resultForPreviousRange,
                        POSPaymentStateStatus.all
                    ),
                    successful: this.getResponseDataByStatus(
                        result,
                        resultForPreviousRange,
                        POSPaymentStateStatus.successful
                    ),
                    failed: this.getResponseDataByStatus(
                        result,
                        resultForPreviousRange,
                        POSPaymentStateStatus.failed
                    )
                }

                this.dataLoadingState = LoadingState.DONE
            })
        } catch (error) {
            this._data = {
                all: {amount: undefined, count: undefined},
                successful: {amount: undefined, count: undefined},
                failed: {amount: undefined, count: undefined}
            }
            this.allTransactions = {
                all: [],
                successful: [],
                failed: []
            }
            this.dataLoadingState = LoadingState.FAILED
            return openErrorNotification(error?.message)
        }
    }

    get data() {
        if (!this._currencySelectStore.value && this._merchantStoresStore.ecomStores) {
            this._currencySelectStore.setValue(
                getPosMostUsedDefaultCurrency(this._merchantStoresStore.ecomStores)
            )
        }

        if (this._storeSelectStore.loadingState !== LoadingState.DONE) {
            return this.getEmptyValues()
        }

        const currentData = this._data[this.selectedStatus]
        if (
            (!currentData || !currentData?.amount || !currentData?.count) &&
            this.dataLoadingState !== LoadingState.LOADING &&
            this.dataLoadingState !== LoadingState.DONE &&
            this.dataLoadingState !== LoadingState.FAILED
        ) {
            this.loadData()
        }

        if (!currentData?.amount && !currentData?.count) {
            return this.getEmptyValues()
        }

        return {
            data: currentData[this.selectedDataType.key],
            options: barChartConfig.barCompareOptions({
                title: generateChartTitleForTwoRanges({
                    previousRangeFrom: this._rangePickerStore.prevDates.startDate,
                    previousRangeTo: this._rangePickerStore.prevDates.endDate,
                    currentRangeFrom: this._rangePickerStore.startDate,
                    currentRangeTo: this._rangePickerStore.endDate
                }),
                renderTooltipTitle: (tooltipItem) => {
                    const [tooltip] = tooltipItem
                    return getIntervalValue({
                        interval: this.currentInterval,
                        date: moment(tooltip.dataset.dates[tooltip.dataIndex])
                    })
                }
            }) as ChartOptions
        }
    }

    getEmptyValues = () => {
        const options = barChartConfig.barOptions({
            title: generateChartTitleForTwoRanges({
                previousRangeFrom: this._rangePickerStore.prevDates.startDate,
                previousRangeTo: this._rangePickerStore.prevDates.endDate,
                currentRangeFrom: this._rangePickerStore.startDate,
                currentRangeTo: this._rangePickerStore.endDate
            }),
            showTooltip: false
        }) as ChartOptions

        return {
            data: emptyChartDataGenerator(
                this.currentDateRange,
                generateInterval({
                    from: this._rangePickerStore.startDate,
                    to: this._rangePickerStore.endDate
                }),
                true
            ),
            options
        }
    }

    setSelectedStatus = (status: POSPaymentStateStatus) => {
        this.selectedStatus = status
    }

    setSelectedDataType = (dataTypeKey: string) => {
        this.selectedDataType = this.dataTypes.find((item) => (item.key = dataTypeKey))
    }
}
