import translations from './translations'

translations.add('en', {
    paymentDetails: 'Payment details',
    date: 'Date',
    transactionDate: 'Transaction date (GMT)',
    amount: 'Amount',
    status: 'Status',
    terminalId: 'Terminal ID',
    card: 'Card',
    details: 'Details',
    resultCode: 'Result code',
    message: 'Message',
    cardType: 'Card type',
    cardCategory: 'Card category',
    corporate: 'Corporate',
    personal: 'Personal',
    europeanCard: 'European card',
    captureMethod: 'Capture method',
    paymentMethod: 'Payment method',
    transaction: {
        id: 'Transaction ID',
        type: 'Transaction type',
        details: 'Transaction details',
        country: 'Transaction country',
        city: 'Transaction city'
    },
    currency: 'Currency',
    isCorporateCard: 'Card category',
    returnCode: 'Result code',
    returnCodeDescription: 'Message',
    mid: 'MID',
    issuerRegion: 'Issuer region',
    cardScheme: 'Card scheme',
    cardMask: 'Card mask'
})
