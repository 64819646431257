import { BasePOSTransactionType } from '@/types/transactions'
import rootTranslations from '@/translations'

export const getPOSTransactionType = ({transactionType, operation}: BasePOSTransactionType) => {
    switch (transactionType) {
        case 'retail':
        case 'unique':
            switch (operation) {
                case 'request':
                    return rootTranslations().transactionTypes.AUTH
                case 'advice':
                    return rootTranslations().transactionTypes.SALE
                case 'adjustment':
                    return rootTranslations().transactionTypes.ADJUSTMENT
                case 'reversal':
                    return rootTranslations().transactionTypes.REFUND
            }
        case 'credit':
        case 'credit-notif':
        case 'credit-apm':
            switch (operation) {
                case 'advice':
                    return rootTranslations().transactionTypes.REFUND
            }
        case 'retail-apm':
            switch (operation) {
                case 'advice':
                    return rootTranslations().transactionTypes.SALE
            }
    }

    return '-'
}
