import React from 'react'
import {Form, FormInstance, FormItemProps, Input, InputProps} from 'antd'
import {isEmpty} from 'dna-common'
import {Currencies, MAX_AMOUNT_LENGTH} from '@/constants'
import {AMOUNT_PATTERN, START_ZERO} from '@/constants/patterns'
import {addZeroes, getCurrencySymbol, isInteger} from '@/utils'
import translations from './translations'
import {AMOUNT_INPUT} from '@/constants/playwright-ids'

type Props = {
    currency?: Currencies
    form: FormInstance
    inputProps?: InputProps
    shouldAcceptZero?: boolean
} & FormItemProps

export const AmountFormField: React.FC<Props> = (props) => {
    const {
        form,
        name = 'amount',
        currency = Currencies.GBP,
        inputProps,
        rules = [],
        shouldAcceptZero = true,
        ...rest
    } = props

    return (
        <Form.Item
            name={name}
            label={translations().amount}
            rules={[
                {required: true, message: translations().errors.amountRequired},
                {
                    validator: (field, value) => {
                        if (parseFloat(value) === 0 && shouldAcceptZero) {
                            return Promise.resolve()
                        }

                        if ((!value && value !== 0) || parseFloat(value) > 0) {
                            return Promise.resolve()
                        }

                        return Promise.reject(new Error(translations().errors.amountInvalid))
                    }
                },
                ...rules
            ]}
            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                const onlyNumberRegexp = AMOUNT_PATTERN
                const value = e.currentTarget.value
                if (!onlyNumberRegexp.test(value) && !isEmpty(value)) {
                    return form.getFieldValue(name)
                } else if (isInteger(value) && START_ZERO.test(value)) {
                    return parseFloat(value)
                } else if (!isInteger(value) && START_ZERO.test(value.split('.')[0])) {
                    return `${parseInt(value.split('.')[0], 10)}.${
                        value.split('.')[1] ? value.split('.')[1] : ''
                    }`
                } else if (value === '.') {
                    return '0.'
                }
                return value
            }}
            {...rest}
        >
            <Input
                placeholder='0.00'
                data-test-id={AMOUNT_INPUT}
                maxLength={MAX_AMOUNT_LENGTH}
                onBlur={() => {
                    if (form.getFieldValue(name)) {
                        form.setFieldsValue({
                            [name.toString()]: addZeroes(Number(form.getFieldValue(name)))
                        })
                        form.validateFields([name])
                    }
                }}
                prefix={getCurrencySymbol(currency)}
                {...inputProps}
            />
        </Form.Item>
    )
}
