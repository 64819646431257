import translations from './translations'
import React from 'react'

translations.add('es', {
    title: 'Open Banking',
    description: <>
        Open Banking es una forma segura de recibir pagos por bienes y servicios directamente desde la cuenta bancaria del consumidor.
        <br/><br/>
        Al activar Open Banking, los consumidores tendrán la opción de elegir su proveedor bancario (entre más de 50 entidades financieras, una lista que no para de crecer) y realizar el pago en unos sencillos pasos. Los fondos se cargarán en la cuenta al instante, lo que ayudará a gestionar los saldos disponibles de forma más eficiente.
        <br/><br/>
        DNA Payments se enorgullece de haberse asociado con Ecospend, proveedor líder de servicios de banca abierta, para poner este servicio a disposición de nuestra base de clientes.
        <br/><br/>
    </>
})
