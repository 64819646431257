import translations from './translations'
import React from 'react'

translations.add('ro', {
    title: 'Google Pay',
    description: <>
        Google Pay permite consumatorilor să plătească ușor și rapid online pentru bunuri și servicii. Consumatorii își stochează informațiile cardului de plată în portofelul lor Google, pe care îl pot accesa de pe dispozitivele Android sau prin intermediul browserului web Google Chrome.
        <br/><br/>
        DNA Payments este încântată să ofere această metodă de plată comercianților, iar aceasta poate fi activată sau dezactivată aici.
        <br/><br/>
    </>
})
