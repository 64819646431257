import translations from './translations'

translations.add('sl', {
    labels: {
        newPaymentLink: 'Nové platobné prepojenie',
        date: 'Dátum',
        customer: 'Zákazník',
        transactionType: 'Typ transakcie',
        amount: 'Množstvo',
        customerName: 'Meno zákazníka',
        name: 'Meno',
        description: 'Popis',
        nameOfTheServiceOrItemYouProvide: 'Názov služby alebo položky, ktorú poskytujete',
        linkExpiration: 'Platnosť odkazu',
        stores: 'Predajne',
        theNewPaymentLinkIsCreatedAndCopiedToClipboard:
            'Vytvorí sa nový platobný odkaz a skopíruje sa do schránky',
        recurring: 'Počiatočná platba (opakovaná)'
    },
    buttons: {
        createNewLink: 'Vytvoriť nový odkaz',
        close: 'Zavri'
    },
    errors: {
        storeRequired: 'Vyžaduje sa predajňa',
        orderNumberRequired: 'Vyžaduje sa číslo objednávky',
        descriptionRequired: 'Vyžaduje sa popis',
        linkExpirationRequired: 'Vyžaduje sa uplynutie platnosti odkazu',
        customerNameRequired: 'Vyžaduje sa meno zákazníka'
    },
    ranges: {
        hour24: '24 hodín',
        hour48: '48 hodín',
        week1: '1 týždeň',
        month1: '1 mesiac'
    },
    tooltips: {
        recurring:
            'Táto platba bude označená ako prvá zo série budúcich platieb iniciovaných obchodníkom alebo zákazníkom.'
    },
    selectDate: 'Vyberte dátum'
})
