import {TranslationBuilder} from '@/components/dumb/Translation'
import {TransactionStatus} from '@/stores'

export default TranslationBuilder.create<{
    pendingTransactions: (number: number) => string
    description: string
    sum: string
    transactionStatus: {
        [key in keyof typeof TransactionStatus]: string
    }
    all: string
}>()
