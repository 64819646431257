import translations from './translations'

translations.add('en', {
    title: 'Onboarding process',
    initialCheck: 'Initial check',
    initialCheckDescription: 'The questions from manager to clarify your business details',
    actionRequired: 'Action required',
    processTakesDays: 'Process takes 1 day',
    documentsUpload: 'Documents upload',
    finalCheck: 'Final check',
    agreement: 'Agreement',
    activation: 'Activation',
    submit: 'Submit',
    cancel: 'Cancel',
    initialCheckSuccessMessage: 'Thank you! We are now reviewing information you have provided.',
    thankYouMessage: 'Thank you! We are now reviewing information you have provided.',
    documentsUploadDescription: 'Please upload your supporting documents: proof of ID, proof of address and a bank statement',
    finalCheckDescription: 'Please provide additional information',
    back: 'Back'
})
