import {injectable} from 'inversify'
import {ChartOptions} from 'chart.js'
import moment from 'moment'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import {AuthStoreInterface, ECOMTransactionSummaryResponse} from '@/stores'
import {LoadingState} from '@/types'
import {getAmount, openErrorNotification} from '@/utils'
import {Currencies, OnlinePaymentStatus} from '@/constants'
import {
    TRangePickerContainerStore,
    TSelectContainerStore,
    TTransactionsCardWithBarChartContainerStore,
    TTransactionsComparisonCardWithBarChartContainerStore
} from '@/components/containers'
import {barChartConfig} from '@/services/chart-config'
import {
    generateChartTitleForRange,
    generateInterval,
    getIntervalValue
} from '@/stores/reports/services/date-utils'
import {
    ECOMTransactionSummaryItem,
    getECOMTransactionsSummary
} from '@/stores/reports/services/fetchers'
import {emptyChartDataGenerator} from '@/stores/reports/services/mocks'
import translations from './translations'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import {
    calculateTotalAmount,
    calculateTotalCount
} from '@/stores/reports/POSTransactionsCardWithBarChartContainerStore/services/utils'
import {EcomStoreSelectStore} from '@/stores/store-and-terminals'

@injectable()
export class ECOMTransactionsCardWithBarChartContainerStore
    implements TTransactionsCardWithBarChartContainerStore
{
    private _authStore: AuthStoreInterface
    private _rangePickerStore: TRangePickerContainerStore
    private _currencySelectStore: TSelectContainerStore
    private _ecomOverviewTransactionsComparisonCardWithBarChartStore: TTransactionsComparisonCardWithBarChartContainerStore
    private _storeSelectStore: EcomStoreSelectStore
    private _statusSelectStore: TSelectContainerStore

    transactionValue: number
    dailyValue: number
    transactionsPerDay: number
    _shouldFetchData: boolean

    constructor(
        authStore: AuthStoreInterface,
        rangePickerStore: TRangePickerContainerStore,
        currencySelectStore: TSelectContainerStore,
        shouldFetchData: boolean,
        ecomOverviewTransactionsComparisonCardWithBarChartStore: TTransactionsComparisonCardWithBarChartContainerStore,
        storeSelectStore: EcomStoreSelectStore,
        statusSelectStore: TSelectContainerStore
    ) {
        this._authStore = authStore
        this._rangePickerStore = rangePickerStore
        this._currencySelectStore = currencySelectStore
        this._ecomOverviewTransactionsComparisonCardWithBarChartStore =
            ecomOverviewTransactionsComparisonCardWithBarChartStore
        this._storeSelectStore = storeSelectStore
        this._statusSelectStore = statusSelectStore
        this.transactionValue = null
        this.dailyValue = null
        this.transactionsPerDay = null
        this._shouldFetchData = shouldFetchData

        makeObservable(this, {
            _data: observable,
            transactionValue: observable,
            dailyValue: observable,
            transactionsPerDay: observable,
            _shouldFetchData: observable,
            data: computed,
            currency: computed,
            dateRange: computed,
            selectedStatus: computed,
            dataLoadingState: observable,
            selectedDataType: observable,
            setSelectedDataType: action,
            handleCarouselWidgetClick: action,
            setData: action
        })

        reaction(
            () => ({
                from: this._rangePickerStore.startDate,
                to: this._rangePickerStore.endDate,
                selectedStatus: this.selectedStatus,
                currency: this._currencySelectStore.value,
                storeId: this._storeSelectStore.value
            }),
            ({from, to, selectedStatus, currency}) => {
                if (
                    from &&
                    to &&
                    selectedStatus &&
                    currency &&
                    this._authStore.isAuthenticated &&
                    shouldFetchData
                ) {
                    this.loadData()
                }
            }
        )

        reaction(
            () => ({
                data: this._ecomOverviewTransactionsComparisonCardWithBarChartStore.allTransactions,
                loadingState:
                    this._ecomOverviewTransactionsComparisonCardWithBarChartStore.dataLoadingState
            }),
            ({data, loadingState}) => {
                if (!shouldFetchData) {
                    this.dataLoadingState = loadingState
                    this.setData(data as ECOMTransactionSummaryResponse)
                    this.averageMetricsHandler(data as ECOMTransactionSummaryResponse)
                }
            }
        )
    }

    _data = {amount: undefined, count: undefined}
    selectedDataType = this.dataTypes[0]
    dataLoadingState = LoadingState.IDLE

    get selectedStatus() {
        return this._statusSelectStore.value as OnlinePaymentStatus
    }

    get statuses() {
        return this._statusSelectStore.options
    }

    get dataTypes(): {key: string; title: string}[] {
        return [
            {key: 'amount', title: translations().dataTypes.amount},
            {key: 'count', title: translations().dataTypes.count}
        ]
    }

    get interval() {
        return generateInterval({
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate
        })
    }

    get dateRange() {
        return {
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate
        }
    }

    get currency() {
        return this._currencySelectStore.value as Currencies
    }

    averageMetricsHandler(data: ECOMTransactionSummaryItem[]) {
        const totalAmount = calculateTotalAmount(data)
        const totalTransactions = calculateTotalCount(data)

        const period =
            this._rangePickerStore.endDate.diff(this._rangePickerStore.startDate, 'days') + 1

        runInAction(() => {
            this.transactionValue = getAmount(totalAmount / totalTransactions)
            this.dailyValue = getAmount(totalAmount / period)
            this.transactionsPerDay = Math.floor(totalTransactions / period)
        })
    }

    setData = (transactions: ECOMTransactionSummaryResponse) => {
        this._data = {
            amount: {
                labels: transactions?.map((item) =>
                    getIntervalValue({
                        interval: this.interval,
                        date: moment(item.date)
                    })
                ),
                datasets: [
                    {
                        label: this._currencySelectStore.value,
                        currency: this._currencySelectStore.value,
                        data: transactions?.map((item) => item.amount)
                    }
                ]
            },
            count: {
                labels: transactions?.map((item) =>
                    getIntervalValue({
                        interval: this.interval,
                        date: moment(item.date)
                    })
                ),
                datasets: [
                    {
                        label: this._currencySelectStore.value,
                        data: transactions?.map((item) => item.count)
                    }
                ]
            }
        }
    }

    async loadData() {
        if (this.dataLoadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.dataLoadingState = LoadingState.LOADING
        })

        const storeId = this._storeSelectStore.value
        const request = {
            from: this._rangePickerStore.startDate.format(),
            to: this._rangePickerStore.endDate.format(),
            interval: this.interval,
            status: this.selectedStatus,
            currency: this._currencySelectStore.value,
            ...(storeId ? {storeId} : {})
        }

        const allTransactionsRequest = {
            from: this._rangePickerStore.startDate.format(),
            to: this._rangePickerStore.endDate.format(),
            status: OnlinePaymentStatus.charge,
            interval: this.interval,
            currency: this._currencySelectStore.value,
            ...(storeId ? {storeId} : {})
        }

        await runInAction(async () => {
            const {result, error} = await getECOMTransactionsSummary(request)

            const {result: allTransactionsResult, error: allTransactionsError} =
                await getECOMTransactionsSummary(allTransactionsRequest)

            if (error || allTransactionsError) {
                this.dataLoadingState = LoadingState.FAILED
                return openErrorNotification(error.message)
            }

            this.averageMetricsHandler(allTransactionsResult)
            this.setData(result)

            this.dataLoadingState = LoadingState.DONE
        })
    }

    get data() {
        if (
            this.dataLoadingState !== LoadingState.LOADING &&
            this.dataLoadingState !== LoadingState.FAILED &&
            this.dataLoadingState !== LoadingState.DONE &&
            this._shouldFetchData
        ) {
            this.loadData()
        }

        if (!this._data || !this._data.amount || !this._data.count) {
            const options = barChartConfig.barOptions({
                title: generateChartTitleForRange({
                    from: this._rangePickerStore.startDate,
                    to: this._rangePickerStore.endDate
                }),
                showTooltip: false
            }) as ChartOptions

            return {data: emptyChartDataGenerator(this.dateRange, this.interval), options}
        }

        return {
            data: this._data[this.selectedDataType.key],
            options: barChartConfig.barOptions({
                title: generateChartTitleForRange({
                    from: this._rangePickerStore.startDate,
                    to: this._rangePickerStore.endDate
                })
            }) as ChartOptions
        }
    }

    setSelectedStatus = (status: OnlinePaymentStatus) => {
        this._statusSelectStore.setValue(status)
    }

    setSelectedDataType = (dataTypeKey: string) => {
        this.selectedDataType = this.dataTypes.find((item) => (item.key = dataTypeKey))
    }

    handleCarouselWidgetClick = () => {
        getRouterStore().push(ROUTES.paymentLinks)
    }
}
