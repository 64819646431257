import {inject, injectable} from 'inversify'
import {action, makeObservable, observable, runInAction} from 'mobx'
import {sendMessage, SendMessageRequest} from '@/api'
import {TModalContainerStore} from '@/components/containers/ModalContainer/TModalContainerStore'
import {openErrorNotification, openSuccessNotification} from '@/utils'
import {PayByLinkLinkDetailsModalContainerStoreSymbol} from '@/pages/PayByLink'
import { ProfileStoreInterface, ProfileStoreSymbol } from '@/pages/Profile'
import translations from '@/stores/pay-by-link/translations'
import rootTranslations from '@/translations/translations'

@injectable()
export class SendMessageStore {
    private readonly _linkDetailsModalStore: TModalContainerStore
    private readonly _profileStore: ProfileStoreInterface

    get merchantName() {
        return this._profileStore?.merchant?.name
    }

    constructor(
        @inject(PayByLinkLinkDetailsModalContainerStoreSymbol)
        linkDetailsModalStore: TModalContainerStore,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface
    ) {
        this._linkDetailsModalStore = linkDetailsModalStore
        this._profileStore = profileStore

        makeObservable(this, {
            isLoading: observable,
            sendMessage: action.bound,
            closeLinkDetailsModal: action.bound
        })
    }

    public isLoading = false

    public closeLinkDetailsModal() {
        this._linkDetailsModalStore.setOpen(false)
    }

    public async sendMessage(id, values: SendMessageRequest, customer: string) {
        this.isLoading = true
        try {
            const {error} = await sendMessage(id, values)

            runInAction(() => {
                if (error) {
                    return openErrorNotification(error.message)
                }

                this._linkDetailsModalStore.setOpen(false)

                return openSuccessNotification(translations().linkSentToName(customer))
            })
        } catch (error) {
            return openErrorNotification(rootTranslations().errors.general)
        } finally {
            runInAction(() => {
                this.isLoading = false
            })
        }
    }
}
