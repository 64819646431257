import translations from './translations'

translations.add('es', {
    back: 'Atrás para iniciar sesión',
    title: 'Restablecer la contraseña',
    text: 'Ingrese la dirección de correo electrónico que utilizó para registrarse en el portal y le enviaremos instrucciones para restablecer su contraseña',
    email: 'Dirección de correo electrónico',
    emailRequired: 'Se requiere Dirección de correo electrónico',
    emailInvalid: 'La dirección de correo electrónico es inválida',
    submit: 'Enviar',
    errorOnEmail: message =>
        `Error al obtener o analizar el correo electrónico de los parámetros de consulta: ${message}`
})
