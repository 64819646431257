import moment from 'moment'
import {ApiResponse} from 'back-connector'

import {AppError} from '@/types'
import {OnlinePaymentStatus, urls} from '@/constants'
import {longPoll, openPopupWindowPromise} from '@/utils/promise-utils'
import {EpayPayment, getEpayPayments, refundPayment} from '../transactions'
import rootTranslations from '@/translations'

type Response = {
    id: string
    deepLinkURL: string
}

export const refundAstropay = async (
    paymentId: string,
    amount: number
): Promise<ApiResponse<EpayPayment>> => {
    const {popup, waitPopupClose} = openPopupWindowPromise('/loading.html', 'AstroPay', {
        maxWidth: 1000,
        maxHeight: 700
    })

    const storageEventListener = (event: StorageEvent) => {
        if (event.key !== 'astropay-return-data') return
        localStorage.removeItem('astropay-return-data')
        popup.close()
    }

    window.addEventListener('storage', storageEventListener)

    const {result: refundedItem, error} = await refundPayment<Response>(paymentId, amount, {
        returnUrl: urls.astropayReturn
    })

    if (error) return {error}

    popup.location.href = refundedItem.deepLinkURL

    await waitPopupClose

    window.removeEventListener('storage', storageEventListener)

    const checkTransactionStatus = async () => {
        const {result, error} = await getEpayPayments({
            paging: {
                page: 0,
                size: 20
            },
            searchParameters: [
                {
                    name: 'created_date',
                    method: 'between',
                    searchParameter: [
                        moment().subtract(1, 'day').toDate(),
                        moment().endOf('day').toDate()
                    ]
                },
                {
                    method: 'in',
                    name: 'id',
                    searchParameter: [refundedItem.id]
                }
            ]
        })

        if (error || !result?.records?.length) {
            throw new Error(error?.message || rootTranslations().errors.unknown)
        }

        return result.records[0]
    }

    try {
        const result = await longPoll(checkTransactionStatus, (payment) =>
            [OnlinePaymentStatus.refund, OnlinePaymentStatus.credited].includes(
                payment.status as OnlinePaymentStatus
            )
        )
        return {result}
    } catch (err) {
        const message =
            (err as AppError).code === 'MAX_ATTEMPT_REACHED'
                ? rootTranslations().errors.refundProcessing
                : rootTranslations().errors.errorOccured
        return {error: {message}}
    }
}
