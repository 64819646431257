import {OnlinePaymentStatus, posStatusTabKeys} from '@/constants'
import rootTranslations from '@/translations'

export const statuses = [OnlinePaymentStatus.auth, OnlinePaymentStatus.charge, OnlinePaymentStatus.cancel, OnlinePaymentStatus.refund].map((status) => ({
    value: status,
    label: rootTranslations().constants.onlinePaymentStatus[status]
}))

export const posStatuses = posStatusTabKeys.map((status) => ({
    value: status,
    label: rootTranslations().constants.posPaymentStatus[status]
}))
