import {getWithAuth, patchWithAuth, postWithAuth} from '@/api'
import {
    OnboardingPayPalRequest,
    OnboardingPayPalResponse,
    PayPalSettingsRequestModel,
    PayPalSettingsResponseModel
} from '@/stores/payment-methods'

export const fetchSettings = (paypalMerchantId?: string) => {
    const queryStr = paypalMerchantId ? `?paypalMerchantId=${paypalMerchantId}` : ''
    return getWithAuth<PayPalSettingsResponseModel>(`/oppapi/v1/paypal/settings${queryStr}`)
}

export const setPaypalSettings = (data: PayPalSettingsRequestModel) =>
    patchWithAuth<null>('/oppapi/v1/paypal/settings', data)

export const onboardingPayPal = (params: OnboardingPayPalRequest) =>
    postWithAuth<OnboardingPayPalResponse>('/oppapi/v1/paypal/partner-referrals', params)
