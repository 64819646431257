import React from 'react'
import {Spin, Table} from 'antd'
import classNames from 'classnames'
import {useInjection} from 'dna-react-ioc'
import {observer} from 'mobx-react'
import {PaginationContainer} from '@/components/containers'
import {PageHeader, Tabs} from '@/components'
import {MobilePayByLinkPaymentList} from '@/pages/components'
import {IPayByLinkStore} from '@/pages/PayByLink/IPayByLinkStore'
import {getPayByLinkListActionButtons} from '@/pages/PayByLink/utils'
import {useScreenType} from '@/hooks'
import {TableViewType} from '@/constants'
import {withTitle} from '@/router/utils'
import {MobileTableControls} from '@/pages/OnlinePayments'
import {OverviewModal, TableFilter} from './components'
import translations from './translations'
import styles from './styles.scss'

export const PayByLinkStoreSymbol = Symbol('PayByLinkStore')
export const PayByLinkRangePickerContainerStoreSymbol = Symbol('PayByLinkRangePickerContainerStore')
export const PayByLinkOverviewModalContainerStoreSymbol = Symbol(
    'PayByLinkOverviewModalContainerStore'
)
export const PayByLinkLinkDetailsModalContainerStoreSymbol = Symbol(
    'PayByLinkLinkDetailsModalContainerStore'
)
export const PayByLinkNewPaymentLinkModalContainerStoreSymbol = Symbol(
    'PayByLinkNewPaymentLinkModalContainerStore'
)
export const PayByLinkCreateNewLinkStoreSymbol = Symbol('PayByLinkCreateNewLinkStore')
export const PayByLinkPaymentLinkDetailsStoreSymbol = Symbol('PayByLinkPaymentLinkDetailsStore')
export const PayByLinkWidgetPreviewStoreSymbol = Symbol('PayByLinkWidgetPreviewStore')
export const PayByLinkSendMessageStoreSymbol = Symbol('PayByLinkSendMessageStore')
export const PayByLinkEditPaymentLinkModalContainerStoreSymbol = Symbol(
    'PayByLinkEditPaymentLinkModalContainerStore'
)
export const PayByLinkEditPaymentLinkStoreSymbol = Symbol('PayByLinkEditPaymentLinkStore')
export const PayByLinkCancelPaymentLinkStoreSymbol = Symbol('PayByLinkCancelPaymentLinkStore')
export const PayByLinkPaginationStoreSymbol = Symbol('PayByLinkPaginationStore')
export const PayByLinkLinkActionsMenuStoreSymbol = Symbol('PayByLinkLinkActionsMenuStore')

const PayByLink = observer(() => {
    const {isMobile} = useScreenType()

    const store = useInjection<IPayByLinkStore>(PayByLinkStoreSymbol)
    const {
        paymentLinks,
        columns,
        isLoading,
        selectedPaymentLink,
        loadPaymentLinks,
        mobileTableViewType
    } = store

    const renderContent = () => {
        return (
            <>
                <PageHeader onBack={null} title={translations().title} border={'none'}>
                    {isMobile && (
                        <TableFilter
                            defaultSelectValue={translations().statuses.all}
                            rangePickerInjectableIdentifier={
                                PayByLinkRangePickerContainerStoreSymbol
                            }
                            selectProps={{
                                value: store.status,
                                options: store.statusSelectOptions,
                                onChange: store.setStatus
                            }}
                            onReloadClick={loadPaymentLinks}
                        />
                    )}

                    <Tabs
                        className={classNames(styles.headerTabs)}
                        tabs={store.statusTabsOptions}
                        activeKey={store.status}
                        onChange={store.setStatus}
                        tabBarExtraContent={
                            isMobile
                                ? null
                                : {
                                      right: (
                                          <TableFilter
                                              defaultSelectValue={translations().statuses.all}
                                              rangePickerInjectableIdentifier={
                                                  PayByLinkRangePickerContainerStoreSymbol
                                              }
                                              selectProps={{
                                                  value: store.status,
                                                  options: store.statusSelectOptions,
                                                  onChange: store.setStatus
                                              }}
                                              onReloadClick={loadPaymentLinks}
                                          />
                                      )
                                  }
                        }
                    />

                    {isMobile && (
                        <MobileTableControls
                            paginationInjectableID={PayByLinkPaginationStoreSymbol}
                            setViewType={(e) => store.setMobileTableViewType(e.target.value)}
                        />
                    )}
                </PageHeader>

                {isMobile && mobileTableViewType === TableViewType.SHORTED && (
                    <>
                        <Table
                            scroll={{x: 500}}
                            columns={columns}
                            dataSource={paymentLinks}
                            pagination={false}
                            rowKey={(paymentLink) => paymentLink.id}
                        />
                        <PaginationContainer
                            hideSizeChanger
                            injectableIdentifier={PayByLinkPaginationStoreSymbol}
                        />
                    </>
                )}

                {isMobile && mobileTableViewType === TableViewType.EXPANDED && (
                    <>
                        <MobilePayByLinkPaymentList
                            dataSource={paymentLinks as any}
                            listExtra={(item) => getPayByLinkListActionButtons(item, store)}
                        />
                        <PaginationContainer
                            hideSizeChanger
                            injectableIdentifier={PayByLinkPaginationStoreSymbol}
                        />
                    </>
                )}

                {!isMobile && (
                    <>
                        <Table
                            className={styles.tableRow}
                            columns={columns}
                            dataSource={paymentLinks}
                            pagination={false}
                            scroll={{x: 1000}}
                            rowKey={(paymentLink) => paymentLink.id}
                            onRow={(record) => ({
                                onClick: () => store.openPaymentDetailsModal(record)
                            })}
                        />
                        <PaginationContainer
                            injectableIdentifier={PayByLinkPaginationStoreSymbol}
                        />
                    </>
                )}

                <OverviewModal
                    paymentLink={selectedPaymentLink}
                    injectableIdentifier={PayByLinkOverviewModalContainerStoreSymbol}
                />
            </>
        )
    }

    return <Spin spinning={isLoading}>{renderContent()}</Spin>
})

export default withTitle(PayByLink)
