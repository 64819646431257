import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    edit: string
    share: string
    areYouSureYouWantToDeactivate: string
    yes: string
    no: string
    createSimilar: string
    deactivate: string
}>()

