import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Tabs, TTabsContainerStore} from '@/components'
import {TabsContainerProps} from './props'

export const TabsContainerStoreSymbol = Symbol('TabsContainerStore')

export const TabsContainer: React.FC<TabsContainerProps> = observer((
    {store: _store, injectableIdentifier = TabsContainerStoreSymbol, className, tabBarExtraContent, onChange}
) => {
    const store = _store || useInjection<TTabsContainerStore>(injectableIdentifier)

    const _onChange = (value) => {
        store.setValue(value)
        onChange && onChange(value)
    }

    return (
        <Tabs
            className={className}
            tabs={store.tabs}
            activeKey={store.value}
            onChange={_onChange}
            tabBarExtraContent={tabBarExtraContent}
        />
    )
})
