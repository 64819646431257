import translations from './translations'

translations.add('es', {
    title: 'Configuración del terminal',
    configurationOptions: 'Opciones de configuración',
    shippingPreference: 'Preferencia de envío',
    useCustomerProvidedShippingAddress: 'Utilice la dirección de envío proporcionada por el cliente en el sitio de PayPal.',
    redactShippingAddressFromPaypalSite: 'Elimine la dirección de envío del sitio de PayPal. Recomendado para productos digitales.',
    useMerchantProvidedAddress: 'Utilice la dirección proporcionada por el comerciante. El cliente no puede cambiar esta dirección en el sitio de PayPal.',
    defaultTransactionType: 'Tipo de transacción predeterminado',
    authorization: 'Autorización',
    capture: 'Captura',
    cancel: 'Cancelar',
    saveChanges: 'Guardar cambios'
})
