import translations from './translations'

translations.add('ro', {
    pendingTransactions: number => `Tranzacții în așteptare (${number})`,
    description: 'Descriere',
    sum: 'Sumă',
    transactionStatus: {
        PROCESSED: 'Processed',
        PENDING: 'Pending',
        FAILED: 'Failed',
        CANDELLED: 'Cancelled'
    },
    all: 'Toate'
})
