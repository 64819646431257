import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import {TransactionType} from '@/types/transactions'
import {POSPaymentStateStatus} from '@/constants/pos-payment-state-status'
import {OnlinePaymentStatus} from '@/constants/online-payment-status'
import {ChargebackStatus} from '@/constants/chargeback-status'
import {PaymentCardType} from '@/constants/payment-card-type'
import {ResonCodeCategory} from '@/constants/reason-code-category'
import translations from './translations'

translations.add('es', {
    siteTitle: 'Portal del comerciante DNA Payments',
    pages: {
        home: 'Inicio',
        activation: 'Activación',
        overview: 'Visión general',
        paymentLinks: 'Enlaces de pago',
        posPayments: 'Pagos TPV',
        posAmexPayments: 'POS American Express',
        onlinePayments: 'Pagos en línea',
        chargebacks: 'Reembolsos',
        virtualTerminal: 'Terminal virtual',
        settlements: 'Liquidaciones',
        billing: 'Facturación',
        invoices: 'Facturas',
        reports: 'Informes',
        paymentMethods: 'Formas de pago',
        payPal: 'PayPal',
        payByBank: 'Pay by Bank',
        openBanking: 'Open Banking',
        settings: 'Configuración',
        developers: 'Desarrolladores',
        checkoutCustomization: 'Personalización del pago',
        tariffs: 'Tarifas',
        profile: 'Perfil',
        login: 'Login',
        resetPassword: 'Restablecer la contraseña',
        twoFA: 'Autenticación de Dos Factores',
        twoFAEnforced: 'Se ha aplicado la autenticación de dos factores',
        recoveryCode: 'Recovery code',
        chooseCompany: 'Elegir empresa',
        confirmEmail: 'Confirmar correo electrónico',
        confirmTwoFA: 'Autenticación de dos factores',
        cardsManagement: 'Gestión de tarjetas',
        setPassword: 'Establecer contraseña',
        teamManagement: 'Gestión de equipos',
        noPermissions: 'Sin permisos',
        help: 'Ayuda',
        ecospendReturn: 'Ecospend Devolución',
        noPage: 'No hay página',
        accountInfo: 'Información de la cuenta',
        exports: 'Exportación',
        makeTransfer: 'Make transfer',
        onlineChat: 'Online Chat'
    },
    constants: {
        onlinePaymentStatus: {
            [OnlinePaymentStatus.all]: 'Todo',
            [OnlinePaymentStatus.auth]: 'Pendiente',
            [OnlinePaymentStatus.created]: 'Creado',
            [OnlinePaymentStatus.credited]: 'Acreditado',
            [OnlinePaymentStatus.charge]: 'Cargado',
            [OnlinePaymentStatus.cancel]: 'Cancelado',
            [OnlinePaymentStatus.refund]: 'Reembolsado',
            [OnlinePaymentStatus.reject]: 'Rechazado',
            [OnlinePaymentStatus.failed]: 'Fallido',
            [OnlinePaymentStatus.new]: 'Nuevo',
            [OnlinePaymentStatus.threeDSecure]: '3D Secure',
            [OnlinePaymentStatus.tokenized]: 'Tokenizado',
            [OnlinePaymentStatus.verified]: 'Verificado',
            [OnlinePaymentStatus.processing]: 'Procesando',
            [OnlinePaymentStatus.abandoned]: 'Abandonado',
            [OnlinePaymentStatus.other]: 'Otros'
        },
        chargebackStatus: {
            [ChargebackStatus.all]: 'Todo',
            [ChargebackStatus.chargeback]: 'Contracargo',
            [ChargebackStatus['chargeback-reversal']]: 'Reversión de contracargo',
            [ChargebackStatus['second-chargeback-lost']]: 'Segundo contracargo - Perdido'
        },
        reasonCodeCategory: {
            [ResonCodeCategory['processing-errors']]: 'Errores de procesamiento',
            [ResonCodeCategory['fraud']]: 'Fraude',
            [ResonCodeCategory['consumer']]: 'Consumidor',
            [ResonCodeCategory['authorisation']]: 'Autorización'
        },
        posPaymentStatus: {
            all: 'Todo',
            authorised: 'Autorizado',
            cancelled: 'Cancelado',
            charged: 'Cargado',
            declined: 'Rechazado',
            refunded: 'Reembolsado',
            chargeback: 'Contracargo',
            'chargeback-reversal': 'Anulación de contracargo',
            'second-chargeback-lost': 'Segundo contracargo - perdido'
        },
        posPaymentStateStatus: {
            [POSPaymentStateStatus.all]: 'Todo',
            [POSPaymentStateStatus.successful]: 'Exito',
            [POSPaymentStateStatus.failed]: 'Rechazado'
        },
        payByLinkStatus: {
            active: 'Activo',
            expired: 'Vencidos',
            paid: 'Pagado',
            cancelled: 'Cancelado',
            viewed: 'Visto',
            attempted: 'Intento',
            all: 'Todo'
        },
        paymentMethodType: {
            card: 'Tarjeta',
            paypal: 'PayPal',
            applepay: 'Apple Pay',
            googlepay: 'Google Pay',
            ecospend: 'Open Banking',
            paybybankapp: 'Pay by Bank',
            klarna: 'Klarna',
            clicktopay: 'ClickToPay',
            astropay: 'AstroPay',
            alipay: 'Alipay',
            wechatpay: 'WeChat Pay'
        },
        fileType: {
            PDF: 'PDF',
            EXCEL: 'EXCEL',
            CSV: 'CSV'
        },
        issuerRegion: {
            Domestic: 'Nacional',
            Intra: 'Intra',
            International: 'Internacional'
        },
        cardType: {
            [PaymentCardType.Credit]: 'Crédito',
            [PaymentCardType.Debit]: 'Débito'
        },
        cardScheme: {
            Visa: 'Visa',
            Mastercard: 'MasterCard',
            UnionPay: 'UnionPay',
            AmericanExpress: 'American Express',
            UPI: 'UnionPay',
            Amex: 'Amex',
            DinersClub: 'Diners Club'
        },
        captureMethods: {
            'ecom-paybybankapp': 'Pay by Bank app',
            'ecom-openbanking': 'Open Banking',
            'ecom-klarna': 'Klarna',
            'pos-contactless': 'TPV Contactles',
            'pos-openbanking': 'Open Banking',
            'pos-alipay': 'Alipay',
            ecom: 'ECOM',
            pos: 'TPV',
            stored: 'Almacenado',
            moto: 'MOTO',
            recurring: 'Periódico'
        },
        terminalTypes: {
            all: 'Todo',
            ecom: 'E-commerce',
            pos: 'TPV'
        }
    },
    filter: {
        all: 'Todo',
        allAll: 'Todo/Todo'
    },
    success: {
        paymentChargedSuccessfully: 'Pago cobrado correctamente.',
        paymentRefundedSuccessfully: 'Reembolso parcial correcto.',
        paymentRefundedFullySuccessfully: 'Reembolso completo efectuado correctamente.',
        newPaymentProcessedSuccessfully: 'El proceso de nuevo pago fue exitoso',
        sendReceiptSuccess: 'El recibo se ha enviado correctamente.',
        downloadReceiptSuccess: 'El recibo se ha descargado correctamente.'
    },
    errors: {
        refundProcessing: 'La transacción de reembolso se está procesando actualmente.',
        unknown: 'Ooops algo salió mal. Nuestro equipo ya lo está investigando.',
        errorOccured: 'Se ha producido un error, inténtelo de nuevo más tarde.',
        general:
            'Lo sentimos, no podemos procesar la solicitud en este momento. Por favor, inténtelo de nuevo más tarde.',
        maxAttempt: (count) => `Se alcanzaron máximo ${count} intentos.`,
        INVALID_CARD_NUMBER: 'Número de tarjeta no válido',
        INVALID_CARDHOLDER_NAME: 'Nombre del titular no válido',
        INVALID_SECURE_CODE: 'Código de seguridad no válido',
        INVALID_EXPIRY_DATE: 'Fecha de caducidad no válida'
    },
    transactionTypes: {
        [TransactionType.REFUND]: 'Reembolso',
        [TransactionType.ADJUSTMENT]: 'Adaptación',
        [TransactionType.OTHER]: 'Otros',
        [TransactionType.PAYBYLINK]: 'Pay by Link',
        [TransactionType.RETAIL]: 'Venta',
        [TransactionType.SALE]: 'Venta',
        [TransactionType.INIT]: 'Init',
        [TransactionType.VERIFICATION]: 'Verificación',
        [TransactionType.TOKENIZATION]: 'Tokenización',
        [TransactionType.P2P]: 'P2P',
        [TransactionType.RECURRING]: 'Periódico',
        [TransactionType.AUTH]: 'Autorización solamente',
        [TransactionType.PREAUTH as any]: 'Preautorización'
    },
    transactionTypesTooltip: {
        [TransactionType.SALE]:
            '"Venta" - la transacción se autorizará y liquidará automáticamente',
        [TransactionType.AUTH]:
            '"Sólo autorización" - reserva de fondos con liquidación manual posterior (función de cargo)',
        [TransactionType.VERIFICATION]: '"Sólo verificación" - sólo verificación de cuenta',
        [TransactionType.PREAUTH as any]:
            '"Preautorización" - preautorización de fondos con liquidación manual posterior (función de cargo)'
    },
    languages: {
        english: 'English',
        spanish: 'Español',
        greek: 'Ελληνικά',
        romanian: 'Română',
        slovak: 'Slovenský'
    },
    action: {
        title: 'Acción',
        viewDetails: 'Ver detalles'
    },
    fields: {
        accountNumber: 'Número de cuenta',
        cardMask: 'Máscara de tarjeta',
        cardScheme: 'Tipo de tarjeta',
        captureMethod: 'Método de captura',
        currency: 'Divisa',
        mid: 'MID',
        status: 'Status',
        store: 'Tienda',
        terminalId: 'Terminal ID',
        terminalType: 'Tipo de terminal',
        transactionId: 'ID de transacción'
    },
    messages: {
        successExportMessage: (
            <span>
                Su solicitud de descarga ha sido puesta en cola para ser procesada. Una vez que el
                documento esté listo, podrá descargarse desde la sección{' '}
                <Link to={ROUTES.exports}>Exportaciones</Link> .
            </span>
        ),
        validation: {
            minLength: (n) => `Introduzca al menos ${n} caracteres`
        }
    }
})
