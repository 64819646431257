import React from 'react'
import translations from './translations'

translations.add('es', {
    title: 'Para usar una aplicación autenticadora, sigue estos pasos:',
    step1: (iOSUrl, androidUrl) => (
        <>
            Descarga una aplicación autenticadora de dos factores como Google Authenticator para{' '}
            <a href={androidUrl} target='_blank'>
                Android
            </a>{' '}
            y{' '}
            <a href={iOSUrl} target='_blank'>
                iOS
            </a>{' '}
            o una aplicación autenticadora similar
        </>
    ),
    step1Note:
        'Nota: Microsoft Authenticator no admite una cuenta en varios sistemas. Ten en cuenta soluciones alternativas para una compatibilidad más amplia del sistema al activar la autenticación de dos factores (2FA).',
    step2: (key, login) => (
        <>
            Escanea el código QR o introduce el inicio de sesión {login} y esta clave{' '}
            <code>{key}</code> en tu aplicación autenticadora de dos factores. Los espacios y las
            mayúsculas no importan
        </>
    ),
    step3: 'Una vez que hayas escaneado el código QR o ingresado la clave anterior, tu aplicación autenticadora de dos factores te proporcionará un código único. Introduce el código en el cuadro de confirmación a continuación y haz clic en enviar'
})
