import React from 'react'
import {Form} from 'antd'
import {observer} from 'mobx-react'
import {Description, ManagerTip, Modal, ModalFormFooter} from '@/components'
import {LoadingState} from '@/types'
import translations from './translations'
import {GetPINModalProps} from './props'

export const GetPINModal: React.FC<GetPINModalProps> = observer((
    {open, onCancel, onGetPINClick, pinData, loadingState}
) => {
    const [form] = Form.useForm()
    const formId = 'pin-input'

    const onFinish = async (values) => {
        await form.validateFields()
        // return onGetCVVClick(Object.entries(values).map(([_, value]) => value).join(''))
    }

    const PINDescriptions = pinData && [
        {
            label: translations().pinCode,
            value: pinData.data.pin
        }
    ]

    return (
        <Modal
            open={open}
            title={translations().getPIN}
            onCancel={onCancel}
            footer={<ModalFormFooter
                formId={formId}
                onCancel={onCancel}
                cancelText={translations().cancel}
                okText={pinData ? translations().gotIt : translations().getPIN}
                onOk={pinData ? onCancel : onGetPINClick}
                confirmLoading={loadingState === LoadingState.LOADING}
            />}
        >
            <ManagerTip text={translations().seeYourPINAndRemember}/>
            {/*<Form*/}
            {/*    form={form}*/}
            {/*    id={formId}*/}
            {/*    onFinish={onFinish}*/}
            {/*>*/}
            {/*    <PinInput form={form} length={6} formId={formId}/>*/}
            {/*</Form>*/}
            {pinData ? <Description dataSource={PINDescriptions}/> : null}
        </Modal>
    )
})
