import 'reflect-metadata'
import {inject, injectable} from 'inversify'
import {action, computed, makeObservable} from 'mobx'

import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import {AuthStoreInterface, AuthStoreSymbol} from '@/stores'
import {MerchantSelectStoreType} from '@/layouts/MainLayout/components'

@injectable()
export class MerchantSelectStore implements MerchantSelectStoreType {
    private readonly _profileStore: ProfileStoreInterface
    private readonly _authStore: AuthStoreInterface

    get value() {
        return this._profileStore.merchant?.merchantId
    }

    get options() {
        const options = []

        this._profileStore?.profileData?.merchants?.forEach((merchant) => {
            const el = {
                label: merchant.name,
                value: merchant.merchantId,
                subLabel: merchant.companyName
            }
            if (el.value === this.value) {
                options.unshift(el)
            } else {
                options.push(el)
            }
        })

        return options
    }

    constructor(
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface,
        @inject(AuthStoreSymbol) authStore: AuthStoreInterface
    ) {
        this._profileStore = profileStore
        this._authStore = authStore

        makeObservable(this, {
            value: computed,
            options: computed,
            setValue: action.bound
        })
    }

    async setValue(value: string) {
        if (value === this.value) {
            return
        }

        await this._authStore.updateToken({merchantId: value})
        window.location.reload()
    }

    setOptions(): void {
        // not needed, but required in interface
    }

    clear(): void {
        // not needed, but required in interface
    }
}
