import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    provideDomainWhereTheFile: string
    inputTheTopLevelDomain: string
    downloadDomainAssociationFile: string
    hostDownloadedDomainAssociationFile: string
    youNeedToHostVerificationFileYouDownloaded: string
    cancel: string
    add: string
    errors: {
        domainRequired: string
    }
}>()

