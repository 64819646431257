import translations from './translations'

translations.add('en', {
    labels: {
        email: 'Email',
        firstName: 'First name',
        lastName: 'Last name',
        store: 'Store',
        status: 'Status',
        authMethod: 'Auth Method',
        active: 'Active'
    }
})
