import React from 'react'
import {render} from 'react-dom'
import 'reflect-metadata'
import '~/assets/styles/antd.vars.less'
import '~/assets/styles/main.scss'
import '@/constants'

import {App} from './App'

render(<App />, document.getElementById('dna-content'))
