import 'reflect-metadata'
import {inject, injectable} from 'inversify'
import {makeAutoObservable, runInAction} from 'mobx'
import {openErrorNotification} from '@/utils'
import {SetPasswordRequest} from '@/stores'
import {TSetPasswordStore} from '@/pages/SetPassword'
import {ConfirmEmailStoreSymbol} from '@/pages/ConfirmEmail/ConfirmEmail'
import {TConfirmEmailStore} from '@/pages/ConfirmEmail'
import {fetchSetPassword} from './fetchers'
import {AuthType} from './models'
import {LoadingState} from '@/types'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import translations from '@/translations'

@injectable()
export class SetPasswordStore implements TSetPasswordStore {
    private _confirmEmailStore: TConfirmEmailStore

    constructor(@inject(ConfirmEmailStoreSymbol) confirmEmailStore: TConfirmEmailStore) {
        makeAutoObservable(this)

        this._confirmEmailStore = confirmEmailStore
    }

    public restrictionErrors = {
        atLeastEightCharacters: true,
        atLeastOneDigit: true,
        atLeastOneSpecialCharacter: true,
        atLeastOneUppercaseLetter: true,
        atLeastOneLowercaseLetter: true
    }
    public password = ''
    public passwordChangingLoadingState = LoadingState.IDLE

    get email() {
        return this._confirmEmailStore.email
    }

    get verificationId() {
        return this._confirmEmailStore.verificationId
    }

    changePassword = async () => {
        const request: SetPasswordRequest = {
            email: this.email,
            password: this.password,
            verificationId: this.verificationId
        }

        runInAction(() => {
            this.passwordChangingLoadingState = LoadingState.LOADING
        })

        try {
            const {error, status} = await fetchSetPassword(request)

            if (status === 401) {
                getRouterStore().push(ROUTES.confirmTwoFA)
                return
            }

            if (error) {
                runInAction(() => {
                    this.passwordChangingLoadingState = LoadingState.FAILED
                })
                openErrorNotification(error.message)
            } else {
                runInAction(() => {
                    this.passwordChangingLoadingState = LoadingState.DONE
                })

                getRouterStore().push(ROUTES.setPasswordSuccess)
            }
        } catch (error) {
            runInAction(() => {
                this.passwordChangingLoadingState = LoadingState.FAILED
            })
            openErrorNotification(translations().errors.general)
        }
    }

    confirmTwoFA = async (code: number, authType: AuthType) => {
        const request: SetPasswordRequest = {
            email: this.email,
            password: this.password,
            verificationId: this.verificationId
        }

        runInAction(() => {
            this.passwordChangingLoadingState = LoadingState.LOADING
        })

        try {
            const {error} = await fetchSetPassword(request, code, authType)

            if (error) {
                runInAction(() => {
                    this.passwordChangingLoadingState = LoadingState.FAILED
                })
                openErrorNotification(error.message)
            } else {
                runInAction(() => {
                    this.passwordChangingLoadingState = LoadingState.DONE
                })

                getRouterStore().push(ROUTES.setPasswordSuccess)
            }
        } catch (error) {
            runInAction(() => {
                this.passwordChangingLoadingState = LoadingState.FAILED
            })
            openErrorNotification(translations().errors.general)
        }
    }

    setPassword = (password: string) => {
        this.password = password
        this.restrictionErrors = {
            atLeastEightCharacters: password.length < 8,
            atLeastOneDigit: !/\d/.test(password),
            atLeastOneSpecialCharacter: !/[^a-zA-Zа-яёА-ЯЁ0-9]/.test(password),
            atLeastOneUppercaseLetter: !/[A-ZА-ЯЁ]/.test(password),
            atLeastOneLowercaseLetter: !/[a-zа-яё]/.test(password)
        }
    }

    continue = () => {
        getRouterStore().push(ROUTES.login)
    }
}
