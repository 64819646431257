import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    labels: {
        newPaymentLink: string
        date: string
        customer: string
        transactionType: string
        amount: string
        customerName: string
        name: string
        description: string
        nameOfTheServiceOrItemYouProvide: string
        linkExpiration: string
        stores: string
        theNewPaymentLinkIsCreatedAndCopiedToClipboard: string
        recurring: string
    }
    buttons: {
        createNewLink: string
        close: string
    }
    errors: {
        storeRequired: string
        orderNumberRequired: string
        customerNameRequired: string
        descriptionRequired: string
        linkExpirationRequired: string
    }
    ranges: {
        hour24: string
        hour48: string
        week1: string
        month1: string
    }
    tooltips: {
        recurring: string
    }
    selectDate: string
}>()
