import {TerminalType} from '@/constants'
import {SelectItem} from '@/components/dumb/Select'
import {SelectItemWithTwoLabels} from '@/components/dumb/SelectWithTwoLabels/models'
import {Terminal} from '@/pages/VirtualTerminal/models'
import {StoreTerminalType, StoreType, StoreWithTerminalsType} from '../types'
import rootTranslations from '@/translations'

export const convertTerminalTypesToSelectItems = (terminalTypes: TerminalType[]): SelectItem[] => {
    return (
        terminalTypes?.map((el) => ({
            value: el,
            label: rootTranslations().constants.terminalTypes[el]
        })) || []
    )
}

export const convertStoresToSelectItems = (stores: StoreType[]): SelectItem[] => {
    return stores?.map((store) => ({value: store.id, label: store.name})) || []
}

export const convertStoresToSelectItemWithTwoLabels = (
    stores: StoreTerminalType[]
): SelectItemWithTwoLabels[] => {
    const setOfValues = new Set<string>()
    return (
        stores.reduce((prev: SelectItemWithTwoLabels[], item) => {
            const value = getStoreNameLocation(item)

            if (!setOfValues.has(value)) {
                prev.push({
                    value,
                    labelPrimary: item.name?.toLocaleUpperCase(),
                    labelSecondary: item.location?.toLocaleUpperCase()
                })
                setOfValues.add(value)
            }

            return prev
        }, []) || []
    )
}

export const getStoreTerminal = (store: StoreWithTerminalsType): Terminal => {
    if (!store.terminals?.length) return null

    const activeDefaultTerminal = store.terminals.find(
        (terminal) => terminal.isActive && terminal.isDefault
    )

    const [firstTerminal] = store.terminals

    return activeDefaultTerminal || firstTerminal
}

export const convertStoresTerminalToSelectItems = (
    stores: StoreWithTerminalsType[]
): SelectItem[] => {
    return (
        stores?.map((store) => {
            const terminal = getStoreTerminal(store)
            return {value: terminal?.id, label: store.name}
        }) || []
    )
}

/** Used as Store ID for stores from Way4 */
export const getStoreNameLocation = (store: StoreTerminalType) =>
    `${store.name?.toLocaleUpperCase()}/${store.location?.toLocaleUpperCase()}`
