import translations from './translations'

translations.add('el', {
    paymentDetails: 'Λεπτομέρειες πληρωμής',
    date: 'Ημερομηνία',
    transactionDate: 'Ημερομηνία συναλλαγής (GMT)',
    amount: 'Ποσό',
    status: 'Κατάσταση',
    terminalId: 'Αναγνωριστικό τερματικού',
    card: 'Κάρτα',
    details: 'Λεπτομέριες',
    resultCode: 'Κωδικός αποτελέσματος',
    message: 'Μήνυμα',
    cardType: 'Τύπος κάρτας',
    cardCategory: 'Κατηγορία κάρτας',
    corporate: 'Εταιρικό',
    personal: 'Προσωπικός',
    europeanCard: 'Ευρωπαϊκή Κάρτα',
    captureMethod: 'Μέθοδος λήψης',
    paymentMethod: 'Μέθοδος πληρωμής',
    transaction: {
        id: 'Αναγνωριστικό συναλλαγής',
        type: 'Τύπος συναλλαγής',
        details: 'Λεπτομέρειες Συναλλαγής',
        country: 'Χώρα συναλλαγής',
        city: 'Πόλη συναλλαγής'
    },
    currency: 'Νόμισμα',
    isCorporateCard: 'Κατηγορία κάρτας',
    returnCode: 'Κωδικός αποτελέσματος',
    returnCodeDescription: 'Μήνυμα',
    mid: 'MID',
    issuerRegion: 'Περιοχή έκδοσης',
    cardScheme: 'Τύπος κάρτας',
    cardMask: 'Μάσκα κάρτας'
})
