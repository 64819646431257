import _ from 'lodash'
import {inject, injectable} from 'inversify'
import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {TTeamManagementStore} from '@/pages/TeamManagement/TTeamManagementStore'
import {roleSelectOptions, teamManagementAllTab} from './constants/constants'
import {
    TeamManagementFiltersStoreSymbol,
    TeamManagementInvitedPaginationContainerStoreSymbol,
    TeamManagementPaginationContainerStoreSymbol,
    TeamManagementRoleSelectContainerStoreSymbol,
    TeamManagementTabsContainerStoreSymbol
} from '@/pages/TeamManagement/TeamManagement'
import {
    EditTeammateStoreSymbol,
    InviteTeammateModalContainerStoreSymbol,
    InviteTeammateStoreSymbol
} from '@/pages/TeamManagement/components/InviteEditTeammate'
import {PaginationStore, SelectContainerStore, TabsContainerStore} from '@/stores'
import {ModalContainerStore} from '@/stores/modal/__mocks__/ModalContainerStore'
import {
    deleteTeammate,
    getTeammates,
    resendInvitation
} from '@/stores/team-management/services/fetchers'
import {LoadingState} from '@/types'
import {openErrorNotification} from '@/utils'
import translations from './translations'
import {Teammate, TeammatesRequest} from '@/stores/team-management/models'
import {Role, TeammateStatus} from '@/stores/team-management/constants'
import {error} from 'dna-common'
import {getTeammateStatusTag, renderActionsMenu} from '@/stores/team-management/services'
import {EditTeammateStore} from '@/stores/team-management/EditTeammateStore'
import {InviteTeammateStore} from '@/stores/team-management/InviteTeammateStore'
import {hasPermissions} from '@/stores/auth/services/utils'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {TTeamManagementFilterStore} from '@/pages/TeamManagement/components/TeamManagementFilter'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'

@injectable()
export class TeamManagementStore implements TTeamManagementStore {
    constructor(
        @inject(TeamManagementTabsContainerStoreSymbol) tabsStore: TabsContainerStore,
        @inject(TeamManagementRoleSelectContainerStoreSymbol) selectStore: SelectContainerStore,
        @inject(TeamManagementPaginationContainerStoreSymbol) paginationStore: PaginationStore,
        @inject(TeamManagementInvitedPaginationContainerStoreSymbol)
        invitedPaginationStore: PaginationStore,
        @inject(InviteTeammateModalContainerStoreSymbol) modalStore: ModalContainerStore,
        @inject(EditTeammateStoreSymbol) editTeammateStore: EditTeammateStore,
        @inject(InviteTeammateStoreSymbol) inviteTeammateStore: InviteTeammateStore,
        @inject(TeamManagementFiltersStoreSymbol)
        teamManagementFilterStore: TTeamManagementFilterStore,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface
    ) {
        this._tabsStore = tabsStore
        this._selectStore = selectStore
        this._paginationStore = paginationStore
        this._invitedPaginationStore = invitedPaginationStore
        this._modalStore = modalStore
        this._editTeammateStore = editTeammateStore
        this._inviteTeammateStore = inviteTeammateStore
        this._teamManagementFilterStore = teamManagementFilterStore

        this._tabsStore.setTabs(teamManagementAllTab)
        this._tabsStore.setValue(teamManagementAllTab[0].key)
        this._selectStore.setOptions(roleSelectOptions)
        this._selectStore.setValue(roleSelectOptions[0].value)
        this._editTeammateStore._teamManagementStore = this
        this._inviteTeammateStore._teamManagementStore = this

        makeAutoObservable(this)

        this.loadTeammates()
        this.loadInvitedTeammates()

        reaction(
            () => this._selectStore.value,
            async () => {
                await this.loadTeammates()
                await this.loadInvitedTeammates()
            }
        )

        reaction(
            () => this._paginationStore.paging,
            async () => {
                await this.loadTeammates()
            }
        )

        reaction(
            () => this._invitedPaginationStore.paging,
            async () => {
                await this.loadInvitedTeammates()
            }
        )

        reaction(
            () => this.filter,
            async () => {
                await this.loadTeammates()
            }
        )

        reaction(
            () => this.invitedFilter,
            async () => {
                if (_.isEqual(this.invitedFilter, this._prevInvitedFilter)) {
                    return
                }
                await this.loadInvitedTeammates()
            }
        )

        reaction(
            () => ({email: profileStore.profileData.email}),
            async () => {
                this._teammates = null
                this._invitedTeammates = null
            }
        )
    }

    _tabsStore: TabsContainerStore
    _selectStore: SelectContainerStore
    _paginationStore: PaginationStore
    _invitedPaginationStore: PaginationStore
    _modalStore: ModalContainerStore
    _editTeammateStore: EditTeammateStore
    _inviteTeammateStore: InviteTeammateStore
    _teamManagementFilterStore: TTeamManagementFilterStore

    _teammates = null
    _invitedTeammates = null
    _loadingState: LoadingState = LoadingState.IDLE
    _loadingInvitedState: LoadingState = LoadingState.IDLE
    _prevInvitedFilter = null

    get filter() {
        return {
            ...(this._teamManagementFilterStore.filter?.role
                ? {role: this._teamManagementFilterStore.filter?.role}
                : {}),
            ...(this._teamManagementFilterStore.filter?.status
                ? {status: this._teamManagementFilterStore.filter?.status}
                : {}),
            ...(this._teamManagementFilterStore.filter?.store
                ? {sid: this._teamManagementFilterStore.filter?.store}
                : {}),
            ...(this._teamManagementFilterStore.filter?.email
                ? {email: this._teamManagementFilterStore.filter?.email}
                : {}),
            ...(this._teamManagementFilterStore.filter?.firstName
                ? {firstName: this._teamManagementFilterStore.filter?.firstName}
                : {}),
            ...(this._teamManagementFilterStore.filter?.lastName
                ? {lastName: this._teamManagementFilterStore.filter?.lastName}
                : {}),
            ...(this._teamManagementFilterStore.filter?.authMethod
                ? {authMethod: this._teamManagementFilterStore.filter?.authMethod}
                : {})
        }
    }

    get invitedFilter() {
        return {
            ...(this._teamManagementFilterStore.filter?.role
                ? {role: this._teamManagementFilterStore.filter?.role}
                : {}),
            ...(this._teamManagementFilterStore.filter?.email
                ? {email: this._teamManagementFilterStore.filter?.email}
                : {}),
            ...(this._teamManagementFilterStore.filter?.store
                ? {sid: this._teamManagementFilterStore.filter?.store}
                : {}),
            ...(this._teamManagementFilterStore.filter?.firstName
                ? {firstName: this._teamManagementFilterStore.filter?.firstName}
                : {}),
            ...(this._teamManagementFilterStore.filter?.lastName
                ? {lastName: this._teamManagementFilterStore.filter?.lastName}
                : {})
        }
    }

    get loadingState(): LoadingState {
        switch (this._tabsStore.value) {
            case TeammateStatus.ok: {
                return this._loadingState
            }
            case TeammateStatus.invited: {
                return this._loadingInvitedState
            }
            default: {
                return this._loadingInvitedState
            }
        }
    }

    get isStatusAvailable(): boolean {
        return this._tabsStore.value !== TeammateStatus.invited
    }

    get tabType(): string {
        return this._tabsStore.value
    }

    get columns() {
        switch (this._tabsStore.value) {
            case TeammateStatus.invited: {
                return [
                    {
                        title: translations().columns.email,
                        dataIndex: 'login',
                        key: 'login'
                    },
                    ...(hasPermissions([PermissionsMap.teammates.full])
                        ? [
                              {
                                  title: translations().columns.actions,
                                  key: 'actions',
                                  render: (item, object) => renderActionsMenu(object, this)
                              }
                          ]
                        : [])
                ]
            }
            default:
                return [
                    {
                        title: translations().columns.email,
                        dataIndex: 'login',
                        key: 'login'
                    },
                    {
                        title: translations().columns.firstName,
                        dataIndex: 'firstName',
                        key: 'firstName'
                    },
                    {
                        title: translations().columns.lastName,
                        dataIndex: 'lastName',
                        key: 'lastName'
                    },
                    {
                        title: translations().columns.status,
                        dataIndex: 'status',
                        key: 'status',
                        render: (item) => getTeammateStatusTag(item)
                    },
                    {
                        title: translations().columns.authMethod,
                        dataIndex: 'authMethod',
                        key: 'authMethod'
                    },
                    ...(hasPermissions([PermissionsMap.teammates.full])
                        ? [
                              {
                                  title: translations().columns.actions,
                                  key: 'actions',
                                  render: (item, object) => renderActionsMenu(object, this)
                              }
                          ]
                        : [])
                ]
        }
    }

    get teammates(): Teammate[] {
        switch (this._tabsStore.value) {
            case TeammateStatus.invited: {
                if (!this._invitedTeammates) {
                    this.loadInvitedTeammates()
                    return []
                }

                return this._invitedTeammates
            }
            default: {
                if (!this._teammates) {
                    this.loadTeammates()
                    return []
                }

                return this._teammates
            }
        }
    }

    get selectStoreIdentifier() {
        return TeamManagementRoleSelectContainerStoreSymbol
    }

    get paginationStoreIdentifier() {
        if (this._tabsStore.value === TeammateStatus.invited) {
            return TeamManagementInvitedPaginationContainerStoreSymbol
        }

        return TeamManagementPaginationContainerStoreSymbol
    }

    loadTeammates = async () => {
        if (this._loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this._loadingState = LoadingState.LOADING
        })

        const request: TeammatesRequest = {
            role: (this._selectStore.value as Role) || undefined,
            active: true,
            ...this._paginationStore.paging,
            ...this.filter
        }

        await runInAction(async () => {
            let response
            try {
                response = await getTeammates(request)
            } catch (e) {
                this._loadingState = LoadingState.FAILED
                this._teammates = []
                this._paginationStore.setTotal(0)
                error(e.message)
                return
            }

            const {result, error: err} = response

            if (err) {
                this._loadingState = LoadingState.FAILED
                this._teammates = []
                this._paginationStore.setTotal(0)
                return openErrorNotification(err.message)
            } else if (!result) {
                this._loadingState = LoadingState.FAILED
                this._teammates = []
                this._paginationStore.setTotal(0)
                return
            }

            this._teammates = result.teamMates.map((item) => ({
                ...item,
                key: item.id,
                active: true
            }))
            this._paginationStore.setTotal(result.totalCount)

            this._loadingState = LoadingState.DONE
        })
    }

    loadInvitedTeammates = async () => {
        if (this._loadingInvitedState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this._loadingInvitedState = LoadingState.LOADING
        })

        const request: TeammatesRequest = {
            role: (this._selectStore.value as Role) || undefined,
            active: false,
            ...this._invitedPaginationStore.paging,
            ...this.invitedFilter
        }

        this._prevInvitedFilter = this.invitedFilter

        const handleInvitedTeammatesDataLoadFailure = () => {
            this._loadingInvitedState = LoadingState.FAILED
            this._invitedTeammates = []
            this._invitedPaginationStore.setTotal(0)
            this.removeTabByKey(TeammateStatus.invited)
        }

        await runInAction(async () => {
            let response
            try {
                response = await getTeammates(request)
            } catch (e) {
                handleInvitedTeammatesDataLoadFailure()
                error(e.message)
                return
            }

            const {result, error: err} = response

            if (err) {
                handleInvitedTeammatesDataLoadFailure()
                return openErrorNotification(err.message)
            } else if (!result) {
                handleInvitedTeammatesDataLoadFailure()
                return
            }

            this._invitedTeammates = result.teamMates.map((item) => ({
                ...item,
                status: TeammateStatus.invited,
                active: false
            }))
            this._invitedPaginationStore.setTotal(result.totalCount)
            this.identifyAvailableAndActiveTab()
            this._loadingInvitedState = LoadingState.DONE
        })
    }

    onInviteTeammateButtonClick = () => {
        this._modalStore.setOpen(true)
    }

    deleteTeammate = async (teammateId: string) => {
        let response
        try {
            response = await deleteTeammate(teammateId)
        } catch (e) {
            error(e.message)
            return
        }

        const {error: err} = response

        if (err) {
            return openErrorNotification(err.message)
        }

        switch (this._tabsStore.value) {
            case TeammateStatus.invited: {
                await this.loadInvitedTeammates()
                this.identifyAvailableAndActiveTab()
                break
            }
            default: {
                await this.loadTeammates()
                break
            }
        }
    }

    resendInvitation = async (teammateId: string) => {
        let response
        try {
            response = await resendInvitation(teammateId)
        } catch (e) {
            error(e.message)
            return
        }

        const {error: err} = response

        if (err) {
            return openErrorNotification(err.message)
        }

        await this.loadInvitedTeammates()
    }

    identifyAvailableAndActiveTab = () => {
        const tabs = [...teamManagementAllTab]

        const isInvitedTeammateVisible = this._invitedTeammates && this._invitedTeammates.length > 0

        if (isInvitedTeammateVisible) {
            tabs.push({
                title: translations().tabs.invited,
                key: TeammateStatus.invited
            })
        }

        this._tabsStore.setTabs(tabs)
    }

    removeTabByKey = (key: TeammateStatus) => {
        this._tabsStore.setTabs(this._tabsStore.tabs.filter((tab) => tab.key !== key))
    }

    reload = async () => {
        await this.loadTeammates()
        await this.loadInvitedTeammates()
    }
}
