import React from 'react'
import {Col, Divider, Row, Typography} from 'antd'
import {RangePickerContainer, SearchSelectContainer, SelectWithTitleContainer} from '@/components'
import {AccountTransactionsFilterButton} from '../AccountTransactionsFilterButton'
import translations from './translations'
import styles from './styles.scss'

export const AccountInfoSearchSelectStoreSymbol = Symbol('AccountInfoSearchSelectStore')
export const AccountInfoTableFilterRangePickerContainerStoreSymbol = Symbol('AccountInfoFilterRangePickerContainerStore')
export const AccountInfoTableFilterStatusSelectContainerStoreSymbol = Symbol('AccountInfoTableFilterStatusSelectContainerStore')

export const AccountTransactionFilter = () => {
    return (
        <div className={styles.container}>
            <Typography.Title level={5} className={styles.title}>{translations().recentTransactions}</Typography.Title>
            <Row wrap={false}>
                <AccountTransactionsFilterButton />
                <SelectWithTitleContainer
                    className={styles.select}
                    displayValue={translations().status}
                    injectableIdentifier={AccountInfoTableFilterStatusSelectContainerStoreSymbol}
                />
                <RangePickerContainer
                    className={styles.select}
                    injectableIdentifier={AccountInfoTableFilterRangePickerContainerStoreSymbol}
                />
            </Row>
            <Divider/>
        </div>
    )
}
