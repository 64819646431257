import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    pleaseEnterReasonWhyUnregistering: string
    unregister: string
    close: string
    errors: {
        reasonRequired: string
    }
}>()

