import React, {useEffect} from 'react'
import {Form} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {FilterButtonPopover} from '@/components'
import {getObjectNestedKeys} from '@/utils'
import {OnlinePaymentsFilterForm} from '../OnlinePaymentsFilterForm'
import {TOnlinePaymentsFilterButtonStore} from './TOnlinePaymentsFilterButtonStore'
import {OnlinePaymentsFilterButtonProps} from './props'
import styles from './styles.scss'

export const OnlinePaymentsFilterButtonStoreSymbol = Symbol('OnlinePaymentsFilterButtonStore')

export const OnlinePaymentsFilterButton = observer(
    ({
        injectableIdentifier = OnlinePaymentsFilterButtonStoreSymbol
    }: OnlinePaymentsFilterButtonProps) => {
        const [form] = Form.useForm()
        const {
            count,
            openFilter,
            onFilterClose,
            clearFilters,
            open,
            setOpen,
            onFinish,
            resetPagination,
            postLinkStatuses,
            paymentMethods,
            cardSchemes,
            currencies,
            stores,
            formValues,
            onFormValuesChange,
            setForm
        } = useInjection<TOnlinePaymentsFilterButtonStore>(injectableIdentifier)

        const formName = 'online-payments-popover-filter-form'

        const onClearFilters = () => {
            clearFilters()
            resetPagination()
            form.resetFields()
        }

        useEffect(() => {
            setForm(form)
        }, [])

        useEffect(() => {
            form.setFieldsValue(formValues)
            onFormValuesChange(formValues)
        }, [formValues])

        const defaultActiveKey = getObjectNestedKeys(formValues)

        return (
            <FilterButtonPopover
                buttonClassName={styles.filter}
                count={count}
                formId={formName}
                onClearFiltersClick={onClearFilters}
                onClick={openFilter}
                open={open}
                setOpen={setOpen}
                onCloseClick={onFilterClose}
                popoverContent={
                    <OnlinePaymentsFilterForm
                        formId={formName}
                        form={form}
                        onFinish={onFinish}
                        postLinkStatuses={postLinkStatuses}
                        paymentMethods={paymentMethods}
                        cardSchemes={cardSchemes}
                        currencies={currencies}
                        stores={stores}
                        values={formValues}
                        onFormValuesChange={onFormValuesChange}
                        defaultActiveKey={defaultActiveKey}
                    />
                }
            />
        )
    }
)
