import translations from './translations'

translations.add('en', {
    title: 'Payment links',
    paymentDetails: 'Payment details',
    chargedAmount: 'Charged amount',
    expirationDate: 'Expiration date',
    dateCreated: 'Date created',
    summary: 'Summary',
    channel: 'Channel',
    description: 'Description',
    amount: 'Amount',
    status: 'Status',
    customer: 'Customer',
    action: 'Action',
    statement: 'Statement',
    statuses: {
        active: 'Active',
        expired: 'Expired',
        paid: 'Paid',
        cancelled: 'Cancelled',
        viewed: 'Viewed',
        attempted: 'Attempted',
        all: 'All'
    }
})
