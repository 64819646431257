import isEmpty from 'lodash/isEmpty'
import { MenuDataItem } from '@/types'
import {ROUTES} from '@/router/routes'

export function computeSelectedKey(currentRoute: string, menuItems: MenuDataItem[]) {
    let selectedKey = ''
    for (const item of menuItems) {
        if (currentRoute.startsWith(item.path)) {
            selectedKey = item.path
        }

        if (currentRoute === ROUTES.profile) {
            selectedKey = ''
        }

        if (!isEmpty(item.children)) {
            selectedKey = computeSelectedKey(currentRoute, item.children) || selectedKey
        }
    }
    return selectedKey
}

export function computeOpenKeys(currentRoute: string, menuItems: MenuDataItem[]) {

    const openKeys = []
    for (const item of menuItems) {
        if (isRoutesRelated(currentRoute, item.path) && !isEmpty(item.children)) {
            openKeys.push(item.path)
        }

        if (!isEmpty(item.children)) {
            openKeys.push(...computeOpenKeys(currentRoute, item.children))
        }
    }
    return openKeys
}

function isRoutesRelated (currentRoute: string, route: string) {
    const currentRouteParent = currentRoute && currentRoute.split('/')[1]
    const routeParent = route && route.split('/')[1]

    return (Boolean(currentRoute) && Boolean(routeParent)) && (currentRouteParent === routeParent)
}
