import React from 'react'
import translations from './translations'

translations.add('sl', {
    buttons: {
        home: 'Choď domov'
    },
    errors: {
        ourSupportTeamIsHereToHelp: (
            <>
                Operácia zlyhala, náš tím podpory je tu, aby pomohol:{' '}
                <a
                    href={'https://dnapayments.com/online-quote#2'}
                    target={'_blank'}
                    rel={'noreferrer'}
                >
                    Podpora
                </a>
            </>
        )
    }
})
