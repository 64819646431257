import React from 'react'

import {observer} from 'mobx-react'

import {Map, Marker, TileLayer} from 'react-leaflet'

interface GeolocationPaneProps {
    center: {
        lat: number,
        lng: number
    },
    zoom?: number
}

export const GeolocationMap = observer(({center, zoom = 11}: GeolocationPaneProps) => {
    return (
        <Map center={center} zoom={zoom}>
            <TileLayer
                attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
            />
            <Marker position={center}/>
        </Map>
    )
})
