import React from 'react'
import translations from './translations'

translations.add('es', {
    title: (
        <>
            Se ha generado el código de recuperación para su cuenta. <br /> Este código de 12
            caracteres se puede utilizar una vez para recuperar su cuenta si no puede iniciar sesión
            con autenticación de dos factores (2FA).
        </>
    ),
    copy: 'Copiar',
    copyText: 'Copia el código para continuar.',
    copied: 'Copiado',
    warning: {
        line1: 'El código solo se puede usar una vez',
        line2: 'Por razones de seguridad, este código no se mostrará nuevamente',
        line3: 'No comparta este código con nadie'
    }
})
