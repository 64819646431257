import React, {useEffect} from 'react'
import classNames from 'classnames'
import {Col, Divider, Form, Grid, Row, Typography} from 'antd'
import {isEmpty} from 'dna-common'

import {getAmountWithCurrency} from '@/utils'
import {Description} from '@/components/dumb/Description'
import {TitleWithStatus} from '@/components/composite/TitleWithStatus'
import {ModalContainer} from '@/components/containers'
import {AmountFormField, ModalFormFooter} from '@/components'

import translations from './translations'
import {PaymentViewModalPoprs} from './props'
import styles from './styles.scss'

const {Title} = Typography
const {useBreakpoint} = Grid

export const PaymentViewModal: React.FC<PaymentViewModalPoprs> = (
    { injectableIdentifier, selectedPayment, isLoading, isForRefund, statusProps, onCancel, onFinish }
) => {
    const screens = useBreakpoint()
    const isMobile = screens.xs
    const [form] = Form.useForm()
    const {amount, description, balance, currency} = selectedPayment
    const isRefundWithBalance = !isEmpty(balance) && isForRefund

    useEffect(() => {
        form.setFieldsValue({
            amount
        })
    }, [form, amount])

    const onModalCancel = () => {
        form.resetFields()
        onCancel()
    }

    const [modalTitle, bodyTitle, btnText] = (() => {
        if (isForRefund) {
            return [translations().paymentRefund, translations().refundAmount, translations().refund]
        }
        return [translations().paymentCharge, translations().chargeAmount, translations().charge]
    })()

    const renderForm = () => (
        <Form
            id={'paymentForm'}
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            initialValues={{amount}}
        >
            <AmountFormField
                form={form}
                rules={[
                    {
                        validator: (field, value) => {
                            const amountOrBalance = isRefundWithBalance ? balance : amount

                            if(Number(value) === 0) {
                                const error = new Error(translations().errors.positiveAmount)
                                return Promise.reject(error)
                            }

                            if (!value || parseFloat(value) <= amountOrBalance) {
                                return Promise.resolve()
                            }

                            const errorMessage = isForRefund
                                ? translations().errors.amountRefundExceed
                                : translations().errors.amountChargeExceed

                            const error = new Error(errorMessage)
                            return Promise.reject(error)
                        }
                    }
                ]}
                inputProps={{placeholder: translations().chargeAmount}}
                currency={currency}
            />
        </Form>
    )

    return (
        <ModalContainer
            title={modalTitle}
            injectableIdentifier={injectableIdentifier}
            onOk={onModalCancel}
            onCancel={onModalCancel}
            width={'700px'}
            footer={<ModalFormFooter
                okText={btnText}
                confirmLoading={isLoading}
                formId={'paymentForm'}
                onCancel={onModalCancel}
            />}
        >
            {statusProps ? (
                <TitleWithStatus
                    subtitle={description}
                    statusProps={statusProps}
                >
                    {getAmountWithCurrency(amount, currency)}
                </TitleWithStatus>
            ) : null}

            <Divider/>

            <Row>
                <Col className={classNames({[styles.chargeInput]: isMobile})} xs={24} md={11}>
                    <Title level={5}>{bodyTitle}</Title>
                    {renderForm()}
                </Col>

                <Col xs={24} md={{span: 11, offset: 2}}>
                    <Description
                        dataSource={[
                            {
                                key: translations().paymentAmount,
                                label: translations().paymentAmount,
                                value: getAmountWithCurrency(amount, currency)
                            },
                            ...(isRefundWithBalance ? [{
                                key: translations().balance,
                                label: translations().balance,
                                value: getAmountWithCurrency(balance, currency)
                            }] : [])
                        ]}
                    />
                </Col>
            </Row>

        </ModalContainer>
    )
}
