import translations from './translations'

translations.add('sl', {
    jobNameTypes: {
        all: 'Všetko',
        'merchant-statement-excel': 'Oznámenie (Excel)',
        'merchant-statement-csv': 'Oznámenie (CSV)',
        'pos-transactionsv2-csv': 'POS platby (CSV)',
        'pos-amex-transactions-csv': 'POS American Express (CSV)',
        'ecom-transactions-csv': 'Online platby (CSV)'
    }
})
