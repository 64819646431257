import translations from './translations'

translations.add('ro', {
    jobNameTypes: {
        'all': 'Toate',
        'merchant-statement-excel': 'Extras de cont (Excel)',
        'merchant-statement-csv': 'Extras de cont (CSV)',
        'pos-transactionsv2-csv': 'Plăți POS (CSV)',
        'pos-amex-transactions-csv': 'POS American Express (CSV)',
        'ecom-transactions-csv': 'Plăți online (CSV)'
    }
})
