import React from 'react'
import translations from './translations'

translations.add('ro', {
    text: 'Retrimite',
    seconds: count =>
        count ? (
            <>
                {' '}
                în <b>{count} secunde</b>
            </>
        ) : (
            ''
        )
})
