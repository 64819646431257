import translations from './translations'

translations.add('el', {
    title: 'Διακανονισμοί',
    download: 'Λήψη δήλωσης',
    perDay: 'Ανά μέρα',
    perTransaction: 'Ανά συναλλαγή',
    description1:
        'Λάβετε υπόψη ότι περιστασιακά, οι Διακανονισμοί σας ενδέχεται να τρέχουν έως τις 5 μ.μ. τις εργάσιμες ημέρες, αλλά θα κατατίθενται αυτόματα στον τραπεζικό σας λογαριασμό.',
    description2:
        'Να θυμάστε ότι οι πληρωμές μέσω POS της American Express που πραγματοποιούνται μέσω πληρωμών DNA διακανονίζονται απευθείας από την American Express και δεν θα εμφανίζονται στις Εκκαθαριστικές καταστάσεις σας. Έχετε τη δυνατότητα να παρακολουθείτε αυτές τις συναλλαγές ανά πάσα στιγμή χρησιμοποιώντας την Πύλη Εμπόρων.'
})
