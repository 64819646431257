import {StoreWithTerminalsType} from '@/stores/store-and-terminals'
import {isEmpty} from 'dna-common'
import {Currencies} from '@/constants'
import {MerchantTerminalType} from '@/stores/pay-by-link/models/MerchantTerminalType'

export const replaceSupportedCurrenciesToDefaultIfNull = (
    data: StoreWithTerminalsType[]
): StoreWithTerminalsType[] => {
    for (const entry of data) {
        for (const terminal of entry.terminals) {
            if (terminal.supportedCurrencies === null) {
                terminal.supportedCurrencies = []
            }

            if (isEmpty(terminal.defaultCurrency)) {
                terminal.defaultCurrency = Currencies.GBP
            }
        }
    }
    return data
}

export const getEcomTerminals = (stores: StoreWithTerminalsType[]): MerchantTerminalType[] => {
    return stores
        .filter((s) => s.type === 'ecom')
        .reduce((prev: MerchantTerminalType[], curr) => [...prev, ...curr.terminals], [])
}

export const getPosTerminals = (stores: StoreWithTerminalsType[]) => {
    return stores
        .filter((s) => s.type === 'pos')
        .reduce((prev: MerchantTerminalType[], curr) => [...prev, ...curr.terminals], [])
}

export const getEcomMostUsedDefaultCurrency = (stores: StoreWithTerminalsType[]) => {
    const ecomTerminals = getEcomTerminals(stores)
    const activeTerminals = filterActiveTerminals(ecomTerminals)
    const currencyCount = countCurrencyOccurrences(activeTerminals)
    return findMostUsedCurrency(currencyCount)
}

export const getPosMostUsedDefaultCurrency = (stores: StoreWithTerminalsType[]) => {
    const posTerminals = getPosTerminals(stores)
    const activeTerminals = filterActiveTerminals(posTerminals)
    const currencyCount = countCurrencyOccurrences(activeTerminals)
    return findMostUsedCurrency(currencyCount)
}

export const filterActiveTerminals = (terminals: MerchantTerminalType[]) => {
    return terminals.filter((term) => term.isActive)
}

export const countCurrencyOccurrences = (
    terminals: MerchantTerminalType[]
): {[currency in Currencies]: number} => {
    return terminals.reduce(
        (count, term) => {
            const currency = term.defaultCurrency
            count[currency] = (count[currency] || 0) + 1
            return count
        },
        {GBP: 0, EUR: 0, USD: 0, CNY: 0, SEK: 0} as {[currency in Currencies]: number}
    )
}

function findMostUsedCurrency(currencyCount: {[currency in Currencies]: number}) {
    return Object.keys(currencyCount).reduce((mostUsed, currency) => {
        if (currencyCount[currency] > currencyCount[mostUsed]) {
            return currency
        } else {
            return mostUsed
        }
    }, Currencies.GBP)
}
