export const AMOUNT_PATTERN = /(^\d*\.?\d{0,2}$)/
export const NUMBER_PATTERN = /^\d+$/
export const START_ZERO = /^0+/
export const PHONE_UK_NUMBER_PATTERN = /^\+?(\d){10}$/
export const PASSWORD_PATTERN =
    /^(?=.*[0-9])(?=.*[\W_])(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ])[0-9a-zA-Zа-яёА-ЯЁ\W_]{8,}$/
export const TRANSACTION_ID_PATTERN =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
export const EMPTY_PATTERN = /^[ \t]*$/
export const NOT_EMPTY_PATTERN = /^(?![ \t]*$).+/
