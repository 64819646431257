import translations from './translations'

translations.add('ro', {
    title: 'Gestionarea echipei',
    inviteTeammate: 'Adaugă utilizator',
    role: 'Rol',
    all: 'Toate',
    active: 'Invitat',
    inactive: 'Invitație acceptată',
    statuses: {
        ok: 'Activ',
        invited: 'Invitat',
        validateEmail: 'Validați adresa de email',
        changePassword: 'Schimbați parola',
        block: 'Blocat'
    },
    authMethods: {
        password: 'Parolă',
        twoFAApp: '2FA (Aplicație Autentificator)',
        twoFAEnforced: '2FA (Obligatoriu)'
    },
    notifications: {
        userSuccessfullyInvited: 'Utilizator invitat cu succes'
    }
})
