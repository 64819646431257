import translations from './translations'

translations.add('es', {
    title: 'Verificación requerida',
    continue: 'Continuar',
    resend: {
        question: `¿No recibiste un código?`
    },
    description: (n, e) =>
        `Para continuar, ingresa el código de verificación de ${n} dígitos enviado a ${e}`
})
