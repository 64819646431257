import rootTranslations from '@/translations'

export enum POSPaymentStatus {
    all = 'all',
    authorised = 'authorised',
    charged = 'charged',
    declined = 'declined',
    refunded = 'refunded',
    cancelled = 'cancelled',
    chargeback = 'chargeback',
    'chargeback-reversal' = 'chargeback-reversal',
    'second-chargeback-lost' = 'second-chargeback-lost'
}

export const posStatusTabKeys = [POSPaymentStatus.all, POSPaymentStatus.authorised, POSPaymentStatus.charged]

export const getPortalPosStatuses = () => Object.keys(POSPaymentStatus).map(item => ({
    label: rootTranslations().constants.posPaymentStatus[item],
    value: item as POSPaymentStatus
}))
