import {TranslationBuilder} from '@/components/dumb/Translation'
import {TeammateStatus, AuthMethods} from '@/stores/team-management/constants'

export default TranslationBuilder.create<{
    title: string
    inviteTeammate: string
    role: string
    all: string
    active: string
    inactive: string
    statuses: {
        [key in keyof typeof TeammateStatus]: string
    }
    authMethods: {
        [key in keyof typeof AuthMethods]: string
    }
    notifications: {
        userSuccessfullyInvited: string
    }
}>()
