import React from 'react'
import {Text} from '@/components'
import translations from './translations'

translations.add('sl', {
    linkSentToName: name => (
        <>
            Odkaz na platbu bol úspešne odoslaný na adresu <Text strong>{name}</Text>
        </>
    ),
    linkSaved: 'Odkaz na platbu bol uložený',
    linkDeactivated: 'Platobný odkaz bol deaktivovaný'
})
