import {ChartOptions} from 'chart.js'
import moment from 'moment'
import {injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import {subscribe} from 'dna-react-ioc'

import {TTransactionsCardWithBarChartContainerStore} from '@/components/containers/TransactionsCardWithBarChartContainer'
import {AuthStoreInterface, POSSummaryResponse, PosTransactionSummaryItem} from '@/stores'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol,
    PosStoreSelectStore
} from '@/stores/store-and-terminals'
import {Currencies, POSPaymentStateStatus} from '@/constants'
import {
    TRangePickerContainerStore,
    TSelectContainerStore,
    TTransactionsComparisonCardWithBarChartContainerStore
} from '@/components/containers'
import {LoadingState} from '@/types'
import {getAmount, openErrorNotification} from '@/utils'
import {POSTransactionStatuses} from '@/stores/reports/services/generators'
import {SelectItem} from '@/components/dumb/Select'
import {barChartConfig} from '@/services/chart-config'
import {
    generateChartTitleForRange,
    generateInterval,
    getIntervalValue
} from '@/stores/reports/services/date-utils'
import {getPOSTransactionsSummary} from '@/stores/reports/services/fetchers'
import {emptyChartDataGenerator} from '@/stores/reports/services/mocks'
import translations from './translations'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import {
    calculateTotalAmount,
    calculateTotalCount
} from '@/stores/reports/POSTransactionsCardWithBarChartContainerStore/services/utils'
import {getPosMostUsedDefaultCurrency} from '@/stores/handbooks/services'
import {convertPosStatesToSelectItems} from '@/utils/transaction'
import rootTranslations from '@/translations'

@injectable()
export class POSTransactionsCardWithBarChartContainerStore
    implements TTransactionsCardWithBarChartContainerStore
{
    private _authStore: AuthStoreInterface
    private _rangePickerStore: TRangePickerContainerStore
    private _currencySelectStore: TSelectContainerStore
    private _posOverviewTransactionsComparisonCardWithBarChartStore: TTransactionsComparisonCardWithBarChartContainerStore
    private readonly _merchantStoresStore: AvailableStoresDataStore
    private readonly _storeSelectStore: PosStoreSelectStore
    transactionValue: number
    dailyValue: number
    transactionsPerDay: number
    _shouldFetchData: boolean

    constructor(
        protected _name = 'POSTransactionsCardWithBarChartContainerStore',
        authStore: AuthStoreInterface,
        rangePickerStore: TRangePickerContainerStore,
        currencySelectStore: TSelectContainerStore,
        shouldFetchData?: boolean,
        posOverviewTransactionsComparisonCardWithBarChartStore?: TTransactionsComparisonCardWithBarChartContainerStore,
        storeSelectStore?: PosStoreSelectStore
    ) {
        this._authStore = authStore
        this._rangePickerStore = rangePickerStore
        this._currencySelectStore = currencySelectStore
        this._shouldFetchData = shouldFetchData
        this._posOverviewTransactionsComparisonCardWithBarChartStore =
            posOverviewTransactionsComparisonCardWithBarChartStore
        this._merchantStoresStore = subscribe<AvailableStoresDataStore>(
            AvailableStoresDataStoreSymbol
        )
        this._storeSelectStore = storeSelectStore
        this.transactionValue = null
        this.dailyValue = null
        this.transactionsPerDay = null

        makeObservable(this, {
            _data: observable,
            transactionValue: observable,
            dailyValue: observable,
            transactionsPerDay: observable,
            data: computed,
            currency: computed,
            dateRange: computed,
            dataLoadingState: observable,
            selectedDataType: observable,
            selectedStatus: observable,
            setSelectedStatus: action,
            setSelectedDataType: action,
            handleCarouselWidgetClick: action,
            setData: action
        })

        reaction(
            () => ({
                from: this._rangePickerStore.startDate,
                to: this._rangePickerStore.endDate,
                currency: this._currencySelectStore.value,
                shouldFetchData: this._shouldFetchData,
                isTerminalsLoaded: this._storeSelectStore.loadingState === LoadingState.DONE,
                store: this._storeSelectStore.value
            }),
            ({from, to, currency, shouldFetchData, isTerminalsLoaded}) => {
                if (
                    from &&
                    to &&
                    currency &&
                    this._authStore.isAuthenticated &&
                    shouldFetchData &&
                    isTerminalsLoaded
                ) {
                    this.loadData()
                }
            }
        )

        reaction(
            () => ({
                data: this._posOverviewTransactionsComparisonCardWithBarChartStore?.allTransactions,
                loadingState:
                    this._posOverviewTransactionsComparisonCardWithBarChartStore?.dataLoadingState
            }),
            ({data, loadingState}) => {
                if (!shouldFetchData) {
                    this.dataLoadingState = loadingState
                    this.setData(data as POSSummaryResponse)
                    this.averageMetricsHandler(
                        (data as POSSummaryResponse).successful as PosTransactionSummaryItem[]
                    )
                }
            }
        )
    }

    _data = {
        all: {amount: undefined, count: undefined},
        successful: {amount: undefined, count: undefined},
        failed: {amount: undefined, count: undefined}
    }
    selectedDataType = this.dataTypes[0]
    dataLoadingState = LoadingState.IDLE
    selectedStatus: POSPaymentStateStatus = POSTransactionStatuses[0]
    statuses: SelectItem[] = convertPosStatesToSelectItems(POSTransactionStatuses)

    get dataTypes(): {key: string; title: string}[] {
        return [
            {key: 'amount', title: translations().dataTypes.amount},
            {key: 'count', title: translations().dataTypes.count}
        ]
    }

    get interval() {
        return generateInterval({
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate
        })
    }

    get dateRange() {
        return {
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate
        }
    }

    get currency() {
        return this._currencySelectStore.value as Currencies
    }

    setData = (transactions: POSSummaryResponse) => {
        const all =
            transactions?.all?.length > 0
                ? this.getResponseDataByStatus(transactions, POSPaymentStateStatus.all)
                : {
                      amount: emptyChartDataGenerator(this.dateRange, this.interval),
                      count: emptyChartDataGenerator(this.dateRange, this.interval)
                  }

        const successful =
            transactions?.successful?.length > 0
                ? this.getResponseDataByStatus(transactions, POSPaymentStateStatus.successful)
                : {
                      amount: emptyChartDataGenerator(this.dateRange, this.interval),
                      count: emptyChartDataGenerator(this.dateRange, this.interval)
                  }

        const failed =
            transactions?.failed?.length > 0
                ? this.getResponseDataByStatus(transactions, POSPaymentStateStatus.failed)
                : {
                      amount: emptyChartDataGenerator(this.dateRange, this.interval),
                      count: emptyChartDataGenerator(this.dateRange, this.interval)
                  }

        this._data = {
            all,
            successful,
            failed
        }
    }

    getResponseDataByStatus(response: POSSummaryResponse, status: POSPaymentStateStatus) {
        return {
            amount: {
                labels: response[status]?.map((item) =>
                    getIntervalValue({
                        interval: this.interval,
                        date: moment(item.value)
                    })
                ),
                datasets: [
                    {
                        label: translations().previousRangeData,
                        currency: this._currencySelectStore.value,
                        data: response[status]?.map((item) => item.amount)
                    }
                ]
            },
            count: {
                labels: response[status]?.map((item) =>
                    getIntervalValue({
                        interval: this.interval,
                        date: moment(item.value)
                    })
                ),
                datasets: [
                    {
                        label: translations().currentRangeData,
                        data: response[status]?.map((item) => item.count)
                    }
                ]
            }
        }
    }

    averageMetricsHandler(data: PosTransactionSummaryItem[]) {
        const totalAmount = calculateTotalAmount(data)

        const totalTransactions = calculateTotalCount(data)

        const period =
            this._rangePickerStore.endDate.diff(this._rangePickerStore.startDate, 'days') + 1

        runInAction(() => {
            this.transactionValue = getAmount(totalAmount / totalTransactions)
            this.dailyValue = getAmount(totalAmount / period)
            this.transactionsPerDay = Math.floor(totalTransactions / period)
        })
    }

    async loadData() {
        if (this.dataLoadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.dataLoadingState = LoadingState.LOADING
        })

        const request = {
            from: this.dateRange.from?.format(),
            to: this.dateRange.to?.format(),
            interval: this.interval,
            currency: this._currencySelectStore.value,
            ...this._storeSelectStore.requestForServer
        }

        await runInAction(async () => {
            try {
                const {result, error} = await getPOSTransactionsSummary(request)

                if (error || !result) {
                    this.setData({
                        all: [],
                        successful: [],
                        failed: []
                    })
                }

                if (error) {
                    this.dataLoadingState = LoadingState.FAILED
                    return openErrorNotification(error.message)
                } else if (!result) {
                    this.dataLoadingState = LoadingState.FAILED
                    return
                }

                this.averageMetricsHandler(result.successful)
                this.setData(result)

                this.dataLoadingState = LoadingState.DONE
            } catch (error) {
                this.dataLoadingState = LoadingState.FAILED
                this.setData({
                    all: [],
                    successful: [],
                    failed: []
                })
                return openErrorNotification(rootTranslations().errors.general)
            }
        })
    }

    get data() {
        if (!this._currencySelectStore.value && this._merchantStoresStore.ecomStores) {
            this._currencySelectStore.setValue(
                getPosMostUsedDefaultCurrency(this._merchantStoresStore.ecomStores)
            )
        }

        if (
            this.dataLoadingState !== LoadingState.LOADING &&
            this.dataLoadingState !== LoadingState.DONE &&
            this.dataLoadingState !== LoadingState.FAILED &&
            this._shouldFetchData
        ) {
            this.loadData()
        }

        const currentData = this._data[this.selectedStatus]
        if (!currentData || !currentData.amount || !currentData.count) {
            const options = barChartConfig.barOptions({
                title: generateChartTitleForRange({
                    from: this._rangePickerStore.startDate,
                    to: this._rangePickerStore.endDate
                }),
                showTooltip: false
            }) as ChartOptions

            return {data: emptyChartDataGenerator(this.dateRange, this.interval), options}
        }

        return {
            data: currentData[this.selectedDataType.key],
            options: barChartConfig.barOptions({
                title: generateChartTitleForRange({
                    from: this._rangePickerStore.startDate,
                    to: this._rangePickerStore.endDate
                })
            }) as ChartOptions
        }
    }

    setSelectedStatus = (status: POSPaymentStateStatus) => {
        this.selectedStatus = status
    }

    setSelectedDataType = (dataTypeKey: string) => {
        this.selectedDataType = this.dataTypes.find((item) => (item.key = dataTypeKey))
    }

    handleCarouselWidgetClick = () => {
        getRouterStore().push(ROUTES.paymentLinks)
    }
}
