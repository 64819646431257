import React from 'react'
import { observer } from 'mobx-react-lite'
import { Router } from 'react-router-dom'

import { RouterStore } from './RouterStore'

type Props = React.PropsWithChildren<{ routerStore: RouterStore }>

export const BrowserRouter = observer((props: Props) => {
    const { routerStore, children } = props
    return (
        <Router
            location={routerStore.state.location}
            navigationType={routerStore.state.action}
            navigator={routerStore.history}
        >
            {children}
        </Router>
    )
})
