import {ReactNode} from 'react'
import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    description: ReactNode
    paypalStatus: string
    settings: string
    yourAccountDisabled: string
    consentRevokedText: string
    readyPaymentText: string
}>()
