import React from 'react'
import {observer} from 'mobx-react'
import {Link} from 'react-router-dom'
import {Col, Collapse as AntCollapse, Row, Typography} from 'antd'

import {Button, Collapse, CookiesLinks, Description, Switch} from '@/components'
import {essentialDataSource, statisticsDataSource} from '../../constants/constants'
import translations from './translations'
import {IndividualCookieProps} from './props'
import styles from './styles.scss'

const {Panel} = AntCollapse

export const IndividualCookie: React.FC<IndividualCookieProps> = observer((
    {onBackClick, onAcceptAllClick, onSaveClick, setStatisticsChecked, statisticsChecked}
) => {
    return (
        <Row gutter={40} justify={'space-between'}>
            <Col span={24}>
                <Typography.Title level={3}>
                    {translations().cookies}
                </Typography.Title>
                <Typography.Text>
                    {translations().cookiesContent}
                </Typography.Text>
                <br/><br/>
                <Button
                    onClick={onAcceptAllClick}
                    type={'primary'}
                >
                    {translations().acceptAll}
                </Button>
                <br/><br/>
                <Button onClick={onSaveClick}>{translations().save}</Button>
                <br/><br/>
                <Link to={'#'} onClick={onBackClick}>{translations().back}</Link>
                <br/><br/>
                <Typography.Title level={4}>
                    {translations().essential}
                </Typography.Title>
                <Typography.Text>
                    {translations().essentialContent}
                </Typography.Text>
                <Collapse className={styles.collapse}>
                    <Panel header={translations().cookieInformation} key="1">
                        <Description dataSource={essentialDataSource}/>
                    </Panel>
                </Collapse>
                <br/><br/>
                <Typography.Title level={4}>
                    {translations().statistics}
                </Typography.Title>
                <Typography.Text>
                    {translations().statisticsContent}
                </Typography.Text>
                <Collapse className={styles.collapse}>
                    <Panel
                        extra={(
                            <Switch
                                checked={statisticsChecked}
                                onClick={(checked, e) => {
                                    e.stopPropagation()
                                    setStatisticsChecked(checked)
                                }}
                            />
                        )}
                        header={translations().cookieInformation} key="1"
                    >
                        <Description dataSource={statisticsDataSource}/>
                    </Panel>
                </Collapse>
                <br/>
                <CookiesLinks className={styles.links}/>
            </Col>
        </Row>
    )
})
