import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    averageMetrics: string
    averageMetricsTooltip: string
    transactionsValue: string
    dailyValue: string
    transactionsPerDay: string
}>()

