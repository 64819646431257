import React, { useEffect } from 'react'
import {withTitle} from '@/router/utils'

export const EcospendReturn = withTitle(() => {
    useEffect(() => {
        const data = getQueryVariables() as any
        localStorage.setItem('ecospend-return-data', JSON.stringify(data))
        setTimeout(() => {
            window.close()
        }, 500)
    }, [])

    return <div />
})

function getQueryVariables() {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    const result = {}
    for (const item of vars) {
        const pair = item.split('=')
        result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    }
    return result
}
