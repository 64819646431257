import translations from './translations'

translations.add('en', {
    showAll: 'Show all',
    previousRangeData: 'Previous',
    currentRangeData: 'Current',
    statuses: {
        AUTH: 'Pending',
        CHARGE: 'Charged',
        REFUND: 'Refunded',
        REJECT: 'Rejected'
    },
    dataTypes: {
        amount: 'Amount',
        count: 'Number'
    },
    week: 'Week'
})
