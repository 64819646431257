import translations from './translations'

translations.add('ro', {
    cookies: 'Preferințe cookie-uri',
    cookiesContent: 'Utilizăm cookie-uri pe site-ul nostru. Unele dintre ele sunt esențiale, în timp ce altele ne ajută să îmbunătățim acest site și experiența dvs.',
    acceptAll: 'Acceptă toate',
    save: 'SaSalvațive',
    back: 'Înapoi',
    essential: 'Esențiale',
    essentialContent: 'Cookie-urile esențiale permit funcții de bază și sunt necesare pentru ca site-ul să funcționeze corect.',
    statistics: 'Statistică',
    statisticsContent: 'Cookie-urile de statistică colectează informații în mod anonim. Aceste informații ne ajută să înțelegem cum utilizează vizitatorii site-ul nostru.',
    cookieInformation: 'Informații despre cookie-uri'
})
