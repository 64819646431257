import React, {useEffect} from 'react'
import {Collapse as AntCollapse, Form} from 'antd'
import {observer} from 'mobx-react'
import {Collapse, Input, Select} from '@/components'
import translations from './translations'
import {TeamManagementFilterFormProps} from './props'
import styles from './styles.scss'
import {
    TEAMMATE_EMAIL,
    TEAMMATE_EMAIL_FILTER,
    TEAMMATE_FIRST_NAME_FILTER,
    TEAMMATE_LAST_NAME_FILTER,
    TEAMMATE_STATUS_FILTER,
    TEAMMATE_STORE_FILTER,
    TEAMMATE_AUTH_METHOD_FILTER
} from '@/constants/playwright-ids'

const {Panel} = AntCollapse

export const TeamManagementFilterForm: React.FC<TeamManagementFilterFormProps> = observer(
    ({
        formId,
        form,
        onFinish,
        stores,
        statuses,
        authMethods,
        isInvitedTabActive,
        onStoreChange,
        onStatusChange,
        onFormValuesChange,
        onAuthMethodChange,
        values
    }) => {
        useEffect(() => {
            form.setFieldsValue({...values})
        }, [values])

        return (
            <Form
                id={formId}
                form={form}
                onFinish={onFinish}
                initialValues={{
                    store: stores && stores[0].value,
                    authMethod: authMethods && authMethods[0].value,
                    ...(statuses.length > 0 ? {status: statuses && statuses[0]?.value} : {})
                }}
                onValuesChange={(changedValues, allValues) => {
                    onFormValuesChange(allValues)
                }}
            >
                <Collapse defaultActiveKey={['email']}>
                    <Panel
                        className={styles.panel}
                        header={translations().labels.email}
                        key='email'
                    >
                        <Form.Item className={styles.formItem} name='email'>
                            <Input
                                className={styles.input}
                                data-test-id={TEAMMATE_EMAIL_FILTER}
                                placeholder={translations().labels.email}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel
                        className={styles.panel}
                        header={translations().labels.firstName}
                        key='firstName'
                    >
                        <Form.Item className={styles.formItem} name='firstName'>
                            <Input
                                className={styles.input}
                                data-test-id={TEAMMATE_FIRST_NAME_FILTER}
                                placeholder={translations().labels.firstName}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel
                        className={styles.panel}
                        header={translations().labels.lastName}
                        key='lastName'
                    >
                        <Form.Item className={styles.formItem} name='lastName'>
                            <Input
                                className={styles.input}
                                data-test-id={TEAMMATE_LAST_NAME_FILTER}
                                placeholder={translations().labels.lastName}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel
                        className={styles.panel}
                        header={translations().labels.store}
                        key='store'
                    >
                        <Form.Item className={styles.formItem} name='store'>
                            <Select
                                className={styles.select}
                                bordered={false}
                                data-test-id={TEAMMATE_STORE_FILTER}
                                options={stores}
                                placeholder={translations().labels.store}
                                onChange={onStoreChange}
                                showSearch
                            />
                        </Form.Item>
                    </Panel>
                    {statuses.length > 0 ? (
                        <Panel
                            className={styles.panel}
                            header={translations().labels.status}
                            key='status'
                        >
                            <Form.Item className={styles.formItem} name='status'>
                                <Select
                                    className={styles.select}
                                    bordered={false}
                                    data-test-id={TEAMMATE_STATUS_FILTER}
                                    options={statuses}
                                    placeholder={translations().labels.status}
                                    onChange={onStatusChange}
                                    showSearch
                                />
                            </Form.Item>
                        </Panel>
                    ) : null}

                    {!isInvitedTabActive && (
                        <Panel
                            className={styles.panel}
                            header={translations().labels.authMethod}
                            key='authMethod'
                        >
                            <Form.Item className={styles.formItem} name='authMethod'>
                                <Select
                                    className={styles.select}
                                    bordered={false}
                                    data-test-id={TEAMMATE_AUTH_METHOD_FILTER}
                                    options={authMethods}
                                    placeholder={translations().labels.authMethod}
                                    onChange={onAuthMethodChange}
                                    showSearch
                                />
                            </Form.Item>
                        </Panel>
                    )}
                </Collapse>
            </Form>
        )
    }
)
