import React from 'react'
import translations from './translations'

translations.add('ro', {
    title: 'Retrageri',
    description: (
        <>
            Ce este un refuz la plată? <br /> Un refuz la plată este rezultatul potențial al unei
            tranzacții contestate cu cardul de credit sau debit. Dacă banca titularului de card
            acceptă disputa, aceasta va inversa tranzacția, luând fondurile de la comerciant și
            returnându-le titularului de card. <br /> Când un titular de card contestă o tranzacție,
            banca emitentă va revizui tranzacția și va decide dacă disputa este valabilă. Dacă
            consideră că este, vor oferi titularului de card un credit provizoriu și vor colabora cu
            rețeaua de carduri și banca achizitoare pentru a finaliza refuzul la plată.
        </>
    )
})
