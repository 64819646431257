import React from 'react'
import {observer} from 'mobx-react'
import {SendVerificationCode} from '@/components'

import translations from './translations'
import styles from './styles.scss'

export type TwoFAConfirmProps = {
    onSubmit: (v) => void
    onCancel: () => void
}

export const TwoFAConfirm: React.FC<TwoFAConfirmProps> = observer(({onCancel, onSubmit}) => {
    return (
        <SendVerificationCode
            cancelButtonText={translations().cancel}
            submitButtonText={translations().submit}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )
})
