import translations from './translations'

const getPOSBaseExportColumns = (): Record<string, string> => ({
    transactionDate: translations().transactionDate,
    amount: translations().amount,
    currency: translations().currency,
    status: translations().status,
    returnCode: translations().returnCode,
    returnCodeDescription: translations().returnCodeDescription,
    transactionId: translations().transaction.id,
    mid: translations().mid,
    terminalId: translations().terminalId,
    transactionType: translations().transaction.type,
    transactionDetails: translations().transaction.details,
    transactionCountry: translations().transaction.country,
    transactionCity: translations().transaction.city,
    cardScheme: translations().cardScheme,
    cardMask: translations().cardMask
})

export const getPOSExportColumns = () => ({
    ...getPOSBaseExportColumns(),
    cardType: translations().cardType,
    issuerRegion: translations().issuerRegion,
    isCorporateCard: translations().isCorporateCard,
    captureMethod: translations().captureMethod
})

export const getPOSAmexExportColumns = () => ({
    ...getPOSBaseExportColumns(),
    captureMethod: translations().captureMethod
})
