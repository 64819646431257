import React from 'react'
import {Collapse as AntCollapse, Typography} from 'antd'
import {PaymentMethodSettingPanelHeader} from '@/pages/payment-methods/components'
import {PaymentMethodStoreSetting} from './components'
import {Collapse} from '@/components'
import {getActiveApplePayTerminalCount, getTerminalsLabel} from '@/stores/payment-methods'
import {PaymentMethodStoreSettingsProps} from './props'
import translations from './translations'
import styles from './styles.scss'
import {PAYMENT_METHOD_SETTINGS} from '@/constants/playwright-ids'

export const PaymentMethodStoreSettings: React.FC<PaymentMethodStoreSettingsProps> = ({
    stores,
    paymentMethodType,
    extra
}) => {
    if (!stores?.length) {
        return null
    }

    return (
        <>
            <br />
            <Typography.Title className={styles.title} type={'secondary'} level={4}>
                {translations().settings} {extra && extra}
            </Typography.Title>
            <Collapse defaultActiveKey={'1'}>
                {stores.map((store) => (
                    <AntCollapse.Panel
                        header={
                            <PaymentMethodSettingPanelHeader
                                title={store.name}
                                terminalsLabel={getTerminalsLabel(store.terminals)}
                                activeLabel={getActiveApplePayTerminalCount(
                                    store.terminals,
                                    paymentMethodType
                                )}
                            />
                        }
                        className={styles.panelHeader}
                        key={store.name}
                        data-test-id={PAYMENT_METHOD_SETTINGS}
                    >
                        <PaymentMethodStoreSetting
                            dataSource={store.terminals}
                            paymentMethodType={paymentMethodType}
                            onSwitchChange={store.onTerminalSwitchChange}
                        />
                    </AntCollapse.Panel>
                ))}
            </Collapse>
        </>
    )
}
