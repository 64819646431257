import translations from './translations'

translations.add('ro', {
    amount: 'Sumă',
    chargebackDate: 'Data Returnării',
    transactionDate: 'Data Tranzacției',
    currency: 'Monedă',
    reasonCode: 'Codul Motivului',
    merchantName: 'Numele Comercianului',
    reason: 'Motiv',
    status: 'Stare',
    paymentMethod: 'Metodă de plată'
})
