import {Col, Form, FormInstance, FormItemProps, InputProps, Row, Select, SelectProps} from 'antd'
import React from 'react'
import {Currencies, currencies as defaultCurrencies} from '@/constants'
import {AmountFormField} from '@/components'
import translations from '../AmountFormField/translations'
import {CURRENCY_ID} from '@/constants/playwright-ids'

type Props = {
    form: FormInstance
    amountName?: string
    currencyName?: string
    currency?: Currencies
    inputProps?: InputProps
    selectProps?: SelectProps
    currencies?: string[]
    shouldAcceptZero?: boolean
} & Pick<FormItemProps, 'rules' | 'dependencies'>

export const AmountCurrencyFormField: React.FC<Props> = (props) => {
    const {
        form,
        amountName = 'amount',
        currencyName = 'currency',
        currency = Currencies.GBP,
        inputProps,
        selectProps,
        currencies = defaultCurrencies,
        rules,
        dependencies,
        shouldAcceptZero
    } = props
    const showCurrency = currencies?.length >= 1 || form.getFieldValue(currency) !== Currencies.GBP

    return (
        <Row gutter={[8, 0]}>
            <Col xs={12}>
                <Form.Item label={translations().amount}>
                    <AmountFormField
                        name={amountName}
                        currency={currency}
                        form={form}
                        label={null}
                        noStyle
                        inputProps={{
                            prefix: null,
                            ...inputProps
                        }}
                        shouldAcceptZero={shouldAcceptZero}
                        rules={rules}
                        dependencies={dependencies}
                    />
                </Form.Item>
            </Col>
            <Col xs={12} style={{display: showCurrency ? 'block' : 'none'}}>
                <Form.Item label={translations().currency} name={currencyName}>
                    <Select {...selectProps} data-test-id={CURRENCY_ID}>
                        {currencies.map((item) => (
                            <Select.Option key={item} value={item} data-test-id={item}>
                                {item}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    )
}
