import translations from './translations'

translations.add('sl', {
    edit: 'Uprav',
    share: 'Zdieľať',
    areYouSureYouWantToDeactivate: 'Naozaj chcete deaktivovať odkaz na platbu?',
    yes: 'Áno',
    no: 'Nie',
    createSimilar: 'Vytvoriť nové',
    deactivate: 'Deaktivovať'
})
