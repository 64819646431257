import React from 'react'
import {Col, Divider, Layout, Row, Skeleton} from 'antd'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {useInjection} from 'dna-react-ioc'
import {LoadingState} from '@/types'
import {useScreenType} from '@/hooks'
import {withTitle} from '@/router/utils'
import {PaginationContainer} from '@/components'
import {AccountInfoHeaderCard, AccountTransactionFilter, TransactionsList} from './components'
import {AccountInfoStoreInterface} from './AccountInfoStoreInterface'
import translations from './translations'
import styles from './styles.scss'

export const AccountInfoStoreSymbol = Symbol('AccountInfoStore')
export const AccountInfoPaginationStoreSymbol = Symbol('AccountInfoPaginationStore')
export const CardIssuingConfigsStoreSymbol = Symbol('CardIssuingConfigs')

const AccountInfo: React.FC = observer(() => {
    const {isMobile} = useScreenType()
    const {
        transactions,
        accountsLoadingState,
        transactionsLoadingState,
        accountInfo,
        accountName,
        onPayAndTransferClick
    } = useInjection<AccountInfoStoreInterface>(AccountInfoStoreSymbol)

    return (
        <>
            <Layout.Content className={styles.body}>
                {accountsLoadingState === LoadingState.LOADING && (
                    <Skeleton className={styles.skeleton} active={true} />
                )}
                {accountsLoadingState === LoadingState.DONE && (
                    <AccountInfoHeaderCard
                        name={accountName}
                        available={accountInfo.available}
                        current={accountInfo.available - accountInfo.blocked}
                        accountNumber={accountInfo.iban}
                        onClick={onPayAndTransferClick}
                    />
                )}

                <AccountTransactionFilter />

                <Row justify={'space-between'}>
                    <Col>{translations().description}</Col>
                    <Col className={classNames(styles.sum, {[styles.sumMobile]: isMobile})}>
                        {translations().sum}
                    </Col>
                </Row>
                <Divider className={styles.divider} />
                <TransactionsList
                    dataSource={transactions}
                    loading={
                        transactionsLoadingState === LoadingState.LOADING ||
                        transactionsLoadingState === LoadingState.IDLE
                    }
                />

                <PaginationContainer injectableIdentifier={AccountInfoPaginationStoreSymbol} />
            </Layout.Content>
        </>
    )
})

export default withTitle(AccountInfo)
