import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import translations from './translations'


translations.add('es', {
    title: 'Exportar pagos en línea',  
    columns: 'Columnas',
    cancel: 'Cancelar',
    ok: 'Exportar',
    all: (value) => `Todo (${value})`,
    custom: 'Personalizado',
    successExportMessage: <span>
        Su solicitud de descarga ha sido puesta en cola para ser procesada. Una vez que el documento esté listo, podrá descargarse desde la sección {' '}
        <Link to={ROUTES.exports}>Exportaciones</Link>{' '}.
    </span>
})
