import translations from './translations'

translations.add('el', {
    title: 'Εξαγωγές',
    documentType: 'Είδος αρχείου',
    id: 'Αναγνωριστικό',
    fileName: 'Ονομα αρχείου',
    dateRequested: 'Ημερομηνία που ζητήθηκε',
    lastUpdatedDate: 'Τελευταία ενημέρωση',
    status: 'Κατάσταση',
    statuses: {
        STARTED: 'Ξεκίνησε',
        PROCESSING: 'Σε εξέλιξη',
        QUEUED: 'Σε ουρά',
        FINISHED: 'Ολοκληρώθηκε',
        FAILED: 'Απέτυχε'
    }

})
