import translations from './translations'

translations.add('el', {
    areYouSureYouWantTo: (c: boolean) =>
        `Είστε βέβαιοι ότι θέλετε να ${c ? 'ενεργοποιήσετε' : 'απενεργοποιήσετε'} τον τρόπο πληρωμής?`,
    yes: 'Ναι',
    no: 'Όχι',
    active: 'Ενεργοί',
    disabled: 'Aπενεργοποιημένο'
})
