import React from 'react'
import {Form} from 'antd'
import {useInjection} from 'dna-react-ioc'
import {observer} from 'mobx-react'
import {useTimer} from 'dna-common'
import {Link} from 'react-router-dom'

import {ROUTES} from '@/router/routes'
import {withTitle} from '@/router/utils'
import {LoadingState} from '@/types'
import {Button, Card, Input} from '@/components'
import {TConfirmEmailStore} from '@/pages/ConfirmEmail'
import {CONFIRM_EMAIL_BUTTON, RESEND_OTP_CODE} from '@/constants/playwright-ids'
import translations from './translations'
import styles from './styles.scss'


export const ConfirmEmailStoreSymbol = Symbol('ConfirmEmailStore')

const ConfirmEmail = observer(() => {
    const [form] = Form.useForm()
    const store = useInjection<TConfirmEmailStore>(ConfirmEmailStoreSymbol)
    const {start, count, isRunning} = useTimer(60)

    const onFinish = (values) => {
        store.confirmEmail(values)
    }

    const onResendCodeClick = () => {
        start()
        store.onResendClick()
    }

    const formId = 'reset-password'

    return <>
        <div className={styles.resetPassContainer}>
            <Link to={ROUTES.resetPassword}>{translations().back}</Link>
            <Card
                title={translations().title}
            >
                <div>{translations().text(store.email)}</div>
                <br/>
                <Form
                    form={form}
                    id={formId}
                    layout={'vertical'}
                    requiredMark={false}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={translations().otp}
                        name={'key'}
                        rules={[{required: true, message: translations().otpRequired}]}
                    >
                        <Input maxLength={6} placeholder={translations().otp}  data-test-id={'code'}/>
                    </Form.Item>
                    <Form.Item data-test-id={RESEND_OTP_CODE}>
                        {
                            isRunning ?
                                translations().waitingToResendVerificationCode(count) :
                                translations().confirmEmailDescription(onResendCodeClick)
                        }
                    </Form.Item>
                    <Form.Item key={'submit'}>
                        <Button
                            form={formId}
                            type={'primary'}
                            htmlType={'submit'}
                            className={styles.submit}
                            loading={store.confirmEmailLoadingState === LoadingState.LOADING}
                            data-test-id={CONFIRM_EMAIL_BUTTON}
                        >
                            {translations().submit}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    </>
})

export default withTitle(ConfirmEmail)
