import React from 'react'
import {observer} from 'mobx-react-lite'
import {Table} from 'antd'
import {useInjection} from 'dna-react-ioc'
import {TableViewType} from '@/constants'
import {useScreenType} from '@/hooks/useScreenType'
import {TChargebacksTableStore} from './TChargebacksTableStore'
import {PaginationContainer} from '@/components/containers'
import {ChargebacksTableMobile} from '../ChargebacksTableMobile'
import {ActionButtons} from '@/pages/components'

import styles from './styles.scss'

export const ChargebacksTableStoreSymbol = Symbol('ChargebacksTableStoreSymbol')
export const ChargebacksPaginationStoreSymbol = Symbol('ChargebacksPaginationStoreSymbol')

export const ChargebacksTable = observer(() => {
    const {isMobile} = useScreenType()
    const store = useInjection<TChargebacksTableStore>(ChargebacksTableStoreSymbol)
    const {chargebacksDetailsStore, chargebacksStore, data, columns, isLoading} = store

    return (
        <div>
            {isMobile && chargebacksStore.mobileTableViewType === TableViewType.SHORTED && (
                <>
                    <Table
                        scroll={{x: 500}}
                        loading={isLoading}
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        onRow={(item) => ({onClick: () => chargebacksDetailsStore.onOpen(item)})}
                        rowKey={(transaction) => transaction.id}
                    />
                    <PaginationContainer
                        hideSizeChanger
                        injectableIdentifier={ChargebacksPaginationStoreSymbol}
                    />
                </>
            )}

            {isMobile && chargebacksStore.mobileTableViewType === TableViewType.EXPANDED && (
                <>
                    <ChargebacksTableMobile
                        isLoading={isLoading}
                        dataSource={data}
                        listExtra={(item) => (
                            <ActionButtons onClick={() => chargebacksDetailsStore.onOpen(item)} />
                        )}
                    />

                    <PaginationContainer
                        hideSizeChanger
                        injectableIdentifier={ChargebacksPaginationStoreSymbol}
                    />
                </>
            )}

            {!isMobile && (
                <>
                    <Table
                        scroll={{x: 1000}}
                        loading={isLoading}
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        onRow={(item) => ({onClick: () => chargebacksDetailsStore.onOpen(item)})}
                        rowKey={({id}) => id}
                        rowClassName={() => styles.row}
                    />

                    <PaginationContainer injectableIdentifier={ChargebacksPaginationStoreSymbol} />
                </>
            )}
        </div>
    )
})
