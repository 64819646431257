import translations from './translations'

translations.add('ro', {
    paymentView: 'Vizualizare plată',
    paymentLink: 'Link de plată',
    date: 'Dată',
    orderNumber: 'Număr comandă',
    customer: 'Client',
    transactionType: 'Tip tranzacție',
    cancel: 'Anulare',
    ok: 'Detalii complete despre plată'
})
