import React from 'react'
import {observer} from 'mobx-react'
import {Collapse, Grid} from 'antd'
import {CaretRightOutlined} from '@ant-design/icons'

import {DrawerContainer} from '@/components/containers'
import {Description} from '@/components/dumb/Description'
import {TransactionDetailsContainerProps} from './props'
import styles from './styles.scss'

const {Panel} = Collapse
const {useBreakpoint} = Grid

export const TransactionDetailsContainer: React.FC<TransactionDetailsContainerProps> = observer((props) => {
    const screens = useBreakpoint()
    const isMobile = screens.xs
    const {title, details = [], drawerStore, drawerInjectableId} = props

    return (
        <DrawerContainer
            store={drawerStore}
            injectableIdentifier={drawerInjectableId}
            placement="right"
            title={title}
            className={styles.drawer}
        >
            <Collapse
                bordered={false}
                ghost
                defaultActiveKey={['0', '1']}
                expandIcon={({ isActive }) => (
                    <CaretRightOutlined className={styles.expandIcon} rotate={isActive ? 270 : 90} />
                )}
                expandIconPosition={'end'}
                className={styles.accordion}
            >
                {
                    details.map((detail, index) => (
                        <Panel className={styles.panel} header={detail.title} key={index}>
                            <Description className={isMobile && styles.description} dataSource={detail.fields}/>
                        </Panel>
                    ))
                }
            </Collapse>
        </DrawerContainer>
    )
})
