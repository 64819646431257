import React from 'react'
import {observer} from 'mobx-react'
import {Form, Radio, Space, Typography} from 'antd'
import {Modal, ModalFormFooter} from '@/components'
import {ShippingReference} from '@/stores/payment-methods'
import {AddNewDomainModalProps} from './props'
import translations from './translations'
import {
    PAYPAL_SETTINGS_CUSTOMER_ADDRESS, PAYPAL_SETTINGS_MERCHANT_ADDRESS,
    PAYPAL_SETTINGS_SHIPPING_ADDRESS, PAYPAL_TRANSACTION_AUTH,
    PAYPAL_TRANSACTION_CAPTURE
} from '@/constants/playwright-ids'

export const PaypalSettingsModal: React.FC<AddNewDomainModalProps> = observer((
    {isOpen, onOk, onCancel, loading, settings}
) => {
    const [form] = Form.useForm()
    const shippingPreference = Form.useWatch('shippingPreference', form)
    const defaultTransactionType = Form.useWatch('defaultTransactionType', form)

    if (!settings) {
        return null
    }

    return (
        <Modal
            title={translations().title}
            open={isOpen}
            onCancel={onCancel}
            footer={<ModalFormFooter
                formId={'set-paypal-settings'}
                onCancel={onCancel}
                cancelText={translations().cancel}
                confirmLoading={loading}
                okText={translations().saveChanges}
                okButtonProps={{
                    disabled: settings.defaultTransactionType === defaultTransactionType &&
                        settings.shippingPreference === shippingPreference
                }}
            />}
        >
            <Form
                form={form}
                id={'set-paypal-settings'}
                layout={'vertical'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={settings}
                onFinish={onOk}
                autoComplete='off'
            >
                <Typography.Text>
                    {translations().configurationOptions}
                </Typography.Text>
                <br/><br/>
                <Typography.Text type={'secondary'}>
                    {translations().shippingPreference}
                </Typography.Text>
                <br/><br/>
                <Form.Item name={'shippingPreference'}>
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio
                                value={ShippingReference.GET_FROM_FILE}
                                data-test-id={PAYPAL_SETTINGS_CUSTOMER_ADDRESS}
                            >
                                {translations().useCustomerProvidedShippingAddress}
                            </Radio>
                            <Radio
                                value={ShippingReference.NO_SHIPPING}
                                data-test-id={PAYPAL_SETTINGS_SHIPPING_ADDRESS}
                            >
                                {translations().redactShippingAddressFromPaypalSite}
                            </Radio>
                            <Radio
                                value={ShippingReference.SET_PROVIDED_ADDRESS}
                                data-test-id={PAYPAL_SETTINGS_MERCHANT_ADDRESS}
                            >
                                {translations().useMerchantProvidedAddress}
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Typography.Text type={'secondary'}>
                    {translations().defaultTransactionType}
                </Typography.Text>
                <br/><br/>
                <Form.Item name={'defaultTransactionType'}>
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value={'auth'} data-test-id={PAYPAL_TRANSACTION_AUTH}>
                                {translations().authorization}
                            </Radio>
                            <Radio value={'sale'} data-test-id={PAYPAL_TRANSACTION_CAPTURE}>
                                {translations().capture}
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    )
})
