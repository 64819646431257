import React from 'react'
import translations from './translations'

translations.add('ro', {
    hereWillBeTheList: 'Aici va fi lista domeniilor dvs',
    textWithExplanationOfDomain: <>
        Nu aveți domenii verificate
        <br/>
        Pentru a adăuga domeniile dvs., utilizați butonul &quot;Adaugă Domenii&quot;
    </>,
    addNewDomain: 'Adaugă un domeniu nou'
})
