import translations from './translations'

translations.add('es', {
    title: 'Dispositivos de confianza',
    delete: 'Eliminar',
    columns: {
        device: 'Dispositivo',
        addedOn: 'Añadido en',
        lastLogin: 'Último inicio de sesión'
    },
    messages: {
        confirm: '¿Estás seguro/a de que quieres eliminar tu dispositivo de confianza?'
    },
    yes: 'Sí',
    no: 'No',
    tablet: 'Tableta',
    mobile: 'Móvil',
    desktop: 'Escritorio',
    bot: 'Bot'
})
