import {getWithAuth, putWithAuth} from '@/api'
import {
    CardCVVResponse,
    CardPINResponse,
    CardResponse,
    CardsRequest,
    CardsResponse,
    CardStatus
} from '@/stores'

export const tokens = {
    companyToken: '081d936c-b7b0-4cf4-adfd-29002542c79b',
    walletTokenVirtual: '52cdc8e2-7021-4543-84d8-6df87c6c671e',
    virtualCardToken: '5b7ffe64704fb6c05e3b555352fcf122b45d30a20e62576c003263a6faffea7d',
    walletTokenPlastic: '397a930f-9d22-4bc2-8730-5268e6610cb1',
    plasticCardToken: '52f192fbd87f5be769965e9f1b4c495df7ac45c7be6b005c8da205727ab50bc3'
}

export const fetchCards = (request: Partial<CardsRequest>) =>
    getWithAuth<CardsResponse>('/oppapi/v2/card-issuing/cards', request)

export const fetchCardFirstHalf = (token: string) =>
    getWithAuth<CardResponse>(`/oppapi/v2/card-issuing/cards/${token}/first-half`, null)

export const fetchCardCVV = (token: string) =>
    getWithAuth<CardCVVResponse>(`/oppapi/v2/card-issuing/cards/${token}/second-half`, null)

export const fetchCardPIN = (token: string) =>
    getWithAuth<CardPINResponse>(`/oppapi/v2/card-issuing/cards/${token}/pin`, null)

export const fetchChangeCardStatus = (token: string, status: CardStatus) =>
    putWithAuth<CardPINResponse>(`/oppapi/v2/card-issuing/cards/${token}/status`, {
        status: status
    })

export const activateCard = () =>
    putWithAuth<{status: string}>(
        `/oppapi/v1/card-issuing/company/${tokens.companyToken}/wallet/${tokens.walletTokenPlastic}/activate`
    )
