import translations from './translations'

translations.add('es', {
    all: 'Todo',
    selectAll: 'Seleccionar todo',
    deselectAll: 'Deseleccionar todo',
    placeholder: 'Busca aquí...',
    noData: 'Sin datos',
    selected: (count) => `${count} Seleccionado`
})

