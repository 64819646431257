import { POSPaymentStatus } from '@/constants'
import {TagType} from '@/components/dumb/Tag/props'

export const getPOSPaymentStatusTagType = (status: POSPaymentStatus): TagType => {
    switch (status) {
        case POSPaymentStatus.authorised:
        case POSPaymentStatus.charged:
            return TagType.Success
        case POSPaymentStatus.cancelled:
        case POSPaymentStatus.declined:
            return TagType.Error
        default:
            return TagType.Default
    }
}
