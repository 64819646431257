import {injectable} from 'inversify'
import {makeObservable} from 'mobx'

import {TerminalType, getPortalCurrencies, portalCardSchemes} from '@/constants'
import {FilterFormFieldType} from '@/types'
import {POSFilterType} from '@/types/transactions'
import {getNotEmptyValues} from '@/utils'
import {getPOSCaptureMethods} from '@/services/pos-transaction'
import {BaseFilterContainerStore} from '@/stores/shared'
import {StoreMidTerminalFilterStore} from '@/stores/store-and-terminals'

import rootTranslations from '@/translations'

@injectable()
export class POSFiltersStore extends BaseFilterContainerStore<POSFilterType> {
    private _smtfStore = new StoreMidTerminalFilterStore({
        terminalType: TerminalType.POS
    })

    constructor(
        private options: {
            hiddenFields?: string[]
        } = {}
    ) {
        super()

        makeObservable(this, {
            ...super.getAnnotationsMap()
        })
    }

    get loadingState() {
        return this._smtfStore.loadingState
    }

    get filter() {
        return {
            ...this._filter,
            store: this._smtfStore.selectedStore,
            mid: this._smtfStore.selectedMid,
            terminalId: this._smtfStore.selectedTerminal
        }
    }

    get fields(): FilterFormFieldType[] {
        const fields: FilterFormFieldType[] = [
            {
                name: 'transactionId',
                label: rootTranslations().fields.transactionId,
                type: 'input'
            },
            {
                name: 'store',
                label: rootTranslations().fields.store,
                type: 'selectWithTwoLabels',
                isHidden: this._smtfStore.storesAsSelectItemsWithAll.length === 0,
                data: {
                    optionsWithTwoLabels: this._smtfStore.storesAsSelectItemsWithAll
                }
            },
            {
                name: 'mid',
                label: rootTranslations().fields.mid,
                type: 'select',
                isHidden: this._smtfStore.midsAsSelectItemWithAll.length === 0,
                data: {
                    options: this._smtfStore.midsAsSelectItemWithAll
                }
            },
            {
                name: 'terminalId',
                label: rootTranslations().fields.terminalId,
                type: 'select',
                isHidden: this._smtfStore.terminalsAsSelectItemWithAll.length === 0,
                data: {
                    options: this._smtfStore.terminalsAsSelectItemWithAll
                }
            },
            {
                name: 'currency',
                label: rootTranslations().fields.currency,
                type: 'select',
                data: {
                    options: [
                        {label: rootTranslations().filter.all, value: ''},
                        ...getPortalCurrencies
                    ]
                }
            },
            {
                name: 'cardMask',
                label: rootTranslations().fields.cardMask,
                type: 'input',
                rules: [{min: 3, message: rootTranslations().messages.validation.minLength(3)}]
            },
            {
                name: 'cardScheme',
                label: rootTranslations().fields.cardScheme,
                type: 'select',
                data: {
                    options: [
                        {label: rootTranslations().filter.all, value: ''},
                        ...portalCardSchemes
                    ]
                }
            },
            {
                name: 'captureMethod',
                label: rootTranslations().fields.captureMethod,
                type: 'select',
                data: {
                    options: [
                        {label: rootTranslations().filter.all, value: ''},
                        ...getPOSCaptureMethods()
                    ]
                }
            }
        ]

        return fields.filter((f) => !this.options.hiddenFields?.includes(f.name))
    }

    isFilterSelected(field: string, value: any): boolean {
        if (field === 'store' && this._smtfStore.storesAsSelectItemsWithAll.length === 1) {
            return false
        }
        return super.isFilterSelected(field, value)
    }

    getInitialFilter() {
        return {
            transactionId: '',
            currency: '',
            cardMask: '',
            cardScheme: '',
            captureMethod: ''
        }
    }

    setFilter(filter: Partial<POSFilterType>) {
        const {store, mid, terminalId, ...rest} = filter
        super.setFilter(rest)

        if (store !== undefined) {
            this._smtfStore.setStore(store)
        }
        if (mid !== undefined) {
            this._smtfStore.setMid(mid)
        }
        if (terminalId !== undefined) {
            this._smtfStore.setTerminal(terminalId as string)
        }
    }

    clear() {
        this._smtfStore.clear()
        super.clear()
    }

    getFilterForBackend = (): Omit<Partial<POSFilterType>, 'store' | 'mid'> & {mids?: string[]} => {
        return getNotEmptyValues({
            ...this._smtfStore.requestForServer,
            transactionId: this.submittedFilter.transactionId,
            currency: this.submittedFilter.currency,
            cardScheme: this.submittedFilter.cardScheme,
            captureMethod: this.submittedFilter.captureMethod,
            cardMask: this.submittedFilter.cardMask
        })
    }
}
