import React from 'react'
import {notification} from 'antd'
import {NotificationInstance} from 'antd/lib/notification'
import {Icon} from '@/components'

let notificationInstance: NotificationInstance = null

export const setNotificationInstance = (instance: NotificationInstance) => notificationInstance = instance

export const openSuccessNotification = (message: string | React.ReactNode, duration = 3) => {
    const key = `openSuccess${Date.now()}`
    notificationInstance?.success({
        message,
        duration,
        key,
        placement: 'top',
        closeIcon: null,
        icon: <Icon hoverable onClick={() => notification.close(key)} type={'check'} />,
        style: {
            width: 575
        }
    })
}

export const openErrorNotification = (
    message: string | React.ReactNode,
    duration = 3,
    key = ''
) => {
    const generatedKey = key || `openError${Date.now()}`
    notificationInstance?.error({
        message,
        duration,
        key: generatedKey,
        placement: 'top',
        closeIcon: null,
        icon: <Icon hoverable onClick={() => notification.close(generatedKey)} type={'close'} />,
        style: {
            width: 575
        }
    })
}
