import translations from './translations'

translations.add('ro', {
    title: 'Setări card',
    freeze: {
        freezeCard: 'Blocare temporară a cardului',
        inCaseYouWantToFreeze: 'În cazul în care dorești să oprești temporar funcționarea cardului'
    },
    block: {
        blockCard: 'Blocare card',
        inCaseYouWantToBlock: 'În cazul în care cardul este pierdut sau furat'
    },
    activate: {
        activateCard: 'Activate card',
        inCaseYouWantToActivate: 'In case of blocked card'
    },
    getCVV: 'Get CVV',
    getPIN: 'Get PIN',
    cardholder: 'Titularul cardului',
    address: 'Adresă'
})
