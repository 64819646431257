import React from 'react'
import translations from './translations'

translations.add('el', {
    download: 'Κατεβάστε',
    cancel: 'Ακύρωση',
    title: 'Λήψη δήλωσης PDF',
    text: (
        <>
            Επιλέξτε την επιθυμητή Περίοδο Διακανονισμού και κάντε κλικ στο Λήψη. <br /> <br />
            <strong>Αποποίηση ευθυνών:</strong> Οι δηλώσεις μπορούν να ληφθούν για έναν συμπληρωμένο
            μήνα από την 1η ημέρα του νέου μήνα. Από τον Αύγουστο του 2023 και μετά, θα είναι τα πιο
            πρόσφατα στυλ και μορφή, και πριν από τον Αύγουστο του 2023, θα παραμείνουν στο παλιό
            σχέδιο.
        </>
    )
})
