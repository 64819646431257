import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    columns: string
    cancel: string
    ok: string
    all: (number) => string,
    custom: string
}>()

