import {injectable} from 'inversify'
import {makeAutoObservable, runInAction} from 'mobx'
import {ApiError, ApiResponse, ApiResponseError} from 'back-connector'
import {addListener, isStatusOk, listenPopapWindowClose, openErrorNotification} from '@/utils'
import {
    CLOSE_ERROR_CODE,
    fetchEcospendBanks,
    refundEcospendPayment,
    refundEcospendPOSPayment,
    EcospendBankModel,
    EcospendRefundResponseModel,
    EcospendReturnPageQueryModel
} from '@/stores/ecospend'
import {TEcospendStore} from '@/pages/components'
import {ProfileStoreInterface} from '@/pages/Profile'
import { TBasePaymentStore } from '@/types'

@injectable()
export class EcospendStore implements TEcospendStore {
    private readonly _profileStore: ProfileStoreInterface
    private readonly _paymentsStore: TBasePaymentStore

    constructor(
        profileStore: ProfileStoreInterface,
        paymentsStore: TBasePaymentStore,
        private paymentType: 'Online' | 'POS'
    ) {
        makeAutoObservable(this)
        this._profileStore = profileStore
        this._paymentsStore = paymentsStore

        addListener('storage', this.listener, window)
    }

    public popapWindow: Window = null
    public timerId: NodeJS.Timeout = null
    public refundFunction: () => Promise<any> = null
    private _resolveRefund: (value: any) => void = null

    public isEcospendModalVisible = false
    public isLoading = false
    public isPaymentProcessing = false
    public isCreatingOrder = false

    private _amount: number
    public refundResponse: EcospendRefundResponseModel = null
    public selectedBank: EcospendBankModel = null
    public banks: EcospendBankModel[] = []
    public searchText = ''
    public result: EcospendReturnPageQueryModel = null

    get amount() {
        return this._amount || this.currentPayment?.amount
    }

    public tryAgain = () => {
        this.back()
    }

    public closeEcospendModal = async () => {
        if (this.result) {
            const status = this.result.status
            this.isEcospendModalVisible = false
            this.clear()

            if (isStatusOk(status)) {
                this._resolveRefund({result: {id: this.currentPayment.id}})
                await this._paymentsStore?.onAfterPaymentAction(true)
            } else {
                this.rejectRefund({code: CLOSE_ERROR_CODE})
                await this._paymentsStore?.onAfterPaymentAction(false)
            }

        } else {
            this.rejectRefund({code: CLOSE_ERROR_CODE})
            await this._paymentsStore?.onAfterPaymentAction(false)
        }
    }

    public setSearchText(text: string) {
        this.searchText = text
    }

    public setSelectedBank(bank?: EcospendBankModel) {
        this.selectedBank = bank
    }

    public clear() {
        this.isCreatingOrder = false
        this.isPaymentProcessing = false
        this.selectedBank = null
        this.refundResponse = null
        this.searchText = ''
        this.result = null
    }

    public back() {
        this.clear()
    }

    public cancel() {
        this.closeEcospendModal()
    }

    public rejectRefund(error: ApiResponseError) {
        this.isEcospendModalVisible = false
        this.clear()
        this._resolveRefund({error: new ApiError(error)})
    }

    public refund(transactionId: string, amount = this.currentPayment?.amount) {
        this._amount = amount
        this.isEcospendModalVisible = true
        return new Promise<ApiResponse<any>>((res) => {
            this._resolveRefund = res
        })
    }

    public setResult(result: EcospendReturnPageQueryModel) {
        let {message} = result
        if (message) {
            message = message.replace(/\+/g, ' ')
        }
        this.result = {...result, message}
    }

    public async createOrder() {
        this.isCreatingOrder = true

        const {id, currency, invoiceId} = this.currentPayment
        const {result, error} = await (
            this.paymentType === 'Online'
                ? refundEcospendPayment(id, this.selectedBank.bank_id, this.amount)
                : refundEcospendPOSPayment(id, this.selectedBank.bank_id, this.amount, currency, invoiceId)
        )
        runInAction(() => {
            this.isCreatingOrder = false

            if (error || !result?.success) {
                const {code, message} = error || {}
                openErrorNotification(message)
                this.rejectRefund({code, message: message || 'translations().unknownError'})
                return
            }

            this.refundResponse = result
        })
    }

    public async loadBanks() {
        this.isLoading = true
        const {error, result} = await fetchEcospendBanks()
        this.isLoading = false

        if (error || !result) {
            openErrorNotification(new Error('Could not fetch bank list'))
            return
        }

        runInAction(() => {
            this.banks = result.data
        })
    }

    public openPopapWindow() {
        this.isPaymentProcessing = true
        const url = this.refundResponse.bankUrl
        const isDesktop = window.outerWidth > 768
        const width = isDesktop ? window.outerWidth * 0.8 : window.outerWidth
        const height = isDesktop ? window.outerHeight * 0.9 : window.outerHeight
        const top = Math.ceil((window.outerHeight - height) / 2)
        const left = Math.ceil((window.outerWidth - width) / 2)
        this.popapWindow = window.open(url, 'popap-window', `width=${width},height=${height},left=${left},top=${top}`)
        listenPopapWindowClose(this.popapWindow, this.onPopapWindowClose)
    }

    public onPopapWindowClose = () => {
        this.popapWindow = null
        this.isPaymentProcessing = false
    }

    public listener = (event: StorageEvent) => {
        let data: EcospendReturnPageQueryModel
        try {
            data = JSON.parse(event.newValue)
        } catch (exception) {
            return
        }

        if (event.key !== 'ecospend-return-data' || !data || data.payment_id !== this.refundResponse?.rrn) return

        this.setResult(data)
        localStorage.removeItem('ecospend-return-data')

        if (this.popapWindow) {
            this.popapWindow.close()
        }
    }

    get filteredBanks() {
        return this.banks.filter((bank) => bank.name.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) >= 0)
    }

    get payee() {
        return this._profileStore?.merchant?.name
    }

    get currentPayment() {
        const {selectedTransaction} = this._paymentsStore
        return selectedTransaction
    }
}
