import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    all: string
    selectAll: string
    deselectAll: string
    placeholder: string
    noData: string
    selected(count: number): string
}>()
