import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Select} from '@/components'
import {TSelectContainerStore} from '@/components/containers/SelectContainer/TSelectContainerStore'
import {SelectContainerProps} from './props'

export const SelectContainerStoreSymbol = Symbol('SelectContainerStore')

export const SelectContainer: React.FC<SelectContainerProps> = observer((
    {
        injectableIdentifier = SelectContainerStoreSymbol, className, defaultValue, placeholder,
        ...rest
    }
) => {
    const store = useInjection<TSelectContainerStore>(injectableIdentifier)

    return (
        <Select
            defaultValue={defaultValue}
            className={className}
            value={store.value}
            onChange={store.setValue}
            options={store.options}
            placeholder={placeholder}
            {...rest}
        />
    )
})
