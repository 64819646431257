import React, {FC} from 'react'
import classNames from 'classnames'
import {FileType, GoogleManagerTagClassNames} from '@/constants'
import {DownloadButtonsProps} from './props'
import {Button, Icon} from '@/components'

import styles from './styles.scss'
import {DOWNLOAD_ICON} from '@/constants/playwright-ids'

export const DownloadButtons: FC<DownloadButtonsProps> = ({
    className,
    menuItems,
    onMenuItemClick
}) => {
    const getIconType = (type: FileType): FileType =>
        type === FileType.PDF ? ('pdf_v2' as FileType) : type

    return (
        <div>
            {menuItems.map(({key}) => {
                const k = key as FileType

                return (
                    <Button
                        type='link'
                        key={k}
                        title={k}
                        className={classNames(
                            styles.button,
                            className,
                            GoogleManagerTagClassNames.settlements.downloadButtons(k)
                        )}
                        icon={<Icon style={{pointerEvents: 'none'}} type={getIconType(k)} />}
                        onClick={() => onMenuItemClick(k)}
                        data-test-id={DOWNLOAD_ICON + '_' + k}
                    ></Button>
                )
            })}
        </div>
    )
}
