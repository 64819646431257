import translations from './translations'

translations.add('ro', {
    title: 'Verificare necesară',
    continue: 'Continuare',
    resend: {
        question: `Nu ați primit un cod?`
    },
    description: (n, e) =>
        `Pentru a continua, introduceți codul de verificare cu ${n} cifre trimis la ${e}`
})
