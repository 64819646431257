import translations from './translations'

translations.add('es', {
    title: 'Exportar pagos en línea',
    columns: 'Columnas',
    cancel: 'Cancelar',
    ok: 'Exportar',
    all: (value) => `Todo (${value})`,
    custom: 'Personalizar'
})
