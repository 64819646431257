import translations from './translations'

translations.add('el', {
    id: 'ID',
    date: 'Ημερομηνία Έκδοσης',
    period: 'Περίοδος',
    merchantName: 'Έμπορος',
    amount: 'Ποσό',
    currency: 'Νόμισμα',
    status: 'Κατάσταση',
    invoices: 'Invoices',
    all: 'Ολα',
    paid: 'Πληρώθηκε',
    issued: 'Issued',
    action: 'Ενέργεια',
    download: 'Κατεβάστε'
})
