import React from 'react'
import {Carousel, Col, Layout, Row} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import {LoadingState} from '@/types'
import {TerminalType} from '@/constants'
import {
    TransactionsCardWithBarChartContainer,
    TransactionSummaryListContainer,
    TTransactionsCardWithBarChartContainerStore,
    UserGuideStoreSymbol
} from '@/components/containers'
import {AverageMetrics} from '@/pages/Overview/components/AverageMetrics'
import {CarouselWidgetItem} from '@/pages/Overview/components/CarouselWidgetItem'
import translations from '../../translations'
import styles from './styles.scss'
import {TUserGuideStore} from '@/components/containers/UserGuideContainer/TUserGuideStore'

export const OverviewTransactionSummaryStoreSymbol = Symbol('OverviewTransactionSummaryStore')
export const OverviewChargedTransactionsCardWithBarChartContainerStoreSymbol = Symbol(
    'OverviewChargedTransactionsCardWithBarChartContainerStore'
)
export const OverviewChargedTransactionsComparisonCardWithBarChartContainerStoreSymbol = Symbol(
    'OverviewChargedTransactionsComparisonCardWithBarChartContainerStore'
)

const OverviewOnlinePaymentsAnalytics: React.FC<{}> = observer(() => {
    const transactionStore = useInjection<TTransactionsCardWithBarChartContainerStore>(
        OverviewChargedTransactionsCardWithBarChartContainerStoreSymbol
    )
    const {setIsModalVisible} = useInjection<TUserGuideStore>(UserGuideStoreSymbol)
    return (
        <>
            <Layout.Content className={styles.body}>
                <Row gutter={[16, 16]}>
                    <Col span={24} xs={24}>
                        <TransactionSummaryListContainer
                            type={TerminalType.ECOM}
                            injectableIdentifier={OverviewTransactionSummaryStoreSymbol}
                        />
                    </Col>
                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        <TransactionsCardWithBarChartContainer
                            title={translations().chargedTransactions}
                            injectableIdentifier={
                                OverviewChargedTransactionsCardWithBarChartContainerStoreSymbol
                            }
                        />
                    </Col>
                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        <AverageMetrics
                            isLoading={transactionStore.dataLoadingState === LoadingState.LOADING}
                            transactionValue={transactionStore.transactionValue}
                            dailyValue={transactionStore.dailyValue}
                            transactionsPerDay={transactionStore.transactionsPerDay}
                            currency={transactionStore.currency}
                        />
                    </Col>
                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        <TransactionsCardWithBarChartContainer
                            title={translations().chargedTransactionsComparison}
                            injectableIdentifier={
                                OverviewChargedTransactionsComparisonCardWithBarChartContainerStoreSymbol
                            }
                        />
                    </Col>
                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        <Carousel autoplay>
                            <CarouselWidgetItem
                                title={translations().productGuides}
                                subtitle={translations().merchantPortal}
                                description={translations().toHelpYouGetStarted}
                                linkText={translations().merchantPortalGuide}
                                href={'https://dnapayments.com/support/guides'}
                                backgroundColor={'yellow'}
                            />
                            <CarouselWidgetItem
                                title={translations().whereToStart}
                                subtitle={translations().merchantPortal}
                                description={translations().onlinePaymentSystem}
                                linkText={translations().readMore}
                                onLinkClick={() => setIsModalVisible(true)}
                                backgroundColor={'blue'}
                            />
                        </Carousel>
                    </Col>
                </Row>
            </Layout.Content>
        </>
    )
})

export default OverviewOnlinePaymentsAnalytics
