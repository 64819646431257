import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {
    TransactionsCardWithBarChart,
    TransactionsCardWithBarChartProps
} from '@/components/composite'
import {TTransactionsCardWithBarChartContainerStore} from '@/components/containers/TransactionsCardWithBarChartContainer/TTransactionsCardWithBarChartContainerStore'
import {THasIoCIdentifier} from '@/types'

export type TransactionsCardWithBarChartContainerProps = TransactionsCardWithBarChartProps &
    THasIoCIdentifier
export const TransactionsCardWithBarChartContainerStoreSymbol = Symbol(
    'TransactionsCardWithBarChartContainerStore'
)

export const TransactionsCardWithBarChartContainer = observer(
    (props: TransactionsCardWithBarChartContainerProps) => {
        const {injectableIdentifier, ...restOfTheProps} = props
        const store = useInjection<TTransactionsCardWithBarChartContainerStore>(
            injectableIdentifier || TransactionsCardWithBarChartContainerStoreSymbol
        )

        return (
            <TransactionsCardWithBarChart
                data={store.data.data}
                dataLoadingState={store.dataLoadingState}
                dataTypes={store.dataTypes}
                onDataTypeChange={store.setSelectedDataType}
                selectedStatus={store.selectedStatus}
                statuses={store.statuses}
                onStatusChange={store.setSelectedStatus}
                chartOptions={store.data.options}
                {...restOfTheProps}
            />
        )
    }
)
