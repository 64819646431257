import {UploadFile} from 'antd/es/upload/interface'
import {Status} from '@/types/activation/Status'
import {Step} from '@/types/activation/Step'
import activationTranslations from '@/pages/Activation/translations'
import reportsTranslations from '@/pages/Reports/translations'
import payByLinkTranslations from '@/pages/PayByLink/translations'
import {
    Colors,
    DEFAULT_PAGE_SIZE,
    EXTRA_LARGE_PAGE_SIZE,
    EXTRA_SMALL_PAGE_SIZE,
    LARGE_PAGE_SIZE,
    PayByLinkStatus,
    SMALL_PAGE_SIZE
} from '@/constants'
import {DoughnutTabType} from '@/pages/Reports/components'

export const emptyDoughnutChartData = {
    labels: [reportsTranslations().empty],
    total: 0,
    datasets: [
        {
            label: 'Empty',
            data: [1],
            backgroundColor: [Colors.LIGHT_GRAY]
        }
    ]
}

export const activationMock = [
    {
        key: '1',
        step: 'initialCheck' as Step,
        title: activationTranslations().initialCheck,
        description: activationTranslations().initialCheckDescription,
        tagText: activationTranslations().actionRequired,
        status: 'Complete' as Status,
        hint: activationTranslations().processTakesDays
    },
    {
        key: '2',
        step: 'documentsUpload' as Step,
        title: activationTranslations().documentsUpload,
        description: activationTranslations().documentsUploadDescription,
        tagText: activationTranslations().actionRequired,
        status: 'Review' as Status,
        hint: activationTranslations().processTakesDays
    },
    {
        key: '3',
        step: 'finalCheck' as Step,
        title: activationTranslations().finalCheck,
        description: activationTranslations().finalCheckDescription,
        tagText: activationTranslations().actionRequired,
        status: 'Action' as Status,
        hint: activationTranslations().processTakesDays
    },
    {
        key: '4',
        step: 'agreement' as Step,
        title: activationTranslations().agreement,
        disabled: true
    },
    {
        key: '5',
        step: 'activation' as Step,
        title: activationTranslations().activation,
        disabled: true
    }
]

export const mockDefaultUploadFiles: UploadFile[] = [
    {
        uid: '-1',
        name: 'xxx.png',
        status: 'done',
        url: 'https://www.baidu.com/xxx.png'
    },
    {
        uid: '0',
        name: 'xxx.png',
        status: 'uploading',
        url: 'https://www.baidu.com/xxx.png'
    }
]

export const amountCountTabs = [
    {
        title: reportsTranslations().amount,
        key: DoughnutTabType?.amount
    },
    {
        title: reportsTranslations().count,
        key: DoughnutTabType?.count
    }
]

export const payByLinkTabs = [
    {
        title: payByLinkTranslations().statuses.all,
        key: PayByLinkStatus.all
    },
    {
        title: payByLinkTranslations().statuses.active,
        key: PayByLinkStatus.active
    },
    {
        title: payByLinkTranslations().statuses.paid,
        key: PayByLinkStatus.paid
    },
    {
        title: payByLinkTranslations().statuses.attempted,
        key: PayByLinkStatus.attempted
    }
]

export const paginationOptions = [
    {
        value: EXTRA_SMALL_PAGE_SIZE,
        label: EXTRA_SMALL_PAGE_SIZE
    },
    {
        value: SMALL_PAGE_SIZE,
        label: SMALL_PAGE_SIZE
    },
    {
        value: DEFAULT_PAGE_SIZE,
        label: DEFAULT_PAGE_SIZE
    },
    {
        value: LARGE_PAGE_SIZE,
        label: LARGE_PAGE_SIZE
    },
    {
        value: EXTRA_LARGE_PAGE_SIZE,
        label: EXTRA_LARGE_PAGE_SIZE
    }
]
