import translations from './translations'

translations.add('en', {
    acceptPolicies:
        'By clicking this button I consent to sharing my data with PayPal for boarding purposes',
    setUpPayPalAccount: 'Setup PayPal Account',
    warningMessage:
        'Once the above button is clicked you will be taken to the PayPal site in a new browser tab.  Please follow the instructions to either create a new account or add your existing PayPal account.  Once the process is completed you will be returned to the this screen and the status will be updated.',
    permissionNotGranted:
        'Unable to detect PayPal permissions have been granted. Please check and retry the onboarding process.',
    errors: {
        terminalNotFound: 'No eligible e-commerce terminal found'
    }
})
