import translations from './translations'

translations.add('ro', {
    title: 'Anulați înregistrarea domeniului',
    pleaseEnterReasonWhyUnregistering: 'Vă rugăm să introduceți motivul pentru care anulați înregistrarea domeniilor selectate',
    unregister: 'Anulați înregistrarea',
    close: 'Închideți',
    errors: {
        reasonRequired: 'Motivul este obligatoriu'
    }
})
