import {useInjection} from 'dna-react-ioc'
import {
    ProcessNewPaymentStoreSymbol,
    TProcessNewPaymentModalStore
} from '@/pages/OnlinePayments/components/ProcessNewPaymentModal'

export * from './ProcessNewPaymentStore'
export * from './services'
export * from './models'

export const useProcessNewPaymentStore = (identifier: symbol = ProcessNewPaymentStoreSymbol) =>
    useInjection<TProcessNewPaymentModalStore>(identifier)
