import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    paymentView: string
    paymentLink: string
    date: string
    orderNumber: string
    customer: string
    transactionType: string
    cancel: string
    ok: string
}>()

