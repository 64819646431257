import translations from './translations'

translations.add('es', {
    linkDetails: 'Detalles del enlace de pago',
    copyToClipboard: 'Copiar al portapapeles',
    copied: 'Copiado',
    orderNumber: 'Número de orden:',
    amount: 'Cantidad',
    customer: 'Cliente',
    dateCreated: 'Fecha de creacion',
    expirationDate: 'Fecha de caducidad',
    viewedDate: 'Fecha de visualización',
    paidDate: 'Fecha de pago',
    cancelledDate: 'Fecha cancelada',
    attemptedDate: 'Fecha de intento',
    description: 'Descripción',
    createdBy: 'Creado por'
})
