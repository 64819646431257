import translations from './translations'

translations.add('sl', {
    welcome: 'Vitajte v DNA payments',
    merchantPortal: 'Merchant Portal',
    startTutorial: 'Začni výuku',
    description1:
        'Náš obchodnícky portál je výkonnou platformou, ktorá vám pomáha sledovať a riadiť váš workflow, transakcie, refundácie, sporné platby a vyrovnania a to všetko v reálnom čase. ',
    description2:
        'Odporúčame vám pozrieť si náš návod, aby ste zistili, ako naplno využiť vlastnosti a funkcie nášho obchodného portálu',
    skipTutorial: 'Preskoč tutorial',
    back: 'Späť',
    close: 'Zavri',
    next: 'Ďalej',
    open: 'Otvor toto dialógové okno',
    skip: 'Preskoč'
})
