import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    titles: {
        makeTransfer: string
        transferSummary
    }
    buttons: {
        edit: string
    }
    tabs: {
        general: string
        card: string
    }
}>()
