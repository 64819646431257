import translations from './translations'
import React from 'react'

translations.add('en', {
    title: 'Pay by Bank',
    description: <>
        DNA Payments are proud to offer merchants the ability for consumers to pay for goods and services direct from their bank account via the new Pay by Bank app service. Pay by Bank app is a new online checkout option that lets consumers pay using their bank app on their phone. It&apos;s designed to make online checkout easier, whilst using all the security of the supported banks.
        <br/><br/>
        Most importantly Pay by Bank app isn’t a separate app to download or service to sign-up for – it works through the bank app consumers use already.
        <br/><br/>
    </>
})
