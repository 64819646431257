import translations from './translations'
import React from 'react'

translations.add('es', {
    title: 'Pay by Bank',
    description: <>
        DNA Payments se enorgullece de ofrecer a los comerciantes la posibilidad de que los consumidores paguen bienes y servicios directamente desde su cuenta bancaria a través del nuevo servicio Pay by Bank app. Pay by Bank app es una nueva opción de pago en línea que permite a los consumidores pagar utilizando la aplicación de su banco en el teléfono. It&apos;s se ha diseñado para facilitar el pago en línea con toda la seguridad de los bancos compatibles.
        <br/><br/>
        Y lo que es más importante, la aplicación Pay by Bank no es una aplicación que haya que descargar por separado ni un servicio al que haya que suscribirse, sino que funciona a través de la aplicación bancaria que ya utilizan los consumidores.
        <br/><br/>
    </>
})
