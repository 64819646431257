import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import translations from './translations'


translations.add('en', {
    title: 'Export online payments',
    columns: 'Columns',
    cancel: 'Cancel',
    ok: 'Export',
    all: (value) => `All (${value})`,
    custom: 'Custom',
    successExportMessage: <span>
        Your download request has been queued for processing. Once the document is ready, it can be downloaded from the {' '}
        <Link to={ROUTES.exports}>Exports</Link>{' '}section.
    </span>
})
