import translations from './translations'

translations.add('en', {
    labels: {
        transactionType: 'Transaction type'
    },
    warnings: {
        verification: 'Account verification will initiate a 0 (zero) amount transaction to verify the card details.'
    },
    errors: {
        transactionType: 'transaction type is required'
    },
    placeholders: {
        transactionType: 'Transaction type'
    }
})
