import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {PaymentMethodDetails, TKlarnaPaymentMethodStore} from '@/pages/payment-methods'
import {PaymentMethodsKlarnaStoreSymbol} from '@/pages/payment-methods/PaymentMethods/PaymentMethods'
import {PaymentMethodType} from '@/constants'
import translations from './translations'

export const KlarnaPaymentMethod = observer(() => {
    const store = useInjection<TKlarnaPaymentMethodStore>(PaymentMethodsKlarnaStoreSymbol)

    return <PaymentMethodDetails
        title={translations().title}
        description={translations().description}
        paymentMethodType={PaymentMethodType.klarna}
        stores={store.stores}
    />
})
