import translations from './translations'

translations.add('sl', {
    showAll: 'Ukáž všetko',
    previousRangeData: 'Predchádzajúci',
    currentRangeData: 'Súčasný',
    statuses: {
        AUTH: 'Prebiehajúci',
        CHARGE: 'Naúčtovaný',
        REFUND: 'Vrátený',
        REJECT: 'Odmietnutý'
    },
    dataTypes: {
        amount: 'Množstvo',
        count: 'Číslo'
    },
    week: 'Týždeň'
})
