import type { BrowserHistory, Update } from 'history'
import { injectable } from 'inversify'
import { action, computed, makeObservable, observable } from 'mobx'
import 'reflect-metadata'

export const RouterStoreSymbol = Symbol('RouterStoreSymbol')

@injectable()
export class RouterStore {
    public history: BrowserHistory

    public state: Update

    constructor(history: BrowserHistory) {
        this.history = history
        this.state = {
            action: this.history.action,
            location: this.history.location
        }

        makeObservable(this, {
            state: observable,
            location: computed,
            setState: action.bound
        })

        this.push = history.push.bind(history)
        this.replace = history.replace.bind(history)
        this.go = history.go.bind(history)
        this.back = history.back.bind(history)
        this.forward = history.forward.bind(history)

        this.history.listen(this.setState)
    }

    public get location() {
        return this.state?.location
    }

    public setState(newState: Update) {
        this.state = {
            action: newState.action,
            location: { ...newState.location }
        }
    }

    public push: BrowserHistory['push']

    public replace: BrowserHistory['replace']

    public go: BrowserHistory['go']

    public back: BrowserHistory['back']

    public forward: BrowserHistory['forward']
}
