import React from 'react'
import {PaymentMethodLabelWithIcon, CardSchemeLabelWithIcon} from '@/components'
import { PaymentMethodType } from '@/constants'
import {PaymentMethodOrMaskedCardProps} from './props'
import rootTranslations from '@/translations'

export const PaymentMethodOrMaskedCard: React.FC<PaymentMethodOrMaskedCardProps> = (props) => {
    const {
        paymentMethod,
        cardScheme,
        cardMask
    } = props

    if (!paymentMethod) return <span>-</span>

    if (paymentMethod === PaymentMethodType.card) {
        if (!cardScheme || !cardMask) return <span>-</span>

        return (
            <CardSchemeLabelWithIcon
                cardScheme={cardScheme}
                title={cardMask}
            />
        )
    }

    const title =
        paymentMethod === PaymentMethodType.clicktopay
            ? cardMask
            : rootTranslations().constants.paymentMethodType[paymentMethod]

    return (
        <PaymentMethodLabelWithIcon
            paymentMethod={paymentMethod}
            title={title}
        />
    )
}
