import React from 'react'
import {Col, Divider, List as AntList, Modal, Row, Typography} from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {hasPermissions} from '@/stores/auth/services/utils'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {Switch} from '@/components'
import {PaymentMethodStoreSettingProps} from './props'
import translations from './translations'
import {SWITCH_TERMINAL} from '@/constants/playwright-ids'

const {Item} = AntList

export const PaymentMethodTerminalSetting: React.FC<PaymentMethodStoreSettingProps> = (
    {terminalId, checked, onSwitchChange, extra}
) => {
    const confirm = (checked: boolean) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: translations().areYouSureYouWantTo(checked),
            okText: translations().yes,
            cancelText: translations().no,
            onOk: () => onSwitchChange(terminalId, checked)
        })
    }

    return (
        <Item actions={[
            <Row key={'row'} gutter={[16, 16]} justify={'space-between'}>
                <Col>
                    <Typography.Text type={checked ? 'success' : 'danger'}>
                        {checked ? translations().active : translations().disabled}
                    </Typography.Text>
                </Col>
                {
                    hasPermissions([PermissionsMap.payment_methods.full]) &&
                    <>
                        <Col>
                            <Switch
                                data-test-id={SWITCH_TERMINAL}
                                key={'active'}
                                title={'Active'}
                                checked={checked}
                                onChange={confirm}
                            />
                            {
                                extra ? (
                                    <>
                                        <Divider type={'vertical'}/>
                                        {extra}
                                    </>
                                ) : null
                            }
                        </Col>
                    </>
                }
            </Row>
        ]}>
            {terminalId}
        </Item>
    )
}
