import React from 'react'
import translations from './translations'

translations.add('el', {
    title: 'Για να χρησιμοποιήσετε μια εφαρμογή πιστοποίησης, ακολουθήστε τα παρακάτω βήματα:',
    step1: (iOSUrl, androidUrl) => (
        <>
            Κατεβάστε μια εφαρμογή πιστοποίησης δύο παραγόντων όπως το Google Authenticator για{' '}
            <a href={androidUrl} target='_blank'>
                Android
            </a>{' '}
            και{' '}
            <a href={iOSUrl} target='_blank'>
                iOS
            </a>{' '}
            ή μια παρόμοια εφαρμογή πιστοποίησης
        </>
    ),
    step1Note:
        'Σημείωση: Ο Microsoft Authenticator δεν υποστηρίζει έναν λογαριασμό σε πολλαπλά συστήματα. Λάβετε υπόψη εναλλακτικές λύσεις για ευρύτερη συμβατότητα του συστήματος κατά την ενεργοποίηση της διπλής πιστοποίησης (2FA).',
    step2: (key, login) => (
        <>
            Σαρώστε τον κωδικό QR ή εισάγετε τη σύνδεση {login} και αυτόν τον κλειδί{' '}
            <code>{key}</code> στην εφαρμογή πιστοποίησης δύο παραγόντων σας. Διαστήματα και πεζά
            δεν έχουν σημασία
        </>
    ),
    step3: 'Μόλις σαρώσετε τον κωδικό QR ή εισαγάγετε το παραπάνω κλειδί, η εφαρμογή πιστοποίησης δύο παραγόντων σας θα σας παρέχει ένα μοναδικό κωδικό. Εισάγετε τον κωδικό στο πλαίσιο επιβεβαίωσης παρακάτω και κάντε κλικ στην υποβολή'
})
