import React from 'react'
import translations from './translations'

translations.add('ro', {
    title: 'Pentru a utiliza o aplicație de autentificare, urmați acești pași:',
    step1: (iOSUrl, androidUrl) => (
        <>
            Descărcați o aplicație de autentificare cu două factori, precum Google Authenticator
            pentru{' '}
            <a href={androidUrl} target='_blank'>
                Android
            </a>{' '}
            și{' '}
            <a href={iOSUrl} target='_blank'>
                iOS
            </a>{' '}
            sau o aplicație similară de autentificare
        </>
    ),
    step1Note:
        'Notă: Microsoft Authenticator nu suportă un cont pe mai multe sisteme. Luați în considerare soluții alternative pentru o compatibilitate mai largă a sistemului la activarea autentificării cu două factori (2FA).',
    step2: (key, login) => (
        <>
            Scanați codul QR sau introduceți loginul {login} și această cheie <code>{key}</code> în
            aplicația dvs. de autentificare cu două factori. Spațiile și majusculele nu contează
        </>
    ),
    step3: 'După ce ați scanat codul QR sau ați introdus cheia de mai sus, aplicația dvs. de autentificare cu două factori vă va furniza un cod unic. Introduceți codul în caseta de confirmare de mai jos și apăsați submit'
})
