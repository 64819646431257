import {register} from 'dna-react-ioc'
import {TPaymentMethodsStore} from '@/pages/payment-methods/PaymentMethods/TPaymentMethodsStore'
import {
    PaymentMethodsActivateTerminalSymbol,
    PaymentMethodsApplePayStoreSymbol,
    PaymentMethodsGooglePayStoreSymbol,
    PaymentMethodsKlarnaStoreSymbol,
    PaymentMethodsOpenBankingStoreSymbol,
    PaymentMethodsPayByBankAppStoreSymbol,
    PaymentMethodsPaypalStoreSymbol,
    PaymentMethodsStoreSymbol
} from '@/pages/payment-methods/PaymentMethods/PaymentMethods'
import {
    ApplePayPaymentMethodStore,
    GooglePayPaymentMethodStore,
    KlarnaPaymentMethodStore,
    OpenBankingPaymentMethodStore,
    PayByBankAppPaymentMethodStore,
    PaymentMethodsStore,
    PaypalPaymentMethodStore
} from '@/stores/payment-methods'
import {
    ApplePayDomainsStoreSymbol,
    TApplePayDomainsStore,
    TApplePayPaymentMethodStore,
    TGooglePayPaymentMethodStore,
    TKlarnaPaymentMethodStore,
    TOpenBankingPaymentMethodStore,
    TPayByBankAppPaymentMethodStore,
    IPaypalPaymentMethodStore
} from '@/pages/payment-methods'
import {ApplePayDomainsStore} from '@/stores/payment-methods/ApplePayDomainsStore/ApplePayDomainsStore'
import {ActivateTerminalStore} from '@/stores/payment-methods/ActivateTerminalStore'

export const paymentMethodsState = () => {
    register<TPaymentMethodsStore>(PaymentMethodsStoreSymbol)
        .to(PaymentMethodsStore)
        .inSingletonScope()
    register<ActivateTerminalStore>(PaymentMethodsActivateTerminalSymbol)
        .to(ActivateTerminalStore)
        .inSingletonScope()
    register<TApplePayPaymentMethodStore>(PaymentMethodsApplePayStoreSymbol)
        .to(ApplePayPaymentMethodStore)
        .inSingletonScope()
    register<TPayByBankAppPaymentMethodStore>(PaymentMethodsPayByBankAppStoreSymbol)
        .to(PayByBankAppPaymentMethodStore)
        .inSingletonScope()
    register<IPaypalPaymentMethodStore>(PaymentMethodsPaypalStoreSymbol)
        .to(PaypalPaymentMethodStore)
        .inSingletonScope()
    register<TOpenBankingPaymentMethodStore>(PaymentMethodsOpenBankingStoreSymbol)
        .to(OpenBankingPaymentMethodStore)
        .inSingletonScope()
    register<TGooglePayPaymentMethodStore>(PaymentMethodsGooglePayStoreSymbol)
        .to(GooglePayPaymentMethodStore)
        .inSingletonScope()
    register<TKlarnaPaymentMethodStore>(PaymentMethodsKlarnaStoreSymbol)
        .to(KlarnaPaymentMethodStore)
        .inSingletonScope()
    register<TApplePayDomainsStore>(ApplePayDomainsStoreSymbol)
        .to(ApplePayDomainsStore)
        .inSingletonScope()
}
