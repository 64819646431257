import React, {useState, useEffect} from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {Col, Row, Typography, Space} from 'antd'
import {useInjection} from 'dna-react-ioc'
import {useScreenType} from '@/hooks'
import {Select, Button} from '@/components'
import {TimeZoneStoreInterface} from '@/pages/Profile/components'

import translations from './translations'
import styles from './styles.scss'
import {SAVE_TIME_ZONE, SET_TIME_ZONE, TIME_ZONE} from '@/constants/playwright-ids'

export const TimeZoneStoreSymbol = Symbol('TimeZoneStoreSymbol')

export const TimeZone = observer(() => {
    const {isMobile, isTablet} = useScreenType()
    const [timeZone, setTimeZone] = useState<string>('')

    const {isLoading, timeZoneList, savedTimeZone, saveTimeZone} =
        useInjection<TimeZoneStoreInterface>(TimeZoneStoreSymbol)

    const isButtonsVisible: boolean = Boolean(timeZone) && timeZone !== savedTimeZone

    useEffect(() => {
        setTimeZone(savedTimeZone || '')
    }, [savedTimeZone])

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} md={24} lg={12}>
                <Typography.Title level={4}>{translations().title}</Typography.Title>
                <Typography.Text>{translations().text}</Typography.Text>
            </Col>

            <Col xs={24} md={24} lg={8} offset={isMobile || isTablet ? 0 : 4}>
                <Select
                    value={timeZone}
                    className={classNames(styles.select, {[styles.mobile]: isMobile || isTablet})}
                    options={timeZoneList}
                    showSearch={true}
                    onSelect={v => setTimeZone(v)}
                    data-test-id={TIME_ZONE}
                />

                {isButtonsVisible && (
                    <div className={styles.buttonsHolder}>
                        <Space>
                            <Button onClick={() => setTimeZone(savedTimeZone)} data-test-id={SET_TIME_ZONE}>
                                {translations().cancel}
                            </Button>

                            <Button
                                type={'primary'}
                                onClick={() => saveTimeZone(timeZone)}
                                disabled={isLoading}
                                data-test-id={SAVE_TIME_ZONE}
                            >
                                {translations().save}
                            </Button>
                        </Space>
                    </div>
                )}
            </Col>
        </Row>
    )
})
