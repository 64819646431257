import translations from './translations'

translations.add('ro', {
    title: 'Încasări',
    download: 'Descarcă extrasul',
    perDay: 'Pe zi',
    perTransaction: 'Pe tranzacție',
    description1:
        'Vă rugăm să rețineți că, ocazional, decontările dvs. se pot desfășura până la ora 17:00 în zilele lucrătoare, dar acestea vor fi depuse automat în contul dvs. bancar.',
    description2:
        'Rețineți că plățile POS American Express efectuate prin DNA Payments sunt decontate direct de American Express și nu vor apărea în extrasele dvs. de decontare. Aveți posibilitatea de a monitoriza aceste tranzacții în orice moment utilizând Portalul Comercianților.'
})
