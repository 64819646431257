import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    placeholder: string
    tags: {
        active: string
        switch: string
    }
}>()

