import React, {useState} from 'react'
import {Avatar, List} from 'antd'
import classNames from 'classnames'
import {CloseOutlined} from '@ant-design/icons'

import {useScreenType} from '@/hooks'
import {Button} from '@/components'
import {storage} from '@/services/storage'
import {GoogleManagerTagClassNames} from '@/constants'

import DNALogoIcon from '~/assets/img/logos/logo_icon.svg'

import translations from './translations'
import styles from './MobileAppStoresRedirect.scss'

export const MobileAppStoresRedirect = () => {
    const REDIRECT_APP_KEY = 'mobileAppStoresRedirectAdVisibility'
    const VISIBLE_FLAG = '1'

    const visibility = storage.get(REDIRECT_APP_KEY)

    const {isMobile, isTablet} = useScreenType()
    const [openPanel, setOpenPanel] = useState(visibility)

    if ((!isMobile && !isTablet) || openPanel === VISIBLE_FLAG) {
        return null
    }

    const handleRedirect = () => {
        const userAgent = navigator.userAgent

        if (/iPhone|iPad|iPod/i.test(userAgent)) {
            window.open(_APP_STORE_URL_, '_blank')
        }
        if (/Android/i.test(userAgent)) {
            window.open(_GOOGLE_PLAY_URL_, '_blank')
        }
    }

    const handleCloseIcon = () => {
        storage.set(REDIRECT_APP_KEY, VISIBLE_FLAG)
        setOpenPanel(VISIBLE_FLAG)
    }

    return (
        <div className={styles.root}>
            <span
                className={GoogleManagerTagClassNames.header.mobileAppStoreAdSliderCloseIcon}
                onClick={handleCloseIcon}
            >
                <CloseOutlined
                    className={classNames(
                        styles.closeButton,
                        GoogleManagerTagClassNames.header.mobileAppStoreAdSliderCloseIcon
                    )}
                />
            </span>

            <List.Item.Meta
                avatar={<Avatar size={'large'} shape={'square'} src={DNALogoIcon} />}
                title={translations().merchantPortal}
                description={translations().availableNow}
            />

            <Button
                onClick={handleRedirect}
                className={classNames(
                    styles.getButton,
                    GoogleManagerTagClassNames.header.redirectToMobileAppStoreGetButton
                )}
            >
                <span
                    className={GoogleManagerTagClassNames.header.redirectToMobileAppStoreGetButton}
                >
                    {translations().get}
                </span>
            </Button>
        </div>
    )
}
