import moment, {Moment} from 'moment'
import translations from '../TransactionsCardWithBarChartContainerStore/translations'
import {Interval} from '@/stores/reports/models/Interval'

export const generateInterval = ({from, to}: {from: Moment, to: Moment}): Interval => {
    const difference = moment.duration(to.diff(from)).asDays()
    if (difference <= 1) {
        return Interval.HOUR
    } else if (difference < 32) {
        return Interval.DAY
    } else if (difference < 61) {
        return Interval.WEEK
    } else if (difference < 366) {
        return Interval.MONTH
    } else {
        return Interval.YEAR
    }
}

export const generateChartTitleForRange = ({from, to}: {from: Moment, to: Moment}) => {
    //check the years
    if(from.get('year') === to.get('year')) {
        return `${from.format('MMM DD')} - ${to.format('MMM DD, YYYY')}`
    }

    //if the years are different
    return `${from.format('MMM DD, YYYY')} - ${to.format('MMM DD, YYYY')}`
}

export const getIntervalValue = ({interval, date}:{ interval: Interval, date: Moment }) => {
    switch (interval) {
        case Interval.HOUR: {
            return date.format('HH:mm')
        }
        case Interval.DAY: {
            return `${date.format('DD.MM')}`
        }
        case Interval.WEEK: {
            return `${translations().week} ${date.week()}`
        }
        case Interval.MONTH: {
            return date.format('MMM')
        }
        case Interval.YEAR: {
            return date.format('YYYY')
        }
    }
}
