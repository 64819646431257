import translations from './translations'

translations.add('sl', {
    title: 'Zúčtovanie, platba',
    download: 'Vyhlásenie o stiahnutí',
    perDay: 'Za deň',
    perTransaction: 'Na transakciu',
    description1:
        'Uvedomte si, prosím, že príležitostne môžu vaše Vysporiadania prebiehať do 17:00 v pracovných dňoch, ale budú automaticky vložené na váš bankový účet.',
    description2:
        'Pamätajte, že platby American Express POS uskutočnené prostredníctvom platieb DNA sú zúčtované priamo spoločnosťou American Express a nezobrazia sa vo vašich výpisoch o vysporiadaní. Tieto transakcie máte možnosť kedykoľvek sledovať pomocou obchodného portálu.'
})
