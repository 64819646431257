import {inject, injectable} from 'inversify'
import {makeAutoObservable} from 'mobx'

import {Step, TMakeTransferStore} from '@/pages/business-account/MakeTransfer'
import {MakeTransferRequest, TransferSummaryData} from './models'
import {makeTransfer} from '@/stores/business-account/make-transfer/services'
import {AccountInfoStoreSymbol} from '@/pages/business-account/AccountInfo/AccountInfo'
import {AccountInfoStoreInterface} from '@/pages/business-account/AccountInfo'
import {LoadingState} from '@/types'
import {openErrorNotification} from '@/utils'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import {AccountInfo} from '@/stores'
import {Currencies} from '@/constants'

@injectable()
export class MakeTransferStore implements TMakeTransferStore {
    private readonly _accountInfoStore: AccountInfoStoreInterface

    constructor(
        @inject(AccountInfoStoreSymbol) accountInfoStore: AccountInfoStoreInterface
    ) {
        makeAutoObservable(this)

        this._accountInfoStore = accountInfoStore
    }

    _preparedFormData: TransferSummaryData = null
    step: Step = 'fill'
    makeTransferLoadingState: LoadingState = LoadingState.IDLE

    get preparedFormData(): TransferSummaryData {
        return this._preparedFormData
    }

    get senderName(): string {
        return this._accountInfoStore.accountName
    }

    get accountInfo(): AccountInfo {
        return this._accountInfoStore.accountInfo
    }

    setValuesForTransfer = (values): void => {
        this._preparedFormData = values
    }

    onConfirmTransfer = async () => {
        this.makeTransferLoadingState = LoadingState.LOADING

        const requestData: Partial<MakeTransferRequest> = {
            amount: +this._preparedFormData.amount,
            reference: this._preparedFormData.reference,
            currency: Currencies.GBP,
            originOfFunds: '009',
            debtor: {
                accountNumber: this._accountInfoStore?.accountInfo?.accountNumber,
                balance: this._accountInfoStore?.accountInfo?.token,
                sortCode: this._accountInfoStore?.accountInfo?.sortCode,
                name: this._accountInfoStore?.accountName,
                iban: this._accountInfoStore?.accountInfo?.iban,
                externalIdentifier: this._accountInfoStore?.accountInfo?.externalIdentifier
            },
            creditor: {
                name: this._preparedFormData.receiverName,
                address: this._preparedFormData.receiverAddress,
                iban: this._preparedFormData.iban
            }
        }

        try {
            const {result, error} = await makeTransfer(requestData)

            if (error) {
                this.makeTransferLoadingState = LoadingState.FAILED
                this.setStep('fill')
                return openErrorNotification(error.message)
            } else {
                if (result.status === -3) {
                    this.makeTransferLoadingState = LoadingState.FAILED
                    this.setStep('fill')
                } else {
                    this.setStep('success')
                    this.makeTransferLoadingState = LoadingState.DONE
                }
            }
        } catch (error) {
            return openErrorNotification(error.message)
        }
    }

    setStep = (step: Step) => (this.step = step)

    seeInTransactionsClick = () => {
        this.setStep('fill')
        getRouterStore().push(ROUTES.businessAccount.accountInfo)
    }

    reset = () => {
        this.setStep('fill')
    }
}
