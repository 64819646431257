import React from 'react'
import {observer} from 'mobx-react'
import {Form, Input, Modal, Typography} from 'antd'
import {ModalFormFooter} from '@/components'
import {UnregisterReasonConfirmModalProps} from './props'
import translations from './translations'
import {REASON_INPUT_DOMAIN} from '@/constants/playwright-ids'

export const UnregisterReasonConfirmModal: React.FC<UnregisterReasonConfirmModalProps> = observer((
    {open, onOk, onCancel, confirmLoading}
) => {
    const [form] = Form.useForm()

    const onFinish = async (values) => {
        await onOk(values)
        form.resetFields()
    }

    const onCancelClick = (e) => {
        form.setFieldsValue({
            reason: ''
        })
        onCancel(e)
    }

    return (
        <Modal
            title={translations().title}
            open={open}
            onCancel={onCancelClick}
            footer={<ModalFormFooter
                formId={'unregister-domain'}
                onCancel={onCancelClick}
                cancelText={translations().close}
                confirmLoading={confirmLoading}
                okText={translations().unregister}
            />}
        >
            <Typography.Text type={'secondary'}>
                {translations().pleaseEnterReasonWhyUnregistering}
            </Typography.Text>
            <br/><br/>
            <Form
                form={form}
                id={'unregister-domain'}
                layout={'vertical'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete='off'
            >
                <Form.Item
                    name={'reason'}
                    rules={[
                        { required: true, message: translations().errors.reasonRequired }
                    ]}
                >
                    <Input.TextArea
                        data-test-id={REASON_INPUT_DOMAIN}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
})
