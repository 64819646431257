import translations from './translations'

translations.add('sl', {
    title: 'Možnosti úhrady',
    buttons: {
        send: 'Odoslať',
        receipt: 'Potvrdenie',
        downloadReceipt: 'Stiahnuť potvrdenie'
    },
    labels: {
        email: 'E-mail zákazníka',
        emailPlaceholder: 'E-mailová adresa',
        date: 'Dátum',
        description: 'Popis objednávky',
        paymentMethod: 'Spôsob platby',
        orderNumber: 'Číslo objednávky'
    },
    errors: {
        emailInvalid: 'Neplatná e-mailová adresa',
        emailRequired: 'E-mailová adresa je povinná'
    }
})
