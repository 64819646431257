import React, {useEffect} from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Typography, Table} from 'antd'
import {TwoFADevicesStoreInterface} from '@/stores/profile/TwoFADevicesStore/TwoFADevicesStoreInterface'
import {getColumns} from './services'
import {PaginationContainer} from '@/components'

import translations from './translations'
import styles from './styles.scss'
import {DEVICE_TABLE} from '@/constants/playwright-ids'

type TwoFADevicesProps = {
    className?: string
    onChange?: (v: string) => void
}

export const TwoFADevicesStoreSymbol = Symbol('TwoFADevicesStoreSymbol')
export const TwoFADevicesPaginationContainerStoreSymbol = Symbol(
    'TwoFADevicesPaginationContainerStoreSymbol'
)

export const TwoFADevices: React.FC<TwoFADevicesProps> = observer(({className}) => {
    const {
        isLoading,
        devices,
        isOpen,
        paginationStore,
        twoFAModalStore,
        getDevices,
        deleteDevice
    } = useInjection<TwoFADevicesStoreInterface>(TwoFADevicesStoreSymbol)

    const {isTrustedDeviceSelected} = twoFAModalStore
    const {total} = paginationStore

    useEffect(() => {
        if (isTrustedDeviceSelected) {
            paginationStore.setPageNumber(1)
            getDevices()
        }
    }, [isTrustedDeviceSelected])

    if (!isOpen || total < 1) return null

    return (
        <div className={classNames(className)}>
            <Typography.Title level={5}>{translations().title}</Typography.Title>

            <Table
                className={styles.table}
                dataSource={devices}
                scroll={{x: 500}}
                columns={getColumns(deleteDevice)}
                loading={isLoading}
                pagination={false}
                rowKey={'id'}
                data-test-id={DEVICE_TABLE}
            />

            <div className={styles.pagination}>
                <PaginationContainer
                    hideSizeChanger
                    injectableIdentifier={TwoFADevicesPaginationContainerStoreSymbol}
                />
            </div>
        </div>
    )
})
