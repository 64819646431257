import {computed, makeObservable} from 'mobx'
import {injectable} from 'inversify'
import {ApiResponse} from 'back-connector'

import {convertStringToSelectItem, getWithAll} from '@/utils'
import {BaseDataStore} from '@/stores/shared/BaseDataStore'

import {fetchAccountNumbers} from '../api'

@injectable()
export class AccountNumbersDataStore extends BaseDataStore<string[]> {
    get selectItemsWithAll() {
        return getWithAll(convertStringToSelectItem(this.data), {addIfOneItem: true})
    }

    constructor() {
        super()

        makeObservable(this, {
            ...this.getAnnotationsMap(),
            selectItemsWithAll: computed
        })
    }

    protected async fetchData(): Promise<ApiResponse<string[]>> {
        return fetchAccountNumbers()
    }

    protected getDefaultData(): string[] {
        return []
    }
}
