import translations from './translations'
import React from 'react'

translations.add('el', {
    title: 'Open Banking',
    description: <>
        Το Open Banking είναι ένας ασφαλής τρόπος για να λαμβάνετε πληρωμές για αγαθά και υπηρεσίες απευθείας από τον τραπεζικό λογαριασμό του καταναλωτή.
        <br/><br/>
        Open Banking, οι καταναλωτές θα έχουν τη δυνατότητα να επιλέξουν τον τραπεζικό πάροχο (από πάνω από 50+ χρηματοπιστωτικά ιδρύματα σε μια λίστα που μεγαλώνει συνεχώς) πραγματοποιώντας την πληρωμή με λίγα εύκολα βήματα. Τα κεφάλαια χρεώνονται από τον λογαριασμό άμεσα βοηθώντας στη διαχείριση των διαθέσιμων υπολοίπων πιο αποτελεσματικά.
        <br/><br/>
        Η DNA Payments είναι περήφανη που συνεργάστηκε με τον κορυφαίο προμηθευτή υπηρεσιών Open Banking Ecospend για να καταστήσει αυτή τη διευκόλυνση διαθέσιμη στην πελατειακή της βάση.
        <br/><br/>
    </>
})
