import {isEmpty} from 'dna-common'
import {SelectItem} from '@/components/dumb/Select'
import {SelectItemWithTwoLabels} from '@/components/dumb/SelectWithTwoLabels/models'
import rootTranslations from '@/translations'

export const isFilterSelected = (value: any) => {
    return !isEmpty(value) && !isAllSelected(value)
}

export const getNotEmptyValues = <T extends {} = {}>(values: T) =>
    Object.entries(values)
        .filter(([_, value]) => !isEmpty(value) && !isAllSelected(value))
        .reduce((prev, [key, value]) => ({...prev, [key]: value}), {}) as T

export const getWithAll = (
    items: SelectItem[],
    options: {addIfOneItem?: boolean} = {}
): SelectItem[] => {
    if ((options.addIfOneItem && items.length >= 1) || items.length > 1) {
        return [{value: null, label: rootTranslations().filter.all}, ...items]
    }
    return items
}

export const getWithAllForTwoLabels = (
    items: SelectItemWithTwoLabels[],
    options: {addIfOneItem?: boolean} = {}
): SelectItemWithTwoLabels[] => {
    if ((options.addIfOneItem && items.length >= 1) || items.length > 1) {
        return [
            {
                value: null,
                labelPrimary: rootTranslations().filter.all,
                labelSecondary: rootTranslations().filter.all
            },
            ...items
        ]
    }
    return items
}

export const convertStringToSelectItem = (items: string[]): SelectItem[] =>
    items.map((item) => ({value: item, label: item}))

export const getUniqueValues = <T extends string | number>(values: T[]) =>
    Array.from(new Set(values))

export const isAllSelected = (value: unknown) =>
    value === null ||
    value === undefined ||
    value === '' ||
    ['all', 'all/all'].includes(String(value).toLocaleLowerCase())
