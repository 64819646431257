import React from 'react'
import {List} from 'antd'

import {Card} from '@/components'

import {MobileTableProps} from './props'
import styles from './styles.scss'

export const MobileTable = <T extends {}>(props: MobileTableProps<T>) => {
    const {
        dataSource = [],
        rowKey,
        renderItem,
        renderItemTitle,
        renderItemExtra,
        isLoading
    } = props

    return (
        <List
            loading={isLoading}
            itemLayout={ 'horizontal' }
            dataSource={dataSource}
            renderItem={(item, index) => {
                return (
                    <Card
                        key={item[rowKey] + index}
                        title={renderItemTitle(item)}
                        extra={renderItemExtra && renderItemExtra(item)}
                        className={styles.listItem}
                        data-test-id={`${rowKey}-${index}`}
                    >
                        {renderItem(item)}
                    </Card>
                )
            }}
        />
    )
}
