import translations from './translations'

translations.add('es', {
    shop: 'Tienda',
    shopName: 'Nombre de la tienda',
    orderNumber: 'Número de orden',
    description: 'Descripción',
    customerName: 'Nombre del cliente',
    postLink: 'Publicar enlace',
    customerId: 'ID de cuenta de cliente',
    email: 'Correo electrónico',
    rrn: 'RRN',
    receivalReferenceNumber: 'Número de referencia del recibo',
    amount: {
        label: 'Cantidad',
        from: 'de',
        to: 'a'
    },
    cardMask: {
        label: 'Tarjeta',
        first: 'Primeros 6 dígitos',
        last: 'Últimos 4'
    },
    cardScheme: 'Tipo de tarjeta',
    donationAmount: {
        label: 'Monto de donación',
        from: 'de',
        to: 'a'
    },
    paymentMethod: 'Método de pago',
    currency: 'Divisa'
})
