import {ChartOptions} from 'chart.js'
import {LayoutPosition} from 'chart.js/types/layout'
import {drawCtxCenterText, getCurrencySymbol} from '@/utils'
import {getFontFamilyName} from '@/utils/css-styles-utils'
import {Colors, Currencies} from '@/constants'

export const doughnutChartConfig = {
    doughnutOptions: (
        centerTitle: string,
        centerSubtitle: string | number,
        renderLegend
    ): object => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                }
            },
            cutout: '70%',
            radius: '100%',
            elements: {
                title: {
                    text: centerTitle,
                    color: Colors.GRAY,
                    fontStyle: getFontFamilyName(),
                    sidePadding: 20,
                    heightPadding: 0,
                    minFontSize: 12,
                    maxFontSize: 14,
                    lineHeight: 12,
                    fontSize: 12,
                    textAlign: 'center',
                    textBaseline: 'bottom'
                },
                subtitle: {
                    text: centerSubtitle,
                    color: Colors.PURPLE,
                    fontStyle: getFontFamilyName(),
                    sidePadding: 0,
                    heightPadding: 10,
                    minFontSize: 14,
                    maxFontSize: 18,
                    lineHeight: 14,
                    fontSize: 14,
                    textAlign: 'center',
                    textBaseline: 'top'
                }
            },
            legendCallback: renderLegend
        } as ChartOptions
    },
    getDoughnutPlugins: [
        {
            id: 'text',
            beforeDraw: function (chart) {
                if (chart.config?.options?.elements?.title?.text) {
                    drawCtxCenterText(chart, 'title')
                }
                if (chart.config?.options?.elements?.subtitle?.text) {
                    drawCtxCenterText(chart, 'subtitle')
                }
            }
        }
    ]
}

const getTooltipAmountWithCurrency = (currency: Currencies, amount: string | number) => {
    let res = ''

    if (currency) {
        res += getCurrencySymbol(currency)
    }
    if (amount !== null) {
        res += amount
    }
    return res
}

export const barChartConfig = {
    barCompareOptions: ({title = 'Custom Chart Title', renderTooltipTitle}): ChartOptions => {
        return {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    stacked: true
                },
                y: {
                    stacked: true
                }
            },
            plugins: {
                legend: {
                    position: 'bottom' as LayoutPosition,
                    labels: {
                        boxWidth: 8,
                        boxHeight: 8,
                        font: {
                            size: 10,
                            family: getFontFamilyName()
                        },
                        padding: 10,
                        color: Colors.PURPLE
                    },
                    align: 'center' as 'center' | 'end' | 'start'
                },
                title: {
                    position: 'top',
                    display: true,
                    text: title,
                    align: 'start',
                    font: {
                        size: 14,
                        family: getFontFamilyName()
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function (context: any) {
                            return getTooltipAmountWithCurrency(
                                context.dataset.currency as Currencies,
                                context.parsed.y
                            )
                        },
                        title: function (tooltipItem: any) {
                            if (renderTooltipTitle) {
                                return renderTooltipTitle(tooltipItem)
                            }
                            const [tooltip] = tooltipItem
                            return tooltip.label
                        }
                    }
                }
            }
        }
    },
    barOptions: ({title = 'Custom Chart Title', showTooltip = true, renderTooltipTitle = null}) => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: title,
                    align: 'start',
                    font: {
                        size: 14,
                        family: getFontFamilyName(),
                        color: Colors.PURPLE
                    }
                },
                tooltip: {
                    enabled: showTooltip,
                    callbacks: {
                        label: function (context) {
                            return getTooltipAmountWithCurrency(
                                context.dataset.currency as Currencies,
                                context.parsed.y
                            )
                        },
                        title: function (tooltipItem: any) {
                            if (renderTooltipTitle) {
                                return renderTooltipTitle(tooltipItem)
                            }
                            const [tooltip] = tooltipItem
                            return tooltip.label
                        }
                    }
                }
            }
        }
    },
    barMobileOptions: {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            }
        },
        scales: {
            x: {
                beginAtZero: true
            }
        }
    }
}
