import {register} from 'dna-react-ioc'
import {interfaces} from 'inversify'

import {getPortalPosStatuses} from '@/constants'

import {
    DrawerStore,
    ModalStore,
    PaginationStore,
    PeriodType,
    RangePickerStore,
    TabsContainerStore
} from '@/stores'

import {
    PaginationContainerStoreType,
    TRangePickerContainerStore,
    TSelectContainerStore,
    TTabsContainerStore
} from '@/components'

import {
    exportPOSTransactions,
    POSPaymentsStore,
    POSPaymentsStoreSymbol,
    POSPaymentsTabsStoreSymbol,
    POSPaymentsFilterStoreSymbol,
    POSPaymentsDrawerStoreSymbol,
    POSPaymentsExportsStoreSymbol,
    POSPaymentsStatusSelectStoreSymbol,
    POSPaymentsRangePickerStoreSymbol,
    POSPaymentsPaginationStoreSymbol,
    POSPaymentsOperationStoreSymbol,
    POSPaymentsOperationModalContainerStoreSymbol
} from '@/stores/transactions/pos'
import {POSPaymentsOperationStore} from '@/stores/transactions/pos/POSPaymentsOperationStore'

import {ProfileStoreInterface, ProfileStoreSymbol} from '@/pages/Profile'
import {IPOSPaymentsStore} from '~/code/pages/POSPayments'

import {TDrawerContainerStore} from '@/components/containers/DrawerContainer/TDrawerContainerStore'
import {StatusSelectContainerStore} from '@/stores/select/StatusSelectContainerStore'

import {IFilterContainerStore, TModalContainerStore} from '@/components/containers'
import {
    IExportsModalContainerStore,
    IPaymentOperationModalStore
} from '@/components/containers/transaction'

import {ExportsModalContainerStore, POSFiltersStore} from '@/stores/shared'

import {getExportFileName} from '@/utils/transactions'
import {getPOSPaymentTabs, getPOSExportColumns} from '@/utils/pos-transaction'

export const posPaymentsState = () => {
    register<IPOSPaymentsStore>(POSPaymentsStoreSymbol).to(POSPaymentsStore).inSingletonScope()

    register<TRangePickerContainerStore>(POSPaymentsRangePickerStoreSymbol)
        .toDynamicValue(() => new RangePickerStore({period: PeriodType.Day}))
        .inSingletonScope()

    register<TTabsContainerStore>(POSPaymentsTabsStoreSymbol)
        .toDynamicValue(() => new TabsContainerStore(getPOSPaymentTabs()))
        .inSingletonScope()

    register<TSelectContainerStore>(POSPaymentsStatusSelectStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new StatusSelectContainerStore(
                    context.container.get(POSPaymentsTabsStoreSymbol),
                    getPortalPosStatuses()
                )
        )
        .inSingletonScope()

    register<PaginationContainerStoreType>(POSPaymentsPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()

    register<TDrawerContainerStore>(POSPaymentsDrawerStoreSymbol).to(DrawerStore).inSingletonScope()

    register<IFilterContainerStore>(POSPaymentsFilterStoreSymbol)
        .toDynamicValue(() => new POSFiltersStore())
        .inSingletonScope()

    register<IExportsModalContainerStore>(POSPaymentsExportsStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new ExportsModalContainerStore(getPOSExportColumns(), (fields) => {
                    const profileStore: ProfileStoreInterface =
                        context.container.get(ProfileStoreSymbol)
                    const posPaymentsStore: IPOSPaymentsStore =
                        context.container.get(POSPaymentsStoreSymbol)

                    const format = 'csv'
                    const {startDate, endDate} = posPaymentsStore.rangePickerStore
                    const fileName = getExportFileName(
                        startDate,
                        endDate,
                        profileStore.merchant?.name,
                        format
                    )

                    return exportPOSTransactions({
                        ...posPaymentsStore.request,
                        format,
                        fields,
                        fileName
                    })
                })
        )
        .inSingletonScope()

    register<TModalContainerStore>(POSPaymentsOperationModalContainerStoreSymbol)
        .to(ModalStore)
        .inSingletonScope()

    register<IPaymentOperationModalStore>(POSPaymentsOperationStoreSymbol)
        .to(POSPaymentsOperationStore)
        .inSingletonScope()
}
