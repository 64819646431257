import translations from './translations'

translations.add('sl', {
    cookies: 'Preferencie súborov cookie',
    cookiesContent:
        'Na našej webovej stránke používame súbory cookie. Niektoré z nich sú nevyhnutné, zatiaľ čo iné nám pomáhajú zlepšovať túto webovú stránku a vaše skúsenosti.',
    descriptions: {
        name: 'Meno',
        provider: 'Provider',
        saveVisitorsPreferences: 'Ukladá preferencie návštevníkov zvolené v súbore cookie',
        cookieName: 'Názov súboru cookie',
        cookieExpiry: 'Platnosť súborov cookie',
        cookie: 'Cookie',
        ownerOfSite: 'Majiteľ tohto"Vlastník tejto webovej stránky',
        cookieByGoogleAdvanced:
            'Súbor cookie od spoločnosti Google používaný na riadenie pokročilého spracovania skriptov a udalostí',
        purpose: 'Purpose',
        year: '1 rok',
        googleTagManager: 'Správca značiek Google',
        googleLLC: 'Google LLC',
        twoYears: '2 roky',
        privacyPolicy: 'Zásady ochrany osobných údajov'
    }
})
