import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {Divider, Grid, Select as AntSelect, Typography} from 'antd'
import classNames from 'classnames'

import {toPascalCase} from '@/utils'
import {Icon} from '@/components/dumb/Icon'
import {withSelectWithTwoLabels} from '../SelectWithTwoLabels/withSelectWithTwoLabels'
import {SelectWithTitleProps} from './props'
import styles from './styles.scss'
import {SELECT_BY_TITLE_FILTER, SELECTED_BY_TITLE} from '@/constants/playwright-ids'

const {Option} = AntSelect
const {Text} = Typography

export const SelectWithTitle: React.FC<SelectWithTitleProps> = observer(
    ({
        value,
        options,
        onChange,
        className,
        defaultValue,
        placeholder,
        getDisplayValue,
        displayValue,
        isMobile: forceIsMobile,
        popupClassName,
        displayValueWidth
    }) => {
        const screens = Grid.useBreakpoint()
        const isMobile = forceIsMobile ?? screens.xs

        const selectedLabel = options?.find((option) => option.value === value)?.label || ''
        const displayLabel = getDisplayValue
            ? getDisplayValue(value)
            : selectedLabel || toPascalCase(value) || defaultValue
        const displayLabelStyle = displayValueWidth ? {width: displayValueWidth} : {}

        useEffect(() => {
            if (options?.length === 1) {
                onChange(options[0].value, options[0])
            }
        }, [onChange, options])

        return (
            <div
                className={classNames(
                    styles.selectWrapper,
                    {[styles.selectWrapperMobile]: isMobile},
                    className
                )}
            >
                <AntSelect
                    listHeight={300}
                    className={styles.select}
                    suffixIcon={<Icon type={'caret_down'} />}
                    value={isMobile ? value : displayValue}
                    placeholder={placeholder}
                    popupClassName={classNames(styles.dropdown, popupClassName, {[styles.dropdownMobile]: isMobile})}
                    onChange={onChange}
                    data-test-id={SELECT_BY_TITLE_FILTER}
                >
                    {options?.map((option) => (
                        <Option key={option.value} value={option.value} data-test-id={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </AntSelect>

                {!isMobile && (
                    <>
                        <Divider className={styles.divider} type={'vertical'} />
                        <Text className={styles.text} style={displayLabelStyle} data-test-id={SELECTED_BY_TITLE + displayLabel}>
                            {displayLabel}
                        </Text>
                    </>
                )}
            </div>
        )
    }
)

export const SelectWithTitleAndTwoLabel = withSelectWithTwoLabels(SelectWithTitle)
