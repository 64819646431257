import translations from './translations'

translations.add('ro', {
    date: 'Dată',
    orderNumber: 'Număr comandă',
    customer: 'Client',
    transactionType: 'Tip tranzacție',
    amount: 'Sumă',
    customerName: 'Nume client',
    name: 'Nume',
    description: 'Descriere',
    nameOfTheServiceOrItemYouProvide: 'Numele serviciului sau produsului furnizat',
    linkExpiration: 'Expirare link',
    stores: 'Magazine',
    theNewPaymentLinkIsCreatedAndCopiedToClipboard: 'Linkul de plată nou a fost creat și copiat în clipboard',
    copied: 'Text personal, dacă este necesar',
    errors: {
        storeRequired: 'Magazin necesar',
        orderNumberRequired: 'Numărul comenzii este obligatoriu',
        descriptionRequired: 'Descriere necesară',
        linkExpirationRequired: 'Expirarea link-ului este obligatorie',
        customerNameRequired: 'Nume client necesar'
    },
    ranges: {
        hour24: '24 de ore',
        hour48: '48 de ore',
        week1: '1 săptămână',
        month1: '1 lună'
    }
})
