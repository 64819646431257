import {Teammate} from '@/stores/team-management/models'
import {
    InviteEditTeammateFormModel,
    InviteTeammatePermissions,
    PermissionType
} from '@/pages/TeamManagement/components/InviteEditTeammate/models'
import {Role} from '@/stores/team-management/constants'
import {AccessType} from '@/pages/TeamManagement/components'

export const convertTeammateToFormModel = (teammate: Teammate): InviteEditTeammateFormModel => {
    // const permissions = teammate.permissions

    return {
        id: teammate.id,
        email: teammate.login,
        firstName: teammate.firstName,
        lastName: teammate.lastName,
        shopIds: teammate.shopIds,
        inviteAs: teammate.roles?.includes(Role.admin) ? AccessType.admin : AccessType.restricted,
        role: getRole(teammate.roles),
        permissions: convertPermissionsArrayIntoObject(teammate.permissions)
    }
}

export const getRole = (roles: Role[]) => {
    if (!roles) {
        return Role.custom
    }

    if (roles.includes(Role.admin)) {
        return Role.admin
    }

    if (roles.includes(Role.finance)) {
        return Role.finance
    }

    return Role.custom
}

export const convertPermissionsArrayIntoObject = (
    permissions: string[]
): InviteTeammatePermissions => {
    return {
        overview: identifyPermissionLevel('overview', permissions),
        online_payments: identifyPermissionLevel('online_payments', permissions),
        pos_payments: identifyPermissionLevel('pos_payments', permissions),
        pos_amex_payments: identifyPermissionLevel('pos_amex_payments', permissions),
        payment_links: identifyPermissionLevel('payment_links', permissions),
        virtual_terminal: identifyPermissionLevel('virtual_terminal', permissions),
        settlements: identifyPermissionLevel('settlements', permissions),
        invoices: identifyPermissionLevel('invoices', permissions),
        reports: identifyPermissionLevel('reports', permissions),
        payment_methods: identifyPermissionLevel('payment_methods', permissions),
        teammates: identifyPermissionLevel('teammates', permissions),
        chargebacks: identifyPermissionLevel('chargebacks', permissions)
    }
}

const identifyPermissionLevel = (sectionName: string, permissions: string[]): PermissionType => {
    if (!permissions || permissions.length === 0) {
        return 'no'
    }

    const filteredPermissions = permissions
        .filter((item) => item.startsWith(sectionName + '.'))
        .map((item) => item.replace(sectionName + '.', ''))

    if (filteredPermissions.includes('full')) {
        return 'full'
    } else if (filteredPermissions.includes('read')) {
        return 'read'
    }

    return 'no'
}
