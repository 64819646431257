import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    back: string
    title: string
    text: (email: string) => JSX.Element
    otp: string
    otpInvalid: string
    otpRequired: string
    submit: string
    confirmEmailDescription(onClick: () => void): JSX.Element
    waitingToResendVerificationCode(count: number): JSX.Element
}>()

