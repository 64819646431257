import translations from './translations'


translations.add('es', {
    amount: 'Importe',
    currency: 'Moneda',
    errors: {
        amountRequired: 'Importe requerido',
        amountInvalid: 'El importe debe ser superior a 0'
    }
})
