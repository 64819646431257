import {interfaces} from 'inversify'
import {register} from 'dna-react-ioc'
import {OnlineEcospendStoreSymbol, POSEcospendStoreSymbol, TEcospendStore} from '@/pages/components'
import {EcospendStore} from '@/stores/ecospend/EcospendStore'
import { ProfileStoreSymbol } from '@/pages/Profile/Profile'
import { OnlinePaymentsStoreSymbol } from '@/pages/OnlinePayments'
import { POSPaymentsStoreSymbol } from '@/stores/transactions/pos/symbols'

export const ecospendInitState = () => {

    register<TEcospendStore>(OnlineEcospendStoreSymbol).toDynamicValue((context: interfaces.Context) => (
        new EcospendStore(context.container.get(ProfileStoreSymbol), context.container.get(OnlinePaymentsStoreSymbol), 'Online')
    )).inSingletonScope()

    register<TEcospendStore>(POSEcospendStoreSymbol).toDynamicValue((context: interfaces.Context) => (
        new EcospendStore(context.container.get(ProfileStoreSymbol), context.container.get(POSPaymentsStoreSymbol), 'POS')
    )).inSingletonScope()

}
