import React from 'react'
import {Drawer, Grid} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import { MobileBackButton } from '@/components/dumb/MobileBackButton'
import {TDrawerContainerStore} from '@/components/containers/DrawerContainer/TDrawerContainerStore'
import {DrawerContainerProps} from './props'

export const DrawerContainerStoreSymbol = Symbol('DrawerContainerStore')

export const DrawerContainer: React.FC<DrawerContainerProps> = observer((
    {
        injectableIdentifier = DrawerContainerStoreSymbol, className, title,
        footer, children, open, placement = 'right', store: _store,
        width
    }
) => {
    const store = _store || useInjection<TDrawerContainerStore>(injectableIdentifier)

    const screens = Grid.useBreakpoint()
    const isMobile = screens.xs

    const drawerWidth = width ? width :
        isMobile ? '100vw' : '650'

    return (
        <Drawer
            title={<>
                {isMobile && <MobileBackButton onCancel={store.onClose}/>}
                {title}
            </>}
            placement={placement}
            className={className}
            closable={!isMobile}
            onClose={store.onClose}
            footer={footer}
            open={open || store.open}
            width={drawerWidth}
        >
            {children}
        </Drawer>
    )
})
