import {injectable} from 'inversify'
import {action, makeObservable, observable} from 'mobx'

@injectable()
export class PaymentLinkWidgetPreviewStore {
    constructor() {

        makeObservable(this, {
            type: observable,
            setType: action.bound
        })
    }

    public type: 'mobile' | 'laptop' = 'laptop'

    public setType(type) {
        this.type = type
    }
}
