import translations from './translations'

translations.add('sl', {
    title: 'Online platby',
    charge: 'Naúčtuj',
    refund: 'Vrátenie',
    paymentDetails: 'Platobné údaje',
    chargedAmount: 'Účtovaná suma',
    refundAmount: 'Výška vrátenia',
    balance: 'Zostatok',
    donationAmount: 'Výška daru',
    date: 'Dátum',
    status: 'Stav',
    summary: 'Zhrnutie',
    verifications: 'Overenia',
    channel: 'Kanál',
    shop: 'Obchod',
    search: 'Hľadaj',
    statement: 'Vyhlásenie',
    select: 'Vyber',
    items: 'Položky',
    description: 'Popis',
    customer: 'Zákazník',
    orderNumber: 'Číslo objednávky',
    amount: 'Množstvo',
    rnn: 'RNN',
    transactionType: 'Typ transakcie',
    authorizedOn: 'Povolené dňa',
    chargedOn: 'Naúčtované dňa',
    refundedOn: 'Vrátené dňa',
    issuer: 'Vydávajúci',
    card: 'Karta',
    is3DSecure: '3DS',
    cardExpiryDate: 'Dátum skončenia platnosti karty',
    acquirerResponseCode: 'Kód odpovede Acquirera',
    authCode: 'Autentifikačný kód',
    AVSHouseNumberResult: 'Číslo domu AVS',
    AVSPostcodeResult: 'PSČ AVS',
    yes: 'Áno',
    no: 'Nie',
    payerDetails: 'Údaje o zákazníkovi',
    payer: 'Meno',
    accountId: 'ID účtu',
    email: 'Email',
    phone: 'Telefón',
    resultMessage: 'Dôvod zamietnutia',
    geolocation: {
        payerIp: 'IP platiteľa',
        location: 'Umiestnenie'
    },
    paymentPage: {
        title: 'Platobná stránka',
        language: 'Jazyk',
        postLinkAddress: 'Adresa PostLink',
        postLink: 'PostLink',
        ok: 'Ok',
        failed: 'Zlyhalo'
    },
    signifydDetails: 'Signifyd Details',
    signifydId: 'Signifyd ID',
    decision: 'Rozhodnutie',
    reason: 'Zdôvodnenie',
    score: 'Skóre',
    id: 'ID',
    processingTypeName: 'Druh spracovania',
    reference: 'RRN',
    cancelOn: 'Zrušené dňa',
    avsResult: 'Výsledok AVS',
    payPal: {
        details: 'PayPal Podrobnosti',
        paypalOrderStatus: 'Stav objednávky',
        paypalPayerName: 'Meno platiteľa',
        paypalEmail: 'E-mail platiteľa',
        paypalPayerID: 'ID platiteľa',
        paypalPayerCountryCode: 'Kód krajiny platiteľa',
        paypalCaptureStatus: 'Stav zachytenia',
        paypalCaptureStatusReason: 'Dôvod zachytenia stavu',
        paypalSellerProtectionStatus: 'Štatút ochrany predajcu',
        paypalSellerProtectionDisputeCategories: 'Kategórie sporov týkajúcich sa ochrany predajcu'
    },
    searchFilter: {
        transactionId: 'ID transakcie',
        customerAccountId: 'ID zákazníckeho účtu',
        description: 'Popis',
        order: 'Objednávka #',
        email: {
            startsWith: 'E-mail sa začína na'
        },
        customerName: 'Meno zákazníka',
        card: {
            startsWith: 'Karta začína na',
            endsWith: 'Karta končí'
        }
    },
    searchFilterErrors: {
        transactionId: 'Zadajte platné ID'
    },
    createdDate: 'Created date (GMT)',
    payoutAmount: 'Confirmed amount',
    currency: 'Mena',
    payoutDate: 'Confirmed date (GMT)',
    processingType: 'Druh spracovania',
    invoiceId: 'Číslo objednávky',
    paymentMethod: 'Platobná metóda',
    cardType: 'Kartová schéma',
    cardMask: 'Maska karty',
    secure3D: '3DS',
    avsHouseNumberResult: 'Výsledok čísla domu AVS',
    avsPostcodeResult: 'Výsledok PSČ AVS',
    cscResult: 'Výsledok CSC',
    paEnrollment: 'Zápis do PA',
    paAuthentication: 'Autentifikácia PA',
    payerName: 'Meno zákazníka',
    payerEmail: 'Customer email',
    payerPhone: 'Customer phone',
    payerIp: 'Payer IP',
    ipCountry: 'IP country',
    ipCity: 'IP city',
    ipLatitude: 'IP latitude',
    ipLongitude: 'IP longitude',
    message: 'Správa',
    transactionTypeLower: 'Typ transakcie',
    initiatorEmail: 'Spustené pomocou',
    statuses: {
        'Matched': 'Zhoduje sa',
        'Not Matched': 'Nezhoduje sa',
        'Partial Match': 'Čiastočná zhoda',
        'Yes': 'Áno',
        'No': 'Nie',
        'Authenticated': 'Overené',
        'Not authenticated': 'Neoverené',
        'Unable to authenticate': 'Nemožno overiť',
        'Attempted': 'Pokúšal sa',
        'Not attempted': 'Nepokúšal sa',
        'Enrolled': 'Zapísaný',
        'Not enrolled': 'Nezapísaný',
        'Challenge required': 'Vyžaduje výzvu',
        'Not Provided': 'Nie je poskytnuté',
        'Not Checked': 'Neoverené',
        'Unable to check': 'Nemožno overiť',
        'Not Set': 'Nie je nastavené',
        'Authentication rejected': 'Overenie zamietnuté'
    }
})
