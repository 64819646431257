import {isEmpty} from 'dna-common'
import {injectable} from 'inversify'
import {action, computed, observable} from 'mobx'

import {FilterFormFieldType, LoadingState} from '@/types'
import {isFilterSelected} from '@/utils'
import {IFilterContainerStore} from '@/components/containers'

@injectable()
export abstract class BaseFilterContainerStore<T, TB = Partial<T>>
    implements IFilterContainerStore<T, TB>
{
    isDisabled = false
    open = false
    _filter = this.getInitialFilter()
    submittedFilter = this.getInitialFilter()

    get loadingState() {
        return LoadingState.IDLE
    }

    get isLoading() {
        return this.loadingState == LoadingState.LOADING
    }

    get isFilterButtonVisible() {
        return this.fields.filter((f) => !f.isHidden).length > 0
    }

    get count() {
        return Object.entries(this.submittedFilter).filter(([key, value]) =>
            this.isFilterSelected(key, value)
        ).length
    }

    protected getAnnotationsMap() {
        return {
            open: observable,
            _filter: observable,
            submittedFilter: observable,
            isDisabled: observable,

            isLoading: computed,
            isFilterButtonVisible: computed,
            filter: computed,
            fields: computed,

            submit: action.bound,
            clear: action.bound,
            setOpen: action.bound,
            setFilter: action.bound,
            setIsDisabled: action.bound
        }
    }

    isFilterSelected(field: string, value: any) {
        if (this.fields.find((f) => f.name === field)?.isHidden) {
            return false
        }

        /** typeof null is equal to 'object' */
        if (typeof value === 'object' && value !== null) {
            return isFilterSelected(value['value'])
        }
        return isFilterSelected(value)
    }

    getInitialFilter(): Partial<T> {
        return {}
    }

    abstract filter: Partial<T>

    abstract fields: FilterFormFieldType[]

    abstract getFilterForBackend(): TB

    setOpen(open: boolean) {
        this.open = open
    }

    setFilter(filter: Partial<T>) {
        if (!isEmpty(filter)) {
            this._filter = {
                ...this._filter,
                ...filter
            }
        }
    }

    setIsDisabled(isDisabled: boolean) {
        this.isDisabled = isDisabled
    }

    submit() {
        this.submittedFilter = this.filter
        this.setOpen(false)
    }

    clear() {
        this._filter = this.getInitialFilter()
    }
}
