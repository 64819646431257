import {makeAutoObservable, runInAction} from 'mobx'
import {inject, injectable} from 'inversify'

import { POSPaymentStatus, PaymentMethodType } from '@/constants'
import { openErrorNotification, openSuccessNotification } from '@/utils'
import {TModalContainerStore} from '@/components/containers'
import {POSEcospendStoreSymbol, TEcospendStore} from '@/pages/components'
import { refundPOSAlipay, refundPOSPayment } from './api'
import {
    POSPaymentsOperationModalContainerStoreSymbol,
    POSPaymentsStoreSymbol
} from './symbols'
import { IPaymentOperationModalStore } from '~/code/components/containers/transaction'

import translations from '@/translations'
import { IPOSPaymentsStore } from '~/code/pages/POSPayments'
import { getPOSPaymentMethod } from '~/code/utils/pos-transaction'

@injectable()
export class POSPaymentsOperationStore implements IPaymentOperationModalStore {
    private readonly _modalContainerStore: TModalContainerStore
    private readonly _posPaymentsStore: IPOSPaymentsStore
    private readonly _ecospendStore: TEcospendStore

    isLoading = false

    get modalContainerStore() {
        return this._modalContainerStore
    }

    get selectedPayment() {
        return this._posPaymentsStore.selectedTransaction
    }

    get isForRefund() {
        if (!this.selectedPayment) return null
        return this.selectedPayment.status === POSPaymentStatus.charged
    }

    constructor(
        @inject(POSPaymentsOperationModalContainerStoreSymbol) modalContainerStore: TModalContainerStore,
        @inject(POSPaymentsStoreSymbol) posPaymentsStore: IPOSPaymentsStore,
        @inject(POSEcospendStoreSymbol) ecospendStore: TEcospendStore
    ) {
        this._modalContainerStore = modalContainerStore
        this._posPaymentsStore = posPaymentsStore
        this._ecospendStore = ecospendStore

        makeAutoObservable(this)
    }

    public onFinishPayment = async (amount: number): Promise<void> => {
        const { currency, mid, terminalId, transactionDate, id } = this.selectedPayment
        const paymentMethod = getPOSPaymentMethod(this.selectedPayment)

        if (paymentMethod === PaymentMethodType.ecospend) {
            return this.openEcospendRefundModal(id, amount)
        }
        
        if (this.isLoading) {
            return
        }

        runInAction(() => this.isLoading = true)

        const showSuccessMessage = () => {
            const isPartial = this._posPaymentsStore.selectedTransaction.amount === amount
            openSuccessNotification(
                isPartial 
                    ? translations().success.paymentRefundedFullySuccessfully
                    : translations().success.paymentRefundedSuccessfully
            )
        }
        
        try {
            switch (paymentMethod) {
                case PaymentMethodType.alipay: {
                    const {result, error} = await refundPOSAlipay(id, { amount, currency })
                    if (result) {
                        if (result.status === 'Completed') {
                            showSuccessMessage()
                        } else {
                            openErrorNotification(result.message || translations().errors.general)
                        }
                        this.onPaymentModalCancel()
                    } else {
                        openErrorNotification(error?.message)
                    }
                    break
                }
                case PaymentMethodType.card: {
                    const {result, error} = await refundPOSPayment(id, { amount, mid, terminalId, transactionDate })
                    if (result) {
                        showSuccessMessage()
                        this.onPaymentModalCancel()
                    } else {
                        openErrorNotification(error?.message || translations().errors.general)
                    }
                    break
                }
            }
        } catch (error) {
            openErrorNotification(translations().errors.general)
        } finally {
            runInAction(() => this.isLoading = false)
        }
    }

    public openEcospendRefundModal = (id: string, amount: number) => {
        this._ecospendStore.refund(id, amount)
        this._ecospendStore.loadBanks()
        this.onPaymentModalCancel()
    }

    public onPaymentModalCancel = () => {
        this.modalContainerStore.onCancel()
    }
}
