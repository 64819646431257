import translations from './translations'

translations.add('ro', {
    buttons: {
        processNewPayment: 'Procesare plată nouă',
        cancelPayment: 'Anulare plată',
        charge: 'Încasare',
        refund: 'Restituire'
    },
    transactions: 'Tranzacții',
    transactionDetails: 'Detalii tranzacție',
    noDescription: 'Fără descriere',
    refundDisabledTooltip: "The Customer's bank does not support Open Banking refunds"
})
