import translations from './translations'

translations.add('ro', {
    title: 'Blocarea cardului',
    ohSeemsLikeSomethingBadHappened: '-  Oh, se pare că s-a întâmplat ceva rău cu cardul tău?',
    pleaseClarifyTheProblem: 'Te rog să clarifici problema',
    myCardWasStolen: 'Mi-a fost furat cardul meu',
    iveLostMyCard: 'Am pierdut cardul meu',
    cardIsDamaged: 'Cardul este deteriorat',
    cancel: 'Anulare',
    blockCard: 'Blocare card'
})
