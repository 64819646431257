import translations from './translations'

translations.add('sl', {
    title: 'Proces uvedenia',
    initialCheck: 'Počiatočná kontrola',
    initialCheckDescription: 'Otázky od manažéra na objasnenie podrobností o vašej firme',
    actionRequired: 'Vyžaduje sa akcia',
    processTakesDays: 'Proces trvá 1 deň',
    documentsUpload: 'Nahrávanie dokumentov',
    finalCheck: 'Záverečná kontrola',
    agreement: 'Dohoda',
    activation: 'Aktivácia',
    submit: 'Predložiť',
    cancel: 'Zruš',
    initialCheckSuccessMessage: 'Ďakujem! Teraz preverujeme informácie, ktoré ste poskytli.',
    thankYouMessage: 'Ďakujem! Teraz preverujeme informácie, ktoré ste poskytli.',
    documentsUploadDescription:
        'Please upload your supporting documents: proof of ID, proof of address and a bank statement',
    finalCheckDescription: 'Please provide additional information',
    back: 'Späť'
})
