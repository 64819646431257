import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    yes: string
    no: string
    delete: string
    tablet: string
    mobile: string
    desktop: string
    bot: string
    columns: {
        device: string
        addedOn: string
        lastLogin: string
    }
    messages: {
        confirm: string
    }
}>()
