import translations from './translations'

translations.add('ro', {
    title: 'Adăugați un nou domeniu',
    provideDomainWhereTheFile: 'Furnizați domeniul unde fișierul va fi găzduit',
    inputTheTopLevelDomain: 'Introduceți domeniul de nivel superior (de exemplu, dnapayments.com) sau sub-domeniul\n' +
     '(de exemplu, pay.dnapayments.com) pentru care doriți să activați Apple Pay.',
    downloadDomainAssociationFile: 'Descărcați fișierul de asociere a domeniului',
    hostDownloadedDomainAssociationFile: 'Găzduiți fișierul de asociere a domeniului descărcat',
    youNeedToHostVerificationFileYouDownloaded: 'Va trebui să găzduiți fișierul de verificare pe care l-ați descărcat mai sus la domeniul dvs. în următoarea locație:',
    cancel: 'Anulați',
    add: 'Adăugați',
    errors: {
        domainRequired: 'Domeniul este obligatoriu'
    }
})
