import translations from './translations'

translations.add('el', {
    bankingDate: 'Ημερομηνία Τραπεζικής Συναλλαγής',
    amount: 'Ποσό',
    currency: 'Νόμισμα',
    captureMethod: 'Capture method',
    status: 'Κατάσταση',
    documentId: 'Αριθμός Εγγράφου',
    operation: 'Ενέργεια',
    transactionType: 'Τύπος Συναλλαγής',
    chargebackDate: 'Ημερομηνία Ανάκλησης',
    cardMask: 'Μάσκα κάρτας',
    cardScheme: 'Σχήμα κάρτας',
    terminalId: 'Ταυτότητα τερματικού',
    transactionId: 'Ταυτότητα συναλλαγής',
    reasonCode: 'Κωδικός Αιτίας',
    arn: 'ARN',
    previousDocumentId: 'Προηγούμενος Αριθμός Εγγράφου',
    chargebacksDetails: 'Λεπτομέρειες Ανακλήσεων',
    originalTransactionDetails: 'Λεπτομέρειες Πρωτότυπης Συναλλαγής',
    chargebackHistory: 'Ιστορικό Ανακλήσεων',
    chargebackStatus: 'Κατάσταση επιστροφής χρημάτων',
    financialStatus: 'Οικονομική κατάσταση'
})
