import {getWithAuth, patchWithAuth, putWithAuth, deleteWithAuth, postWithAuth} from '@/api'
import {
    Profile,
    AuthAppInfoModel,
    AuthSecretKeyModel,
    TwoFASettingsModel,
    TwoFAUserSettingsModel,
    RecoveryCodeInterface,
    TwoFAUserUpdateSettingsRequestModel,
    TwoFATrustedDeviceResponseModel
} from '@/stores/profile/models'

export const getProfile = () => getWithAuth<Profile>('/oppapi/profile')

export const getTwoFASettings = () => getWithAuth<TwoFASettingsModel>('/oauth/oauth2/2fa/settings')

export const getTwoFAUserSettings = () =>
    getWithAuth<TwoFAUserSettingsModel>('/oauth/oauth2/user/2fa/settings')

export const getAuthAppInfo = () => getWithAuth<AuthAppInfoModel>('/oauth/oauth2/2fa/auth/apps')

export const getAuthSecretKey = () =>
    getWithAuth<AuthSecretKeyModel>('/oauth/oauth2/user/2fa/auth/info')

export const getAuthQR = () => getWithAuth<string>('/oauth/oauth2/user/2fa/auth/qr')

export const getRecoveryCodes = () =>
    getWithAuth<RecoveryCodeInterface[]>('/oauth/oauth2/user/2fa/recovery-codes')

export const updateTwoFAUserSettings = (
    settings: TwoFAUserUpdateSettingsRequestModel,
    code: number
) =>
    putWithAuth<RecoveryCodeInterface>('/oauth/oauth2/user/2fa/settings', settings, [
        ['code', `${code}`]
    ])

export const updateProfileTimezone = (timezone: string) =>
    patchWithAuth('/oauth/oauth2/user/settings', {timezone})

export const deleteUserTrustedDevices = (id: string) =>
    deleteWithAuth(`/oauth/oauth2/user/2fa/device/${id}`)

export const getUserTrustedDevices = (page: number, size: number) =>
    getWithAuth<TwoFATrustedDeviceResponseModel>(
        `oauth/oauth2/user/2fa/devices?page=${page}&size=${size}`
    )

export const sendEmailOtp = () => postWithAuth('/oauth/oauth2/email/otp?type=twoFAEnable')

export const validateEmailOtp = (code: string) =>
    postWithAuth('/oauth/oauth2/email/validation', {code})
