import React from 'react'
import translations from './translations'

translations.add('en', {
    selectBank: 'pick your bank',
    warnText: (
        <>
            To refund a payment made through Open Banking, follow these steps:
            <ol>
                <li>Choose your bank from the list below</li>
                <li>Log in to your bank account</li>
                <li>Process the refund from your bank account</li>
            </ol>
        </>
    )
})
