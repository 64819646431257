import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    charge: string
    refund: string
    cancel: string
    paymentCharge: string
    paymentRefund: string
    paymentAmount: string
    balance: string
    chargeAmount: string
    refundAmount: string
    pending: string
    errors: {
        positiveAmount: string
        amountRefundExceed: string
        amountChargeExceed: string
    }
}>()

