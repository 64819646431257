import translations from './translations'

translations.add('en', {
    titles: {
        makeTransfer: 'Make transfer',
        transferSummary: 'Transfer summary'
    },
    buttons: {
        edit: 'Payment details'
    },
    tabs: {
        general: '',
        card: ''
    }
})
