import translations from './translations'

translations.add('es', {
    bankingDate: 'Fecha de la Transacción Bancaria',
    amount: 'Cantidad',
    currency: 'Moneda',
    captureMethod: 'Capture method',
    status: 'Estado',
    documentId: 'ID de Documento',
    operation: 'Operación',
    transactionType: 'Tipo de Transacción',
    chargebackDate: 'Fecha de Devolución',
    cardMask: 'Máscara de tarjeta',
    cardScheme: 'Esquema de tarjeta',
    terminalId: 'ID del terminal',
    transactionId: 'ID de transacción',
    reasonCode: 'Código de Motivo',
    arn: 'ARN',
    previousDocumentId: 'ID de Documento Anterior',
    chargebacksDetails: 'Detalles de Devoluciones',
    originalTransactionDetails: 'Detalles de la Transacción Original',
    chargebackHistory: 'Historial de Devoluciones',
    chargebackStatus: 'Estado de contracargo',
    financialStatus: 'Estado financiero'
})
