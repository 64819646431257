import React from 'react'
import translations from './translations'
import {Typography} from 'antd'

translations.add('en', {
    back: 'Back to reset password',
    title: 'Confirm email',
    text: (email) => <>
        If we find an account associated with <Typography.Text strong>{email}</Typography.Text>, we will send a 6-digit verification code to it.
    </>,
    otp: 'Verification code',
    otpInvalid: 'Verification code is required',
    otpRequired: 'Verification code is invalid',
    submit: 'Confirm',
    confirmEmailDescription(onClick) {
        return <>If you didn&apos;t receive a verification code, please check "Spam" bin or try to <a onClick={onClick}>re-send</a></>
    },
    waitingToResendVerificationCode(count) {
        return <>If you didn&apos;t receive verification email please check "Spam" bin or try to <a className="disabled">re-send</a> in <span className="count">{count} seconds</span></>
    }
})
