import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    bankingDate: string
    amount: string
    currency: string
    captureMethod: string
    status: string
    documentId: string
    operation: string
    transactionType: string
    cardScheme: string
    cardMask: string
    terminalId: string
    transactionId: string
    chargebackDate: string
    reasonCode: string
    arn: string
    previousDocumentId: string
    chargebacksDetails: string
    originalTransactionDetails: string
    chargebackHistory: string
    chargebackStatus: string
    financialStatus: string
}>()
