import React from 'react'
import {Tooltip} from 'antd'
import {Icon} from '@/components'
import {IconTitleProps} from './props'
import styles from './styles.scss'
import classNames from 'classnames'

export const IconTitle: React.FC<IconTitleProps> = ({title, tooltipText, className, iconType = 'info'}) => {
    return (
        <div className={classNames(styles.titleWrapper, className)}>
            <div className={styles.title}>{ title }</div>
            <Tooltip
                title={tooltipText}
            >
                <Icon className={styles.icon} type={iconType} />
            </Tooltip>
        </div>
    )
}
