import translations from './translations'

translations.add('sl', {
    title: 'Platobné prepojenia',
    paymentDetails: 'Platobné údaje',
    chargedAmount: 'Účtovaná suma',
    expirationDate: 'Dátum vypršania platnosti',
    dateCreated: 'Dátum vytvorenia',
    summary: 'Zhrnutie',
    channel: 'Kanál',
    description: 'Popis',
    amount: 'Množstvo',
    status: 'Stav',
    customer: 'Zákazník',
    action: 'Akcia',
    statement: 'Vyhlásenie',
    statuses: {
        active: 'Aktívny',
        expired: 'Platnosť vypršala',
        paid: 'Zaplatené',
        cancelled: 'Zrušené',
        viewed: 'Zobrazené',
        attempted: 'Pokus vykonaný',
        all: 'Všetko'
    }
})
