import {inject, injectable} from 'inversify'
import {makeAutoObservable} from 'mobx'
import {SelectItem} from '@/components/dumb/Select'
import {ModalStore} from '@/stores'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol
} from '@/stores/store-and-terminals'
import translations from '@/pages/TeamManagement/translations'
import {TTeamManagementFilterStore} from '@/pages/TeamManagement/components/TeamManagementFilter'
import {Role, TeammateStatus} from '@/stores/team-management/constants'
import {TeamManagementFilterModel} from '@/pages/TeamManagement/components/TeamManagementFilter/models'
import {AuthMethods} from '@/stores/team-management/constants'

@injectable()
export class TeamManagementFiltersStore implements TTeamManagementFilterStore {
    private _merchantStoresStore: AvailableStoresDataStore
    private _popupStore = new ModalStore()

    constructor(
        @inject(AvailableStoresDataStoreSymbol) merchantStoresStore: AvailableStoresDataStore
    ) {
        this._merchantStoresStore = merchantStoresStore

        makeAutoObservable(this)
    }

    email = ''
    role: Role = Role.all
    firstName = ''
    lastName = ''
    store = ''
    status = null
    authMethod = null
    active = false
    filter = null
    _formValues: TeamManagementFilterModel = null

    get formValues(): TeamManagementFilterModel {
        return this._formValues
    }

    get count() {
        let _count = 0

        if (this._formValues?.email) {
            _count++
        }

        if (this._formValues?.firstName) {
            _count++
        }

        if (this._formValues?.lastName) {
            _count++
        }

        if (this._formValues?.store) {
            _count++
        }

        if (this._formValues?.status) {
            _count++
        }

        if (this._formValues?.authMethod) {
            _count++
        }

        return _count
    }

    get open(): boolean {
        return this._popupStore.open
    }

    get selectStores(): SelectItem[] {
        return this._merchantStoresStore.storesAsSelectItemsWithAll
    }

    get authMethods(): SelectItem[] {
        return [
            {label: translations().all, value: ''},
            ...Object.keys(AuthMethods).map((authMethod) => ({
                label: translations().authMethods[authMethod],
                value: AuthMethods[authMethod]
            }))
        ]
    }

    get selectStatuses(): SelectItem[] {
        return [
            {label: translations().all, value: ''},
            ...Object.keys(TeammateStatus)
                .filter((status) => status !== 'invited')
                .map((status) => ({
                    label: translations().statuses[status],
                    value: TeammateStatus[status]
                }))
        ]
    }

    setStore = (store: string) => {
        this.store = store
    }

    setEmail = (email: string) => {
        this.email = email
    }

    setFirstName = (firstName: string) => {
        this.firstName = firstName
    }

    setLastName = (lastName: string) => {
        this.lastName = lastName
    }

    setStatus = (status: TeammateStatus) => {
        this.status = status
    }

    setAuthMethod = (authMethod: AuthMethods) => {
        this.authMethod = authMethod
    }

    setActiveness = (active: boolean) => {
        this.active = active
    }

    onFilterClose = () => {
        this._popupStore.open = false
    }

    openFilter = () => {
        this._popupStore.open = true
    }

    setOpen = (open: boolean) => {
        this._popupStore.open = open
    }

    onFormValuesChange = (values: TeamManagementFilterModel): void => {
        this._formValues = values
    }

    onFinish = (values) => {
        this.setStore(values.store)
        this.setEmail(values.email)
        this.setFirstName(values.firstName)
        this.setLastName(values.lastName)
        this.setStatus(values.status)
        this.setAuthMethod(values.authMethod)
        this.setActiveness(values.active)
        this.filter = {
            email: this.email,
            store: this.store,
            firstName: this.firstName,
            lastName: this.lastName,
            status: this.status,
            authMethod: this.authMethod,
            active: this.active,
            role: this.role
        }
        this._popupStore.onCancel()
    }

    clearFilters = () => {
        this.setStore(null)
        this.setEmail(null)
        this.setFirstName(null)
        this.setLastName(null)
        this.setStatus(null)
        this.setAuthMethod(null)
        this.setActiveness(false)
        this.filter = null
        this.onFormValuesChange(null)
        this._popupStore.onCancel()
    }
}
