import translations from './translations'

translations.add('ro', {
    all: 'Toate',
    selectAll: 'Selectează tot',
    deselectAll: 'Deselectează tot',
    placeholder: 'Caută aici...',
    noData: 'Nici o informație',
    selected: (count) => `${count} selectate`
})

