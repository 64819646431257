import {injectable} from 'inversify'
import {action, computed, makeObservable, observable} from 'mobx'
import {subscribe} from 'dna-react-ioc'

import {TSelectContainerStore} from '@/components/containers/SelectContainer/TSelectContainerStore'
import {AvailableStoresDataStore} from './AvailableStoresDataStore'
import {AvailableStoresDataStoreSymbol} from '../symbols'

@injectable()
export class EcomStoreSelectStore implements TSelectContainerStore {
    protected _availableStoresDataStore: AvailableStoresDataStore

    value: string = null

    get options() {
        return this._availableStoresDataStore.ecomStoresAsSelectItemsWithAll
    }

    constructor() {
        this._availableStoresDataStore = subscribe<AvailableStoresDataStore>(
            AvailableStoresDataStoreSymbol
        )

        makeObservable(this, {
            value: observable,
            options: computed,
            setValue: action.bound
        })
    }

    clear() {
        this.value = null
    }

    setValue(value: string): void {
        this.value = value
    }

    setOptions(): void {
        // not needed, but required in interface
    }
}
