import {Moment} from 'moment-timezone'
import {generateChartTitleForRange} from '@/stores/reports/services/date-utils'

export const generateChartTitleForTwoRanges = (
    {previousRangeFrom, previousRangeTo, currentRangeFrom, currentRangeTo}:
    {previousRangeFrom: Moment, previousRangeTo: Moment, currentRangeFrom: Moment, currentRangeTo: Moment}) => {
    const previousRangeTitle = generateChartTitleForRange({
        from: previousRangeFrom, to: previousRangeTo
    })
    const currentRangeTitle = generateChartTitleForRange({
        from: currentRangeFrom, to: currentRangeTo
    })

    return `${previousRangeTitle} / ${currentRangeTitle}`
}
