import React from 'react'
import {observer} from 'mobx-react'
import {Button} from 'antd'
import {useTimer} from 'dna-common'

import translations from './translations'
import styles from './styles.scss'
import {RESEND} from '@/constants/playwright-ids'

export type Props = {
    onResend: () => void
}

export const ResendButton: React.FC<Props> = observer(({onResend}) => {
    const {start, count, isRunning} = useTimer(60)

    const onClick = async () => {
        await onResend()

        start()
    }

    return (
        <>
            <Button
                type='link'
                className={styles.button}
                onClick={onClick}
                disabled={isRunning}
                data-test-id={RESEND}
            >
                {translations().text}
            </Button>
            {translations().seconds(count)}.
        </>
    )
})
