import translations from './translations'
import React from 'react'

translations.add('ro', {
    title: 'Klarna',
    description: <>
        DNA Payments este mândru să colaboreze cu Klarna pentru furnizarea tranzacțiilor Cumpără acum, plătește mai târziu (BNPL). BNPL permite consumatorilor să împartă costul unei achiziții aplicabile fără a plăti dobânzi sau taxe suplimentare.
        <br/><br/>
        Odată activată această opțiune, consumatorii vor avea posibilitatea de a alege Klarna BNPL în timpul procesului de finalizare a achiziției, cu excepția cazului în care este dezactivată în configurarea integrării.
        <br/><br/>
    </>
})
