import React from 'react'
import translations from './translations'

translations.add('es', {
    hereWillBeTheList: 'Aquí estará la lista de sus dominios',
    textWithExplanationOfDomain: <>
        No tiene dominios verificados
        <br/>
        Para añadir sus dominios utilice el botón &quot;Add Domains&quot;
    </>,
    addNewDomain: 'Añadir nuevo dominio'
})
