export const RETRY_REFRESH_ACCESS_TOKEN_COUNT = 3
export const RETRY_REFRESH_ACCESS_TOKEN_TIMEOUT = 1000
export const REFRESH_ACCESS_TOKEN_BEFORE = 300

export const ACCESS_TOKEN_KEY = 'accessToken'
export const REFRESH_TOKEN_KEY = 'refreshToken'

export const MERCHANT_ID_KEY = 'merchantId'

export const VISITOR_ID_KEY = 'tid'
export const X_CLIENT_ID_KEY = 'DNA-Device-ID'

export const OTP_CODE_LENGTH = 6
export const OTP_CODE_RESEND_TIME_SEC = 60
