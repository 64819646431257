import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    id: string
    date: string
    period: string
    merchantName: string
    amount: string
    currency: string
    status: string
    invoices: string
    all: string
    paid: string
    issued: string
    action: string
    download: string
}>()

