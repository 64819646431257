import translations from './translations'

translations.add('es', {
    title: 'Asentamientos',
    download: 'Descargar declaración',
    perDay: 'Por día',
    perTransaction: 'Por transacción',
    description1:
        'Tenga en cuenta que, ocasionalmente, sus Acuerdos pueden estar vigentes hasta las 5:00 p. m. en días hábiles, pero se depositarán automáticamente en su cuenta bancaria.',
    description2:
        'Recuerde, los pagos POS de American Express realizados a través de DNA Payments son liquidados directamente por American Express y no aparecerán en sus extractos de liquidación. Tiene la capacidad de monitorear estas transacciones en cualquier momento utilizando el Portal del Comerciante.'
})
