import moment from 'moment'
import {debounce} from 'lodash'
import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction, when} from 'mobx'

import {LoadingState} from '@/types'
import {DEBOUNCE_MS, TableViewType, TABLE_STORAGE_KEYS, LARGE_PAGE_SIZE} from '@/constants'
import {openErrorNotification, tryParse} from '@/utils'
import {storage} from '@/services/storage'
import {AuthStoreInterface, AuthStoreSymbol, PeriodType} from '@/stores'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {
    PaginationContainerStoreType,
    TRangePickerContainerStore,
    TSelectContainerStore,
    TTabsContainerStore
} from '@/components'

import {IPOSPaymentsStore} from '~/code/pages/POSPayments'
import {ProfileStoreInterface, ProfileStoreSymbol} from '@/pages/Profile'
import {TDrawerContainerStore} from '@/components/containers/DrawerContainer/TDrawerContainerStore'

import {isFilterSelected} from '@/utils'
import {IFilterContainerStore} from '@/components/containers'
import {IExportsModalContainerStore} from '~/code/components/containers/transaction'
import {POSTransactionRequestType, POSTransactionType, POSFilterType} from '@/types/transactions'

import {getPOSTransactions} from './api'
import {
    POSPaymentsDrawerStoreSymbol,
    POSPaymentsExportsStoreSymbol,
    POSPaymentsFilterStoreSymbol,
    POSPaymentsPaginationStoreSymbol,
    POSPaymentsRangePickerStoreSymbol,
    POSPaymentsStatusSelectStoreSymbol,
    POSPaymentsTabsStoreSymbol
} from './symbols'

import rootTranslations from '@/translations'

type F = POSFilterType
type T = POSTransactionType

@injectable()
export class POSPaymentsStore implements IPOSPaymentsStore {
    private readonly _authStore: AuthStoreInterface
    private readonly _profileStore: ProfileStoreInterface

    readonly tabsStore: TTabsContainerStore
    readonly drawerStore: TDrawerContainerStore
    readonly filterStore: IFilterContainerStore<F>
    readonly statusSelectStore: TSelectContainerStore
    readonly rangePickerStore: TRangePickerContainerStore
    readonly paginationStore: PaginationContainerStoreType
    readonly exportsModalStore: IExportsModalContainerStore

    isInfinitePagination = true
    hasMoreData = true

    loadingState = LoadingState.IDLE
    viewType = TableViewType.EXPANDED
    selectedTransaction: T = null

    transactions: T[] = []

    get pageSize() {
        return this.isInfinitePagination ? LARGE_PAGE_SIZE : this.paginationStore.pageSize
    }

    get request(): POSTransactionRequestType {
        return {
            page: this.paginationStore.pageNumber,
            size: this.pageSize,
            from: this.rangePickerStore.startDate?.format(),
            to: this.rangePickerStore.endDate?.format(),
            ...(isFilterSelected(this.statusSelectStore.value)
                ? {status: this.statusSelectStore.value}
                : {}),
            ...this.filterStore.getFilterForBackend()
        }
    }

    get preferences() {
        return {
            pageSize: this.pageSize,
            from: this.rangePickerStore.startDate,
            to: this.rangePickerStore.endDate,
            status: this.statusSelectStore.value,
            filter: this.filterStore.submittedFilter
        }
    }

    get merchantId() {
        return this._profileStore?.merchant?.merchantId
    }

    get isExportsAvailable() {
        return hasPermissions([PermissionsMap.pos_payments.read, PermissionsMap.pos_payments.full])
    }

    constructor(
        @inject(AuthStoreSymbol) authStore: AuthStoreInterface,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface,
        @inject(POSPaymentsTabsStoreSymbol) tabsStore: TTabsContainerStore,
        @inject(POSPaymentsDrawerStoreSymbol) drawerStore: TDrawerContainerStore,
        @inject(POSPaymentsFilterStoreSymbol) filterStore: IFilterContainerStore<F>,
        @inject(POSPaymentsStatusSelectStoreSymbol) statusSelectStore: TSelectContainerStore,
        @inject(POSPaymentsExportsStoreSymbol) exportsModalStore: IExportsModalContainerStore,
        @inject(POSPaymentsRangePickerStoreSymbol) rangePickerStore: TRangePickerContainerStore,
        @inject(POSPaymentsPaginationStoreSymbol) paginationStore: PaginationContainerStoreType
    ) {
        this._authStore = authStore
        this._profileStore = profileStore

        this.tabsStore = tabsStore
        this.filterStore = filterStore
        this.drawerStore = drawerStore
        this.paginationStore = paginationStore
        this.rangePickerStore = rangePickerStore
        this.statusSelectStore = statusSelectStore
        this.exportsModalStore = exportsModalStore

        makeObservable(this, {
            loadingState: observable,
            viewType: observable,
            transactions: observable,
            selectedTransaction: observable,

            request: computed,
            merchantId: computed,
            preferences: computed,

            setViewType: action.bound,
            setSelectedTransaction: action.bound,
            loadTransactions: action.bound
        })

        /** reaction:preferences */
        reaction(
            () => this.preferences,
            debounce(() => {
                if (this.paginationStore.pageNumber === 1) {
                    this.loadTransactions()
                } else {
                    this.paginationStore.setPageNumber(1)
                }
            }, DEBOUNCE_MS)
        )

        reaction(
            () => this.paginationStore.pageNumber,
            () => this.loadTransactions()
        )

        when(
            () => Boolean(this.merchantId) && filterStore.loadingState === LoadingState.DONE,
            () => this.initializePreferences()
        )
    }

    private initializePreferences = () => {
        const profileEmail = storage.get('email')
        const storageKey = `${TABLE_STORAGE_KEYS.posPayments}-${profileEmail}-${this.merchantId}`
        const value = storage.get(storageKey) && JSON.parse(storage.get(storageKey))

        if (value) {
            const {pageSize, period: p, from, to, status, filter} = value
            const period = p as PeriodType

            if (pageSize) {
                this.paginationStore.setPageSize(pageSize)
            }

            if (period && period !== PeriodType.Custom) {
                this.rangePickerStore.setPeriod(period)
            } else if (from && to) {
                this.rangePickerStore.setDates([moment(from), moment(to)])
            }

            if (status) {
                this.statusSelectStore.setValue(status)
            }

            if (filter) {
                this.filterStore.setFilter(filter)
                this.filterStore.submittedFilter = filter
            }
        } else {
            /**
             * We need to trigger the data loading process if there is no filter value in the local storage.
             * Because the 'reaction:preferences' in the constructor was not activated.
             */
            this.loadTransactions()
        }

        reaction(
            () => this.preferences,
            debounce(() => {
                if (profileEmail) {
                    const period = this.rangePickerStore.period
                    storage.set(storageKey, JSON.stringify({...this.preferences, period}))
                }
            }, DEBOUNCE_MS)
        )
    }

    reload = () => {
        if (this.isInfinitePagination && this.paginationStore.pageNumber !== 1) {
            this.paginationStore.setPageNumber(1)
        } else {
            this.loadTransactions()
        }
    }

    async loadTransactions() {
        if (!this._authStore.isAuthenticated || this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.loadingState = LoadingState.LOADING
        })

        try {
            const {result, error} = await getPOSTransactions(this.request)

            runInAction(() => {
                this.loadingState = LoadingState.DONE

                if (error) {
                    throw error
                }

                const transactions = result.data.map(
                    ({transactionId, returnCodeDescription, ...rest}) => ({
                        ...rest,
                        id: transactionId,
                        description: returnCodeDescription,
                        additionalInfo: rest.additionalInfo
                            ? tryParse(String(rest.additionalInfo))
                            : null
                    })
                )

                if (this.isInfinitePagination && this.paginationStore.pageNumber > 1) {
                    this.transactions = this.transactions.concat(transactions)
                } else {
                    this.transactions = transactions
                }

                this.hasMoreData = transactions.length === this.pageSize

                this.paginationStore.setTotal(result.totalCount)
            })
        } catch (error) {
            runInAction(() => {
                this.loadingState = LoadingState.FAILED
                this.transactions = []
                this.hasMoreData = false
                this.paginationStore.setTotal(0)
            })

            return openErrorNotification(rootTranslations().errors.general)
        }
    }

    setViewType(viewType: TableViewType) {
        this.viewType = viewType
    }

    setSelectedTransaction(transaction: T) {
        this.selectedTransaction = transaction
    }
}
