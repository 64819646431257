import React from 'react'
import translations from './translations'
import {Typography} from 'antd'

translations.add('sl', {
    back: 'Späť na obnovenie hesla',
    title: 'Potvrdiť e-mail',
    text: email => (
        <>
            Ak nájdeme účet spojený s e-mailom <Typography.Text strong>{email}</Typography.Text>, we
            pošleme doň 6-miestny overovací kód.
        </>
    ),
    otp: 'Overovací kód',
    otpInvalid: 'Vyžaduje sa overovací kód',
    otpRequired: 'Overovací kód je neplatný',
    submit: 'Potvrdiť',
    confirmEmailDescription(onClick) {
        return (
            <>
                Ak ste nedostali overovací kód, skontrolujte kôš Spam alebo skúste ho{' '}
                <a onClick={onClick}>znova odoslať</a>
            </>
        )
    },
    waitingToResendVerificationCode(count) {
        return (
            <>
                Ak ste nedostali overovací e-mail, skontrolujte kôš "Spam" alebo skúste správu
                <a className='disabled'>znova odoslať</a> o{' '}
                <span className='count'>{count} sekúnd.</span>
            </>
        )
    }
})
