import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    labels: {
        transactionType: string
    }
    warnings: {
        verification: string
    }
    errors: {
        transactionType: string
    }
    placeholders: {
        transactionType: string
    }
}>()
