import React from 'react'
import {Tabs} from 'antd'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {LeftOutlined} from '@ant-design/icons'
import {PaymentMethodType} from '@/constants'
import {useScreenType} from '@/hooks'
import {ROUTES} from '@/router/routes'
import {getPaymentMethodDetailsRoute, withTitle} from '@/router/utils'
import {
    ApplePayPaymentMethod,
    PayByBankAppPaymentMethod,
    OpenBankingPaymentMethod,
    PaypalPaymentMethod,
    GooglePayPaymentMethod,
    KlarnaPaymentMethod
} from '@/pages/payment-methods'
import rootTranslations from '@/translations'
import translations from './translations'
import styles from './styles.scss'

const TabbedPaymentMethods: React.FC<object> = observer(() => {
    const {isMobile, isDesktop} = useScreenType()
    const {type} = useParams()
    const navigate = useNavigate()

    const setPaymentType = (activeKey) => {
        navigate(getPaymentMethodDetailsRoute(activeKey))
    }

    const renderMobilePaymentMethod = () => {
        switch (type) {
            case PaymentMethodType.paybybankapp:
                return <PayByBankAppPaymentMethod />
            case PaymentMethodType.ecospend:
                return <OpenBankingPaymentMethod />
            case PaymentMethodType.klarna:
                return <KlarnaPaymentMethod />
            case PaymentMethodType.applepay:
                return <ApplePayPaymentMethod />
            case PaymentMethodType.paypal:
                return <PaypalPaymentMethod />
            case PaymentMethodType.googlepay:
                return <GooglePayPaymentMethod />
            default:
                return null
        }
    }

    return (
        <>
            <br />
            <br />
            <Link className={styles.link} to={ROUTES.paymentMethods}>
                <LeftOutlined />
                {translations().allPaymentMethods}
            </Link>
            {!isMobile ? (
                <Tabs
                    activeKey={type}
                    onChange={setPaymentType}
                    className={classNames(styles.tabs, {[styles.tabsDesktop]: isDesktop})}
                    tabPosition={'right'}
                >
                    <Tabs.TabPane
                        className={styles.tabPane}
                        tab={rootTranslations().constants.paymentMethodType.applepay}
                        key={PaymentMethodType.applepay}
                    >
                        <ApplePayPaymentMethod />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        className={styles.tabPane}
                        tab={rootTranslations().constants.paymentMethodType.googlepay}
                        key={PaymentMethodType.googlepay}
                    >
                        <GooglePayPaymentMethod />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        className={styles.tabPane}
                        tab={rootTranslations().constants.paymentMethodType.paypal}
                        key={PaymentMethodType.paypal}
                    >
                        <PaypalPaymentMethod />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        className={styles.tabPane}
                        tab={rootTranslations().constants.paymentMethodType.paybybankapp}
                        key={PaymentMethodType.paybybankapp}
                    >
                        <PayByBankAppPaymentMethod />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        className={styles.tabPane}
                        tab={rootTranslations().constants.paymentMethodType.ecospend}
                        key={PaymentMethodType.ecospend}
                    >
                        <OpenBankingPaymentMethod />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        className={styles.tabPane}
                        tab={rootTranslations().constants.paymentMethodType.klarna}
                        key={PaymentMethodType.klarna}
                    >
                        <KlarnaPaymentMethod />
                    </Tabs.TabPane>
                </Tabs>
            ) : null}
            {isMobile ? renderMobilePaymentMethod() : null}
        </>
    )
})

export default withTitle(TabbedPaymentMethods)
