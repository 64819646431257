import {getWithAuth} from '@/api'
import {TJob} from '@/pages/Exports/models/TJob'
import {get, parseTextResult} from 'back-connector'
import saveAs from 'file-saver'
import {openErrorNotification} from '@/utils'

export const fetchJobs = (params: {jobName: string; page: number; size: number}) =>
    getWithAuth<{data: TJob[]; totalCount: number}>('/oppapi/v1/exports/jobs', params)

export const downloadFile = async (job: TJob) => {
    const response = await get(job.url, {}, [], {
        parseResponse: async res => {
            if (res.status !== 200) {
                const error = await parseTextResult(res)
                throw new Error(error.message)
            }
            const blobFile = await res.blob()
            return blobFile
        }
    })
    if (response.error) {
        return openErrorNotification(response.error)
    }

    const fileExtension = job.url.match(/\.[0-9a-z]+$/)[0]
    const fileName = job.fileName.replace(/\.[0-9a-z]+$/, fileExtension)

    saveAs.saveAs(await response.result, fileName)
}
