import React from 'react'
import {Divider, Spin} from 'antd'
import classNames from 'classnames'

import {RangePickerContainer, RefreshButton, SelectWithTitleContainer} from '@/components'
import {FilterContainer} from '@/components/containers'

import translations from '@/translations'
import {PaymentsTableFilterProps} from './props'
import styles from './styles.scss'

export const PaymentsFilterContainer: React.FC<PaymentsTableFilterProps> = ({
    formId,
    filterStore,
    statusSelectStore,
    rangePickerStore,
    loading,
    extra,
    className,
    onReload
}) => {
    return (
        <div className={classNames(styles.root, className)}>
            {onReload && (
                <Spin spinning={loading} indicator={null}>
                    <RefreshButton onClick={onReload} />
                </Spin>
            )}

            {filterStore && (
                <FilterContainer store={filterStore} formId={formId} isLoading={loading} />
            )}

            {statusSelectStore && (
                <div className={classNames(styles.statusSelect)}>
                    <Spin spinning={loading} indicator={null}>
                        <SelectWithTitleContainer
                            displayValue={translations().fields.status}
                            store={statusSelectStore}
                        />
                    </Spin>
                </div>
            )}

            {(rangePickerStore || extra) && <div className={styles.divider} />}

            {rangePickerStore && (
                <RangePickerContainer
                    showRangePickerTime
                    store={rangePickerStore}
                    isLoading={loading}
                />
            )}

            {extra && (
                <div className='nowrap'>
                    <Divider type={'vertical'} />
                    {extra}
                </div>
            )}
        </div>
    )
}
