import translations from './translations'

translations.add('sl', {
    calendarTitle: 'Vlastné',
    submit: 'Predložiť',
    period: 'Period',
    presets: {
        today: 'Dnes',
        yesterday: 'Včera',
        thisWeek: 'Tento týždeň',
        lastWeek: 'Minulý týždeň',
        last7Days: 'Posledných 7 dní',
        thisMonth: 'Tento mesiac',
        last30Days: 'Posledných 30 dní',
        last60Days: 'Posledných 60 dní',
        last90Days: 'Posledných 90 dní',
        lastMonth: 'Posledný mesiac',
        thisYear: 'Tento rok',
        last12Month: 'Posledných 12 mesiacov'
    }
})
