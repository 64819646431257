import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    showAll: string
    dataTypes: {
        amount: string
        count: string
    }
    week: string
    previousRangeData: string
    currentRangeData: string
}>()
