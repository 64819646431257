import translations from './translations'

translations.add('el', {
    calendarTitle: 'Τροποιποιημένο',
    submit: 'Υποβολή',
    period: 'Περίοδος',
    presets: {
        today: 'Σήμερα',
        yesterday: 'Εχθες',
        thisWeek: 'Αυτή την εβδομάδα',
        lastWeek: 'Την προηγούμενη εβδομάδα',
        last7Days: 'Τελευταίες 7 ημέρες',
        thisMonth: 'Αυτο το μηνα',
        last30Days: 'Τελευταίες 30 ημέρες',
        last60Days: 'Τελευταίες 60 ημέρες',
        last90Days: 'Τελευταίες 90 ημέρες',
        lastMonth: 'Τον προηγούμενο μήνα',
        thisYear: 'Αυτή την χρονιά',
        last12Month: 'Τελευταίοι 12 μήνες'
    }
})
