import translations from './translations'

translations.add('en', {
    title: 'Online payments',
    charge: 'Charge',
    refund: 'Refund',
    paymentDetails: 'Payment details',
    chargedAmount: 'Charged amount',
    refundAmount: 'Refund amount',
    balance: 'Balance',
    donationAmount: 'Donation amount',
    date: 'Date',
    status: 'Status',
    summary: 'Summary',
    verifications: 'Verifications',
    channel: 'Channel',
    shop: 'Store',
    search: 'Search',
    statement: 'Statement',
    select: 'Select',
    items: 'Items',
    description: 'Description',
    customer: 'Customer',
    orderNumber: 'Order number',
    amount: 'Amount',
    rnn: 'RNN',
    transactionType: 'Transaction type',
    authorizedOn: 'Authorised on',
    chargedOn: 'Charged on',
    refundedOn: 'Refunded On',
    issuer: 'Issuer',
    card: 'Card',
    is3DSecure: '3DS',
    cardExpiryDate: 'Card expiry date',
    acquirerResponseCode: 'Acquirer response code',
    authCode: 'Auth code',
    AVSHouseNumberResult: 'AVS house number',
    AVSPostcodeResult: 'AVS postcode',
    yes: 'Yes',
    no: 'No',
    payerDetails: 'Customer details',
    payer: 'Name',
    accountId: 'Account ID',
    email: 'Email',
    phone: 'Phone',
    resultMessage: 'Rejection reason',
    geolocation: {
        payerIp: 'Payer IP',
        location: 'Location'
    },
    paymentPage: {
        title: 'Payment page',
        language: 'Language',
        postLinkAddress: 'PostLink address',
        postLink: 'PostLink',
        ok: 'Ok',
        failed: 'Failed'
    },
    signifydDetails: 'Signifyd Details',
    signifydId: 'Signifyd ID',
    decision: 'Decision',
    reason: 'Reason',
    score: 'Score',
    id: 'ID',
    processingTypeName: 'Processing type',
    reference: 'RRN',
    cancelOn: 'Cancelled on',
    avsResult: 'AVS result',
    payPal: {
        details: 'Paypal details',
        paypalOrderStatus: 'Order status',
        paypalPayerName: 'Payer name',
        paypalEmail: 'Payer email',
        paypalPayerID: 'Payer ID',
        paypalPayerCountryCode: 'Payer country code',
        paypalCaptureStatus: 'Capture status',
        paypalCaptureStatusReason: 'Capture status reason',
        paypalSellerProtectionStatus: 'Seller protection status',
        paypalSellerProtectionDisputeCategories: 'Seller protection dispute categories'
    },
    searchFilter: {
        transactionId: 'Transaction ID',
        customerAccountId: 'Customer account ID',
        description: 'Description',
        order: 'Order #',
        email: {
            startsWith: 'Email starts with'
        },
        customerName: 'Customer name',
        card: {
            startsWith: 'Card starts with',
            endsWith: 'Card ends with'
        }
    },
    searchFilterErrors: {
        transactionId: 'Please enter a valid ID'
    },
    createdDate: 'Created date (GMT)',
    payoutAmount: 'Confirmed amount',
    currency: 'Currency',
    payoutDate: 'Confirmed date (GMT)',
    processingType: 'Processing type',
    invoiceId: 'Order number',
    paymentMethod: 'Payment method',
    cardType: 'Card scheme',
    cardMask: 'Card mask',
    secure3D: '3DS',
    avsHouseNumberResult: 'AVS house number result',
    avsPostcodeResult: 'AVS postcode result',
    cscResult: 'CSC result',
    paEnrollment: 'PA enrollment',
    paAuthentication: 'PA authentication',
    payerName: 'Customer name',
    payerEmail: 'Customer email',
    payerPhone: 'Customer phone',
    payerIp: 'Payer IP',
    ipCountry: 'IP country',
    ipCity: 'IP city',
    ipLatitude: 'IP latitude',
    ipLongitude: 'IP longitude',
    message: 'Message',
    transactionTypeLower: 'Transaction type',
    initiatorEmail: 'Initiated by',
    statuses: {
        'Matched': 'Matched',
        'Not Matched': 'Not matched',
        'Partial Match': 'Partial match',
        'Yes': 'Yes',
        'No': 'No',
        'Authenticated': 'Authenticated',
        'Not authenticated': 'Not authenticated',
        'Unable to authenticate': 'Unable to authenticate',
        'Attempted': 'Attempted',
        'Not attempted': 'Not attempted',
        'Enrolled': 'Enrolled',
        'Not enrolled': 'Not enrolled',
        'Challenge required': 'Challenge required',
        'Not Provided': 'Not provided',
        'Not Checked': 'Not checked',
        'Unable to check': 'Unable to check',
        'Not Set': 'Not set',
        'Authentication rejected': 'Authentication rejected'
    }
})
