import React from 'react'
import {ActionButtons, Status} from '@/pages/components'
import {Menu, Modal} from 'antd'
import {Teammate} from '@/stores/team-management/models'
import {TeamManagementStore} from '@/stores/team-management/TeamManagementStore'
import translations from '../translations'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {convertTeammateToFormModel} from '@/stores/team-management/services/processors'
import {TeammateStatus} from '@/stores/team-management/constants'
import {TagType} from '@/components/dumb/Tag/props'
import styles from './styles.scss'

const confirmDelete = (teammateId: string, teamManagementStore: TeamManagementStore) => {
    Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: translations().confirmations.areYouSureToDeleteTeammate,
        okText: translations().confirmations.yes,
        cancelText: translations().confirmations.no,
        onOk: () => teamManagementStore.deleteTeammate(teammateId)
    })
}

const confirmResendInvitation = (teammateId: string, teamManagementStore: TeamManagementStore) => {
    Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: translations().confirmations.areYouSureToResendInvitation,
        okText: translations().confirmations.yes,
        cancelText: translations().confirmations.no,
        onOk: () => teamManagementStore.resendInvitation(teammateId)
    })
}

export const renderActionsMenu = (teammate: Teammate, teamManagementStore: TeamManagementStore) => {
    switch (teammate.active) {
        case true:
            return <ActionButtons
                menu={(
                    <Menu
                        items={[
                            {
                                key: '1',
                                label: (
                                    <a onClick={() => teamManagementStore._editTeammateStore.openEditForm(convertTeammateToFormModel(teammate))}>
                                        {translations().actions.edit}
                                    </a>
                                )
                            },
                            {
                                key: '2',
                                label: (
                                    <a onClick={() => confirmDelete(teammate.id, teamManagementStore)}>
                                        {translations().actions.delete}
                                    </a>
                                )
                            }
                        ]}
                    />
                )}
            />
        default:
            return <ActionButtons
                menu={(
                    <Menu
                        items={[
                            {
                                key: '1',
                                label: (
                                    <a onClick={() => confirmResendInvitation(teammate.id, teamManagementStore)}>
                                        {translations().actions.resendInvitation}
                                    </a>
                                )
                            },
                            {
                                key: '2',
                                label: (
                                    <a onClick={() => confirmDelete(teammate.id, teamManagementStore)}>
                                        {translations().actions.delete}
                                    </a>
                                )
                            }
                        ]}
                    />
                )}
            />
    }
}

export const getTeammateStatusTag = (status: TeammateStatus) => {
    const props = {
        className: styles.status,
        iconClassName: styles.statusIcon,
        status: getTeammateStatusLabel(status)
    }

    switch (status) {
        case TeammateStatus.block:
            return <Status {...props} iconType={'close'} type={TagType.Error} />
        case TeammateStatus.invited:
            return <Status {...props} iconType={'clock'} type={TagType.Processing} />
        case TeammateStatus.ok:
            return <Status {...props} iconType={'tick'} type={TagType.Success} />
        case TeammateStatus.changePassword:
            return <Status {...props} iconType={'lock'} type={TagType.Default} />
        case TeammateStatus.validateEmail:
            return <Status {...props} iconType={'clock'} type={TagType.Processing} />
        default:
            return ''
    }
}

export const getTeammateStatusLabel = (status: TeammateStatus) => {
    switch (status) {
        case TeammateStatus.block:
            return translations().statuses.block
        case TeammateStatus.invited:
            return translations().statuses.invited
        case TeammateStatus.ok:
            return translations().statuses.ok
        case TeammateStatus.changePassword:
            return translations().statuses.changePassword
        case TeammateStatus.validateEmail:
            return translations().statuses.validateEmail
        default:
            return ''
    }
}
