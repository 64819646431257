import translations from './translations'
import React from 'react'

translations.add('el', {
    title: 'PayPal',
    description: (
        <>
            Η DNA Payments έχει συνεργαστεί με την PayPal με σκοπό να παρέχει στους εμπόρους μια
            εύκολη μέθοδο να προσθέσουν αυτόν τον δημοφιλή τρόπο πληρωμής για τους πελάτες τους. Οι
            έμποροι με υπάρχοντες λογαριασμούς PayPal μπορούν να τους συνδέσουν σε αυτήν τη σελίδα ή
            μπορεί εάν χρειάζεται να δημιουργηθεί ένας λογαριασμός PayPal.
            <br />
            <br />
            Μόλις συνδεθεί ένας λογαριασμός, η επιλογή πληρωμής PayPal μπορεί εύκολα να
            ενεργοποιηθεί ή να απενεργοποιηθεί χρησιμοποιώντας την σχετική επιλογή δίνοντας στον
            έμπορο τον πλήρη έλεγχο.
            <br />
            <br />
        </>
    ),
    paypalStatus: 'Κατάσταση Paypal',
    settings: 'Προσαρμογή ρυθμίσεων',
    yourAccountDisabled:
        'Η ρύθμιση του λογαριασμού PayPal σας είναι προς το παρόν απενεργοποιημένη. Για να αρχίσετε ξανά να δέχεστε πληρωμές μέσω PayPal, παρακαλώ ενεργοποιήστε τον.',
    consentRevokedText:
        'Οι συγκαταθέσεις για τη ρύθμιση του λογαριασμού PayPal έχουν ανακληθεί ή ο λογαριασμός εμπόρου PayPal είναι κλειστός.',
    readyPaymentText: 'Είστε έτοιμοι να δεχθείτε πληρωμές μέσω PayPal.'
})
