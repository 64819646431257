import React from 'react'
import {observer} from 'mobx-react'
import {Col, Divider, Row, Typography} from 'antd'
import {ShareButtons} from '@/pages/components'
import {CopyLink} from '@/components'
import translations from './translations'
import {LinkDetailsModalFooterProps} from './props'
import styles from './styles.scss'
import {PayByLinkStatus} from '@/constants'

const {Text} = Typography

export const LinkDetailsModalFooter: React.FC<LinkDetailsModalFooterProps> = observer((
    {onEmailBtnClick, onSmsBtnClick, url, status}
) => {
    const isShareShown = status === PayByLinkStatus.viewed || status === PayByLinkStatus.active || status === PayByLinkStatus.attempted
    return (
        <div className={styles.container}>
            <Row className={styles.row}>
                <Col className={styles.flex} xs={21}>
                    <Text className={styles.title}>{translations().copyLink}</Text>
                </Col>
                {isShareShown ? <Col className={styles.flex} xs={3}>
                    <Text className={styles.title}>{translations().shareVia}</Text>
                </Col> : null}
            </Row>
            <Row className={styles.row}>
                <Col xs={19}>
                    <CopyLink url={url}/>
                </Col>
                {isShareShown ? (
                    <>
                        <Col xs={2} className={styles.divider}>
                            <Divider type={'vertical'}/>
                        </Col>
                        <Col className={styles.flex} xs={3}>
                            <ShareButtons
                                onEmailClick={onEmailBtnClick}
                                onSmsClick={onSmsBtnClick}
                            />
                        </Col>
                    </>
                ) : null}
            </Row>
        </div>
    )
})
