import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import translations from './translations'


translations.add('el', {
    title: 'Εξαγωγή online πληρωμών',
    columns: 'Στήλες',
    cancel: 'Ακύρωση',
    ok: 'Εξαγωγή',
    all: (value) => `Όλα (${value})`,
    custom: 'Τροποιποιημένο',
    successExportMessage: <span>
        Το αίτημα λήψης σας έχει μπει σε ουρά για επεξεργασία. Μόλις το έγγραφο είναι έτοιμο, μπορείτε να το κατεβάσετε από την ενότητα {' '}
        <Link to={ROUTES.exports}>Εξαγωγές</Link>.
    </span>
})
