import React from 'react'
import {Typography} from 'antd'
import {PaymentMethodSettingPanelHeaderProps} from './props'
import styles from './styles.scss'
import {observer} from 'mobx-react'

const {Title, Text} = Typography

export const PaymentMethodSettingPanelHeader: React.FC<PaymentMethodSettingPanelHeaderProps> = observer((
    {title, activeLabel, terminalsLabel}
) => {
    return (
        <>
            <Title level={4}>
                {title}
            </Title>
            <Text>
                {terminalsLabel}
            </Text>&nbsp;
            <Text className={styles.subtitleSecondary}>
                {activeLabel}
            </Text>
        </>
    )
})
