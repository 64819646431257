import translations from './translations'

translations.add('el', {
    edit: 'Επεξεργασία',
    share: 'Μοιραστείτε',
    areYouSureYouWantToDeactivate: 'Είστε βέβαιοι ότι θέλετε να απενεργοποιήσετε το σύνδεσμο πληρωμής;',
    yes: 'Ναι',
    no: 'Όχι',
    createSimilar: 'Δημιουργία νέου',
    deactivate: 'Απενεργοποίηση'
})
