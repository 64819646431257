import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    paymentDetails: string
    date: string
    transactionDate: string
    amount: string
    status: string
    terminalId: string
    card: string
    details: string
    resultCode: string
    message: string
    cardType: string
    cardCategory: string
    corporate: string
    personal: string
    europeanCard: string
    captureMethod: string
    paymentMethod: string
    transaction: {
        id: string
        type: string
        details: string
        country: string
        city: string
    }
    currency: string
    isCorporateCard: string
    returnCode: string
    returnCodeDescription: string
    mid: string
    issuerRegion: string
    cardScheme: string
    cardMask: string
}>()

