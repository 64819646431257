import translations from './translations'

translations.add('el', {
    title: 'Αναφορές',
    transactions: 'Συναλλαγές',
    transactionsComparison: 'Σύγκριση συναλλαγών',
    calendarTitle: 'Τον προηγούμενο μήνα',
    paymentMethods: 'Μέθοδοι πληρωμής',
    cardPayments: 'Μέθοδοι κάρτας',
    issuingBanks: 'Εκδότριες Τράπεζες (μόνο πληρωμές με κάρτα)',
    amount: 'Ποσό',
    count: 'Αριθμός',
    volumeTotal: 'Συνολικός όγκος',
    previous: 'Προηγούμενο',
    current: 'Τρέχον',
    downloadLink: 'Κατεβάστε',
    cardScheme: 'Τύπος κάρτας',
    onlinePayments: 'Διαδικτυακές πληρωμές',
    posPayments: 'Πληρωμές POS',
    currency: 'Νόμισμα',
    noContentTitle: 'Δεν υπάρχει διαθέσιμο περιεχόμενο',
    empty: 'Άδειοg'
})
