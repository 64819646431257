import translations from './translations'

translations.add('el', {
    jobNameTypes: {
        'all': 'Ολα',
        'merchant-statement-excel': 'Δήλωση (Excel)',
        'merchant-statement-csv': 'Δήλωση (CSV)',
        'pos-transactionsv2-csv': 'Πληρωμές POS (CSV)',
        'pos-amex-transactions-csv': 'POS American Express (CSV)',
        'ecom-transactions-csv': 'Online Payments (CSV)'
    }
})
