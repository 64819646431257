import 'reflect-metadata'
import {injectable} from 'inversify'
import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import {Domain, TApplePayDomainsStore} from '@/pages/payment-methods'
import {
    fetchDomains,
    registerDomain,
    unregisterDomain,
    UnregisterDomainRequestModel
} from '@/stores/payment-methods'
import {LoadingState} from '@/types'
import {openErrorNotification} from '@/utils'
import rootTranslations from '@/translations'

@injectable()
export class ApplePayDomainsStore implements TApplePayDomainsStore {
    constructor() {
        makeObservable(this, {
            _domains: observable,
            loadingState: observable,
            isDomainUnregisterModalVisible: observable,
            isRegisterModalVisible: observable,
            domainToUnregister: observable,
            domainToRegister: observable,
            isUnregisterLoading: observable,
            isRegisterLoading: observable,
            domains: computed,
            loadDomains: action.bound,
            onTrashIconClick: action.bound,
            onUnregisterDomain: action.bound,
            onCloseUnregisterModal: action.bound,
            onCloseRegisterModal: action.bound,
            onOpenRegisterModal: action.bound,
            onRegisterDomain: action.bound
        })
    }

    _domains: Domain[] = []
    loadingState: LoadingState = LoadingState.IDLE
    isDomainUnregisterModalVisible = false
    isRegisterModalVisible = false
    isUnregisterLoading = false
    isRegisterLoading = false
    domainToUnregister = ''
    domainToRegister = ''

    get domains(): Domain[] {
        if (
            this._domains.length === 0 &&
            this.loadingState !== LoadingState.LOADING &&
            this.loadingState !== LoadingState.DONE
        ) {
            this.loadDomains()
        }

        return this._domains
    }

    loadDomains = async () => {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.loadingState = LoadingState.LOADING
        })

        try {
            const {result, error} = await fetchDomains()

            runInAction(() => {
                this.loadingState = LoadingState.DONE

                if (error) {
                    openErrorNotification(error.message)
                }

                this._domains =
                    result?.domainNames?.map(domain => ({
                        name: domain,
                        onTrashIconClick: this.onTrashIconClick
                    })) || []
            })
        } catch (error) {
            openErrorNotification(rootTranslations().errors.general)
        }
    }

    onTrashIconClick = async (name: string) => {
        this.domainToUnregister = name
        this.isDomainUnregisterModalVisible = true
    }

    onUnregisterDomain = async values => {
        const data: UnregisterDomainRequestModel = {
            domainNames: [this.domainToUnregister],
            reason: values.reason
        }

        runInAction(() => {
            this.isUnregisterLoading = true
        })

        try {
            const {error} = await unregisterDomain(data)

            runInAction(() => {
                if (error) {
                    openErrorNotification(error.message)
                }

                this.domainToUnregister = ''
                this.isDomainUnregisterModalVisible = false
                this.isUnregisterLoading = false
            })
        } catch (error) {
            openErrorNotification(rootTranslations().errors.general)
        }

        await this.loadDomains()
    }

    onRegisterDomain = async values => {
        const data: Pick<UnregisterDomainRequestModel, 'domainNames'> = {
            domainNames: [values.domain]
        }

        runInAction(() => {
            this.isRegisterLoading = true
        })

        try {
            const {error} = await registerDomain(data)

            runInAction(() => {
                if (error) {
                    openErrorNotification(error.message)
                }

                this.isRegisterModalVisible = false
                this.isRegisterLoading = false
            })
        } catch (error) {
            openErrorNotification(rootTranslations().errors.general)
        }

        await this.loadDomains()
    }

    onCloseUnregisterModal = () => {
        this.isDomainUnregisterModalVisible = false
    }

    onCloseRegisterModal = () => {
        this.isRegisterModalVisible = false
    }

    onOpenRegisterModal = () => {
        this.isRegisterModalVisible = true
    }
}
