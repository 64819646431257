import React from 'react'
import translations from './translations'
import {Typography} from 'antd'

translations.add('ro', {
    back: 'Înapoi la Resetare Parolă',
    title: 'Confirmare Email',
    text: (email) => <>
        Dacă găsim un cont asociat cu adresa de <Typography.Text strong>{email}</Typography.Text>, vom trimite un cod de verificare cu 6 cifre la aceasta
    </>,
    otp: 'Cod de verificare',
    otpInvalid: 'Codul de verificare este obligatoriu',
    otpRequired: 'Codul de verificare este invalid',
    submit: 'Confirmă',
    confirmEmailDescription(onClick) {
        return <>Dacă nu ai primit un cod de verificare, te rugăm să verifici folderul "Spam" sau să încerci <a onClick={onClick}>să-l trimiți din nou</a></>
    },
    waitingToResendVerificationCode(count) {
        return <>Dacă nu ai primit emailul de verificare, te rugăm să verifici folderul "Spam" sau <a className="disabled">re-send</a> în 5 <span className="count">{count} secunde</span></>
    }
})
