import translations from './translations'

translations.add('sl', {
    recipientName: 'Meno odosielateľa',
    recipientNumber: 'Telefónne číslo',
    recipientsNumber: ' Telefónne číslo',
    recipientEmail: 'E-mailová adresa',
    recipientsEmail: ' E-mailová adresa',
    errors: {
        recipientNumberRequired: 'Vyžaduje sa číslo príjemcu',
        recipientNumberInvalid: 'Telefón príjemcu nie je platný',
        recipientEmailRequired: 'Vyžaduje sa e-mail',
        recipientEmailInvalid: 'E-mail nie je platný',
        recipientNameRequired: 'Vyžaduje sa meno odosielateľa'
    }
})
