import React from 'react'
import {Typography} from 'antd'
import translations from './translations'
import styles from './styles.scss'
import {
    LINK_SHARE_EMAIL_MESSAGE_PREVIEW,
    LINK_SHARE_PHONE_MESSAGE_PREVIEW
} from '@/constants/playwright-ids'

const {Text, Link} = Typography

type Props = {
    type: 'sms' | 'email'
    name: string
    url: string
    customer: string
}

export const LinkShareMessagePreview: React.FC<Props> = ({type, name, url, customer}) => {

    const renderPreview = (type) => {
        switch (type) {
            case 'sms':
                return (
                    <div className={styles.message}>
                        <Text>{translations().message(name)}</Text>
                        <br/><br/>
                        <Link data-test-id={LINK_SHARE_PHONE_MESSAGE_PREVIEW} href={url} target="_blank" rel={'noreferrer'}>{url}</Link>
                    </div>
                )
            case 'email':
                return (
                    <div className={styles.message}>
                        <Text>{translations().emailMessage(name, customer)}</Text>
                        <br/><br/>
                        <Link data-test-id={LINK_SHARE_EMAIL_MESSAGE_PREVIEW} href={url} target="_blank" rel={'noreferrer'}>{url}</Link>
                    </div>
                )
            default:
                return <></>
        }
    }

    return (
        <>
            <Text>{translations().messageTextPreview}</Text>
            {renderPreview(type)}
        </>
    )
}
