import translations from './translations'

translations.add('el', {
    title: 'ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ',
    detailedReport: 'Αναλυτική αναφορά',
    onlinePayments: 'Διαδικτυακές πληρωμές',
    posPayments: 'Πληρωμές POS',
    chargedTransactions: 'Χρεωμένες συναλλαγές',
    chargedTransactionsComparison: 'Σύγκριση χρεωμένων συναλλαγών',
    howItWorks: 'Πως δουλεύει?',
    merchantPortal: 'Πύλη εμπόρων',
    productGuides: 'Οδηγοί προϊόντων',
    paymentLinks: 'Σύνδεσμοι για πληρωμή',
    readMore: 'Διαβάστε περισσότερα',
    merchantPortalGuide: 'Οδηγός πύλης εμπόρων',
    onlinePaymentSystem:
        'Ένα ηλεκτρονικό σύστημα πληρωμών ενσωματωμένο απευθείας στον ιστότοπό σας - Κάντε κλικ στις συμβουλές για το πώς να ξεκινήσετε να χρησιμοποιείτε την πύλη',
    toHelpYouGetStarted:
        'Για να σας βοηθήσουμε να ξεκινήσετε, ετοιμάσαμε μερικούς γρήγορους και εύχρηστους οδηγούς',
    whereToStart: 'Από πού να ξεκινήσετε',
    startEarning: 'Ξεκινήστε να εισπράττετε',
    allPosTransactions: 'Όλες οι συναλλαγές POS',
    currency: 'Νόμισμα',
    noContentTitle: 'Δεν υπάρχει διαθέσιμο περιεχόμενο'
})
