import translations from './translations'

translations.add('el', {
    title: 'Διαδικτυακές πληρωμές',
    charge: 'Χρέωση',
    refund: 'Επιστροφή χρημάτων',
    paymentDetails: 'Λεπτομέρειες πληρωμής',
    chargedAmount: 'Χρεωμένο ποσό',
    refundAmount: 'Ποσό επιστροφής χρημάτων',
    balance: 'Υπόλοιπο',
    donationAmount: 'Ποσό δωρεάς',
    date: 'Ημερομηνία',
    status: 'Κατάσταση',
    summary: 'Περίληψη',
    verifications: 'Επαληθεύσεις',
    channel: 'Κανάλι',
    shop: 'Κατάστημα',
    search: 'Αναζήτηση',
    statement: 'Δήλωση',
    select: 'Επιλέξτε',
    items: 'Είδη',
    description: 'Περιγραφή',
    customer: 'Πελάτης',
    orderNumber: 'Αριθμός παραγγελίας',
    amount: 'Ποσό',
    rnn: 'RNN',
    transactionType: 'Τύπος συναλλαγής',
    authorizedOn: 'Εξουσιοδοτημένο στις',
    chargedOn: 'Χρέωση στις',
    refundedOn: 'Επιστροφή χρημάτων στις',
    issuer: 'Εκδότης',
    card: 'Κάρτα',
    is3DSecure: '3DS',
    cardExpiryDate: 'Ημερομηνία Λήξης Κάρτας',
    acquirerResponseCode: 'Κωδικός απάντησης Acquirer',
    authCode: 'Κωδικός έγκρισης',
    AVSHouseNumberResult: 'AVS house number',
    AVSPostcodeResult: 'AVS postcode',
    yes: 'Ναι',
    no: 'Όχι',
    payerDetails: 'Πληροφορίες Πελάτη',
    payer: 'Ονομα',
    accountId: 'Αναγνωριστικό λογαριασμού',
    email: 'Ηλεκτρονική διεύθυνση',
    phone: 'Τηλέφωνο',
    resultMessage: 'Λόγος απόρριψης',
    geolocation: {
        payerIp: 'IP πληρωτή',
        location: 'Τοποθεσία'
    },
    paymentPage: {
        title: 'Σελίδα πληρωμών',
        language: 'Γλώσσα',
        postLinkAddress: 'Διεύθυνση PostLink',
        postLink: 'PostLink',
        ok: 'Εντάξει',
        failed: 'Απέτυχε'
    },
    signifydDetails: 'Λεπτομέρειες Signifyd',
    signifydId: 'Αναγνωριστικό Signifyd',
    decision: 'Απόφαση',
    reason: 'Αιτιολογία',
    score: 'Score',
    id: 'ID',
    processingTypeName: 'Τύπος επεξεργασίας',
    reference: 'RRN',
    cancelOn: 'Ακυρώθηκε στις',
    avsResult: 'Αποτέλεσμα AVS',
    payPal: {
        details: 'Λεπτομέρειες Paypal',
        paypalOrderStatus: 'Κατάσταση παραγγελίας',
        paypalPayerName: 'Όνομα πληρωτή',
        paypalEmail: 'Email πληρωτή',
        paypalPayerID: 'Αναγνωριστικό πληρωτή',
        paypalPayerCountryCode: 'Κωδικός χώρας πληρωμής',
        paypalCaptureStatus: 'Κατάσταση λήψης',
        paypalCaptureStatusReason: 'Αιτιολογία κατάστασης λήψης',
        paypalSellerProtectionStatus: 'Κατάσταση προστασίας πωλητή',
        paypalSellerProtectionDisputeCategories: 'Κατηγορίες Διαφωνιών Προστασίας Πωλητών'
    },
    searchFilter: {
        transactionId: 'Αναγνωριστικό συναλλαγής',
        customerAccountId: 'Αναγνωριστικό λογαριασμού πελάτη',
        description: 'Περιγραφή',
        order: 'Παραγγελία #',
        email: {
            startsWith: 'Το email ξεκινά με'
        },
        customerName: 'Ονομα πελάτη',
        card: {
            startsWith: 'Η κάρτα αρχίζει με',
            endsWith: 'Η κάρτα τελειώνει με'
        }
    },
    searchFilterErrors: {
        transactionId: 'Παρακαλώ εισάγετε ένα έγκυρο αναγνωριστικό'
    },
    createdDate: 'Ημερομηνία Δημιουργίας (GMT)',
    payoutAmount: 'Επιβεβαιωμένο ποσό',
    currency: 'Νόμισμα',
    payoutDate: 'Confirmed date (GMT)',
    processingType: 'Processing type',
    invoiceId: 'Αριθμός παραγγελίας',
    paymentMethod: 'Μέθοδος πληρωμής',
    cardType: 'Τύπος κάρτας',
    cardMask: 'Μάσκα κάρτας',
    secure3D: '3DS',
    avsHouseNumberResult: 'AVS house number',
    avsPostcodeResult: 'AVS postcode',
    cscResult: 'Αποτέλεσμα CSC',
    paEnrollment: 'PA enrollment',
    paAuthentication: 'Επαλήθευση PA',
    payerName: 'Ονομα πελάτη',
    payerEmail: 'email πελάτη',
    payerPhone: 'Τηλέφωνο πελάτη',
    payerIp: 'IP πληρωτή',
    ipCountry: 'IP χώρας',
    ipCity: 'IP πόλης',
    ipLatitude: 'IP γεωγραφικού πλάτους',
    ipLongitude: 'IP γεωγραφικού μήκους',
    message: 'Μήνυμα',
    transactionTypeLower: 'Τύπος συναλλαγής',
    initiatorEmail: 'Εκκινούμενο από',
    statuses: {
        'Matched': 'Ταιριάζει',
        'Not Matched': 'Δεν ταιριάζει',
        'Partial Match': 'Μερική Ταίριαση',
        'Yes': 'Ναι',
        'No': 'Όχι',
        'Authenticated': 'Επαληθευμένο',
        'Not authenticated': 'Μη Επαληθευμένο',
        'Unable to authenticate': 'Αδυναμία Επαλήθευσης',
        'Attempted': 'Προσπαθήθηκε',
        'Not attempted': 'Δεν Προσπαθήθηκε',
        'Enrolled': 'Εγγεγραμμένο',
        'Not enrolled': 'Μη Εγγεγραμμένο',
        'Challenge required': 'Απαιτείται Πρόκληση',
        'Not Provided': 'Δεν Παρασχέθηκε',
        'Not Checked': 'Δεν Ελέγχθηκε',
        'Unable to check': 'Αδυναμία Ελέγχου',
        'Not Set': 'Δεν Έχει Οριστεί',
        'Authentication rejected': 'Απόρριψη Επαλήθευσης'
    }
})
