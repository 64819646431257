import translations from './translations'

translations.add('el', {
    title: 'Εμπιστευόμενες Συσκευές',
    delete: 'Διαγραφή',
    columns: {
        device: 'Συσκευή',
        addedOn: 'Προστέθηκε στις',
        lastLogin: 'Τελευταία Σύνδεση'
    },
    messages: {
        confirm: 'Είστε σίγουρος/η ότι θέλετε να διαγράψετε την εμπιστευόμενη συσκευή σας;'
    },
    yes: 'Ναι',
    no: 'Όχι',
    tablet: 'Τάμπλετ',
    mobile: 'Κινητό',
    desktop: 'Επιφάνεια εργασίας',
    bot: 'Ρομπότ'
})
