import translations from './translations'

translations.add('el', {
    title: 'Card freeze',
    freezeYourCardTemporarily: '- Παγώστε την κάρτα σας προσωρινά',
    indefinitely: 'Indefinitely',
    oneHour: '1 ώρα',
    twentyFourHour: '24 ώρες',
    customPeriod: 'Προσαρμοσμένη περίοδος',
    cancel: 'Ακύρωση',
    freezeCard: 'Κλείδωμα κάρτας'
})
