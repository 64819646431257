import React from 'react'
import classNames from 'classnames'
import {LinkWithIconProps} from './props'
import styles from './styles.scss'

export const LinkWithIcon: React.FC<LinkWithIconProps> = (props) => {
    const {isMobile, children, icon, className, onClick} = props
    return <a
        onClick={onClick}
        className={classNames(styles.link, className, { [styles.mobileLink]: isMobile })}
    >
        {icon} {!isMobile && children}
    </a>
}
