import translations from './translations'

translations.add('en', {
    cookies: 'Cookies Preferences',
    cookiesContent: 'We use cookies on our website. Some of them are essential, while others help us to improve this website and your experience.',
    acceptAll: 'Accept all',
    save: 'Save',
    back: 'Back',
    essential: 'Essential',
    essentialContent: 'Essential cookies enable basic functions and are necessary for the website to function properly.',
    statistics: 'Statistics',
    statisticsContent: 'Statistics cookies collect information anonymously. This information helps us to understand how our visitors use our website.',
    cookieInformation: 'Cookie Information'
})
