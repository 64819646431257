import translations from './translations'

translations.add('es', {
    back: 'Atrás para iniciar sesión',
    title: 'Establecer nueva contraseña',
    newPassword: 'Tu nueva contraseña',
    confirmPassword: 'Confirmar Contraseña',
    errors: {
        newPasswordRequired: 'Por favor, introduzca su contraseña',
        newPasswordInvalid: 'Formato de contraseña incorrecto',
        confirmPasswordInvalid: 'Las contraseñas no coinciden',
        confirmPasswordRequired: 'Por favor ingrese su contraseña nuevamente para confirmar'
    },
    submit: 'Confirmar',
    codeMustContain: 'La contraseña debe contener',
    codeRestrictions: {
        atLeastEightCharacters: 'Al menos 8 carácteres',
        atLeastOneDigit: 'Al menos 1 dígito',
        atLeastOneSpecialCharacter: 'Al menos 1 carácter especial, e.g. !@#&',
        atLeastOneUppercaseLetter: 'Al menos 1 letra mayúscula',
        atLeastOneLowercaseLetter: 'Al menos 1 letra minúscula'
    }
})
