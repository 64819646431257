import translations from './translations'

translations.add('en', {
    postLinkStatus: {
        all: 'All',
        ok: 'Ok',
        failed: 'Failed'
    },
    columns: {
        amount: 'Amount',
        status: 'Status',
        customer: 'Customer',
        paymentMethod: 'Payment method',
        orderNumber: 'Order number',
        date: 'Date',
        action: 'Action'
    },
    actions: {
        charge: 'Charge',
        refund: 'Refund',
        cancel: 'Cancel'
    },
    viewDetails: 'View details',
    all: 'All',
    cancelPaymentInTheAmount: (amount, currency) =>
        `Cancel 1 payment in the amount of ${amount}${currency}?`,
    ok: 'Ok',
    cancel: 'Cancel',
    successfullyCancelled: 'Payment successfully cancelled',
    error: 'Error'
})
