import {Moment} from 'moment-timezone'
import {injectable} from 'inversify'
import {makeAutoObservable} from 'mobx'
import 'reflect-metadata'

import {TRangePickerContainerStore} from '@/components/containers/RangePickerContainer/TRangePickerContainerStore'
import translations from '@/components/dumb/RangePicker/translations'
import {getDates, getPeriod, getPrevDates} from '@/stores'
import {TRangePickerStoreOptions, PeriodType} from './models'

@injectable()
export class RangePickerStore implements TRangePickerContainerStore {
    constructor(options: TRangePickerStoreOptions = {}) {
        makeAutoObservable(this)

        if (options.period) {
            this.setPeriod(options.period)
        }

        if (options.rangePresets) {
            this._rangePresets = options.rangePresets
        }
    }

    public disabled = false
    public withLeftMenu = true
    public periodTitle = translations().calendarTitle
    public endDate: Moment = null
    public startDate: Moment = null
    public period: PeriodType = null
    public isLoading = false
    _rangePresets: Record<string, PeriodType> = {
        [translations().presets.today]: PeriodType.Day,
        [translations().presets.yesterday]: PeriodType.Yesterday,
        [translations().presets.thisWeek]: PeriodType.Week,
        [translations().presets.last7Days]: PeriodType.Last7Days,
        [translations().presets.thisMonth]: PeriodType.Month,
        [translations().presets.last30Days]: PeriodType.Last30Days,
        [translations().presets.last60Days]: PeriodType.Last60Days,
        [translations().presets.last90Days]: PeriodType.Last90Days,
        [translations().presets.thisYear]: PeriodType.Year,
        [translations().presets.last12Month]: PeriodType.Last12Months
    }

    public clear() {
        this.startDate = null
        this.endDate = null
    }

    public setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading
    }

    public setDates = ([startDate, endDate]): void => {
        this.startDate = startDate
        this.endDate = endDate

        this.period = getPeriod(startDate, endDate)
        this.setPeriodTitle()
    }

    public setPeriod = (period: PeriodType) => {
        const {startDate, endDate} = getDates(period)
        this.startDate = startDate
        this.endDate = endDate
        this.period = period
        this.setPeriodTitle()
    }

    public setPeriodTitle = () => {
        if (!this.period) {
            this.periodTitle = translations().calendarTitle
            return
        }

        switch (this.period) {
            case PeriodType.Custom:
                this.periodTitle = translations().calendarTitle
                break
            case PeriodType.Day:
                this.periodTitle = translations().presets.today
                break
            case PeriodType.Yesterday:
                this.periodTitle = translations().presets.yesterday
                break
            case PeriodType.Week:
                this.periodTitle = translations().presets.thisWeek
                break
            case PeriodType.Last7Days:
                this.periodTitle = translations().presets.last7Days
                break
            case PeriodType.LastWeek:
                this.periodTitle = translations().presets.lastWeek
                break
            case PeriodType.Month:
                this.periodTitle = translations().presets.thisMonth
                break
            case PeriodType.LastMonth:
                this.periodTitle = translations().presets.lastMonth
                break
            case PeriodType.Last30Days:
                this.periodTitle = translations().presets.last30Days
                break
            case PeriodType.Last60Days:
                this.periodTitle = translations().presets.last60Days
                break
            case PeriodType.Last90Days:
                this.periodTitle = translations().presets.last90Days
                break
            case PeriodType.Year:
                this.periodTitle = translations().presets.thisYear
                break
            case PeriodType.Last12Months:
                this.periodTitle = translations().presets.last12Month
                break
        }
    }

    public get rangePresets(): Record<string, PeriodType> {
        return this._rangePresets
    }

    get prevDates() {
        return getPrevDates(this.startDate, this.endDate, this.period)
    }
}
