import moment, {Moment} from 'moment-timezone'

const momentUK: Moment = moment.tz('Europe/London')

export const isDateDisabled = (currentDate: Moment, selectedDates: Moment[]) => {
    const _isNowAfter6am = momentUK.hour() > 6
    const weekDay = momentUK.day()
    const prevFriday = momentUK.clone().day(weekDay >= 5 ? 5 : -2)

    // Do not allow any dates before 01.01.2022
    const isBeforeMinAllowedDate =
        currentDate < momentUK.clone().set({year: 2022, month: 0, date: 1}).startOf('day')

    // Disable dates before 3 years from the current month
    const isBefore3Year = currentDate < momentUK.clone().startOf('month').subtract(3, 'year')

    const isTodayOrFuture = currentDate >= momentUK.clone().startOf('day')
    const isYesterdayAndNotAfter6am =
        currentDate?.isSame(momentUK.clone().startOf('day').subtract(1, 'day'), 'day') &&
        !_isNowAfter6am
    const isSundayOrSaturdayOrMondayNotAfter6am =
        weekDay === 6 || weekDay === 0 || (weekDay === 1 && !_isNowAfter6am)

    const isBeyondMaxRange = selectedDates[0] && currentDate > getRangeDate(selectedDates[0], 'end')

    return (
        isBefore3Year ||
        isBeforeMinAllowedDate ||
        isTodayOrFuture ||
        isYesterdayAndNotAfter6am ||
        (isSundayOrSaturdayOrMondayNotAfter6am && currentDate.isSameOrAfter(prevFriday, 'day')) ||
        isBeyondMaxRange
    )
}

export const isDatesAreInRange = (selectedDates: Moment[]) => {
    return selectedDates[1] <= getRangeDate(selectedDates[0], 'end')
}

export const getRangeDate = (date: Moment, rangeType: 'start' | 'end') => {
    if (rangeType === 'end') {
        return date.clone().add(1, 'year').endOf('day')
    }

    return date.clone().subtract(1, 'year').startOf('day')
}

export const getLastFullMonth = () => moment().subtract(1, 'month').endOf('month')

export const disabledDateForPdf = (date: moment.Moment) => {
    // Do not allow any dates before 01.01.2022
    const isBeforeMinAllowedDate =
        date < momentUK.clone().set({year: 2022, month: 0, date: 1}).startOf('day')

    // Disable dates before 3 years from the current month
    const isBefore3Year = date < momentUK.clone().startOf('month').subtract(3, 'year')

    // Disable the current month until it has fully completed.
    const isAfterLastFullMonth = date > getLastFullMonth()

    return isBeforeMinAllowedDate || isBefore3Year || isAfterLastFullMonth
}
