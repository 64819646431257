import {inject, injectable} from 'inversify'
import {makeAutoObservable} from 'mobx'
import {ReportsECOMRangePickerStoreSymbol, ReportsPOSRangePickerStoreSymbol, TReportsStore} from '@/pages/Reports'
import {TRangePickerContainerStore, TSelectContainerStore} from '@/components/containers'
import {
    CMStatusStoreSymbol,
    IssuerStatusStoreSymbol,
    PMStatusStoreSymbol,
    POSCardSchemeStatusStoreSymbol, POSIssuerStatusStoreSymbol
} from '@/pages/Reports/components'
import {posStatuses, statuses} from '@/pages/Reports/constants'

@injectable()
export class ReportsStore implements TReportsStore {
    private _rangePickerStore: TRangePickerContainerStore
    private _posRangePickerStore: TRangePickerContainerStore
    private _pmStatusSelect: TSelectContainerStore
    private _cmStatusSelect: TSelectContainerStore
    private _issuerStatusSelect: TSelectContainerStore
    private _posCsStatusSelect: TSelectContainerStore
    private _posIssuerStatusSelect: TSelectContainerStore

    constructor(
        @inject(ReportsECOMRangePickerStoreSymbol) rangePickerStore: TRangePickerContainerStore,
        @inject(ReportsPOSRangePickerStoreSymbol) posRangePickerStore: TRangePickerContainerStore,
        @inject(PMStatusStoreSymbol) pmStatusSelect: TSelectContainerStore,
        @inject(CMStatusStoreSymbol) cmStatusSelect: TSelectContainerStore,
        @inject(IssuerStatusStoreSymbol) issuerStatusSelect: TSelectContainerStore,
        @inject(POSCardSchemeStatusStoreSymbol) posCsStatusSelect: TSelectContainerStore,
        @inject(POSIssuerStatusStoreSymbol) posIssuerStatusSelect: TSelectContainerStore
    ) {
        this._rangePickerStore = rangePickerStore
        this._posRangePickerStore = posRangePickerStore
        this._pmStatusSelect = pmStatusSelect
        this._cmStatusSelect = cmStatusSelect
        this._issuerStatusSelect = issuerStatusSelect
        this._posCsStatusSelect = posCsStatusSelect
        this._posIssuerStatusSelect = posIssuerStatusSelect

        this.initializeDoughnutStatusesSelect()

        makeAutoObservable(this)
    }

    initializeDoughnutStatusesSelect() {
        this._pmStatusSelect.setValue(statuses[0].value)
        this._pmStatusSelect.setOptions(statuses)

        this._cmStatusSelect.setValue(statuses[0].value)
        this._cmStatusSelect.setOptions(statuses)

        this._issuerStatusSelect.setValue(statuses[0].value)
        this._issuerStatusSelect.setOptions(statuses)

        this._posCsStatusSelect.setValue(posStatuses[0].value)
        this._posCsStatusSelect.setOptions(posStatuses)

        this._posIssuerStatusSelect.setValue(posStatuses[0].value)
        this._posIssuerStatusSelect.setOptions(posStatuses)
    }
}
