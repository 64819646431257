import translations from './translations'

translations.add('en', {
    title: 'Team management',
    inviteTeammate: 'Add user',
    role: 'Role',
    all: 'All',
    active: 'Invited',
    inactive: 'Accepted invitation',
    statuses: {
        ok: 'Active',
        invited: 'Invited',
        validateEmail: 'Validate email',
        changePassword: 'Change password',
        block: 'Blocked'
    },
    authMethods: {
        password: 'Password',
        twoFAApp: '2FA (Auth App)',
        twoFAEnforced: '2FA (Enforced)'
    },
    notifications: {
        userSuccessfullyInvited: 'User successfully invited'
    }
})
