import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable} from 'mobx'
import {PaymentLink} from '@/api'
import {PayByLinkLinkDetailsModalContainerStoreSymbol} from '@/pages/PayByLink'
import {TModalContainerStore} from '@/components/containers/ModalContainer/TModalContainerStore'
import {TPaymentLinkDetailsModalStore} from '@/pages/components'

@injectable()
export class PaymentLinkDetailsModalStore implements TPaymentLinkDetailsModalStore {
    private readonly _linkDetailsModalStore: TModalContainerStore

    constructor(
        @inject(PayByLinkLinkDetailsModalContainerStoreSymbol) linkDetailsModalStore: TModalContainerStore
    ) {
        this._linkDetailsModalStore = linkDetailsModalStore

        makeObservable(this, {
            isLoading: observable,
            _widgetType: observable,
            paymentLink: observable,
            isNewLinkDetails: observable,
            widgetType: computed,
            setPaymentLink: action.bound,
            closeModal: action.bound,
            reset: action.bound
        })
    }

    public isLoading = false
    public isNewLinkDetails = false
    public paymentLink: PaymentLink = {} as PaymentLink

    _widgetType: 'linkDetails' | 'shareViaEmail' | 'shareViaSMS' = 'linkDetails'

    get widgetType(): 'linkDetails' | 'shareViaEmail' | 'shareViaSMS' {
        return this._widgetType
    }

    set widgetType(value: 'linkDetails' | 'shareViaEmail' | 'shareViaSMS') {
        this._widgetType = value
    }

    public setPaymentLink(paymentLink: PaymentLink) {
        this.paymentLink = paymentLink
        this._linkDetailsModalStore.setOpen(true)
    }

    public reset() {
        this.closeModal()
        this.setPaymentLink(null)
    }

    public closeModal() {
        this._linkDetailsModalStore.setOpen(false)
    }
}
