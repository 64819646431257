import React from 'react'
import {Icon} from '@/components'
import {PaymentCardScheme} from '@/constants'
import translations from './translations'
import {CartSchemeIconProps} from './props'
import {AMEX, DINNERS_CLUB, MASTERCARD, UNION_PAY, VISA} from '@/constants/playwright-ids'

export const CardSchemeIcon: React.FC<CartSchemeIconProps> = props => {
    const {type, className} = props

    if (!type) return null

    switch (type) {
        case PaymentCardScheme.Visa:
            return <Icon type={'visa'} className={className} data-test-id={VISA} />
        case PaymentCardScheme.Amex:
        case PaymentCardScheme.AmericanExpress:
            return <Icon type={'amex'} className={className} data-test-id={AMEX} />
        case PaymentCardScheme.Mastercard:
            return <Icon type={'mastercard'} className={className} data-test-id={MASTERCARD} />
        case PaymentCardScheme.UnionPay:
        case PaymentCardScheme.UPI:
            return <Icon type={'union_pay'} className={className} data-test-id={UNION_PAY} />
        case PaymentCardScheme.DinersClub:
            return <Icon type={'diners'} className={className} data-test-id={DINNERS_CLUB} />
        default:
            return <>{translations().other}</>
    }
}
