import translations from './translations'

translations.add('sl', {
    back: 'Späť na prihlásenie',
    title: 'Nastavte nové heslo',
    newPassword: 'Vaše nové heslo',
    confirmPassword: 'Potvrďte heslo',
    errors: {
        newPasswordRequired: 'Please, enter your password',
        newPasswordInvalid: 'Nesprávny formát hesla',
        confirmPasswordInvalid: 'Heslá, ktoré ste zadali, sa nezhodujú',
        confirmPasswordRequired: 'Potvrďte to opätovným zadaním hesla'
    },
    submit: 'Potvrdiť',
    codeMustContain: 'Heslo musí obsahovať',
    codeRestrictions: {
        atLeastEightCharacters: 'Minimálne 8 znakov',
        atLeastOneDigit: 'Najmenej 1 číslica',
        atLeastOneSpecialCharacter: 'Aspoň 1 špeciálny znak, napr. !@#&',
        atLeastOneUppercaseLetter: 'Aspoň 1 veľké písmeno',
        atLeastOneLowercaseLetter: 'Aspoň 1 malé písmeno'
    }
})
