import React from 'react'
import {ReloadOutlined} from '@ant-design/icons'
import {Button} from '@/components'
import styles from './styles.scss'
import {REFRESH} from '@/constants/playwright-ids'

export const RefreshButton = ({onClick, disabled}: {onClick: () => void; disabled?: boolean}) => {
    return (
        <Button
            onClick={onClick}
            className={styles.button}
            type={'ghost'}
            icon={<ReloadOutlined />}
            disabled={disabled}
            data-test-id={REFRESH}
        />
    )
}
