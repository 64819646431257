import translations from './translations'

translations.add('el', {
    amount: 'Ποσό',
    chargebackDate: 'Ημερομηνία Ανάκλησης',
    transactionDate: 'Ημερομηνία Συναλλαγής',
    currency: 'Νόμισμα',
    reasonCode: 'Κωδικός Αιτίας',
    merchantName: 'Όνομα Εμπόρου',
    reason: 'Reason',
    status: 'Status',
    paymentMethod: 'Μέθοδος πληρωμής'
})
