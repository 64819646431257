import translations from './translations'

translations.add('sl', {
    id: 'ID',
    date: 'Issue Date',
    period: 'Period',
    merchantName: 'Merchant',
    amount: 'Množstvo',
    currency: 'Mena',
    status: 'Stav',
    invoices: 'Faktúry',
    all: 'Všetko',
    paid: 'Zaplatené',
    issued: 'Issued',
    action: 'Akcia',
    download: 'Stiahnuť'
})
