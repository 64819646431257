import translations from './translations'

translations.add('es', {
    title: 'Agregar Autenticación de Dos Factores',
    updateTitle: 'Actualizar Autenticación de Dos Factores',
    recoveryCodes: 'Códigos de Recuperación',
    selectFrequency: 'Seleccionar frecuencia',
    configureApp: 'Configurar aplicación autenticadora',
    cancel: 'Cancelar',
    back: 'Atrás',
    next: 'Siguiente',
    update: 'Actualizar',
    submit: 'Enviar'
})
