import translations from './translations'
import React from 'react'

translations.add('el', {
    messageTextPreview: 'Προεπισκόπηση κειμένου μηνύματος:',
    message: (name) => `Λάβατε αυτό το μήνυμα με αίτημα πληρωμής από τον ${name ? name : 'Όνομα αποστολέα '}. Ακολουθήστε το σύνδεσμο για να πραγματοποιήσετε την πληρωμή:`,
    emailMessage: (senderName, recipientName) => (
        <div>
            <div>Dear {recipientName}</div>
            <div>{senderName ? senderName : 'Το Όνομα του Αποστολέα'} σας ζητά να πραγματοποιήσετε μια πληρωμή.
                Παρακαλούμε, ακολουθήστε τον παρακάτω σύνδεσμο και επιλέξτε την προτιμώμενη μέθοδο πληρωμής σας
            </div>
        </div>
    )
})
