import React from 'react'
import {Form} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {ModalFormFooter, Text, TitleWithStatus} from '@/components'
import {ModalContainer} from '@/components/containers'
import {EditPaymentLinkForm, TEditPaymentLinkStore} from '@/pages/components'
import {PayByLinkEditPaymentLinkStoreSymbol} from '@/pages/PayByLink'
import {getAmountWithCurrentCurrency, upperCaseFirstLetter} from '@/utils'
import {getPayByLinkPaymentStatusIconType, getPayByLinkPaymentStatusTagType} from '@/pages/PayByLink/utils'
import translations from './translations'
import {NewPaymentLinkModalProps} from './props'
import styles from './styles.scss'

export const EditPaymentLinkModal: React.FC<NewPaymentLinkModalProps> = observer((
    {injectableIdentifier}: NewPaymentLinkModalProps
) => {
    const {paymentLink, isLoading, closeEditLinkModal} = useInjection<TEditPaymentLinkStore>(PayByLinkEditPaymentLinkStoreSymbol)
    const [form] = Form.useForm()

    return (
        <ModalContainer
            className={styles.modal}
            title={<TitleWithStatus statusProps={{
                status: upperCaseFirstLetter(paymentLink.status),
                type: getPayByLinkPaymentStatusTagType(paymentLink.status),
                iconType: getPayByLinkPaymentStatusIconType(paymentLink.status)
            }}>
                <Text>{getAmountWithCurrentCurrency(paymentLink.amount)}</Text>
            </TitleWithStatus>}
            injectableIdentifier={injectableIdentifier}
            footer={<ModalFormFooter
                formId={'editPaymentLinkForm'}
                onCancel={() => {
                    form.resetFields()
                    closeEditLinkModal()
                }}
                cancelText={translations().close}
                confirmLoading={isLoading}
                okText={translations().save}
            />}
        >
            <EditPaymentLinkForm form={form} formId={'editPaymentLinkForm'}/>
        </ModalContainer>
    )
})
