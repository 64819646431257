import translations from './translations'
import React from 'react'

translations.add('ro', {
    title: 'Open Banking',
    description: <>
        Open Banking este o modalitate sigură de a primi plăți pentru bunuri și servicii direct din contul bancar al consumatorului.
        <br/><br/>
        Prin activarea Open Banking, consumatorii vor avea opțiunea de a-și alege furnizorul de servicii bancare (din peste 50+ instituții financiare, o listă care crește încontinuu), făcând plata în câțiva pași simpli. Fondurile vor fi debitați instantaneu din cont, contribuind la gestionarea mai eficientă a balanțelor disponibile.
        <br/><br/>
        DNA Payments este mândră să fie partenerul principal al furnizorului de servicii Open Banking, Ecospend, pentru a pune la dispoziție această facilitate bazei noastre de clienți.
        <br/><br/>
    </>
})
