import translations from './translations'

translations.add('sl', {
    title: 'Prehľad',
    detailedReport: 'Podrobná správa',
    onlinePayments: 'Online platby',
    posPayments: 'POS platby',
    chargedTransactions: 'Účtované transakcie',
    chargedTransactionsComparison: 'Porovnanie účtovaných transakcií',
    howItWorks: 'Ako to funguje?',
    merchantPortal: 'Merchant Portal',
    productGuides: 'Píručky pre produkt',
    paymentLinks: 'Platobné prepojenia',
    readMore: 'Čítaj viac',
    merchantPortalGuide: 'Merchant Portal sprievodca',
    onlinePaymentSystem:
        'Online platobný systém integrovaný priamo na vašom webovom sídle – kliknite na tipy, ako začať používať portál',
    toHelpYouGetStarted:
        'Aby sme vám pomohli začať, pripravili sme niekoľko rýchlych a ľahko použiteľných príručiek',
    whereToStart: 'Kde začať',
    startEarning: 'Začnite zarábať',
    allPosTransactions: 'Všetky predajné transakcie',
    currency: 'Mena',
    noContentTitle: 'Nie je k dispozícii žiadny obsah'
})
