import translations from './translations'

translations.add('el', {
    welcome: 'Καλώς ήρθατε στη DNA Payments',
    merchantPortal: 'Πύλη εμπόρων',
    startTutorial: 'Έναρξη οδηγού',
    description1: 'Η Πύλη των Εμπόρων μας είναι μια πλατφόρμα που σας βοηθά να παρακολουθείτε και να διαχειρίζεστε Συναλλαγές Ροών Εργασίας, Επιστροφές, Αμφισβητήσεις και Διακανονισμούς σε πραγματικό χρόνο.',
    description2: 'Συνιστούμε να διαβάσετε τον οδηγό μας για να ανακαλύψετε πώς μπορείτε να χρησιμοποιήσετε τις δυνατότητες και τις λειτουργίες της Πύλης Εμπόρων στο μέγιστο των δυνατοτήτων τους',
    skipTutorial: 'Παράλειψη tutorial',
    back: 'Πίσω',
    close: 'Κλείσιμο',
    next: 'Επόμενο',
    open: 'Άνοιγμα διαλόγου',
    skip: 'Παράλειψη'
})
