import {action, computed, observable, runInAction} from 'mobx'
import {injectable} from 'inversify'
import {ApiResponse} from 'back-connector'

import {LoadingState} from '@/types'
import {openErrorNotification} from '@/utils'
import {isAuthenticated} from '@/stores/auth/services'
import rootTranslations from '@/translations'

type LoadDataStoreOptions = {
    ignoreDoneState?: boolean
    ignoreLoadingState?: boolean
    ignoreAuthenticated?: boolean
}

@injectable()
export abstract class BaseDataStore<T> {
    loadingState: LoadingState = LoadingState.IDLE

    protected _name = 'BaseDataStore'
    protected _data: T = this.getDefaultData()
    protected _fetchPromise: Promise<ApiResponse<T>>
    protected _hasInitialLoaded = false

    get isLoading() {
        return this.loadingState === LoadingState.LOADING
    }

    get initialLoadingState() {
        if (this._hasInitialLoaded) {
            return LoadingState.DONE
        }
        return this.loadingState
    }

    get data(): T {
        if (this.loadingState === LoadingState.IDLE) {
            this.loadData()
        }
        return this._data
    }

    constructor(protected options: LoadDataStoreOptions = {}) {}

    protected getAnnotationsMap() {
        return {
            loadingState: observable,
            _data: observable,
            _hasInitialLoaded: observable,

            data: computed,
            isLoading: computed,
            initialLoadingState: computed,

            setData: action.bound,
            setLoadingState: action.bound,
            loadData: action.bound,
            reloadData: action.bound
        }
    }

    protected getDefaultData(): T {
        return null
    }

    protected parseResponse(result: T): T {
        return result || this.getDefaultData()
    }

    protected abstract fetchData(): Promise<ApiResponse<T>>

    setData(data: T) {
        this._data = data
    }

    setLoadingState(state: LoadingState) {
        this.loadingState = state
    }

    async loadData(options: LoadDataStoreOptions = {}) {
        const _options = {...this.options, ...options}

        if (!_options.ignoreAuthenticated && !isAuthenticated()) {
            return
        }

        if (!_options.ignoreDoneState && this.loadingState === LoadingState.DONE) {
            return
        }

        if (!_options.ignoreLoadingState && this.loadingState === LoadingState.LOADING) {
            await this._fetchPromise
            return
        }

        this.setLoadingState(LoadingState.LOADING)

        try {
            this._fetchPromise = this.fetchData()
            const {result, error} = await this._fetchPromise

            if (error) {
                throw new Error(error.message)
            }

            this.setData(this.parseResponse(result))
            this.setLoadingState(LoadingState.DONE)
            runInAction(() => (this._hasInitialLoaded = true))
        } catch (err) {
            this.setData(this.getDefaultData())
            this.setLoadingState(LoadingState.FAILED)
            openErrorNotification(rootTranslations().errors.general)
        }
    }

    async reloadData() {
        await this.loadData({ignoreDoneState: true})
    }
}
