import translations from './translations'

translations.add('sl', {
    charge: 'Naúčtuj',
    refund: 'Vrátenie',
    cancel: 'Zruš',
    paymentCharge: 'Poplatok za platbu',
    paymentRefund: 'Vrátenie platby',
    paymentAmount: 'Suma platby',
    balance: 'Zostatok',
    chargeAmount: 'Výška poplatku',
    refundAmount: 'Výška vrátenia',
    pending: 'Prebiehajúci',
    errors: {
        positiveAmount: 'Čiastka vrátenej náhrady musí byť kladné číslo',
        amountRefundExceed: 'Čiastka vrátenej náhrady musí byť kladné číslo nie vyššie ako zostatok',
        amountChargeExceed: 'Výška poplatku musí byť kladné číslo, ktoré nesmie byť vyššie ako výška platby'
    }
})
