import translations from './translations'

translations.add('es', {
    jobNameTypes: {
        'all': 'Todo',
        'merchant-statement-excel': 'Extracto (Excel)',
        'merchant-statement-csv': 'Extracto (CSV)',
        'pos-transactionsv2-csv': 'Pagos TPV (CSV)',
        'pos-amex-transactions-csv': 'TPV American Express (CSV)',
        'ecom-transactions-csv': 'Pagos Online (CSV)'
    }
})
