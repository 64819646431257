import React, {useState} from 'react'
import classNames from 'classnames'
import {Input, Popover} from 'antd'
import {DownOutlined} from '@ant-design/icons'
import {MultiSelectContent} from './components'
import {MultiSelectAction} from './models'
import translations from './translations'
import {MultiSelectProps} from './props'
import styles from './styles.scss'

export const MultiSelect: React.FC<MultiSelectProps> = props => {
    const {
        value = [],
        options,
        placeholder,
        disabled,
        extraActions = [],
        isSearchable,
        isDefaultAll,
        emptyText = '',
        className,
        onChange = () => null,
        onBlur = () => null,
        onKeyDown = () => null
    } = props
    const [isOpen, setOpen] = useState(false)

    const isEmpty = !value || value.length === 0
    const isActionKey = (key: string) => Boolean(extraActions.find(a => a.key === key))
    const optionsWithoutActionKeys = options.filter(opt => !isActionKey(opt.value))

    const actions: MultiSelectAction[] = [
        {
            text: translations().selectAll,
            onClick: () => onChange(optionsWithoutActionKeys.map(opt => opt.value))
        },
        {text: translations().deselectAll, onClick: () => onChange([])},
        ...extraActions
    ]

    const handleMenuClick = (key: string) => {
        if (value.indexOf(key) >= 0) {
            onChange(value.filter(v => v !== key))
        } else if (isActionKey(key)) {
            onChange([key])
        } else {
            const values = value.filter(v => !isActionKey(v))
            onChange([...values, key])
        }
    }

    let selectedText: string | string[]

    if (isEmpty) {
        selectedText = isDefaultAll ? translations().all : emptyText
    } else if (optionsWithoutActionKeys.length === value.length) {
        selectedText = translations().all
    } else if (value.length > 1) {
        selectedText = translations().selected(value.length)
    } else {
        selectedText = options.find(opt => opt.value === value[0])?.label || value
    }

    const content = (
        <MultiSelectContent
            isSearchable={isSearchable}
            isOpen={isOpen}
            options={options}
            value={value}
            actions={actions}
            handleMenuClick={handleMenuClick}
        />
    )

    const handleInputClick = () => {
        if (disabled) return
        setOpen(true)
    }

    return (
        <Popover
            placement='bottomLeft'
            trigger={['click']}
            content={content}
            overlayClassName={styles.popover}
            open={isOpen}
            onOpenChange={flag => {
                setOpen(flag)
                if (!flag) {
                    onBlur()
                }
            }}
        >
            <Input
                className={classNames(className, styles.input, {
                    [styles.input__disabled]: disabled
                })}
                suffix={<DownOutlined onClick={handleInputClick} />}
                value={selectedText}
                disabled={disabled}
                placeholder={placeholder}
                onClick={handleInputClick}
                onKeyDown={onKeyDown}
            />
        </Popover>
    )
}
