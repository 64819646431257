import React from 'react'
import translations from './translations'

translations.add('ro', {
    title: (
        <>
            Codul de recuperare este generat pentru contul dumneavoastră. <br /> Acest cod cu 12
            caractere poate fi folosit o singură dată pentru a vă recupera contul în cazul în care
            nu puteți să vă autentificați cu autentificarea cu două factori (2FA).
        </>
    ),
    copy: 'Copiază',
    copyText: 'Copiați codul pentru a continua.',
    copied: 'Copiat',
    warning: {
        line1: 'Codul poate fi folosit doar o dată',
        line2: 'Din motive de securitate, acest cod nu va fi afișat din nou',
        line3: 'Nu distribuiți acest cod cu nimeni'
    }
})
