import {injectable} from 'inversify'
import {action, makeObservable, observable} from 'mobx'
import {TSelectContainerStore} from '@/components/containers/SelectContainer/TSelectContainerStore'
import {SelectItem} from '@/components/dumb/Select'
import 'reflect-metadata'

type SelectContainerStoreOptionsType = {
    options?: SelectItem[]
    value?: string
}

@injectable()
export class SelectContainerStore implements TSelectContainerStore {
    constructor(protected _options: SelectContainerStoreOptionsType = {}) {
        if (this._options.options) {
            this.options = this._options.options
        }
        if (this._options.value) {
            this.value = this._options.value
        }

        makeObservable(this, {
            value: observable,
            options: observable,
            setOptions: action.bound,
            setValue: action.bound
        })
    }

    options: SelectItem[] = []
    value: string = null

    clear() {
        this.value = null
    }

    setValue(value: string): void {
        this.value = value
    }

    setOptions(options: SelectItem[]): void {
        this.options = options
    }
}
