import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import {OnlinePaymentStatus, PaymentMethodType} from '@/constants'
import { PaymentViewModal } from '@/pages/components'
import {OnlinePaymentsPaymentOperationStoreSymbol, TPaymentOperationStore, OnlinePaymentsPaymentModalStoreSymbol} from '@/pages/OnlinePayments'
import {
    getOnlinePaymentStatusIconType,
    getOnlinePaymentStatusName,
    getOnlinePaymentStatusTagType
} from '@/pages/OnlinePayments/services'
import {PaymentChargeModalProps} from './props'

export const PaymentModal: React.FC<PaymentChargeModalProps> = observer((
    {selectedTransaction}
) => {
    const {status, id} = selectedTransaction
    const {
        isLoading,
        loadCharge,
        loadRefund,
        onPaymentModalCancel,
        openEcospendRefundModal
    } = useInjection<TPaymentOperationStore>(OnlinePaymentsPaymentOperationStoreSymbol)


    const onFinish = (values) => {
        if (selectedTransaction.paymentMethod === PaymentMethodType.ecospend) {
            openEcospendRefundModal(id, Number(values.amount))
            return
        }

        if (status === OnlinePaymentStatus.auth) {
            loadCharge(id, Number(values.amount))
        } else {
            loadRefund(id, Number(values.amount))
        }
    }

    return (
        <PaymentViewModal
            injectableIdentifier={OnlinePaymentsPaymentModalStoreSymbol}
            selectedPayment={selectedTransaction}
            isLoading={isLoading}
            isForRefund={status === OnlinePaymentStatus.charge}
            statusProps={status ? {
                status: getOnlinePaymentStatusName(status as OnlinePaymentStatus),
                type: getOnlinePaymentStatusTagType(status as OnlinePaymentStatus),
                iconType: getOnlinePaymentStatusIconType(status as OnlinePaymentStatus)
            } : null}
            onCancel={onPaymentModalCancel}
            onFinish={onFinish}
        />
    )
})
