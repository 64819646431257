import {TransactionDirection, TransactionStatus} from '@/stores'
import {SelectItem} from '@/components/dumb/Select'
import translations from '@/pages/business-account/AccountInfo/translations'

export const getDebitCreditAmount = (amount: number, type: TransactionDirection): number => {
    switch (type) {
        case 'credit':
            return amount * -1
        default:
            return amount
    }
}

export const getStatusSelectOptions = (): SelectItem[] => {
    return [
        {
            label: translations().all,
            value: ''
        },
        ...Object.keys(TransactionStatus).map(key => ({
            label: translations().transactionStatus[key],
            value: TransactionStatus[key]
        }))
    ]
}
