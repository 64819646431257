import {interfaces} from 'inversify'
import {register} from 'dna-react-ioc'

import {
    AuthStoreSymbol,
    ECOMTransactionSummaryStore,
    OverviewTabsContainerStore,
    PeriodType,
    POSTransactionsCardWithBarChartContainerStore,
    POSTransactionsComparisonCardWithBarChartContainerStore,
    POSTransactionSummaryStore,
    RangePickerStore,
    SelectContainerStore
} from '@/stores'
import {
    TRangePickerContainerStore,
    TSelectContainerStore,
    TTabsContainerStore,
    TTransactionsCardWithBarChartContainerStore,
    TTransactionSummaryContainerStore
} from '@/components'
import {
    OverviewECOMCurrencySelectStoreSymbol,
    OverviewPOSCurrencySelectStoreSymbol,
    EcomOverviewRangePickerStoreSymbol,
    OverviewTabsContainerStoreSymbol,
    PosOverviewRangePickerStoreSymbol,
    OverviewEcomStoreSelectStoreSymbol,
    OverviewPosStoreSelectStoreSymbol,
    OverviewEcomTransactionsStatusStoreSymbol
} from '@/pages/Overview/Overview'
import {
    OverviewChargedTransactionsCardWithBarChartContainerStoreSymbol,
    OverviewChargedTransactionsComparisonCardWithBarChartContainerStoreSymbol,
    OverviewTransactionSummaryStoreSymbol,
    PosAllTransactionsComparisonCardWithBarChartContainerStoreSymbol,
    PosChargedTransactionsCardWithBarChartContainerStoreSymbol,
    PosTransactionSummaryStoreSymbol
} from '@/pages/Overview'
import {ECOMTransactionsCardWithBarChartContainerStore} from '@/stores/reports/TransactionsCardWithBarChartContainerStore/ECOMTransactionsCardWithBarChartContainerStore'
import {getPortalCurrencies, OnlinePaymentStatus, POSPaymentStateStatus} from '@/constants'
import {ECOMTransactionsComparisonCardWithBarChartContainerStore} from '@/stores/reports/TransactionsComparisonCardWithBarChartContainerStore/ECOMTransactionsComparisonCardWithBarChartContainerStore'
import {EcomStoreSelectStore, PosStoreSelectStore} from '@/stores/store-and-terminals'
import {convertEcomStatusesToSelectItems, convertPosStatesToSelectItems} from '@/utils/transaction'

export const overviewState = () => {
    /** filter stores */

    register<TRangePickerContainerStore>(EcomOverviewRangePickerStoreSymbol)
        .toDynamicValue(() => new RangePickerStore({period: PeriodType.Day}))
        .inSingletonScope()

    register<TRangePickerContainerStore>(PosOverviewRangePickerStoreSymbol)
        .toDynamicValue(() => new RangePickerStore({period: PeriodType.Day}))
        .inSingletonScope()

    register<TSelectContainerStore>(OverviewECOMCurrencySelectStoreSymbol)
        .toDynamicValue(() => new SelectContainerStore({options: getPortalCurrencies}))
        .inSingletonScope()

    register<TSelectContainerStore>(OverviewPOSCurrencySelectStoreSymbol)
        .toDynamicValue(() => new SelectContainerStore({options: getPortalCurrencies}))
        .inSingletonScope()

    register<EcomStoreSelectStore>(OverviewEcomStoreSelectStoreSymbol)
        .to(EcomStoreSelectStore)
        .inSingletonScope()

    register<PosStoreSelectStore>(OverviewPosStoreSelectStoreSymbol)
        .to(PosStoreSelectStore)
        .inSingletonScope()

    register<TTabsContainerStore>(OverviewTabsContainerStoreSymbol)
        .to(OverviewTabsContainerStore)
        .inSingletonScope()

    register<TSelectContainerStore>(OverviewEcomTransactionsStatusStoreSymbol)
        .toDynamicValue(
            () =>
                new SelectContainerStore({
                    value: OnlinePaymentStatus.charge,
                    options: convertEcomStatusesToSelectItems([OnlinePaymentStatus.charge])
                })
        )
        .inSingletonScope()

    /** data stores */

    register<TTransactionSummaryContainerStore>(OverviewTransactionSummaryStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new ECOMTransactionSummaryStore(
                    'overview.ECOMTransactionSummaryStore',
                    context.container.get(EcomOverviewRangePickerStoreSymbol),
                    context.container.get(OverviewECOMCurrencySelectStoreSymbol),
                    context.container.get(OverviewEcomStoreSelectStoreSymbol)
                )
        )
        .inSingletonScope()

    register<TTransactionSummaryContainerStore>(PosTransactionSummaryStoreSymbol)
        .toDynamicValue(
            (context: interfaces.Context) =>
                new POSTransactionSummaryStore(
                    'overview.POSTransactionSummaryStore',
                    context.container.get(PosOverviewRangePickerStoreSymbol),
                    context.container.get(OverviewPOSCurrencySelectStoreSymbol),
                    context.container.get(OverviewPosStoreSelectStoreSymbol)
                )
        )
        .inSingletonScope()

    register<TTransactionsCardWithBarChartContainerStore>(
        OverviewChargedTransactionsComparisonCardWithBarChartContainerStoreSymbol
    )
        .toDynamicValue((context: interfaces.Context) => {
            const ecomOverviewTransactionsComparisonCardWithBarChartStore =
                new ECOMTransactionsComparisonCardWithBarChartContainerStore(
                    context.container.get(AuthStoreSymbol),
                    context.container.get(EcomOverviewRangePickerStoreSymbol),
                    context.container.get(OverviewECOMCurrencySelectStoreSymbol),
                    context.container.get(OverviewEcomStoreSelectStoreSymbol),
                    context.container.get(OverviewEcomTransactionsStatusStoreSymbol)
                )

            return ecomOverviewTransactionsComparisonCardWithBarChartStore
        })
        .inSingletonScope()

    register<TTransactionsCardWithBarChartContainerStore>(
        PosAllTransactionsComparisonCardWithBarChartContainerStoreSymbol
    )
        .toDynamicValue((context: interfaces.Context) => {
            const posOverviewTransactionsComparisonCardWithBarChartStore =
                new POSTransactionsComparisonCardWithBarChartContainerStore(
                    context.container.get(AuthStoreSymbol),
                    context.container.get(PosOverviewRangePickerStoreSymbol),
                    context.container.get(OverviewPOSCurrencySelectStoreSymbol),
                    context.container.get(OverviewPosStoreSelectStoreSymbol)
                )
            posOverviewTransactionsComparisonCardWithBarChartStore.setSelectedStatus(
                POSPaymentStateStatus.all
            )
            posOverviewTransactionsComparisonCardWithBarChartStore.statuses =
                convertPosStatesToSelectItems([POSPaymentStateStatus.all])

            return posOverviewTransactionsComparisonCardWithBarChartStore
        })
        .inSingletonScope()

    register<TTransactionsCardWithBarChartContainerStore>(
        OverviewChargedTransactionsCardWithBarChartContainerStoreSymbol
    )
        .toDynamicValue((context: interfaces.Context) => {
            const ecomOverviewTransactionsCardWithBarChartStore =
                new ECOMTransactionsCardWithBarChartContainerStore(
                    context.container.get(AuthStoreSymbol),
                    context.container.get(EcomOverviewRangePickerStoreSymbol),
                    context.container.get(OverviewECOMCurrencySelectStoreSymbol),
                    false,
                    context.container.get(
                        OverviewChargedTransactionsComparisonCardWithBarChartContainerStoreSymbol
                    ),
                    context.container.get(OverviewEcomStoreSelectStoreSymbol),
                    context.container.get(OverviewEcomTransactionsStatusStoreSymbol)
                )

            return ecomOverviewTransactionsCardWithBarChartStore
        })
        .inSingletonScope()

    register<TTransactionsCardWithBarChartContainerStore>(
        PosChargedTransactionsCardWithBarChartContainerStoreSymbol
    )
        .toDynamicValue((context: interfaces.Context) => {
            const posOverviewTransactionsCardWithBarChartStore =
                new POSTransactionsCardWithBarChartContainerStore(
                    'overview.POSTransactionsCardWithBarChartContainerStore',
                    context.container.get(AuthStoreSymbol),
                    context.container.get(PosOverviewRangePickerStoreSymbol),
                    context.container.get(OverviewPOSCurrencySelectStoreSymbol),
                    false,
                    context.container.get(
                        PosAllTransactionsComparisonCardWithBarChartContainerStoreSymbol
                    ),
                    context.container.get(OverviewPosStoreSelectStoreSymbol)
                )

            posOverviewTransactionsCardWithBarChartStore.setSelectedStatus(
                POSPaymentStateStatus.all
            )
            posOverviewTransactionsCardWithBarChartStore.statuses = convertPosStatesToSelectItems([
                POSPaymentStateStatus.all
            ])
            return posOverviewTransactionsCardWithBarChartStore
        })
        .inSingletonScope()
}
