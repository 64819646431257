import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import { THasIoCIdentifier } from '@/types'
import {TEcospendStore} from '@/pages/components'
import {EcospendBankList, EcospendConsentPage, EcospendResultPage} from './components'

export const OnlineEcospendStoreSymbol = Symbol('OnlineEcospendStore')
export const POSEcospendStoreSymbol = Symbol('POSEcospendStore')

export const Ecospend = observer((props: THasIoCIdentifier) => {
    const store = useInjection<TEcospendStore>(props.injectableIdentifier)

    if (store.result) {
        return <EcospendResultPage
            friendlyName={store.selectedBank?.friendly_name}
            invoiceId={store.currentPayment?.invoiceId}
            amount={store.amount}
            status={store.result.status}
            message={store.result.bank_reference_id}
            bankReferenceId={store.result.message}
            closeEcospendModal={store.closeEcospendModal}
            tryAgain={store.tryAgain}
            payee={store.payee}
        />
    }

    if (store.refundResponse) {
        return <EcospendConsentPage store={store}/>
    }

    return <EcospendBankList store={store}/>
})

