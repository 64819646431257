import translations from './translations'

translations.add('ro', {
    buttons: {
        cancel: 'Anulare',
        continue: 'Continuare'
    },
    description: n =>
        `Vă rugăm să introduceți codul de verificare cu ${n} cifre generat de aplicația dumneavoastră de autentificare`
})
