import 'reflect-metadata'
import moment from 'moment-timezone'
import {reaction, when} from 'mobx'
import {inject, injectable} from 'inversify'
import {tryParse} from '@/utils'
import {TRangePickerContainerStore} from '@/components/containers/RangePickerContainer/TRangePickerContainerStore'
import {PaginationContainerStoreType} from '@/components/containers'
import {getDates, PeriodType} from '@/stores'
import {TABLE_STORAGE_KEYS, SERVER_DATE_FORMAT} from '@/constants'
import {statusSelectOptions} from './services'
import {storage} from '@/services/storage'
import {FilterMomentType} from './models'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import {TChargebacksStore, ChargebacksStoreSymbol} from '@/pages/Chargebacks'
import {TSelectContainerStore} from '@/components'
import {
    ChargebacksStatusSelectContainerStoreSymbol,
    ChargebacksRangePickerContainerStoreSymbol,
    ChargebacksPaginationStoreSymbol,
    ChargebacksTableStoreSymbol,
    TChargebacksFilterStore,
    TChargebacksTableStore
} from '@/pages/Chargebacks/components'

@injectable()
export class ChargebacksFilterStore implements TChargebacksFilterStore {
    profileStore: ProfileStoreInterface
    chargebacksStore: TChargebacksStore
    rangePickerStore: TRangePickerContainerStore
    statusSelectStore: TSelectContainerStore
    chargebacksTableStore: TChargebacksTableStore
    paginationStore: PaginationContainerStoreType

    constructor(
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface,
        @inject(ChargebacksStoreSymbol) chargebacksStore: TChargebacksStore,
        @inject(ChargebacksTableStoreSymbol) chargebacksTableStore: TChargebacksTableStore,
        @inject(ChargebacksRangePickerContainerStoreSymbol)
        rangePickerStore: TRangePickerContainerStore,
        @inject(ChargebacksStatusSelectContainerStoreSymbol)
        statusSelectStore: TSelectContainerStore,
        @inject(ChargebacksPaginationStoreSymbol) paginationStore: PaginationContainerStoreType
    ) {
        this.profileStore = profileStore
        this.paginationStore = paginationStore
        this.chargebacksStore = chargebacksStore
        this.rangePickerStore = rangePickerStore
        this.statusSelectStore = statusSelectStore
        this.chargebacksTableStore = chargebacksTableStore

        this.initReactions()
    }

    private initReactions() {
        when(
            () => !!this.merchantId,
            () => this.initialize()
        )

        reaction(
            () => ({
                from: this.rangePickerStore.startDate,
                to: this.rangePickerStore.endDate,
                status: this.statusSelectStore.value,
                size: this.paginationStore.pageSize
            }),
            this.onChange
        )
    }

    get storageKey() {
        return `${TABLE_STORAGE_KEYS.chargebacks}-${this.profileEmail}-${this.merchantId}`
    }

    get profileEmail() {
        return storage.get('email')
    }

    get merchantId() {
        return this.profileStore?.merchant?.merchantId
    }

    initialize = () => {
        const storedData = this.getStoredData()

        this.statusSelectStore.setOptions(statusSelectOptions)

        if (storedData) {
            this.applyStoredData(storedData)
        } else {
            this.applyDefaultFilters()
        }
    }

    applyStoredData(storedData) {
        const {period, from, to, status, size} = storedData

        this.statusSelectStore.setValue(status || null)
        this.rangePickerStore.setDates([moment(from), moment(to)])

        if (!!size) {
            this.paginationStore.setPageSize(size)
        }

        const filter: Partial<FilterMomentType> = {}

        if (status) {
            filter.status = status
        }

        if (period && period !== PeriodType.Custom) {
            const {startDate, endDate} = getDates(period)
            filter.from = startDate
            filter.to = endDate
        } else if (from && to) {
            filter.from = moment(from)
            filter.to = moment(to)
        }

        this.setSelectedFilters(filter as FilterMomentType)
    }

    applyDefaultFilters() {
        this.rangePickerStore.setPeriod(PeriodType.Day)

        this.setSelectedFilters({
            from: this.rangePickerStore.startDate,
            to: this.rangePickerStore.endDate
        })
    }

    getStoredData() {
        const data = storage.get(this.storageKey)
        return tryParse(data)
    }

    onChange = (data) => {
        this.updateStorageData(data)
        this.setSelectedFilters(data)
    }

    setSelectedFilters = ({from, to, status}: FilterMomentType) => {
        this.chargebacksStore.setSelectedFilters({
            from: from?.format(SERVER_DATE_FORMAT),
            to: to?.format(SERVER_DATE_FORMAT),
            ...(!!status ? {status} : {})
        })
    }

    refresh = () => {
        this.chargebacksTableStore.reload()
    }

    updateStorageData = (data) => {
        const period = this.rangePickerStore.period
        storage.set(this.storageKey, JSON.stringify({...data, period}))
    }
}
