import translations from './translations'

translations.add('en', {
    title: 'Add new domain',
    provideDomainWhereTheFile: 'Provide the domain where the file will be hosted',
    inputTheTopLevelDomain: 'Input the top-level domain (e.g. dnapayments.com) or sub-domain\n' +
        '(e.g. pay.dnapayments.com) that you wish to enable Apple Pay for.',
    downloadDomainAssociationFile: 'Download domain association file',
    hostDownloadedDomainAssociationFile: 'Host the downloaded domain association file',
    youNeedToHostVerificationFileYouDownloaded: 'You\'ll need to host the verification file you downloaded above at your domain\n' +
        'in the following location:',
    cancel: 'Cancel',
    add: 'Add',
    errors: {
        domainRequired: 'Domain required'
    }
})
