import translations from './translations'

translations.add('sl', {
    title: 'Riadenie tímu',
    inviteTeammate: 'Pridať používateľa',
    role: 'Úloha',
    all: 'Všetko',
    active: 'Pozvaný',
    inactive: 'Prijaté pozvanie',
    statuses: {
        ok: 'Aktívny',
        invited: 'Pozvaný',
        validateEmail: 'Overiť e-mail',
        changePassword: 'Zmeň heslo',
        block: 'Zablokovaný'
    },
    authMethods: {
        password: 'Geslo',
        twoFAApp: '2FA (Aplikacija Overitelja)',
        twoFAEnforced: '2FA (Obvezno)'
    },
    notifications: {
        userSuccessfullyInvited: 'Používateľ bol úspešne pozvaný'
    }
})
