import translations from './translations'

translations.add('el', {
    title: 'Εξαγωγή Online πληρωμών',
    columns: 'Στήλες',
    cancel: 'Ακύρωση',
    ok: 'Εξαγωγή',
    all: value => `Όλα (${value})`,
    custom: 'Τροποιποιημένο'
})
