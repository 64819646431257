import React from 'react'
import moment from 'moment'
import {ColumnsType} from 'antd/es/table'
import {ChargebackType} from '@/api'
import {Currencies, ChargebackStatus} from '@/constants'
import {getAmountWithCurrency} from '@/utils'
import {CardSchemeIcon, DateTimeLabel, TableStatus} from '@/components'
import {TagType} from '@/components/dumb/Tag/props'
import {DATE_FORMAT} from '@/stores/chargebacks/ChargebacksTableStore/constants'

import appTranslations from '@/translations'
import translations from '@/stores/chargebacks/ChargebacksTableStore/translations'

export const getColumns = (): ColumnsType<ChargebackType> => {
    return [
        {
            title: translations().amount,
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            width: 150,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().chargebackDate,
            dataIndex: 'chargebackDate',
            key: 'chargebackDate',
            align: 'left',
            render: (date) => moment(date).format(DATE_FORMAT)
        },
        {
            title: translations().transactionDate,
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            render: (date) => <DateTimeLabel date={date} dateFormat={DATE_FORMAT} />
        },

        {
            title: translations().status,
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            width: 250,
            render: (v: ChargebackStatus) => {
                return (
                    <TableStatus
                        statusProps={{
                            status: appTranslations().constants.chargebackStatus[v] || v,
                            type:
                                v === ChargebackStatus['chargeback-reversal']
                                    ? TagType.Success
                                    : TagType.Default,
                            iconType: 'refund'
                        }}
                    />
                )
            }
        },

        {
            title: translations().reason,
            dataIndex: 'reasonCodeCategory',
            key: 'reasonCodeCategory',
            render: (v) => appTranslations().constants.reasonCodeCategory[v] || v
        },

        {
            title: translations().paymentMethod,
            key: 'paymentMethod',
            dataIndex: 'paymentMethod',
            render: (_, object) => (
                <span className='nowrap'>
                    <CardSchemeIcon type={object.cardScheme} />
                    &nbsp;
                    {object.cardMask}
                </span>
            )
        }
    ]
}
