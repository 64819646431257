import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    download: string
    perDay: string
    perTransaction: string
    description1: string
    description2: string
}>()
