import React from 'react'
import {Collapse as AntCollapse, Form} from 'antd'
import {observer} from 'mobx-react'

import {Collapse, Input, Select} from '@/components'
import {SelectWithTwoLabels} from '@/components/dumb/SelectWithTwoLabels'
import {FilterFormFieldType} from '@/types'

import {FilterFormProps} from './props'
import styles from './styles.scss'
import {EXPAND_FILTER, EXPANDED_FILTER_NAME, NAME} from '@/constants/playwright-ids'

const {Panel} = AntCollapse

export const FilterForm: React.FC<FilterFormProps> = observer(
    ({formId, form, fields, onFinish, onValuesChange}) => {
        return (
            <Form
                id={formId}
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                validateTrigger='onBlur'
            >
                <Collapse>
                    {fields.map((field) => {
                        if (field.isHidden) return null

                        return (
                            <Panel
                                className={styles.panel}
                                header={field.label}
                                key={field.name}
                                data-test-id={EXPAND_FILTER + '_' + field.name}
                            >
                                <Form.Item
                                    className={styles.formItem}
                                    name={field.name}
                                    data-test-id={EXPANDED_FILTER_NAME + '_' + field.name}
                                    rules={field.rules}
                                >
                                    {renderField(field)}
                                </Form.Item>
                            </Panel>
                        )
                    })}
                </Collapse>
            </Form>
        )
    }
)

const renderField = (field: FilterFormFieldType) => {
    const {name, label, type, placeholder, data, onChange} = field
    const _placeholder = placeholder || label

    switch (type) {
        case 'select':
            return (
                <Select
                    className={styles.select}
                    bordered={false}
                    data-test-id={NAME}
                    options={data?.options || []}
                    placeholder={placeholder}
                    onChange={onChange}
                />
            )
        case 'selectWithTwoLabels':
            return (
                <SelectWithTwoLabels
                    className={styles.select}
                    bordered={false}
                    data-test-id={NAME}
                    optionsWithTwoLabels={data?.optionsWithTwoLabels || []}
                    placeholder={_placeholder}
                    onChange={onChange}
                    showSearch
                />
            )
    }

    return (
        <Input
            className={styles.input}
            data-test-id={NAME}
            placeholder={_placeholder}
            onChange={onChange}
        />
    )
}
