import translations from './translations'

translations.add('ro', {
    welcome: 'Bun venit la DNA Payments',
    merchantPortal: 'Portalul comerciantului',
    startTutorial: 'Începeți tutorialul',
    description1: 'Portalul nostru pentru comercianți este o platformă puternică care vă ajută să monitorizați, urmăriți și gestionați fluxul de lucru, tranzacțiile, rambursările, litigiile de plată și compensările - toate în timp real.',
    description2: 'Vă recomandăm să parcurgeți tutorialul nostru pentru a descoperi cum să utilizați la maxim caracteristicile și funcționalitatea Portalului nostru pentru comercianți.',
    skipTutorial: 'Săriți peste tutorial',
    back: 'Înapoi',
    close: 'Închideți',
    next: 'Următorul',
    open: 'Deschide dialogul',
    skip: 'Sari peste'
})
