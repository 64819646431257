import translations from './translations'

translations.add('sl', {
    title: 'Dodaj Dvojno Preverbo Identitete',
    updateTitle: 'Posodobi Dvojno Preverbo Identitete',
    recoveryCodes: 'Obnovitveni kodi',
    selectFrequency: 'Izberite pogostost',
    configureApp: 'Konfigurirajte aplikacijo za preverjanje pristnosti',
    cancel: 'Prekliči',
    back: 'Nazaj',
    next: 'Naprej',
    update: 'Posodobi',
    submit: 'Pošlji'
})
