import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    freezeYourCardTemporarily: string
    indefinitely: string
    oneHour: string
    twentyFourHour: string
    customPeriod: string
    cancel: string
    freezeCard: string
}>()
