import {parseTextResult} from 'back-connector'
import {saveAs} from 'file-saver'
import {getWithAuth} from '@/api'
import {TFetchInvoicesParams, TFetchInvoicesResponse} from '@/stores/invoices/models'
import {openErrorNotification} from '@/utils'

export const getInvoices = (request: TFetchInvoicesParams) =>
    getWithAuth<{data: TFetchInvoicesResponse[]; totalCount: number}>('/oppapi/v1/invoices', request)

export const fetchInvoice = async (id, filename) => {
    const response = await getWithAuth(`/oppapi/v1/invoices/${id}`, {}, [], {
        parseResponse: async res => {
            if (res.status !== 200) {
                const error = await parseTextResult(res)
                throw new Error(error.message)
            }
            const blobFile = await res.blob()
            return blobFile
        }
    })
    if (response.error) {
        return openErrorNotification(response.error.message)
    }
    saveAs.saveAs(await response.result, filename)
}
