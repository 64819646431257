import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    initialCheck: string
    initialCheckDescription: string
    actionRequired: string
    processTakesDays: string
    documentsUpload: string
    documentsUploadDescription: string
    finalCheck: string
    finalCheckDescription: string
    agreement: string
    activation: string
    submit: string
    cancel: string
    initialCheckSuccessMessage: string
    thankYouMessage: string
    back: string
}>()

