import {
    ChargebacksRequestType,
    ChargebacksResponseType,
    ChargebackInfoType,
    getWithAuth
} from '@/api'

export * from './models'

export const getChargebacks = (request: ChargebacksRequestType) =>
    getWithAuth<ChargebacksResponseType>('/oppapi/v1/chargebacks', request)

export const getChargebackDetails = (id: string) =>
    getWithAuth<ChargebackInfoType[]>(`/oppapi/v1/chargebacks/${id}`)
