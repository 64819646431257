import translations from './translations'

translations.add('ro', {
    areYouSureYouWantTo: (c: boolean) =>
        `Sunteți sigur că doriți să ${c ? 'activați' : 'dezactivați'} metoda de plată`,
    yes: 'Da',
    no: 'Nu',
    active: 'Activ',
    disabled: 'Dezactivat'
})
