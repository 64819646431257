import {injectable} from 'inversify'
import {makeAutoObservable} from 'mobx'
import {PaginationContainerStoreType} from '@/components/containers'
import {DEFAULT_PAGE_SIZE} from '@/constants'

@injectable()
export class PaginationStore implements PaginationContainerStoreType {
    constructor({pageNumber}: {pageNumber: number} = {pageNumber: 1}) {
        this.pageNumber = pageNumber
        makeAutoObservable(this)
    }

    public pageNumber = 1
    public pageSize = DEFAULT_PAGE_SIZE
    public total = 0

    public setPageNumber = pageNumber => {
        this.pageNumber = pageNumber
    }

    public setPageSize = pageSize => {
        this.pageNumber = 1
        this.pageSize = pageSize
    }

    public setTotal = total => (this.total = total)

    public get paging() {
        return {
            page: this.pageNumber,
            size: this.pageSize
        }
    }
}
