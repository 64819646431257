import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Col, Row, Typography, Space} from 'antd'
import {useScreenType} from '@/hooks'
import {Button} from '@/components'
import {TwoFAStoreInterface} from '@/pages/Profile/components'
import {
    TwoFAModal,
    TwoFADisableModal,
    TwoFADevices,
    TwoFAEmailConfirmationModal,
    TwoFALabel
} from '@/pages/Profile/components/TwoFA/components'

import translations from './translations'
import styles from './styles.scss'
import {
    CONFIGURE_ENABLE_TWO_FA_BUTTON,
    DISABLE_TWO_FA_BUTTON,
    TWO_FA_LABEL
} from '@/constants/playwright-ids'

export const TwoFAStoreSymbol = Symbol('TwoFAStoreSymbol')

export const TwoFA = observer(() => {
    const {isMobile, isTablet} = useScreenType()

    const {
        isEnabled,
        isLoading,
        sendEmailOtp,
        setIsModalOpen,
        setIsDisableModalOpen,
        getTwoFAUserSettings
    } = useInjection<TwoFAStoreInterface>(TwoFAStoreSymbol)

    useEffect(() => {
        getTwoFAUserSettings()
    }, [])

    return (
        <>
            <Row gutter={[16, 16]} align={'middle'}>
                <Col xs={24} md={24} lg={12}>
                    <Typography.Title level={4}>
                        <span className={styles.title} data-test-id={TWO_FA_LABEL}>
                            {translations().title}
                        </span>
                        <TwoFALabel isEnabled={isEnabled} isLoading={isLoading} />
                    </Typography.Title>
                    <Typography.Text>{translations().text}</Typography.Text>
                </Col>

                <Col xs={24} md={24} lg={8} offset={isMobile || isTablet ? 0 : 4}>
                    <Space>
                        <Button
                            className={styles.button}
                            data-test-id={CONFIGURE_ENABLE_TWO_FA_BUTTON}
                            loading={isLoading}
                            onClick={() => (isEnabled ? setIsModalOpen(true) : sendEmailOtp())}
                        >
                            {isEnabled ? translations().configure : translations().enable}
                        </Button>

                        {isEnabled && (
                            <Button
                                className={styles.button}
                                danger
                                onClick={() => setIsDisableModalOpen(true)}
                                data-test-id={DISABLE_TWO_FA_BUTTON}
                            >
                                {translations().disable}
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>

            <TwoFADevices className={styles.devices} />

            <TwoFAModal />
            <TwoFADisableModal />
            <TwoFAEmailConfirmationModal />
        </>
    )
})
