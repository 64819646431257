import translations from './translations'

translations.add('ro', {
    labels: {
        email: 'E-mail',
        firstName: 'Prenume',
        lastName: 'Nume de familie',
        store: 'Magazin',
        status: 'Statut',
        authMethod: 'Metodă de Autentificare',
        active: 'Activ'
    }
})
