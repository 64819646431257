import translations from './translations'

translations.add('ro', {
    title: 'Exporturi',
    documentType: 'Tipul documentului',
    id: 'ID',
    fileName: 'Numele fișierului',
    dateRequested: 'Data solicitată',
    lastUpdatedDate: 'Ultima actualizare',
    status: 'Stare',
    statuses: {
        STARTED: 'Început',
        PROCESSING: 'În desfășurare',
        QUEUED: 'În așteptare',
        FINISHED: 'Terminat',
        FAILED: 'Eșuat'
    }
})
