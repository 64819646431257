import React from 'react'
import {DatePicker} from 'antd'
import {RangePickerProps} from '@/components/dumb/RangePicker/props'
import { MONTH_FORMAT } from '@/constants'
import styles from './styles.scss'

const {RangePicker} = DatePicker

export const MonthPicker: React.FC<RangePickerProps> = (props) => {

    return (
        <>
            <div className={styles.monthPickerWrapper}>
                <RangePicker
                    allowClear={false}
                    picker="month"
                    format={MONTH_FORMAT}
                    value={[props.startDate, props.endDate]}
                    onChange={props.onMonthsChange}
                />
            </div>
        </>
    )
}
