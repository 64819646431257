import {injectable} from 'inversify'
import {action, computed, makeObservable} from 'mobx'

import {TerminalType} from '@/constants'
import {TSelectContainerStore} from '@/components/containers/SelectContainer/TSelectContainerStore'
import {SelectItemWithTwoLabels} from '@/components/dumb/SelectWithTwoLabels/models'
import {StoreMidTerminalFilterStore} from './StoreMidTerminalFilterStore'

@injectable()
export class PosStoreSelectStore implements TSelectContainerStore<SelectItemWithTwoLabels> {
    protected _storeMidTerminalFilterStore = new StoreMidTerminalFilterStore({
        terminalType: TerminalType.POS
    })

    get value() {
        return this._storeMidTerminalFilterStore.selectedStore
    }

    get options() {
        return this._storeMidTerminalFilterStore.storesAsSelectItemsWithAll
    }

    get loadingState() {
        return this._storeMidTerminalFilterStore.loadingState
    }

    get requestForServer() {
        return this._storeMidTerminalFilterStore.requestForServer
    }

    constructor() {
        makeObservable(this, {
            value: computed,
            options: computed,
            loadingState: computed,
            requestForServer: computed,
            setValue: action.bound
        })
    }

    clear() {
        this._storeMidTerminalFilterStore.clear()
    }

    setValue(value: string): void {
        this._storeMidTerminalFilterStore.setStore(value)
    }

    setOptions(): void {
        // not needed, but required in interface
    }
}
