export class AppError extends Error {
    code: AppErrorCodeType
    constructor(code: AppErrorCodeType, message: string) {
        super(message)
        this.name = 'AppError'
        this.code = code
    }
}

export type AppErrorCodeType = 'MAX_ATTEMPT_REACHED' | 'REQUEST_ERROR' | 'UNKNOWN_ERROR'
