import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    continue: string
    resend: {
        question: string
    }
    description: (s: number, e: string) => string
}>()
