import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    showAll: string
    previousRangeData: string
    currentRangeData: string
    statuses: {
        AUTH: string
        CHARGE: string
        REFUND: string
        REJECT: string
    }
    dataTypes: {
        amount: string
        count: string
    }
    week: string
}>()

