import React from 'react'
import translations from './translations'

translations.add('en', {
    messageTextPreview: 'Message text preview:',
    message: (name) => `You received this message with a payment request from ${name ? name : 'Sender Name'}. Please follow the link to start the payment:`,
    emailMessage: (senderName, recipientName) => (
        <div>
            <div>Dear {recipientName}</div>
            <div>{senderName ? senderName : 'Sender Name'} is requesting you to make a payment
                Please follow the link below and select your preferred payment method
            </div>
        </div>
    )
})
