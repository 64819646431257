import {Col, Row} from 'antd'
import React from 'react'
import translations from './translations'
import {POSFilterTitleProps} from './props'
import {CLEAR_FILTER, CLOSE_FILTER} from '@/constants/playwright-ids'

export const FilterTitle: React.FC<POSFilterTitleProps> = ({onClearFilters}) => {
    return (
        <Row justify={'space-between'}>
            <Col>
                <span>{translations().filters}</span>
            </Col>
            <Col>
                <a
                    href="#"
                    onClick={onClearFilters}
                    data-test-id={CLEAR_FILTER}
                >{translations().clearFilter}</a>
            </Col>
        </Row>
    )
}
