import translations from './translations'

translations.add('en', {
    amount: 'Amount',
    chargebackDate: 'Chargeback Date',
    transactionDate: 'Transaction Date',
    currency: 'Currency',
    reasonCode: 'Reason Code',
    merchantName: 'Merchant Name',
    reason: 'Reason',
    status: 'Status',
    paymentMethod: 'Payment method'
})
