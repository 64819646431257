import React from 'react'
import translations from './translations'

translations.add('el', {
    name: {
        ON_EACH_LOGIN: (
            <>
                <strong>Κάθε σύνδεση:</strong> Θα ζητήσουμε έναν κωδικό επαλήθευσης
            </>
        ),
        TRUSTED_DEVICE: (
            <>
                <strong>Μια φορά ανά συσκευή:</strong> Θα ζητήσουμε έναν κωδικό επαλήθευσης μόνο μια
                φορά κάθε 30 ημέρες σε μια αξιόπιστη συσκευή
            </>
        )
    },
    disabled: 'Απενεργοποιημένο'
})
