import React, {useEffect} from 'react'
import {subscribe} from 'dna-react-ioc'

import {RouterStore, RouterStoreSymbol} from './RouterStore'
import {PaymentMethodType} from '@/constants'
import {ROUTES} from './routes'
import translations from '@/translations'

export const getRouterStore = () => {
    return subscribe<RouterStore>(RouterStoreSymbol)
}

export const withTitle = (Component: React.ComponentType<any>, title?: string) => {
    // eslint-disable-next-line react/display-name
    return (props: any) => {
        useEffect(() => {
            const _title = title ? title + ' | ' : ''
            document.title = _title + translations().siteTitle
        }, [])

        return <Component {...props} />
    }
}

export const getPaymentMethodDetailsRoute = (type: PaymentMethodType) => {
    return ROUTES.paymentMethodDetails.replace(':type', type)
}
