import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    labels: {
        email: string
        firstName: string
        lastName: string
        store: string
        status: string
        authMethod: string
        active: string
    }
}>()
