import translations from './translations'

translations.add('ro', {
    bankingDate: 'Data Tranzacției Bancare',
    amount: 'Sumă',
    currency: 'Monedă',
    captureMethod: 'Capture method',
    status: 'Stare',
    documentId: 'ID Document',
    operation: 'Operațiune',
    transactionType: 'Tipul Tranzacției',
    chargebackDate: 'Data Retragerii',
    cardMask: 'Mască de card',
    cardScheme: 'Schemă de card',
    terminalId: 'ID terminal',
    transactionId: 'ID tranzacție',
    reasonCode: 'Codul Motivului',
    arn: 'ARN',
    previousDocumentId: 'ID-ul Documentului Anterior',
    chargebacksDetails: 'Detalii Retrageri',
    originalTransactionDetails: 'Detalii Tranzacție Originală',
    chargebackHistory: 'Istoric Retrageri',
    chargebackStatus: 'Stare de refuz',
    financialStatus: 'Stare financiară'
})
