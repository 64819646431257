import React from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {Tooltip} from 'antd'
import {Button, Icon} from '@/components'
import styles from './styles.scss'

import translations from './translations'
import {SHARE_BY_EMAIL, SHARE_BY_SMS} from '@/constants/playwright-ids'

type Props = {
    onEmailClick: () => void
    onSmsClick: () => void
}

export const ShareButtons: React.FC<Props> = observer(({onEmailClick, onSmsClick}) => {
    return (
        <div className={classNames(styles.buttonContainer)}>
            <Tooltip title={translations().email} placement={'top'} trigger={'hover'}>
                <Button
                    className={classNames(styles.btn, styles.email)}
                    type={'primary'}
                    icon={<Icon className={styles.icon} type={'email'}/>}
                    onClick={onEmailClick}
                    data-test-id={SHARE_BY_EMAIL}
                />
            </Tooltip>

            <Tooltip title={translations().sms} placement={'top'} trigger={'hover'}>
                <Button
                    className={classNames(styles.btn)}
                    type={'primary'}
                    icon={<Icon className={styles.icon} type={'sms'}/>}
                    onClick={onSmsClick}
                    data-test-id={SHARE_BY_SMS}
                />
            </Tooltip>
        </div>
    )
})
