import React, { useState } from 'react'
import {Button} from 'antd'
import {DownloadOutlined} from '@ant-design/icons'
import {ColumnsType} from 'antd/es/table'

import { openErrorNotification } from '@/utils'
import {TJob} from '@/pages/Exports/models/TJob'
import {JobStatusEnum} from '@/pages/Exports/models/JobStatusEnum'
import {downloadFile} from '@/stores/exports/services/fetchers'
import {DateTimeLabel} from '@/components'
import translations from '../translations'

export const getColumns = (): ColumnsType<TJob> => {
    return [
        {
            title: translations().id,
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            width: 160
        },
        {
            title: translations().fileName,
            dataIndex: 'fileName',
            key: 'fileName',
            width: 380
        },
        {
            title: translations().documentType,
            dataIndex: 'name',
            key: 'name',
            width: 200
        },
        {
            title: translations().dateRequested,
            dataIndex: 'createdDate',
            key: 'createdDate',
            width: 140,
            render: (item) => <DateTimeLabel date={item}/>
        },
        {
            title: translations().lastUpdatedDate,
            dataIndex: 'lastUpdateDate',
            key: 'lastUpdateDate',
            width: 140,
            render: (item) => <DateTimeLabel date={item}/>
        },
        {
            title: translations().status,
            dataIndex: 'status',
            key: 'status',
            render: (item) => translations().statuses[item] || item,
            width: 100
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 50,
            render: (item, object) => {
                return object.status === JobStatusEnum.FINISHED && object.url && (
                    <DownloadButton object={object}/>
                )
            }
        }
    ]
}

const DownloadButton = (props: { object: TJob }) => {
    const [isLoading, setIsLoading] = useState(false)
    const download = async () => {
        setIsLoading(true)
        try {
            await downloadFile(props.object)
        } catch (err) {
            openErrorNotification(err?.message)
        }
        setIsLoading(false)
    }

    return (
        <Button
            type={'link'}
            onClick={download}
            size={'small'}
            loading={isLoading}
            icon={<DownloadOutlined />}
        />
    )
}
