import React from 'react'
import {Form} from 'antd'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {useTeamManagementFiltersStore} from '@/stores/team-management/TeamManagementFiltersStore'
import {TeamManagementFilterForm} from '@/pages/TeamManagement/components/TeamManagementFilterForm'
import {FilterButtonPopover} from '@/components'
import {TeamManagementFilterModel} from '@/pages/TeamManagement/components/TeamManagementFilter'
import {TeamManagementFilterProps} from './props'
import styles from './styles.scss'
import {TEAMMATE_FILTER_BUTTON} from '@/constants/playwright-ids'

export const TeamManagementFilter: React.FC<TeamManagementFilterProps> = observer(
    ({isStatusAvailable, isInvitedTabActive}) => {
        const [form] = Form.useForm<TeamManagementFilterModel>()
        const store = useTeamManagementFiltersStore()

        const formName = 'team-management-popover-filters'

        const onFinish = (values: TeamManagementFilterModel) => {
            store.onFinish(values)
        }

        const onClearFilters = () => {
            form.resetFields()
            store.clearFilters()
        }

        const onStoreChange = (value) => {
            store.setStore(value)
            form.setFieldsValue({terminalId: ''})
        }

        const calcCount = () => {
            if (store.count <= 0) {
                return store.count
            }

            if (isInvitedTabActive) {
                if (store.formValues?.status) {
                    return store.count - 1
                }

                if (store.formValues?.authMethod) {
                    return store.count - 1
                }
            }

            return store.count
        }

        return (
            <FilterButtonPopover
                data-test-id={TEAMMATE_FILTER_BUTTON}
                buttonClassName={classNames(styles.filter)}
                count={calcCount()}
                formId={formName}
                onClearFiltersClick={onClearFilters}
                onClick={store.openFilter}
                open={store.open}
                setOpen={store.setOpen}
                onCloseClick={store.onFilterClose}
                popoverContent={
                    <TeamManagementFilterForm
                        formId={formName}
                        form={form}
                        values={store.formValues}
                        onFinish={onFinish}
                        onStoreChange={onStoreChange}
                        onStatusChange={store.setStatus}
                        onAuthMethodChange={store.setAuthMethod}
                        stores={store.selectStores}
                        authMethods={store.authMethods}
                        onFormValuesChange={store.onFormValuesChange}
                        statuses={isStatusAvailable ? store.selectStatuses : []}
                        isInvitedTabActive={isInvitedTabActive}
                    />
                }
            />
        )
    }
)
