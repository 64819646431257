import {SelectItem} from '@/components/dumb/Select'
import {TransactionDirection, TransactionType} from '@/stores'
import translations from '@/pages/business-account/AccountInfo/components/AccountTransactionsFilter/components/AccountTransactionsFilterForm/translations'

export const getSelectTransactionTypesOptions = (): SelectItem[] => {
    return [
        {
            label: translations().all,
            value: ''
        },
        {
            label: translations().transactionTypes.deposit,
            value: TransactionType.deposit
        },
        {
            label: translations().transactionTypes.withdraw,
            value: TransactionType.withdraw
        },
        {
            label: translations().transactionTypes.payment,
            value: TransactionType.payment
        },
        {
            label: translations().transactionTypes.transfer,
            value: TransactionType.transfer
        }
    ]
}

export const getSelectDebitCreditOptions = (): SelectItem[] => {
    return [
        {
            label: translations().all,
            value: ''
        },
        {
            label: translations().debitCredit.CREDIT,
            value: TransactionDirection.CREDIT
        },
        {
            label: translations().debitCredit.DEBIT,
            value: TransactionDirection.DEBIT
        }
    ]
}
