import React from 'react'
import translations from './translations'

translations.add('es', {
    title: 'Acceder a su cuenta',
    selectEntity: 'Seleccione su entidad',
    ourSupportTeamIsHereToHelp: (message: string) => (
        <>
            {message ? (
                <>
                    {message}
                    <br />
                </>
            ) : (
                <>
                    No hemos podido reconocer didn&apos;t los datos de acceso you&apos;re que está
                    utilizando. Por favor, compruebe si está utilizando el correo electrónico con el
                    que se registró.
                </>
            )}
            Nuestro equipo de soporte está aquí para ayudarte:{' '}
            <a href={'https://dnapayments.com/online-quote#2'} target={'_blank'} rel={'noreferrer'}>
                Soporte
            </a>
        </>
    ),
    successfullyUpdatedDefaultMerchant: 'Comerciante predeterminado actualizado correctamente',
    sessionExpired: 'Su sesión ha expirado. Vuelva a iniciar sesión.',
    permissionErrorText: 'No tiene permiso para iniciar sesión',
    back: 'Atrás para iniciar sesión'
})
