import translations from './translations'

translations.add('el', {
    labels: {
        newPaymentLink: 'Νέος σύνδεσμος πληρωμής',
        date: 'Ημερομηνία',
        customer: 'Πελάτης',
        transactionType: 'Τύπος συναλλαγής',
        amount: 'Ποσό',
        customerName: 'Ονομα πελάτη',
        name: 'Ονομα',
        description: 'Περιγραφή',
        nameOfTheServiceOrItemYouProvide: 'Όνομα της υπηρεσίας ή του αντικειμένου που παρέχετε',
        linkExpiration: 'Λήξη συνδέσμου',
        stores: 'Καταστήματα',
        theNewPaymentLinkIsCreatedAndCopiedToClipboard: 'Ο νέος σύνδεσμος πληρωμής δημιουργήθηκε και αντιγράφηκε στο πρόχειρο',
        recurring: 'Αρχική πληρωμή (Επαναλαμβανόμενη)'
    },
    buttons: {
        createNewLink: 'Δημιουργία νέου συνδέσμου',
        close: 'Κλείσιμο'
    },
    errors: {
        storeRequired: 'Απαιτείται κατάστημα',
        orderNumberRequired: 'Απαιτείται αριθμός παραγγελίας',
        descriptionRequired: 'Απαιτείται περιγραφή',
        linkExpirationRequired: 'Απαιτείται λήξη του συνδέσμου',
        customerNameRequired: 'Απαιτείται όνομα πελάτη'
    },
    ranges: {
        hour24: '24 ώρες',
        hour48: '48 ώρες',
        week1: '1 εβδομάδα',
        month1: '1 μήνα'
    },
    tooltips: {
        recurring: 'Αυτό θα χαρακτηριστεί ως η πρώτη σε μια σειρά μελλοντικών πληρωμών που θα ξεκινήσουν από τον έμπορο ή τον πελάτη.'
    },
    selectDate: 'Επιλέξτε ημερομηνία'
})
