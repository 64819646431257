import React from 'react'
import {Divider as AntDivider, Drawer, Layout} from 'antd'
import classNames from 'classnames'
import {ROUTES} from '@/router/routes'
import {Logo, Burger, SiderMenu} from '@/components'
import {getPermissions, navigateUserAfterLogin} from '@/stores/auth/services'
import {MerchantSelect} from '@/layouts/MainLayout/components'
import {Theme} from '@/constants'
import {SiderProps} from './props'
import styles from './styles.scss'
import {MENU} from '@/constants/playwright-ids'

const siderExpandedWidth = 304
const siderCollapsedWidth = 64

const Divider = () => <AntDivider className={styles.divider} />

export const Sider: React.FC<SiderProps> = (props) => {
    const {isMobile, isCollapsed, setCollapsed, menuItems, openKeys, selectedKeys, className} = props

    const Header = () => <div className={styles.header}>
        <Burger onClick={() => setCollapsed(!isCollapsed)}/>
        {!isCollapsed && <Logo
            onClick={() => navigateUserAfterLogin(getPermissions())}
            href={ROUTES.home}
            className={styles.logos}
            type={Theme.LIGHT}
            isBlank={false}
        />}
    </div>

    const Content = () => <div className={classNames(styles.content, {[styles.mobile]: isMobile})}>
        <Header/>
        <MerchantSelect isCollapsed={isCollapsed} />
        <Divider/>
        <SiderMenu
            menuItems={menuItems}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onSelect={() => {
                if (isMobile) {
                    setCollapsed(true)
                }
            }}
        />
    </div>

    return isMobile ? <Drawer
        open={!isCollapsed}
        placement="left"
        className={classNames(styles.drawer, className)}
        closable={false}
        width={siderExpandedWidth}
        onClose={() => setCollapsed(false)}
    >
        <Content/>
    </Drawer> : <Layout.Sider
        className={classNames(styles.sider, className)}
        collapsible
        collapsed={isCollapsed}
        trigger={null}
        width={siderExpandedWidth}
        collapsedWidth={siderCollapsedWidth}
        data-test-id={MENU}
    >
        <Content/>
    </Layout.Sider>
}
