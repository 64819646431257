import React from 'react'
import translations from './translations'

translations.add('ro', {
    back: 'Schimbă banca',
    cancel: 'Anulează',
    continue: 'Continuă',
    infoText: `Vom solicita băncii dvs. să ne ofere numele dvs., codul sortare și numărul de cont. Vom utiliza aceste detalii doar în cazul în care solicitați o rambursare pentru această tranzacție.`,
    permissionText: 'Ecospend Technologies Limited are nevoie de permisiunea dvs. pentru a iniția în mod securizat o comandă de plată de la banca dvs.',
    privacyPolicyText: (termsUrl: string, privacyUrl: string) => <>
        Dând clic pe Continuă, acceptați <a href={termsUrl} target="_blank" rel="noreferrer">termenii și condițiile noastre</a> și <a href={privacyUrl} target="_blank" rel="noreferrer">politica de confidențialitate</a>
    </>
})
