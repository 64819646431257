import {Currencies} from '@/constants'

export default {
    version: '1.0.1',

    // TODO move all of these configs to CI/CD variables
    api: {
        baseUrl: 'https://portal.dnapayments.com/oppapi/'
    },
    auth: {
        baseUrl: 'https://portal.dnapayments.com/oauth/',
        user: {
            scope: 'webapi usermanagement email_send verification statement statistics virtual_terminal',
            storageKey: 'auth',
            userInfoKey: 'userInfo'
        },
        idleTimeout: 10
    },
    urls: {
        signup: 'https://signup.dnapayments.com'
    },
    currency: Currencies.GBP
}
