import React from 'react'
import {Layout, Divider} from 'antd'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Button} from '@/components/dumb/Button'
import {
    PayByLinkEditPaymentLinkModalContainerStoreSymbol,
    PayByLinkLinkDetailsModalContainerStoreSymbol,
    PayByLinkNewPaymentLinkModalContainerStoreSymbol
} from '@/pages/PayByLink'
import {
    PaymentLinkDetailsModal,
    NewPaymentLinkModal,
    EditPaymentLinkModal
} from '@/pages/components'
import {THeaderStore, LanguageSelect, ProfilePopover} from '@/components'
import {hasPermissions} from '@/stores/auth/services/utils'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {GoogleManagerTagClassNames} from '@/constants'

import translations from './translations'
import {HeaderProps} from './props'
import styles from './styles.scss'
import {NEW_PAYMENT_LINK_BUTTON} from '@/constants/playwright-ids'

const {Header: AntHeader} = Layout

export const HeaderStoreSymbol = Symbol('HeaderStore')

export const Header: React.FC<HeaderProps> = observer(({isMobile, children}) => {
    const store = useInjection<THeaderStore>(HeaderStoreSymbol)

    return (
        <AntHeader className={classNames(styles.header, {[styles.mobile]: isMobile})}>
            {children}
            <div className='grow' />
            {hasPermissions([PermissionsMap.payment_links.full]) && (
                <Button
                    type={'primary'}
                    className={GoogleManagerTagClassNames.header.newPaymentLink}
                    onClick={() => store.openNewPaymentLinkModal()}
                    data-test-id={NEW_PAYMENT_LINK_BUTTON}
                >
                    <span className={GoogleManagerTagClassNames.header.newPaymentLink}>
                        +{' '}
                        {isMobile
                            ? translations().newPaymentLinkInMobile
                            : translations().newPaymentLink}
                    </span>
                </Button>
            )}
            <Divider type='vertical' className={styles.divider} />

            <LanguageSelect className={styles.language} />

            <ProfilePopover onLogOut={store.logout} />

            <NewPaymentLinkModal
                injectableIdentifier={PayByLinkNewPaymentLinkModalContainerStoreSymbol}
            />

            <EditPaymentLinkModal
                injectableIdentifier={PayByLinkEditPaymentLinkModalContainerStoreSymbol}
            />

            <PaymentLinkDetailsModal
                injectableIdentifier={PayByLinkLinkDetailsModalContainerStoreSymbol}
            />
        </AntHeader>
    )
})
