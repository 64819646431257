import {computed, makeObservable} from 'mobx'
import {injectable} from 'inversify'
import {ApiResponse} from 'back-connector'

import {BaseDataStore} from '@/stores/shared/BaseDataStore'
import {TerminalType} from '@/constants'
import {getUniqueValues, getWithAll} from '@/utils'

import {StoreWithTerminalsType} from '../types'
import {fetchAvailableStoresOfMerchant} from '../api'
import {convertStoresToSelectItems} from '../utils'
import {
    getEcomMostUsedDefaultCurrency,
    getPosMostUsedDefaultCurrency
} from '../../handbooks/services'

@injectable()
export class AvailableStoresDataStore extends BaseDataStore<StoreWithTerminalsType[]> {
    protected _name = 'AvailableStoresDataStore'

    get ecomStores() {
        return this.data.filter((el) => el.type === TerminalType.ECOM)
    }

    get ecomStoresAsSelectItems() {
        return convertStoresToSelectItems(this.ecomStores)
    }

    get ecomStoresAsSelectItemsWithAll() {
        return getWithAll(this.ecomStoresAsSelectItems)
    }

    get storesAsSelectItems() {
        return convertStoresToSelectItems(this.data)
    }

    get storesAsSelectItemsWithAll() {
        return getWithAll(this.storesAsSelectItems)
    }

    /**
     * These Merchant IDs (MIDs) are utilized to filter terminals from Way4,
     * enabling the identification of available terminals accessible to the current authorized user.
     */
    get mids() {
        return getUniqueValues(this.data.map((el) => el.mid))
    }

    get ecomMostUsedDefaultCurrency() {
        return getEcomMostUsedDefaultCurrency(this.data)
    }

    get posMostUsedDefaultCurrency() {
        return getPosMostUsedDefaultCurrency(this.data)
    }

    constructor() {
        super()

        makeObservable(this, {
            ...this.getAnnotationsMap(),
            ecomStores: computed,
            ecomStoresAsSelectItems: computed,
            ecomStoresAsSelectItemsWithAll: computed,
            storesAsSelectItems: computed,
            storesAsSelectItemsWithAll: computed,
            mids: computed,
            ecomMostUsedDefaultCurrency: computed,
            posMostUsedDefaultCurrency: computed
        })
    }

    protected async fetchData(): Promise<ApiResponse<StoreWithTerminalsType[]>> {
        return fetchAvailableStoresOfMerchant()
    }

    protected getDefaultData(): StoreWithTerminalsType[] {
        return []
    }
}
