import translations from './translations'
import React from 'react'

translations.add('sl', {
    title: 'Pay by Bank',
    description: (
        <>
            DNA Payments s hrdosťou ponúka obchodníkom možnosť platiť za tovar a služby priamo z ich
            bankového účtu prostredníctvom novej aplikácie Pay by Bank. Aplikácia Pay by Bank je
            nová možnosť online platby, ktorá umožňuje spotrebiteľom platiť pomocou bankovej
            aplikácie v telefóne. Je navrhnutý tak, aby uľahčil online platbu a zároveň využil
            všetku bezpečnosť podporovaných bánk.
            <br />
            <br />
            Najdôležitejšie je, že aplikácia Pay by Bank nie je samostatná aplikácia na stiahnutie
            alebo službu, do ktorej sa môžete zaregistrovať – funguje prostredníctvom bankovej
            aplikácie, ktorú už spotrebitelia používajú.
            <br />
            <br />
        </>
    )
})
