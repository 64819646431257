import translations from './translations'

translations.add('el', {
    cookies: 'Προτιμήσεις cookies',
    cookiesContent:
        'Χρησιμοποιούμε cookies στον ιστότοπό μας. Ορισμένα από αυτά είναι απαραίτητα, ενώ άλλα μας βοηθούν να βελτιώσουμε αυτόν τον ιστότοπο και την εμπειρία σας.',
    descriptions: {
        name: 'Ονομα',
        provider: 'Πάροχος',
        saveVisitorsPreferences:
            'Αποθηκεύει τις προτιμήσεις των επισκεπτών που έχουν επιλεγεί στο Cookie',
        cookieName: 'Όνομα cookie',
        cookieExpiry: 'Λήξη cookie',
        cookie: 'Cookie',
        ownerOfSite: 'Ιδιοκτήτης αυτού του ιστότοπου',
        cookieByGoogleAdvanced:
            'Cookie by Google χρησιμοποιείται για τον έλεγχο προηγμένων script και χειρισμού συμβάντων',
        purpose: 'Σκοπός',
        year: '1 χρόνος',
        googleTagManager: 'Google Tag Manager',
        googleLLC: 'Google LLC',
        twoYears: '2 χρόνια',
        privacyPolicy: 'Πολιτική Απορρήτου'
    }
})
