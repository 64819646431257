import translations from './translations'

translations.add('ro', {
    title: 'Procesul de înrolare',
    initialCheck: 'Verificarea inițială',
    initialCheckDescription: 'Întrebările de la manager pentru a clarifica detaliile afacerii dumneavoastră',
    actionRequired: 'Acțiune necesară',
    processTakesDays: 'Procesul durează 1 zi',
    documentsUpload: 'Încărcarea documentelor',
    finalCheck: 'Verificarea finală',
    agreement: 'Acord',
    activation: 'Activare',
    submit: 'Trimite',
    cancel: 'Anulare',
    initialCheckSuccessMessage: 'Thank you! We are now reviewing information you.',
    thankYouMessage: 'Thank you! We are now reviewing information you.',
    documentsUploadDescription: 'Vă rugăm să încărcați documentele de suport: dovada identității, dovada adresei și un extras de cont bancar',
    finalCheckDescription: 'Vă rugăm să furnizați informații suplimentare',
    back: 'Înapoi'
})
