import {ROUTES} from '@/router/routes'
import {ProductsMap} from '@/stores/auth/constants/products-map'

export const routesProductsMap = {
    [ROUTES.home]: [],
    [ROUTES.activation]: [],
    [ROUTES.paymentLinks]: [],
    [ROUTES.onlinePayments]: [],
    [ROUTES.posPayments]: [],
    [ROUTES.posAmexPayments]: [ProductsMap.amex],
    [ROUTES.settlements]: [],
    [ROUTES.virtualTerminal]: [],
    [ROUTES.reports]: [],
    [ROUTES.exports]: [],
    [ROUTES.profile]: [],
    [ROUTES.settings.developers]: [],
    [ROUTES.settings.checkout]: [],
    [ROUTES.settings.tariffs]: [],
    [ROUTES.teamManagement]: [],
    [ROUTES.businessAccount.accountInfo]: [],
    [ROUTES.businessAccount.cardsManagement]: [],
    [ROUTES.paymentMethods]: [],
    [ROUTES.paymentMethodDetails]: [],
    [ROUTES.billing.invoices]: [],
    [ROUTES.noPermissions]: []
}
