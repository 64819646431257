import React, {useCallback, useEffect, useRef} from 'react'
import {Spin, Table} from 'antd'
import {TableProps} from 'antd/es/table'

import {TableViewType} from '@/constants'
import {MobileTable} from '../MobileTable'

import {AdaptiveTableProps} from './props'
import styles from './styles.scss'

export const AdaptiveTable = <T extends {}>(props: AdaptiveTableProps<T>) => {
    const {
        isMobile,
        isInfinitePagination,
        isLoadingMore,
        tableViewType,
        dataSource = [],
        columns,
        rowKey,
        tableMinWidth = 1000,
        mobileTableProps,
        onSelectItem,
        onLoadMore
    } = props

    const tableProps: TableProps<T> = {
        columns,
        dataSource,
        pagination: false,
        rowKey: (item, index) => item[rowKey] + index,
        onRow: (item: T) => ({onClick: () => onSelectItem && onSelectItem(item)}),
        loading: mobileTableProps.isLoading
    }

    /**
     * The table's minimum height is 400px.
     * The combined height of the site header, POS payments header, and the site footer is 342px.
     */
    const tableMinHeight = 400
    const headerAndFooterHeight = 342
    const tableHeight = isInfinitePagination
        ? Math.max(window.innerHeight - headerAndFooterHeight, tableMinHeight)
        : 'auto'

    const tableRef = useRef<HTMLDivElement>()
    const isMobileExpanded =
        isMobile && mobileTableProps && tableViewType === TableViewType.EXPANDED

    const getTableBody = useCallback(
        () => tableRef.current?.querySelector('.ant-table-body'),
        [tableRef]
    )

    /**
     * handle scroll for antd table
     * The height of five POS payments in the table is approximately 300px.
     */
    useEffect(() => {
        const tableBody = getTableBody()

        const handleScroll = () => {
            const {scrollTop, scrollHeight, clientHeight} = tableBody
            const deltaHeight = 300
            const isAtEnd = scrollTop + clientHeight + deltaHeight >= scrollHeight

            if (isInfinitePagination && !isMobileExpanded && onLoadMore && isAtEnd) {
                onLoadMore()
            }
        }

        tableBody?.addEventListener('scroll', handleScroll)

        return () => {
            tableBody?.removeEventListener('scroll', handleScroll)
        }
    }, [isInfinitePagination, isMobileExpanded, getTableBody, onLoadMore])

    /**
     * handle scroll for mobile table
     * The height of five POS payments in the mobile table is approximately 1500px.
     */
    useEffect(() => {
        const handleScroll = () => {
            const {offsetHeight, offsetTop} = tableRef.current
            const deltaHeight = 1500
            const isAtEnd = window.scrollY + deltaHeight >= offsetTop + offsetHeight

            if (isInfinitePagination && isMobileExpanded && onLoadMore && isAtEnd) {
                onLoadMore()
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [tableRef, isInfinitePagination, isMobileExpanded, onLoadMore])

    /**
     * When the filter is changed or data is reloaded, the table will scroll to the top
     */
    useEffect(() => {
        const tableBody = getTableBody()
        if (isInfinitePagination && mobileTableProps.isLoading && tableBody) {
            tableBody.scrollTop = 0
        }
    }, [getTableBody, isInfinitePagination, mobileTableProps.isLoading])

    const render = () => {
        if (isMobileExpanded) {
            return <MobileTable dataSource={dataSource} rowKey={rowKey} {...mobileTableProps} />
        }

        if (isMobile) {
            return <Table<T> {...tableProps} scroll={{x: tableMinWidth, y: tableHeight}} />
        }

        return (
            <Table<T>
                {...tableProps}
                scroll={{x: tableMinWidth, y: tableHeight}}
                className={styles.tableRow}
            />
        )
    }

    return (
        <div className={styles.root} ref={tableRef}>
            {render()}
            {isInfinitePagination && isLoadingMore && (
                <div className={styles.loader}>
                    <Spin />
                </div>
            )}
        </div>
    )
}
