import 'reflect-metadata'
import {action, makeObservable, observable} from 'mobx'
import {inject, injectable} from 'inversify'
import {TableViewType} from '@/constants'
import {TDrawerContainerStore} from '@/components/containers/DrawerContainer/TDrawerContainerStore'
import {ChargebacksDetailsDrawerContainerStoreSymbol} from '@/pages/Chargebacks/components'
import {FilterType} from '@/stores/chargebacks/ChargebacksFilterStore'
import {TChargebacksStore} from '@/pages/Chargebacks'

@injectable()
export class ChargebacksStore implements TChargebacksStore {
    drawerStore: TDrawerContainerStore
    selectedFilters: FilterType = null
    mobileTableViewType = TableViewType.EXPANDED
    isChargebacksDetailsDrawerOpen: boolean = false

    constructor(
        @inject(ChargebacksDetailsDrawerContainerStoreSymbol) drawerStore: TDrawerContainerStore
    ) {
        this.drawerStore = drawerStore

        makeObservable(this, {
            selectedFilters: observable,
            mobileTableViewType: observable,

            setSelectedFilters: action.bound,
            setMobileTableViewType: action.bound
        })
    }

    setSelectedFilters = (selectedFilters: FilterType) => {
        this.selectedFilters = selectedFilters
    }

    setMobileTableViewType = (tableViewType: TableViewType) => {
        this.mobileTableViewType = tableViewType
    }
}
