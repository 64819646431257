import {PayByLinkStatus} from '@/constants'
import {SelectItem} from '@/components/dumb/Select'
import translations from '../translations'

export const statuses = [
    {
        label: translations().statuses.all,
        value: PayByLinkStatus.all
    },
    {
        label: translations().statuses.active,
        value: PayByLinkStatus.active
    },
    {
        label: translations().statuses.expired,
        value: PayByLinkStatus.expired
    },
    {
        label: translations().statuses.paid,
        value: PayByLinkStatus.paid
    },
    {
        label: translations().statuses.cancelled,
        value: PayByLinkStatus.cancelled
    },
    {
        label: translations().statuses.attempted,
        value: PayByLinkStatus.attempted
    },
    {
        label: translations().statuses.viewed,
        value: PayByLinkStatus.viewed
    }
] as SelectItem[]
