import translations from './translations'

translations.add('ro', {
    title: 'Blocarea temporară a cardului',
    freezeYourCardTemporarily: '- Blochează temporar cardul tău',
    indefinitely: 'La infinit',
    oneHour: '1 oră',
    twentyFourHour: '24 de ore',
    customPeriod: 'Perioadă personalizată',
    cancel: 'Anulare',
    freezeCard: 'Blocare temporară a cardului'
})
