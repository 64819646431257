import translations from './translations'
import React from 'react'

translations.add('el', {
    title: 'Google Pay',
    description: <>
        Το Google Pay επιτρέπει στους καταναλωτές να πληρώνουν για τα αγαθά και τις υπηρεσίες τους εύκολα και γρήγορα στο διαδίκτυο. Οι καταναλωτές αποθηκεύουν τα στοιχεία της κάρτας πληρωμής τους στο Google Wallet τους, τα οποία μπορούν να κληθούν στην συνέχεια από τις Android συσκευές τους ή από το πρόγραμμα περιήγησης Google Chrome.
        <br/><br/>
        Η DNA Payments με χαρά προσφέρει αυτόν τον τρόπο πληρωμής στους εμπόρους και μπορεί να ενεργοποιηθεί ή να απενεργοποιηθεί εδώ.
        <br/><br/>
    </>
})
