import React from 'react'
import {observer} from 'mobx-react-lite'
import {Grid, Spin, Typography} from 'antd'
import {useInjection} from 'dna-react-ioc'
import {DrawerContainer} from '@/components/containers'
import {ChargebacksDetails, TChargebacksDetailsStore} from '@/pages/Chargebacks/components'

import translations from './translations'
import styles from './styles.scss'

export const ChargebacksDetailsStoreSymbol = Symbol('ChargebacksDetailsStore')
export const ChargebacksDetailsDrawerContainerStoreSymbol = Symbol(
    'ChargebacksDetailsDrawerContainerStore'
)

const {useBreakpoint} = Grid

export const ChargebacksDetailsDrawer: React.FC = observer(() => {
    const screens = useBreakpoint()
    const isMobile = screens.xs

    const store = useInjection<TChargebacksDetailsStore>(ChargebacksDetailsStoreSymbol)

    const {data, selectedChargeback, isLoading} = store

    const renderTitle = () => {
        return (
            <>
                <Typography.Title className={styles.title} level={4}>
                    {selectedChargeback?.merchantName}
                </Typography.Title>
                <div>
                    <span className={styles.subTitle}>{translations().chargebackId}: </span>
                    <Typography.Text
                        copyable={{
                            tooltips: [translations().copy, translations().copied]
                        }}
                    >
                        {selectedChargeback?.id}
                    </Typography.Text>
                </div>
            </>
        )
    }

    return (
        <DrawerContainer
            injectableIdentifier={ChargebacksDetailsDrawerContainerStoreSymbol}
            placement='right'
            title={renderTitle()}
            className={styles.drawer}
        >
            <Spin spinning={isLoading}>
                <ChargebacksDetails
                    data={data}
                    chargeback={selectedChargeback}
                    isMobile={isMobile}
                />
            </Spin>
        </DrawerContainer>
    )
})
