import React from 'react'
import translations from './translations'

translations.add('sl', {
    title: 'Prihláste sa do svojho účtu',
    selectEntity: 'Select your entity',
    ourSupportTeamIsHereToHelp: (message: string) => (
        <>
            {message ? (
                <>
                    {message}
                    <br />
                </>
            ) : (
                <>
                    Nerozpoznali sme prihlasovacie údaje, ktoré používate. Skontrolujte, či
                    používate e-mail, s ktorým ste boli zaregistrovaní.
                </>
            )}
            Náš tím podpory je tu, aby vám pomohol:{' '}
            <a href={'https://dnapayments.com/online-quote#2'} target={'_blank'} rel={'noreferrer'}>
                Podpora
            </a>
        </>
    ),
    successfullyUpdatedDefaultMerchant: 'Default merchant successfully updated',
    sessionExpired: 'Your session has expired. Please log in again.',
    permissionErrorText: 'Nemáte povolenie na prihlásenie',
    back: 'Späť na prihlásenie'
})
