import 'reflect-metadata'
import {ApiResponse, ContentType, post as _post} from 'back-connector'
import {storage} from '@/services/storage'
import {VISITOR_ID_KEY, X_CLIENT_ID_KEY} from '@/constants/auth-constants'

export const post = <T>(
    url: string,
    data?: ContentType,
    headers?,
    options?
): Promise<ApiResponse<T>> => {
    const _headers = headers || []
    const visitorId = storage.get(VISITOR_ID_KEY)

    _headers.push([X_CLIENT_ID_KEY, visitorId])

    return _post<T>(url, data, _headers, options)
}
