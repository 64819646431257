import React from 'react'
import {Divider, Dropdown} from 'antd'
import {Icon} from '@/components/dumb/Icon'
import {ActionButtonProps} from './props'
import styles from './styles.scss'
import {ACTION_LINK, VIEW_ACTIONS_BUTTON} from '@/constants/playwright-ids'

export const ActionButtons: React.FC<ActionButtonProps> = ({linkTitle, onLinkClick, onClick, menu}: ActionButtonProps) => {
    return (
        <span className={styles.actions}>
            {onLinkClick && linkTitle && (
                <>
                    <a data-test-id={ACTION_LINK} onClick={onLinkClick} href="#">{linkTitle}</a>
                    <Divider type={'vertical'}/>
                </>
            )}
            {menu && (
                <Dropdown overlay={menu}>
                    <Icon
                        data-test-id={VIEW_ACTIONS_BUTTON}
                        className={styles.ellipsis}
                        onClick={e => e.preventDefault()}
                        type={'ellipsis'}
                    />
                </Dropdown>
            )}
            {!menu && (
                <Icon className={styles.ellipsis} onClick={onClick} type={'ellipsis'}/>
            )}
        </span>
    )
}
