import translations from './translations'

translations.add('sl', {
    paymentView: 'Zobrazenie platieb',
    paymentLink: 'Odkaz na platbu',
    date: 'Dátum',
    orderNumber: 'Číslo objednávky',
    customer: 'Zákazník',
    transactionType: 'Typ transakcie',
    cancel: 'Zruš',
    ok: 'Úplné platobné údaje'
})
