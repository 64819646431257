import translations from './translations'

translations.add('en', {
    type: 'Type',
    code: 'Code',
    channel: 'Channel',
    merchant: 'Merchant',
    user: 'User',
    reference: 'Reference',
    showHidden: 'Show hidden',
    account: 'Account',
    balance: 'Balance',
    debitCredit: {
        CREDIT: 'Credit',
        DEBIT: 'Debit'
    },
    transactionTypes: {
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        payment: 'Payment',
        transfer: 'Transfer'
    },
    transactionDirection: 'Transaction direction',
    loadAfter: 'Load after',
    yes: 'Yes',
    no: 'No',
    all: 'All'
})
