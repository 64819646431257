import React from 'react'

import AlipaySvg from './alipay.svg'
import ArrowDownSvg from './arrow_down.svg'
import ArrowUpSvg from './arrow_up.svg'
import AttachSvg from './attach.svg'
import AttemptedSvg from './attempted.svg'
import BackArrowSvg from './back_arrow.svg'
import BurgerMenuSvg from './burger_menu.svg'
import CalendarSvg from './calendar.svg'
import CancelledSvg from './cancelled.svg'
import ChargebacksSvg from './chargebacks.svg'
import CardSvg from './card.svg'
import ChargedSvg from './charged.svg'
import CheckSvg from './check.svg'
import CloseSvg from './close.svg'
import CopySvg from './copy.svg'
import DocumentSvg from './document.svg'
import DotSvg from './dot.svg'
import DownOutlinedSvg from './down_outlined.svg'
import DownloadDocumentSvg from './download_document.svg'
import CaretDownSvg from './caret_up.svg'
import CaretUpSvg from './caret_down.svg'
import HelpSvg from './help.svg'
import HomeSvg from './home.svg'
import InfoHelpSvg from './info_help.svg'
import InfoSvg from './info.svg'
import LaptopSvg from './laptop.svg'
import LoaderSvg from './loader.svg'
import MeatballsMenuSvg from './meatballs_menu.svg'
import MobileSvg from './mobile.svg'
import NotificationSvg from './notification.svg'
import PaymentLinksSvg from './payment_links.svg'
import PaypalSvg from './paypal.svg'
import PaypalTitledSvg from './paypal_titled.svg'
import ReadyToChargeSvg from './ready_to_charge.svg'
import RejectedSvg from './rejected.svg'
import ReportsSvg from './reports.svg'
import RestoreSvg from './restore.svg'
import SearchSvg from './search.svg'
import SettingsOutlinedSvg from './settings_outlined.svg'
import SettingsSvg from './settings.svg'
import ShareSvg from './share.svg'
import TransactionsSvg from './transactions.svg'
import SettlementsSvg from './settlements.svg'
import TrashSvg from './trash.svg'
import TrendArrowsSvg from './trend_arrows.svg'
import UpOutlinedSvg from './up_outlined.svg'
import UserSvg from './user.svg'
import UserOutlinedSvg from './user_outlined.svg'
import ViewedSvg from './viewed.svg'
import VirtualTerminalSvg from './virtual_terminal.svg'
import VisaSvg from './visa.svg'
import VisaColoredSvg from './visa_colored.svg'
import ArrowRightSvg from './arrow_right.svg'
import ActivationSvg from './activation.svg'
import ClockSvg from './clock.svg'
import TickSvg from './tick.svg'
import ExpandedSvg from './expanded.svg'
import ShortedSvg from './shorted.svg'
import EllipsisSvg from './ellipsis.svg'
import PdfSvg from './pdf.svg'
import MastercardSvg from './mastercard.svg'
import MastercardColoredSvg from './mastercard_colored.svg'
import ApplePaySvg from './apple_pay.svg'
import GooglePaySvg from './google_pay.svg'
import PayByBankAppSvg from './paybybank.svg'
import EcospendSvg from './ecospend.svg'
import KlarnaSvg from './klarna.svg'
import AmexSvg from './amex.svg'
import AmexTransactionSvg from './amex_transaction.svg'
import UnionPaySvg from './union_pay.svg'
import UnionPayColoredSvg from './union_pay_colored.svg'
import FilterSvg from './filter.svg'
import SortSvg from './sort.svg'
import WarnSvg from './warn.svg'
import EyeSvg from './eye.svg'
import EyeClosedSvg from './eye_closed.svg'
import SMSSvg from './sms.svg'
import EmailSvg from './email.svg'
import BackArrowWithTailSvg from './back_arrow_with_tail.svg'
import NextSvg from './next.svg'
import CardPaymentsSvg from './card_payments.svg'
import PayByBankLogoSvg from './pay_by_bank_logo.svg'
import PayByBankTitledSvg from './pay_by_bank_titled.svg'
import OpenBankingSvg from './open_banking.svg'
import KlarnaLogoSvg from './klarna_logo.svg'
import KlarnaTitledSvg from './klarna_titled.svg'
import DinersSvg from './diners.svg'
import BarclaysLogo from './barclays_logo.svg'
import JPMorganChaseLogo from './jpmorgan_chase_logo.svg'
import SainsburryLogo from './sainsburry_logo.svg'
import EuroPayLogo from './europay_logo.svg'
import GooglePayLogo from './google_pay_logo.svg'
import EcospendSvgLogo from './ecospend_logo.svg'
import ApplePayLogo from './apple_pay_logo.svg'
import PosTransactionsLogo from './pos_transactions.svg'
import ChipSvg from './chip_background.svg'
import RepeatPaymentSvg from './repeat_payment.svg'
import Astropay from './astropay.svg'
import ClickToPay from './clicktopay.svg'
import PdfV2Svg from './pdf_v2.svg'
import CsvSvg from './csv.svg'
import ExcelSvg from './excel.svg'
import WechatPay from './wechat_pay.svg'
import Refund from './refund.svg'

import {DownloadOutlined, LockOutlined, ShopOutlined, TeamOutlined} from '@ant-design/icons'

const icons = {
    alipay: <AlipaySvg />,
    arrow_down: <ArrowDownSvg />,
    arrow_up: <ArrowUpSvg />,
    arrow_right: <ArrowRightSvg />,
    attach: <AttachSvg />,
    attempted: <AttemptedSvg />,
    astropay: <Astropay />,
    back_arrow: <BackArrowSvg />,
    burger_menu: <BurgerMenuSvg />,
    calendar: <CalendarSvg />,
    cancelled: <CancelledSvg />,
    card: <CardSvg />,
    charged: <ChargedSvg />,
    chargebacks: <ChargebacksSvg />,
    check: <CheckSvg />,
    clicktopay: <ClickToPay />,
    close: <CloseSvg />,
    copy: <CopySvg />,
    document: <DocumentSvg />,
    dot: <DotSvg />,
    down_outlined: <DownOutlinedSvg />,
    download_document: <DownloadDocumentSvg />,
    caret_down: <CaretDownSvg />,
    caret_up: <CaretUpSvg />,
    help: <HelpSvg />,
    home: <HomeSvg />,
    info_help: <InfoHelpSvg />,
    info: <InfoSvg />,
    laptop: <LaptopSvg />,
    loader: <LoaderSvg />,
    meatballs_menu: <MeatballsMenuSvg />,
    mobile: <MobileSvg />,
    notification: <NotificationSvg />,
    payment_links: <PaymentLinksSvg />,
    paypal: <PaypalSvg />,
    paypal_titled: <PaypalTitledSvg />,
    ready_to_charge: <ReadyToChargeSvg />,
    rejected: <RejectedSvg />,
    reports: <ReportsSvg />,
    restore: <RestoreSvg />,
    search: <SearchSvg />,
    settings_outlined: <SettingsOutlinedSvg />,
    settings: <SettingsSvg />,
    share: <ShareSvg />,
    trash: <TrashSvg />,
    trend_arrows: <TrendArrowsSvg />,
    up_outlined: <UpOutlinedSvg />,
    user: <UserSvg />,
    user_outlined: <UserOutlinedSvg />,
    viewed: <ViewedSvg />,
    virtual_terminal: <VirtualTerminalSvg />,
    visa: <VisaSvg />,
    visa_colored: <VisaColoredSvg />,
    transactions: <TransactionsSvg />,
    settlements: <SettlementsSvg />,
    activation: <ActivationSvg />,
    clock: <ClockSvg />,
    tick: <TickSvg />,
    expanded: <ExpandedSvg />,
    shorted: <ShortedSvg />,
    ellipsis: <EllipsisSvg />,
    pdf: <PdfSvg />,
    mastercard: <MastercardSvg />,
    mastercard_colored: <MastercardColoredSvg />,
    apple_pay: <ApplePaySvg />,
    google_pay: <GooglePaySvg />,
    pay_by_bank_app: <PayByBankAppSvg />,
    ecospend: <EcospendSvg />,
    klarna: <KlarnaSvg />,
    amex: <AmexSvg />,
    amex_transactions: <AmexTransactionSvg />,
    union_pay: <UnionPaySvg />,
    union_pay_colored: <UnionPayColoredSvg />,
    filter: <FilterSvg />,
    warn: <WarnSvg />,
    eye: <EyeSvg />,
    eye_closed: <EyeClosedSvg />,
    sms: <SMSSvg />,
    email: <EmailSvg />,
    sort: <SortSvg />,
    next: <NextSvg />,
    card_payments: <CardPaymentsSvg />,
    pay_by_bank_logo: <PayByBankLogoSvg />,
    pay_by_bank_titled: <PayByBankTitledSvg />,
    open_banking: <OpenBankingSvg />,
    klarna_logo: <KlarnaLogoSvg />,
    klarna_titled: <KlarnaTitledSvg />,
    diners: <DinersSvg />,
    barclays_logo: <BarclaysLogo />,
    jpmorgan_chase_logo: <JPMorganChaseLogo />,
    sainsburry_logo: <SainsburryLogo />,
    europay_logo: <EuroPayLogo />,
    back_arrow_with_tail: <BackArrowWithTailSvg />,
    google_pay_logo: <GooglePayLogo />,
    ecospend_logo: <EcospendSvgLogo />,
    apple_pay_logo: <ApplePayLogo />,
    pos_transactions: <PosTransactionsLogo />,
    chip_background: <ChipSvg />,
    repeat_payment: <RepeatPaymentSvg />,
    lock: <LockOutlined />,
    download: <DownloadOutlined />,
    shop_outlined: <ShopOutlined />,
    team: <TeamOutlined />,
    pdf_v2: <PdfV2Svg />,
    csv: <CsvSvg />,
    excel: <ExcelSvg />,
    wechat_pay: <WechatPay />,
    refund: <Refund />
}

export {
    AlipaySvg,
    ArrowDownSvg,
    ArrowUpSvg,
    AttachSvg,
    AttemptedSvg,
    BackArrowSvg,
    BurgerMenuSvg,
    CalendarSvg,
    CancelledSvg,
    CardSvg,
    ChargedSvg,
    CheckSvg,
    ChargebacksSvg,
    CloseSvg,
    CopySvg,
    DocumentSvg,
    DotSvg,
    DownOutlinedSvg,
    DownloadDocumentSvg,
    CaretDownSvg,
    CaretUpSvg,
    HelpSvg,
    HomeSvg,
    InfoHelpSvg,
    InfoSvg,
    LaptopSvg,
    LoaderSvg,
    MeatballsMenuSvg,
    MobileSvg,
    NotificationSvg,
    PaymentLinksSvg,
    PaypalSvg,
    ReadyToChargeSvg,
    RejectedSvg,
    ReportsSvg,
    RestoreSvg,
    SearchSvg,
    SettingsOutlinedSvg,
    SettingsSvg,
    ShareSvg,
    TransactionsSvg,
    SettlementsSvg,
    TrashSvg,
    TrendArrowsSvg,
    UpOutlinedSvg,
    UserSvg,
    UserOutlinedSvg,
    ViewedSvg,
    VirtualTerminalSvg,
    VisaSvg,
    VisaColoredSvg,
    ActivationSvg,
    ClockSvg,
    TickSvg,
    ExpandedSvg,
    ShortedSvg,
    EllipsisSvg,
    PdfSvg,
    MastercardSvg,
    MastercardColoredSvg,
    ApplePaySvg,
    GooglePaySvg,
    PayByBankAppSvg,
    EcospendSvg,
    KlarnaSvg,
    AmexSvg,
    UnionPaySvg,
    UnionPayColoredSvg,
    FilterSvg,
    SortSvg,
    EyeSvg,
    SMSSvg,
    EmailSvg,
    WarnSvg,
    NextSvg,
    CardPaymentsSvg,
    PayByBankLogoSvg,
    OpenBankingSvg,
    KlarnaLogoSvg,
    KlarnaTitledSvg,
    DinersSvg,
    BarclaysLogo,
    JPMorganChaseLogo,
    SainsburryLogo,
    EuroPayLogo,
    BackArrowWithTailSvg,
    GooglePayLogo,
    EcospendSvgLogo,
    ApplePayLogo,
    PosTransactionsLogo,
    ChipSvg,
    RepeatPaymentSvg,
    PaypalTitledSvg,
    PayByBankTitledSvg,
    PdfV2Svg,
    CsvSvg,
    ExcelSvg,
    Refund
}

export default icons
