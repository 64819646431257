import translations from './translations'

translations.add('es', {
    buttons: {
        send: 'Enviar',
        cancel: 'Cancelar',
        sendReceipt: 'Enviar recibo'
    },
    labels: {
        email: 'Dirección de Email'
    },
    errors: {
        emailInvalid: 'El correo electrónico no es válido',
        emailRequired: 'correo electrónico obligatorio'
    }
})
