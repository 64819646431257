import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {TSearchSelectContainerStore, SearchSelect} from '@/components'
import {THasIoCIdentifier} from '@/types'

export const SearchSelectContainer = observer(({injectableIdentifier}: THasIoCIdentifier) => {
    const store = useInjection<TSearchSelectContainerStore>(injectableIdentifier)

    return (
        <SearchSelect
            search={store.search}
            values={store.values}
            options={store.options}
            onSearch={store.onSearch}
            setValues={store.setValues}
        />
    )
})
