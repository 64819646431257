import React, {useState} from 'react'
import classNames from 'classnames'
import {GlobalOutlined} from '@ant-design/icons'
import {storage} from '@/services/storage'
import {languages} from '@/constants'
import {Popover} from '@/components'
import {THasClassName} from '@/types/common/THasClassName'
import {TranslationBuilder} from '@/components/dumb/Translation'
import {Language} from '@/components/dumb/Translation/'

import styles from './styles.scss'
import {EARTH_ICON, LANGUAGE} from '@/constants/playwright-ids'

type LanguageSelectProps = THasClassName

export const LanguageSelect = ({className}: LanguageSelectProps) => {
    const [open, setOpen] = useState<boolean>(false)
    const selectedLanguage = TranslationBuilder.getSelectedLang()

    const onClick = (value: Language) => {
        storage.set('lang', value)
        location.reload()
    }

    const content = () => {
        return (
            <div className={styles.content}>
                {languages.map(({label, value}) => {
                    return (
                        <div
                            key={value}
                            className={classNames(styles.row, {
                                [styles.active]: selectedLanguage === value
                            })}
                            onClick={() => onClick(value)}
                            data-test-id={LANGUAGE + '_' + value}
                        >
                            {label}
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <Popover
            open={open}
            overlayClassName={styles.overlay}
            placement={'bottomRight'}
            content={content()}
            trigger={'click'}
            onOpenChange={setOpen}
        >
            <div className={classNames(styles.iconHolder, className)} data-test-id={EARTH_ICON}>
                <GlobalOutlined className={styles.icon} />
            </div>
        </Popover>
    )
}
