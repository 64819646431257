import translations from './translations'

translations.add('ro', {
    title: 'Dispozitive de încredere',
    delete: 'Șterge',
    columns: {
        device: 'Dispozitiv',
        addedOn: 'Adăugat la',
        lastLogin: 'Ultima autentificare'
    },
    messages: {
        confirm: 'Sunteți sigur că doriți să ștergeți dispozitivul de încredere?'
    },
    yes: 'Da',
    no: 'Nu',
    tablet: 'Tabletă',
    mobile: 'Telefon mobil',
    desktop: 'Desktop',
    bot: 'Bot'
})
