import React from 'react'
import {Form, Grid} from 'antd'
import classNames from 'classnames'
import {Button, Icon} from '@/components'
import translations from './translations'
import styles from './styles.scss'

type Props = {
    onCancel: () => void
}

export const MobileBackButton: React.FC<Props> = ({onCancel}) => {
    const screens = Grid.useBreakpoint()
    const isMobile = screens.xs

    return (
        <Form.Item key={'cancel'}>
            <Button className={classNames({ [styles.cancelMobile]: isMobile })} onClick={onCancel} type={'link'}>
                <Icon type={'back_arrow'}/>{translations().back}
            </Button>
        </Form.Item>
    )
}
