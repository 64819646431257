     import translations from './translations'

translations.add('ro', {
    shop: 'Magazin',
    shopName: 'Denumire magazin',
    orderNumber: 'Număr comandă',
    description: 'Descriere',
    customerName: 'Nume client',
    postLink: 'Link poștal',
    customerId: 'ID cont client',
    email: 'Email',
    rrn: 'RRN',
    receivalReferenceNumber: 'Număr de referință al recepției',
    amount: {
        label: 'Sumă',
        from: 'de la',
        to: 'către'
    },
    cardMask: {
        label: 'Card',
        first: 'Primele 6 cifre',
        last: 'Ultimele 4'
    },
    cardScheme: 'Tip card',
    donationAmount: {
        label: 'Sumă donație',
        from: 'de la',
        to: 'către'
    },
    paymentMethod: 'Metodă de plată',
    currency: 'Moneda'
})
