import React from 'react'
import {Theme} from '@/constants'
import {LogoProps} from './props'

import DNALogoLight from '~/assets/img/logos/dna_payments_logo_light.svg'
import DNALogoDark from '~/assets/img/logos/dna_payments_logo_dark.svg'
import {LOGO} from '@/constants/playwright-ids'

export const Logo: React.FC<LogoProps> = (props) => {
    const {
        type,
        className,
        logoLight = DNALogoLight,
        logoDark = DNALogoDark,
        altTitle = 'dnapayments',
        href = 'https://dnapayments.com/',
        isBlank = true,
        onClick
    } = props

    const logo = (() => {
        switch (type) {
            case Theme.DARK:
                return logoLight
            case Theme.LIGHT:
                return logoDark
            default:
                return null
        }
    })()

    if (!logo) return null

    return (
        <a
            data-test-id={LOGO}
            onClick={onClick}
            {...(onClick ? {} : {href})}
            className={className}
            target={isBlank ? '_blank' : ''}
            rel='noreferrer'
        >
            <img src={logo} alt={altTitle} />
        </a>
    )
}
