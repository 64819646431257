import React from 'react'
import {PageHeader} from '@/components'
import translations from './translations'

export const CardPaymentMethod = () => {
    return <PageHeader
        onBack={() => history.back()}
        title={translations().title}
    >

    </PageHeader>
}
