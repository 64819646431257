import { POSPaymentStatus } from '@/constants'
import icons from '~/assets/icons'

export const getPOSPaymentStatusIconType = (status: POSPaymentStatus): keyof typeof icons => {
    switch (status) {
        case POSPaymentStatus.authorised:
        case POSPaymentStatus.charged:
            return 'tick'
        case POSPaymentStatus.cancelled:
        case POSPaymentStatus.declined:
            return 'close'
        default:
            return 'close'
    }
}
