import translations from './translations'

translations.add('es', {
    calendarTitle: 'Personalizar',
    submit: 'Entregar',
    period: 'Período',
    presets: {
        today: 'Hoy',
        yesterday: 'Ayer',
        thisWeek: 'Esta semana',
        lastWeek: 'La semana pasada',
        last7Days: 'Últimos 7 días',
        thisMonth: 'Este mes',
        last30Days: 'Últimos 30 días',
        last60Days: 'Últimos 60 días',
        last90Days: 'Últimos 90 días',
        lastMonth: 'El mes pasado',
        thisYear: 'Este año',
        last12Month: 'Últimos 12 meses'
    }
})
