import React from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {isStatusOk} from '@/utils'
import {EcospendBankBlock, EcospendBlock, EcospendField, EcospendText, EcospendPayeeBlock} from '..'
import ecospendTranslations from '../../translations'
import translations from './translations'
import {EcospendResultPageProps} from './props'
import styles from './EcospendResultPage.scss'
import {Button, Icon} from '@/components'
import { Typography } from 'antd'

export const EcospendResultPage = observer((props: EcospendResultPageProps) => {
    const {friendlyName, message, bankReferenceId, status, invoiceId, amount, closeEcospendModal, tryAgain, payee} = props
    const isOk = isStatusOk(status)

    const renderMessage = () => {
        if (isOk) return translations().successMessage
        if (message) return message
        switch (status) {
            case 'Canceled':
                return translations().cancelMessage
            case 'Failed':
                return translations().failMessage
            case 'Rejected':
                return translations().rejectMessage
        }
        return ''
    }

    return <div className={styles.ecospendResultPage}>
        <Icon type={isOk ? 'check' : 'close'}
              className={classNames(styles.resultIcon, {[styles.hasError]: !isOk})}/>

        <h3 className={classNames(styles.title, {[styles.errorTitle]: !isOk})}>
            {isOk ? translations().successTitle : status}
        </h3>

        <EcospendText className={styles.subtitle}>{renderMessage()}</EcospendText>

        {bankReferenceId ? <EcospendBlock className={styles.largeBlock}>
            <EcospendField label={translations().transactionId}>
                <div className={styles.transactionContent}>
                    <Typography.Text
                        className={styles.copyText}
                        copyable
                    >
                        {bankReferenceId}
                    </Typography.Text>
                </div>
            </EcospendField>
        </EcospendBlock> : null}

        <EcospendBankBlock
            friendlyName={friendlyName}
            invoiceId={invoiceId}
            amount={amount}
            className={styles.block}
        />

        <EcospendPayeeBlock payee={payee} className={styles.payeeBlock}/>

        <div className={styles.buttons}>
            {isOk ?
                <Button type={'link'} onClick={() => closeEcospendModal()}
                        className={styles.button}>{translations().returnToBack}</Button>
                : <Button type={'link'} onClick={() => tryAgain()}
                          className={styles.button}>{translations().tryAgain}</Button>
            }
        </div>

        <EcospendText
            className={styles.footertext}
        >
            {ecospendTranslations().footerText('https://register.fca.org.uk/s/firm?id=0010X00004KSo9HQAT')}
        </EcospendText>
    </div>
})
