import React, {ComponentType} from 'react'
import {DefaultOptionType, SelectProps} from 'antd/lib/select'

import {isAllSelected} from '@/utils'
import {SelectItemWithTwoLabels} from './models'
import styles from './styles.scss'

type ModifiedProps = {optionsWithTwoLabels: SelectItemWithTwoLabels[]}

export function withSelectWithTwoLabels<P extends SelectProps>(
    WrappedComponent: ComponentType<P>
): ComponentType<Omit<P, 'options'> & ModifiedProps> {
    // eslint-disable-next-line react/display-name
    return (props) => {
        const {optionsWithTwoLabels, ...rest} = props

        return (
            <WrappedComponent
                {...(rest as unknown as P)}
                options={optionsWithTwoLabels.map(
                    (option) =>
                        ({
                            value: option.value,
                            label: (
                                <>
                                    <div>{option.labelPrimary}</div>
                                    {!isAllSelected(option.value) && (
                                        <div className={styles.labelSecondary}>
                                            {option.labelSecondary}
                                        </div>
                                    )}
                                </>
                            )
                        }) as DefaultOptionType
                )}
            />
        )
    }
}
