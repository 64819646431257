import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    buttons: {
        send: string
        cancel: string
        sendReceipt: string
    }
    labels: {
        email: string
    }
    errors: {
        emailInvalid: string
        emailRequired: string
    }
}>()
