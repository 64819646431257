import React from 'react'
import {Col, Row} from 'antd'
import {
    TransactionsCardWithBarChartContainer,
    TransactionsComparisonCardWithBarChartContainer,
    TransactionSummaryListContainer
} from '@/components'
import translations from '@/pages/Reports/translations/translations'
import {POSCardSchemeDoughnutChartContainer, POSIssuerBanksDoughnutChartContainer} from '@/pages/Reports/components'
import {
    ReportsPOSTopSummaryStoreSymbol,
    ReportsPOSTransactionsCardWithBarChartContainerStoreSymbol,
    ReportsPOSTransactionsComparisonCardWithBarChartContainerStoreSymbol
} from '@/pages/Reports'
import {TerminalType} from '@/constants'
import {POS_COMPARE_BAR_CHART, POS_TRANSACTION_CHART} from '@/constants/playwright-ids'

export const POSPaymentsAnalytics = () => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={24} xs={24}>
                <TransactionSummaryListContainer type={TerminalType.POS} injectableIdentifier={ReportsPOSTopSummaryStoreSymbol}/>
            </Col>
            <Col span={24} xs={24}>
                <TransactionsCardWithBarChartContainer
                    injectableIdentifier={ReportsPOSTransactionsCardWithBarChartContainerStoreSymbol}
                    title={translations().transactions}
                    data-test-id={POS_TRANSACTION_CHART}
                />
            </Col>
            <Col span={24} xs={24}>
                <TransactionsComparisonCardWithBarChartContainer
                    injectableIdentifier={ReportsPOSTransactionsComparisonCardWithBarChartContainerStoreSymbol}
                    title={translations().transactionsComparison}
                    data-test-id={POS_COMPARE_BAR_CHART}
                />
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={12}>
                <POSCardSchemeDoughnutChartContainer/>
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={12}>
                <POSIssuerBanksDoughnutChartContainer/>
            </Col>
        </Row>
    )
}
