import translations from './translations'

translations.add('es', {
    charge: 'Cobrar',
    refund: 'Reembolso',
    cancel: 'Cancelar',
    paymentCharge: 'Cargo de pago',
    paymentRefund: 'Reembolso de pago',
    paymentAmount: 'Monto del pago',
    balance: 'Balance',
    chargeAmount: 'Precio a cobrar',
    refundAmount: 'Cantidad devuelta',
    pending: 'Pendiente',
    errors: {
        positiveAmount: 'El monto del reembolso debe ser un número positivo',
        amountRefundExceed: 'El monto del reembolso debe ser un número positivo no mayor que el saldo',
        amountChargeExceed: 'El monto del cargo debe ser un número positivo no mayor que el monto del pago'
    }
})
