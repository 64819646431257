import translations from './translations'

translations.add('ro', {
    id: 'ID',
    date: 'Data emiterii',
    period: 'Perioada',
    merchantName: 'Comerciant',
    amount: 'Sumă',
    currency: 'Moneda',
    status: 'Statut',
    invoices: 'Facturi',
    all: 'Toate',
    paid: 'Plătit',
    issued: 'Emis',
    action: 'Acțiune',
    download: 'Descarcă'
})
