import translations from './translations'

translations.add('es', {
    id: 'ID',
    date: 'Fecha de emisión',
    period: 'Período',
    merchantName: 'Comerciante',
    amount: 'Cantidad',
    currency: 'Devisa',
    status: 'Estado',
    invoices: 'Facturas',
    all: 'Todos',
    paid: 'Pagado',
    issued: 'Emitido',
    action: 'Acción',
    download: 'Descargar'
})
