import React from 'react'
import translations from './translations'

translations.add('ro', {
    buttons: {
        home: 'Mergi la pagina principală'
    },
    errors: {
        ourSupportTeamIsHereToHelp: <>
            Operația a eșuat, echipa noastră de suport este aici pentru a vă ajuta: <a href={'https://dnapayments.com/online-quote#2'} target={'_blank'} rel={'noreferrer'}>Support</a>
        </>
    }
})
