import React from 'react'
import translations from './translations'

translations.add('sl', {
    unknown: 'Neznáme',
    unknownError: 'Vyskytla sa chyba, skúste to znova neskôr.',
    footerText: (url: string) => (
        <>
            Poskytnuté spoločnosťou{' '}
            <a href={url} target='_blank' rel='noreferrer'>
                Ecospend
            </a>
            , s licenciou od FCA
        </>
    )
})
