import translations from './translations'

translations.add('en', {
    back: 'Back to log In',
    title: 'Reset password',
    text: "Enter the email address you used to register with  portal and we'll send you instructions to reset your password",
    email: 'Email address',
    emailRequired: 'Email address is required',
    emailInvalid: 'Email address is not valid',
    submit: 'Send',
    errorOnEmail: message => `Failed to get or parse email from query params: ${message}`
})
