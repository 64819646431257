import translations from './translations'

translations.add('sl', {
    buttons: {
        send: 'Odoslať',
        cancel: 'Zruš',
        sendReceipt: 'Poslané potvrdenie'
    },
    labels: {
        email: 'Emailová adresa'
    },
    errors: {
        emailInvalid: 'Email je neplatný',
        emailRequired: 'Vyžaduje sa email'
    }
})
