import React from 'react'
import {Col, Row, Skeleton} from 'antd'
import classNames from 'classnames'
import {useInjection} from 'dna-react-ioc'
import {observer} from 'mobx-react'
import {Card} from '@/components/dumb/Card'
import {DoughnutChart, IconTitle} from '@/components'
import {LoadingState} from '@/types'
import {TClientsDoughnutChartStore} from '@/pages/Reports/components'
import {useScreenType} from '@/hooks'
import translations from './translations'
import {TransactionBarChartProps} from './props'
import styles from './styles.scss'

export const ClientsStoreSymbol = Symbol('ClientsStoreSymbol')

export const ClientsDoughnutChart: React.FC<TransactionBarChartProps> = observer(() => {
    const store = useInjection<TClientsDoughnutChartStore>(ClientsStoreSymbol)
    const {isMobile} = useScreenType()

    const subtitle = store.data?.total

    return (
        <Card
            title={<IconTitle title={translations().clients} tooltipText={translations().clients}/>}
            className={classNames({ [styles.headerMobile]: isMobile })}
        >
            {store.loadingState === LoadingState.LOADING && <Skeleton active={true}/>}
            {store.loadingState !== LoadingState.LOADING && (
                <Row className={styles.row} gutter={[16, 16]}>
                    <Col span={24}>
                        <DoughnutChart
                            data={store.data}
                            centerTitle={translations().totalClients}
                            centerSubtitle={subtitle}
                        />
                    </Col>
                </Row>
            )}
        </Card>
    )
})
