import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import translations from './translations'

translations.add('ro', {
    columns: {
        paymentMethod: 'Metodă de plată',
        transactionDate: 'Data tranzacției',
        settlementDate: 'Data de așezare',
        address: 'Adresă',
        postcode: 'Cod poștal',
        mid: 'MID (Merchant ID)',
        terminalNumber: 'Număr terminal',
        amount: 'Sumă',
        acquirerFee: 'Taxa de achiziție',
        totalFee: 'Taxa totală',
        interchangeFee: 'Taxa de schimb',
        schemeFee: 'Taxa de schemă',
        processingFee: 'Taxa de procesare',
        gatewayFee: 'Taxa de gateway',
        authorisationFee: 'Taxa de autorizare',
        blendedFee: 'Taxa amestecată',
        refundFee: 'Taxa de rambursare',
        amountToMerchant: 'Suma de plată',
        sortCode: 'Cod de sortare',
        accountNumber: 'Număr de cont',
        operation: 'Operație',
        transactionType: 'Tipul tranzacției',
        transactionId: 'ID tranzacție',
        merchantReference: 'Referință comerciant',
        cardScheme: 'Schema cardului',
        cardMask: 'Masca cardului',
        cardholderName: 'Numele deținătorului cardului',
        cardType: 'Tip de card',
        isEuropeanCard: 'Card european',
        issuerRegion: 'Regiunea emitentului',
        issuerCountry: 'Țara emitentului',
        isCorporateCard: 'Card corporativ',
        captureMethod: 'Metoda de capturare',
        settlementType: 'Tipul așezării'
    },
    columnsPerDay: {
        transactionDate: 'Data tranzacției',
        count: 'Număr',
        amount: 'Sumă',
        acquirerFee: 'Taxa de achiziție',
        amountToMerchant: 'Suma de plată',
        settlementDate: 'Data de așezare'
    },
    captureMethod: {
        posContactless: 'POS Contactless'
    },
    columnValues: {
        european: 'European',
        nonEuropean: 'Non-european',
        corporate: 'Corporativ',
        nonCorporate: 'Non-corporativ',
        today: 'Astăzi'
    },
    messages: {
        successExportMessage: (
            <span>
                Solicitarea de descărcare a fost adăugată în coadă pentru procesare. Odată ce documentul este pregătit, poate fi descărcat din secțiunea {' '}
                <Link to={ROUTES.exports}>Exporturi</Link>{' '}.
            </span>
        )
    }
})
