import translations from './translations'

translations.add('en', {
    labels: {
        newPaymentLink: 'New payment link',
        date: 'Date',
        customer: 'Customer',
        transactionType: 'Transaction type',
        amount: 'Amount',
        customerName: 'Customer name',
        name: 'Name',
        description: 'Description',
        nameOfTheServiceOrItemYouProvide: 'Name of the service or item you provide',
        linkExpiration: 'Link expiry',
        stores: 'Stores',
        theNewPaymentLinkIsCreatedAndCopiedToClipboard: 'The new payment link is created and copied to clipboard',
        recurring: 'Initial payment (recurring)'
    },
    buttons: {
        createNewLink: 'Create new Link',
        close: 'Close'
    },
    errors: {
        storeRequired: 'Store required',
        orderNumberRequired: 'Order number required',
        descriptionRequired: 'Description required',
        linkExpirationRequired: 'Link expiration required',
        customerNameRequired: 'Customer name required'
    },
    ranges: {
        hour24: '24 hours',
        hour48: '48 hours',
        week1: '1 week',
        month1: '1 month'
    },
    tooltips: {
        recurring: 'This will mark as the first in a series of future payments initiated by the merchant or customer.'
    },
    selectDate: 'Select date'
})
