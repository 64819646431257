import { saveAs } from 'file-saver'
import { error } from 'dna-common'
import { parseTextResult } from 'back-connector'

import {EpayPaymentRequest, EpayPaymentResponse, postWithAuth, getWithAuth} from '@/api'
import { openErrorNotification, openSuccessNotification } from '@/utils'
import rootTranslations from '@/translations'
import {isNumber} from 'lodash'

export * from './models'

export const getEpayPayments = (request: EpayPaymentRequest) =>
    postWithAuth<EpayPaymentResponse>('/oppapi/v1/ecom/transactions', request)

export const cancelPayment = (paymentId: string) =>
    postWithAuth<any>(`/oppapi/operation/${paymentId}/cancel`, null)

export const chargePayment = (paymentId: string, amount?: number) =>
    postWithAuth<any>(`/oppapi/operation/${paymentId}/charge${(amount ? '?amount=' + amount : '')}`, null)

export const refundPayment = <T = any>(paymentId: string, amount?: number, data = null) =>
    postWithAuth<T>(`/oppapi/operation/${paymentId}/refund${(isNumber(amount) ? '?amount=' + amount : '')}`, data)

export const sendReceipt = async (transactionId: string, email: string) => {
    try {
        const { error } = await postWithAuth<any>(`/oppapi/v1/payments/${transactionId}/receipts`, { email })

        if (error) {
            throw new Error(error.message)
        }

        openSuccessNotification(rootTranslations().success.sendReceiptSuccess)
        return true
    } catch (err) {
        error(err)
        openErrorNotification(err.message || rootTranslations().errors.errorOccured)
        return false
    }
}

export const downloadReceipt = async (transactionId: string) => {
    try {
        const { error, result } = await getWithAuth<any>(`/oppapi/v1/payments/${transactionId}/receipt`, {}, [], {
            parseResponse: async res => {
                if (res.status !== 200) {
                    const error = await parseTextResult(res)
                    throw new Error(error.message)
                }
                return await res.blob()
            }
        })

        if (error) {
            throw new Error(error.message)
        }

        saveAs.saveAs(await result, `Receipt-${transactionId}.pdf`)
        openSuccessNotification(rootTranslations().success.downloadReceiptSuccess)
        return true
    } catch (err) {
        error(err)
        openErrorNotification(err.message || rootTranslations().errors.errorOccured)
        return false
    }
}
