import React from 'react'
import {observer} from 'mobx-react'
import {Col, Row} from 'antd'
import {Button, Icon} from '@/components'
import {LinkShareForm, LinkShareMessagePreview} from '@/pages/components'
import translations from './translations'
import {LinkShareProps} from './props'
import styles from './styles.scss'
import {BACK_TO_LINK_DETAILS_BUTTON} from '@/constants/playwright-ids'

export const LinkShare: React.FC<LinkShareProps> = observer((
    {type, form, formId, onBackToLinkClick, paymentLink, senderNameHandler}
) => {

    return (
        <div className={styles.body}>
            <Button
                className={styles.backBtn}
                icon={<Icon type={'back_arrow_with_tail'}/>}
                type={'link'}
                onClick={onBackToLinkClick}
                data-test-id={BACK_TO_LINK_DETAILS_BUTTON}
            >
                {translations().backToLinkDetails}
            </Button>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <LinkShareForm
                        senderNameHandler={senderNameHandler}
                        id={paymentLink.id}
                        form={form}
                        formId={formId}
                        type={type}
                        customer={paymentLink.customerName}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <LinkShareMessagePreview
                        type={type}
                        name={paymentLink.senderName}
                        customer={paymentLink.customerName}
                        url={paymentLink.url}
                    />
                </Col>
            </Row>
        </div>
    )
})
