import React from 'react'
import {List} from 'antd'
import {Card, DateTimeLabel, Description} from '@/components'
import { MobileListProps } from '@/pages/OnlinePayments/components/MobileOnlinePaymentList/props'
import {TJob} from '@/pages/Exports/models/TJob'
import translations from '../../translations'

export const MobileExportsList: React.FC<MobileListProps<TJob>> = ({dataSource, listExtra, isLoading}) => (
    <List
        loading={isLoading}
        dataSource={dataSource}
        itemLayout={'horizontal'}
        renderItem={(item) => (
            <Card
                key={item.rowId}
                title={item.fileName}
                extra={listExtra(item)}
            >
                <Description dataSource={[
                    {
                        label: translations().status,
                        value: item.status,
                        key: item.status
                    },
                    {
                        label: translations().dateRequested,
                        value: item.createdDate,
                        key: item.createdDate,
                        render: () => <DateTimeLabel date={item.createdDate}/>
                    },
                    {
                        label: translations().lastUpdatedDate,
                        value: item.lastUpdateDate,
                        key: item.lastUpdateDate,
                        render: () => <DateTimeLabel date={item.lastUpdateDate}/>
                    }
                ]} />
            </Card>
        )}
    />
)
