import React from 'react'
import translations from './translations'

translations.add('sl', {
    back: 'Zmeniť banku',
    cancel: 'Zruš',
    continue: 'Pokračovať',
    infoText:
        'Požiadame vašu banku o poskytnutie vášho mena, triediaceho kódu a čísla účtu. Tieto údaje použijeme iba vtedy, ak požiadate o vrátenie peňazí za túto transakciu.',
    permissionText:
        'Spoločnosť Ecospend Technologies Limited potrebuje vaše povolenie na bezpečné iniciovanie platobného príkazu od vašej banky.',
    privacyPolicyText: (termsUrl: string, privacyUrl: string) => (
        <>
            Kliknutím na tlačidlo Pokračovať súhlasíte s{' '}
            <a href={termsUrl} target='_blank' rel='noreferrer'>
                našimi zmluvnými podmienkami
            </a>{' '}
            a{' '}
            <a href={privacyUrl} target='_blank' rel='noreferrer'>
                zásadami ochrany osobných údajov
            </a>
        </>
    )
})
