import translations from './translations'

translations.add('es', {
    labels: {
        newPaymentLink: 'Nuevo enlace de pago',
        date: 'Fecha',
        customer: 'Cliente',
        transactionType: 'Tipo de transacción',
        amount: 'Cantidad',
        customerName: 'Nombre del cliente',
        name: 'Nombre',
        description: 'Descripción',
        nameOfTheServiceOrItemYouProvide: 'Nombre del servicio o artículo que proporciona',
        linkExpiration: 'Caducidad del enlace',
        stores: 'Tiendas',
        theNewPaymentLinkIsCreatedAndCopiedToClipboard: 'El nuevo enlace de pago se crea y se copia en el portapapeles',
        recurring: 'Pago inicial (recurrente)'
    },
    buttons: {
        createNewLink: 'Crear nuevo enlace',
        close: 'Cerrar'
    },
    errors: {
        storeRequired: 'Tienda requerida',
        orderNumberRequired: 'Número de pedido requerido',
        descriptionRequired: 'Descripción requerida',
        linkExpirationRequired: 'Caducidad del enlace requerida',
        customerNameRequired: 'Nombre de cliente requerido'
    },
    ranges: {
        hour24: '24 horas',
        hour48: '48 horas',
        week1: '1 semana',
        month1: '1 mes'
    },
    tooltips: {
        recurring: 'Esto marcará como el primero de una serie de pagos futuros iniciados por el comerciante o el cliente.'
    },
    selectDate: 'Seleccionar fecha'
})
