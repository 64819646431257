import {action, makeObservable, observable} from 'mobx'
import {injectable} from 'inversify'
import {TModalContainerStore} from '@/components/containers/ModalContainer/TModalContainerStore'

@injectable()
export class ModalStore implements TModalContainerStore {
    constructor() {
        makeObservable(this, {
            open: observable,
            setOpen: action.bound,
            onOk: action.bound,
            onCancel: action.bound
        })
    }

    public open = false

    public setOpen = (isOpen) => {
        this.open = isOpen
    }

    public onCancel = () => {
        this.open = false
    }

    public onOk = () => {
        this.open = false
    }
}
