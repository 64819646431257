import translations from './translations'

translations.add('ro', {
    edit: 'Editați',
    share: 'Partajați',
    areYouSureYouWantToDeactivate: 'Sigur doriți să dezactivați linkul de plată?',
    yes: 'Da',
    no: 'Nu',
    createSimilar: 'Creați nou',
    deactivate: 'Dezactivați'
})
