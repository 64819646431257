import translations from './translations'

translations.add('ro', {
    acceptPolicies:
        'Făcând clic pe acest buton, consimt să-mi împărtășesc datele cu PayPal în scopuri de îmbarcare',
    setUpPayPalAccount: 'Configurare cont PayPal',
    warningMessage:
        'Odată ce se face clic pe butonul de mai sus, veți fi dus pe site-ul PayPal într-o nouă filă a browserului. Urmați instrucțiunile pentru a crea un cont nou sau pentru a adăuga contul dvs. PayPal existent. Odată ce procesul este completat, veți fi întors la această pagină și starea va fi actualizată.',
    permissionNotGranted:
        'Nu se pot detecta permisiunile PayPal care au fost acordate. Vă rugăm să verificați și să reîncercați procesul de integrare.',
    errors: {
        terminalNotFound: 'Nu a fost găsit niciun terminal de comerț electronic eligibil'
    }
})
