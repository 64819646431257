import React from 'react'
import {Collapse as AntCollapse, Input as AntInput, Form, Radio} from 'antd'
import {observer} from 'mobx-react'
import {Collapse, Input, Select, MultiSelect} from '@/components'
import translations from './translations'
import {OnlinePaymentsFilterFormProps} from './props'
import styles from './styles.scss'
import {
    ACCOUNT_EXPAND,
    ACCOUNT_ID, ACCOUNT_VALUE, AMOUNT_EXPAND,
    AMOUNT_FROM,
    AMOUNT_TO,
    CARD, CARD_EXPAND,
    CARD_MASK_FIRST,
    CARD_MASK_LAST,
    CARD_SCHEME, CARD_SCHEME_EXPAND,
    CURRENCY_EXPAND,
    CURRENCY_ID, DONATION_AMOUNT, DONATION_AMOUNT_FROM, DONATION_AMOUNT_TO, DONATION_EXPAND,
    PAYER_DESCRIPTION, PAYER_DESCRIPTION_EXPAND,
    PAYER_EMAIL, PAYER_EMAIL_EXPAND,
    PAYER_NAME, PAYER_NAME_EXPAND,
    PAYMENT_METHOD, PAYMENT_METHOD_EXPAND,
    PAYMENT_ORDER_NUMBER,
    PAYMENT_ORDER_NUMBER_EXPAND,
    POST_LINK_STATUS,
    POSTLINK_EXPAND,
    RRN, RRN_EXPAND,
    RRN_INPUT,
    STORE,
    STORE_EXPAND
} from '@/constants/playwright-ids'

const {Panel} = AntCollapse
const {Group: InputGroup} = AntInput

export const OnlinePaymentsFilterForm: React.FC<OnlinePaymentsFilterFormProps> = observer((
    {
        formId, form, onFinish, postLinkStatuses, paymentMethods, cardSchemes,
        currencies, stores, defaultActiveKey = ['invoice_id'],
        onFormValuesChange
    }
) => {
    return (
        <Form
            id={formId}
            form={form}
            onValuesChange={(changedValues, allValues) => {
                onFormValuesChange(allValues)
            }}
            onFinish={onFinish}
        >
            <Collapse defaultActiveKey={[...defaultActiveKey]}>
                <Panel
                    className={styles.panel}
                    header={translations().orderNumber}
                    key="invoice_id"
                    data-test-id={PAYMENT_ORDER_NUMBER_EXPAND}
                >
                    <Form.Item className={styles.formItem} name={'invoice_id'}>
                        <Input placeholder={translations().orderNumber} data-test-id={PAYMENT_ORDER_NUMBER}/>
                    </Form.Item>
                </Panel>
                <Panel
                    className={styles.panel}
                    header={translations().description}
                    key="description"
                    data-test-id={PAYER_DESCRIPTION_EXPAND}
                >
                    <Form.Item className={styles.formItem} name={'description'}>
                        <Input placeholder={translations().description} data-test-id={PAYER_DESCRIPTION}/>
                    </Form.Item>
                </Panel>
                <Panel
                    className={styles.panel}
                    header={translations().customerName}
                    key="payer_name"
                    data-test-id={PAYER_NAME_EXPAND}
                >
                    <Form.Item className={styles.formItem} name={'payer_name'}>
                        <Input placeholder={translations().customerName} data-test-id={PAYER_NAME}/>
                    </Form.Item>
                </Panel>
                <Panel
                    className={styles.panel}
                    header={translations().email}
                    key="payer_email"
                    data-test-id={PAYER_EMAIL_EXPAND}
                >
                    <Form.Item className={styles.formItem} name={'payer_email'}>
                        <Input placeholder={translations().email} data-test-id={PAYER_EMAIL}/>
                    </Form.Item>
                </Panel>
                <Panel
                    className={styles.panel}
                    header={translations().amount.label}
                    key="amount"
                    data-test-id={AMOUNT_EXPAND}
                >
                    <Form.Item className={styles.formItem} name={'amount'}>
                        <InputGroup compact>
                            <Form.Item name={['amount', 'from']} noStyle>
                                <Input style={{ width: '50%' }} placeholder={translations().amount.from} data-test-id={AMOUNT_FROM} />
                            </Form.Item>
                            <Form.Item name={['amount', 'to']} noStyle>
                                <Input style={{ width: '50%' }} placeholder={translations().amount.to} data-test-id={AMOUNT_TO} />
                            </Form.Item>
                        </InputGroup>
                    </Form.Item>
                </Panel>
                <Panel
                    className={styles.panel}
                    header={translations().currency}
                    key="currency_id"
                    data-test-id={CURRENCY_EXPAND}
                >
                    <Form.Item className={styles.formItem} name={'currency_id'} data-test-id={CURRENCY_ID}>
                        <Select
                            placeholder={translations().currency}
                            options={currencies}
                        />
                    </Form.Item>
                </Panel>
                <Panel
                    className={styles.panel}
                    header={translations().paymentMethod}
                    key="payment_method"
                    data-test-id={PAYMENT_METHOD_EXPAND}
                >
                    <Form.Item
                        className={styles.formItem}
                        name={'payment_method'}
                        data-test-id={PAYMENT_METHOD}
                    >
                        <Select
                            placeholder={translations().paymentMethod}
                            options={paymentMethods}
                        />
                    </Form.Item>
                </Panel>

                <Panel
                    className={styles.panel}
                    header={translations().cardMask.label}
                    key="card_mask"
                    data-test-id={CARD_EXPAND}
                >
                    <Form.Item className={styles.formItem} name={'card_mask'} data-test-id={CARD}>
                        <InputGroup compact>
                            <Form.Item name={['card_mask', 'from']} noStyle>
                                <Input style={{ width: '50%' }} placeholder={translations().cardMask.first} data-test-id={CARD_MASK_FIRST} />
                            </Form.Item>
                            <Form.Item name={['card_mask', 'to']} noStyle>
                                <Input style={{ width: '50%' }} placeholder={translations().cardMask.last} data-test-id={CARD_MASK_LAST} />
                            </Form.Item>
                        </InputGroup>
                    </Form.Item>
                </Panel>

                <Panel
                    className={styles.panel}
                    header={translations().cardScheme}
                    key={'card_type_id'}
                    data-test-id={CARD_SCHEME_EXPAND}
                >
                    <Form.Item
                        className={styles.formItem}
                        name={'card_type_id'}
                        data-test-id={CARD_SCHEME}
                    >
                        <MultiSelect
                            placeholder={translations().cardScheme}
                            options={cardSchemes}
                            data-test-id={cardSchemes}
                        />
                    </Form.Item>
                </Panel>
                { stores?.length > 1 && (
                    <Panel
                        className={styles.panel}
                        header={translations().shop}
                        key="shop"
                        data-test-id={STORE_EXPAND}
                    >
                        <Form.Item className={styles.formItem} name={'shop'} data-test-id={STORE}>
                            <Select
                                placeholder={translations().shopName}
                                options={stores}
                            />
                        </Form.Item>
                    </Panel>
                ) }
                <Panel
                    className={styles.panel}
                    header={translations().customerId}
                    key="account_id"
                    data-test-id={ACCOUNT_EXPAND}
                >
                    <Form.Item className={styles.formItem} name={'account_id'} data-test-id={ACCOUNT_ID}>
                        <Input placeholder={translations().customerId} data-test-id={ACCOUNT_VALUE} />
                    </Form.Item>
                </Panel>
                <Panel
                    className={styles.panel}
                    header={translations().rrn}
                    key="reference"
                    data-test-id={RRN_EXPAND}
                >
                    <Form.Item className={styles.formItem} name={'reference'} data-test-id={RRN}>
                        <Input placeholder={translations().receivalReferenceNumber} data-test-id={RRN_INPUT} />
                    </Form.Item>
                </Panel>
                <Panel
                    className={styles.panel}
                    header={translations().postLink}
                    key="post_link_status"
                    data-test-id={POSTLINK_EXPAND}
                >
                    <Form.Item
                        className={styles.formItem}
                        name={'post_link_status'}
                        data-test-id={POST_LINK_STATUS}
                    >
                        <Radio.Group buttonStyle="solid">
                            {postLinkStatuses.map(item =>
                                <Radio.Button
                                    data-test-id={item.label}
                                    key={`postlink_option_${item.value}`}
                                    value={item.value}>{item.label}
                                </Radio.Button>
                            )}
                        </Radio.Group>
                    </Form.Item>
                </Panel>
                <Panel
                    className={styles.panel}
                    header={translations().donationAmount.label}
                    key="11"
                    data-test-id={DONATION_EXPAND}
                >
                    <Form.Item
                        className={styles.formItem}
                        name={'donation_amount'}
                        data-test-id={DONATION_AMOUNT}
                    >
                        <InputGroup compact>
                            <Form.Item name={['donation_amount', 'from']} noStyle>
                                <Input
                                    style={{ width: '50%' }}
                                    placeholder={translations().donationAmount.from}
                                    data-test-id={DONATION_AMOUNT_FROM}
                                />
                            </Form.Item>
                            <Form.Item name={['donation_amount', 'to']} noStyle>
                                <Input
                                    style={{ width: '50%' }}
                                    placeholder={translations().donationAmount.to}
                                    data-test-id={DONATION_AMOUNT_TO}
                                />
                            </Form.Item>
                        </InputGroup>
                    </Form.Item>
                </Panel>
            </Collapse>
        </Form>
    )
})