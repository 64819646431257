import React from 'react'
import {Col, Row} from 'antd'
import {
    TransactionsCardWithBarChartContainer,
    TransactionsComparisonCardWithBarChartContainer,
    TransactionSummaryListContainer
} from '@/components'
import {
    CMDoughnutChartContainer,
    IssuerDoughnutChartContainer,
    PMDoughnutChartContainer
} from '@/pages/Reports/components'
import {ReportsECOMTopSummaryStoreSymbol} from '@/pages/Reports'
import {TerminalType} from '@/constants'
import translations from '@/pages/Reports/translations/translations'
import {ONLINE_TRANS_COMPARE_CHART, ONLINE_TRANSACTION_CHART} from '@/constants/playwright-ids'

export const ECOMPaymentsAnalytics = () => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={24} xs={24}>
                <TransactionSummaryListContainer type={TerminalType.ECOM} injectableIdentifier={ReportsECOMTopSummaryStoreSymbol}/>
            </Col>
            <Col span={24} xs={24}>
                <TransactionsCardWithBarChartContainer
                    title={translations().transactions}
                    data-test-id={ONLINE_TRANSACTION_CHART}
                />
            </Col>
            <Col span={24} xs={24}>
                <TransactionsComparisonCardWithBarChartContainer
                    title={translations().transactionsComparison}
                    data-test-id={ONLINE_TRANS_COMPARE_CHART}
                />
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={12}>
                <PMDoughnutChartContainer/>
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={12}>
                <CMDoughnutChartContainer/>
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={12}>
                <IssuerDoughnutChartContainer/>
            </Col>
        </Row>
    )
}
