import translations from './translations'

translations.add('es', {
    title: 'Opciones de recibo de pago',
    buttons: {
        send: 'Enviar',
        receipt: 'Recibo',
        downloadReceipt: 'Descargar recibo'
    },
    labels: {
        email: 'Email del cliente',
        emailPlaceholder: 'Dirección de Email',
        date: 'Fecha',
        description: 'Descripción del pedido',
        paymentMethod: 'Metodo de pago',
        orderNumber: 'Número de orden'
    },
    errors: {
        emailInvalid: 'El correo electrónico no es válido',
        emailRequired: 'correo electrónico obligatorio'
    }
})
