import React from 'react'
import {Badge, Form} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import {useScreenType} from '@/hooks'
import {LoadingState} from '@/types'
import {PASSWORD_PATTERN} from '@/constants/patterns'
import {withTitle} from '@/router/utils'
import {BackToLoginLink, Button, Card, InputPassword, Popover} from '@/components'
import {TSetPasswordStore} from '@/pages/SetPassword'
import translations from './translations'
import styles from './styles.scss'
import {CONFIRM_NEW_PASSWORD_INPUT, SET_NEW_PASSWORD_BUTTON, SET_NEW_PASSWORD_INPUT} from '@/constants/playwright-ids'

export const SetPasswordStoreSymbol = Symbol('SetPasswordStore')

const SetPassword = observer(() => {
    const [form] = Form.useForm()
    const store = useInjection<TSetPasswordStore>(SetPasswordStoreSymbol)
    const {isMobile} = useScreenType()

    const onFinish = () => {
        store.changePassword()
    }

    const formId = 'reset-password'

    const popoverContent = () => {
        return (
            <div>
                {Object.entries(translations().codeRestrictions).map(([key, value]) => (
                    <div key={key}>
                        {store.restrictionErrors[key] ? (
                            <Badge status={'error'} />
                        ) : (
                            <Badge status={'success'} />
                        )}{' '}
                        {value}
                    </div>
                ))}
            </div>
        )
    }

    return (
        <>
            <div className={styles.setPasswordModalContainer}>
                <BackToLoginLink />

                <Card title={translations().title}>
                    <Form
                        form={form}
                        id={formId}
                        layout={'vertical'}
                        requiredMark={false}
                        onFinish={onFinish}
                    >
                        <Popover
                            content={popoverContent()}
                            title={translations().codeMustContain}
                            placement={isMobile ? 'bottom' : 'right'}
                        >
                            <Form.Item
                                label={translations().newPassword}
                                name={'password'}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().errors.newPasswordRequired
                                    },
                                    {
                                        pattern: PASSWORD_PATTERN,
                                        message: translations().errors.newPasswordInvalid
                                    }
                                ]}
                            >
                                <InputPassword
                                    onChange={e => store.setPassword(e.target.value)}
                                    placeholder={translations().newPassword}
                                    data-test-id={SET_NEW_PASSWORD_INPUT}
                                />
                            </Form.Item>
                        </Popover>
                        <Form.Item
                            label={translations().confirmPassword}
                            name={'confirm'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().errors.confirmPasswordRequired
                                },
                                {
                                    validator: (field, value) => {
                                        if (value === store.password) {
                                            return Promise.resolve()
                                        }

                                        return Promise.reject(
                                            new Error(translations().errors.confirmPasswordInvalid)
                                        )
                                    }
                                }
                            ]}
                        >
                            <InputPassword
                                placeholder={translations().confirmPassword}
                                data-test-id={CONFIRM_NEW_PASSWORD_INPUT}
                            />
                        </Form.Item>
                        <Form.Item key={'submit'}>
                            <Button
                                form={formId}
                                type={'primary'}
                                htmlType={'submit'}
                                className={styles.submit}
                                loading={store.passwordChangingLoadingState === LoadingState.LOADING}
                                data-test-id={SET_NEW_PASSWORD_BUTTON}
                            >
                                {translations().submit}
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </>
    )
})

export default withTitle(SetPassword)
