import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {Col, Divider, Form, Grid, Row, Typography} from 'antd'
import {isEmpty} from 'dna-common'
import { useInjection } from 'dna-react-ioc'

import {getAmountWithCurrency} from '@/utils'
import {Description} from '@/components/dumb/Description'
import {ModalContainer} from '@/components/containers'
import {AmountFormField, ModalFormFooter} from '@/components'

import { IPaymentOperationModalStore } from './store'
import translations from './translations'
import {PaymentViewModalProps} from './props'
import styles from './styles.scss'

const {Title} = Typography
const {useBreakpoint} = Grid

export const PaymentOperationModal = observer((
    props: PaymentViewModalProps
) => {
    const { injectableIdentifier, store: _store, renderInfo } = props
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const store = _store || useInjection<IPaymentOperationModalStore>(injectableIdentifier)
    const screens = useBreakpoint()
    const isMobile = screens.xs
    const [form] = Form.useForm()
    const {amount, balance, currency} = store.selectedPayment || {}
    const isRefundWithBalance = !isEmpty(balance) && store.isForRefund

    useEffect(() => {
        form.setFieldsValue({
            amount
        })
    }, [form, amount])

    const onModalCancel = () => {
        form.resetFields()
        store.modalContainerStore.onCancel()
    }

    const [modalTitle, bodyTitle, btnText] = (() => {
        if (store.isForRefund) {
            return [translations().paymentRefund, translations().refundAmount, translations().refund]
        }
        return [translations().paymentCharge, translations().chargeAmount, translations().charge]
    })()

    const renderForm = () => (
        <Form
            id={'paymentForm'}
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={(values) => store.onFinishPayment(Number(values.amount))}
            initialValues={{amount}}
        >
            <AmountFormField
                form={form}
                rules={[
                    {
                        validator: (field, value) => {
                            const amountOrBalance = isRefundWithBalance ? balance : amount

                            if(Number(value) === 0) {
                                const error = new Error(translations().errors.positiveAmount)
                                return Promise.reject(error)
                            }

                            if (!value || parseFloat(value) <= amountOrBalance) {
                                return Promise.resolve()
                            }

                            const errorMessage = store.isForRefund
                                ? translations().errors.amountRefundExceed
                                : translations().errors.amountChargeExceed

                            const error = new Error(errorMessage)
                            return Promise.reject(error)
                        }
                    }
                ]}
                inputProps={{placeholder: translations().chargeAmount}}
                currency={currency}
            />
        </Form>
    )

    return (
        <ModalContainer
            title={modalTitle}
            store={store.modalContainerStore}
            onOk={onModalCancel}
            onCancel={onModalCancel}
            width={'700px'}
            footer={<ModalFormFooter
                okText={btnText}
                confirmLoading={store.isLoading}
                formId={'paymentForm'}
                onCancel={onModalCancel}
            />}
        >
            {renderInfo && renderInfo(store.selectedPayment)}

            <Divider/>

            <Row>
                <Col className={classNames({[styles.chargeInput]: isMobile})} xs={24} md={11}>
                    <Title level={5}>{bodyTitle}</Title>
                    {renderForm()}
                </Col>

                <Col xs={24} md={{span: 11, offset: 2}}>
                    <Description
                        dataSource={[
                            {
                                key: translations().paymentAmount,
                                label: translations().paymentAmount,
                                value: getAmountWithCurrency(amount, currency)
                            },
                            ...(isRefundWithBalance ? [{
                                key: translations().balance,
                                label: translations().balance,
                                value: getAmountWithCurrency(balance, currency)
                            }] : [])
                        ]}
                    />
                </Col>
            </Row>

        </ModalContainer>
    )
})
