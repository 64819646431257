import translations from './translations'

translations.add('el', {
    linkDetails: 'Λεπτομέρειες συνδέσμου πληρωμής',
    copyToClipboard: 'Αντιγραφή στο πρόχειρο',
    copied: 'Αντιγράφηκε',
    orderNumber: 'Αριθμός παραγγελίας:',
    amount: 'Ποσό',
    customer: 'Πελάτης',
    dateCreated: 'Ημερομηνία Δημιουργίας',
    expirationDate: 'Ημερομηνία λήξης',
    viewedDate: 'Ημερομηνία προβολής',
    paidDate: 'Ημερομηνία πληρωμής',
    cancelledDate: 'Ημερομηνία ακύρωσης',
    attemptedDate: 'Ημερομηνία απόπειρας',
    description: 'Περιγραφή',
    createdBy: 'Δημιουργία από'
})
