import translations from './translations'
import React from 'react'

translations.add('ro', {
    title: 'Apple Pay',
    description: <>
        Apple Pay permite consumatorilor să plătească ușor și rapid online pentru bunuri și servicii. Consumatorii își stochează informațiile cardului de plată în portofelul lor Apple, pe care îl pot accesa de pe dispozitivele lor Apple sau prin intermediul browserului web Safari.
        <br/><br/>
        DNA Payments este încântată să ofere această metodă de plată comercianților, iar aceasta poate fi activată sau dezactivată aici.
        <br/><br/>
    </>
})
