import React from 'react'
import translations from './translations'

translations.add('ro', {
    title: 'Autentifică-te în contul tău',
    selectEntity: 'Selectați entitatea dvs',
    ourSupportTeamIsHereToHelp: (message: string) => (
        <>
            {message ? (
                <>
                    {message}
                    <br />
                </>
            ) : (
                <>
                    Nu am recunoscut detaliile de autentificare pe care le utilizați. Vă rugăm să
                    verificați dacă utilizați adresa de email cu care ați fost înregistrat.
                </>
            )}
            Echipa noastră de suport este aici pentru a vă ajuta:{' '}
            <a href={'https://dnapayments.com/online-quote#2'} target={'_blank'} rel={'noreferrer'}>
                Support
            </a>
        </>
    ),
    successfullyUpdatedDefaultMerchant: 'Comerciantul implicit a fost actualizat cu succes',
    sessionExpired: 'Sesiunea dvs. a expirat. Vă rugăm să vă autentificați din nou.',
    permissionErrorText: 'Nu aveți permisiunea de a vă autentifica',
    back: 'Înapoi la autentificare'
})
