import React from 'react'
import {Col, Row} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {TPaymentMethodsStore} from '@/pages/payment-methods/PaymentMethods/TPaymentMethodsStore'
import {withTitle} from '@/router/utils'
import {PageHeader, PaymentMethodCard} from '@/components'
import translations from './translations'

export const PaymentMethodsStoreSymbol = Symbol('PaymentMethodsStore')
export const PaymentMethodsActivateTerminalSymbol = Symbol('PaymentMethodsActivateTerminalStore')
export const PaymentMethodsApplePayStoreSymbol = Symbol('PaymentMethodsApplePayStore')
export const PaymentMethodsPayByBankAppStoreSymbol = Symbol('PaymentMethodsPayByBankAppStore')
export const PaymentMethodsPaypalStoreSymbol = Symbol('PaymentMethodsPaypalStore')
export const PaymentMethodsOpenBankingStoreSymbol = Symbol('PaymentMethodsOpenBankingStore')
export const PaymentMethodsGooglePayStoreSymbol = Symbol('PaymentMethodsGooglePayStore')
export const PaymentMethodsKlarnaStoreSymbol = Symbol('PaymentMethodsGooglePayStore')

const PaymentMethods = observer(() => {
    const store = useInjection<TPaymentMethodsStore>(PaymentMethodsStoreSymbol)

    return (
        <PageHeader onBack={null} title={translations().title} border={'none'}>
            <Row gutter={[16, 16]}>
                {store.paymentMethods?.map((paymentMethod) => (
                    <Col xs={24} sm={12} md={12} lg={8} key={paymentMethod.type}>
                        <PaymentMethodCard
                            title={paymentMethod.title}
                            type={paymentMethod.type}
                            link={paymentMethod.link}
                            linkText={paymentMethod.linkText}
                            onClick={paymentMethod.onClick}
                        />
                    </Col>
                ))}
            </Row>
        </PageHeader>
    )
})

export default withTitle(PaymentMethods)
