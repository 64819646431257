import translations from './translations'

translations.add('sl', {
    title: 'Správy',
    transactions: 'Transakcie',
    transactionsComparison: 'Porovnanie transakcií',
    calendarTitle: 'Minulý mesiac',
    paymentMethods: 'Spôsoby platby',
    cardPayments: 'Kartové metódy',
    issuingBanks: 'Vydávajúce banky (len platby kartou)',
    amount: 'Množstvo',
    count: 'Číslo',
    volumeTotal: 'Celkový objem',
    previous: 'Predchádzajúci',
    current: 'Súčasný',
    downloadLink: 'Stiahnuť',
    cardScheme: 'Kartová schéma',
    onlinePayments: 'Online platby',
    posPayments: 'POS platby',
    currency: 'Mena',
    noContentTitle: 'No content available',
    empty: 'Prázdny'

})
