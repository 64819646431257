import translations from './translations'

translations.add('ro', {
    title: 'Setări terminal',
    configurationOptions: 'Opțiuni de configurare',
    shippingPreference: 'Preferință de livrare',
    useCustomerProvidedShippingAddress: 'Utilizați adresa de livrare furnizată de client pe site-ul PayPal.',
    redactShippingAddressFromPaypalSite: 'Ascundeți adresa de livrare de pe site-ul PayPal. Recomandat pentru produsele digitale.',
    useMerchantProvidedAddress: 'Utilizați adresa furnizată de comerciant. Clientul nu poate modifica această adresă pe site-ul PayPal.',
    defaultTransactionType: 'Tipul de tranzacție implicit',
    authorization: 'Autorizare',
    capture: 'Captură',
    cancel: 'Anulare',
    saveChanges: 'Salvați modificările'
})
