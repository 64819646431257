import translations from './translations'

translations.add('el', {
    cookies: 'Προτιμήσεις cookies',
    cookiesContent: 'Χρησιμοποιούμε cookies στον ιστότοπό μας. Ορισμένα από αυτά είναι απαραίτητα, ενώ άλλα μας βοηθούν να βελτιώσουμε αυτόν τον ιστότοπο και την εμπειρία σας.',
    acceptAll: 'Αποδοχή όλων',
    save: 'Αποθήκευση',
    back: 'Πίσω',
    essential: 'Essential',
    essentialContent: 'Τα βασικά cookies επιτρέπουν βασικές λειτουργίες και είναι απαραίτητα για τη σωστή λειτουργία του ιστότοπου.',
    statistics: 'Statistics',
    statisticsContent: 'Τα cookies στατιστικών συλλέγουν πληροφορίες ανώνυμα. Αυτές οι πληροφορίες μας βοηθούν να κατανοήσουμε πώς χρησιμοποιούν οι επισκέπτες μας τον συγκεκριμένο ιστότοπό.',
    cookieInformation: 'Πληροφορίες cookie'
})
