import React from 'react'
import translations from './translations'

translations.add('es', {
    title: 'Chargebacks',
    description: (
        <>
            ¿Qué es un contracargo? <br /> Un contracargo es el posible resultado de una transacción
            de crédito o débito disputada. Si el banco del titular de la tarjeta acepta la disputa,
            revertirá la transacción, tomando los fondos del comerciante y devolviéndolos al titular
            de la tarjeta. <br /> Cuando un titular de la tarjeta disputa un cargo, su banco emisor
            revisará la transacción y decidirá si la disputa es válida. Si creen que lo es,
            proporcionarán al titular de la tarjeta un crédito provisional y trabajarán con la red
            de tarjetas y el banco adquirente para finalizar el contracargo.
        </>
    )
})
