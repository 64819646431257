import { TranslationBuilder } from '@/components/dumb/Translation'
import {PayByLinkStatus} from '@/constants'

export default TranslationBuilder.create<{
    title: string
    paymentDetails: string
    chargedAmount: string
    expirationDate: string
    dateCreated: string
    summary: string
    channel: string
    description: string
    amount: string
    status: string
    customer: string
    action: string
    statement: string
    statuses: {
        [key in keyof typeof PayByLinkStatus]: string
    }
}>()

