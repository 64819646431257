import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {PaymentMethodDetails, TOpenBankingPaymentMethodStore} from '@/pages/payment-methods'
import {PaymentMethodsOpenBankingStoreSymbol} from '@/pages/payment-methods/PaymentMethods/PaymentMethods'
import {PaymentMethodType} from '@/constants'
import translations from './translations'

export const OpenBankingPaymentMethod = observer(() => {
    const store = useInjection<TOpenBankingPaymentMethodStore>(PaymentMethodsOpenBankingStoreSymbol)

    return <PaymentMethodDetails
        title={translations().title}
        description={translations().description}
        paymentMethodType={PaymentMethodType.ecospend}
        stores={store.stores}
    />
})
