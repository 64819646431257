import translations from './translations'

translations.add('ro', {
    tabs: {
        all: 'Toate',
        invited: 'Invitat',
        blocked: 'Blocat'
    },
    statuses: {
        ok: 'Activ',
        block: 'Blocat',
        invited: 'Invitat',
        changePassword: 'Schimbare parolă',
        validateEmail: 'Validare e-mail'
    },
    roles: {
        all: 'Toate',
        admin: 'Admin',
        finance: 'Finanțe',
        custom: 'Personalizat'
    },
    columns: {
        email: 'E-mail',
        firstName: 'Prenume',
        lastName: 'Nume de familie',
        status: 'Statut',
        authMethod: 'Metodă de Autentificare',
        actions: 'Acțiuni'
    },
    actions: {
        edit: 'Editați',
        delete: 'Ștergere',
        resendInvitation: 'Retrimite invitație'
    },
    confirmations: {
        areYouSureToDeleteTeammate: 'Ești sigur că vrei să-ți ștergi coechipierul?',
        areYouSureToResendInvitation:
            'Sunteți sigur că doriți să retrimiteți invitația coechipierului dvs?',
        yes: 'Da',
        no: 'Nu'
    }
})
