import {inject, injectable} from 'inversify'
import {action, makeObservable, observable, runInAction} from 'mobx'
import {
    AccessType,
    InviteTeammateModalContainerStoreSymbol
} from '@/pages/TeamManagement/components'
import {TInviteEditTeammateStore} from '@/pages/TeamManagement/components/InviteEditTeammate/TInviteEditTeammateStore'
import {LoadingState} from '@/types'
import {ModalContainerStore} from '@/stores/modal/__mocks__/ModalContainerStore'
import {error} from 'dna-common'
import {InviteEditTeammateFormModel} from '@/pages/TeamManagement/components/InviteEditTeammate/models'
import {inviteTeammate} from '@/stores/team-management/services'
import {openErrorNotification, openSuccessNotification} from '@/utils'
import {InviteTeammateRequest} from '@/stores/team-management/models/InviteTeammateRequest'
import {generatePermissionsArray} from '@/stores/team-management/services/generators'
import {AuthStoreInterface, AuthStoreSymbol} from '@/stores'
import {
    AvailableStoresDataStoreSymbol,
    AvailableStoresDataStore
} from '@/stores/store-and-terminals'
import {TeamManagementStore} from '@/stores/team-management/TeamManagementStore'
import {SelectItem} from '@/components/dumb/Select'
import translations from '@/pages/TeamManagement/translations'
import {Role} from '@/stores/team-management/constants'

@injectable()
export class InviteTeammateStore implements TInviteEditTeammateStore {
    constructor(
        @inject(AuthStoreSymbol) authStore: AuthStoreInterface,
        @inject(InviteTeammateModalContainerStoreSymbol) modalStore: ModalContainerStore,
        @inject(AvailableStoresDataStoreSymbol) merchantStoresStore: AvailableStoresDataStore
    ) {
        this._authStore = authStore
        this._modalStore = modalStore
        this._merchantStoresStore = merchantStoresStore

        makeObservable(this, {
            loadingState: observable,
            cancel: action,
            inviteEditTeammate: action,
            setStores: action
        })
    }

    /**
     * stores
     */
    _authStore: AuthStoreInterface
    _modalStore: ModalContainerStore
    _teamManagementStore: TeamManagementStore
    _merchantStoresStore: AvailableStoresDataStore

    /**
     * fields
     */
    loadingState: LoadingState = LoadingState.IDLE
    stores: string[] = []

    get selectStores(): SelectItem[] {
        return this._merchantStoresStore.storesAsSelectItems
    }

    get isAdmin(): boolean {
        return this._authStore.isAdmin
    }

    cancel = (): void => {
        this._modalStore.setOpen(false)
    }

    inviteEditTeammate = async (values: InviteEditTeammateFormModel): Promise<any> => {
        if (this.loadingState === LoadingState.LOADING) {
            return Promise.resolve()
        }

        runInAction(() => {
            this.loadingState = LoadingState.LOADING
        })

        const shouldSendAllStores = values.shopIds.length === this.selectStores.length

        const request: InviteTeammateRequest = {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            ...(shouldSendAllStores ? {} : {shopIds: values.shopIds})
        }
        if (values.inviteAs === AccessType.admin) {
            request.roles = [AccessType.admin]
        } else {
            if (values.role === Role.finance) {
                request.roles = [Role.finance]
            } else {
                const permissions = generatePermissionsArray(values.permissions)
                request.permissions = permissions
                request.roles = [Role.custom]
            }
        }

        try {
            const response = await inviteTeammate(request)

            if (response.error) {
                this.loadingState = LoadingState.FAILED
                openErrorNotification(response.error.message)
                return
            }

            openSuccessNotification(translations().notifications.userSuccessfullyInvited)
            this.loadingState = LoadingState.DONE
            this._modalStore.setOpen(false)
            await Promise.all([
                this._teamManagementStore?.loadTeammates(),
                this._teamManagementStore?.loadInvitedTeammates()
            ])
        } catch (e) {
            error(e)
        }
    }

    setStores = (stores: string[]) => {
        this.stores = stores
    }
}
