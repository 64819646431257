import translations from './translations'
import React from 'react'

translations.add('sl', {
    messageTextPreview: 'Ukážka textu správy:',
    message: name =>
        `Dostali ste túto správu so žiadosťou o platbu od ${name}. Ak chcete zaplatiť, kliknite na odkaz`,
    emailMessage: (senderName, recipientName) => (
        <div>
            <div>Dear {recipientName}</div>
            <div>{senderName ? senderName : 'Meno odosielateľa'}
                žiada o vykonanie platby. Prosím, choďte na nižšie uvedený odkaz a vyberte si svoju preferovanú platobnú metódu.
            </div>
        </div>
    )
})
