import moment from 'moment'
import {DATE_FORMAT, MONTH_FORMAT, FileType} from '@/constants'

export function getFileContentType(type: FileType) {
    switch (type) {
        case FileType.EXCEL:
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        case FileType.CSV:
            return 'text/csv'
        default:
            return 'application/pdf'
    }
}

export async function parseTextResult(response) {
    const text = await response.text()
    return text && response.status !== 401 ? JSON.parse(text) : undefined
}

export function getFileExtension(type: FileType) {
    switch (type) {
        case FileType.EXCEL:
            return 'xlsx'
        case FileType.CSV:
            return 'csv'
        default:
            return 'pdf'
    }
}

export function getFilename(name: string, from: string, to: string, format: FileType) {
    return `${name} ${moment(from).format(DATE_FORMAT)} - ${moment(to).format(
        DATE_FORMAT
    )}.${getFileExtension(format)}`
}

export function getPdfFilename(name, period, format) {
    return `${name} ${moment(period).format(MONTH_FORMAT)}.${getFileExtension(format)}`
}
