import React from 'react'
import {observer} from 'mobx-react'
import {Input, Spin} from 'antd'

import {Icon} from '@/components'
import translations from './translations'
import {EcospendBankListProps} from './props'
import styles from './EcospendBankList.scss'

export const EcospendBankList = observer((props: EcospendBankListProps) => {
    const {store} = props

    return <Spin spinning={store.isCreatingOrder}>
        <>
            <div className={styles.text}>
                {translations().warnText}
            </div>

            <Input.Search
                name="searchText"
                type="text"
                placeholder={translations().selectBank}
                onChange={(e) => store.setSearchText(e.target.value)}
                className={styles.input}
                value={store.searchText}
            />

            <div className={styles.banks}>
                {store.isLoading && <div className={styles.center}>
                    <Spin />
                </div>}

                {store.filteredBanks.map((bank) => {
                    const BankItem = () => <div
                        onClick={() => {
                            store.setSelectedBank(bank)
                            store.createOrder()
                        }}
                        className={styles.bank}
                        title={bank.name}
                    >
                        <img src={bank.logo} alt={bank.name}/>
                        <div className={styles.bankText}>{bank.friendly_name}</div>
                        <Icon className={styles.arrow} type={'arrow_right'}/>
                    </div>

                    return <div
                        key={bank.bank_id}
                        className={styles.bankContainer}
                    >
                        <BankItem/>
                    </div>
                })}
            </div>
        </>
    </Spin>
})
