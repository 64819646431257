import translations from './translations'

translations.add('ro', {
    successTitle: 'Felicitări',
    successMessage: 'Plata dumneavoastră a fost efectuată cu succes.',
    cancelMessage: 'Plata dumneavoastră a fost anulată.',
    failMessage: 'Plata dumneavoastră a eșuat.',
    rejectMessage: 'Plata dumneavoastră a fost respinsă.',
    transactionId: 'ID tranzacție',
    autoRedirectAfter: 'Veți fi redirecționat automat către site-ul în',
    seconds: 'sec',
    tryAgain: 'Încercați din nou',
    returnToBack: 'Reveniți la site'
})
