import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    inviteTeammate: string
    editTeammate: string
    cancel: string
    submit: string
    invite: string
    formFields: {
        email: string
        store: string
        firstName: string
        lastName: string
        teammatePermissions: string
        inviteAsAdmin: string
        inviteAsRestrictedAccess: string
        accountantAccess: string
        customAccess: string
        grantedPermissions: string
        accessLevels: string
        sectionName: string
        noAccess: string
        readAccess: string
        fullAccess: string
        sections: {
            overview: string
            onlinePayments: string
            posPayments: string
            posAmexPayments: string
            paymentLinks: string
            virtualTerminal: string
            settlements: string
            invoices: string
            reports: string
            paymentMethods: string
            teammates: string
            chargebacks: string
        }
    }
    formFieldsPlaceholders: {
        role: string
    }
    formErrors: {
        emailRequired: string
        roleRequired: string
        shopRequired: string
        enterValidEmail: string
        firstNameRequired: string
        lastNameRequired: string
    }
}>()
