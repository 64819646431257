export const Colors = {
    GRAY: '#7F8286',
    WHITE: '#FFFFFF',
    LIGHT_GRAY: '#F1F2F2',
    SUPER_LIGHT_GRAY: '#F9FAFA',
    PURPLE: '#1D1737',
    GREEN: '#317E72',
    LIGHT_GREEN: '#69B6AA',
    LIGHT_BLUE: '#7ABFCC',
    BLUE: '#45A4FF',
    YELLOW: '#FFDF59',
    PINK: '#E79C93',
    ORANGE: '#F99B64'
}
