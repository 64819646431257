import translations from './translations'

translations.add('es', {
    buttons: {
        cancel: 'Cancelar',
        continue: 'Continuar'
    },
    description: n =>
        `Por favor, ingrese el código de verificación de ${n} dígitos generado por su aplicación autenticadora`
})
