import {ReactNode} from 'react'
import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: ReactNode
    copy: string
    copyText: string
    copied: string
    warning: {
        line1: string
        line2: string
        line3: string
    }
}>()
