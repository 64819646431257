import 'reflect-metadata'
import {injectable} from 'inversify'
import {makeAutoObservable, runInAction} from 'mobx'

import {TResetPasswordStore} from '@/pages/ResetPassword'
import {PasswordRecoveryRequest} from '@/stores/password-recovery/models'
import {recoverPassword} from '@/stores/password-recovery/fetchers'
import {openErrorNotification} from '@/utils'
import {LoadingState} from '@/types'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import translations from '@/translations/translations'

@injectable()
export class PasswordRecoveryStore implements TResetPasswordStore {
    constructor() {
        makeAutoObservable(this)
    }

    public email = ''
    public resetPasswordLoadingState = LoadingState.IDLE

    sendInstructions = async (data: PasswordRecoveryRequest) => {
        runInAction(() => {
            this.resetPasswordLoadingState = LoadingState.LOADING
        })

        try {
            const {error} = await recoverPassword(data)

            if (error) {
                runInAction(() => {
                    this.resetPasswordLoadingState = LoadingState.FAILED
                })
                openErrorNotification(error.message)
            } else {
                runInAction(() => {
                    this.email = data.email
                    this.resetPasswordLoadingState = LoadingState.DONE
                })
                getRouterStore().push(ROUTES.confirmEmail)
            }
        } catch (error) {
            runInAction(() => {
                this.resetPasswordLoadingState = LoadingState.FAILED
            })
            openErrorNotification(translations().errors.general)
        }
    }

    navigateToPasswordFailure = (errorMessage: string) => {
        getRouterStore().push(`${ROUTES.resetPasswordFailure}?m=${errorMessage}`)
    }
}
