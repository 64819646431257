import {inject, injectable} from 'inversify'
import {action, makeObservable, observable, runInAction} from 'mobx'
import {cancelLink} from '@/api'
import {openErrorNotification, openSuccessNotification} from '@/utils'
import {PayByLinkStoreSymbol} from '@/pages/PayByLink'
import rootTranslations from '@/translations'
import {IPayByLinkStore} from '@/pages/PayByLink/IPayByLinkStore'
import translations from './translations'

@injectable()
export class CancelPaymentLinkStore {
    private readonly _payByLinkStore: IPayByLinkStore

    constructor(@inject(PayByLinkStoreSymbol) payByLinkStore: IPayByLinkStore) {
        this._payByLinkStore = payByLinkStore

        makeObservable(this, {
            isLoading: observable,
            cancelPaymentLink: action.bound
        })
    }

    public isLoading = false

    public async cancelPaymentLink(id) {
        this.isLoading = true
        try {
            const {error} = await cancelLink(id)

            runInAction(() => {
                if (error) {
                    return openErrorNotification(error.message)
                }

                this._payByLinkStore.loadPaymentLinks()

                return openSuccessNotification(translations().linkDeactivated)
            })
        } catch (error) {
            return openErrorNotification(rootTranslations().errors.general)
        } finally {
            this.isLoading = false
        }
    }
}
