import translations from './translations'

translations.add('el', {
    back: 'Επιστροφή στην σελίδα εισόδου',
    title: 'Επαναφέρετε τον κωδικό πρόσβασης',
    text: 'Εισαγάγετε τη διεύθυνση email που χρησιμοποιήσατε για να εγγραφείτε στην πύλη και θα σας στείλουμε οδηγίες για να επαναφέρετε τον κωδικό πρόσβασής σας',
    email: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
    emailRequired: 'Απαιτείται διεύθυνση email',
    emailInvalid: 'Η διεύθυνση email δεν είναι έγκυρη',
    submit: 'Στείλτε',
    errorOnEmail: message =>
        `Αποτυχία λήψης ή ανάλυσης email από παραμέτρους ερωτήματος: ${message}`
})
