import translations from './translations'

translations.add('en', {
    title: 'Overview',
    detailedReport: 'Detailed report',
    onlinePayments: 'Online payments',
    posPayments: 'POS payments',
    chargedTransactions: 'Charged transactions',
    chargedTransactionsComparison: 'Charged transactions comparison',
    howItWorks: 'How it works?',
    merchantPortal: 'Merchant Portal',
    productGuides: 'Product guides',
    paymentLinks: 'Payment links',
    readMore: 'Read more',
    merchantPortalGuide: 'Merchant Portal guide',
    onlinePaymentSystem: 'An online payment system, integrated directly to your website - Click through the tips on how to start using the portal',
    toHelpYouGetStarted: 'To help you get started, we\'ve prepared some quick and easy-to-use guides',
    whereToStart: 'Where to start',
    startEarning: 'Start earning',
    allPosTransactions: 'All POS transactions',
    currency: 'Currency',
    noContentTitle: 'No content available'
})
