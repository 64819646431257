import React, {useEffect} from 'react'
import {Spin, Table} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import {withTitle} from '@/router/utils'
import {useScreenType} from '@/hooks'
import {getColumns, TInvoicesStore} from '@/pages/Billing/pages/Invoices'
import {MonthPickerContainer, PageHeader, PaginationContainer, RefreshButton} from '@/components'
import translations from './translations'
import styles from './styles.scss'

export const InvoicesRangePickerContainerStoreSymbol = Symbol('InvoicesRangePickerContainerStore')
export const InvoicesPaginationStoreSymbol = Symbol('InvoicesPaginationStore')
export const InvoicesStoreSymbol = Symbol('InvoicesStore')

export const Invoices = withTitle(observer(() => {
    const {isMobile} = useScreenType()

    const {invoices, isInvoicesLoading, loadInvoices} = useInjection<TInvoicesStore>(InvoicesStoreSymbol)

    useEffect(() => loadInvoices(), [])

    const renderContent = () => (
        <>
            <PageHeader
                className={styles.invoicesHeader}
                onBack={null}
                title={<div>{translations().invoices}</div>}
                border={'none'}
            >
                {isMobile && (
                    <>
                        <MonthPickerContainer injectableIdentifier={InvoicesRangePickerContainerStoreSymbol}/>
                        <br/>
                    </>
                )}

                <div>
                    {isMobile ? null : (
                        <div style={{display: 'flex'}}>
                            <RefreshButton onClick={loadInvoices}/>
                            <MonthPickerContainer injectableIdentifier={InvoicesRangePickerContainerStoreSymbol} />
                        </div>
                    )}
                </div>
            </PageHeader>

            {isMobile ? null : <div className={styles.invoiceDivider}/>}

            <>
                <Table
                    scroll={{ x: 500 }}
                    columns={getColumns()}
                    dataSource={invoices}
                    pagination={false}
                    rowKey={(invoice) => invoice.rowKey}
                />
                <PaginationContainer injectableIdentifier={InvoicesPaginationStoreSymbol}/>
            </>
        </>
    )

    return <Spin spinning={isInvoicesLoading}>{renderContent()}</Spin>
}))
