import translations from './translations'
import React from 'react'

translations.add('en', {
    title: 'Google Pay',
    description: <>
        Google Pay allows consumers to pay for their goods and services easily and quickly online. The consumers store their payment card information in their Google Wallet which can be invoked from their Android devices or within the Google Chrome web browser.
        <br/><br/>
        DNA Payments are pleased to offer this payment method to merchants and it can be enabled or disabled here.
        <br/><br/>
    </>
})
