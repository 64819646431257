export const EXTRA_SMALL_PAGE_SIZE = 5
export const SMALL_PAGE_SIZE = 10
export const DEFAULT_PAGE_SIZE = 20
export const LARGE_PAGE_SIZE = 50
export const EXTRA_LARGE_PAGE_SIZE = 100
export const DEBOUNCE_MS = 100

export const TABLE_STORAGE_KEYS = {
    paymentLinks: 'payment-links',
    posPayments: 'pos-payments',
    posAmexPayments: 'pos-amex-payments',
    settlements: 'settlements',
    onlinePayments: 'online-payments',
    chargebacks: 'chargebacks'
}
