import translations from './translations'

translations.add('ro', {
    title: 'Prezentare generală',
    detailedReport: 'Raport detaliat',
    onlinePayments: 'Plăți online',
    posPayments: 'Plăți POS',
    chargedTransactions: 'Tranzacții efectuate',
    chargedTransactionsComparison: 'Comparație a tranzacțiilor efectuate',
    howItWorks: 'Cum funcționează?',
    merchantPortal: 'Portalul comerciantului',
    productGuides: 'Ghiduri de produse',
    paymentLinks: 'Link-uri de plată',
    readMore: 'Citește mai mult',
    merchantPortalGuide: 'Ghid pentru Portalul comerciantului',
    onlinePaymentSystem:
        'Un sistem de plată online integrat direct în site-ul tău web - Consultă sfaturile despre cum să începi să utilizezi portalul',
    toHelpYouGetStarted:
        'Pentru a te ajuta să începi, am pregătit câteva ghiduri rapide și ușor de utilizat',
    whereToStart: 'Unde să începi',
    startEarning: 'Începe să câștigi',
    allPosTransactions: 'Toate tranzacțiile POS',
    currency: 'Valută',
    noContentTitle: 'Nu există conținut disponibil'
})
