import translations from './translations'

translations.add('en', {
    linkDetails: 'Payment link details',
    copyToClipboard: 'Copy to clipboard',
    copied: 'Copied',
    orderNumber: 'Order number:',
    amount: 'Amount',
    customer: 'Customer',
    dateCreated: 'Date created',
    expirationDate: 'Expiration date',
    viewedDate: 'Viewed date',
    paidDate: 'Paid date',
    cancelledDate: 'Cancelled date',
    attemptedDate: 'Attempted date',
    description: 'Description',
    createdBy: 'Created by'
})
