import React from 'react'
import translations from './translations'

translations.add('es', {
    name: {
        ON_EACH_LOGIN: (
            <>
                <strong>Cada inicio de sesión:</strong> Solicitaremos un código de verificación
            </>
        ),
        TRUSTED_DEVICE: (
            <>
                <strong>Una vez por dispositivo:</strong> Solicitaremos un código de verificación
                solo una vez cada 30 días en un dispositivo de confianza
            </>
        )
    },
    disabled: 'Desactivado'
})
