import translations from './translations'

translations.add('sl', {
    cookies: 'Preferencie súborov cookie',
    cookiesContent:
        'Na našej webovej stránke používame súbory cookie. Niektoré z nich sú nevyhnutné, zatiaľ čo iné nám pomáhajú zlepšovať túto webovú stránku a vaše skúsenosti.',
    acceptAll: 'Prijať všetko',
    save: 'Uložiť',
    back: 'Späť',
    essential: 'Essential',
    essentialContent:
        'Nevyhnutné súbory cookie umožňujú základné funkcie a sú potrebné na správne fungovanie internetovej stránky.',
    statistics: 'Statistics',
    statisticsContent:
        'Štatistické súbory cookie zhromažďujú informácie anonymne. Tieto informácie nám pomáhajú pochopiť, ako naši návštevníci používajú našu webovú stránku.',
    cookieInformation: 'Informácie o súboroch cookie'
})
