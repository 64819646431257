import 'reflect-metadata'
import {injectable} from 'inversify'
import {action, makeObservable, observable, computed} from 'mobx'
import {getTwoFAUserSettings, sendEmailOtp} from '@/stores/profile/services/fetchers'
import {TwoFAUserSettingsModel} from '@/stores/profile/models'
import {TwoFAStoreInterface} from '@/pages/Profile/components'

@injectable()
export class TwoFAStore implements TwoFAStoreInterface {
    public isLoading: boolean = false
    public isModalOpen: boolean = false

    public isDisableModalOpen: boolean = false
    public isEmailConfirmationModalOpen: boolean = false
    public userSettings: TwoFAUserSettingsModel = null

    constructor() {
        makeObservable(this, {
            isEnabled: computed,
            isLoading: observable,

            isDisableModalOpen: observable,
            isModalOpen: observable,
            isEmailConfirmationModalOpen: observable,
            userSettings: observable,

            setIsLoading: action.bound,
            setIsModalOpen: action.bound,
            setUserSettings: action.bound
        })
    }

    get isEnabled(): boolean {
        return this.userSettings?.status?.value === 'ENABLED'
    }

    getTwoFAUserSettings = async () => {
        try {
            this.setIsLoading(true)

            const {result} = await getTwoFAUserSettings()

            this.setUserSettings(result)
        } catch (error) {
            error(error)
        } finally {
            this.setIsLoading(false)
        }
    }

    sendEmailOtp = async () => {
        try {
            await sendEmailOtp()

            this.setIsEmailConfirmationModalOpen(true)
        } catch (error) {
            console.error(error)
        }
    }

    public setUserSettings = (settings: TwoFAUserSettingsModel) => {
        this.userSettings = settings
    }

    public setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading
    }

    public setIsModalOpen = (isOpen: boolean) => {
        this.isModalOpen = isOpen
    }

    public setIsDisableModalOpen = (isOpen: boolean) => {
        this.isDisableModalOpen = isOpen
    }

    public setIsEmailConfirmationModalOpen = (isOpen: boolean) => {
        this.isEmailConfirmationModalOpen = isOpen
    }
}
