import translations from './translations'
import React from 'react'

translations.add('ro', {
    title: 'Pay by Bank',
    description: <>
        NA Payments este mândră să ofere comercianților posibilitatea ca consumatorii să plătească pentru bunuri și servicii direct din contul lor bancar prin intermediul noii aplicații Pay by Bank. Pay by Bank este o nouă opțiune de plată online care permite consumatorilor să plătească folosind aplicația băncii de pe telefonul lor. Este concepută pentru a face procesul de plată online mai ușor, utilizând în același timp toate măsurile de securitate ale băncilor suportate.
        <br/><br/>
        
        Cel mai important, Pay by Bank nu este o aplicație separată de descărcat sau un serviciu pentru care să fie necesară înregistrarea - funcționează prin intermediul aplicației băncii pe care consumatorii o utilizează deja.
        <br/><br/>
    </>
})
