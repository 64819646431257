import React from 'react'
import {Divider} from 'antd'
import {Status} from '@/components/dumb/Status'
import {TableStatusProps} from './props'
import styles from './styles.scss'

export const TableStatus = ({statusProps, extra}: TableStatusProps) => {
    return (
        <div className={styles.gridStatus}>
            <Divider className={styles.gridDivider} type={'vertical'}/>
            <Status {...statusProps}/>
            { extra }
        </div>
    )
}
