import {injectable} from 'inversify'
import {NotificationsClient} from 'dna-notification-client'
import {action, makeObservable, observable, runInAction, toJS} from 'mobx'
import {PLATFORM, SECTION_ROUTES} from '@/stores/notification/constants'
import {INotificationStore} from '@/layouts'
import {NotificationArgsType} from '@/stores/notification/types'
import {storage} from '@/services/storage'
import {ACCESS_TOKEN_KEY} from '@/constants/auth-constants'

@injectable()
export class AuthNotificationStore implements INotificationStore {
    notificationClient: NotificationsClient | null = null
    sections = {}
    isAppBlocked = false
    accessToken: string = storage.get(ACCESS_TOKEN_KEY)

    constructor() {
        makeObservable(this, {
            sections: observable,
            isAppBlocked: observable,
            notificationClient: observable,
            eventHandler: action.bound
        })

        this.startOnLogin()
    }

    public eventHandler(args: NotificationArgsType[]) {
        const newSections = {}

        if (!args.length) {
            runInAction(() => {
                this.sections = {}
                this.isAppBlocked = false
            })
            return
        }

        args.forEach((notification) => {
            const {systemId, notificationContentAreaId, descriptionLine1} = notification
            if (systemId === PLATFORM) {
                newSections[notificationContentAreaId] = {
                    description: descriptionLine1,
                    route: SECTION_ROUTES[notificationContentAreaId],
                    ...notification
                }
            }
        })
        runInAction(() => this.sections = newSections)
    }

    startOnLogin = () => {
        if (this.accessToken) {
            if (this.notificationClient) this.notificationClient.connectionClose()
            this.notificationClient = new NotificationsClient(_IS_PROD_ ? _AUTH_NOTIFICATION_PROD_URL_ : _AUTH_NOTIFICATION_TEST_URL_, this.accessToken)
            this.notificationClient.subscribe(this)
        }
    }

    refreshOnTokenChange = (result) => {
        if (result?.access_token) {
            this.notificationClient.connectionClose()

            this.notificationClient = new NotificationsClient(_IS_PROD_ ? _AUTH_NOTIFICATION_PROD_URL_ : _AUTH_NOTIFICATION_TEST_URL_, result?.access_token)
            this.notificationClient.subscribe(this)
        }
    }

    destroy() {
        this.notificationClient.connectionClose()
    }
}
