import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    back: string
    title: string
    somethingWentWrong: string
    tryAgain: string
    unexpectedError: string
}>()

