import translations from './translations'

translations.add('en', {
    charge: 'Charge',
    refund: 'Refund',
    cancel: 'Cancel',
    paymentCharge: 'Payment charge',
    paymentRefund: 'Payment refund',
    paymentAmount: 'Payment amount',
    balance: 'Balance',
    chargeAmount: 'Charge amount',
    refundAmount: 'Refund amount',
    pending: 'Pending',
    errors: {
        positiveAmount: 'Refund amount must be a positive number',
        amountRefundExceed: 'Refund amount must be a positive number not greater than the balance',
        amountChargeExceed: 'Charge amount must be a positive number not greater than the payment amount'
    }
})
