import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    updateTitle: string
    recoveryCodes: string
    selectFrequency: string
    configureApp: string
    cancel: string
    back: string
    next: string
    update: string
    submit: string
}>()
