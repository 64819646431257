import 'reflect-metadata'
import {injectable} from 'inversify'
import {makeAutoObservable} from 'mobx'
import {LoadingState} from '@/types'
import {fetchProcessNewPayment} from '@/stores/transactions/ProcessNewPaymentStore/services'
import {TProcessNewPaymentModalStore} from '@/pages/OnlinePayments/components/ProcessNewPaymentModal'
import {ProcessNewPaymentRequest} from '@/stores/transactions/ProcessNewPaymentStore/models'

@injectable()
export class ProcessNewPaymentStore implements TProcessNewPaymentModalStore {
    loadingState: LoadingState = LoadingState.IDLE
    open = false

    constructor() {
        makeAutoObservable(this)
    }

    loadProcessNewPayment = async (values: ProcessNewPaymentRequest) => {
        this.loadingState = LoadingState.LOADING

        try {
            return await fetchProcessNewPayment(values)
                .then(res => {
                    this.loadingState = LoadingState.DONE
                    return res
                })
        } catch (e) {
            this.loadingState = LoadingState.FAILED
            throw new Error(e)
        }
    }

    onProcessNewPaymentModalCancel(): void {
        this.setOpen(false)
    }

    setOpen = open => this.open = open
}
