import translations from './translations'

translations.add('en', {
    cookies: 'Cookies Preferences',
    cookiesContent: 'We use cookies on our website. Some of them are essential, while others help us to improve this website and your experience.',
    descriptions: {
        name: 'Name',
        provider: 'Provider',
        saveVisitorsPreferences: 'Saves the visitors preferences selected in the Cookie',
        cookieName: 'Cookie Name',
        cookieExpiry: 'Cookie Expiry',
        cookie: 'Cookie',
        ownerOfSite: 'Owner of this website',
        cookieByGoogleAdvanced: 'Cookie by Google used to control advanced script and event handling',
        purpose: 'Purpose',
        year: '1 year',
        googleTagManager: 'Google Tag Manager',
        googleLLC: 'Google LLC',
        twoYears: '2 years',
        privacyPolicy: 'Privacy Policy'
    }
})
