import translations from './translations'

translations.add('en', {
    welcome: 'Welcome to the DNA Payments',
    merchantPortal: 'Merchant Portal',
    startTutorial: 'Start tutorial',
    description1: 'Our Merchant Portal is a powerful platform that helps you monitor, track, and manage your Workflow, Transactions, Refunds, Payment Disputes, and Settlements, all in real time.',
    description2: 'We recommend looking through our tutorial to discover how to use our Merchant Portal’s features and functionality to their fullest potential',
    skipTutorial: 'Skip tutorial',
    back: 'Back',
    close: 'Close',
    next: 'Next',
    open: 'Open the dialog',
    skip: 'Skip'
})
