import React from 'react'
import {List} from '@/components'
import {PaymentMethodTerminalSetting} from '@/pages/payment-methods'
import {PaymentMethodStoreSettingProps} from './props'

export const PaymentMethodStoreSetting: React.FC<PaymentMethodStoreSettingProps> = (
    {dataSource, paymentMethodType, onSwitchChange}
) => {
    return (
        <List
            dataSource={dataSource}
            renderItem={({id, settings}) => {
                const setting = settings[paymentMethodType]
                return <PaymentMethodTerminalSetting
                    onSwitchChange={onSwitchChange}
                    checked={setting?.status === 'active'}
                    terminalId={id}
                />
            }}
        />
    )
}
