import {inject, injectable} from 'inversify'
import uuid from 'uuid/v4'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import {TExportStore} from '@/pages/Exports/TExportStore'
import {TJob} from '@/pages/Exports/models/TJob'
import {ExportsPaginationContainerSymbol} from '@/pages/Exports/Exports'
import {PaginationContainerStoreType} from '@/components'
import {fetchJobs} from '@/stores/exports/services/fetchers'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {ExportFormatsEnum} from '@/stores/exports/models/ExportFormatsEnum'
import {DocumentOptionType} from '@/stores/exports/models/DocumentOptionType'
import translations from './translations'
import {openErrorNotification} from '@/utils'
import rootTranslations from '@/translations'

@injectable()
export class ExportsStore implements TExportStore {
    exports: TJob[] = []
    paginationContainer: PaginationContainerStoreType
    isLoading = false
    selectedOption: DocumentOptionType = {
        value: ExportFormatsEnum.ALL,
        label: translations().jobNameTypes[ExportFormatsEnum.ALL]
    }

    constructor(
        @inject(ExportsPaginationContainerSymbol) paginationContainer: PaginationContainerStoreType
    ) {
        this.paginationContainer = paginationContainer

        makeObservable(this, {
            exports: observable,
            isLoading: observable,
            selectedOption: observable,
            selectOption: action.bound,
            options: computed
        })

        reaction(
            () => {
                return {
                    value: this.selectedOption.value,
                    paging: this.paginationContainer.paging
                }
            },
            () => this.loadJobs()
        )
    }

    init = () => {
        if (this.paginationContainer.paging.page === 1) {
            this.loadJobs()
        } else {
            this.paginationContainer.setPageNumber(1)
        }
    }

    refresh = () => {
        this.init()
    }

    get options() {
        const selectOptions: DocumentOptionType[] = [
            {label: translations().jobNameTypes.all, value: ExportFormatsEnum.ALL}
        ]

        if (hasPermissions([
            PermissionsMap.pos_payments.read,
            PermissionsMap.pos_payments.full
        ])) {
            selectOptions.push({
                label: translations().jobNameTypes[ExportFormatsEnum.POSCSV],
                value: ExportFormatsEnum.POSCSV
            })
        }

        if (hasPermissions([
            PermissionsMap.pos_amex_payments.read,
            PermissionsMap.pos_amex_payments.full
        ])) {
            selectOptions.push({
                label: translations().jobNameTypes[ExportFormatsEnum.POSAMEXCSV],
                value: ExportFormatsEnum.POSAMEXCSV
            })
        }

        if (
            hasPermissions([
                PermissionsMap.online_payments.read,
                PermissionsMap.online_payments.full
            ])
        ) {
            selectOptions.push({
                label: translations().jobNameTypes[ExportFormatsEnum.ECOMCSV],
                value: ExportFormatsEnum.ECOMCSV
            })
        }

        if (hasPermissions([PermissionsMap.settlements.full])) {
            selectOptions.push(
                ...[
                    {
                        label: translations().jobNameTypes[ExportFormatsEnum.EXCELL],
                        value: ExportFormatsEnum.EXCELL
                    },
                    {
                        label: translations().jobNameTypes[ExportFormatsEnum.CSV],
                        value: ExportFormatsEnum.CSV
                    }
                ]
            )
        }

        return selectOptions
    }

    public selectOption(value, option) {
        this.selectedOption = option
    }

    loadJobs = async () => {
        runInAction(() => {
            this.isLoading = true
        })

        try {
            const {error, result} = await fetchJobs({
                jobName:
                    this.selectedOption.value === ExportFormatsEnum.ALL
                        ? undefined
                        : this.selectedOption.value,
                ...this.paginationContainer.paging
            })

            if (error) {
                throw new Error(error.message)
            }

            runInAction(() => {
                this.exports =
                    (result.data &&
                        result.data.map(item => ({
                            ...item,
                            rowId: uuid(),
                            name: translations().jobNameTypes[item.name]
                        }))) ||
                    []
                this.paginationContainer.setTotal(result.totalCount)
            })
        } catch (error) {
            openErrorNotification(rootTranslations().errors.general)
        } finally {
            runInAction(() => {
                this.isLoading = false
            })
        }
    }
}
