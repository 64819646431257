import React from 'react'
import {Grid, Typography} from 'antd'
import classNames from 'classnames'
import {Icon} from '@/components/dumb/Icon'
import {Card} from '@/components/dumb/Card'
import {SliderPreviewItemProps} from './props'
import styles from './styles.scss'
import {CAROUSEL, CAROUSEL_SUBTITLE} from '@/constants/playwright-ids'

const {Text, Title} = Typography

export const CarouselWidgetItem: React.FC<SliderPreviewItemProps> = ({
    title,
    subtitle,
    description,
    linkText,
    onLinkClick,
    backgroundColor,
    href
}) => {
    const screens = Grid.useBreakpoint()
    const isMobile = screens.xs

    return (
        <Card
            className={classNames(
                styles.card,
                {[styles.cardMobile]: isMobile},
                {[styles.cardYellow]: backgroundColor === 'yellow'},
                {[styles.cardBlue]: backgroundColor === 'blue'}
            )}
            data-test-id={CAROUSEL}
        >
            <div className={classNames(styles.body, {[styles.bodyMobile]: isMobile})}>
                <Text className={styles.title} data-test-id={CAROUSEL_SUBTITLE}>{subtitle}</Text>
                <Title className={classNames(styles.subtitle, {[styles.subtitleMobile]: isMobile})}>
                    {title}
                </Title>
                <Text className={styles.text}>{description}</Text>
                <a
                    target={'_blank'}
                    href={href}
                    onClick={onLinkClick}
                    className={styles.link}
                    rel='noreferrer'
                >
                    {linkText}
                    <Icon type={'arrow_right'} />
                </a>
            </div>
        </Card>
    )
}
