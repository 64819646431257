import React from 'react'
import {observer} from 'mobx-react'
import {Modal, ModalFormFooter, ManagerTip} from '@/components'
import translations from './translations'
import {CardFreezeModalProps} from './props'

export const CardFreezeModal: React.FC<CardFreezeModalProps> = observer((
    {open, onCancel}
) => {
    return (
        <Modal
            open={open}
            title={translations().title}
            onCancel={onCancel}
            footer={<ModalFormFooter
                formId={'set-paypal-settings'}
                onCancel={onCancel}
                cancelText={translations().cancel}
                okText={translations().freezeCard}
            />}
        >
            <ManagerTip text={translations().freezeYourCardTemporarily}/>
        </Modal>
    )
})
