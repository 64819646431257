import {makeAutoObservable} from 'mobx'
import {inject, injectable} from 'inversify'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import {TOverviewModalStore} from '@/pages/PayByLink/components/OverviewModal/TOverviewModalStore'
import {OnlinePaymentsStoreSymbol, TOnlinePaymentsStore} from '@/pages/OnlinePayments'

@injectable()
export class OverviewModalStore implements TOverviewModalStore {
    private _onlinePaymentsStore: TOnlinePaymentsStore

    constructor(
        @inject(OnlinePaymentsStoreSymbol) onlinePaymentsStore: TOnlinePaymentsStore
    ) {
        this._onlinePaymentsStore = onlinePaymentsStore
        makeAutoObservable(this)
    }

    public open = false

    public setOpen = (isOpen) => {
        this.open = isOpen
    }

    public onCancel = () => {
        this.open = false
    }

    public onOk = () => {
        this.open = false
        this._onlinePaymentsStore.loadTransactions()
        getRouterStore().push(ROUTES.onlinePayments)
    }
}
