import moment from 'moment'
import {ExpirationDateType} from '@/stores/pay-by-link/models/ExpirationDateType'

export const getExpirationDateByPreset = (preset: ExpirationDateType) => {
    switch (preset) {
        case ExpirationDateType.Hour24:
            return moment().add(1, 'days')
        case ExpirationDateType.Hour48:
            return moment().add(2, 'days')
        case ExpirationDateType.Week1:
            return moment().add(1, 'weeks')
        case ExpirationDateType.Month1:
            return moment().add(1, 'months')
        default:
            return null
    }
}
