import React from 'react'
import classNames from 'classnames'
import {Button} from '@/components'
import translations from './translations'
import {CookiesAcceptProps} from './props'
import styles from './styles.scss'
import {ACCEPT_ALL_COOKIES, ACCEPT_ESSENTIAL_COOKIES, ACCEPT_INDIVIDUAL_COOKIES} from '@/constants/playwright-ids'

export const CookiesAccept: React.FC<CookiesAcceptProps> = ({className, onAllClick, onIndividualClick, onEssentialClick}) => {
    return <div className={classNames(styles.links, className)}>
        <Button
            type={'primary'}
            onClick={onAllClick}
            className={styles.acceptAll}
            data-test-id={ACCEPT_ALL_COOKIES}
        >{translations().acceptAll}</Button>
        <br/>
        <a
            data-test-id={ACCEPT_ESSENTIAL_COOKIES}
           href={'#'}
           onClick={onEssentialClick}
        >{translations().acceptOnlyEssential}</a>
        <br/>
        <a
            data-test-id={ACCEPT_INDIVIDUAL_COOKIES}
            href={'#'}
            onClick={onIndividualClick}
        >{translations().initialPrivacy}</a>
    </div>
}
