import translations from './translations'

translations.add('en', {
    title: 'Card freeze',
    freezeYourCardTemporarily: '- Freeze your card temporarily',
    indefinitely: 'Indefinitely',
    oneHour: '1 hour',
    twentyFourHour: '24 hour',
    customPeriod: 'Custom period',
    cancel: 'Cancel',
    freezeCard: 'Freeze card'
})
