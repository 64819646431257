export enum OnlinePaymentStatus {
    all = '',
    auth = 'AUTH',
    created = 'CREATED',
    credited = 'CREDITED',
    charge = 'CHARGE',
    cancel = 'CANCEL',
    refund = 'REFUND',
    reject = 'REJECT',
    failed = 'FAILED',
    new = 'NEW',
    threeDSecure = '3D',
    tokenized = 'TOKENIZED',
    verified = 'VERIFIED',
    processing = 'PROCESSING',
    abandoned = 'ABANDONED',
    other = 'OTHER'
}
