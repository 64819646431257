import translations from './translations'

translations.add('en', {
    tabs: {
        all: 'All',
        invited: 'Invited',
        blocked: 'Blocked'
    },
    statuses: {
        ok: 'Active',
        block: 'Blocked',
        invited: 'Invited',
        changePassword: 'Change password',
        validateEmail: 'Validate Email'
    },
    roles: {
        all: 'All',
        admin: 'Admin',
        finance: 'Finance',
        custom: 'Custom'
    },
    columns: {
        email: 'Email',
        firstName: 'First name',
        lastName: 'Last name',
        status: 'Status',
        authMethod: 'Auth Method',
        actions: 'Actions'
    },
    actions: {
        edit: 'Edit',
        delete: 'Delete',
        resendInvitation: 'Resend invitation'
    },
    confirmations: {
        areYouSureToDeleteTeammate: 'Are you sure that you want to delete your teammate?',
        areYouSureToResendInvitation:
            'Are you sure that you want to resend the invitation to your teammate?',
        yes: 'Yes',
        no: 'No'
    }
})
