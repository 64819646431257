import translations from './translations'

translations.add('el', {
    tabs: {
        all: 'Ολα',
        invited: 'Προσκλήθηκαν',
        blocked: 'Μπλοκαρίστηκαν'
    },
    statuses: {
        ok: 'Ενεργοί',
        block: 'Μπλοκαρίστηκαν',
        invited: 'Προσκλήθηκαν',
        changePassword: 'Αλλάξτε κωδικό',
        validateEmail: 'Επικύρωση Email'
    },
    roles: {
        all: 'Ολα',
        admin: 'Διαχειριστής',
        finance: 'Finance',
        custom: 'Τροποιποιημένο'
    },
    columns: {
        email: 'Ηλεκτρονική διεύθυνση',
        firstName: 'Ονομα',
        lastName: 'Επίθετο',
        status: 'Κατάσταση',
        authMethod: 'Μέθοδος Επαλήθευσης',
        actions: 'Ενέργειες'
    },
    actions: {
        edit: 'Επεξεργασία',
        delete: 'Διαγραφή',
        resendInvitation: 'Στείλτε ξανά πρόσκληση'
    },
    confirmations: {
        areYouSureToDeleteTeammate: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το συνεργάτη σας;',
        areYouSureToResendInvitation:
            'Είστε σίγουροι ότι θέλετε να στείλετε ξανά την πρόσκληση στον συνεργάτη σας;',
        yes: 'Ναι',
        no: 'Όχι'
    }
})
