import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    welcome: string
    merchantPortal: string,
    startTutorial: string,
    description1: string
    description2: string
    skipTutorial: string
    back: string
    close: string
    next: string
    open: string
    skip: string
}>()

