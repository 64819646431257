import translations from './translations'

translations.add('el', {
    title: 'Διαδικασία onboarding',
    initialCheck: 'Αρχικός έλεγχος',
    initialCheckDescription: 'Οι ερωτήσεις από τον διευθυντή για να διευκρινιστούν τα στοιχεία της επιχείρησής σας',
    actionRequired: 'Action Required',
    processTakesDays: 'Η διαδικασία διαρκεί 1 ημέρα',
    documentsUpload: 'Μεταφόρτωση εγγράφων',
    finalCheck: 'Τελικός έλεγχος',
    agreement: 'Συμφωνία',
    activation: 'Ενεργοποίηση',
    submit: 'Υποβολή',
    cancel: 'Ακύρωση',
    initialCheckSuccessMessage: 'Ευχαριστούμε! Τώρα εξετάζουμε τις πληροφορίες που μας έχετε δώσει.',
    thankYouMessage: 'Ευχαριστούμε! Τώρα εξετάζουμε τις πληροφορίες που μας έχετε δώσει.',
    documentsUploadDescription: 'Παρακαλώ ανεβάστε τα δικαιολογητικά σας: αποδεικτικό ταυτότητας, αποδεικτικό διεύθυνσης κατοικίας και τραπεζικό αντίγραφο',
    finalCheckDescription: 'Παρακαλώ δώστε πρόσθετες πληροφορίες',
    back: 'Πίσω'
})
