import translations from './translations'

translations.add('sl', {
    paymentDetails: 'Platobné údaje',
    date: 'Dátum',
    transactionDate: 'Dátum transakcie (GMT)',
    amount: 'Množstvo',
    status: 'Stav',
    terminalId: 'ID terminálu',
    card: 'Karta',
    details: 'Podrobnosti',
    resultCode: 'Kód výsledku',
    message: 'Správa',
    cardType: 'Typ karty',
    cardCategory: 'Kategória karty',
    corporate: 'Firemný',
    personal: 'Osobná',
    europeanCard: 'Európska karta',
    captureMethod: 'Metóda zachytávania',
    paymentMethod: 'Spôsob platby',
    transaction: {
        id: 'ID transakcie',
        type: 'Typ transakcie',
        details: 'Podrobnosti o transakcii',
        country: 'Krajina transakcie',
        city: 'Mesto transakcie'
    },
    currency: 'Mena',
    isCorporateCard: 'Kategória karty',
    returnCode: 'Kód výsledku',
    returnCodeDescription: 'Správa',
    mid: 'MID',
    issuerRegion: 'Región vydávateľa',
    cardScheme: 'Kartová schéma',
    cardMask: 'Maska karty'
})
