export enum CardStatus {
    ACTIVE = '00',
    REFER_CARD_ISSUER = '01',
    NOT_ACTIVATED = '02',
    CAPTURE_CARD = '04',
    DO_NOT_HONOUR = '05',
    INVALID_CARD = '14',
    LOST_CARD = '41',
    STOLEN_CARD = '43',
    CLOSED_ACCOUNT = '46',
    EXPIRED_CARD = '54',
    TRANSACTION_NOT_PERMITTED_TO_CARDHOLDER = '57',
    SUSPECTED_FRAUD = '59',
    RESTRICTED_CARD = '62',
    SECURITY_VIOLATION = '63',
    CARDHOLDER_TO_CONTACT_ISSUER = '70',
    PIN_TRIES_EXCEEDED = '75',
    CARD_DESTROYED = '83',
    REFUND_GIVEN_TO_CUSTOMER = '98',
    CARD_VOIDED = '99'
}
