import translations from './translations'

translations.add('es', {
    title: 'Pagos en línea',
    charge: 'Cargar',
    refund: 'Reembolso',
    paymentDetails: 'Detalles del pago',
    chargedAmount: 'Cantidad cobrada',
    refundAmount: 'Cantidad devuelta',
    balance: 'Balance',
    donationAmount: 'Monto de donación',
    date: 'Fecha',
    status: 'Estado',
    summary: 'Resumen',
    verifications: 'Verificaciones',
    channel: 'Canal',
    shop: 'Tienda',
    search: 'Buscar',
    statement: 'Declaración',
    select: 'Seleccionar',
    items: 'Artículos',
    description: 'Descripción',
    customer: 'Cliente',
    orderNumber: 'Número de orden',
    amount: 'Cantidad',
    rnn: 'RNN',
    transactionType: 'Tipo de transacción',
    authorizedOn: 'Autorizada en',
    chargedOn: 'Cargada',
    refundedOn: 'Reembolsada en',
    issuer: 'Editor',
    card: 'Tarjeta',
    is3DSecure: '3DS',
    cardExpiryDate: 'Fecha de expiración de la tarjeta',
    acquirerResponseCode: 'Código de respuesta del adquirente',
    authCode: 'Código de autenticación',
    AVSHouseNumberResult: 'Número de casa AVS',
    AVSPostcodeResult: 'Código postal AVS',
    yes: 'Sí',
    no: 'No',
    payerDetails: 'Detalles del cliente',
    payer: 'Nombre',
    accountId: 'ID de la cuenta',
    email: 'Correo electrónico',
    phone: 'Motivo de rechazo',
    resultMessage: 'Motivo de rechazo',
    geolocation: {
        payerIp: 'IP del pagador',
        location: 'Ubicación'
    },
    paymentPage: {
        title: 'Página de pago',
        language: 'Idioma',
        postLinkAddress: 'Dirección de PostLink',
        postLink: 'PostLink',
        ok: 'Ok',
        failed: 'Fallido'
    },
    signifydDetails: 'Detalles Significados',
    signifydId: 'Identificación significada',
    decision: 'Decisión',
    reason: 'Razón',
    score: 'Puntaje',
    id: 'ID',
    processingTypeName: 'Tipo de procesamiento',
    reference: 'RRN',
    cancelOn: 'Cancelado el',
    avsResult: 'resultado AVS',
    payPal: {
        details: 'Detalles de Paypal',
        paypalOrderStatus: 'Estado del pedido',
        paypalPayerName: 'Nombre del pagador',
        paypalEmail: 'Correo electrónico del pagador',
        paypalPayerID: 'Identificación del pagador',
        paypalPayerCountryCode: 'Código de país del pagador',
        paypalCaptureStatus: 'Estado de captura',
        paypalCaptureStatusReason: 'Motivo del estado de captura',
        paypalSellerProtectionStatus: 'Estado de protección del vendedor',
        paypalSellerProtectionDisputeCategories: 'Categorías de disputas de protección al vendedor'
    },
    searchFilter: {
        transactionId: 'ID de transacción',
        customerAccountId: 'ID de cuenta de cliente',
        description: 'Descripción',
        order: 'Orden #',
        email: {
            startsWith: 'El correo electrónico comienza con'
        },
        customerName: 'Nombre del cliente',
        card: {
            startsWith: 'La tarjeta comienza con',
            endsWith: 'Tarjeta termina en'
        }
    },
    searchFilterErrors: {
        transactionId: 'Por favor ingrese una identificación válida'
    },
    createdDate: 'Fecha de creación (GMT)',
    payoutAmount: 'Importe confirmado',
    currency: 'Divisa',
    payoutDate: 'Fecha confirmada (GMT)',
    processingType: 'Tipo de procesamiento',
    invoiceId: 'Número de orden',
    paymentMethod: 'Métodos de pago',
    cardType: 'Sistema de pagos',
    cardMask: 'Card mask',
    secure3D: '3DS',
    avsHouseNumberResult: 'Resultado de número de casa AVS',
    avsPostcodeResult: 'Resultado del código postal de AVS',
    cscResult: 'resultado CSC',
    paEnrollment: 'Inscripción PA',
    paAuthentication: 'autenticación PA',
    payerName: 'Nombre del cliente',
    payerEmail: 'Correo electrónico del cliente',
    payerPhone: 'Teléfono del cliente',
    payerIp: 'IP del pagador',
    ipCountry: 'País IP',
    ipCity: 'Ciudad IP',
    ipLatitude: 'Latitud IP',
    ipLongitude: 'Longitud IP',
    message: 'Mensaje',
    transactionTypeLower: 'Tipo de transacción',
    initiatorEmail: 'Iniciado por',
    statuses: {
        'Matched': 'Coincide',
        'Not Matched': 'No coincide',
        'Partial Match': 'Coincidencia parcial',
        'Yes': 'Sí',
        'No': 'No',
        'Authenticated': 'Autenticado',
        'Not authenticated': 'No autenticado',
        'Unable to authenticate': 'No se pudo autenticar',
        'Attempted': 'Intentado',
        'Not attempted': 'No intentado',
        'Enrolled': 'Inscrito',
        'Not enrolled': 'No inscrito',
        'Challenge required': 'Requiere desafío',
        'Not Provided': 'No proporcionado',
        'Not Checked': 'No Comprobado',
        'Unable to check': 'No se pudo comprobar',
        'Not Set': 'No configurado',
        'Authentication rejected': 'Autenticación rechazada'
    }
})
