import translations from './translations'
import React from 'react'

translations.add('sl', {
    title: 'PayPal',
    description: (
        <>
            Spoločnosť DNA Payments spolupracuje so spoločnosťou PayPal, aby obchodníkom poskytla
            jednoduchú cestu k pridaniu tohto obľúbeného spôsobu platby pre zákazníkov. Obchodníci s
            existujúcimi účtami PayPal ich môžu prepojiť na tejto stránke alebo ak je potrebné
            vytvoriť PayPal účet, môžete to urobiť aj tu.
            <br />
            <br />
            Po prepojení účtu je možné PayPal možnosť platby ľahko povoliť alebo zakázať pomocou
            možnosti prepínania, čím sa obchodníkovi ponecháva úplná kontrola.
            <br />
            <br />
        </>
    ),
    paypalStatus: 'Paypal status',
    settings: 'Prispôsobiť nastavenia',
    yourAccountDisabled:
        'Vaša nastavitev računa PayPal je trenutno onemogočena. Da začnete znova sprejemati plačila prek PayPal-a, jo prosimo, aktivirajte.',
    consentRevokedText:
        'Soglasja za nastavitev računa PayPal so bila preklicana ali pa je račun trgovca PayPal zaprt.',
    readyPaymentText: 'Ste pripravení prijímať platby cez PayPal.'
})
