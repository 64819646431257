import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    detailedReport: string
    onlinePayments: string
    posPayments: string
    chargedTransactions: string
    chargedTransactionsComparison: string
    howItWorks: string
    merchantPortal: string
    productGuides: string
    paymentLinks: string
    readMore: string
    merchantPortalGuide: string
    onlinePaymentSystem: string
    toHelpYouGetStarted: string
    whereToStart: string
    startEarning: string
    allPosTransactions: string
    currency: string
    noContentTitle: string
}>()

