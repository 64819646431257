import React from 'react'

import {useAuthStore} from '@/stores'
import translations from './translations'
import {LeftOutlined} from '@ant-design/icons'
import styles from './styles.scss'

export const BackToLoginLink = () => {
    const {backToLogin} = useAuthStore()

    return (
        <a href={'#'} onClick={(e) => {
            e.preventDefault()
            backToLogin()
        }} className={styles.backToLogin}>
            <span> <LeftOutlined />{translations().back} </span>
        </a>
    )
}
