import React from 'react'
import {Typography, Spin} from 'antd'
import {observer} from 'mobx-react'
import {Modal} from '@/components'
import {LoadingOutlined} from '@ant-design/icons'
import {SettlementsPdfDownloadLoadingModalProps} from './props'

import translations from './translations'
import styles from './styles.scss'

export const SettlementsPdfDownloadLoadingModal = observer(
    ({isOpen, onCancel}: SettlementsPdfDownloadLoadingModalProps) => {
        return (
            <Modal
                width={'408px'}
                open={isOpen}
                footer={null}
                centered={true}
                onCancel={onCancel}
                closable={false}
            >
                <div className={styles.spinHolder}>
                    <Spin indicator={<LoadingOutlined className={styles.loadingIcon} spin />} />
                </div>

                <Typography.Title level={5} className={styles.title}>
                    {translations().title}
                </Typography.Title>
                <Typography.Text className={styles.text}>{translations().text}</Typography.Text>
            </Modal>
        )
    }
)
