import React, { useEffect } from 'react'
import { Button, Spin, Table } from 'antd'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { DownloadOutlined } from '@ant-design/icons'

import { PageHeader, PaginationContainer, RefreshButton, SelectWithTitle } from '@/components'
import { useScreenType } from '@/hooks'
import {withTitle} from '@/router/utils'
import { MobileExportsList } from '@/pages/Exports/components'
import { TExportStore } from '@/pages/Exports/TExportStore'
import { JobStatusEnum } from '@/pages/Exports/models/JobStatusEnum'
import { getColumns } from './utils'
import translations from './translations'
import styles from './styles.scss'

export const ExportsStoreSymbol = Symbol('ExportsStore')
export const ExportsPaginationContainerSymbol = Symbol('ExportsPaginationContainer')

const Exports = observer(() => {

    const { exports, isLoading, refresh, options, selectOption, selectedOption } = useInjection<TExportStore>(ExportsStoreSymbol)
    const { isMobile } = useScreenType()

    useEffect(() => {
        refresh()
    }, [])

    const renderContent = () => (
        <>
            <PageHeader
                onBack={null}
                title={translations().title}
                extra={
                    <div className={styles.filterContainer}>
                        <div></div>
                        <RefreshButton onClick={refresh} />
                        <div>
                            <SelectWithTitle
                                displayValue={translations().documentType}
                                options={options}
                                popupClassName={styles.selectDropdown}
                                onChange={selectOption}
                                value={selectedOption.value}
                            />
                        </div>
                    </div>
                }
            />
            {isMobile ? (
                <>
                    <PaginationContainer injectableIdentifier={ExportsPaginationContainerSymbol} />

                    <MobileExportsList
                        isLoading={isLoading}
                        dataSource={exports}
                        listExtra={(item) => item.status === JobStatusEnum.FINISHED && item.url && (
                            <Button type={'link'} href={item.url} size={'middle'} icon={<DownloadOutlined />} />
                        )}
                    />
                </>
            ) : (
                <>
                    <Table
                        size={'large'}
                        columns={getColumns()}
                        dataSource={exports}
                        pagination={false}
                        rowKey={(exportItem) => exportItem.rowId}
                        scroll={{ x: 1000 }}
                    />
                    <PaginationContainer injectableIdentifier={ExportsPaginationContainerSymbol} />
                </>
            )}
        </>
    )

    return <Spin spinning={isLoading}>{renderContent()}</Spin>
})

export default withTitle(Exports)
