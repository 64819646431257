import React from 'react'
import classNames from 'classnames'
import {useScreenType} from '@/hooks/useScreenType'
import {CardTitleProps} from './props'
import styles from './styles.scss'
import {CHART_TITLE_TEXT} from '@/constants/playwright-ids'

export const CardTitle = ({title = '', children}: CardTitleProps) => {
    const screenType = useScreenType()

    return <div className={classNames(styles.title, {[styles.titleMobile]: screenType.isMobile})}>
        <div className={styles.titleText} data-test-id={CHART_TITLE_TEXT}>{title}</div>
        {children}
    </div>
}
