import React from 'react'
import classNames from 'classnames'
import { Icon } from '@/components/dumb/Icon'
import { BurgerProps } from './props'
import styles from './styles.scss'

export const Burger: React.FC<BurgerProps> = ({ className, ...rest }) => (
    <div className={styles.burger}>
        <Icon
            type='burger_menu'
            className={classNames(styles.burger, className)}
            {...rest}
        />
    </div>
)
