import {action, computed, IComputedValue, makeObservable, observable} from 'mobx'
import {Language, Translation} from '@/components/dumb/Translation/Translation'
import {storage} from '@/services/storage'

const DEFAULT_LANGUAGE: Language = 'en'

class TranslationBuilderSingleton {
    constructor() {
        this.setLang(storage.get('lang') || DEFAULT_LANGUAGE)

        makeObservable(this, {
            _lang: observable,
            lang: computed,
            setLang: action
        })
    }

    _lang: Language

    public get lang() {
        return this._lang
    }

    public setLang(value) {
        storage.set('lang', value)
        this._lang = value
    }

    public getSelectedLang(): Language {
        return storage.get('lang') || DEFAULT_LANGUAGE
    }

    public getIsGreekLanguageSelected(): boolean {
        return storage.get('lang') === 'el'
    }

    public create<T>(): Translation<T> {
        const map = new Map<string, T>()
        const currentLanguageMap: IComputedValue<T> = computed(
            () => map.get(this._lang) || map.get(DEFAULT_LANGUAGE)
        )

        const translation: Translation<T> = () => currentLanguageMap.get()
        translation.add = (lang, value) => {
            map.set(lang, value)
            return translation
        }

        return translation
    }
}

const translationBuilder = new TranslationBuilderSingleton()

export {translationBuilder as TranslationBuilder}
