import React from 'react'
import translations from './translations'

translations.add('el', {
    buttons: {
        home: 'Πήγαινε στην κεντρική σελίδα'
    },
    errors: {
        ourSupportTeamIsHereToHelp: <>
            Η λειτουργία απέτυχε, η ομάδα υποστήριξής μας είναι εδώ για να βοηθήσει: <a href={'https://dnapayments.com/online-quote#2'} target={'_blank'} rel={'noreferrer'}>Υποστήριξη</a>
        </>
    }
})
