import React from 'react'
import classNames from 'classnames'
import {EcospendBlockProps} from './props'
import styles from './EcospendBlock.scss'

export const EcospendBlock: React.FC<EcospendBlockProps> = (props) => {
    const {className, children, ...rest} = props
    return <div className={classNames(styles.ecospendBlock, className)} {...rest}>
        {children}
    </div>
}
