import translations from './translations'

translations.add('en', {
    shop: 'Store',
    shopName: 'Store name',
    orderNumber: 'Order number',
    description: 'Description',
    customerName: 'Customer name',
    postLink: 'Post link',
    customerId: 'Customer account ID',
    email: 'Email',
    rrn: 'RRN',
    receivalReferenceNumber: 'Receival reference number',
    amount: {
        label: 'Amount',
        from: 'from',
        to: 'to'
    },
    cardMask: {
        label: 'Card',
        first: 'First 6 digits',
        last: 'Last 4'
    },
    cardScheme: 'Card scheme',
    donationAmount: {
        label: 'Donation amount',
        from: 'from',
        to: 'to'
    },
    paymentMethod: 'Payment method',
    currency: 'Currency'
})
