import {computed, makeAutoObservable} from 'mobx'
import {injectable} from 'inversify'
import {ApiResponse} from 'back-connector'

import {BaseDataStore} from '@/stores/shared/BaseDataStore'

import {fetchAvailableStoresOfMerchantV2} from '../api'
import {convertStoresToSelectItems} from '../utils'
import {DossierStoreType} from '../types'

@injectable()
export class DossierStoresWithTerminalsDataStore extends BaseDataStore<DossierStoreType[]> {
    get selectItems() {
        return convertStoresToSelectItems(this.data)
    }

    constructor() {
        super()

        makeAutoObservable(this, {
            ...this.getAnnotationsMap(),
            selectItems: computed
        })
    }

    protected async fetchData(): Promise<ApiResponse<DossierStoreType[]>> {
        return fetchAvailableStoresOfMerchantV2()
    }

    protected getDefaultData(): DossierStoreType[] {
        return []
    }
}
