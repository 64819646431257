import React from 'react'
import {Menu, Typography} from 'antd'
import {ColumnsType} from 'antd/es/table'
import {EpayPayment} from '@/api'
import {DateTimeLabel, PaymentMethodOrMaskedCard, TableStatus} from '@/components'
import {OnlinePaymentStatus, PaymentMethodType} from '@/constants'
import {ActionButtons} from '@/pages/components'
import {TOnlinePaymentsStore} from '@/pages/OnlinePayments'
import {
    getOnlinePaymentStatusIconType,
    getOnlinePaymentStatusName,
    getOnlinePaymentStatusTagType
} from '@/pages/OnlinePayments/services'
import {getAmountWithCurrency} from '@/utils'
import {hasPermissions} from '@/stores/auth/services/utils'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {TransactionType} from '@/types/transactions'
import translations from '../translations'

const renderActionsMenu = (object: EpayPayment, transactionsStore: TOnlinePaymentsStore) => {
    switch (object.status) {
        case OnlinePaymentStatus.auth:
            return (
                <ActionButtons
                    menu={
                        <Menu
                            items={[
                                ...(hasPermissions([PermissionsMap.online_payments.full])
                                    ? [
                                          {
                                              key: '1',
                                              label: (
                                                  <a
                                                      onClick={(e) => {
                                                          e.stopPropagation()
                                                          transactionsStore.openCancelPaymentModal(
                                                              object
                                                          )
                                                      }}
                                                  >
                                                      {translations().actions.cancel}
                                                  </a>
                                              )
                                          },
                                          {
                                              key: '2',
                                              label: (
                                                  <a
                                                      onClick={(e) => {
                                                          e.stopPropagation()
                                                          transactionsStore.openPaymentModal(object)
                                                      }}
                                                  >
                                                      {translations().actions.charge}
                                                  </a>
                                              )
                                          }
                                      ]
                                    : []),
                                {
                                    key: '3',
                                    label: (
                                        <a
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                transactionsStore.openDrawer(object)
                                            }}
                                        >
                                            {translations().viewDetails}
                                        </a>
                                    )
                                }
                            ]}
                        />
                    }
                />
            )
        case OnlinePaymentStatus.charge:
            const isRefundDisabled =
                object.paymentMethod === PaymentMethodType.ecospend &&
                !object.additionalDetails?.refundSupported

            return (
                <ActionButtons
                    menu={
                        <Menu
                            items={[
                                ...(hasPermissions([PermissionsMap.online_payments.full]) &&
                                object.balance > 0
                                    ? [
                                          {
                                              key: '1',
                                              disabled: isRefundDisabled,
                                              label: (
                                                  <a
                                                      onClick={(e) => {
                                                          e.stopPropagation()
                                                          if (!isRefundDisabled) {
                                                              transactionsStore.openPaymentModal(
                                                                  object
                                                              )
                                                          }
                                                      }}
                                                  >
                                                      {translations().actions.refund}
                                                  </a>
                                              )
                                          }
                                      ]
                                    : []),
                                {
                                    key: '2',
                                    label: (
                                        <a
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                transactionsStore.openDrawer(object)
                                            }}
                                        >
                                            {translations().viewDetails}
                                        </a>
                                    )
                                }
                            ]}
                        />
                    }
                />
            )
        default:
            return (
                <ActionButtons
                    menu={
                        <Menu
                            items={[
                                {
                                    key: '1',
                                    label: (
                                        <a onClick={() => transactionsStore.openDrawer(object)}>
                                            {translations().viewDetails}
                                        </a>
                                    )
                                }
                            ]}
                        />
                    }
                />
            )
    }
}

export const getColumns = (transactionsStore: TOnlinePaymentsStore): ColumnsType<EpayPayment> => {
    return [
        {
            title: translations().columns.amount,
            dataIndex: 'amount',
            key: 'amount',
            align: 'right' as any,
            width: 150,
            render: (item, object) => <b>{getAmountWithCurrency(item, object.currency)}</b>
        },
        {
            title: translations().columns.status,
            dataIndex: 'status',
            key: 'status',
            align: 'left' as any,
            width: 150,
            render: (item, object) => {
                if (
                    object.status === OnlinePaymentStatus.credited &&
                    object.transactionType === TransactionType.ADJUSTMENT
                ) {
                    return (
                        <TableStatus
                            statusProps={{
                                status: getOnlinePaymentStatusName(item),
                                type: getOnlinePaymentStatusTagType(item),
                                iconType: getOnlinePaymentStatusIconType(OnlinePaymentStatus.refund)
                            }}
                        />
                    )
                }
                return (
                    <TableStatus
                        statusProps={{
                            status: getOnlinePaymentStatusName(item),
                            type: getOnlinePaymentStatusTagType(item),
                            iconType: getOnlinePaymentStatusIconType(item)
                        }}
                    />
                )
            }
        },
        {
            title: translations().columns.customer,
            dataIndex: 'payerName',
            key: 'payerName',
            render: (item) => (item ? item : '-')
        },
        {
            title: translations().columns.paymentMethod,
            dataIndex: 'cardMask',
            key: 'cardMask',
            render: (item, object) => (
                <PaymentMethodOrMaskedCard
                    paymentMethod={object.paymentMethod}
                    cardScheme={object.cardType}
                    cardMask={object.cardMask}
                />
            )
        },
        {
            title: translations().columns.orderNumber,
            dataIndex: 'invoiceId',
            key: 'invoiceId',
            render: (item) =>
                item ? (
                    <Typography.Text copyable={true} className='nowrap'>
                        {item}
                    </Typography.Text>
                ) : (
                    '-'
                )
        },
        {
            title: translations().columns.date,
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (item) => <DateTimeLabel date={item} />
        },
        {
            title: translations().columns.action,
            width: 120,
            key: 'x',
            render: (item, object) => renderActionsMenu(object, transactionsStore)
        }
    ]
}

export const removePercentSymbolFromStrings = (object: {[key: string]: any}) => {
    if (!object) {
        return {}
    }
    const replaceAll = (str: string, find: string, replace: string) => {
        return str.replace(new RegExp(find, 'g'), replace)
    }

    Object.keys(object).forEach((key) => {
        const value = object[key]
        if (typeof value === 'string') {
            object[key] = replaceAll(value, '%', '')
        }
    })

    return object
}
