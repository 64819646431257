import translations from './translations'

translations.add('el', {
    title: 'Μπλοκάρισμα κάρτας',
    ohSeemsLikeSomethingBadHappened: '- Φαίνεται ότι συνέβη κάτι ασχημο στην κάρτα σας;',
    pleaseClarifyTheProblem: 'Παρακαλώ διευκρινίστε το πρόβλημα',
    myCardWasStolen: 'Μου έκλεψαν την κάρτα',
    iveLostMyCard: 'Έχασα την κάρτα μου',
    cardIsDamaged: 'Η κάρτα είναι κατεστραμμένη',
    cancel: 'Ακύρωση',
    blockCard: 'Μπλοκάρισμα κάρτας'
})
