import React from 'react'
import translations from './translations'

translations.add('en', {
    hereWillBeTheList: 'Here will be the list of your domains',
    textWithExplanationOfDomain: <>
        You have no verified domains
        <br/>
        In order to add your domains, please use the &quot;Add Domains&quot; button
    </>,
    addNewDomain: 'Add new domain'
})
