import translations from './translations'

translations.add('el', {
    buttons: {
        send: 'Στείλτε',
        cancel: 'Ακύρωση',
        sendReceipt: 'Αποστολή receipt'
    },
    labels: {
        email: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου'
    },
    errors: {
        emailInvalid: 'Το email δεν είναι έγκυρο',
        emailRequired: 'απαιτείται email'
    }
})
