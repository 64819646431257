import React from 'react'
import {Col, Row} from 'antd'
import classNames from 'classnames'
import {Icon} from '@/components'
import translations from './translations'
import styles from './styles.scss'
import {Currencies} from '@/constants'

type Props = {
    income: number
    outgoing: number
    incomeCurrency: Currencies
    outgoingCurrency: Currencies
}

export const IncomeOutcome: React.FC<Props> = (
    {income, outgoing, incomeCurrency, outgoingCurrency}
) => {
    return (
        <Row className={styles.container}>
            <Col className={classNames(styles.income, styles.text)}>
                <Icon type={'arrow_up'}/>
                {translations().income(income, incomeCurrency)}
            </Col>
            <Col className={classNames(styles.outgoing, styles.text)}>
                <Icon type={'arrow_down'}/>
                {translations().outgoing(outgoing, outgoingCurrency)}
            </Col>
        </Row>
    )
}
