import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import {getEpayClients} from '@/stores'
import {DoughnutChartData, TSelectContainerStore} from '@/components'
import {openErrorNotification} from '@/utils'
import {TClientsDoughnutChartStore} from '@/pages/Reports/components'
import {TRangePickerContainerStore} from '@/components/containers'
import {
    getDoughnutClientsChartData,
    ReportsECOMCurrencySelectStoreSymbol,
    ReportsECOMRangePickerStoreSymbol
} from '@/pages/Reports'
import {LoadingState} from '@/types'
import {emptyDoughnutChartData} from '@/services/mocks'
import translations from '@/translations'

@injectable()
export class ClientsDoughnutChartStore implements TClientsDoughnutChartStore {
    private _rangePickerStore: TRangePickerContainerStore
    private _currencySelectStore: TSelectContainerStore

    constructor(
        @inject(ReportsECOMRangePickerStoreSymbol) rangePickerStore: TRangePickerContainerStore,
        @inject(ReportsECOMCurrencySelectStoreSymbol) currencySelectStore: TSelectContainerStore
    ) {
        this._rangePickerStore = rangePickerStore
        this._currencySelectStore = currencySelectStore

        makeObservable(this, {
            _data: observable,
            data: computed,
            loadClientsChartData: action.bound
        })

        reaction(
            () => ({
                from: this._rangePickerStore.startDate,
                to: this._rangePickerStore.endDate,
                currency: this._currencySelectStore.value
            }),
            ({from, to, currency}) => {
                if (from && to && currency) {
                    this.loadClientsChartData()
                }
            }
        )

        this.loadClientsChartData()
    }

    _data: DoughnutChartData = null
    loadingState = LoadingState.IDLE

    async loadClientsChartData() {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.loadingState = LoadingState.LOADING
        })

        const request = {
            from: this._rangePickerStore.startDate.format(),
            to: this._rangePickerStore.endDate.format(),
            status: '',
            top: 5,
            currency: this._currencySelectStore.value
        }

        try {
            const {result, error} = await getEpayClients(request)

            runInAction(() => {
                this.loadingState = LoadingState.DONE

                if (error) {
                    this.loadingState = LoadingState.FAILED
                    return openErrorNotification(error.message)
                }

                if (result) {
                    this._data =
                        result.new !== 0 || result.returning !== 0
                            ? getDoughnutClientsChartData(result)
                            : emptyDoughnutChartData
                }
            })
        } catch (error) {
            runInAction(() => {
                this.loadingState = LoadingState.FAILED
                return openErrorNotification(translations().errors.general)
            })
        }
    }

    get data() {
        if (
            this._data &&
            this.loadingState !== LoadingState.LOADING &&
            this.loadingState !== LoadingState.DONE
        ) {
            this.loadClientsChartData()
        }

        return this._data
    }
}
