import {inject, injectable} from 'inversify'
import {makeAutoObservable, when} from 'mobx'
import {CardIssuingConfigStoreInterface, fetchAccountTokens, TokenType} from '@/stores'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import {LoadingState} from '@/types'
import {AccountData, AccountTokenParams} from '@/stores/business-account/account-info/constants'
import {openErrorNotification} from '@/utils'

@injectable()
export class CardIssuingConfigsStore implements CardIssuingConfigStoreInterface {
    _tokens: TokenType = {
        merchant: null,
        account: null,
        card: null,
        public: null
    }
    tokensLoadingState: LoadingState = LoadingState.IDLE
    accountNumber: string
    iban: string
    sortCode: string

    constructor(@inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface) {
        when(
            () => Boolean(profileStore?.profileData?.email),
            () => this.fetchTokens()
        )

        makeAutoObservable(this)
    }

    get tokens(): TokenType {
        return this._tokens
    }

    setTokens = (tokens: TokenType) => {
        this._tokens = tokens
    }

    fetchTokens = async () => {
        try {
            if (this.tokensLoadingState === LoadingState.LOADING) {
                return
            }

            this.tokensLoadingState = LoadingState.LOADING

            const {result, error} = await fetchAccountTokens()

            if (error) {
                this.tokensLoadingState = LoadingState.FAILED
                return openErrorNotification(error.message)
            } else {
                this.setTokens({
                    merchant: result.companyToken,
                    account: result.walletToken,
                    card: result.cardToken,
                    public: result.publicToken
                })

                this.iban = result.iban
                this.accountNumber = result.accountNumber
                this.sortCode = result.sortCode

                this.tokensLoadingState = LoadingState.DONE
            }
        } catch (error) {
            this.tokensLoadingState = LoadingState.FAILED
            return openErrorNotification(error.message)
        }
    }
}
