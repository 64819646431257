import translations from './translations'

translations.add('en', {
    title: 'Card blocking',
    ohSeemsLikeSomethingBadHappened: '-  Oh, seems like something bad happened to your card?',
    pleaseClarifyTheProblem: 'Please clarify the problem',
    myCardWasStolen: 'My card was stolen',
    iveLostMyCard: 'I\'ve lost my card',
    cardIsDamaged: 'The card is damaged',
    cancel: 'Cancel',
    blockCard: 'Block card'
})
