import translations from './translations'

translations.add('en', {
    showAll: 'Show all',
    dataTypes: {
        amount: 'Amount',
        count: 'Number'
    },
    week: 'Week',
    previousRangeData: 'Previous',
    currentRangeData: 'Current'
})
