import React from 'react'
import classNames from 'classnames'
import {addZeroes} from '@/utils'
import {EcospendBlock} from '../EcospendBlock'
import {EcospendField} from '../EcospendField'
import translations from './translations'
import {EcospendBankBlockProps} from './props'
import styles from './EcospendBankBlock.scss'

export const EcospendBankBlock: React.FC<EcospendBankBlockProps> = (props) => {
    const {className, friendlyName, invoiceId, amount} = props

    return <EcospendBlock className={classNames(styles.ecospendBankBlock, className)}>
        <EcospendField className={styles.bankField} label={translations().bank}>
            {friendlyName}
        </EcospendField>
        <EcospendField className={styles.bankField} label={translations().amount}>
            {addZeroes(amount)}
        </EcospendField>
        <EcospendField className={styles.bankField} label={translations().reference}>
            {invoiceId}
        </EcospendField>
    </EcospendBlock>
}
