import translations from './translations'

translations.add('ro', {
    paymentDetails: 'Detalii plată',
    date: 'Dată',
    transactionDate: 'Dată tranzacție (GMT)',
    amount: 'Sumă',
    status: 'Status',
    terminalId: 'ID terminal',
    card: 'Card',
    details: 'Detalii',
    resultCode: 'Cod rezultat',
    message: 'Mesaj',
    cardType: 'Tip card',
    cardCategory: 'Categorie card',
    corporate: 'Corporativ',
    personal: 'Personal',
    europeanCard: 'Card european',
    captureMethod: 'Metodă de captură',
    paymentMethod: 'Metodă de plată',
    transaction: {
        id: 'ID tranzacție',
        type: 'Tipul tranzacției',
        details: 'Detalii tranzacție',
        country: 'Țară tranzacție',
        city: 'Oraș tranzacție'
    },
    currency: 'Moneda',
    isCorporateCard: 'Categorie card',
    returnCode: 'Cod rezultat',
    returnCodeDescription: 'Mesaj',
    mid: 'MID',
    issuerRegion: 'Regiune emitent',
    cardScheme: 'Schemă card',
    cardMask: 'Masca cardului'
})
