import {injectable} from 'inversify'
import {makeObservable} from 'mobx'

import {TerminalType} from '@/constants'
import {FilterFormFieldType} from '@/types'
import {getNotEmptyValues} from '@/utils'
import {BaseFilterContainerStore} from '@/stores/shared'
import {StoreMidTerminalFilterStore} from '@/stores/store-and-terminals'

import rootTranslations from '@/translations'
import {SettlementsFilterForServerType, SettlementsFilterType} from '../models'
import {AccountNumbersDataStore} from './AccountNumbersDataStore'

@injectable()
export class SettlementsFiltersStore extends BaseFilterContainerStore<
    SettlementsFilterType,
    SettlementsFilterForServerType
> {
    private _smtfStore = new StoreMidTerminalFilterStore({
        useAllData: true
    })
    private _accountNumbersStore = new AccountNumbersDataStore()

    constructor() {
        super()

        makeObservable(this, {
            ...super.getAnnotationsMap()
        })
    }

    get loadingState() {
        return this._smtfStore.loadingState
    }

    get isLoading() {
        return this._smtfStore.isLoading || this._accountNumbersStore.isLoading || this.isDisabled
    }

    get filter(): SettlementsFilterType {
        return {
            ...this._filter,
            terminalType: this._smtfStore.selectedTerminalType,
            store: this._smtfStore.selectedStore,
            mid: this._smtfStore.selectedMid,
            terminalId: this._smtfStore.selectedTerminal
        }
    }

    get fields(): FilterFormFieldType[] {
        return [
            {
                name: 'accountNumber',
                label: rootTranslations().fields.accountNumber,
                type: 'select',
                isHidden: this._accountNumbersStore.selectItemsWithAll.length === 0,
                data: {
                    options: this._accountNumbersStore.selectItemsWithAll
                }
            },
            {
                name: 'terminalType',
                label: rootTranslations().fields.terminalType,
                type: 'select',
                isHidden: this._smtfStore.terminalTypesAsSelectItemsWithAll.length < 2,
                data: {
                    options: this._smtfStore.terminalTypesAsSelectItemsWithAll
                }
            },
            {
                name: 'store',
                label: rootTranslations().fields.store,
                type: 'selectWithTwoLabels',
                isHidden:
                    this._smtfStore.storesAsSelectItemsWithAll.length === 0 ||
                    this._smtfStore.selectedTerminalType !== TerminalType.POS,
                data: {
                    optionsWithTwoLabels: this._smtfStore.storesAsSelectItemsWithAll
                }
            },
            {
                name: 'mid',
                label: rootTranslations().fields.mid,
                type: 'select',
                isHidden: this._smtfStore.midsAsSelectItemWithAll.length === 0,
                data: {
                    options: this._smtfStore.midsAsSelectItemWithAll
                }
            },
            {
                name: 'terminalId',
                label: rootTranslations().fields.terminalId,
                type: 'select',
                isHidden: this._smtfStore.terminalsAsSelectItemWithAll.length === 0,
                data: {
                    options: this._smtfStore.terminalsAsSelectItemWithAll
                }
            }
        ]
    }

    isFilterSelected(field: string, value: any): boolean {
        if (field === 'store' && this._smtfStore.storesAsSelectItemsWithAll.length === 1) {
            return false
        }
        return super.isFilterSelected(field, value)
    }

    getInitialFilter(): SettlementsFilterType {
        return {
            accountNumber: null
        }
    }

    setFilter(filter: Partial<SettlementsFilterType>) {
        const {terminalType, store, mid, terminalId, ...rest} = filter
        super.setFilter(rest)

        if (terminalType !== undefined) {
            this._smtfStore.setTerminalType(terminalType as any)
        }
        if (store !== undefined) {
            this._smtfStore.setStore(store)
        }
        if (mid !== undefined) {
            this._smtfStore.setMid(mid)
        }
        if (terminalId !== undefined) {
            this._smtfStore.setTerminal(terminalId as string)
        }
    }

    clear() {
        this._smtfStore.clear()
        super.clear()
    }

    getFilterForBackend = (): SettlementsFilterForServerType => {
        /**
         * The filter fields Terminal Type, Store, and MID are exclusively utilized in the user interface (UI)
         * for filtering mids before they are sent to the server.
         */
        return getNotEmptyValues({
            accountNumber: this.submittedFilter.accountNumber,
            ...this._smtfStore.requestForServer
        })
    }
}
