import React, {createRef, useRef} from 'react'
import {Col, Row, Input, InputRef, Form} from 'antd'
import {observer} from 'mobx-react'
import {NUMBER_PATTERN} from '@/constants/patterns'
import {PinInputProps} from './props'
import styles from './styles.scss'

export const PinInput: React.FC<PinInputProps> = observer(({length, form}) => {
    const inputsRef = useRef(Array.from({length}).map(() => createRef<InputRef>()))

    return (
        <Row gutter={[16, 16]} justify={'start'}>
            {inputsRef.current.map((inputRef, index) => {
                return (
                    <Col key={`key-${index}`}>
                        <Form.Item
                            name={`input-${index}`}
                            rules={[
                                {
                                    required: true,
                                    message: ''
                                }
                            ]}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const onlyNumberRegexp = NUMBER_PATTERN
                                const value = e.currentTarget.value
                                if (!onlyNumberRegexp.test(value)) {
                                    return form.getFieldValue(`input-${index}`)
                                } else if (value.length > 1) {
                                    return value[1]
                                }

                                return value
                            }}
                        >
                            <Input
                                onClick={() => inputRef.current?.focus({cursor: 'end'})}
                                ref={inputRef}
                                className={styles.input}
                                onChange={e => {
                                    if (e.target.value.length > 0 && inputsRef.current[index + 1]) {
                                        inputsRef.current[index + 1].current?.focus()
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                )
            })}
        </Row>
    )
})
