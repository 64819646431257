import React from 'react'
import {Result} from 'antd'
import {observer} from 'mobx-react'
import {useSearchParams} from 'react-router-dom'

import {withTitle} from '@/router/utils'
import {Button, Card} from '@/components'
import {useInjection} from 'dna-react-ioc'
import {TResetPasswordFailureStore} from './TResetPasswordFailureStore'
import translations from './translations'
import {TRY_RESET_PASSWORD_AGAIN} from '@/constants/playwright-ids'

export const ResetPasswordFailureStoreSymbol = Symbol('ResetPasswordFailureStore')

const ResetPasswordFailure = observer(() => {
    const [queryParams] = useSearchParams()
    const m = queryParams.get('m') || ''
    const store = useInjection<TResetPasswordFailureStore>(ResetPasswordFailureStoreSymbol)

    return <>
        <Card>
            <Result
                status={'error'}
                title={translations().somethingWentWrong}
                subTitle={m ? m : translations().unexpectedError}
                extra={(
                    <Button
                        type={'primary'}
                        key={'console'}
                        onClick={store.onTryAgainClick}
                        data-test-id={TRY_RESET_PASSWORD_AGAIN}
                    >
                        {translations().tryAgain}
                    </Button>
                )}
            />
        </Card>
    </>
})

export default withTitle(ResetPasswordFailure)
