import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable, runInAction} from 'mobx'

import {LoadingState} from '@/types'
import {CreatePayLinkRequest, createPaymentLink, PaymentLink} from '@/api'
import {openErrorNotification, openSuccessNotification} from '@/utils'

import {
    PayByLinkLinkDetailsModalContainerStoreSymbol,
    PayByLinkNewPaymentLinkModalContainerStoreSymbol,
    PayByLinkPaymentLinkDetailsStoreSymbol
} from '@/pages/PayByLink'
import translations from '@/pages/components/NewPaymentLinkModal/translations'
import {TModalContainerStore} from '@/components/containers/ModalContainer/TModalContainerStore'
import {TNewPaymentLinkModalStore, TPaymentLinkDetailsModalStore} from '@/pages/components'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol
} from '@/stores/store-and-terminals'
import {
    formatCreatePayLinkRequest,
    getEcomTerminalsSelectType
} from '@/stores/pay-by-link/services/utils'
import {MerchantTerminalSelectType} from '@/stores/pay-by-link/models/MerchantTerminalSelectType'
import rootTranslations from '@/translations/translations'

@injectable()
export class NewPaymentLinkModalStore implements TNewPaymentLinkModalStore {
    private readonly _linkDetailsModalStore: TModalContainerStore
    private readonly _newPaymentLinkModalStore: TModalContainerStore
    private readonly _merchantStoresStore: AvailableStoresDataStore
    private readonly _paymentLinkDetailsModalStore: TPaymentLinkDetailsModalStore

    loadingState = LoadingState.IDLE
    paymentLink: PaymentLink = {} as PaymentLink

    get terminals(): MerchantTerminalSelectType[] {
        return getEcomTerminalsSelectType(this._merchantStoresStore.ecomStores)
    }

    constructor(
        @inject(PayByLinkLinkDetailsModalContainerStoreSymbol)
        linkDetailsModalStore: TModalContainerStore,
        @inject(PayByLinkNewPaymentLinkModalContainerStoreSymbol)
        newPaymentLinkModalStore: TModalContainerStore,
        @inject(AvailableStoresDataStoreSymbol) merchantStoresStore: AvailableStoresDataStore,
        @inject(PayByLinkPaymentLinkDetailsStoreSymbol)
        paymentLinkDetailsModalStore: TPaymentLinkDetailsModalStore
    ) {
        this._linkDetailsModalStore = linkDetailsModalStore
        this._newPaymentLinkModalStore = newPaymentLinkModalStore
        this._merchantStoresStore = merchantStoresStore
        this._paymentLinkDetailsModalStore = paymentLinkDetailsModalStore

        makeObservable(this, {
            loadingState: observable,
            paymentLink: observable,
            terminals: computed,
            createNewPaymentLink: action.bound,
            createSimilarPaymentLink: action.bound,
            setPaymentLink: action.bound,
            closeModals: action.bound,
            reset: action.bound
        })
    }

    public setPaymentLink(paymentLink: PaymentLink) {
        this.paymentLink = paymentLink
    }

    public createSimilarPaymentLink(similarPaymentLink: PaymentLink) {
        this.setPaymentLink(similarPaymentLink)
        this._linkDetailsModalStore.setOpen(false)
        this._newPaymentLinkModalStore.setOpen(true)
    }

    public reset() {
        this.setPaymentLink(null)
    }

    public closeModals() {
        this._newPaymentLinkModalStore.setOpen(false)
        this._linkDetailsModalStore.setOpen(false)
    }

    onCreateSuccess = (result) => {
        this.loadingState = LoadingState.DONE
        this._paymentLinkDetailsModalStore.widgetType = 'linkDetails'
        this._paymentLinkDetailsModalStore.isNewLinkDetails = true
        this._paymentLinkDetailsModalStore.setPaymentLink(result)
        this._newPaymentLinkModalStore.setOpen(false)
        this._linkDetailsModalStore.setOpen(true)
    }

    public async createNewPaymentLink(values: CreatePayLinkRequest) {
        const defaultTerminalId =
            this._merchantStoresStore.ecomStores &&
            this._merchantStoresStore.ecomStores[0]?.terminals &&
            this._merchantStoresStore.ecomStores[0]?.terminals.find(
                (terminal) => terminal.isActive && terminal.isDefault
            )?.id

        const activeTerminalId =
            this._merchantStoresStore.ecomStores &&
            this._merchantStoresStore.ecomStores[0]?.terminals &&
            this._merchantStoresStore.ecomStores[0]?.terminals.find((terminal) => terminal.isActive)
                ?.id

        const terminalId = defaultTerminalId ? defaultTerminalId : activeTerminalId

        const formattedValues = formatCreatePayLinkRequest(values, terminalId)

        this.loadingState = LoadingState.LOADING

        const isFirefox = navigator.userAgent.indexOf('Firefox') != -1

        if (isFirefox) {
            try {
                const {result, error} = await createPaymentLink(formattedValues)

                runInAction(() => {
                    if (error) {
                        this.loadingState = LoadingState.FAILED
                        return openErrorNotification(error.message)
                    }

                    this.onCreateSuccess(result)
                    navigator.clipboard.writeText(result.url)

                    return openSuccessNotification(
                        translations().labels.theNewPaymentLinkIsCreatedAndCopiedToClipboard
                    )
                })
            } catch (error) {
                this.loadingState = LoadingState.FAILED
                return openErrorNotification(rootTranslations().errors.general)
            }
        } else {
            await runInAction(async () => {
                const text = new ClipboardItem({
                    'text/plain': createPaymentLink(formattedValues)
                        .then(({result, error}) => {
                            if (error) {
                                this.loadingState = LoadingState.FAILED
                                throw new Error(error.message)
                            }

                            this.onCreateSuccess(result)

                            return new Blob([result.url], {type: 'text/plain'})
                        })
                        .catch((error) => {
                            this.loadingState = LoadingState.FAILED
                            openErrorNotification(rootTranslations().errors.general)
                            throw new Error(error.message)
                        })
                })

                await navigator.clipboard.write([text])

                return openSuccessNotification(
                    translations().labels.theNewPaymentLinkIsCreatedAndCopiedToClipboard
                )
            })
        }
    }
}
