import React from 'react'
import {Empty, Typography} from 'antd'
import {Button} from '@/components'
import {hasPermissions} from '@/stores/auth/services/utils'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import translations from './translations'
import styles from './styles.scss'

type Props = {
    onClick: () => void
}

export const NoDomains: React.FC<Props> = ({onClick}) => {
    return (
        <div className={styles.container}>
            <br/><br/>
            <Typography.Title level={5}>
                {translations().hereWillBeTheList}
            </Typography.Title>
            <Empty description={<Typography.Text type={'secondary'}>{translations().textWithExplanationOfDomain}</Typography.Text>}/>
            {
                hasPermissions([PermissionsMap.payment_methods.full]) ?
                <Button
                    onClick={onClick}
                    className={styles.btn}
                    type={'primary'}
                >
                    + {translations().addNewDomain}
                </Button> : null
            }
        </div>
    )
}
