import translations from './translations'

translations.add('sl', {
    labels: {
        email: 'Email',
        firstName: 'Krstné meno',
        lastName: 'Priezvisko',
        store: 'Obchod',
        status: 'Stav',
        authMethod: 'Metoda Preverjanja',
        active: 'Aktívny'
    }
})
