import 'reflect-metadata'
import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import {LoadingState} from '@/types'
import {openErrorNotification} from '@/utils'
import {PaymentMethodMerchantStoreModel, IPaypalPaymentMethodStore} from '@/pages/payment-methods'
import {
    ActivateTerminalStore,
    fetchSettings,
    filterUnavailableTerminals,
    loadPaypalScript,
    onboardingPayPal,
    PayPalReturnUrlQuery,
    PayPalSettingsResponseModel,
    setPaypalSettings,
    setTerminalsSwitchChangeOnStores
} from '@/stores/payment-methods'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol
} from '@/stores/store-and-terminals'
import {PaymentMethodsActivateTerminalSymbol} from '@/pages/payment-methods/PaymentMethods/PaymentMethods'
import {PaymentMethodType} from '@/constants'
import rootTranslations from '@/translations'
import translations from '@/pages/payment-methods/PaypalPaymentMethod/components/PaypalOnboarding/translations/translations'

@injectable()
export class PaypalPaymentMethodStore implements IPaypalPaymentMethodStore {
    loadingState: LoadingState = LoadingState.IDLE
    isSettingsModalVisible = false
    _settings: PayPalSettingsResponseModel = null
    actionUrl = ''
    returnUrlQuery: PayPalReturnUrlQuery = null
    isPoliciesAccepted = false
    errorMessage = ''

    private _merchantStoresStore: AvailableStoresDataStore
    private _activateTerminalStore: ActivateTerminalStore

    constructor(
        @inject(AvailableStoresDataStoreSymbol) merchantStoresStore: AvailableStoresDataStore,
        @inject(PaymentMethodsActivateTerminalSymbol) activateTerminalStore: ActivateTerminalStore
    ) {
        this._merchantStoresStore = merchantStoresStore
        this._activateTerminalStore = activateTerminalStore
        makeObservable(this, {
            loadingState: observable,
            isSettingsModalVisible: observable,
            _settings: observable,
            errorMessage: observable,
            isPoliciesAccepted: observable,
            actionUrl: observable,
            settings: computed,
            isFullyOnboarded: computed,
            getStatus: action,
            openSettingsModal: action.bound,
            closeSettingsModal: action.bound,
            saveSettingsChanges: action.bound,
            clear: action.bound,
            setPoliciesAccepted: action.bound,
            startOnboarding: action.bound,
            reload: action.bound,
            setErrorMessage: action.bound,
            clearErrorMessage: action.bound
        })
    }

    public get isFullyOnboarded() {
        return (
            this.settings &&
            this.settings?.status === 'active' &&
            this.settings?.paymentsReceivable &&
            this.settings?.primaryEmailConfirmed
        )
    }

    get settings(): PayPalSettingsResponseModel {
        return this._settings
    }

    get stores(): PaymentMethodMerchantStoreModel[] {
        if (this._merchantStoresStore?.ecomStores === null) {
            return []
        }

        const filteredStores = filterUnavailableTerminals(
            this._merchantStoresStore.ecomStores,
            PaymentMethodType.paypal
        )

        return setTerminalsSwitchChangeOnStores(filteredStores, this.onTerminalSwitchChange)
    }

    public setPoliciesAccepted = (isPoliciesAccepted: boolean) => {
        this.isPoliciesAccepted = isPoliciesAccepted
    }

    public setErrorMessage = (msg: string) => {
        this.errorMessage = msg
    }

    public clearErrorMessage = () => {
        this.errorMessage = undefined
    }

    public openSettingsModal = () => {
        this.isSettingsModalVisible = true
    }

    public closeSettingsModal = () => {
        this.isSettingsModalVisible = false
    }

    getStatus = async () => {
        this.clear()
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        const paypalMerchantId = this.returnUrlQuery?.merchantIdInPayPal
        this.loadingState = LoadingState.LOADING

        try {
            const {result, error} = await fetchSettings(paypalMerchantId)

            runInAction(() => {
                if (error) {
                    this.loadingState = LoadingState.FAILED

                    const status = Number(error.code)

                    if (status === 322) {
                        this.startOnboarding()
                    } else {
                        this.setErrorMessage(
                            error.message === 'Terminal not found'
                                ? translations().errors.terminalNotFound
                                : error.message
                        )
                    }

                    return
                }

                this.loadingState = LoadingState.DONE
                this._settings = result

                if (result.status === 'consent-revoked') {
                    this.startOnboarding()
                }
            })
        } catch (error) {
            runInAction(() => {
                if (error) {
                    this.loadingState = LoadingState.FAILED
                    openErrorNotification(rootTranslations().errors.general)
                }
            })
        }
    }

    saveSettingsChanges = async (values) => {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => {
            this.loadingState = LoadingState.LOADING
        })

        try {
            const {error} = await setPaypalSettings(values)

            runInAction(() => {
                if (error) {
                    openErrorNotification(error.message)
                    this.loadingState = LoadingState.FAILED
                    return
                }

                this.loadingState = LoadingState.DONE
                this.closeSettingsModal()
            })
        } catch (error) {
            openErrorNotification(rootTranslations().errors.general)
            this.loadingState = LoadingState.FAILED
            return
        }

        this.getStatus()
    }

    onTerminalSwitchChange = async (terminalId: string, checked: boolean) => {
        await this._activateTerminalStore.onTerminalSwitchChange(
            terminalId,
            checked,
            PaymentMethodType.paypal
        )

        this.getStatus()
    }

    clear = () => {
        this.actionUrl = undefined
        this._settings = undefined
        this.clearErrorMessage()
    }

    public async startOnboarding() {
        this.clearErrorMessage()
        this.loadingState = LoadingState.LOADING

        const {hostname, port, protocol} = window.location
        const portStr = port ? `:${port}` : ''
        const returnUrl = `${protocol}//${hostname}${portStr}/payment-methods/detail/paypal`
        const {error, result} = await onboardingPayPal({returnUrl})

        runInAction(() => {
            this.loadingState = LoadingState.DONE

            if (error) {
                openErrorNotification(error.message)
                return
            }

            this.actionUrl = result.links.find((l) => l.rel === 'action_url')?.href
            setTimeout(() => loadPaypalScript())
        })
    }

    public reload = async (query?: PayPalReturnUrlQuery) => {
        this.returnUrlQuery = query
        if (String(this.returnUrlQuery?.permissionsGranted) === 'false') {
            await this.startOnboarding()
            this.setErrorMessage(translations().permissionNotGranted)
            return
        }
        await this.getStatus()
    }
}
