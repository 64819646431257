import {OnlinePaymentStatus, POSPaymentStateStatus} from '@/constants'

export const ECOMTransactionStatuses = [
    OnlinePaymentStatus.charge,
    OnlinePaymentStatus.auth,
    OnlinePaymentStatus.reject,
    OnlinePaymentStatus.refund
]
export const POSTransactionStatuses = [
    POSPaymentStateStatus.all,
    POSPaymentStateStatus.successful,
    POSPaymentStateStatus.failed
]
