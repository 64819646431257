import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Moment} from 'moment'
import {MonthPicker, TRangePickerContainerStore} from '@/components'
import {RangePickerContainerProps} from '@/components/containers/RangePickerContainer/props'

export const MonthPickerStoreSymbol = Symbol('RangePickerStore')

export const MonthPickerContainer: React.FC<RangePickerContainerProps> = observer(({injectableIdentifier = MonthPickerStoreSymbol}) => {

    const store = useInjection<TRangePickerContainerStore>(injectableIdentifier)

    const setMonths = (values: [Moment, Moment]) => {
        const [startDate, endDate] = values

        const start = startDate.clone().startOf('month')
        const end = endDate.clone().endOf('month')

        store.setDates([start, end])
    }

    return (
        <>
            <MonthPicker
                startDate={store.startDate}
                endDate={store.endDate}
                onMonthsChange={setMonths}
            />
        </>
    )
})
