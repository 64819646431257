import translations from './translations'

translations.add('sl', {
    labels: {
        transactionType: 'Typ transakcie'
    },
    warnings: {
        verification:
            'Verifikácia účtu vyvolá transakciu s nulovou hodnotou aby overila detaily karty'
    },
    errors: {
        transactionType: 'Vyžaduje sa typ transakcie'
    },
    placeholders: {
        transactionType: 'Typ transakcie'
    }
})
