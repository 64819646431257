import { TranslationBuilder } from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    selectEntity: string
    ourSupportTeamIsHereToHelp: (message: string) => JSX.Element
    successfullyUpdatedDefaultMerchant: string
    sessionExpired
    permissionErrorText: string
    back: string
}>()

