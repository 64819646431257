import {injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import {subscribe} from 'dna-react-ioc'

import {ECOMTopSummaryRequest, getEpaySummaryPaymentMethods} from '@/stores'
import {
    AvailableStoresDataStore,
    AvailableStoresDataStoreSymbol,
    EcomStoreSelectStore
} from '@/stores/store-and-terminals'
import {DoughnutChartData} from '@/components'
import {openErrorNotification} from '@/utils'
import {TRangePickerContainerStore, TSelectContainerStore} from '@/components/containers'
import {getDoughnutChartAmountData, getDoughnutChartCountData} from '@/pages/Reports'
import {LoadingState} from '@/types'
import {emptyDoughnutChartData} from '@/services/mocks'
import {EcomTransactionSummaryDoughnutChartCardStoreType} from '@/pages/Reports/components'
import {DoughnutChartType, DoughnutTabType} from '@/pages/Reports/components'
import {Currencies, OnlinePaymentStatus} from '@/constants'
import {getEcomMostUsedDefaultCurrency} from '@/stores/handbooks/services'
import translations from '@/translations'

@injectable()
export class ECOMTransactionSummaryDoughnutChartStore
    implements EcomTransactionSummaryDoughnutChartCardStoreType
{
    private _rangePickerStore: TRangePickerContainerStore
    private _statusSelectStore: TSelectContainerStore
    private _currencySelectStore: TSelectContainerStore
    private _merchantStoresStore: AvailableStoresDataStore
    private _storeSelectStore: EcomStoreSelectStore

    constructor(
        rangePickerStore: TRangePickerContainerStore,
        statusSelectStore: TSelectContainerStore,
        currencySelectStore: TSelectContainerStore,
        type: DoughnutChartType,
        storeSelectStore?: EcomStoreSelectStore,
        private _parseLabel?: (label: any) => string
    ) {
        this._rangePickerStore = rangePickerStore
        this._statusSelectStore = statusSelectStore
        this._currencySelectStore = currencySelectStore
        this._merchantStoresStore = subscribe<AvailableStoresDataStore>(
            AvailableStoresDataStoreSymbol
        )
        this._type = type
        this._storeSelectStore = storeSelectStore

        makeObservable(this, {
            chartType: observable,
            _amountData: observable,
            _countData: observable,
            amountLoadingState: observable,
            countLoadingState: observable,
            amountData: computed,
            countData: computed,
            filter: computed,
            setChartTabType: action.bound
        })

        reaction(
            () => this.filter,
            ({from, to, selectedStatus, currency}) => {
                if (from && to && selectedStatus && currency) {
                    this.loadAmountTransactionChartData()
                    this.loadCountTransactionChartData()
                }
            }
        )
    }

    _type: DoughnutChartType
    _amountData: DoughnutChartData = null
    _countData: DoughnutChartData = null
    chartType = DoughnutTabType.amount
    amountLoadingState = LoadingState.IDLE
    countLoadingState = LoadingState.IDLE

    get filter(): Partial<ECOMTopSummaryRequest> {
        return {
            from: this._rangePickerStore.startDate,
            to: this._rangePickerStore.endDate,
            selectedStatus: this._statusSelectStore.value,
            currency: this._currencySelectStore.value,
            storeId: this._storeSelectStore?.value
        }
    }

    async loadAmountTransactionChartData() {
        if (this.amountLoadingState === LoadingState.LOADING || !this.type) {
            return
        }

        runInAction(() => {
            this.amountLoadingState = LoadingState.LOADING
        })

        const storeId = this._storeSelectStore?.value

        const request = {
            from: this._rangePickerStore.startDate.format(),
            to: this._rangePickerStore.endDate.format(),
            status:
                this._statusSelectStore.value === OnlinePaymentStatus.refund
                    ? `${OnlinePaymentStatus.refund},${OnlinePaymentStatus.credited}`
                    : this._statusSelectStore.value,
            top: 5,
            currency: this._currencySelectStore.value,
            orderBy: 'amount',
            ...(storeId ? {storeId} : {})
        }

        try {
            const {result, error} = await getEpaySummaryPaymentMethods(request, this.type)

            runInAction(() => {
                this.amountLoadingState = LoadingState.DONE

                if (error) {
                    this.amountLoadingState = LoadingState.FAILED
                    return openErrorNotification(error.message)
                }

                this._amountData =
                    result?.length > 0
                        ? getDoughnutChartAmountData(result, this._parseLabel)
                        : emptyDoughnutChartData
            })
        } catch (error) {
            runInAction(() => {
                this.amountLoadingState = LoadingState.FAILED
                return openErrorNotification(translations().errors.general)
            })
        }
    }

    async loadCountTransactionChartData() {
        if (this.countLoadingState === LoadingState.LOADING || !this.type) {
            return
        }

        runInAction(() => {
            this.countLoadingState = LoadingState.LOADING
        })

        const storeId = this._storeSelectStore?.value

        const request = {
            from: this._rangePickerStore.startDate.format(),
            to: this._rangePickerStore.endDate.format(),
            status:
                this._statusSelectStore.value === OnlinePaymentStatus.refund
                    ? `${OnlinePaymentStatus.refund},${OnlinePaymentStatus.credited}`
                    : this._statusSelectStore.value,
            top: 5,
            currency: this._currencySelectStore.value,
            orderBy: 'count',
            ...(storeId ? {storeId} : {})
        }

        try {
            const {result, error} = await getEpaySummaryPaymentMethods(request, this.type)

            runInAction(() => {
                this.countLoadingState = LoadingState.DONE

                if (error) {
                    this.countLoadingState = LoadingState.FAILED
                    return openErrorNotification(error.message)
                }

                this._countData =
                    result?.length > 0
                        ? getDoughnutChartCountData(result, this._parseLabel)
                        : emptyDoughnutChartData
            })
        } catch (error) {
            runInAction(() => {
                this.countLoadingState = LoadingState.FAILED
                return openErrorNotification(translations().errors.general)
            })
        }
    }

    setChartTabType(chartType: DoughnutTabType) {
        this.chartType = chartType
    }

    get currency(): Currencies {
        return this._currencySelectStore.value as Currencies
    }

    get type(): DoughnutChartType {
        return this._type
    }

    set type(value: DoughnutChartType) {
        this._type = value
    }

    get amountData() {
        if (!this._currencySelectStore.value && this._merchantStoresStore.ecomStores) {
            this._currencySelectStore.setValue(
                getEcomMostUsedDefaultCurrency(this._merchantStoresStore.ecomStores)
            )
        }

        if (!this._currencySelectStore.value && !this._merchantStoresStore.ecomStores) {
            return emptyDoughnutChartData
        }

        if (!this._amountData && this._currencySelectStore.value) {
            this.loadAmountTransactionChartData()
            return emptyDoughnutChartData
        }

        return this._amountData
    }

    get countData() {
        if (!this._currencySelectStore.value && this._merchantStoresStore.ecomStores) {
            this._currencySelectStore.setValue(
                getEcomMostUsedDefaultCurrency(this._merchantStoresStore.ecomStores)
            )
        }

        if (!this._currencySelectStore.value && !this._merchantStoresStore.ecomStores) {
            return emptyDoughnutChartData
        }

        if (!this._countData && this._currencySelectStore.value) {
            this.loadCountTransactionChartData()
            return emptyDoughnutChartData
        }

        return this._countData
    }
}
