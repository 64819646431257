import translations from './translations'

translations.add('sl', {
    title: 'Potrebna preverba',
    continue: 'Naprej',
    resend: {
        question: `Niste prejeli kode?`
    },
    description: (n, e) => `Za nadaljevanje vnesite ${n}-mestno kodo preverjanja, poslano na ${e}`
})
