import React from 'react'
import translations from './translations'

translations.add('en', {
    title: 'Log in to your account',
    selectEntity: 'Select your entity',
    ourSupportTeamIsHereToHelp: (message: string) => <>{message ? <>{message}<br/></> : <>We didn&apos;t recognise the login details you&apos;re using.
        Please check if you&apos;re using the email you&apos;ve been onboarded with.</>}

        Our support team is here to help: <a href={'https://dnapayments.com/online-quote#2'} target={'_blank'} rel={'noreferrer'}>Support</a></>,
    successfullyUpdatedDefaultMerchant: 'Default merchant successfully updated',
    sessionExpired: 'Your session has expired. Please log in again.',
    permissionErrorText: 'You do not have permission to log in',
    back: 'Back to Login'
})
