export enum PeriodType {
  Day = 'day',
  Yesterday = 'yesterday',
  Week = 'week',
  LastWeek = 'lastWeek',
  Month = 'month',
  LastMonth = 'lastMonth',
  LastTwoMonth = 'lastTwoMonth',
  Year = 'year',
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
  Last60Days = 'last60Days',
  Last90Days = 'last90Days',
  Last12Months = 'last12Months',
  Quarter = 'quarter',
  Custom = 'custom'
}
