import {register} from 'dna-react-ioc'
import {
    CardSettingsStoreInterface,
    CardsManagementStoreInterface
} from '@/pages/business-account/CardsManagement'
import {CardSettingsStoreSymbol} from '@/pages/business-account/CardsManagement/components/CardSettings/CardSettings'
import {CardSettingsStore} from '@/stores/business-account/cards-management/card-settings/CardSettingsStore'
import {CardsManagementStoreSymbol} from '@/pages/business-account/CardsManagement/CardsManagement'
import {CardsManagementStore} from '@/stores/business-account/cards-management/CardsManagementStore'
import {
    PaginationContainerStoreType,
    TRangePickerContainerStore,
    TSearchSelectContainerStore,
    TSelectContainerStore
} from '@/components'
import {
    CardIssuingConfigsStore,
    CardIssuingConfigStoreInterface,
    PaginationStore,
    RangePickerStore,
    SearchSelectStore,
    SelectContainerStore
} from '@/stores'
import {
    AccountInfoSearchSelectStoreSymbol,
    AccountInfoTableFilterRangePickerContainerStoreSymbol,
    AccountInfoTableFilterStatusSelectContainerStoreSymbol,
    AccountInfoStoreInterface
} from '@/pages/business-account/AccountInfo'
import {
    AccountTransactionsFilterButtonStoreSymbol,
    AccountTransactionsFilterButtonStoreInterface
} from '@/pages/business-account/AccountInfo/components/AccountTransactionsFilterButton'
import {searchOptions} from '@/stores/transactions/OnlinePaymentsStore/constants/searchOptions'
import {
    AccountInfoPaginationStoreSymbol,
    AccountInfoStoreSymbol,
    CardIssuingConfigsStoreSymbol
} from '@/pages/business-account/AccountInfo/AccountInfo'
import {AccountInfoStore} from '@/stores/business-account/account-info/AccountInfoStore'
import {MakeTransferStoreSymbol, TMakeTransferStore} from '@/pages/business-account/MakeTransfer'
import {MakeTransferStore} from '@/stores/business-account'
import {AccountTransactionsFilterButtonStore} from '@/stores/business-account/account-transactions'

export const businessAccountInitState = () => {
    register<PaginationContainerStoreType>(AccountInfoPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()
    register<CardsManagementStoreInterface>(CardsManagementStoreSymbol)
        .to(CardsManagementStore)
        .inSingletonScope()
    register<CardSettingsStoreInterface>(CardSettingsStoreSymbol)
        .to(CardSettingsStore)
        .inSingletonScope()
    register<AccountInfoStoreInterface>(AccountInfoStoreSymbol)
        .to(AccountInfoStore)
        .inSingletonScope()
    // filter stores
    register<TRangePickerContainerStore>(AccountInfoTableFilterRangePickerContainerStoreSymbol)
        .to(RangePickerStore)
        .inSingletonScope()
    register<TSelectContainerStore>(AccountInfoTableFilterStatusSelectContainerStoreSymbol)
        .to(SelectContainerStore)
        .inSingletonScope()
    register<AccountTransactionsFilterButtonStoreInterface>(
        AccountTransactionsFilterButtonStoreSymbol
    )
        .to(AccountTransactionsFilterButtonStore)
        .inSingletonScope()
    register<TSearchSelectContainerStore>(AccountInfoSearchSelectStoreSymbol)
        .toDynamicValue(() => new SearchSelectStore(searchOptions))
        .inSingletonScope()
    register<TMakeTransferStore>(MakeTransferStoreSymbol).to(MakeTransferStore).inSingletonScope()
    register<CardIssuingConfigStoreInterface>(CardIssuingConfigsStoreSymbol)
        .to(CardIssuingConfigsStore)
        .inSingletonScope()
}
