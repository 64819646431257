import translations from './translations'

translations.add('en', {
    back: 'Back to login',
    title: 'Set new password',
    newPassword: 'Your new password',
    confirmPassword: 'Confirm password',
    errors: {
        newPasswordRequired: 'Please, enter your password',
        newPasswordInvalid: 'Incorrect password format',
        confirmPasswordInvalid: 'The passwords you entered do not match',
        confirmPasswordRequired: 'Please, enter your password again to confirm'
    },
    submit: 'Confirm',
    codeMustContain: 'Password must contain',
    codeRestrictions: {
        atLeastEightCharacters: 'At least 8 characters',
        atLeastOneDigit: 'At least 1 digit',
        atLeastOneSpecialCharacter: 'At least 1 special character e.g. !@#&',
        atLeastOneUppercaseLetter: 'At least 1 uppercase letter',
        atLeastOneLowercaseLetter: 'At least 1 lowercase letter'
    }
})
